import { Box, Tooltip, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ReactComponent as TeamsIcon } from '@work4all/assets/icons/teams.svg';

import {
  CheckboxRadioItem,
  CheckboxRadioItemProps,
} from '@work4all/components/lib/input/checkbox-radio-item';

import { useCanCreateTeamsMeeting } from '../hooks/use-can-create-teams-meeting';

type Props = Omit<CheckboxRadioItemProps, 'label' | 'icon'>;

const TooltipText = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('TEAMS_MEETING_CHECKBOX.CAN_NOT_CREATE_TOOLTIP_TITLE')}>
      <span
        style={{
          color: 'var(--brand01)',
          textDecoration: 'underline',
        }}
      >
        {children}
      </span>
    </Tooltip>
  );
};

export const TeamsMeetingCheckbox = (props: Props) => {
  const { t } = useTranslation();
  const canCreateTeamsMeeting = useCanCreateTeamsMeeting();

  if (canCreateTeamsMeeting === undefined) {
    return null;
  }

  if (canCreateTeamsMeeting) {
    return (
      <CheckboxRadioItem
        label={t('COMMON.TEAMS_CREATE')}
        icon={<TeamsIcon />}
        {...props}
      />
    );
  }

  return (
    <Box marginTop="0.75rem" display="flex" alignItems="center">
      <Box marginRight="0.25rem">
        <TeamsIcon />
      </Box>

      <Typography>
        <Trans
          components={{
            TooltipText: <TooltipText />,
          }}
        >
          {t('TEAMS_MEETING_CHECKBOX.CAN_NOT_CREATE')}
        </Trans>
      </Typography>
    </Box>
  );
};
