import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateCell, ExtendedNumberCell, RadioCell } from '@work4all/components';
import { EditableCell } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';
import { EditModeCell } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { ChecklistePositionPrioritaet } from '@work4all/models/lib/Enums/ChecklistePositionPrioritaet.enum';
import { ChecklistPositionKind } from '@work4all/models/lib/Enums/ChecklistPositionKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';
import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { CheckListPositionPriorityCell } from '../../../../../../components/table-cells/CheckListPositionPriorityCell';
import { EntitySuggestionPickerCell } from '../../../../../../components/table-cells/EntitySuggestionPickerCell';
import { ProjectProcessPickerCell } from '../../../../../../components/table-cells/ProjectProcessPickerCell';
import { SupplierPickerCell } from '../../../../../../components/table-cells/SupplierPickerCell';
import { UserPickerCell } from '../../../../../../components/table-cells/UserPickerCell';
import {
  EditTableColumns,
  OnEditPosition,
} from '../../../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { CheckListMaskFormValue } from '../../../../types';

type Props = {
  onEdit: (result: OnEditPosition<CheckListPosition<EMode.entity>>) => void;
  columnSettings: {
    visibility: string[];
    order: string[];
    width: Record<string, number>;
  };
};

type Column = EditTableColumns<PathsOf<CheckListPosition, 2>>;
type Return = Column[];

const KINDS_WITH_DISABLED_COLUMN = [
  ChecklistPositionKind.HEADLINE,
  ChecklistPositionKind.EMPTY_ROW,
];

export const useCheckListPositionsColumns = ({
  onEdit,
  columnSettings,
}: Props): Return => {
  const { t } = useTranslation();
  const { getValues } = useFormContextPlus<CheckListMaskFormValue>();

  const columns: Column[] = useMemo(() => {
    return [
      {
        id: 'priority',
        Header: t('FIELDS.priority_short'),
        type: 'picker',
        accessor: 'priority',
        sticky: 'left',
        Cell: (cell) => {
          if (KINDS_WITH_DISABLED_COLUMN.includes(cell.row.original.kind)) {
            return null;
          }

          return (
            <CheckListPositionPriorityCell
              value={cell.value}
              onChange={(value) => {
                onEdit({
                  forceUpdate: true,
                  position: {
                    ...cell.row.original,
                    priority: value || ChecklistePositionPrioritaet.NORMAL,
                  },
                });
              }}
            />
          );
        },
        width: 50,
        style: { padding: undefined, display: 'block' },
      },
      {
        id: 'done',
        Header: t('CHECK_LIST_POSITIONS_TABLE.DONE'),
        accessor: 'isChecked',
        sticky: 'left',
        width: 50,
        Cell: (cell) => {
          if (KINDS_WITH_DISABLED_COLUMN.includes(cell.row.original.kind)) {
            return null;
          }

          return (
            <RadioCell
              value={cell.value}
              onChange={(isChecked) => {
                onEdit({
                  forceUpdate: true,
                  position: {
                    ...cell.row.original,
                    isChecked,
                  },
                });
              }}
            />
          );
        },
      },
      {
        id: 'positionNumber',
        Header: t('COMMON.NUMBER_SHORT'),
        type: 'text',
        accessor: 'positionNumber',
        sticky: 'left',
        width: 50,
        Cell: (cell, { isEditMode }) => {
          if (cell.row.original.kind === ChecklistPositionKind.EMPTY_ROW) {
            return '';
          }

          if (isEditMode) {
            return (
              <EditableCell
                {...(cell as object)}
                isEditable
                isEditMode
                cell={cell as unknown as EditModeCell & ExtendedNumberCell}
                onChange={(positionNumber: string) => {
                  onEdit({
                    forceUpdate: true,
                    position: {
                      ...cell.row.original,
                      positionNumber,
                    },
                  });
                }}
              />
            );
          }

          return cell.value ?? '';
        },
      },
      {
        id: 'name',
        Header: t('COMMON.CONTENT'),
        type: 'text',
        accessor: 'name',
        width: 200,
      },
      {
        id: 'note',
        Header: t('CHECK_LIST_POSITIONS_TABLE.NOTE'),
        type: 'text',
        accessor: 'note',
        width: 200,
      },
      {
        id: 'plannedBy',
        Header: t('CHECK_LIST_POSITIONS_TABLE.USER'),
        accessor: 'plannedBy',
        width: 200,
      },
      {
        id: 'date',
        Header: t('COMMON.BEGINNING'),
        type: 'date',
        accessor: 'date',
        width: 150,
        Cell: DateCell,
      },
      {
        id: 'endDate',
        Header: t('COMMON.ENDDATE'),
        type: 'date',
        accessor: 'endDate',
        width: 150,
        Cell: DateCell,
      },
      {
        id: 'userDone',
        Header: t('CHECK_LIST_POSITIONS_TABLE.DONE_FROM'),
        type: 'text',
        accessor: 'userDone',
        width: 200,
        render: (cell, { isEditMode }) => {
          if (isEditMode) {
            return (
              <UserPickerCell
                hideIcon
                value={cell.value}
                onChange={(user) => {
                  onEdit({
                    forceUpdate: true,
                    position: {
                      ...cell.row.original,
                      userDone: user ?? undefined,
                      doneByUserId: user?.id ?? 0,
                    },
                  });
                }}
              />
            );
          }

          return cell.value?.displayName ?? '';
        },
      },
      {
        id: 'category1',
        Header: t('COMMON.CATEGORY1'),
        type: 'text',
        accessor: 'category1',
        width: 150,
        render: (cell, { isEditMode }) => {
          if (KINDS_WITH_DISABLED_COLUMN.includes(cell.row.original.kind)) {
            return null;
          }

          return (
            <EntitySuggestionPickerCell
              entity={Entities.checkListPosition}
              field="category1"
              value={cell.value}
              onChange={(category1) => {
                onEdit({
                  forceUpdate: true,
                  position: {
                    ...cell.row.original,
                    category1,
                  },
                });
              }}
              isEditMode={isEditMode}
            />
          );
        },
      },
      {
        id: 'category2',
        Header: t('COMMON.CATEGORY2'),
        type: 'text',
        accessor: 'category2',
        width: 150,
        render: (cell, { isEditMode }) => {
          if (KINDS_WITH_DISABLED_COLUMN.includes(cell.row.original.kind)) {
            return null;
          }

          return (
            <EntitySuggestionPickerCell
              entity={Entities.checkListPosition}
              field="category2"
              value={cell.value}
              onChange={(category2) => {
                onEdit({
                  forceUpdate: true,
                  position: {
                    ...cell.row.original,
                    category2,
                  },
                });
              }}
              isEditMode={isEditMode}
            />
          );
        },
      },
      {
        id: 'supplier',
        Header: t('COMMON.SUPPLIER'),
        type: 'text',
        accessor: 'supplier',
        width: 200,
        render: (cell, { isEditMode }) => {
          if (isEditMode) {
            return (
              <SupplierPickerCell
                value={cell.value}
                onChange={(supplier) => {
                  onEdit({
                    forceUpdate: true,
                    position: {
                      ...cell.row.original,
                      supplier: supplier ?? undefined,
                      supplierId: supplier?.id ?? 0,
                    },
                  });
                }}
              />
            );
          }

          return cell.value?.name ?? '';
        },
      },

      {
        id: 'projectProcess',
        Header: t('COMMON.PROJECTPROCESS'),
        type: 'text',
        accessor: 'projectProcess',
        width: 200,
        render: (cell, { isEditMode }) => {
          if (cell.row.original.kind !== ChecklistPositionKind.DEFAULT)
            return '';
          const projectId = getValues('projectId');
          if (isEditMode && projectId) {
            return (
              <ProjectProcessPickerCell
                value={cell.value}
                projectId={projectId}
                onChange={(projectProcess) => {
                  onEdit({
                    forceUpdate: true,
                    position: {
                      ...cell.row.original,
                      projectProcess: projectProcess ?? undefined,
                      projectProcessId: projectProcess?.id ?? 0,
                    },
                  });
                }}
              />
            );
          }

          return cell.value?.process ?? '';
        },
      },
    ];
  }, [getValues, onEdit, t]);

  const columnsWithSavedWidth: Column[] = useDeepMemo(() => {
    return columns.map((column) => {
      return {
        ...column,
        width: columnSettings.width[column.id] ?? column.width,
      };
    });
  }, [columnSettings.width, columns]);

  return columnsWithSavedWidth;
};
