export class Semaphore {
  private _available: number;
  private _waiting: Array<() => void>;

  get available(): number {
    return this._available;
  }

  constructor(count = 1) {
    this._available = count;
    this._waiting = [];
  }

  async wait(): Promise<void> {
    if (this._available > 0) {
      this._available--;
    } else {
      await new Promise<void>((resolve) => this._waiting.push(resolve));
    }
  }

  release(): void {
    if (this._waiting.length > 0) {
      const next = this._waiting.shift();
      if (next) {
        next();
      }
    } else {
      this._available++;
    }
  }
}
