import styles from './FavoriteFlagButton.module.scss';

import { gql, useQuery } from '@apollo/client';
import { Badge, IconButton, Tooltip } from '@mui/material';
import clsx from 'clsx';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { FlagIcon } from '@work4all/components';

import { useWidgetsDataBag } from '@work4all/data';
import { widgetsRequest } from '@work4all/data/lib/data-retriever/hooks/widgets-request';

import { FileContext } from './FileContext';

const useNumberOfFlaggedFileRecords = () => {
  const file = useContext(FileContext);
  const widgetsDataBag = useWidgetsDataBag();

  const requestData = useMemo(() => {
    return widgetsRequest({
      definitions: widgetsDataBag.widgetsDefinitions,
      fileId: Number(file.id),
      contactId: file.contactMetadata?.selectedContact,
      fileType: file.type,
      querySize: 9999,
      filterFileWidgetsByFavoriteRecords: true,
      onlyFlaggableAsFavoriteEntities: true,
      overridesFields: {},
    });
  }, [file, widgetsDataBag.widgetsDefinitions]);

  const groupRequests = Object.keys(requestData.requestByGroup).flatMap(
    (groupRequestsKey) => {
      return requestData.requestByGroup[groupRequestsKey];
    }
  );

  const query = useQuery<{ total: number }[]>(gql`
    query numberOfFlaggedFileRecords {
      ${groupRequests.join('\n')}
    }`);

  if (query.loading) {
    return 0;
  }

  return Object.keys(query.data)
    .map((key) => query.data[key])
    .reduce((accumulator, data) => accumulator + data.total, 0);
};

export const FavoriteFlagButton = () => {
  const { t } = useTranslation();
  const {
    filterFileWidgetsByFavoriteRecords,
    setFilterFileWidgetsByFavoriteRecords,
  } = useWidgetsDataBag();

  const numberOfFlaggedFileRecords = useNumberOfFlaggedFileRecords();

  return (
    <Tooltip
      title={
        numberOfFlaggedFileRecords === 0
          ? t('COMMON.NO_FLAGGED_ITEMS')
          : filterFileWidgetsByFavoriteRecords
          ? t('COMMON.REMOVE_FLAG_FILTER')
          : t('COMMON.SET_FLAG_FILTER')
      }
    >
      <span>
        <IconButton
          onClick={() => {
            setFilterFileWidgetsByFavoriteRecords(
              !filterFileWidgetsByFavoriteRecords
            );
          }}
          size="large"
          disabled={numberOfFlaggedFileRecords === 0}
        >
          <Badge
            badgeContent={numberOfFlaggedFileRecords}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            classes={{
              badge: clsx(styles.badge, {
                [styles.flagged]: filterFileWidgetsByFavoriteRecords,
              }),
            }}
          >
            <FlagIcon isFlagged={filterFileWidgetsByFavoriteRecords} />
          </Badge>
        </IconButton>
      </span>
    </Tooltip>
  );
};
