import { set } from 'lodash';

import {
  DOCUMENT_SHARED_DEFAULT_PREFILTER,
  EntityByLayoutType,
  PARENT_DOCUMENT_ID_PROPERTY,
  WidgetsDefinitions,
} from '@work4all/models';
import { FieldDefinitions } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileType } from '@work4all/models/lib/File';

import { fieldDefinitions } from '../../hooks/data-provider/definitons/fieldDefinitions';
import { buildQuery } from '../../hooks/data-provider/utils/buildQuery';
import { canFlagEntityRecordsAsFavorite } from '../../utils/can-flag-entity-records-as-favorite';
import { entityHasProperty } from '../helpers/entityHelper';
import { widgetArrayColumns } from '../utils/widget-array-columns';

import { layoutDataQuery } from './layout-data-query';

type Props = {
  definitions: WidgetsDefinitions;
  fileId: number;
  fileType: FileType;
  contactId: number | null;
  querySize: number;
  querySizeByWidgetId?: Record<string, number>;
  filterFileWidgetsByFavoriteRecords: boolean;
  onlyFlaggableAsFavoriteEntities?: boolean;
  overridesFields?: Record<string, unknown>;
};

export const widgetsRequest = ({
  definitions,
  fileId,
  fileType,
  contactId,
  querySize,
  querySizeByWidgetId,
  filterFileWidgetsByFavoriteRecords,
  onlyFlaggableAsFavoriteEntities,
  overridesFields,
}: Props) => {
  const allQueryParts = [];
  const requestIdToWidgetConfig = {};
  const entities = [];
  const requestByGroup: Record<string, string[]> = {};
  definitions?.definition.forEach((group) => {
    requestByGroup[group.name] = requestByGroup[group.name] || [];
    for (const definition of group.widgets) {
      for (const entity of definition.config.entities) {
        const key = EntityByLayoutType[entity.entityTypeName];

        const canFlagAsFavorite = canFlagEntityRecordsAsFavorite(key);

        if (onlyFlaggableAsFavoriteEntities && !canFlagAsFavorite) {
          continue;
        }

        entities.push(key);
        const fields = {};

        if (!overridesFields) {
          for (const column of entity.ui.columns) {
            set(fields, column.accessor, null);
          }

          if (entity.ui.rowModifiers) {
            for (const modifier of entity.ui.rowModifiers.filter(
              (rm) => rm.type === 'StyleModifier'
            )) {
              for (const rule of modifier.rules) {
                for (const property of Object.keys(rule.condition)) {
                  set(fields, property, null);
                }
              }
            }
          }
        }

        // Add `id` field to all entities so the response can be normalized.
        addIdFields(overridesFields ?? fields, key);
        const filter: Record<
          string,
          | Record<string, string>
          | Record<string, Record<string, string | number>>[]
        >[] = [...entity.filters];
        if (definitions.layoutType === 'PROJEKT') {
          const _filter: Record<string, Record<string, string>>[] = [];

          /*
           * According to Sascha "ReViewModel" doesn't have a project.
           *
           * Full context: https://work4all.slack.com/archives/C03LXTT1RL4/p1726720590088399
           */

          if (key !== Entities.reViewModel) {
            _filter.push({ projectId: { $eq: fileId.toString() } });
          }

          /**
           * according to Sascha "Incoming invoices have multiple projects, so also the field projectCode is not correct (deprecated)."
           * so for now we'll keep filtering inboundInvoice by projectId
           * https://work4all.slack.com/archives/C03LXTT1RL4/p1683279484778709
           */
          if (
            key !== Entities.reViewModel &&
            // TODO WW-4671
            // We should remove `Entities.inboundInvoice` condition after
            // updating the widget entity type from `InboundInvoice` to
            // `ReViewModel` in the widgets configs for all tenants
            // and after releasing a new release.
            // I only updated the entity type for suppliers and projects
            // in tenant 3.
            // Also we should wait for the new release because
            // `EntityByLayoutType` was missing the `ReViewModel` and
            // without it, it will break the app.
            key !== Entities.inboundInvoice
          ) {
            _filter.push({
              'project.parentProject.id': { $eq: fileId.toString() },
            });
          }

          /*
           * In projects we should show any re that have
           * the project on one or re bookings
           *
           * Full context: https://work4all.atlassian.net/browse/WW-4207
           */

          // TODO WW-4671
          // We should remove `Entities.inboundInvoice` condition after
          // updating the widget entity type from `InboundInvoice` to
          // `ReViewModel` in the widgets configs for all tenants
          // and after releasing a new release.
          // I only updated the entity type for suppliers and projects
          // in tenant 3.
          // Also we should wait for the new release because
          // `EntityByLayoutType` was missing the `ReViewModel` and
          // without it, it will break the app.
          if (
            fileType === FileType.PROJECT &&
            (key === Entities.reViewModel || key === Entities.inboundInvoice)
          ) {
            _filter.push({
              'bookings.projectId': { $eq: fileId.toString() },
            });
          }

          filter.push({ $or: _filter });
        } else {
          if (key === Entities.project) {
            filter.push({
              [definitions.layoutType === 'KUNDE'
                ? 'customerId'
                : 'supplierId']: { $eq: fileId.toString() },
            });
            if (contactId) {
              filter.push({
                [definitions.layoutType === 'KUNDE'
                  ? 'customerContactId'
                  : 'supplierContactId']: { $eq: contactId.toString() },
              });
            }
          } else if (key === Entities.travelReceipts) {
            filter.push({
              'travelExpenses.businessPartnerId': {
                $eq: fileId.toString(),
              },
              'travelExpenses.businessPartnerType': {
                $eq: definitions.layoutType,
              },
            });
            if (contactId) {
              filter.push({
                'travelExpenses.contactId': { $eq: contactId.toString() },
              });
            }
          } else {
            filter.push({ businessPartnerId: { $eq: fileId.toString() } });
            if (
              entityHasProperty(
                EntityByLayoutType[entity.entityTypeName],
                'businessPartnerType'
              )
            ) {
              filter.push({
                businessPartnerType: { $eq: definitions.layoutType },
              });
            }
            if (
              entityHasProperty(
                EntityByLayoutType[entity.entityTypeName],
                'contactId'
              ) &&
              contactId
            ) {
              filter.push({ contactId: { $eq: contactId.toString() } });
            }
          }
        }

        if (key === Entities.document) {
          const hasParentDocumentFilter = filter.some((filterPart) => {
            return PARENT_DOCUMENT_ID_PROPERTY in filterPart;
          });

          if (!hasParentDocumentFilter) {
            filter.push(DOCUMENT_SHARED_DEFAULT_PREFILTER);
          }
        }

        const { arrayColumnsFields } = widgetArrayColumns({
          entity: key,
          fileType,
        });

        if (canFlagAsFavorite) {
          fields['favoriteItem'] = { id: null };
        }

        const entityQuery = buildQuery(
          {
            entity: key,
            sort: entity.sortOptions,
            filter,
            data: overridesFields ?? { ...fields, ...arrayColumnsFields },
          },
          querySizeByWidgetId?.[definition.id] || querySize
        );
        //push the filter for the active layout

        const query = layoutDataQuery(
          entityQuery.gen.queryInner,
          entityQuery.variables,
          canFlagAsFavorite && filterFileWidgetsByFavoriteRecords
        );

        const mappedName = `req${allQueryParts.length + 1}`;
        requestIdToWidgetConfig[mappedName] = definition;
        allQueryParts.push(`${mappedName}:${query.trim()}\n`);
        requestByGroup[group.name].push(`${mappedName}:${query.trim()}\n`);
      }
    }
  });

  return {
    allQuery: allQueryParts.join('\n'),
    reqMap: requestIdToWidgetConfig,
    entities: entities,
    requestByGroup,
  };
};

/**
 * Adds `id` field to the fields list if the it is not present and the entity
 * has a definition for it. Recursively repeats the process for nested entities.
 *
 * This is required for the Apollo cache normalization to work properly.
 */
//eslint-disable-next-line
function addIdFields(fields: any, entity: Entities): void {
  //eslint-disable-next-line
  const definition = fieldDefinitions[entity] as FieldDefinitions<any>;

  // If the entity does not have `id` field, there is nothing to do. There is no
  // reason to check nested entities, because it will be impossible to normalize
  // the cache anyway.
  if (!definition?.id) {
    return;
  }

  fields.id = null;

  // If the entity has nested entities, add `id` field to the fields list.
  const nestedFields = Object.entries(fields).filter(
    ([_, value]) => value !== null
  );

  for (const [key, value] of nestedFields) {
    const def = definition[key.toLowerCase()];

    if (def && def.entity) {
      addIdFields(
        value,
        Array.isArray(def.entity) ? def.entity[0] : def.entity
      );
    }
  }
}
