import { get } from 'lodash';
import { useMemo } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { UserSyncOptions } from '@work4all/models/lib/Classes/UserSyncOptions.entity';
import { SyncOptionsContactExport } from '@work4all/models/lib/Enums/SyncOptionsContactExport.enum';

import { useUserMailboxExchangeSynchronizationData } from './use-user-mailbox-exchange-synchronization-data';

type Props = {
  mailboxId: string;
};

type CreateOptionProps<K extends keyof (User & UserSyncOptions)> = {
  data: User | UserSyncOptions;
  key: K;
  mutate: (
    key: K,
    value: (User & UserSyncOptions)[K],
    isSyncOptions: boolean
  ) => void;
  defaultValue: (User & UserSyncOptions)[K];
  isSyncOptions?: boolean;
};

const createOption = <K extends keyof (User & UserSyncOptions)>({
  data,
  key,
  defaultValue,
  mutate,
  isSyncOptions = false,
}: CreateOptionProps<K>) => {
  return {
    value: get(data, key, defaultValue) as (User & UserSyncOptions)[K],
    set: (value: (User & UserSyncOptions)[K]) =>
      mutate(key, value, isSyncOptions),
  };
};

export const useUserMailboxExchangeSynchronizationOptions = ({
  mailboxId,
}: Props) => {
  const { user, syncOptions, mutate } =
    useUserMailboxExchangeSynchronizationData({
      mailboxId,
    });

  return useMemo(() => {
    return {
      allowToUseOwnPrivateData: createOption<'allowToUseOwnPrivateData'>({
        mutate,
        data: user,
        defaultValue: false,
        key: 'allowToUseOwnPrivateData',
      }),

      allowExportingCustomer: createOption<'contactsExportCustomer'>({
        mutate,
        data: syncOptions,
        defaultValue: false,
        key: 'contactsExportCustomer',
        isSyncOptions: true,
      }),

      allowExportingSupplier: createOption<'contactsExportSupplier'>({
        mutate,
        data: syncOptions,
        defaultValue: false,
        key: 'contactsExportSupplier',
        isSyncOptions: true,
      }),

      allowExportingEmployees: createOption<'contactsExportEmployees'>({
        mutate,
        data: syncOptions,
        defaultValue: false,
        key: 'contactsExportEmployees',
        isSyncOptions: true,
      }),

      contactsExportMode: createOption<'contactsExportMode'>({
        mutate,
        data: syncOptions,
        defaultValue: SyncOptionsContactExport.NO_EXPORT,
        key: 'contactsExportMode',
        isSyncOptions: true,
      }),

      contactsAllowedExportMode: createOption<'contactsExportAllowed'>({
        mutate,
        data: syncOptions,
        defaultValue: SyncOptionsContactExport.NO_EXPORT,
        key: 'contactsExportAllowed',
        isSyncOptions: true,
      }),

      includeContactsPrivateData: createOption<'contactsIcludePrivateData'>({
        mutate,
        data: syncOptions,
        defaultValue: false,
        key: 'contactsIcludePrivateData',
        isSyncOptions: true,
      }),

      allowSyncingAppointments: createOption<'appointmentsSync'>({
        mutate,
        data: syncOptions,
        defaultValue: false,
        key: 'appointmentsSync',
        isSyncOptions: true,
      }),

      allowSyncingPrivateAppointments:
        createOption<'appointmentsSyncPrivateItems'>({
          mutate,
          data: syncOptions,
          defaultValue: false,
          key: 'appointmentsSyncPrivateItems',
          isSyncOptions: true,
        }),
    };
  }, [mutate, user, syncOptions]);
};
