import styles from './CheckboxToggleCell.module.scss';

import { CheckCircleRounded, CircleTwoTone } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import { IUser, useDataMutation, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

export type ITaskStatusCellProps = {
  value: TaskStatus;
  row: Row<Task>;
};

export const TaskStatusCell = (props: ITaskStatusCellProps) => {
  const {
    value,
    row: { original: task },
  } = props;

  const { t } = useTranslation();
  const user = useUser();
  const { isAllowedToModify, isSuccess } = calculateTaskProps(task, user);
  const toggleStatus = useEditTaskStatus();
  const status = value;
  const Icon = isSuccess ? CheckCircleRounded : CircleTwoTone;

  return (
    <div className={styles['task-status-cell-root']}>
      <IconButton
        disabled={!isAllowedToModify}
        onClick={(e) => {
          e.stopPropagation();
          toggleStatus(task);
        }}
        sx={{ padding: 0 }}
      >
        <Icon
          className={clsx(styles['check-circle'], {
            [styles['check-circle-success']]: status === TaskStatus.ERLEDIGT,
            [styles['check-circle-edit']]:
              status === TaskStatus.IN_BEARBEITUNG ||
              status === TaskStatus.WARTET ||
              status === TaskStatus.ZURUECK_GESTELLT,
            [styles['disabled']]: !isAllowedToModify,
          })}
        />
      </IconButton>

      <span className={styles['label']}>{t(`TASK_STATUS.${status}`)}</span>
    </div>
  );
};

export const useEditTaskStatus = () => {
  const [mutate] = useDataMutation({
    entity: Entities.task,
    mutationType: EMode.upsert,
    responseData: { id: null, status: null },
  });

  const user = useUser();

  const toggleStatus = (task: Task, status?: TaskStatus) => {
    const { isAllowedToModify, isSuccess } = calculateTaskProps(task, user);

    if (!isAllowedToModify) return;
    if (isSuccess) {
      mutate({ id: task.id, status: status ?? TaskStatus.OFFEN });
    } else {
      mutate({ id: task.id, status: status ?? TaskStatus.ERLEDIGT });
    }
  };
  return toggleStatus;
};

const calculateTaskProps = (task: Task, user: IUser) => {
  const taskOwnerId = task.user?.id;

  const isOwnTask = user.benutzerCode === taskOwnerId;
  const canCreateTasks = checkUserRight(
    UserRights.TelefonateAufgabenNotizenAnlegenAendern, //is allowe to create and edit tasks
    user.benutzerRechte
  );
  const canOnlyEditOwnTasks = checkUserRight(
    UserRights.TelefonateAufgabenNotizenAendernLoeschenEigene, //but is only allowed to alter "own tasks"
    user.benutzerRechte
  );

  const isAllowedToModify =
    canCreateTasks && canOnlyEditOwnTasks ? isOwnTask : canCreateTasks;

  const status = task.status;
  const isSuccess = status === TaskStatus.ERLEDIGT;

  return { isSuccess, isAllowedToModify };
};
