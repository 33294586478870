import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticsSalesVolumeBySupplierRow } from './StatisticsSalesVolumeBySupplierRow.entity';

export class StatisticsSalesVolumeBySupplierColumn<
  T extends EMode = EMode.entity
> {
  /** Alias: rangeStart */
  rangeStart?: any;
  /** Alias: rangeEnd */
  rangeEnd?: any;
  /** Alias: name */
  name?: any;
  /** Alias: rowCount */
  rowCount?: any;
  /** Alias: rows */
  rows?: StatisticsSalesVolumeBySupplierRow<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeBySupplierColumn> = {
  rangeStart: {
    alias: 'rangeStart',
  },
  rangeEnd: {
    alias: 'rangeEnd',
  },
  name: {
    alias: 'name',
  },
  rowCount: {
    alias: 'rowCount',
  },
  rows: {
    alias: 'rows',
    entity: Entities.statisticsSalesVolumeBySupplierRow,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeBySupplierColumnEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeBySupplierColumn> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeBySupplierColumn',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
