import { Add } from '@mui/icons-material';
import { Fab, Theme, useMediaQuery } from '@mui/material';

import { ArticlePicker } from '@work4all/components/lib/components/entity-picker/article-picker/ArticlePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Article } from '@work4all/models/lib/Classes/Article.entity';

import { useMaskTabContext } from '../../../../../../mask-tabs';

import { useArticlePickerContext } from './ArticlePickerContext';

export const ComponentArticlePicker = () => {
  const maskTabContext = useMaskTabContext();
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const { handler: onAddPosition } = useArticlePickerContext();
  const { ...articlePopoverProps } = usePopoverState();

  if (!isMobile || maskTabContext.value !== 'components') return <></>;

  return (
    <EntityPickerPopover
      picker={
        <ArticlePicker
          multiple={false}
          onChange={(article) => {
            onAddPosition({ article });
            articlePopoverProps.onClose();
          }}
          value={null}
          data={COMPONENT_DATA}
        />
      }
      fullscreen
    >
      <Fab size="medium" color="primary">
        <Add />
      </Fab>
    </EntityPickerPopover>
  );
};

const COMPONENT_DATA: Article = {
  longtext: null,
  netPrice: null,
  netPrice2: null,
  netPrice3: null,
  number: null,
  articleKind: null,
  unit: {
    id: null,
    name: null,
  },
  entryPrice: null,
};
