import { UseFormReturn } from 'react-hook-form';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useFormUpdate } from '../../../utils/use-form-update';
import { DocumentMaskFormValue } from '../types';

export function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    const typename: string = (
      businessPartner as unknown as Record<string, string>
    ).__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}

export const useDocumentFormUpdate = (
  form: UseFormReturn<DocumentMaskFormValue>
) => {
  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.isPrivateCustomer
          ? null
          : businessPartner?.mainContact ?? null;

        return {
          businessPartnerId,
          businessPartnerType,
          contact,
        };
      },

      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const projectSubDirectories = project?.projectSubDirectories ?? [];

        const businessPartnerField = form.getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return {
            projectId,
            assignedToFolder: null,
            projectSubDirectories,
          };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectSubDirectories,
            'businessPartner.data': businessPartner,
          };
        }
      },
      user: (user: User) => {
        const userId = user?.id ?? 0;

        return { userId: userId };
      },
    },
    form
  );
};
