import styles from '../../EditTable.module.scss';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import { TableInstance } from 'react-table';
import { VariableSizeList } from 'react-window';

import { ColumnAdditionalData } from '@work4all/components';
import {
  EditModeCell,
  EditModeConfig,
  EditModeTableInstance,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { UseSettingReturn } from '@work4all/data/lib/settings';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { DeepRequired } from '@work4all/utils/lib/deep-required/DeepRequired';

import { TableCell } from '../../components';
import { genericMemo } from '../../generic-memo';
import { useEditRowSeleciton } from '../../hooks/use-edit-row-selection';
import { InfiniteRow, useInifiteList } from '../../InfiniteList';
import {
  EditTableColumns,
  EditTableEntry,
  EditTableProps,
  NO_BORDER_KIND,
} from '../../types';
import { useResizableContext } from '../TableRow/use-resizable';

interface TableCellContainerProps<T extends EditTableEntry>
  extends Pick<
    EditTableProps<T>,
    | 'showBorders'
    | 'disabled'
    | 'loadingRows'
    | 'noIdEditableCells'
    | 'rowSizeMode'
  > {
  row: InfiniteRow<T>;
  tablesLayoutBorders: UseSettingReturn<
    DeepRequired<{
      vertical?: boolean;
      horizontal?: boolean;
    }>
  >;
  columns: EditTableColumns<T>[];
  rowInEditModeValues: React.MutableRefObject<T>;
  editableCols: string[];
  setEditModeConfig: (config: EditModeConfig) => void;
  onRowEdit: () => void | null;
  skeletonFirstRowAffectedIndex: number;
  index: number;
  tableInstance: TableInstance<T> & EditModeTableInstance;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  listRef: React.MutableRefObject<VariableSizeList<any>>;
  // TODO: move on higher level of row calculates
  isRowVisible: (index: number) => boolean;
  onRowOut?: (row: T, dir: 1 | -1) => void;
}

function TableCellContainerInternal<T extends EditTableEntry>(
  props: TableCellContainerProps<T>
) {
  const {
    row,
    showBorders,
    editableCols,
    tablesLayoutBorders,
    rowInEditModeValues,
    disabled,
    setEditModeConfig,
    onRowEdit,
    loadingRows,
    skeletonFirstRowAffectedIndex,
    noIdEditableCells,
    index,
    tableInstance,
    isRowVisible,
    onRowOut,
    rowSizeMode,
    columns,
  } = props;

  const isDefaultExpanded = (() => {
    const autoFocusColumn = columns.find(
      (x) => x.accessor === tableInstance.state.editModeConfig?.autoFocus
    );
    if (!autoFocusColumn) return false;

    if (
      autoFocusColumn.isLongText &&
      !row.original.cacheOnly &&
      autoFocusColumn.multiLinesRowKindsAllowed?.includes(
        row.original.positionKind
      )
    ) {
      return true;
    }
    return false;
  })();

  const resizeable = useResizableContext();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (isDefaultExpanded && row.original.firstVistiFocus) {
      setTimeout(() => {
        toggleExpanded(true);
        row.original.firstVistiFocus = false;
      }, 0);
    }
  }, [isDefaultExpanded, row.original.firstVistiFocus]);

  const toggleExpanded = useCallback(
    (value?: boolean) => {
      setExpanded((x) => {
        if (value !== undefined) {
          if (!value) {
            resizeable.setHeight();
          }
          return value;
        }
        if (x && resizeable) {
          resizeable.setHeight();
        }
        return !x;
      });
    },
    [resizeable]
  );

  const { onSelectionChange, selectionRangeRef } = useEditRowSeleciton();
  const selectionRange = selectionRangeRef.current;
  const { getMaxSize } = useInifiteList();
  const rowInEditMode = row.cells.some(
    (cell) => (cell as unknown as EditModeCell).isEditMode
  );

  useEffect(() => {
    if (!rowInEditMode && expanded) {
      toggleExpanded(false);
    }
  }, [rowInEditMode, toggleExpanded, expanded]);

  return (
    <React.Fragment>
      {row.cells.map((cell, idx) => {
        const definition: EditTableColumns<T> =
          columns.find((x) => x.accessor === cell.column.id) ??
          ({
            type: 'number',
          } as EditTableColumns<T>);

        const render = definition.render;
        const shouldRender = definition.shouldRender;
        const cellClassStyle = definition.style;
        const baseStyle = {
          display: 'flex',
          alignItems: 'flex-start',
        };
        const { style, ...otherCellProps } = cell.getCellProps({
          style:
            tablesLayoutBorders.value.vertical && showBorders
              ? {
                  borderLeft:
                    NO_BORDER_KIND.includes(row.original.positionKind) ||
                    idx === 0
                      ? undefined
                      : '1px solid var(--ui04)',
                  ...baseStyle,
                }
              : baseStyle,
        });

        const isEditMode = (cell as unknown as EditModeCell).isEditMode;

        let cellType =
          isEditMode && definition.type !== 'picker' ? 'EditableCell' : 'Cell';

        cellType =
          !('Cell' in definition) &&
          cell.column.id !== 'expand' &&
          cellType === 'Cell' &&
          definition.type !== 'picker' &&
          definition.type !== 'date' &&
          definition.type !== 'time'
            ? 'EditableCell'
            : cellType;

        const isEditableCell = editableCols.includes(cell.column.id);

        const onCellClick = (
          _e: React.MouseEvent<HTMLElement> | HTMLElement
        ) => {
          if (onRowEdit === null) return;

          const target = 'currentTarget' in _e ? _e.currentTarget : _e;
          const nestedInput = target.querySelector('textarea, input') as
            | HTMLTextAreaElement
            | HTMLInputElement;
          if (nestedInput)
            onSelectionChange(
              nestedInput.selectionStart,
              nestedInput.selectionEnd,
              cell.row.id,
              cell.column.id
            );
          if (disabled) {
            return;
          }
          if (cell.row.original.id === rowInEditModeValues.current?.id) return;

          if (rowInEditModeValues.current) onRowEdit();
          rowInEditModeValues.current = {
            id: row.original.id,
            localId: row.original.localId,
          } as T;

          setEditModeConfig({
            row: cell.row.id,
            columns: editableCols,
            autoFocus: cell.column.id,
          });
        };

        const stopEdit = () => {
          onRowEdit();
          rowInEditModeValues.current = null;

          onSelectionChange();
          return setEditModeConfig({
            row: cell.row.id,
            columns: [],
            autoFocus: cell.column.id,
          });
        };

        const showSkeleton =
          (loadingRows.includes(row.original.id) ||
            (loadingRows.length &&
              skeletonFirstRowAffectedIndex < index &&
              [
                ErpPositionsKind.ZWISCHENSUMME,
                ErpPositionsKind.TITELSUMME,
              ].includes(row.original.positionKind as ErpPositionsKind))) &&
          definition.skeleton;

        const isValueVisble = Boolean(
          row.original.id ||
            noIdEditableCells?.includes(cell.column.id) ||
            !(row.original.posId && cell.column.id === 'amount')
        );

        const autoFocus = selectionRange
          ? selectionRange.cellId === cell.column.id &&
            selectionRange.rowId === cell.row.id
          : undefined;

        const dateTransform = ['date', 'time'].includes(definition.type)
          ? (value: DateTime) => {
              return value?.toISO();
            }
          : definition.transform;

        const renderedCell =
          render && (shouldRender ? shouldRender?.(row.original) : true)
            ? render(cell, { isEditMode, tableInstance })
            : cell.render(cellType, {
                onEdit: onCellClick,
                transform: dateTransform,
                ...definition,
                textarea: definition.type === 'text',
                disabled: !isValueVisble,
                isValueVisble: isValueVisble,
                onSubmit: stopEdit,
                onExit: stopEdit,
                onSelectionChange: (start: number, end: number) => {
                  if (cell.row.id !== selectionRange?.rowId) {
                    setEditModeConfig({
                      row: cell.row.id,
                      columns: editableCols,
                      autoFocus: cell.column.id,
                    });
                  }
                  onSelectionChange(start, end, cell.row.id, cell.column.id);
                },
                selectinRange: autoFocus ? selectionRange : undefined,
                autoFocus,
                onNext: (
                  event: React.KeyboardEvent<HTMLTextAreaElement>,
                  dir: 1 | -1
                ) => {
                  const sortedCols =
                    tableInstance.state.columnOrder.length ===
                    tableInstance.visibleColumns.length
                      ? tableInstance.state.columnOrder
                      : tableInstance.visibleColumns.map((x) => x.id);
                  const currentColumns = sortedCols.filter((x) =>
                    editableCols.includes(x as string)
                  );
                  const idx = currentColumns.findIndex(
                    (x) => cell.column.id === x
                  );

                  const next = currentColumns[idx + dir];

                  if (!next) {
                    stopEdit();
                    onRowOut?.(row.original, dir);
                  }
                },
                className: clsx('inside-row', styles.cellSingleLine, {
                  [styles['right-oriented']]: definition.type === 'number',
                }),
                maxHeight: getMaxSize(index),
                isVisible: isRowVisible(cell.row.index),
                isEditMode: rowInEditMode,
                isEditable: isEditableCell,
                maxLines:
                  !definition.multiLinesRowKindsAllowed ||
                  definition.multiLinesRowKindsAllowed?.includes(
                    cell.row.original.positionKind
                  )
                    ? undefined
                    : 1,
                rowSizeMode,
              });

        const renderCell = () => (
          <TableCell
            {...otherCellProps}
            onClick={onCellClick}
            className={clsx(
              styles['table-cell'],
              (cell.column as ColumnAdditionalData).className,
              {
                [styles.skeleton]: showSkeleton,
                [styles.blurValue]: !isValueVisble,
                [styles.parent]: !isEditableCell,
                [styles['edit-mode']]:
                  tableInstance.state.editModeConfig?.row === row.id,
                [styles.fitContent]: cell.column.id !== 'longtext',
              }
            )}
            style={{
              ...style,
              padding: definition.type === 'picker' ? '0.125rem' : undefined,
              ...cellClassStyle,
            }}
            id={cell.column.id}
            key={cell.column.id}
            kind={row.original.positionKind}
            shouldWrap={
              definition.type !== 'number' && definition.type !== 'picker'
            }
            rowSizeMode={rowSizeMode}
            rowInEditMode={rowInEditMode}
            expandable={
              isEditableCell &&
              !cell.row.original.cacheOnly &&
              definition.isLongText &&
              definition.multiLinesRowKindsAllowed?.includes(
                cell.row.original.positionKind
              )
            }
            expanded={expanded}
            toggleExpanded={toggleExpanded}
          >
            {renderedCell}
          </TableCell>
        );

        return renderCell();
      })}
    </React.Fragment>
  );
}

export const TableCellContainer = genericMemo(TableCellContainerInternal);
