import React from 'react';
import { TableInstance } from 'react-table';

import { TextBuildingBlock } from '@work4all/models/lib/Classes/TextBuildingBlock.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/textBuildingBlock-table-schema.json';

import { useTextBuildingBlockTableAreas } from './hooks/use-text-building-block-table-areas';

const manualGroupBy = false;

const defaultSort = [{ field: 'name', direction: SortDirection.DESCENDING }];

export const TextBuildingBlockTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function TextBuildingBlockTable(props, ref) {
  const { forceRequestFields, ...rest } = props;

  const dataTable = useEntityDataTable<
    TextBuildingBlock,
    Record<string, never>
  >({
    schema: schema as never,
    forceRequestFields,
    defaultSort,
  });

  const areas = useTextBuildingBlockTableAreas();

  return (
    <EntityTable
      ref={ref}
      {...dataTable}
      manualGroupBy={manualGroupBy}
      {...rest}
      areas={areas}
    />
  );
});
