import { Box, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const ForbiddenPriceCell = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('ALERTS.NOT_AUTHORIZED_TO_SEE_PRICES')}>
      <Box flex="1" textAlign="right">
        *
      </Box>
    </Tooltip>
  );
};
