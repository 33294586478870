export enum LetterTemplateType {
  LETTER = 'LETTER',
  DOCUMENT = 'DOCUMENT',
  OFFER = 'OFFER',
  QA_QS = 'QA_QS',
  ERP_ITEM = 'ERP_ITEM',
  INVENTORY = 'INVENTORY',
  APPOINTMENT = 'APPOINTMENT',
  CALL_NOTICE = 'CALL_NOTICE',
  TASK = 'TASK',
  PROCESS_OPERATION = 'PROCESS_OPERATION',
}
