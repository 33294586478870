import { IconButton } from '@mui/material';
import { MutableRefObject } from 'react';
import { TableInstance } from 'react-table';

import { ReactComponent as ColumnsIcon } from '@work4all/assets/icons/columns.svg';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ColumnsVisibility } from '../../../../../../../../../../components/data-tables/table/components/toolbar/components/columns-visibility';
import { settings, useSetting } from '../../../../../../../../../../settings';
import { DEFAULT_VISIBLE_CRM_COLUMNS } from '../../../../../../../../../../settings/settings';
import { useResetColumns } from '../../../../../../erp/components/tab-panels/positions/components/hooks/use-reset-columns';

type Props = {
  tableInstanceRef: MutableRefObject<TableInstance> | undefined;
};

export const CheckListPositionsTableHeaderColumnsVisibility = ({
  tableInstanceRef,
}: Props) => {
  const columnsPopoverState = usePopoverState();
  const openColumnsPopover = columnsPopoverState.handleClick;
  const columnSettings = useSetting(
    settings.crmPositionsConfig(Entities.checkList)
  );

  const resetColumns = useResetColumns({
    columnSettings,
    tableInstanceRef,
    defaultColumns: DEFAULT_VISIBLE_CRM_COLUMNS,
  });

  return (
    <>
      <IconButton
        color="primary"
        onClick={(event) => {
          return openColumnsPopover(event);
        }}
      >
        <ColumnsIcon />
      </IconButton>

      <ColumnsVisibility
        popoverState={columnsPopoverState}
        tableInstanceRef={tableInstanceRef}
        visibleColumns={tableInstanceRef.current?.allColumns ?? []}
        onResetColumns={resetColumns}
      />
    </>
  );
};
