import styles from './Picker.module.scss';

import { Box, LinearProgress } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import React, {
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList } from 'react-window';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

import {
  EntityPickerTab,
  EntityPickerTabBar,
  FilterTextInput,
} from '../../components';
import { KeyNavigationHandlerContainer } from '../../components/list-entity-picker/KeyNavigationHandlerContainer';
import { useSelectionModel } from '../../hooks/use-selection-model';
import type { Selection } from '../../utils/selection-model';
import { StandardEmailTemplates } from '../EmailTemplatePicker';

import { Item } from './Item';

export type Dataset = {
  total: number;
  loading?: boolean;
  data: EMailTemplate[];
};

export type PickerTabProps = {
  label: string;
  data: Dataset;
  disabled?: boolean;
};

export type PickerProps = {
  clearable?: boolean;
  value: Selection<EMailTemplate, false>;
  tabs: PickerTabProps[];
  layout?: 'simple' | 'advanced';
  standardTemplates?: StandardEmailTemplates;
  className?: string;
  onChange: (value: Selection<EMailTemplate, false>) => void;
  onStandardChange: (items: EMailTemplate) => void;
};

export type PickerInterface = {
  toggle: (val: EMailTemplate) => void;
  setQueryString: (val) => void;
  handleKeyboardEvent: (event: KeyboardEvent | React.KeyboardEvent) => void;
};

export function Picker(props: PickerProps) {
  const {
    value,
    clearable = true,
    layout,
    onChange,
    onStandardChange,
    standardTemplates,
  } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const tabs: PickerTabProps[] = props.tabs;
  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    if (!getTabIndex || !value) return 0;
    return getTabIndex(value);
  });

  const { data } = tabs[activeTabIndex];

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const selectionValue = useMemo(() => (value ? [value] : []), [value]);
  const initialSelected = useRef(selectionValue).current;
  const selection = useSelectionModel({
    multiple: false,
    keyFn: (template: EMailTemplate) => template.id,
    initialSelected,
  });

  useEffect(() => {
    selection.setSelected(selectionValue);
  }, [selection, selectionValue]);

  const handleSelect = (value: EMailTemplate) => {
    selection.select(value);
    onChange(selection.getSelected());
  };

  const handleDeselect = (value: EMailTemplate) => {
    selection.deselect(value);
    onChange(selection.getSelected());
  };

  const handleToggle = (value: EMailTemplate) => {
    if (selection.isSelected(value)) {
      if (clearable) {
        handleDeselect(value);
      }
    } else {
      handleSelect(value);
    }
    setQuery('');
    inputRef.current?.focus();
  };

  useImperativeHandle<PickerInterface, PickerInterface>(null, () => {
    return {
      toggle: (val) => handleToggle(val),
      setQueryString: setQuery,
      handleKeyboardEvent: handleKeyNavigation,
    };
  });

  const [query, setQuery] = useState('');

  const regularResult = { ...data };

  if (query !== '') {
    const filterExpr = new RegExp(`.*${query}.*`, 'i');
    const filtered = regularResult.data.filter((template) =>
      template?.name.toString().match(filterExpr)
    );
    regularResult.data = filtered;
    regularResult.total = filtered.length;
  }

  const showSimpleView = layout === 'simple';
  const showPrependedItems = !showSimpleView && !query;

  const items = useMemo(() => [...regularResult.data], [regularResult.data]);

  const initialSelectedInActiveTab = useMemo(() => {
    return getTabIndex
      ? initialSelected.filter((value) => getTabIndex(value) === activeTabIndex)
      : initialSelected;
  }, [activeTabIndex, initialSelected]);

  const prependedItems = useMemo(
    () =>
      showPrependedItems && initialSelectedInActiveTab.length > 0
        ? initialSelectedInActiveTab
        : [],
    [initialSelectedInActiveTab, showPrependedItems]
  );

  const allItems = useMemo(() => {
    const prependedSet = new Set(prependedItems.map((item) => item.id));
    const restItems = items.filter((item) => !prependedSet.has(item.id));
    return [...prependedItems, ...restItems];
  }, [prependedItems, items]);

  const infiniteListRef = useRef<VariableSizeList>();

  const [activeRowIndex, setActiveRowIndex] = useState<number>(0);

  const handleKeyNavigation = useEventCallback(function handleKeyNavigation(
    e: React.KeyboardEvent<HTMLElement>
  ) {
    if (e.code === 'ArrowUp' && activeRowIndex > 0) {
      setActiveRowIndex(activeRowIndex - 1);
      infiniteListRef.current?.scrollToItem(activeRowIndex - 1);
    } else if (e.code === 'ArrowDown' && activeRowIndex < allItems.length - 1) {
      setActiveRowIndex(activeRowIndex + 1);
      infiniteListRef.current?.scrollToItem(activeRowIndex + 1);
    } else if (e.code === 'Enter') {
      e.preventDefault();
      if (allItems[activeRowIndex]) {
        handleToggle(allItems[activeRowIndex]);
        setQuery('');
      }
    } else {
      setActiveRowIndex(0);
      infiniteListRef.current?.scrollToItem(0);
    }
  });

  const handleTabChange = (tab: number) => {
    setActiveTabIndex(tab);
    scrollToTopAfterNextRender.current = true;
    inputRef.current?.focus();
  };

  const showLoadingPlaceholder = allItems.length === 0 && regularResult.loading;

  const scrollToTopAfterNextRender = useRef(false);

  useEffect(() => {
    if (scrollToTopAfterNextRender.current) {
      scrollToTopAfterNextRender.current = false;
      infiniteListRef.current?.scrollToItem(0);
    }
  });

  const total = Math.max(1, allItems.length);
  const LIST_ITEM_HEIGHT = remToPx(2.25);

  const getRowHeight = (index: number) => {
    return index === total - 1 ? LIST_ITEM_HEIGHT - 1 : LIST_ITEM_HEIGHT;
  };

  const [tempStandards, setTempStandards] =
    useState<StandardEmailTemplates>(standardTemplates);
  const pickerItems = data?.data;
  const standardTemplate = useMemo(
    () =>
      tempStandards[pickerItems?.[pickerItems?.length - 1]?.eMailTemplateKind],
    [pickerItems, tempStandards]
  );

  const handleStandardChange = (template: EMailTemplate) => {
    onStandardChange(template);
    setTempStandards({
      ...tempStandards,
      [template.eMailTemplateKind]:
        template.id !== tempStandards[template.eMailTemplateKind]
          ? template.id
          : null,
    });
  };

  return (
    <KeyNavigationHandlerContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
      className={props.className}
      onKeyDown={handleKeyNavigation}
      autoFocus
    >
      {!showSimpleView && (
        <div className={styles.textWrapper}>
          <Box sx={{ flexGrow: 1, paddingLeft: '0.5rem' }}>
            <FilterTextInput
              ref={inputRef}
              autoFocus
              value={query}
              onChange={(e) => {
                setQuery(e);
              }}
              placeholder={t('INPUTS.SEARCH')}
              className={styles['filter-input']}
            />
          </Box>
        </div>
      )}

      {showLoadingPlaceholder && <LinearProgress />}

      <EntityPickerTabBar value={activeTabIndex} onChange={handleTabChange}>
        {tabs.map((tab, index) => (
          <EntityPickerTab key={index} value={index} disabled={tab.disabled}>
            {tab.label}
          </EntityPickerTab>
        ))}
      </EntityPickerTabBar>

      <Box height={LIST_ITEM_HEIGHT * 5 - 1} flex="auto">
        {!showLoadingPlaceholder && (
          <AutoSizer>
            {({ width, height }) => (
              <VariableSizeList
                ref={infiniteListRef}
                className="custom-scrollbar"
                width={width}
                height={height - 1}
                itemSize={getRowHeight}
                itemCount={total}
                overscanCount={3}
                itemData={{
                  activeRowIndex,
                  clearable,
                  items: allItems,
                  isSelected: (item: EMailTemplate) =>
                    selection.isSelected(item),
                  onToggle: handleToggle,
                  onStandardChange: handleStandardChange,
                  standardTemplate,
                }}
              >
                {Item}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </Box>
    </KeyNavigationHandlerContainer>
  );
}

function getTabIndex(template: EMailTemplate): number {
  switch (template.eMailTemplateKind) {
    case EMailTemplateKind.TICKET:
      return 1;
    case EMailTemplateKind.APPOINTMENT_NOTIFICATION:
      return 2;
    case EMailTemplateKind.ERP_OBJECTS:
      return 3;
    default:
      return 0;
  }
}
