import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

export class FavoriteItem<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: objCode */
  objCode?: any;
  /** Alias: objId */
  objId?: any;
  /** Alias: dateTime */
  dateTime?: string;
  /** Alias: comment */
  comment?: any;
  /** Alias: userCode */
  userCode?: any;
  /** Alias: user */
  user?: any;

  __typename?: string;
}

const fields: FieldDefinitions<FavoriteItem> = {
  id: {
    alias: 'id',
  },
  objectType: {
    alias: 'objectType',
  },
  objCode: {
    alias: 'objCode',
  },
  objId: {
    alias: 'objId',
  },
  dateTime: {
    alias: 'dateTime',
  },
  comment: {
    alias: 'comment',
  },
  userCode: {
    alias: 'userCode',
  },
  user: {
    alias: 'user',
  },
  __typename: {
    alias: '__typename',
  },
};

export const favoriteItemEntityDefinition: EntitiyDefinition<FavoriteItem> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'FavoriteItem',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'setFavoriteItem',
        args: [
          { name: 'objectType', type: 'ObjectType!' },
          { name: 'codeOrId', type: 'String!' },
          { name: 'comment', type: 'String' },
        ],
      },
    },
  },
  fieldDefinitions: fields,
};
