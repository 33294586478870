import styles from './PositionPicker.module.scss';

import { Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { HeaderRow } from '../components/custom-list-entity-picker/HeaderRow';
import { ItemRow } from '../components/custom-list-entity-picker/ItemRow';
import { Picker } from '../components/v2/Picker';
import { usePickerContext } from '../components/v2/picker-context';
import { IPickerProps } from '../types';

import {
  PositionPickerSettings,
  PositionPickerSettingsProps,
} from './PositionPickerSettings';

export interface IPositionPickerProps
  extends Pick<
      IPickerProps<Position, false>,
      'value' | 'onChange' | 'maxItems' | 'clearable' | 'placeholder'
    >,
    PositionPickerSettingsProps {
  positions: Position[];
}

// This is a trimmed down version of ListEntityPicker with customizations.
export function PositionPicker(props: IPositionPickerProps) {
  const {
    positions,
    descriptionField,
    onDescriptionFieldChange,
    ...otherProps
  } = props;

  const isInteractive = useCallback(
    (item: Position) => SELECTABLE_POSITION_TYPES.includes(item.positionKind),
    []
  );

  const items = useMemo(() => {
    return positions.map((el) => {
      return { ...el, unselectable: !isInteractive(el) };
    });
  }, [isInteractive, positions]);

  function renderRow(position: Position, all: Position[]) {
    const interactiveEl = isInteractive(position);
    const description =
      position[descriptionField] || position.longtext || position.shortText;
    return (
      <ItemRow
        key={position.id}
        title={description}
        isInteractive={interactiveEl}
        prevWasInteractive={isInteractive(
          all[Math.max(1, all.indexOf(position)) - 1]
        )}
        content={
          <>
            <Typography
              variant={interactiveEl ? 'body1' : 'h4'}
              className={styles.mark}
            />
            <Typography
              variant={interactiveEl ? 'body1' : 'h4'}
              className={styles.number}
            >
              {position.number}
            </Typography>
            <Typography
              variant={interactiveEl ? 'body1' : 'h4'}
              className={styles.text}
            >
              {description}
            </Typography>
            <Typography
              variant={interactiveEl ? 'body1' : 'h4'}
              className={styles.amount}
            >
              {isInteractive ? position.amount : null}
            </Typography>
            <Typography
              variant={interactiveEl ? 'body1' : 'h4'}
              className={styles.unit}
            >
              {isInteractive ? position.unit : null}
            </Typography>
          </>
        }
      />
    );
  }

  return (
    <Picker
      options={items}
      sortBy={'longtext'}
      sortByDirection={SortDirection.ASCENDING}
      filterBy={['longtext', 'shortText', 'number']}
      prependSelected={false}
      prependList={
        <PositionPickerHeaderRow descriptionField={descriptionField} />
      }
      renderItemContent={renderRow}
      alignClearIcon={true}
      layout={'advanced'}
      headerAppend={
        <PositionPickerSettings
          descriptionField={descriptionField}
          onDescriptionFieldChange={onDescriptionFieldChange}
        />
      }
      {...otherProps}
    />
  );
}

const SELECTABLE_POSITION_TYPES: ErpPositionsKind[] = [
  ErpPositionsKind.STANDARD,
  ErpPositionsKind.STUECKLISTE,
  ErpPositionsKind.INTERNE_POSITION,
  ErpPositionsKind.INTERNE_STUECKLISTE,
];

function PositionPickerHeaderRow({
  descriptionField,
}: Required<Pick<PositionPickerSettingsProps, 'descriptionField'>>) {
  const { t } = useTranslation();

  const context = usePickerContext();

  return (
    <HeaderRow
      content={
        <>
          <Typography variant="h4" className={styles.mark} />
          <Typography variant="h4" className={styles.number}>
            {t('POSITIONS_PICKER.COLUMNS.NUMBER')}
          </Typography>
          <Typography variant="h4" className={styles.text}>
            {t(`POSITIONS_PICKER.COLUMNS.${descriptionField}`)}
          </Typography>
          <Typography variant="h4" className={styles.amount}>
            {t('POSITIONS_PICKER.COLUMNS.AMOUNT')}
          </Typography>
          <Typography variant="h4" className={styles.unit}>
            {t('POSITIONS_PICKER.COLUMNS.UNIT')}
          </Typography>
          {context.hasSelection && <div className={styles.close} />}
        </>
      }
    />
  );
}
