import styles from './organisation-area-content.module.scss';

import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { QuickColumnFilterPicker } from '../../data-tables/table-quick-filters';
import { PersonalAndGeneralView } from '../../filters/PersonalAndGeneralView';
import {
  useOrganisationAreaContext,
  useOrganisationAreaSectionMultiselectability,
  useOrganisationAreaSectionVisibility,
} from '../hooks';
import { availableSectionsConfigs, sectionToPickerType } from '../utils';

import { OrganisationAreaGroupsOverlay } from './organisation-area-groups-overlay';
import { OrganisationAreaSection } from './organisation-area-section';
import { OrganisationAreaSectionActions } from './organisation-area-section-actions';
import { OrganisationAreaSectionEditAction } from './organisation-area-section-edit-action';
import { OrganisationAreaSectionHeader } from './organisation-area-section-header';
import { OrganisationAreaSectionMultiselectAction } from './organisation-area-section-multiselect-action';
import { OrganisationAreaSectionTitle } from './organisation-area-section-title';

export const OrganisationAreaContent = () => {
  const { t } = useTranslation();
  const { entity } = useOrganisationAreaContext();
  const sections = availableSectionsConfigs(entity, [
    'general-filters',
    'personal-filters',
  ]);

  const { isVisible } = useOrganisationAreaSectionVisibility({
    entity,
  });
  const { isMultiselectable } = useOrganisationAreaSectionMultiselectability();

  const renderManagedSections = useCallback(() => {
    return sections.map((section) => {
      return (
        <OrganisationAreaSection
          key={section.id}
          isVisible={isVisible(section.id)}
        >
          <OrganisationAreaSectionHeader>
            <OrganisationAreaSectionTitle
              rightElement={
                <OrganisationAreaSectionActions>
                  <OrganisationAreaSectionMultiselectAction
                    section={section.id}
                  />

                  <OrganisationAreaSectionEditAction section={section.id} />
                </OrganisationAreaSectionActions>
              }
            >
              {t(section.nameTranslationKey)}
            </OrganisationAreaSectionTitle>
          </OrganisationAreaSectionHeader>

          <OrganisationAreaGroupsOverlay />

          <QuickColumnFilterPicker
            entity={entity}
            type={sectionToPickerType(section.id)}
            multiple={isMultiselectable(section.id)}
          />
        </OrganisationAreaSection>
      );
    });
  }, [sections, isVisible, t, entity, isMultiselectable]);

  return (
    <div className={styles.root}>
      {renderManagedSections()}

      <PersonalAndGeneralView
        entity={entity}
        hideGeneralFilters={!isVisible('general-filters')}
        hidePersonalFilters={!isVisible('personal-filters')}
      />
    </div>
  );
};
