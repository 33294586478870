import { useMemo } from 'react';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DocumentsTypesIntersection } from '../types';

export const useDocumentsRequestData = (
  entity: Entities,
  id: string | number
) => {
  return useMemo(() => {
    // we will always filter by id and will always try to normalize the data
    // so an id field will be available
    const filter = [{ id: { $eq: id } }];

    const FileInfoFragment: FileEntity<EMode.query> = {
      previewUrl: null,
      downloadUrl: null,
      fileRequestType: null,
      previewMimeType: null,
      fileEntityFilename: null,
      downloadMimeType: null,
      fileServiceProviderInfos: {
        id: null,
        exists: null,
        fileName: null,
        mimeType: null,
        fspUrl: null,
      },
    };

    const DocumentFileFragment: Document<EMode.query> = {
      thumbnail: {
        id: null,
        filename: null,
        fileInfos: {
          ...FileInfoFragment,
        },
      },
      fileInfos: {
        ...FileInfoFragment,
      },
      noteHtml: null,
    };

    const data: DocumentsTypesIntersection = {
      id: null,
      note: null,
      date: null,
      user: {
        id: null,
        displayName: null,
        firstName: null,
        lastName: null,
      },
      userId: null,
      topicMarkList: [
        {
          name: null,
          id: null,
        },
      ],

      businessPartnerId: null,
      businessPartnerType: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            name: null,
            id: null,
            mainContactId: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
            isPrivateCustomer: null,
          },
          supplier: {
            name: null,
            id: null,
            mainContactId: null,
            number: null,
            website: null,
            contactList: [
              {
                id: null,
                displayName: null,
                role: null,
                layedOff: null,
                salutation: {
                  id: null,
                  isMale: null,
                  isFemale: null,
                },
              },
            ],
            isPrivateCustomer: null,
          },
        },
      },
      contact: {
        name: null,
        firstName: null,
        displayName: null,
        businessPartnerId: null,
        businessPartnerType: null,
        eMail: null,
        id: null,
      },
      project: {
        name: null,
        id: null,
        number: null,
        projectSubDirectories: [
          {
            id: null,
            name: null,
          },
        ],
      },
      projectId: null,
      filePath: null,
      fileInfos: {
        ...FileInfoFragment,
      },
      favoriteItem: {
        id: null,
      },
    };

    if (entity === Entities.document) {
      (data as unknown as Document).infoWindowName = null;

      Object.assign(data, {
        ...DocumentFileFragment,
        childDocuments: [
          {
            id: null,
            ...DocumentFileFragment,
          },
        ],
      });
    }

    return {
      filter,
      entity,
      data,
    };
  }, [entity, id]);
};
