import {
  DatePeriodPresets,
  DateTimeCustom,
  formatDatePeriod,
  formatDateString,
} from '@work4all/utils/lib/date-utils/formatDateString';

export type IDateCellProps = {
  value: string;
  column: {
    cellParams: {
      spanEnd?: string;
      format?: string; //https://github.com/moment/luxon/blob/master/docs/formatting.md#table-of-tokens
      wholeDayIndicator: string; //field that determines if this is to be considered without time
    };
  };
  row: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
  };
};

// ToDo: decide whether to clean up schemas
// -> remove all custom 'dd.MM.yy' & 'dd.MM.yyyy' from schemas and use
//    the default one: DateTimeCustom.DATE_SIMPLE_2YEAR
const mapSchemaDateFormat = (format: string) => {
  switch (format) {
    case 'dd.MM.yy':
      return DateTimeCustom.DATE_SIMPLE_2YEAR;
    case 'dd.MM.yyyy':
      return DateTimeCustom.DATE_SHORT;
    case 'dd.MM.yy HH:mm':
      return DateTimeCustom.DATE_TIME_SIMPLE_2YEAR;
    default:
      return format ? format : DateTimeCustom.DATE_SIMPLE_2YEAR;
  }
};

export const DateCell: React.FC = (props: IDateCellProps) => {
  const {
    value,
    column: { cellParams },
    row: { original: rowData },
  } = props;

  let endValue;
  if (cellParams && cellParams.spanEnd && rowData[cellParams.spanEnd]) {
    endValue = rowData[cellParams.spanEnd];
  }

  if (
    Object.values(DatePeriodPresets).includes(
      cellParams?.format as DatePeriodPresets
    )
  ) {
    if (value && endValue) {
      return formatDatePeriod(
        cellParams?.format as DatePeriodPresets,
        value,
        endValue,
        rowData['isWholeDay']
      );
    } else {
      return '-';
    }
  }

  return formatDateString(value, mapSchemaDateFormat(cellParams?.format));
};
