import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from 'lz-string';

import { entityHasProperty } from '@work4all/data/lib/data-retriever/helpers/entityHelper';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { throwInDev } from '@work4all/utils';

export interface CustomerTableFilterSettingValue {
  id: string;
  name: string;
}

export interface CustomerTableFilterSetting {
  value: CustomerTableFilterSettingValue[];
  readOnly?: boolean;
}

export interface SupplierTableFilterSettingValue {
  id: string;
  name: string;
}

export interface SupplierTableFilterSetting {
  value: SupplierTableFilterSettingValue[];
  readOnly?: boolean;
}

export interface ProjectTableFilterSettingValue {
  id: string;
  name: string;
}

export interface ProjectTableFilterSetting {
  value: ProjectTableFilterSettingValue[];
  readOnly?: boolean;
}

export interface ContactTableFilterSettingValue {
  id: string;
  displayName: string;
}

export interface ContactTableFilterSetting {
  value: ContactTableFilterSettingValue[];
  readOnly?: boolean;
}

export interface DateTableFilterSetting {
  value: { startDate: string; endDate: string };
  readOnly?: boolean;
}

export interface UserTableFilterSettingValue {
  id: string;
  displayName: string;
}

export interface UserTableFilterSetting {
  value: UserTableFilterSettingValue[];
  readOnly?: boolean;
}

export interface StatusTableFilterSettingValue {
  id: string;
  name: string;
}

export interface StatusTableFilterSetting {
  value: StatusTableFilterSettingValue[];
  readOnly?: boolean;
}

export type AnyTableFilterSetting =
  | CustomerTableFilterSetting
  | SupplierTableFilterSetting
  | ProjectTableFilterSetting
  | ContactTableFilterSetting
  | DateTableFilterSetting
  | UserTableFilterSetting
  | StatusTableFilterSetting;

export interface AnyEntityTableSettingsInit {
  customer?: CustomerTableFilterSetting;
  supplier?: SupplierTableFilterSetting;
  project?: ProjectTableFilterSetting;
  contact?: ContactTableFilterSetting;
  date?: DateTableFilterSetting;
  user?: UserTableFilterSetting;
  status?: StatusTableFilterSetting;
}

export type TableSettingFilter = AnyTableFilterSetting & {
  id: string;
};

export interface TableSettings {
  filter?: TableSettingFilter[];
  sort?: { id: string; desc: boolean }[];
}

const FILTERED_COLUMN_IDS = {
  customer: 'customer.id',
  supplier: 'supplier.id',
  project: 'project.id',
  businessPartner: 'businessPartner.data.id',
  businessPartnerContactCombinedBusinessPartner:
    'businessPartnerContactCombined.businessPartner.data.id',
  contact: 'contact.id',
  businessPartnerContactCombinedContact:
    'businessPartnerContactCombined.contact.id',

  date: 'date',
  user: 'user.id',
  status: 'status',
};

/**
 *
 * @param entityType Will try to construct a table state object that can be
 * consumed by a data table using the given entity type to map different
 * properties to table columns. Will ignore unknown properties or properties
 * that can't be applied to the given entity type.
 *
 * The table component will ignore any unrecognized filter, so this function can
 * sometimes return extra filters, which is a result of misconfiguration, but it
 * shouldn't affect the final table state.
 *
 * The return value of this function should be passed to
 * `stringifyTableSettings` function and then added ot the ULR as the `"settings"`
 * parameter.
 */
export function makeTableSettings(
  entityType: Entities,
  init: AnyEntityTableSettingsInit,
  forceStatusProperty: string = null
): TableSettings | null {
  const filters = [
    makeCustomerFilter(entityType, init),
    makeSupplierFilter(entityType, init),
    makeBusinessPartnerFilter(entityType, init),
    makeProjectFilter(entityType, init),
    makeContactFilter(entityType, init),
    makeDateFilter(entityType, init),
    makeUserFilter(entityType, init),
    makeStatusFilter(entityType, init, forceStatusProperty),
  ].filter(Boolean);

  if (filters.length === 0) {
    return null;
  }

  return {
    filter: filters,
  };
}

function makeCustomerFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.customer) {
    return null;
  }

  switch (entityType) {
    // Add custom cases here if necessary.
    default:
      if (entityHasProperty(entityType, 'customer')) {
        return {
          id: FILTERED_COLUMN_IDS.customer,
          ...init.customer,
        };
      }

      return null;
  }
}

function makeSupplierFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.supplier) {
    return null;
  }

  switch (entityType) {
    // Add custom cases here if necessary.
    default:
      if (entityHasProperty(entityType, 'supplier')) {
        return {
          id: FILTERED_COLUMN_IDS.supplier,
          ...init.supplier,
        };
      }

      return null;
  }
}

function makeBusinessPartnerFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.customer && !init.supplier) {
    return null;
  }

  switch (entityType) {
    // Add custom cases here if necessary.
    case Entities.travelReceipts:
      return {
        id: 'travelExpenses.businessPartner.data.id',
        ...(init.customer ?? init.supplier),
      };

    default:
      if (
        entityHasProperty(entityType, 'businessPartner') ||
        entityHasProperty(entityType, 'businessPartnerContactCombined')
      ) {
        if (init.customer && init.supplier) {
          throwInDev(
            'Cannot unambiguously determine business partner because both customer and supplier are present in the init object.'
          );

          return null;
        }

        return {
          id: entityHasProperty(entityType, 'businessPartner')
            ? FILTERED_COLUMN_IDS.businessPartner
            : FILTERED_COLUMN_IDS.businessPartnerContactCombinedBusinessPartner,
          ...(init.customer ?? init.supplier),
        };
      }

      return null;
  }
}

function makeProjectFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.project) {
    return null;
  }

  switch (entityType) {
    case Entities.reViewModel:
      return {
        id: 'bookings.projectId',
        ...init.project,
      };
    // Add custom cases here if necessary.
    default:
      if (entityHasProperty(entityType, 'project')) {
        return {
          id: FILTERED_COLUMN_IDS.project,
          ...init.project,
        };
      }

      return null;
  }
}

function makeContactFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.contact) {
    return null;
  }

  switch (entityType) {
    // Add custom cases here if necessary.
    case Entities.project:
      if (init.customer) {
        return {
          id: 'customerContact.id',
          ...init.contact,
        };
      }
      if (init.supplier) {
        return {
          id: 'supplierContact.id',
          ...init.contact,
        };
      }
      return null;
    case Entities.travelReceipts:
      return {
        id: 'travelExpenses.contact.id',
        ...init.contact,
      };
    default:
      if (
        entityHasProperty(entityType, 'contact') ||
        entityHasProperty(entityType, 'businessPartnerContactCombined')
      ) {
        return {
          id: entityHasProperty(entityType, 'contact')
            ? FILTERED_COLUMN_IDS.contact
            : FILTERED_COLUMN_IDS.businessPartnerContactCombinedContact,
          ...init.contact,
        };
      }

      return null;
  }
}

function getUserFilterProperty(entityType: Entities): string | null {
  switch (entityType) {
    case Entities.ticket:
      return 'editor1.id';

    // Add custom cases here if necessary.
    default:
      if (entityHasProperty(entityType, 'user')) {
        return FILTERED_COLUMN_IDS.user;
      }

      return null;
  }
}

function makeUserFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.user) {
    return null;
  }

  const property = getUserFilterProperty(entityType);

  if (!property) {
    return null;
  }

  return {
    id: property,
    ...init.user,
  };
}

function getStatusFilterProperty(entityType: Entities): string | null {
  switch (entityType) {
    case Entities.ticket:
      return 'status1';
    case Entities.mailboxContent:
      return 'status';

    // Add custom cases here if necessary.
    default:
      if (entityHasProperty(entityType, 'status')) {
        return FILTERED_COLUMN_IDS.status;
      }

      return null;
  }
}

function makeStatusFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit,
  forceStatusProperty: string = null
): TableSettingFilter | null {
  if (!init.status) {
    return null;
  }

  const property = forceStatusProperty || getStatusFilterProperty(entityType);

  if (!property) {
    return null;
  }

  return {
    id: property,
    ...init.status,
  };
}

function getDateFilterProperty(entityType: Entities): string | null {
  switch (entityType) {
    case Entities.appointment:
      return 'startDate';

    case Entities.visitationReport:
      return 'startTime';

    case Entities.ticket:
      return 'followUpDate';

    case Entities.contract:
      return 'contractDate';

    case Entities.reViewModel:
      return 'invoiceDate';

    // Add other custom cases here if necessary.
    default:
      if (entityHasProperty(entityType, 'date')) {
        return FILTERED_COLUMN_IDS.date;
      }

      return null;
  }
}

function makeDateFilter(
  entityType: Entities,
  init: AnyEntityTableSettingsInit
): TableSettingFilter | null {
  if (!init.date) {
    return null;
  }

  const property = getDateFilterProperty(entityType);

  if (!property) {
    return null;
  }

  return {
    id: property,
    ...init.date,
  };
}

export function stringifyTableSettings(settings: TableSettings): string {
  return compressToEncodedURIComponent(JSON.stringify(settings));
}

export function parseTableSettings(
  settingsString: string
): TableSettings | null {
  try {
    const jsonString = decompressFromEncodedURIComponent(settingsString);

    if (!jsonString) {
      return null;
    }

    return JSON.parse(jsonString);
  } catch {
    return null;
  }
}
