import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '@work4all/components/lib/components/entity-preview/types';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { VacationApprovalPreview } from './VacationApprovalPreview';

type VacationApprovalPreviewContainerProps = EntityPreviewContainerProps;

export const VacationFields: VacationRequestEntity<EMode.query> = {
  id: null,
  dateFrom: null,
  dateTo: null,
  insertTime: null,
  status: null,
  user: {
    id: null,
    displayName: null,
    departmentName: null,
  },
  releasedByUser: {
    id: null,
    displayName: null,
  },
  releasedByUserId: null,
  vacationItems: [
    {
      id: null,
      approveDate: null,
      amount: null,
      note: null,
      date: null,
      vacationApprover: {
        id: null,
        displayName: null,
        departmentName: null,
      },
    },
  ],
};

export function VacationApprovalPreviewContainer(
  props: VacationApprovalPreviewContainerProps
) {
  const { ids, ...handlers } = props;

  const entries = useEntityPreviewData<VacationRequestEntity>(
    ids,
    Entities.vacationRequestEntity,
    VacationFields
  );

  if (!entries) {
    return null;
  }

  return <VacationApprovalPreview {...handlers} entries={entries} />;
}
