import { DateTime } from 'luxon';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

export const formatters = {
  dateSimple2year: (input: string) =>
    DateTime.fromISO(input).toLocaleString(DateTimeCustom.DATE_SIMPLE_2YEAR),
  dateTimeSimple: (input: string) =>
    DateTime.fromISO(input).toLocaleString(DateTimeCustom.DATE_TIME),
};
