import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class TicketFilter<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: global */
  global?: boolean;
  /** Alias: filter */
  filter?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;

  __typename?: string;
}

const fields: FieldDefinitions<TicketFilter> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  userId: {
    alias: 'userCode',
  },
  global: {
    alias: 'global',
  },
  filter: {
    alias: 'filter',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  __typename: {
    alias: '__typename',
  },
};

export const ticketFilterEntityDefinition: EntitiyDefinition<TicketFilter> = {
  local: {},
  remote: {
    queryName: 'getTicketFilters',
    fragmentName: 'TicketFilter',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertTicketFilter',
        args: [
          { name: 'input', type: 'InputTicketFilter!' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
