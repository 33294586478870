import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { EntityPicker } from '../components/v2/EntityPicker';
import { IPickerProps } from '../types';

export type ILookUpProps<TMultiple extends boolean> = IPickerProps<
  LookUp,
  TMultiple
> & {
  lookupType: LookupType;
};

export function LookUpPicker<TMultiple extends boolean>(
  props: ILookUpProps<TMultiple>
) {
  const { data, lookupType, ...otherProps } = props;

  const allFields = useMemo(() => ({ ...LookUp_FIELDS, ...data }), [data]);
  const vars = useMemo(() => ({ type: lookupType }), [lookupType]);

  return (
    <EntityPicker
      entity={Entities.lookUp}
      data={allFields}
      filterBy={'name'}
      sortBy={'name'}
      layout={'advanced'}
      vars={vars}
      renderItemContent={(lookUp) => (
        <Tooltip title={lookUp.name}>
          <Typography variant="body2" noWrap>
            {lookUp.name}
          </Typography>
        </Tooltip>
      )}
      {...otherProps}
    />
  );
}

const LookUp_FIELDS: LookUp = {
  id: null,
  name: null,
  value: null,
};
