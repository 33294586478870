import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ComesGoesRounding } from '../Enums/ComesGoesRounding.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { TypeOfRemuneration } from '../Enums/TypeOfRemuneration.enum';
import { TypeOfWorkTimeTracking } from '../Enums/TypeOfWorkTimeTracking.enum';
import { UserClass } from '../Enums/UserClass.enum';

import { CostCenter } from './CostCenter.entity';
import { CustomerGroup } from './CustomerGroup.entity';
import { LookUp } from './LookUp.entity';
import { PauseRule } from './PauseRule.entity';
import { Salutation } from './Salutation.entity';
import { Supplier } from './Supplier.entity';
import { UserDailyTarget } from './UserDailyTarget.entity';
import { UserGroup } from './UserGroup.entity';
import { UserSyncOptions } from './UserSyncOptions.entity';

export class FullUser<T extends EMode = EMode.entity> {
  /** Alias: abteilung */
  departmentName?: string;
  /** Alias: name */
  name?: string;
  /** Alias: anzeigename */
  displayName?: string;
  /** Alias: ausgeschieden */
  hasLeft?: boolean;
  /** Alias: briefAnrede */
  letterSalutation?: string;
  /** Alias: code */
  id?: number;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: telefax */
  faxNumber?: string;
  /** Alias: funktion */
  role?: string;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: instanzCode */
  instanceId?: number;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: master */
  isMaster?: number;
  /** Alias: mobil */
  mobileNumber?: string;
  /** Alias: nachname */
  lastName?: string;
  /** Alias: telefon */
  phoneNumber?: string;
  /** Alias: titel */
  title?: string;
  /** Alias: urlaubsgenehmigerCode */
  vacationApproverId?: number;
  /** Alias: vorgesetzterCode */
  supervisorId?: number;
  /** Alias: vorname */
  firstName?: string;
  /** Alias: zeichen */
  shortName?: string;
  /** Alias: projectAccessRightsLimited */
  projectAccessRightsLimited?: boolean;
  /** Alias: kommtGehtMinimaleStartZeit */
  comesGoesMinimumStartTime?: number;
  /** Alias: lieferantenCode */
  supplierId?: number;
  /** Alias: anredeCode */
  salutationCode?: number;
  /** Alias: praefix */
  prefix?: string;
  /** Alias: iA */
  iA?: boolean;
  /** Alias: iV */
  iV?: boolean;
  /** Alias: alternativeAnrede */
  alternativeSalutation?: boolean;
  /** Alias: niederlassungsCode */
  departmentId?: number;
  /** Alias: verguetungsart */
  typeofRemuneration?: TypeOfRemuneration;
  /** Alias: standardGruppe */
  standardGroupId?: number;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: staatsangehoerigkeit */
  nationality?: string;
  /** Alias: geburtsort */
  placeOfBirth?: string;
  /** Alias: sozialversicherungsnummer */
  socialSecurityNumber?: string;
  /** Alias: krankenkasse */
  healthInsuranceCompany?: string;
  /** Alias: eintrittsdatum */
  enterDate?: string;
  /** Alias: austrittsdatum */
  exitDate?: string;
  /** Alias: iBAN */
  iban?: string;
  /** Alias: bIC */
  bic?: string;
  /** Alias: bankverbindung */
  bankData?: string;
  /** Alias: lohnsatz1 */
  wageRate1?: number;
  /** Alias: lohnsatz2 */
  wageRate2?: number;
  /** Alias: lohnsatz3 */
  wageRate3?: number;
  /** Alias: strasse */
  street?: string;
  /** Alias: plz */
  postalCode?: string;
  /** Alias: ort */
  location?: string;
  /** Alias: staat */
  state?: string;
  /** Alias: eMailPrivat */
  eMailPrivate?: string;
  /** Alias: telefonPrivat */
  telefonPrivate?: string;
  /** Alias: mobilPrivat */
  mobilPrivate?: string;
  /** Alias: telefaxPrivat */
  telefaxPrivate?: string;
  /** Alias: zeitstempelSollMinutenProTag */
  timestampTargetMinutesPerDay?: number;
  /** Alias: maximaleArbeitsstunden */
  maxWorkingHours?: number;
  /** Alias: maximaleArbeitsstundenMo */
  maxWorkHoursMonday?: number;
  /** Alias: maximaleArbeitsstundenDi */
  maxWorkHoursTuesday?: number;
  /** Alias: maximaleArbeitsstundenMi */
  maxWorkHoursWednesday?: number;
  /** Alias: maximaleArbeitsstundenDo */
  maxWorkHoursThursday?: number;
  /** Alias: maximaleArbeitsstundenFr */
  maxWorkHoursFriday?: number;
  /** Alias: maximaleArbeitsstundenSa */
  maxWorkHoursSaturday?: number;
  /** Alias: maximaleArbeitsstundenSo */
  maxWorkHoursSunday?: number;
  /** Alias: urlaubswert */
  vacationValue?: number;
  /** Alias: kommtGehtProfilId */
  comesGoesProfileId?: string;
  /** Alias: zeitstempelErfassungsvariante */
  timestampRecordingVariant?: TypeOfWorkTimeTracking;
  /** Alias: taktung */
  timing?: number;
  /** Alias: feiertageAlsGeleisteteArbeitszeit */
  markHolidaysAsWorkingHours?: boolean;
  /** Alias: zeitstempelRundungMorgens */
  timestampRoundingMorning?: ComesGoesRounding;
  /** Alias: zeitstempelRundungAbends */
  timestampRoundingEvening?: ComesGoesRounding;
  /** Alias: rundungAbUhrzeit */
  roundingForHour?: string;
  /** Alias: fruehesteKommtZeit */
  earliestCommingTime?: string;
  /** Alias: zeitstempelInkludierteMinutenProTag */
  timestampIncludedMinutesPerDay?: number;
  /** Alias: kommtGehtInklWoche */
  comesGoesInclWeek?: number;
  /** Alias: zeitstempelInkludierteMinutenProMonat */
  timestampIncludedMinutesPerMonth?: number;
  /** Alias: kommtGehtMinimalePause */
  comesGoesMinimalPause?: number;
  /** Alias: pausenCode */
  pauseId?: number;
  /** Alias: benutzerArt */
  userKind?: UserClass;
  /** Alias: salutation */
  salutation?: Salutation<T>;
  /** Alias: supplier */
  supplier?: Supplier<T>;
  /** Alias: superior */
  supervisor?: FullUser<T>;
  /** Alias: userToPermitVacation */
  vacationApprover?: FullUser<T>;
  /** Alias: branch */
  branch?: LookUp<T>;
  /** Alias: group */
  group?: UserGroup<T>;
  /** Alias: costCenter */
  costCenter?: CostCenter<T>;
  /** Alias: ressourceClass */
  ressourceClass?: LookUp<T>;
  /** Alias: defaultGroupForCustomer */
  defaultGroupForCustomer?: CustomerGroup<T>;
  /** Alias: workTimeTrackingBreakRule */
  workTimeTrackingBreakRule?: PauseRule<T>;
  /** Alias: userWorkTimeDaylieTargets */
  userWorkTimeDaylieTargets?: UserDailyTarget<T>[];
  /** Alias: pName */
  designation?: string;
  /** Alias: geburtsdatum */
  birthDate?: string;
  /** Alias: zeitstempelRundungAbUhrzeit */
  timestampRoundingFromTime?: string;
  /** Alias: allowWork4allWeb */
  allowWork4allWeb?: boolean;
  /** Alias: licenseExchangeConnector */
  licenseExchangeConnector?: boolean;
  /** Alias: licenseLegacyApp */
  licenseLegacyApp?: boolean;
  /** Alias: licenseWorkTimeTracking */
  licenseWorkTimeTracking?: boolean;
  /** Alias: licenseTickets */
  licenseTickets?: boolean;
  /** Alias: licenseInventoryScanningSoftware */
  licenseInventoryScanningSoftware?: boolean;
  /** Alias: licenseApiUsage */
  licenseApiUsage?: boolean;
  /** Alias: licenseCrmLight */
  licenseCrmLight?: boolean;
  /** Alias: licenseErpLight */
  licenseErpLight?: boolean;
  /** Alias: licenseProjektLight */
  licenseProjektLight?: boolean;
  /** Alias: licenseWorkTimeTrackingLight */
  licenseWorkTimeTrackingLight?: boolean;
  /** Alias: licenseProjectTimeTrackingLight */
  licenseProjectTimeTrackingLight?: boolean;
  /** Alias: licenseTravelCostLight */
  licenseTravelCostLight?: boolean;
  /** Alias: licenseTicketsLight */
  licenseTicketsLight?: boolean;
  /** Alias: funktion2 */
  role2?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: syncOptions */
  syncOptions?: UserSyncOptions<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<FullUser> = {
  departmentName: {
    alias: 'abteilung',
  },
  name: {
    alias: 'name',
  },
  displayName: {
    alias: 'anzeigename',
  },
  hasLeft: {
    alias: 'ausgeschieden',
  },
  letterSalutation: {
    alias: 'briefAnrede',
  },
  id: {
    alias: 'code',
  },
  eMail: {
    alias: 'eMail',
  },
  faxNumber: {
    alias: 'telefax',
  },
  role: {
    alias: 'funktion',
  },
  groupId: {
    alias: 'grCode',
  },
  instanceId: {
    alias: 'instanzCode',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  isMaster: {
    alias: 'master',
  },
  mobileNumber: {
    alias: 'mobil',
  },
  lastName: {
    alias: 'nachname',
  },
  phoneNumber: {
    alias: 'telefon',
  },
  title: {
    alias: 'titel',
  },
  vacationApproverId: {
    alias: 'urlaubsgenehmigerCode',
  },
  supervisorId: {
    alias: 'vorgesetzterCode',
  },
  firstName: {
    alias: 'vorname',
  },
  shortName: {
    alias: 'zeichen',
  },
  projectAccessRightsLimited: {
    alias: 'projectAccessRightsLimited',
  },
  comesGoesMinimumStartTime: {
    alias: 'kommtGehtMinimaleStartZeit',
  },
  supplierId: {
    alias: 'lieferantenCode',
  },
  salutationCode: {
    alias: 'anredeCode',
  },
  prefix: {
    alias: 'praefix',
  },
  iA: {
    alias: 'iA',
  },
  iV: {
    alias: 'iV',
  },
  alternativeSalutation: {
    alias: 'alternativeAnrede',
  },
  departmentId: {
    alias: 'niederlassungsCode',
  },
  typeofRemuneration: {
    alias: 'verguetungsart',
  },
  standardGroupId: {
    alias: 'standardGruppe',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  nationality: {
    alias: 'staatsangehoerigkeit',
  },
  placeOfBirth: {
    alias: 'geburtsort',
  },
  socialSecurityNumber: {
    alias: 'sozialversicherungsnummer',
  },
  healthInsuranceCompany: {
    alias: 'krankenkasse',
  },
  enterDate: {
    alias: 'eintrittsdatum',
  },
  exitDate: {
    alias: 'austrittsdatum',
  },
  iban: {
    alias: 'iBAN',
  },
  bic: {
    alias: 'bIC',
  },
  bankData: {
    alias: 'bankverbindung',
  },
  wageRate1: {
    alias: 'lohnsatz1',
  },
  wageRate2: {
    alias: 'lohnsatz2',
  },
  wageRate3: {
    alias: 'lohnsatz3',
  },
  street: {
    alias: 'strasse',
  },
  postalCode: {
    alias: 'plz',
  },
  location: {
    alias: 'ort',
  },
  state: {
    alias: 'staat',
  },
  eMailPrivate: {
    alias: 'eMailPrivat',
  },
  telefonPrivate: {
    alias: 'telefonPrivat',
  },
  mobilPrivate: {
    alias: 'mobilPrivat',
  },
  telefaxPrivate: {
    alias: 'telefaxPrivat',
  },
  timestampTargetMinutesPerDay: {
    alias: 'zeitstempelSollMinutenProTag',
  },
  maxWorkingHours: {
    alias: 'maximaleArbeitsstunden',
  },
  maxWorkHoursMonday: {
    alias: 'maximaleArbeitsstundenMo',
  },
  maxWorkHoursTuesday: {
    alias: 'maximaleArbeitsstundenDi',
  },
  maxWorkHoursWednesday: {
    alias: 'maximaleArbeitsstundenMi',
  },
  maxWorkHoursThursday: {
    alias: 'maximaleArbeitsstundenDo',
  },
  maxWorkHoursFriday: {
    alias: 'maximaleArbeitsstundenFr',
  },
  maxWorkHoursSaturday: {
    alias: 'maximaleArbeitsstundenSa',
  },
  maxWorkHoursSunday: {
    alias: 'maximaleArbeitsstundenSo',
  },
  vacationValue: {
    alias: 'urlaubswert',
  },
  comesGoesProfileId: {
    alias: 'kommtGehtProfilId',
  },
  timestampRecordingVariant: {
    alias: 'zeitstempelErfassungsvariante',
  },
  timing: {
    alias: 'taktung',
  },
  markHolidaysAsWorkingHours: {
    alias: 'feiertageAlsGeleisteteArbeitszeit',
  },
  timestampRoundingMorning: {
    alias: 'zeitstempelRundungMorgens',
  },
  timestampRoundingEvening: {
    alias: 'zeitstempelRundungAbends',
  },
  roundingForHour: {
    alias: 'rundungAbUhrzeit',
  },
  earliestCommingTime: {
    alias: 'fruehesteKommtZeit',
  },
  timestampIncludedMinutesPerDay: {
    alias: 'zeitstempelInkludierteMinutenProTag',
  },
  comesGoesInclWeek: {
    alias: 'kommtGehtInklWoche',
  },
  timestampIncludedMinutesPerMonth: {
    alias: 'zeitstempelInkludierteMinutenProMonat',
  },
  comesGoesMinimalPause: {
    alias: 'kommtGehtMinimalePause',
  },
  pauseId: {
    alias: 'pausenCode',
  },
  userKind: {
    alias: 'benutzerArt',
  },
  salutation: {
    alias: 'salutation',
    entity: Entities.salutation,
  },
  supplier: {
    alias: 'supplier',
    entity: Entities.supplier,
  },
  supervisor: {
    alias: 'superior',
    entity: Entities.fullUser,
  },
  vacationApprover: {
    alias: 'userToPermitVacation',
    entity: Entities.fullUser,
  },
  branch: {
    alias: 'branch',
    entity: Entities.lookUp,
  },
  group: {
    alias: 'group',
    entity: Entities.userGroup,
  },
  costCenter: {
    alias: 'costCenter',
    entity: Entities.costCenter,
  },
  ressourceClass: {
    alias: 'ressourceClass',
    entity: Entities.lookUp,
  },
  defaultGroupForCustomer: {
    alias: 'defaultGroupForCustomer',
    entity: Entities.customerGroup,
  },
  workTimeTrackingBreakRule: {
    alias: 'workTimeTrackingBreakRule',
    entity: Entities.pauseRule,
  },
  userWorkTimeDaylieTargets: {
    alias: 'userWorkTimeDaylieTargets',
    entity: Entities.userDailyTarget,
  },
  designation: {
    alias: 'pName',
  },
  birthDate: {
    alias: 'geburtsdatum',
  },
  timestampRoundingFromTime: {
    alias: 'zeitstempelRundungAbUhrzeit',
  },
  allowWork4allWeb: {
    alias: 'allowWork4allWeb',
  },
  licenseExchangeConnector: {
    alias: 'licenseExchangeConnector',
  },
  licenseLegacyApp: {
    alias: 'licenseLegacyApp',
  },
  licenseWorkTimeTracking: {
    alias: 'licenseWorkTimeTracking',
  },
  licenseTickets: {
    alias: 'licenseTickets',
  },
  licenseInventoryScanningSoftware: {
    alias: 'licenseInventoryScanningSoftware',
  },
  licenseApiUsage: {
    alias: 'licenseApiUsage',
  },
  licenseCrmLight: {
    alias: 'licenseCrmLight',
  },
  licenseErpLight: {
    alias: 'licenseErpLight',
  },
  licenseProjektLight: {
    alias: 'licenseProjektLight',
  },
  licenseWorkTimeTrackingLight: {
    alias: 'licenseWorkTimeTrackingLight',
  },
  licenseProjectTimeTrackingLight: {
    alias: 'licenseProjectTimeTrackingLight',
  },
  licenseTravelCostLight: {
    alias: 'licenseTravelCostLight',
  },
  licenseTicketsLight: {
    alias: 'licenseTicketsLight',
  },
  role2: {
    alias: 'funktion2',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  syncOptions: {
    alias: 'syncOptions',
    entity: Entities.userSyncOptions,
  },
  __typename: {
    alias: '__typename',
  },
};

export const fullUserEntityDefinition: EntitiyDefinition<FullUser> = {
  local: {},
  remote: {
    queryName: 'getFullUser',
    fragmentName: 'FullUser',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertFullUser',
        args: [
          { name: 'input', type: 'InputFullUser!' },
          { name: 'relations', type: 'InputFullUserRelation!' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
