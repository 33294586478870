const dayUnits = ['day', 'days', 'd', 'd.', 'tag', 'tage', 'tg', 'tg.'];
const hourUnits = [
  'h',
  'h.',
  'hour',
  'hours',
  'std',
  'std.',
  'stunde',
  'stunden',
];
const minuteUnits = ['minute', 'minuten', 'min', 'min.', 'minutes'];

export const getFactor = (initialUnit: string, targetUnit = 'day') => {
  if (dayUnits.includes(targetUnit.toLowerCase())) {
    if (hourUnits.includes(initialUnit.toLowerCase())) {
      return 1 / 8;
    }
    if (minuteUnits.includes(initialUnit.toLowerCase())) {
      return 1 / 8 / 60;
    }
  }

  if (hourUnits.includes(targetUnit.toLowerCase())) {
    if (dayUnits.includes(initialUnit.toLowerCase())) {
      return 1 * 8;
    }
    if (minuteUnits.includes(initialUnit.toLowerCase())) {
      return 1 / 8;
    }
  }

  if (minuteUnits.includes(targetUnit.toLowerCase())) {
    if (dayUnits.includes(initialUnit.toLowerCase())) {
      return 1 * 8 * 60;
    }
    if (hourUnits.includes(initialUnit.toLowerCase())) {
      return 1 * 60;
    }
  }

  return 1;
};
