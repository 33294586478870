import { useMemo } from 'react';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CUSTOM_FIELDS_DATA } from '../../../components/custom-fields/custom-fields-data';

export const useAppointmentDataRequest = (id: string | number) => {
  return useMemo(() => {
    const filter = [{ id: { $eq: id } }];
    const entity = Entities.appointment;
    const data: Appointment<EMode.query> = {
      id: null,
      userId: null,
      title: null,
      city: null,
      privat: null,
      note: null,
      startDate: null,
      endDate: null,
      fromAbsolute: null,
      toAbsolute: null,
      isWholeDay: null,
      remindDate: null,
      projectId: null,
      remind: null,
      contactId: null,
      exchangeMeeting: null,
      meetingUrl: null,
      contact: {
        displayName: null,
        firstName: null,
        name: null,
        id: null,
        eMail: null,
        businessPartnerId: null,
        businessPartnerType: null,
      },
      businessPartnerId: null,
      businessPartnerType: null,
      businessPartner: {
        id: null,
        data: {
          customer: {
            id: null,
            name: null,
            number: null,
            website: null,
            eMail: null,
            isPrivateCustomer: null,
          },
          supplier: {
            id: null,
            name: null,
            number: null,
            website: null,
            eMail: null,
            isPrivateCustomer: null,
          },
        },
      },
      project: { id: null, name: null, number: null },
      projectProcess: { id: null, projectId: null, process: null },
      projectProcessId: null,
      topicMarkList: [
        {
          id: null,
          name: null,
        },
      ],
      deliveryNote: {
        id: null,
        note: null,
        date: null,
        isClosed: null,
        number: null,
      },
      contract: {
        id: null,
        note: null,
        contractNumber: null,
        contractDate: null,
        isClosed: null,
        number: null,
      },
      projectProcessDuration: null,
      appointmentState: { id: null, name: null },
      appointmentAttendeeList: [
        {
          id: null,
          userId: null,
          contactId: null,
          user: {
            id: null,
            displayName: null,
            firstName: null,
            lastName: null,
            eMail: null,
            userKind: null,
            loginName: null,
          },
          ressource: {
            id: null,
            displayName: null,
            firstName: null,
            lastName: null,
            eMail: null,
            userKind: null,
            loginName: null,
          },
          contact: {
            id: null,
            displayName: null,
            eMail: null,
            firstName: null,
            name: null,
            businessPartnerType: null,
            businessPartnerId: null,
            businessPartner: {
              id: null,
              data: {
                customer: {
                  id: null,
                  name: null,
                  website: null,
                },
                supplier: {
                  id: null,
                  name: null,
                  website: null,
                },
              },
            },
          },
          businessPartnerType: null,
          businessPartner: {
            id: null,
            data: {
              customer: {
                id: null,
                name: null,
                eMail: null,
                website: null,
              },
              supplier: {
                id: null,
                name: null,
                eMail: null,
                website: null,
              },
            },
          },
        },
      ],
      attachmentList: [
        {
          id: null,
          fileName: null,
          note: null,
          fileInfos: {
            fileSize: null,
            fileEntityFilename: null,
            previewUrl: null,
            downloadUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
          },
          date: null,
          userId: null,
          user: {
            id: null,
            displayName: null,
          },
        },
      ],
      customFieldList: [CUSTOM_FIELDS_DATA],
      favoriteItem: { id: null },
    };
    return { filter, entity, data };
  }, [id]);
};
