import { setContext } from '@apollo/client/link/context';
import * as ReactSentry from '@sentry/react';

export const createAuthLink = (
  accessToken: string,
  tenant: number,
  baseUrl = ''
) => {
  return setContext((request, previousContext) => {
    ReactSentry.addBreadcrumb({
      category: 'auth',
      message: 'enhancing request with token',
      data: {
        hasAccessToken: (accessToken || '').trim().length > 0,
      },
      level: 'info',
    });
    return {
      ...previousContext,
      headers: {
        ...previousContext.headers,
        authorization: `bearer ${accessToken}`,
        'x-work4all-mandant': tenant,
        'x-work4all-apiurl': baseUrl,
      },
    };
  });
};
