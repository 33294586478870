import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { TextBuildingBlockGroup } from '@work4all/models/lib/Classes/TextBuildingBlockGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerField } from './EntityPickerField';

export const TextBuildingBlockGroupPickerField = React.forwardRef<
  HTMLDivElement,
  Omit<IPickerProps<TextBuildingBlockGroup, false>, 'multiple'>
>((props, ref) => {
  const { t } = useTranslation();
  return (
    <EntityPickerField
      ref={ref}
      label={t('COMMON.GROUP')}
      entity={Entities.textBuildingBlockGroup}
      overrides={{
        completeDataResponse: false,
      }}
      {...props}
    />
  );
});
