import styles from './SearchHistoryListItem.module.scss';

import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { WebsiteFavicon } from '@work4all/components';
import { Body2 } from '@work4all/components/lib/typography/body2/Body2';
import { Caption } from '@work4all/components/lib/typography/caption/Caption';

import { IListItemProps } from '@work4all/models/lib/Project';

export const SearchHistoryListItem: React.FC<
  IListItemProps & { website: string; isPrivateCustomer: boolean }
> = (props) => {
  const number: number = props.number
    ? typeof props.number === 'string'
      ? parseInt(props.number, 10)
      : props.number
    : undefined;

  const renderIcon = () => {
    if (props.website) {
      return <WebsiteFavicon website={props.website} />;
    }
    return props.isPrivateCustomer ? <PersonIcon /> : <HomeIcon />;
  };

  const renderSecondaryContent = () => {
    return (
      <>
        {number && number !== 0 && (
          <Caption className={styles['list-item__id']}>{number}</Caption>
        )}
        {props.category && (
          <Caption className={styles['list-item__category']}>
            {props.category}
          </Caption>
        )}
      </>
    );
  };

  return (
    <ListItem
      classes={{
        root: clsx(
          styles['list-item__item'],
          props.noPadding && styles['list-item__item--no-padding'],
          props.className
        ),
      }}
      divider={props.divider}
    >
      {props.website !== undefined && (
        <ListItemIcon>{renderIcon()}</ListItemIcon>
      )}
      <ListItemText
        classes={{
          root: styles['list-item__text'],
          primary: styles['list-item__title-wrapper'],
          secondary: styles['list-item__meta-wrapper'],
        }}
        primary={
          <Body2 className={styles['list-item__title']}>{props.name}</Body2>
        }
        secondary={renderSecondaryContent()}
      />
    </ListItem>
  );
};
