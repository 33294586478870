import { useEventCallback } from '@mui/material/utils';
import { compact, concat, minBy, range } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';
import { useBuildQuery } from '@work4all/data/lib/hooks/data-provider/hooks/useBuildQuery';

import { DataRequest } from '@work4all/models/lib/DataProvider';

import { invariant } from '@work4all/utils';
import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { Path, TableRow } from '../../../types';
import { useTableStateBag } from '../../useTableStateBag';
import { FetchMoreOptions, Filters, GroupedItems } from '../types';
import { mergeDataWithFooterData } from '../utils/merge-data-with-footer-data';
import { replaceRows } from '../utils/replaceRows';

import { useFetchGroupedRowFooterData } from './use-fetch-grouped-row-footer-data';
import { useFetchDefault } from './useFetchDefault';
import { useFetchGroupBy } from './useFetchGroupBy';

export const useGroupByData = (
  requestedData: DataRequest,
  pageSize: number,
  groupedBy: string[],
  getGroupKeyFields:
    | ((field: string) => {
        id: string;
        label: string;
      })
    | null,
  columns: string[],
  skip?: boolean,
  customFields?: ParsedCustomFieldConfig[],
  enableFooter = false
): {
  data: GroupedItems;
  total: 'rowsLength';
  isItemLoaded: (index: number, row: TableRow) => boolean;
  fetchMore: (
    startIndex: number,
    stopIndex: number,
    rows: TableRow[]
  ) => Promise<void>;
  refetch: () => Promise<void>;
} => {
  const tableBag = useTableStateBag();

  const { tableInstance } = tableBag;

  const createFetchGroupBy = useFetchGroupBy(
    requestedData,
    pageSize,
    groupedBy,
    getGroupKeyFields,
    customFields
  );

  const createFetchDefault = useFetchDefault(
    requestedData,
    pageSize,
    customFields
  );

  const fetchGroupedRowFooterData = useFetchGroupedRowFooterData({
    entity: requestedData.entity,
    columns,
  });

  const [data, setRows] = useState<GroupedItems>([]);

  const isItemLoaded = useCallback((_index: number, row: TableRow) => {
    return row != null && !row.original.skeleton;
  }, []);

  const [fetchOptions, setFetchOptions] = useState<FetchMoreOptions>(null);

  // Keep track of all fetchMore calls that are queued up and the respective row
  // indexes. We only want to fetch one range at a time.
  type FetchMoreQueueItem = { index: number; options: FetchMoreOptions };
  const fetchMoreOptionsQueue = useRef<FetchMoreQueueItem[]>([]);
  const fetchMoreTimeoutRef = useRef<number | null>(null);

  const queueFetchMore = useCallback(
    (index: number, options: FetchMoreOptions) => {
      fetchMoreOptionsQueue.current.push({ index, options });

      if (fetchMoreTimeoutRef.current === null) {
        fetchMoreTimeoutRef.current = window.setTimeout(() => {
          // Fetch more rows from top to bottom.
          //
          // Find the first requested range and fetch it. After the data is
          // fetched, the table will rerender and any not yet loaded rows on
          // screen will trigger another fetchMore call.
          try {
            const optionsList = fetchMoreOptionsQueue.current;
            fetchMoreOptionsQueue.current = [];

            const first = minBy(optionsList, (it) => it.index);
            if (!first) return;

            setFetchOptions(first.options);
          } finally {
            fetchMoreTimeoutRef.current = null;
          }
        }, 0);
      }
    },
    []
  );

  const fetchMore = useCallback(
    async (startIndex: number, _endIndex: number, rows: TableRow[]) => {
      invariant(
        Array.isArray(rows),
        'Invalid `fetchMore` call: `rows` should be an array.'
      );

      // Ignore fetchMore calls if we are currently refetching the list after a
      // mutation. After the refetch completes, fetchMore will be called again.
      if (refetchStateRef.current.running > 0) {
        return;
      }

      // If already fetching, ignore this call. After the table re-renders with
      // new items `fetchMore` will be called again if there are any skeleton
      // rows in the viewport.
      if (fetchOptions !== null) {
        return;
      }

      const firstRow = rows[startIndex];

      const {
        depth,
        index,
        original: {
          meta: { path },
        },
      } = firstRow;

      const filterCols = groupedBy.slice(0, depth);
      const filters: Filters = filterCols.map((col, i) => {
        /**
         * Path SHOULD always consist of "groupKey" values and "groupKey"
         * values should be used for filtering rows server-side.
         */
        const groupByVal = path[i];

        return {
          [col]: {
            $eq: groupByVal,
          },
        };
      });

      queueFetchMore(index, {
        path,
        filters,
        offset: index,
      });
    },
    [fetchOptions, groupedBy, queueFetchMore]
  );

  const handleFetchItems = useEventCallback(async (item: FetchMoreOptions) => {
    const { path, filters, offset } = item;

    if (path.length < groupedBy.length) {
      return createFetchGroupBy(item).fetchData();
    }

    const data = await createFetchDefault(item).fetchData();

    if (!enableFooter || data.items.length + offset !== data.total) {
      return data;
    }

    const footerData = (await fetchGroupedRowFooterData({
      filter: concat(...compact([requestedData.filter, filters])),
    })) as object;

    const dataWithFooterData = mergeDataWithFooterData({
      path,
      data,
      footerData,
    });

    return dataWithFooterData;
  });

  useEffect(() => {
    if (fetchOptions === null) return;

    let cancelled = false;

    const run = async () => {
      const { path, offset } = fetchOptions;
      try {
        const { items, total } = await handleFetchItems(fetchOptions);

        if (!cancelled) {
          setRows((rows) => replaceRows(rows, { path, offset, items, total }));
        }
      } finally {
        if (!cancelled) {
          setFetchOptions(null);
        }
      }
    };

    run();

    return () => {
      cancelled = true;
    };
  }, [handleFetchItems, fetchOptions]);

  const handleReset = useEventCallback(() => {
    refetchStateRef.current.controller?.abort();

    setRows([]);
    tableBag?.apiRef?.current?.toggleAllRowsExpanded(false);

    if (skip) {
      setFetchOptions(null);
    } else {
      setFetchOptions({ filters: [], path: [], offset: 0 });
    }
  });

  // TODO There is no actual need to build the query here. I'm just using it as
  // a simple way to detect changes to the config and reset the state when
  // needed. Can replace it later with a cheaper comparison function.
  const { variables } = useBuildQuery(requestedData, pageSize);
  const memoedVars = useDeepMemo(() => variables, [variables]);

  useEffect(handleReset, [handleReset, memoedVars, groupedBy, skip]);

  interface RefetchState {
    running: number;
    controller: AbortController | null;
  }

  const refetchStateRef = useRef<RefetchState>({
    running: 0,
    controller: null,
  });

  const refetch = useCallback(async () => {
    // Cancel the currently running refetch if there is one.
    refetchStateRef.current.controller?.abort();
    refetchStateRef.current.controller = new AbortController();

    // Keep track of rows expanded at the moment when we started the refetch.
    const { expanded } = tableInstance.state;

    const root = Symbol();

    function pathToKey(path: Path): string | symbol {
      if (path.length === 0) {
        return root;
      }

      return path.join('.');
    }

    interface RefetchRowsOptions extends Omit<FetchMoreOptions, 'offset'> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      signal: any; //AbortSignal; //toDo the AbortSignal typing is correct, but the used throwIfAborted is only existing in the typings since node 17.2+, and for some reason the nx build --prod does use older types
    }

    // Calculate how many pages we need to fetch for every group. Ignore
    // skeleton rows here.
    function countLoadedPagesByKey(rows: GroupedItems) {
      const loadedPagesByPath: Record<string | symbol, number> = {};

      function countRowsAndSubrows(rows: GroupedItems) {
        for (const row of rows) {
          if (row.isGrouped) {
            const subrows = row.subRows.filter((it) => !it.skeleton);

            const key = pathToKey(row.meta.path);
            const loadedPages = Math.ceil(subrows.length / pageSize);
            loadedPagesByPath[key] = loadedPages;

            countRowsAndSubrows(subrows);
          }
        }
      }

      loadedPagesByPath[root] = Math.ceil(
        rows.filter((it) => !it.skeleton).length / pageSize
      );

      countRowsAndSubrows(rows);

      return loadedPagesByPath;
    }

    const loadedPagesByPath = countLoadedPagesByKey(data);

    async function refetchRows(options: RefetchRowsOptions): Promise<{
      total: number;
      items: GroupedItems;
    }> {
      const { filters, path, signal } = options;

      const totalPages = loadedPagesByPath[pathToKey(path)];

      // If the current group depth is less than total group depth, we need to
      // use the groupBy query to load this group. Otherwise, its a normal list.
      const depth = path.length;
      const isGroup = depth < groupedBy.length;

      // Just refetch this group's rows using normal list query.

      if (!isGroup) {
        const newRows = await fetchPages({
          totalPages,
          pageSize,
          createFetch: createFetchDefault,
          filters,
          path,
        });

        signal.throwIfAborted();

        return newRows;
      }

      // Refetch this group's rows and its subrows.

      const newRows = await fetchPages({
        totalPages,
        pageSize,
        createFetch: createFetchGroupBy,
        filters,
        path,
      });

      signal.throwIfAborted();

      // If the row is a group row and is expanded, refetch it as well. Other
      // rows will be fetched as normal when you expand them again.
      const promises = newRows.items.map(async (row) => {
        if (
          row.skeleton ||
          !row.isGrouped ||
          !expanded[row.meta.path.join('.')] ||
          !loadedPagesByPath[pathToKey(row.meta.path)]
        ) {
          return row;
        }

        const subrows = await refetchRows({
          filters: [
            ...filters,
            { [groupedBy[depth]]: { $eq: row.meta.groupByVal } },
          ],
          path: row.meta.path,
          signal,
        });

        return {
          ...row,
          subRows: replaceRows(row.subRows, {
            offset: 0,
            items: subrows.items,
            total: subrows.total,
            path: row.meta.path,
          }),
        };
      });

      const newRowsWithSubrows = await Promise.all(promises);

      signal.throwIfAborted();

      return {
        total: newRows.total,
        items: newRowsWithSubrows,
      };
    }

    try {
      const controller = (refetchStateRef.current.controller =
        refetchStateRef.current.controller ?? new AbortController());
      refetchStateRef.current.running += 1;

      const { items, total } = await refetchRows({
        filters: [],
        path: [],
        signal: controller.signal,
      });

      const allNewGroupedRowIds = getAllGroupedRowIds(items);

      const expandedRowIds = Object.keys(tableInstance.state.expanded);
      const newExpandedRowIds = expandedRowIds.filter((id) => {
        return allNewGroupedRowIds.has(id);
      });

      tableInstance.toggleAllRowsExpanded(false);
      newExpandedRowIds.forEach((id) => {
        // The types are not correct here. A string is a valid argument.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        tableInstance.toggleRowExpanded(id as any, true);
      });

      setRows((rows) => {
        return replaceRows(rows, {
          path: [],
          offset: 0,
          items,
          total,
        });
      });
    } catch (error: unknown) {
      if (error instanceof Error && error.name === 'AbortError') {
        return;
      }

      throw error;
    } finally {
      refetchStateRef.current.running -= 1;

      if (refetchStateRef.current.running === 0) {
        refetchStateRef.current.controller = null;
      }
    }
  }, [
    tableInstance,
    createFetchDefault,
    createFetchGroupBy,
    data,
    groupedBy,
    pageSize,
  ]);

  return {
    data,
    total: 'rowsLength',
    fetchMore,
    isItemLoaded,
    refetch,
  };
};

interface FetchPagesOptions<T> extends Omit<FetchMoreOptions, 'offset'> {
  totalPages: number;
  createFetch: (params: FetchMoreOptions) => {
    fetchData: () => Promise<{
      items: T[];
      total: number;
    }>;
  };
  pageSize: number;
}

async function fetchPages<T>(options: FetchPagesOptions<T>) {
  const { totalPages, pageSize, createFetch, filters, path } = options;

  const requests = range(totalPages).map((page) => {
    const { fetchData } = createFetch({
      filters,
      path,
      offset: page * pageSize,
    });

    return fetchData();
  });

  const pages = await Promise.all(requests);

  const newRows = mergePages(pages);

  return newRows;
}

function mergePages<T>(pages: { items: T[]; total: number }[]) {
  return pages.reduce(
    (acc, cur) => {
      return {
        total: cur.total,
        items: [...acc.items, ...cur.items],
      };
    },
    { total: 0, items: [] }
  );
}

function getAllGroupedRowIds(rows: GroupedItems): Set<string> {
  const ids = new Set<string>();

  addIdsRecursively(rows);

  return ids;

  function addIdsRecursively(rows: GroupedItems): void {
    for (const row of rows) {
      if (row.isGrouped) {
        ids.add(row.meta.path.join('.'));

        if (row.subRows) {
          addIdsRecursively(row.subRows);
        }
      }
    }
  }
}
