import { UseFormReturn } from 'react-hook-form';

import { useFormUpdate } from '../../../utils/use-form-update';
import { UserFormValue } from '../types';

const updateDay = (input) => {
  if (!input) return {};
  return { timestampTargetMinutesPerDay: 0 };
};
const updateMaxDay = (input) => {
  if (!input) return {};
  return { maxWorkingHours: 0 };
};

export const useUserFormUpdate = (form: UseFormReturn<UserFormValue>) => {
  useFormUpdate(
    {
      supervisor: (supervisor) => ({ supervisorId: supervisor?.id || 0 }),
      supplier: (supplier) => ({ supplierId: supplier?.id || 0 }),
      vacationApprover: (vacationApprover) => ({
        vacationApproverId: vacationApprover?.id || 0,
      }),
      costCenter: (costCenter) => ({ costCenterId: costCenter?.id || 0 }),
      group: (group) => ({ objGrId: group?.id || 0 }),
      ressourceClass: (ressourceClass) => ({
        lookupId: ressourceClass?.id || 0,
      }),
      branch: (branch) => ({ departmentId: branch?.id || 0 }),
      defaultGroupForCustomer: (defaultGroupForCustomer) => ({
        standardGroupId: defaultGroupForCustomer?.id || 0,
      }),
      workTimeTrackingBreakRule: (workTimeTrackingBreakRule) => ({
        pauseId: workTimeTrackingBreakRule?.id || 0,
      }),
      salutation: (salutation) => ({
        salutationCode: salutation?.id || 0,
      }),
      dailyTargetWorkHoursMonday: updateDay,
      dailyTargetWorkHoursTuesday: updateDay,
      dailyTargetWorkHoursWednesday: updateDay,
      dailyTargetWorkHoursThursday: updateDay,
      dailyTargetWorkHoursFriday: updateDay,
      dailyTargetWorkHoursSaturday: updateDay,
      dailyTargetWorkHoursSunday: updateDay,
      maxWorkHoursMonday: updateMaxDay,
      maxWorkHoursTuesday: updateMaxDay,
      maxWorkHoursWednesday: updateMaxDay,
      maxWorkHoursThursday: updateMaxDay,
      maxWorkHoursFriday: updateMaxDay,
      maxWorkHoursSaturday: updateMaxDay,
      maxWorkHoursSunday: updateMaxDay,
      timestampTargetMinutesPerDay: (input) => {
        if (!input) return {};
        return {
          dailyTargetWorkHoursMonday: 0,
          dailyTargetWorkHoursTuesday: 0,
          dailyTargetWorkHoursWednesday: 0,
          dailyTargetWorkHoursThursday: 0,
          dailyTargetWorkHoursFriday: 0,
          dailyTargetWorkHoursSaturday: 0,
          dailyTargetWorkHoursSunday: 0,
        };
      },
      maxWorkingHours: (input) => {
        if (!input) return {};
        return {
          maxWorkHoursMonday: 0,
          maxWorkHoursTuesday: 0,
          maxWorkHoursWednesday: 0,
          maxWorkHoursThursday: 0,
          maxWorkHoursFriday: 0,
          maxWorkHoursSaturday: 0,
          maxWorkHoursSunday: 0,
        };
      },
    },
    form
  );
};
