import { Add, Delete, Poll } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { sortBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { HotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import {
  BasicTable,
  BasicTableColumn,
  DateCell,
  TableStateBagProvider,
} from '@work4all/components';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useNavigate, useUser } from '@work4all/data';
import { nameof } from '@work4all/data/lib/helper/nameof';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { canManageProjectProcess } from '@work4all/utils/lib/permissions';

import { ProjectPlanningPageSearchParamKeys } from '../../../../../../../containers/mask-overlays/mask-overlay/views/project-planning/ProjectPlanningPage';
import { useTableBorders } from '../../../../../../../utils/use-table-borders/use-table-borders';
import { useDeleteProjectProcess } from '../../hooks/use-delete-project-process';
import { ProjectProcessQuickCreateDialog } from '../project-planning-view/components/project-process-quick-create-dialog/ProjectProcessQuickCreateDialog';
import { ProjectProcessTemplate } from '../project-planning-view/components/project-process-quick-create-dialog/types';
import { useProjectWithProcesses } from '../project-planning-view/hooks/use-project-with-processes';

const keyMap = {
  DELETE: ['Delete'],
};

interface IProps {
  projectId: number;
}

export const ProjectProcessList = ({ projectId }: IProps) => {
  const { data: projectList, refetch } = useProjectWithProcesses([projectId]);

  const [tableInstance, setTableInstance] = useState<TableInstance>(null);

  const [selectedRows, setSelectedRows] = useState<Row<ProjectProcess>[]>();

  const project = projectList?.[0];

  const processes: ProjectProcess[] = sortBy(
    projectList?.[0]?.projectProcessList || [],
    'index'
  );

  const { t } = useTranslation();
  const tableBorders = useTableBorders();

  const { handleDelete } = useDeleteProjectProcess({
    ids: selectedRows?.map((x) => x.original.id),
    onCompleted: refetch,
  });

  const user = useUser();
  const userCanManageProjectProcess = canManageProjectProcess(user);

  const columns = useMemo<BasicTableColumn[]>(() => {
    return [
      {
        accessor: nameof<ProjectProcess>('process'),
        Header: t('COMMON.NAME'),
        width: 300,
      },
      {
        accessor: nameof<ProjectProcess>('startDatum'),
        Header: t('COMMON.START'),
        width: 75,
        Cell: DateCell,
      },
      {
        accessor: nameof<ProjectProcess>('endDateInner'),
        Header: t('COMMON.END'),
        width: 75,
        Cell: DateCell,
      },
    ];
  }, [t]);

  const rowModifiers = (value: ProjectProcess) => {
    return {
      isBold: value.kindId === ProjectProcessKind.GLIEDERUNGSPUNKT,
    };
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [projectProcessId, setProjectProcessId] = useState(null);
  const [projectProcessTemplate, setProjectProcessTemplate] =
    useState<ProjectProcessTemplate>(null);

  const onRowDoubleClick = useCallback(
    (id: string) => {
      if (userCanManageProjectProcess) {
        setProjectProcessId(id);
        setDialogOpen(true);
      }
    },
    [userCanManageProjectProcess]
  );

  const onAddBtnClick = useCallback(() => {
    setProjectProcessTemplate({
      projectId,
      project: project,
      customerId: project?.customerId,
      customer: project?.customer,
      supplierId: project?.supplierId,
      supplier: project?.supplier,
      kindId: ProjectProcessKind.VORGANG,
    });
    setDialogOpen(true);
  }, [project, projectId]);

  const navigate = useNavigate();
  const handleProjectPlanningClick = useCallback(() => {
    const pathname = '/more/project-planning';
    const search = new URLSearchParams({
      [ProjectPlanningPageSearchParamKeys.layout]: 'gantt',
      [ProjectPlanningPageSearchParamKeys.projectIds]: `${projectId}`,
    }).toString();

    navigate({ pathname, search });
  }, [navigate, projectId]);

  const handleClose = useCallback(() => {
    setDialogOpen(false);
    setProjectProcessId(null);
    /** setting timeout because of resetStore error */
    setTimeout(refetch);
  }, [refetch]);

  const keyHandlers = useMemo(
    () => ({
      DELETE: () => selectedRows.length && handleDelete(),
    }),
    [handleDelete, selectedRows?.length]
  );

  return (
    <>
      {dialogOpen && (
        <ProjectProcessQuickCreateDialog
          open={dialogOpen}
          onClose={handleClose}
          onOpenMask={() => null}
          template={projectProcessTemplate}
          id={projectProcessId}
        />
      )}
      <Stack direction="row" alignContent="center" gap="0.5rem" width="100%">
        <Divider
          title={t('COMMON.PROJECTPROCESS', { count: 2 })}
          style={{ width: '100%', padding: 0 }}
        />
        {userCanManageProjectProcess && (
          <>
            <Tooltip
              title={t('COMMON.DELETE', {
                name: t('COMMON.PROJECTPROCESS', {
                  count: selectedRows?.length || 1,
                }),
              })}
            >
              <IconButton
                onClick={handleDelete}
                color="primary"
                disabled={!selectedRows?.length}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip
              title={t('COMMON.ADD', { name: t('COMMON.PROJECTPROCESS') })}
            >
              <IconButton onClick={onAddBtnClick} color="primary">
                <Add />
              </IconButton>
            </Tooltip>
          </>
        )}

        <Tooltip title={t('PROJECT_PLANNING.LAYOUT.gantt')}>
          <IconButton color="primary" onClick={handleProjectPlanningClick}>
            <Poll style={{ transform: 'rotate(90deg)' }} />
          </IconButton>
        </Tooltip>
      </Stack>
      {processes.length ? (
        <TableStateBagProvider tableInstance={tableInstance}>
          <HotKeys keyMap={keyMap} handlers={keyHandlers}>
            <BasicTable
              columns={columns}
              data={processes}
              mode="client"
              hideHeaderTooltip
              classes={tableBorders}
              resizableColumns
              fitContentHeight
              prepareRowDisplayModifiers={rowModifiers}
              onRowDoubleClick={onRowDoubleClick}
              onSelectedRowsChange={setSelectedRows}
              selectableRows
              isVirtual={false}
              ref={setTableInstance}
            />
          </HotKeys>
        </TableStateBagProvider>
      ) : (
        <BaseActionButton onClick={handleProjectPlanningClick} icon={<Add />}>
          {t('MASK.ADD_TO')}
        </BaseActionButton>
      )}
    </>
  );
};
