import { CheckCircleRounded, Close, Send } from '@mui/icons-material';
import Button from '@mui/material/Button/Button';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useFormPlus } from '@work4all/data';

import { UserRights } from '@work4all/models/lib/Enums/UserRights.enum';

import { ControllerPlus } from '../../../containers/mask-overlays/form-plus/controller-plus';
import { ControlWrapper } from '../../../containers/mask-overlays/mask-overlay/components';
import { UserPickerField } from '../../entity-picker/UserPickerField';
import { ApprovalStatuses } from '../approval-actions';

import { ConfirmDialogData, ForwardConfirmDialogData } from './types';

type ReApprovalDialogProps = {
  open: boolean;
  status: ApprovalStatuses;
  onClose: () => void;
  onConfirm: (value: ConfirmDialogData | ForwardConfirmDialogData) => void;
};

const FORM_ID = 're-approval-status-comment';

export function ReApprovalDialog(props: ReApprovalDialogProps) {
  const { open, status, onClose, onConfirm } = props;

  const isForwarded = status === ApprovalStatuses.forward;

  const { t } = useTranslation();

  const defaultValues: ConfirmDialogData | ForwardConfirmDialogData =
    useMemo(() => {
      const baseValues = {
        comment: null,
      };
      if (isForwarded) {
        return {
          ...baseValues,
          user: null,
        };
      }
      return baseValues;
    }, [isForwarded]);

  const form = useFormPlus<ConfirmDialogData | ForwardConfirmDialogData>({
    defaultValues,
  });
  const { control, handleSubmit, register, watch } = form;
  const handleFormSubmit = handleSubmit(onConfirm);

  // @ts-expect-error lib version limitation https://github.com/react-hook-form/react-hook-form/issues/6679
  const user = watch('user');
  const comment = watch('comment');
  const disabled = isForwarded && (!user || !comment);

  const confirmIcon =
    status === ApprovalStatuses.approve ? (
      <CheckCircleRounded />
    ) : status === ApprovalStatuses.reject ? (
      <Close />
    ) : (
      <Send />
    );

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        title={t('APPROVAL_CENTER.STATUS', { context: status.toUpperCase() })}
        onClose={onClose}
      >
        <DialogContent>
          <form
            id={FORM_ID}
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            {status === ApprovalStatuses.forward && (
              <ControlWrapper>
                <ControllerPlus
                  control={control}
                  name="user"
                  render={({ field }) => (
                    <UserPickerField
                      {...field}
                      required
                      label={t('COMMON.USER')}
                      vars={{
                        filterUserRights: [
                          {
                            userRight: UserRights.FREIGABE_EINGANGSRECHNUNGEN,
                            state: true,
                          },
                        ],
                      }}
                    />
                  )}
                />
              </ControlWrapper>
            )}
            <ControlWrapper>
              <LabeledInput
                label={t('COMMON.COMMENT')}
                required={isForwarded}
                autoFocus
                multiline
                minRows={6}
                {...register('comment', { required: isForwarded })}
              />
            </ControlWrapper>
          </form>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {t('COMMON.ABORT')}
          </Button>
          <Button
            color="primary"
            type="submit"
            form={FORM_ID}
            startIcon={confirmIcon}
            disabled={disabled}
          >
            {t('APPROVAL_CENTER.STATUS', { context: status.toUpperCase() })}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
