import styles from './CustomListEntityPicker.module.scss';

import { Box } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

import { Tooltip } from '../../../tooltip/Tooltip';

interface ItemRowProps {
  content: ReactNode;
  title: string;
  isInteractive?: boolean;
  // TODO Remove - Not necessary and can be resolved with simple CSS combinators.
  prevWasInteractive?: boolean;
}

export const ItemRow = ({
  content,
  title,
  isInteractive,
  prevWasInteractive,
}: ItemRowProps) => {
  return (
    <Box height={'100%'}>
      <Tooltip title={title} hide={!isInteractive}>
        <div
          className={clsx(styles.row, {
            [styles.muted]: !isInteractive,
            [styles.firstMuted]: !isInteractive && prevWasInteractive,
          })}
        >
          {content}
        </div>
      </Tooltip>
    </Box>
  );
};
