import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

export const TicketPreviewFields: Ticket<EMode.query> = {
  id: null,
  title: null,
  creationDate: null,
  number: null,
  followUpDate: null,
  problemDescription: null,
  status1: null,
  editor1: {
    id: null,
    displayName: null,
    lastName: null,
    firstName: null,
  },
  attachmentList: [
    {
      id: null,
      fileName: null,
      displayName: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
      lastModificationDate: null,
    },
  ],
  projectId: null,
  projectProcessId: null,
  projectProcess: {
    id: null,
    process: null,
  },
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  ticketKind: {
    id: null,
    name: null,
  },
  solutionText: null,
};
