import React from 'react';

import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { TaxKey } from '@work4all/models/lib/Classes/TaxKey.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerField, EntityPickerFieldProps } from './EntityPickerField';

interface TaxKeyPickerFieldProps
  extends Omit<IPickerProps<TaxKey, false>, 'multiple' | 'value'>,
    Pick<EntityPickerFieldProps<TaxKey>, 'view' | 'label'> {
  value: TaxKey | null;
}

export const TaxKeyPickerField = React.forwardRef<
  HTMLDivElement,
  TaxKeyPickerFieldProps
>((props, ref) => {
  const { value, onChange, view = 'cell' } = props;
  return (
    <EntityPickerField
      ref={ref}
      entity={Entities.taxKey}
      filterBy="taxKeyValue"
      sortBy="taxKeyValue"
      view={view}
      overrides={{
        data: {
          id: null,
          taxKeyValue: null,
        },
        completeDataResponse: false,
        freeSolo: true,
        displayProperty: 'taxKeyValue',
      }}
      {...props}
      value={
        value
          ? {
              id: value.id,
              taxKeyValue: value.taxKeyValue,
            }
          : null
      }
      onChange={(value: TaxKey) => {
        if (value === undefined || value === null) return onChange(value);
        const taxKeyValue = parseInt(`${value.taxKeyValue}`);
        if (!Number.isNaN(taxKeyValue)) onChange({ id: value.id, taxKeyValue });
      }}
    />
  );
});
