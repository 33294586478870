import produce from 'immer';
import { useEffect, useState } from 'react';

import {
  AppointmentCalendarParams,
  AppointmentCalendarState,
  AppointmentCalendarView,
  AppointmentCalendarViewInit,
  AppointmentsGroupMode,
  CalendarPhase,
  CalendarProps,
} from '../types';

import { UseCalendarStateReturn } from './types';
import { useCalendarSettingsSync } from './use-calendar-settings-sync';
import { useCalendarViews } from './use-calendar-views';
import { useInitialUsersDataProvider } from './use-initial-users-data-provider';
import { useLastCalendarParams } from './use-last-calendar-params';
import { useUpdateLastCalendarParams } from './use-update-last-calendar-params';

export function useCalendarInMemoryState(): UseCalendarStateReturn {
  const { isReady: isSettingsStoreReady } = useCalendarSettingsSync();

  const { lastParams, setLastParams } = useLastCalendarParams();
  const { views, createView, deleteView } = useCalendarViews();

  const [phase, setPhase] = useState<CalendarPhase>('initial');
  const [params, setParams] = useState<AppointmentCalendarParams>(null);
  const [state, setState] = useState<AppointmentCalendarState>(null);

  // Update the last used params whenever calendar state changes.
  useUpdateLastCalendarParams({ state, setLastParams });

  // Initialize the calendar to the last used view.
  useEffect(() => {
    if (phase !== 'initial' || !isSettingsStoreReady) return;

    setParams({ ...lastParams, date: new Date() });
    setPhase('loading');
  }, [phase, isSettingsStoreReady, lastParams]);

  const users = useInitialUsersDataProvider({
    userIds: params?.userIds ?? [],
    disabled: phase !== 'loading' && phase !== 'reloading',
  });

  // After the users are loaded, initialize the calendar.
  useEffect(() => {
    if ((phase !== 'loading' && phase !== 'reloading') || users.loading) return;

    const { userIds: _userIds, ...state } = params;

    setState({ ...state, users: users.data });
    setPhase('ready');
  }, [phase, users, params]);

  function updateState<T extends keyof typeof state>(property: T) {
    return (value: AppointmentCalendarState[T]) => {
      setState((state) => ({ ...state, [property]: value }));
    };
  }

  const onUsersChange = updateState('users');
  const onFocusedUserIdsChange = updateState('focusedUserIds');
  const onDateChange = updateState('date');
  const onRangeChange = updateState('range');
  const onPlanningAppointmentsChange = updateState('planningAppointments');
  const onGroupModeChange = (groupMode: AppointmentsGroupMode) => {
    setState(
      produce((draft) => {
        draft.groupMode = groupMode;

        if (groupMode === 'vertical' && draft.range === 'day') {
          draft.range = 'week-5';
        }
      })
    );
  };
  const onAppointmentStatesChange = updateState('appointmentStates');

  const onSelectView = (view: AppointmentCalendarView): void => {
    setParams({ ...view.params, date: new Date() });
    setPhase('reloading');
  };

  const onCreateView = (name: string): void => {
    const {
      users,
      focusedUserIds,
      range,
      groupMode,
      appointmentStates,
      planningAppointments,
    } = state;

    const init: AppointmentCalendarViewInit = {
      name,
      params: {
        userIds: users.map((user) => user.id),
        focusedUserIds,
        range,
        groupMode,
        appointmentStates,
        planningAppointments,
      },
    };

    createView(init);
  };

  const onDeleteView = (view: AppointmentCalendarView): void => {
    deleteView(view.id);
  };

  const getCalendarProps = () => {
    const props: Omit<CalendarProps, 'onOpenMask'> = {
      loading: phase === 'reloading',
      ...state,
      onUsersChange,
      onFocusedUserIdsChange,
      onDateChange,
      onRangeChange,
      onGroupModeChange,
      onAppointmentStatesChange,
      views,
      onSelectView,
      onCreateView,
      onDeleteView,
      onPlanningAppointmentsChange,
    };

    return props;
  };

  const isReady = state !== null;

  if (isReady === false) {
    return { isReady };
  }

  return { isReady, getCalendarProps };
}
