import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GrouPickerItem, GroupPicker } from '../GroupPicker';

interface UserStatusPickerProps {
  value: GrouPickerItem<boolean>[] | null;
  onChange: (value: GrouPickerItem<boolean>[]) => void;
  multiple?: boolean;
}

export function UserStatusPicker(props: UserStatusPickerProps) {
  const { value, onChange, multiple } = props;

  const { t } = useTranslation();

  const groups = useMemo(() => {
    return [
      {
        id: false,
        name: t('COMMON.ACTIVE'),
      },
      {
        id: true,
        name: t('FIELDS.inactive'),
      },
    ];
  }, [t]);

  return (
    <GroupPicker<boolean>
      value={value}
      onChange={onChange}
      groups={groups}
      multiple={multiple}
    />
  );
}
