import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { SyncOptionsContactExport } from '../Enums/SyncOptionsContactExport.enum';

export class UserSyncOptions<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: userCode */
  userCode?: number;
  /** Alias: contactsExportAllowed */
  contactsExportAllowed?: SyncOptionsContactExport;
  /** Alias: contactsExportMode */
  contactsExportMode?: SyncOptionsContactExport;
  /** Alias: contactsLastSyncDate */
  contactsLastSyncDate?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: contactsExportCustomer */
  contactsExportCustomer?: boolean;
  /** Alias: contactsExportSupplier */
  contactsExportSupplier?: boolean;
  /** Alias: contactsExportEmployees */
  contactsExportEmployees?: boolean;
  /** Alias: appointmentsSync */
  appointmentsSync?: boolean;
  /** Alias: appointmentsAllowPrivate */
  appointmentsAllowPrivate?: boolean;
  /** Alias: appointmentsSyncExternalAppointments */
  appointmentsSyncExternalAppointments?: boolean;
  /** Alias: mailboxId */
  mailboxId?: string;
  /** Alias: contactsIcludePrivateData */
  contactsIcludePrivateData?: boolean;
  /** Alias: appointmentsSyncPrivateItems */
  appointmentsSyncPrivateItems?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<UserSyncOptions> = {
  id: {
    alias: 'id',
  },
  userCode: {
    alias: 'userCode',
  },
  contactsExportAllowed: {
    alias: 'contactsExportAllowed',
  },
  contactsExportMode: {
    alias: 'contactsExportMode',
  },
  contactsLastSyncDate: {
    alias: 'contactsLastSyncDate',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  contactsExportCustomer: {
    alias: 'contactsExportCustomer',
  },
  contactsExportSupplier: {
    alias: 'contactsExportSupplier',
  },
  contactsExportEmployees: {
    alias: 'contactsExportEmployees',
  },
  appointmentsSync: {
    alias: 'appointmentsSync',
  },
  appointmentsAllowPrivate: {
    alias: 'appointmentsAllowPrivate',
  },
  appointmentsSyncExternalAppointments: {
    alias: 'appointmentsSyncExternalAppointments',
  },
  mailboxId: {
    alias: 'mailboxId',
  },
  contactsIcludePrivateData: {
    alias: 'contactsIcludePrivateData',
  },
  appointmentsSyncPrivateItems: {
    alias: 'appointmentsSyncPrivateItems',
  },
  __typename: {
    alias: '__typename',
  },
};

export const userSyncOptionsEntityDefinition: EntitiyDefinition<UserSyncOptions> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'UserSyncOptions',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
