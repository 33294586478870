import styles from './TopBar.module.scss';

import { ArrowDropDown } from '@mui/icons-material';
import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '@work4all/assets/icons/outline-arrow-24.svg';

import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { IUser, useNavigate } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import {
  canApproveVacations,
  canViewInboundInvoicesApprovalCenter,
} from '@work4all/utils/lib/permissions';

import { ApprovalSource } from '../../ApprovalPage';

type TopBarProps = {
  view: ApprovalSource;
  user: IUser;
};

export const TopBar = ({ view, user }: TopBarProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();
  const viewParam = params['*']?.split('/')[0];

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const canOpenReApprovalCenter = canViewInboundInvoicesApprovalCenter(user);
  const canOpenVacationApprovalCenter = canApproveVacations(user);

  const { handleClick, ...menuState } = usePopoverState('bottom');

  const handleSourceChange = (source: ApprovalSource) => {
    menuState.onClose();
    navigate({ pathname: `/more/approval/${source}` });
  };

  return (
    <>
      <Menu {...menuState}>
        {canOpenReApprovalCenter && (
          <MenuItem
            key={ApprovalSource.reViewModel}
            onClick={() => handleSourceChange(ApprovalSource.reViewModel)}
          >
            {t(`APPROVAL_CENTER.TOP_BAR.REVIEWMODEL`)}
          </MenuItem>
        )}
        {canOpenVacationApprovalCenter && (
          <MenuItem
            key={ApprovalSource.vacation}
            onClick={() => handleSourceChange(ApprovalSource.vacation)}
          >
            {t(`APPROVAL_CENTER.TOP_BAR.VACATION`)}
          </MenuItem>
        )}
      </Menu>
      <div className={styles.root}>
        <div className={styles.left}>
          <IconButton
            onClick={() => {
              navigate(-1);
            }}
            className={styles.goBackButton}
            size="large"
          >
            <ArrowIcon />
          </IconButton>
          <Stack direction="row" gap="0.5rem" alignItems="center">
            {t('APPROVAL_CENTER.TITLE')}
            <span style={{ color: 'var(--text03)' }}>|</span>
          </Stack>
          <BaseActionButton onClick={handleClick}>
            <Stack
              direction="row"
              style={{ color: 'var(--text03)' }}
              alignContent="center"
              alignItems="center"
              fontSize="1.25rem"
            >
              <Typography variant={isMobile ? 'h4' : 'h3'} fontWeight="bold">
                {t(
                  `APPROVAL_CENTER.TOP_BAR.${(
                    view || viewParam
                  )?.toUpperCase()}`
                )}
              </Typography>
              <ArrowDropDown />
            </Stack>
          </BaseActionButton>
        </div>
      </div>
    </>
  );
};
