import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';

import { useDataMutation } from '@work4all/data';

import { InputCrmAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputCrmAnhangAttachementsRelation.entity';
import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LetterTemplateType } from '@work4all/models/lib/Enums/LetterTemplateType.enum';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { DocumentTemplateContent } from './DocumentTemplateContent';
import { DocumentTemplateFormValue } from './DocumentTemplateFormValue';
import { useDocumentTemplateRequest } from './use-document-template-request';

const newEntityData: DocumentTemplateFormValue = {};

export const DocumentTemplateOverlayController = (
  props: MaskControllerProps
) => {
  const { t } = useTranslation();
  const mask = useMaskConfig(props);
  const request = useDocumentTemplateRequest({
    id: mask.id,
    entity: mask.entity,
  });

  const customRules = useCallback(
    (data) => {
      if (!data.title) {
        return {
          title: {
            message: t('ERROR.FIELD_REQUIRED'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const getTempFileInitialData = useCallback(
    (data: DocumentTemplateFormValue) => {
      return data?.fileInfos?.previewMimeType
        ? [{ ...data, fileName: data.templateFilename }]
        : [];
    },
    []
  );

  const overlay = useMaskOverlay<DocumentTemplateFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    getSubTitle: (x) => x.title,
    getTempFileInitialData,
    customRules,
  });

  const attachementsRelation =
    useAttachementsRelation<InputCrmAnhangAttachementsRelation>(
      overlay.tempFileManager,
      mask.entity,
      'id'
    );

  const showExistDialog = useRef(false);
  const [mutate] = useDataMutation<
    WordDocumentTemplate | WordLetterTemplate,
    EMode.upsert
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: (data) => {
      props.onAfterSave(data);
    },
    onError: (error) => {
      showExistDialog.current = error.graphQLErrors.some(
        (x) => x.extensions.code === 'DocumentLetterTemplate_FileAlreadyExists'
      );
    },
  });

  const getAttachement = () => {
    if (attachementsRelation?.attachements?.add?.length) {
      return {
        templateFilename: attachementsRelation.attachements.add[0].name,
        relations: {
          overwriteExistingFile: false,
          tempFileId: attachementsRelation.attachements.add[0].tempFileId,
        },
      };
    } else if (attachementsRelation?.attachements?.remove?.length) {
      return {
        templateFilename: null,
        relations: {
          overwriteExistingFile: false,
          tempFileId: null,
        },
      };
    }
    return {};
  };
  const dialogs = useDialogs();

  const onSubmit = async (
    formData: DocumentTemplateFormValue,
    overwriteExistingFile?: boolean
  ) => {
    const toModify = pickUpdateFields(
      formData,
      overlay.form.formState.dirtyFields
    );
    toModify.groupId = toModify.templateGroup?.id;
    toModify.templateType = mapEntityToLetterTemplateType(mask.entity);

    const { templateFilename, relations } = getAttachement();
    if (relations || templateFilename) {
      toModify.templateFilename = templateFilename;
      relations.overwriteExistingFile = overwriteExistingFile;
      await mutate(toModify, {
        relations,
      });
      if (showExistDialog.current) {
        showExistDialog.current = false;
        const submitAgain = await dialogs.confirm({
          title: t('ALERTS.FILE_EXIST'),
          description: t('ALERTS.OVERWRITE_FILE'),
        });
        if (submitAgain) {
          onSubmit(formData, true);
        }
      }
    } else mutate(toModify);
  };

  const onDropRejected = useCallback(() => {
    dialogs.alert({
      title: t('EMAIL.STATUSES.error'),
      description: t('ERROR.WRONG_FILE_EXTENSION_BY_TYPE', {
        files: '.pdf, .doc/.docx or .xls/.xlsx',
      }),
    });
  }, [t, dialogs]);

  return (
    <OverlayController<DocumentTemplateFormValue>
      {...overlay}
      onSubmit={onSubmit}
    >
      <AttachmentsDropZone
        single
        onDropRejected={onDropRejected}
        accept={{
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            ['.docx'],
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx',
          ],
        }}
      >
        <DocumentTemplateContent onFileRejected={onDropRejected} />
      </AttachmentsDropZone>
    </OverlayController>
  );
};

const mapEntityToLetterTemplateType = (entity: Entities) => {
  switch (entity) {
    case Entities.wordLetterTemplate:
      return LetterTemplateType.LETTER;
    case Entities.wordDocumentTemplate:
      return LetterTemplateType.DOCUMENT;
    default:
      throw new Error(
        `There is no mapping for ${entity}. (mapEntityToLetterTemplateType)`
      );
  }
};
