import styles from './RequiredTimeRangePicker.module.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebouncedCallback } from '@work4all/utils/lib/hooks/use-debounce-callback';

import { LabeledInput } from '../../../../input/labeled-input';
import { MultiStepControls, Step } from '../../../../input/multi-step-controls';

interface IRequiredTimeRangePickerProps {
  from?: number;
  to?: number;
  onChange: (from: number, to: number) => void;
  convertTo?: 'hours' | 'days';
}

export const RequiredTimeRangePicker: React.FC<
  IRequiredTimeRangePickerProps
> = (props) => {
  const { t } = useTranslation();
  const { from: fromProp, to: toProps, onChange, convertTo } = props;

  const [from, setFrom] = useState(fromProp);
  const [to, setTo] = useState(toProps);

  const debouncedOnChange = useDebouncedCallback(onChange, 500);

  return (
    <div className={styles.pickerWrap}>
      <MultiStepControls>
        <Step active={true} index={0}>
          <LabeledInput
            value={formatValue(from, convertTo)}
            label={t('COMMON.FROM')}
            type="number"
            onChange={(e) => {
              const from = parseValue(e.target.value, convertTo);
              setFrom(from);
              debouncedOnChange(from, to);
            }}
            onClear={() => {
              setFrom(null);
              onChange(null, to);
            }}
          />
        </Step>
        <Step active={true} index={1}>
          <LabeledInput
            value={formatValue(to, convertTo)}
            label={t('COMMON.TO')}
            type="number"
            onChange={(e) => {
              const to = parseValue(e.target.value, convertTo);
              setTo(to);
              debouncedOnChange(from, to);
            }}
            onClear={() => {
              setTo(null);
              onChange(from, null);
            }}
          />
        </Step>
      </MultiStepControls>
    </div>
  );
};

function formatValue(value: number | null, format: 'hours' | 'days'): string {
  if (value == null || Number.isNaN(value)) {
    return '';
  }

  switch (format) {
    case 'hours':
      return (value * 8).toString();
    default:
      return value.toString();
  }
}

function parseValue(value: string, format: 'hours' | 'days'): number | null {
  if (value == null || value === '') {
    return null;
  }

  const parsed: number = parseFloat(value);

  if (Number.isNaN(parsed)) {
    return null;
  }

  switch (format) {
    case 'hours':
      return parsed / 8;
    default:
      return parsed;
  }
}
