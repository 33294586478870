import { useEffect, useState } from 'react';

import { application } from '@work4all/components/lib/hooks/object-lock/SessionId';
import { useLockObject } from '@work4all/components/lib/hooks/object-lock-subscription/LockObjectProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

interface UseMaskLockParams {
  entity: Entities;
  id?: string | number | null;
}

/**
 * Automatically manages the lock state within a mask.
 *
 * Will lock the entity on mount and unlock on unmount. Will ignore masks opened
 * in "create" mode.
 *
 * @example
 * // Just pass the object returned by useMaskConfig for simplicity.
 * const mask = useMaskConfig(props);
 * const lock = useMaskLock(mask);
 *
 * // Or only pass entity type and id explicitly.
 * const mask = useMaskConfig(props);
 *
 * const { entity, id } = mask;
 *
 * const lock = useMaskLock({ entity, id });
 */
export function useMaskLock(params: UseMaskLockParams) {
  const { entity, id } = params;

  const objectLockContext = useLockObject();
  // The lock is initialized to {loading: false, locked: false} on the initial
  // render. Keep track of its initialized state so don't render the mask until
  // we know if the lock has been acquired successfully.
  const [isLoading, setIsLoading] = useState(true);
  const locker = objectLockContext.getLock(id, entity);
  const sessionOwnAllLocks = locker.sessionId === application;
  const isLocked = sessionOwnAllLocks ? false : locker?.locked;

  useEffect(() => {
    if (objectLockContext.lockData[entity]) setIsLoading(false);
  }, [objectLockContext.lockData, entity]);

  return { isLoading, isLocked };
}
