import styles from './GeneralTabPanel.module.scss';

import { Box } from '@mui/material';
import { useContext } from 'react';

import { Card } from '@work4all/components/lib/dataDisplay/card';
import { LockContext } from '@work4all/components/lib/hooks';

import { Panel } from '../../../../../components/panel/Panel';

import { Document } from './components/document/Document';

export const GeneralTabPanel = () => {
  const { locked } = useContext(LockContext);

  return (
    <Panel className={styles.panel}>
      <Box className={styles.container}>
        <Card className={styles.card} noPadding data-theme="light">
          <Document disabled={locked} />
        </Card>
      </Box>
    </Panel>
  );
};
