import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

export type ArticleKindPickerProps<TMultiple extends boolean> = IPickerProps<
  { id: string; name?: string },
  TMultiple
>;

const OPTIONS = [
  ArticleKind.NORMAL,
  ArticleKind.TAETIGKEIT_FUER_ZEITERFASSUNG,
  ArticleKind.STUECKLISTE,
];

export function ArticleKindPicker<TMultiple extends boolean>(
  props: ArticleKindPickerProps<TMultiple>
) {
  const { t } = useTranslation();

  const data = useMemo(() => {
    return OPTIONS.map((key) => {
      return {
        id: key,
        name: t(`ARTICLE_KIND.LONG.${key}`),
      };
    }).sort((a, b) => a.name.localeCompare(b.name));
  }, [t]);

  return <FixedDataPicker dataSet={data} {...props} />;
}
