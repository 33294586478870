import styles from './FlagCell.module.scss';

import { IconButton } from '@mui/material';
import clsx from 'clsx';

import { FlagIcon } from '../../../../components/flag-icon/FlagIcon';

type IFlagCellProps = {
  value: boolean;
  column: {
    cellParams: {
      onClick: (id: string | number, isFavorite: boolean) => void;
    };
  };
  row: {
    id: string | number;
  };
};

export const FlagCell = (props: IFlagCellProps) => {
  const {
    value,
    column: { cellParams },
    row: { id },
  } = props;

  return (
    <IconButton
      className={clsx(styles.button, { [styles.show]: value })}
      onClick={() => {
        cellParams.onClick(id, value);
      }}
    >
      <FlagIcon isFlagged={value} />
    </IconButton>
  );
};
