export const layoutDataQuery = (
  queryInner: string,
  variables?: Record<string, unknown>,
  filterByFavoriteItemsOnly = false
) => {
  const defaultVariablesWithVariables = {
    queryPage: 0,
    rewriteInlineAttachements: false,
    categoryCodes: null,
    querySortBy: null,
    querySortOrder: null,
    addFavSort: null,
    onlyFavItems: null,
    ...variables,
  };

  if (filterByFavoriteItemsOnly) {
    defaultVariablesWithVariables['onlyFavItems'] = true;
  }

  Object.keys(defaultVariablesWithVariables).forEach((name) => {
    let value = defaultVariablesWithVariables[name];

    if (
      name === 'querySortOrder' ||
      name === 'querySortBy' ||
      name === 'addFavSort' ||
      (name === 'onlyFavItems' && !value)
    ) {
      queryInner = queryInner.replace(
        new RegExp(`,?\\s*${name}:\\s*\\$${name}\\s*`),
        ''
      );
      return;
    }

    if (typeof value === 'string') {
      value = `"${value.replace(/"/g, '\\"')}"`;
    } else if (name === 'querySortByEx') {
      value = `${JSON.stringify(value)
        .replace(/"(\w+)"(?=:)/g, '$1')
        .replace(/(?<=sortOrder:\s*)"([^"]*)"/g, '$1')}`;
    }

    queryInner = queryInner.replace(`$${name}`, value);
  });

  return queryInner;
};
