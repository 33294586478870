import { useEffect, useRef } from 'react';

export const useActiveInterval = (callback: () => void, interval = 10000) => {
  const intervalIdRef = useRef(null);

  useEffect(() => {
    const startInterval = () => {
      if (!intervalIdRef.current) {
        intervalIdRef.current = setInterval(callback, interval);
      }
    };

    const stopInterval = () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };

    const onFocus = () => {
      callback();
      startInterval();
    };

    const onBlur = () => {
      stopInterval();
    };

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    if (document.hasFocus()) {
      startInterval();
    }

    return () => {
      stopInterval();
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, [callback, interval]);
};
