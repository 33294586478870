import Typography from '@mui/material/Typography';
import React from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from '../components/list-entity-picker';
import { IPickerProps } from '../types';

export type IEnumPickerProps<
  TMultiple extends boolean,
  T = { id: string | number; name: string }
> = IPickerProps<T, TMultiple> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataSet: any[];
  renderItemContent?: (value) => React.ReactNode;
  sortData?: boolean;
};

export function FixedDataPicker<
  TMultiple extends boolean,
  T extends { id?: string | number; name?: string } = {
    id: string | number;
    name: string;
  }
>(props: IEnumPickerProps<TMultiple, T>) {
  const { dataSet, sortData, ...rest } = props;

  const fixedDataSet = {
    total: dataSet.length,
    loading: false,
    fetchMore: () => Promise.resolve(),
    refetch: () => Promise.resolve(null),
    data: dataSet,
  };

  return (
    <ListEntityPicker
      entity={Entities.customer}
      multiple={props.multiple}
      layout="simple"
      maxItems={dataSet.length}
      data={FIELDS}
      filterBy="name"
      sortBy="name"
      clearable={props.clearable}
      fixedDataSet={fixedDataSet}
      renderItemContent={(status) => (
        <Typography variant="body2" noWrap>
          {status.name}
        </Typography>
      )}
      sortFixedData={sortData}
      {...rest}
    />
  );
}

const FIELDS = {
  id: null,
  name: null,
};
