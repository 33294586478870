import styles from './PickerTargetButton.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import { forwardRef } from 'react';

export interface PickerTargetButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  layout?: 'table' | 'cards' | 'board';
}

export const PickerTargetButton = forwardRef<
  HTMLButtonElement,
  PickerTargetButtonProps
>(({ layout, ...props }, ref) => (
  <button
    ref={ref}
    {...props}
    type="button"
    className={clsx(styles.pickerButton, {
      [styles.cardPickerButton]: layout === 'cards',
    })}
  >
    <span className={styles.pickerButtonContent}>{props.children}</span>
    <ArrowDropDownIcon className={styles.pickerButtonDropdownIcon} />
  </button>
));
