import { Badge } from '@mui/material';
import { DateTime } from 'luxon';
import { Trans, useTranslation } from 'react-i18next';

import { UserIcon } from '@work4all/components/lib/components/user-icon/UserIcon';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { NotificationDate } from '../components/NotificationDate';
import { NotificationIcon } from '../components/NotificationIcon';
import { NotificationLayout } from '../components/NotificationLayout';
import { NotificationText } from '../components/NotificationText';
import { NotificationTitle } from '../components/NotificationTitle';
import { NotificationListItemApprovalProps } from '../NotificationListItemApproval';

import { resolveApprovalNotificationData } from './utils/resolve-approval-notification-data';

export function NotificationListItemVacationApproval(
  props: NotificationListItemApprovalProps
) {
  const { className, style, notification, onClick, count = 1 } = props;

  const { t } = useTranslation();

  const { refObject, creator, approver, newCreated, isApproved, isRejected } =
    resolveApprovalNotificationData(
      notification.object,
      ObjectType.VACATION_REQUEST
    );

  const parentObject = refObject as VacationRequestEntity;
  const dateFrom = parentObject?.dateFrom
    ? DateTime.fromISO(parentObject?.dateFrom).toLocaleString(
        DateTimeCustom.DATE_SHORT
      )
    : '';
  const dateTo = parentObject?.dateTo
    ? DateTime.fromISO(parentObject?.dateTo).toLocaleString(
        DateTimeCustom.DATE_SHORT
      )
    : '';
  const date = dateFrom && dateTo ? `${dateFrom} - ${dateTo}` : '';

  return (
    <NotificationLayout
      className={className}
      style={style}
      unread={!notification.isRead}
      onClick={onClick}
    >
      <NotificationIcon>
        <Badge
          badgeContent={count.toString()}
          color={notification.isRead ? 'secondary' : 'primary'}
          invisible={count <= 1}
        >
          {notification.fromUser ? (
            <HookedUserIcon userId={notification.fromUser.id} />
          ) : (
            <UserIcon
              shortName="?"
              bgColor="var(--brand01)"
              fontColor="white"
            />
          )}
        </Badge>
      </NotificationIcon>
      <NotificationTitle unread={!notification.isRead}>
        {newCreated
          ? t('MORE.APPROVAL_CENTER.VACATION')
          : t('COMMON.VACATIONREQUEST')}
      </NotificationTitle>
      <NotificationText>
        <div>
          {newCreated &&
            t(`NOTIFICATION.APPROVAL.VACATION.NEW_REQUEST`, {
              user: creator?.displayName,
            })}
          {isApproved && (
            <Trans
              i18nKey={'NOTIFICATION.APPROVAL.VACATION.APPROVED'}
              values={{
                user: approver?.displayName,
              }}
            />
          )}
          {isRejected && (
            <Trans
              i18nKey={'NOTIFICATION.APPROVAL.VACATION.REJECTED'}
              values={{
                user: approver?.displayName,
              }}
            />
          )}
        </div>
        <div>
          {date && t('NOTIFICATION.APPROVAL.VACATION.PERIOD', { date })}
        </div>
      </NotificationText>

      <NotificationDate date={notification.insertTime} />
    </NotificationLayout>
  );
}
