import { useEventCallback } from '@mui/material/utils';
import { omit } from 'lodash';
import { useMemo } from 'react';

import {
  useDataMutation,
  useDataProvider,
  useUser as useUserContext,
} from '@work4all/data';

import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const USER_DATA: User = {
  id: null,
  allowToUseOwnPrivateData: null,
};

const SYNC_OPTIONS_DATA: User | FullUser = {
  id: null,
  syncOptions: [
    {
      id: null,
      mailboxId: null,
      contactsExportCustomer: null,
      contactsExportSupplier: null,
      contactsExportEmployees: null,
      contactsExportMode: null,
      contactsIcludePrivateData: null,
      appointmentsSync: null,
      appointmentsSyncPrivateItems: null,
      contactsExportAllowed: null,
    },
  ],
};

type Props = {
  mailboxId: string;
};

const useUserDataAndMutation = () => {
  const userContext = useUserContext();

  const request = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      data: USER_DATA,
      filter: [
        {
          id: { $eq: userContext.benutzerCode },
        },
      ],
    };
  }, [userContext.benutzerCode]);

  const { data } = useDataProvider<User>(request);

  const user = useMemo(() => data[0] ?? {}, [data]);

  const [mutate] = useDataMutation({
    entity: Entities.user,
    mutationType: EMode.upsert,
    responseData: USER_DATA,
  });

  return [user, mutate] as const;
};

const useSyncOptionsDataAndMutation = ({ mailboxId }: Props) => {
  const userContext = useUserContext();
  const entity = userContext.isMaster ? Entities.fullUser : Entities.user;

  const request = useMemo<DataRequest>(() => {
    return {
      entity,
      data: SYNC_OPTIONS_DATA,
      filter: [
        {
          id: { $eq: userContext.benutzerCode },
        },
      ],
    };
  }, [entity, userContext.benutzerCode]);

  const { data } = useDataProvider<User>(request);

  const syncOptions = useMemo(
    () =>
      data[0]?.syncOptions?.find(
        (syncOption) => syncOption.mailboxId === mailboxId
      ) ?? {},
    [data, mailboxId]
  );

  const [mutate] = useDataMutation({
    entity,
    mutationType: EMode.upsert,
    responseData: SYNC_OPTIONS_DATA,
  });

  return [syncOptions, mutate] as const;
};

export const useUserMailboxExchangeSynchronizationData = ({
  mailboxId,
}: Props) => {
  const userContext = useUserContext();
  const [user, mutateUser] = useUserDataAndMutation();
  const [syncOptions, mutatSyncOptions] = useSyncOptionsDataAndMutation({
    mailboxId,
  });

  const mutate = useEventCallback(
    (key: string, value: unknown, isSyncOptions: boolean) => {
      if (isSyncOptions) {
        const data = {
          ...omit(syncOptions, ['contactsExportAllowed', '__typename']),
          mailboxId,
          [key]: value,
        };

        if (userContext.isMaster && key === 'contactsExportMode') {
          data['contactsExportAllowed'] = value;
        }

        return mutatSyncOptions(
          { id: userContext.benutzerCode },
          {
            relations: {
              syncOptions: {
                addModify: [data],
              },
            },
          }
        );
      }

      return mutateUser({ id: userContext.benutzerCode, [key]: value });
    }
  );

  return { user, syncOptions, mutate };
};
