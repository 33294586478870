import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputChecklistPositionRelation } from './InputChecklistPositionRelation.entity';

export class InputChecklisteRelation<T extends EMode = EMode.entity> {
  /** Alias: positions */
  positions?: InputChecklistPositionRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputChecklisteRelation> = {
  positions: {
    alias: 'positions',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputChecklisteRelationEntityDefinition: EntitiyDefinition<InputChecklisteRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputChecklisteRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
