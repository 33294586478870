import React from 'react';
import {
  Column,
  ColumnInstance as ReactTableColumnInstance,
  ColumnInterfaceBasedOnValue,
  Row,
  TableExpandedToggleProps,
} from 'react-table';

import { GroupLabelElementParams } from '@work4all/models/lib/table-schema/table-schema';

import { Breakpoint } from '@work4all/utils/lib/variables';

import { ISelectionItem } from '../../input/filter-components/search-and-select';

export type Alignment = 'left' | 'right' | 'center';

export interface ICssClasses {
  headerWrapper?: string;
  headerRow?: string;
  headerCellHeading?: string;
  headerSeperator?: string;
  footerWrapper?: string;
  footerRow?: string;
  footerSeperator?: string;
  row?: string;
  cell?: string;
  selection?: string;
  headerCellRoot?: string;
  tableBody?: string;
}

export interface GlobalFilter {
  popoverFilter: FilterValue;
  defaultFilter: unknown;
}

export interface ITableCell {
  isGrouped?: boolean;
  isExpanded?: boolean;
  subRowsLength?: number;
  expandedToggleProps?: TableExpandedToggleProps;
  noPaddingLeft?: boolean;
  noPaddingRight?: boolean;
  dataType?: DataType;
  alignment?: Alignment;
  noFlex?: boolean;
}

export interface ITableRowComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  isSelected?: boolean;
  className?: string;
  isGrouped?: boolean;
  displayModifiers?: TableRowDisplayModifiers;
  cardsView?: boolean;
  groupLevel?: number;
}

export type Path = Array<null | string>;

export interface Meta {
  path: Path;
  groupByID?: string;
  groupByVal?: string;
  groupByLabel?: string;
  groupByTotalCount?: number;
  isSectionStart?: boolean;
  sectionLabel?: string;
  isGroupFooter?: boolean;
}

export interface IRow {
  skeleton?: boolean;
  meta: Meta;
  isGrouped?: boolean;
}

export type TableRow = Row<IRow> & {
  displayModifiers?: TableRowDisplayModifiers;
};

export enum FilterType {
  ClosedStatus = 'closedStatus',
  Date = 'date',
  Number = 'number',
  Search = 'search',
  Picker = 'picker',
  EmailKind = 'emailKind',
  Boolean = 'boolean',
  BooleanNumber = 'booleanNumber',
  TicketStatus = 'ticketStatus',
  AppointmentState = 'appointmentState',
  TaskStatus = 'taskStatus',
  EInvoiceFormat = 'eInvoiceFormat',
  VacationKind = 'vacationKind',
  ObjectType = 'ObjectType',
  ChronoContactPicker = 'ChronoContactPicker',
  SalesOpportunitiesGroupPicker = 'SalesOpportunitiesGroupPicker',
  SalesOpportunitiesStatusPicker = 'SalesOpportunitiesStatusPicker',
  Check = 'Check',
  TravelReceiptStatus = 'TravelReceiptStatus',
  RequiredTime = 'requiredTime',
  PaymentStatus = 'paymentStatus',
  ReAccountingStatus = 'reAccountingStatus',
  PermitStatus = 'permitStatus',
  VacationStatus = 'vacationStatus',
  InvoiceKind = 'invoiceKind',
  DueDateClassified = 'dueDateClassified',
  InvoiceForm = 'invoiceForm',
  RaAccountingStatus = 'raAccountingStatus',
  ArticleKind = 'articleKind',
}

export enum DataType {
  Price = 'price',
  // HACK As far as I can tell this enum is only used for resolving left/right
  // alignment in a table cell. And without this, currency is not aligned
  // property in a footer cell. This should not affect anything else.

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Currency = 'price',
  Percentage = 'percentage',
  Number = 'number',
  Date = 'date',
  MimeType = 'mimeType',
  Checkbox = 'Checkbox',
}

export interface IRetrieveProps {
  retrieve: (items: ISelectionItem[]) => void;
  filter?: Record<string, unknown>;
}
export interface ColumnAdditionalData {
  filterType?: FilterType;
  dataType?: DataType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useFilterData?: any;
  popoverFilter?: boolean;
  resizable?: boolean;
  breakpoint?: Breakpoint;
  withFilter?: boolean;
  headerClass?: string;
  alignment?: Alignment;
  footerAlignment?: Alignment;
  noFlex?: boolean;
  isPrimaryFilter?: boolean;
  quickSearchable?: boolean;
  filterSubgroupPath?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterParams?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellParams?: any;
  disableColumnVisibility?: boolean;
  order?: number;
  required?: boolean;
  disableOrder?: boolean;
  className?: string;
  filterField?: string;
  groupLabelElementParams?: GroupLabelElementParams;
  hasFooter?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BasicTableColumn = Column<any> &
  ColumnAdditionalData & {
    Cell?: ColumnInterfaceBasedOnValue['Cell'];
    GroupLabelElement?: React.ComponentType<unknown>;
    title?: string;
  };

export interface ColumnInstance
  extends Omit<ReactTableColumnInstance, 'filterValue'>,
    ColumnAdditionalData {
  required?: boolean;
  filterValue: {
    colName: string;
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any;
    readOnly?: boolean;
  };
}

type FilterValueType = 'string' | 'date' | 'number' | 'range';
type FilterRange = number[];
type FilterValueValue = FilterRange | string | number | Date;
interface SelectRow {
  id: string;
  value: FilterValueValue;
  type: FilterValueType;
  title: string;
}
export interface SelectRowWithSubrows extends SelectRow {
  subRows?: SelectRow[];
}
export type SelectRows = Array<SelectRowWithSubrows>;
export type FilterValue = Record<string, SelectRow>;
export type TableMode = 'server' | 'client';

export type HandleFilterClick = (
  event: React.MouseEvent<HTMLElement, MouseEvent>,
  col?: ColumnInstance
) => void;

export type RowsLength = 'rowsLength';

type TableRowDisplayModifier =
  | 'bold'
  | 'faded'
  | 'shade1'
  | 'shade2'
  | 'shade3'
  | 'shade4'
  | 'shade5'
  | 'shade6';

export type TableRowDisplayModifiers = Partial<
  Record<`is${Capitalize<TableRowDisplayModifier>}`, boolean>
> & { tooltip?: string };
