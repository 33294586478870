import { Tooltip, Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ProjectPicker } from '@work4all/components/lib/components/entity-picker/project-picker/ProjectPicker';
import { useEntityTemplate } from '@work4all/components/lib/components/entity-template-provider/EntityTemplateProvider';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PickerTargetButton } from './PickerTargetButton';

const DEFAULT_PREFILTER = [
  {
    $or: [
      {
        projectStatusId: { $eq: 0 },
      },
      {
        projectStatusId: { $eq: null },
      },
      {
        'projectStatus.closedStatus': { $eq: 0 },
      },
    ],
  },
  { lockExternalServices: { $eq: 0 } },
];

const SEARCH_ALL = [];

export function ProjectCell(props: {
  disabled?: boolean;
  value: Project;
  onChange: (value: Project | null) => void;
  style?: React.CSSProperties;
  autoFocus?: boolean;
  prefilter?: unknown[];
  useSearchHistory?: boolean;
}) {
  const {
    disabled = false,
    value,
    onChange,
    autoFocus,
    style,
    prefilter,
    useSearchHistory,
  } = props;
  const popoverRef = useRef<EntityPickerPopover>(null);

  const label = value ? (
    <>
      <Typography component="span" variant="body2" color="text.tertiary">
        {value.number}
        {value.number && value.name ? ' | ' : ''}
      </Typography>
      <Typography component="span" variant="body2">
        {value.name}
      </Typography>
    </>
  ) : null;

  const entityTemplate = useEntityTemplate();

  return (
    <EntityPickerPopover
      ref={popoverRef}
      footer={entityTemplate.renderPickerFooter({
        popoverRef,
        entity: Entities.project,
        onChange,
        data: {
          ...value,
          lockExternalServices: null,
          projectStatus: {
            closedStatus: null,
          },
        },
      })}
      picker={
        <ProjectPicker
          value={value}
          onChange={onChange}
          multiple={false}
          favorites={true}
          data={{
            lockExternalServices: null,
            projectStatus: {
              id: null,
              closedStatus: null,
            },
          }}
          prefilter={prefilter ?? DEFAULT_PREFILTER}
          searchPrefilter={SEARCH_ALL}
          useSearchHistory={useSearchHistory}
        />
      }
    >
      <Tooltip title={value?.name ?? ''}>
        <PickerTargetButton
          style={style}
          autoFocus={autoFocus}
          disabled={disabled}
        >
          {label}
        </PickerTargetButton>
      </Tooltip>
    </EntityPickerPopover>
  );
}
