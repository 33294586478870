import styles from './ParticipantsAndAddresses.module.scss';

import { Step } from '@mui/material';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { MultiStepControls } from '@work4all/components/lib/input/multi-step-controls';

import { BusinessPartnerContactCombined } from '@work4all/models/lib/Classes/BusinessPartnerContactCombined.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { BusinessPartnerPickerField } from '../../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../../components/entity-picker/ContactPickerField';
import { CustomerPickerField } from '../../../../../../../../../../components/entity-picker/CustomerPickerField';
import { SupplierPickerField } from '../../../../../../../../../../components/entity-picker/SupplierPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { ProjectMaskFormValue } from '../../../../../types';

const CUSTOMER_DIRECTORY_DATA = {
  directory: null,
};

export const ParticipantsAndAddresses: React.FC = () => {
  const { t } = useTranslation();
  const { control, watch } = useFormContextPlus<ProjectMaskFormValue>();
  const customerWrapperRef = useRef();
  const supplierWrapperRef = useRef();

  const customer = watch('customer');
  const supplier = watch('supplier');

  const getBusinessPartnerContactCombined = (
    businessPartner: Customer | Supplier
  ) => {
    if (!businessPartner) return null;

    const value: BusinessPartnerContactCombined = {};
    value.businessPartner = {
      businessPartnerType:
        businessPartner.__typename === 'Kunde'
          ? SdObjType.KUNDE
          : SdObjType.LIEFERANT,
      data: businessPartner,
      id: businessPartner?.id,
    };
    value.contact = businessPartner?.mainContact || null;

    return value;
  };

  return (
    <Collapse
      defaultOpen
      title={t('MASK.PARTICIPANTS_AND_ADDRESSES')}
      classes={{ wrapperInner: styles.wrapperInner }}
      className={styles.root}
    >
      <ControlWrapper ref={customerWrapperRef} paddingBottom={false}>
        <MultiStepControls isColumnDirection={true}>
          <Step active={true} index={0}>
            <ControllerPlus
              name="customer"
              control={control}
              render={({ field: customer }) => {
                return (
                  <CustomerPickerField
                    {...customer}
                    data={CUSTOMER_DIRECTORY_DATA}
                  />
                );
              }}
            />
          </Step>
          <Step active={customer && !customer.isPrivateCustomer} index={1}>
            <ControllerPlus
              name="customerContact"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <ContactPickerField
                    {...field}
                    businessPartnerId={customer?.id}
                    businessPartnerType={SdObjType.KUNDE}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          </Step>
        </MultiStepControls>
      </ControlWrapper>

      <ControlWrapper ref={supplierWrapperRef} paddingBottom={false}>
        <MultiStepControls isColumnDirection={true}>
          <Step active={true} index={0}>
            <ControllerPlus
              name="supplier"
              control={control}
              render={({ field: supplier }) => {
                return <SupplierPickerField {...supplier} />;
              }}
            />
          </Step>
          <Step active={supplier && !supplier.isPrivateCustomer} index={1}>
            <ControllerPlus
              name="supplierContact"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <ContactPickerField
                    {...field}
                    businessPartnerId={supplier?.id}
                    businessPartnerType={SdObjType.LIEFERANT}
                    error={fieldState.error?.message}
                  />
                );
              }}
            />
          </Step>
        </MultiStepControls>
      </ControlWrapper>

      <ControlWrapper paddingBottom={false}>
        <ControllerPlus
          name="additionalAddress1"
          control={control}
          render={({ field }) => {
            return (
              <BusinessPartnerPickerField
                {...field}
                label={t('COMMON.DELIVERY_ADDRESS')}
                value={field?.value?.businessPartner?.data}
                onChange={(value) => {
                  field.onChange(
                    getBusinessPartnerContactCombined(value?.data)
                  );
                }}
              />
            );
          }}
        />
      </ControlWrapper>

      <ControlWrapper paddingBottom={false}>
        <ControllerPlus
          name="additionalAddress2"
          control={control}
          render={({ field }) => {
            return (
              <BusinessPartnerPickerField
                {...field}
                label={t('INPUT.BILLING_ADDRESS')}
                value={field?.value?.businessPartner?.data}
                onChange={(value) => {
                  field.onChange(
                    getBusinessPartnerContactCombined(value?.data)
                  );
                }}
              />
            );
          }}
        />
      </ControlWrapper>
    </Collapse>
  );
};
