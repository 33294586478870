import styles from './FileContactsCard.module.scss';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TextInputDialog } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';
import { useOpenEmailMaskControllerContext } from '@work4all/components/lib/hooks/contact-email-mask/useOpenEmailMaskControllerContext';

import { useDataMutation, useDataProvider } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import { contactPhoneNumber } from '../utils/contact-phone-number';

import { InlineDataList, InlineDataListItem } from './InlineDataList';

export interface FileContactsCardProps {
  contacts: Contact[];
  mainContactId: number;
  selectedContactId?: number | null;
  onSelectedContactIdChange?: (contactId: number | null) => void;
  onAddContact?: () => void;
  onEditContact?: (contactId: number) => void;
}

export function FileContactsCard(props: FileContactsCardProps) {
  const {
    contacts,
    mainContactId,
    selectedContactId,
    onSelectedContactIdChange,
    onAddContact,
    onEditContact,
  } = props;

  const { t } = useTranslation();

  // Main contact should always be shown first.
  // The rest are sorted by last name.
  const sortedContacts = useMemo(() => {
    return [...contacts].sort((a, b) => {
      if (a.id === mainContactId) {
        return -1;
      }

      if (b.id === mainContactId) {
        return 1;
      }

      return a.name.localeCompare(b.name);
    });
  }, [contacts, mainContactId]);

  const handleContactClick = (contact: Contact) => {
    const newContactId = selectedContactId === contact.id ? null : contact.id;
    onSelectedContactIdChange?.(newContactId);
  };

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    contact: Contact | null;
  }>({
    open: false,
    contact: null,
  });

  const [updateContact] = useDataMutation<Contact, EMode.upsert>({
    entity: Entities.contact,
    mutationType: EMode.upsert,
    responseData: { id: null, note: null },
  });

  const { setCallModal, callModal } = useCallModalContext();
  const openEmailMaskController = useOpenEmailMaskControllerContext();

  const [clickedContactId, setClickedContactId] = useState(0);

  useEffect(() => {
    if (!callModal) setClickedContactId(0);
  }, [callModal]);

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: {
        sdObjType: contacts?.[0]?.businessPartnerType,
        sdObjMemberCode: contacts?.[0]?.businessPartnerId,
        contactCode: clickedContactId,
      },
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [clickedContactId, contacts]);

  const completeTelephoneNumbersResult =
    useDataProvider<ContactTelephonenumbers>(
      completeTelephonenumbersNumbersRequest,
      !clickedContactId
    );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResult.data as unknown as ContactTelephonenumbers;

  useEffect(() => {
    if (
      completeTelephoneNumbers?.directDial !== undefined &&
      clickedContactId
    ) {
      setCallModal({
        data: contacts?.find((x) => x.id === clickedContactId),
        telephoneNumbers: completeTelephoneNumbers,
      });
    }
  }, [
    clickedContactId,
    completeTelephoneNumbers,
    completeTelephoneNumbersResult,
    completeTelephoneNumbersResult?.total,
    contacts,
    setCallModal,
  ]);

  return (
    <>
      <div>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <div style={{ width: '100%' }}>
            <Divider
              title={t('FILE_CONTACTS.CONTACTS')}
              size="body"
              style={{ padding: 0 }}
            />
          </div>
          {onAddContact && (
            <IconButton size="large" color="primary" onClick={onAddContact}>
              <AddIcon />
            </IconButton>
          )}
        </Stack>

        <InlineDataList<Contact & InlineDataListItem>
          onClick={handleContactClick}
          items={sortedContacts.map((contact) => ({
            ...contact,
            className: clsx({
              [styles.active]: selectedContactId === contact.id,
              [styles.layedOff]: contact.layedOff,
            }),
            label: ``,
            onlyContent: true,
            hasDivider: contact.id === mainContactId,
            content: (
              <>
                <Typography
                  component="span"
                  color="text.primary"
                  className={clsx({
                    [styles.main]: contact.id === mainContactId,
                  })}
                >
                  {contact.name} {contact.firstName}
                </Typography>
                {contact.role && (
                  <Typography component="span" color="text.tertiary">
                    {' | '}
                    {contact.role}
                  </Typography>
                )}
                {contact.department?.name && (
                  <Typography component="span" color="text.tertiary">
                    {' | '}
                    {contact.department?.name}
                  </Typography>
                )}
              </>
            ),
            acitonsComponent: (
              <>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={() => setDialogState({ open: true, contact })}
                >
                  <NoteAltIcon />
                </IconButton>

                {contact.eMail ? (
                  <IconButton
                    onClick={() => {
                      openEmailMaskController({
                        businessPartner: {
                          ...contact?.businessPartner,
                          businessPartnerType: typeNameToSdObjType(
                            contact?.businessPartner?.data.__typename
                          ),
                        },
                        contactId: contact?.id,
                      });
                    }}
                    size="small"
                    color="primary"
                  >
                    <SendIcon />
                  </IconButton>
                ) : null}

                {contactPhoneNumber(contact) ? (
                  <IconButton
                    onClick={() => {
                      setClickedContactId(contact.id);
                    }}
                    size="small"
                    color="primary"
                  >
                    <PhoneIcon />
                  </IconButton>
                ) : null}

                {onEditContact && (
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => onEditContact(contact.id)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </>
            ),
          }))}
        />
      </div>

      <TextInputDialog
        open={dialogState.open}
        onClose={() => setDialogState({ open: false, contact: null })}
        onConfirm={(value) => {
          setDialogState({ open: false, contact: null });

          if (!dialogState.contact) {
            return;
          }

          const { id, businessPartnerType } = dialogState.contact;

          updateContact<Contact>({
            id,
            businessPartnerType,
            note: value,
          });
        }}
        title={'Notiz'}
        value={dialogState.contact?.note}
      />
    </>
  );
}
