import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { MailboxFeatures } from '../Enums/MailboxFeatures.enum';

export class MailboxConfiguration<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: smtpAddress */
  smtpAddress?: string;
  /** Alias: serviceId */
  serviceId?: string;
  /** Alias: features */
  features?: MailboxFeatures[];

  __typename?: string;
}

const fields: FieldDefinitions<MailboxConfiguration> = {
  id: {
    alias: 'id',
  },
  userId: {
    alias: 'benutzerCode',
  },
  smtpAddress: {
    alias: 'smtpAddress',
  },
  serviceId: {
    alias: 'serviceId',
  },
  features: {
    alias: 'features',
  },
  __typename: {
    alias: '__typename',
  },
};

export const mailboxConfigurationEntityDefinition: EntitiyDefinition<MailboxConfiguration> =
  {
    local: {},
    remote: {
      queryName: 'getMailboxConfigurations',
      fragmentName: 'MailboxConfiguration',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
