import { set } from 'lodash';

import { GroupQueryResult } from '@work4all/models/lib/Classes/GroupQueryResult.entity';
import { AggregationType } from '@work4all/models/lib/Enums/AggregationType.enum';

export const footerDataByGroupByResult = (
  result: GroupQueryResult
): unknown => {
  const data: object = {};

  if (result.data.length !== 1) {
    console.error('Unexpected length of groupBy query:', result.data.length);
    return data;
  }

  const row = result.data[0];

  for (const field of row.data) {
    const shouldParseAsNumber = field.aggregationType !== AggregationType.COUNT;

    const value = shouldParseAsNumber
      ? tryParseAsNumber(field.value)
      : field.value;

    set(data, field.alias, value);
  }

  return data;
};

const tryParseAsNumber = (value: string): number | null => {
  // The returned value is not serialized correctly. Try to parse anyway.
  value = value.replace(/,/g, '.');

  const parsed = Number(value);
  return Number.isNaN(parsed) ? null : parsed;
};
