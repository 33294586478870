import MuiFlagIcon from '@mui/icons-material/Flag';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';

type Props = {
  isFlagged: boolean;
};

export const FlagIcon = ({ isFlagged }: Props) => {
  return isFlagged ? <MuiFlagIcon color="error" /> : <OutlinedFlagIcon />;
};
