import styles from './MenuActions.module.scss';

import { Typography } from '@mui/material';

import { MenuAction } from './types';

type MenuActionsProps = {
  actions: MenuAction[];
};

export const MenuActions = ({ actions = [] }: MenuActionsProps) => {
  return actions.map((action, index) => {
    return (
      <div
        className={styles.actionWrapper}
        key={index}
        onClick={action.onClick}
      >
        {action.icon}

        <Typography className={styles.name}>{action.name}</Typography>
      </div>
    );
  });
};
