import { useQuery } from '@apollo/client';
import { useDebugValue, useMemo } from 'react';

import { footerDataByGroupByResult } from '@work4all/components';
import {
  translateField,
  translateFilter,
} from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/hooks/translate-utils';

import { buildQuery } from '@work4all/data/lib/hooks/data-provider/utils/buildQuery';

import { ObjectTypeByEntity } from '@work4all/models';
import { GroupQueryResult } from '@work4all/models/lib/Classes/GroupQueryResult.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DataTableColumnConfig } from './table/DataTableColumnConfig';

export interface UseTableFooterDataOptions {
  enabled: boolean;
  entity: Entities;
  columns: DataTableColumnConfig[];
  filter: unknown[];
}

export function useTableFooterData(options: UseTableFooterDataOptions) {
  const { enabled, entity, columns, filter } = options;

  const { query, variables, skip } = useMemo(() => {
    const footerFields = columns
      .filter((column) => !column.disableFooterSum && column.Footer != null)
      .map((column) => column.accessor as string);

    const skip = footerFields.length < 1;

    const request: DataRequest = {
      operationName: 'GetTableFooterData',
      entity: Entities.groupQueryResult,
      data: DATA,
      vars: {
        query: {
          entityType: ObjectTypeByEntity[entity],
          groupKeyFields: footerFields.map((field) => ({
            field: translateField(field, entity),
            alias: field,
            // As of now the SUM aggregation is the only option.
            aggregations: ['SUM'],
            dontAggregate: true,
          })),
          filter:
            filter.length > 0
              ? JSON.stringify(translateFilter(filter, entity))
              : null,
        },
      },
    };

    const { query, variables } = buildQuery(request, null);

    return { query, variables, skip };
  }, [columns, entity, filter]);

  const result = useQuery<{ groupBy: GroupQueryResult }>(query, {
    variables,
    skip: !enabled || skip,
  });

  const footerData = useMemo(() => {
    return !enabled || skip || !result.data?.groupBy
      ? null
      : footerDataByGroupByResult(result.data.groupBy);
  }, [enabled, skip, result.data?.groupBy]);

  useDebugValue(footerData);

  return footerData;
}

const DATA: GroupQueryResult<EMode.query> = {
  data: [
    {
      data: [
        {
          aggregationType: null,
          alias: null,
          value: null,
        },
      ],
    },
  ],
};
