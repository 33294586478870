import {
  ColorSystemOptions,
  createTheme,
  PaletteColor,
} from '@mui/material/styles';

type Colors = {
  brand01: string;
  brand02: string;
  brand03: string;

  primary: string;
  primaryDark: string;

  text01: string;
  text02: string;
  text03: string;
  textInverse: string;

  ui01: string;
  ui02: string;
  ui03: string;
  ui04: string;
  ui05: string;
  ui06: string;
  ui07: string;
  ui08: string;

  userShade1: string;
  userShade2: string;
  userShade3: string;
  userShade4: string;
  userShade5: string;
  userShade6: string;
  userShade7: string;
  userShade8: string;
  userShade9: string;

  success: string;
  error: string;
  widgetBorder: string;
};

const light: Colors = {
  brand01: '#499ce0',
  brand02: '#eec764',
  brand03: '#727279',

  primary: '#499ce0',
  primaryDark: '#3771a0',

  text01: '#232735',
  text02: '#505565',
  text03: '#8b90a0',
  textInverse: '#ffffff',

  ui01: '#ffffff',
  ui02: '#fafafc',
  ui03: '#f0f1f3',
  ui04: '#d3d4d8',
  ui05: '#a1a4b1',
  ui06: '#232735',
  ui07: '#000000',
  ui08: '#e5f1fa',

  success: '#67b443',
  error: '#e14e2e',

  widgetBorder: '#e3e3e3',

  userShade1: '#b8defe',
  userShade2: '#429819',
  userShade3: '#b7ec8e',
  userShade4: '#eaae17',
  userShade5: '#fadb8f',
  userShade6: '#e14e2e',
  userShade7: '#f8b1a1',
  userShade8: '#ce49e3',
  userShade9: '#f4c1fc',
};

const dark: Colors = {
  brand01: '#499ce0',
  brand02: '#ae8846',
  brand03: '#8e8e96',

  primary: '#499ce0',
  primaryDark: '#4384c0',

  text01: '#f2f2f2',
  text02: '#c3c3c3',
  text03: '#8b90a0',
  textInverse: '#121212',

  ui01: '#232735',
  ui02: '#333642',
  ui03: '#161616',
  ui04: '#4a4d60',
  ui05: '#555a6c',
  ui06: '#ffffff',
  ui07: '#ffffff',
  ui08: '#0c131f',

  success: '#5e9940',
  error: '#ed806f',

  widgetBorder: '#b3b7d1',

  userShade1: '#24598a',
  userShade2: '#75ad47',
  userShade3: '#8fbf52',
  userShade4: '#f0b23d',
  userShade5: '#d5a247',
  userShade6: '#b35136',
  userShade7: '#d98d7c',
  userShade8: '#ac47a7',
  userShade9: '#e495f4',
};

const defaultTheme = createTheme();

function augmentColor(main: string, name: string): PaletteColor {
  return defaultTheme.palette.augmentColor({ color: { main }, name });
}

function makeColorScheme(colors: Colors): ColorSystemOptions {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    overlays: Array(25).fill(undefined) as any,
    palette: {
      action: {
        selectedOpacity: 0.08,
      },

      brand01: colors.brand01,
      brand02: colors.brand02,
      brand03: colors.brand03,

      primary: {
        main: colors.primary,
        dark: colors.primaryDark,
      },
      secondary: {
        main: colors.text03,
      },
      success: {
        main: colors.success,
      },
      warning: {
        main: colors.brand02,
      },
      error: {
        main: colors.error,
      },

      ui1: augmentColor(colors.ui01, 'ui1'),
      ui2: augmentColor(colors.ui02, 'ui2'),
      ui3: augmentColor(colors.ui03, 'ui3'),
      ui4: augmentColor(colors.ui04, 'ui4'),
      ui5: augmentColor(colors.ui05, 'ui5'),
      ui6: augmentColor(colors.ui06, 'ui6'),
      ui7: augmentColor(colors.ui07, 'ui7'),
      ui8: augmentColor(colors.ui08, 'ui8'),

      userShade1: colors.userShade1,
      userShade2: colors.userShade2,
      userShade3: colors.userShade3,
      userShade4: colors.userShade4,
      userShade5: colors.userShade5,
      userShade6: colors.userShade6,
      userShade7: colors.userShade7,
      userShade8: colors.userShade8,
      userShade9: colors.userShade9,

      widgetBorder: colors.widgetBorder,

      text: {
        primary: colors.text01,
        secondary: colors.text02,
        tertiary: colors.text03,
        inverse: colors.textInverse,
      },
    },
  };
}

export const colorSchemes = {
  light: makeColorScheme(light),
  dark: makeColorScheme(dark),
};
