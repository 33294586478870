import { useEventCallback } from '@mui/material/utils';

import { useDialogs } from '@work4all/components';

import { LinkMailboxDialog } from '../../../../data-tables/LinkMailboxDialog';

export const useOpenLinkMailboxDialog = () => {
  const dialogs = useDialogs();
  const open = useEventCallback(() => {
    const { id } = dialogs.open(LinkMailboxDialog, {
      onClose,
    });

    function onClose() {
      dialogs.close(id);
    }
  });

  return open;
};
