import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BaseKind {
  id?: string | number;
  name?: string;
}

interface IKindsFooter<T> {
  items: T[];
  isEverythingSelected: boolean;
  toggleAll: () => void;
  toggle: (item: T, checked: boolean) => void;
  isSelected: (item: T) => boolean;
  colorAccessor: string;
  applyColor: boolean;
  title: string;
  planningAppointments: boolean;
  onPlanningAppointmentsChange: (value: boolean) => void;
}

export const KindsFooter = <T extends BaseKind>({
  items,
  isEverythingSelected,
  toggle,
  toggleAll,
  isSelected,
  colorAccessor,
  applyColor,
  title,
  planningAppointments,
  onPlanningAppointmentsChange,
}: IKindsFooter<T>) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        gridArea: 'footer',
        display: 'flex',
        paddingInline: '1rem',
        gap: '1rem',
        alignItems: 'center',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        background: 'var(--ui02)',
        borderTop: '1px solid var(--ui04)',
        zIndex: '100',
      }}
    >
      <div>{title}:</div>

      <Button variant="text" onClick={() => toggleAll()} sx={{ flex: 'none' }}>
        {isEverythingSelected
          ? t('COMMON.SELECT_NONE')
          : t('COMMON.SELECT_ALL')}
      </Button>

      <Divider
        orientation="vertical"
        flexItem
        sx={(theme) => ({ borderColor: theme.vars.palette.ui4.main })}
      />

      <FormControlLabel
        label={t('COMMON.PLANNINGAPPOINTMENT', { count: 2 })}
        control={
          <Checkbox
            checked={planningAppointments}
            onChange={(_event, checked) => {
              onPlanningAppointmentsChange(checked);
            }}
          />
        }
      />

      <Divider
        orientation="vertical"
        flexItem
        sx={(theme) => ({ borderColor: theme.vars.palette.ui4.main })}
      />

      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          alignItems: 'center',
        }}
      >
        {items.map((item) => {
          const color = item[colorAccessor];

          return (
            <FormControlLabel
              key={item.id}
              label={item.name}
              control={
                <Checkbox
                  checked={isSelected(item)}
                  onChange={(_event, checked) => {
                    toggle(item, checked);
                  }}
                  sx={
                    applyColor
                      ? {
                          color,
                          '&.Mui-checked': {
                            color,
                          },
                        }
                      : null
                  }
                />
              }
            />
          );
        })}
      </Box>
    </Box>
  );
};
