import { useEffect } from 'react';
import { ColumnInstance, TableInstance, TableState } from 'react-table';

import { useTableStateBag } from './useTableStateBag';

interface BasicTableBagUpdateProps {
  onVisibleColumnsChange?: (cols: ColumnInstance[]) => void;
  onAllColumnsChange?: (cols: ColumnInstance[]) => void;
  onStateChange?: (state: TableState) => void;
  onTableChange?: (tableInstance: TableInstance) => void;
}

interface UseTableStateBagUpdateProps extends BasicTableBagUpdateProps {
  tableInstance: TableInstance;
}
export const useTableStateBagUpdate = (props: UseTableStateBagUpdateProps) => {
  const { state, visibleColumns, allColumns } = props.tableInstance;
  const tableStateBag = useTableStateBag();

  useEffect(() => {
    if (tableStateBag) {
      tableStateBag.setVisibleColumns(visibleColumns);
    }
    if (props.onVisibleColumnsChange) {
      props.onVisibleColumnsChange(visibleColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumns]);

  useEffect(() => {
    if (props.onAllColumnsChange) {
      props.onAllColumnsChange(allColumns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allColumns]);

  useEffect(() => {
    if (tableStateBag) {
      tableStateBag.setTableState(state);
    }
    if (props.onStateChange) {
      props.onStateChange(state);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    // Table state bag provider not rerender when rows change
    // Board layout needs it for synchronization
    props.onTableChange?.(props.tableInstance);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableInstance.rows]);
};
