import { IResponse } from '@work4all/data';

import { SortDirection } from '@work4all/models/lib/DataProvider';

interface PickerManualDataOptions {
  query: string;
  filterBy: string | string[];
  sortBy: string;
  sortByDirection: SortDirection;
  suppressFilter?: boolean;
}

export type PartialResponse<T> = Pick<IResponse<T>, 'data'> &
  Partial<Pick<IResponse<T>, 'loading' | 'pending'>>;

export function filterAndSortResult<T>(
  result: PartialResponse<T>,
  options: PickerManualDataOptions
): IResponse<T> {
  const { query, filterBy, sortBy, sortByDirection, suppressFilter } = options;

  let items = result.data;

  if (query !== '' && !suppressFilter) {
    const filterArray = Array.isArray(filterBy) ? filterBy : [filterBy];
    const filterExpr = new RegExp(`.*${query}.*`, 'i');
    const filtered = items.filter((el) => {
      return filterArray.some((field) => {
        const value = el[field];
        return typeof value === 'string' && filterExpr.test(value);
      });
    });

    items = filtered;
  }

  const orderOperator = sortByDirection === SortDirection.DESCENDING ? -1 : 1;

  items.slice().sort((a, b) => {
    const aVal = a[sortBy]?.toString() ?? '';
    const bVal = b[sortBy]?.toString() ?? '';

    return orderOperator * aVal.localeCompare(bVal);
  });

  return {
    data: items,
    total: items.length,
    loading: result.loading ?? false,
    pending: result.pending ?? false,
    fetchMore: async () => {},
    refetch: async () => {},
  };
}
