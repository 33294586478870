import { useDataProvider } from '@work4all/data/lib/hooks/data-provider/useDataProvider';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

const DATA: EMailTemplate<EMode.query> = {
  id: null,
  name: null,
  body: null,
  subject: null,
  languageCode: null,
  parentId: null,
  eMailTemplateKind: null,
  childs: [
    {
      id: null,
      body: null,
      subject: null,
      languageCode: null,
      parentId: null,
      signature: {
        id: null,
        name: null,
        body: null,
        bodyTextmarkenAufgeloestInlineImagesResolved: null,
      },
      attachmentList: [
        {
          id: null,
          fileName: null,
          fileInfos: {
            fileSize: null,
            downloadUrl: null,
            fileEntityFilename: null,
            previewUrl: null,
            previewMimeType: null,
            downloadMimeType: null,
            fileRequestType: null,
            fileServiceProviderInfos: {
              customUrl: null,
              exists: null,
              fileName: null,
              fspUrl: null,
              id: null,
              key: null,
              mimeType: null,
              size: null,
              thumbnail: null,
            },
          },
        },
      ],
    },
  ],
  attachmentList: [
    {
      id: null,
      fileName: null,
      fileInfos: {
        fileSize: null,
        downloadUrl: null,
        fileEntityFilename: null,
        previewUrl: null,
        previewMimeType: null,
        downloadMimeType: null,
        fileRequestType: null,
        fileServiceProviderInfos: {
          customUrl: null,
          exists: null,
          fileName: null,
          fspUrl: null,
          id: null,
          key: null,
          mimeType: null,
          size: null,
          thumbnail: null,
        },
      },
    },
  ],
  signature: {
    id: null,
    name: null,
    body: null,
    bodyTextmarkenAufgeloestInlineImagesResolved: null,
  },
};

export const useEmailTemplates = (
  kind: EMailTemplateKind | EMailTemplateKind[]
): Pick<
  EMailTemplate,
  'id' | 'name' | 'languageCode' | 'eMailTemplateKind'
>[] => {
  const resolvedKind = Array.isArray(kind) ? kind : [kind];

  const requestData: DataRequest = useDeepMemo(() => {
    return {
      data: DATA,
      entity: Entities.eMailTemplate,
      filter: [
        { eMailTemplateKind: { $in: resolvedKind } },
        { parentId: { $eq: null } },
      ],
      completeDataResponse: false,
      keysArguments: {
        'signature.body': EMAIL_SIGNATURE_KEYS_ARGUMENTS.body,
      },
    };
  }, [resolvedKind]);

  const resp = useDataProvider<EMailTemplate>(requestData, false, 9999);
  return resp.data;
};
