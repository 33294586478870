import styles from './EMailSignatureOverlayController.module.scss';

import { useEventCallback } from '@mui/material/utils';
import { pick } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { generateFileUploadUrl } from '@work4all/components';
import { TextEditor } from '@work4all/components/lib/input/format-text/TextEditor';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useDataMutation, useUser } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { ControllerPlus } from '../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { Collapse, ControlWrapper } from '../../components';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';

type EMailSignatureFormValue = PathsOf<EMailSignature, 2>;

type EMailSignatureOverlayControllerProps = MaskControllerProps & {
  amplitudeEntryPoint: string;
};

const DEFAULT_VALUES = {};

export function EMailSignatureOverlayController(
  props: EMailSignatureOverlayControllerProps
) {
  const { amplitudeEntryPoint } = props;

  const { goBack, close, currentStackIndex, setObjectionListener } =
    useHistoryStack();
  const [mutate] = useDataMutation<
    EMailSignature,
    EMode.upsert,
    {
      attachements;
    }
  >({
    entity: Entities.eMailSignature,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
    },
  });

  const mask = useMaskConfig(props);

  useEffect(() => {
    if (mask.isCreateMode) {
      sendAmplitudeData(EventType.AddEMailSignature, {
        entryPoint: amplitudeEntryPoint,
      });
    } else {
      sendAmplitudeData(EventType.EditEMailSignature, {
        entryPoint: amplitudeEntryPoint,
      });
    }
  }, [amplitudeEntryPoint, mask, mask.isCreateMode, props.id]);

  const request = useEMailSignatureRequestData(mask.id);

  const overlay = useMaskOverlay<EMailSignatureFormValue>({
    ...props,
    request,
    newEntityData: DEFAULT_VALUES,
    mask,
    getSubTitle: (x) => x.name,
  });

  const { formState } = overlay.form;

  const handleSubmit = useEventCallback(
    async (values: EMailSignatureFormValue) => {
      const update = mask.isCreateMode
        ? values
        : pick(values, ['id', ...Object.keys(formState.dirtyFields)]);

      if (update.body) {
        update.body = htmlParser.applyInlineStyles(update.body);
      }

      await mutate(update);

      setObjectionListener(null);
      sendAmplitudeData(EventType.SaveEMailSignature, {
        entryPoint: amplitudeEntryPoint,
      });
      if (currentStackIndex !== 0) {
        goBack();
      } else {
        close();
      }
    }
  );

  return (
    <OverlayController<EMailSignatureFormValue>
      {...overlay}
      onSubmit={handleSubmit}
    >
      <EMailSignatureMaskContent />
    </OverlayController>
  );
}

function EMailSignatureMaskContent() {
  const { t } = useTranslation();

  const { register, control } = useFormContextPlus<EMailSignatureFormValue>();

  const { activeTenant } = useTenant();
  const user = useUser();

  return (
    <>
      <div className={styles.metaData}>
        <div className={styles.left}>
          <Collapse title={t('COMMON.DESCRIPTION')} defaultOpen>
            <ControlWrapper>
              <LabeledInput label={t('COMMON.NAME')} {...register('name')} />
            </ControlWrapper>
          </Collapse>
        </div>
      </div>
      <Collapse defaultOpen>
        <ControllerPlus
          control={control}
          name="body"
          render={({ field }) => (
            <TextEditor
              value={field.value}
              onChange={field.onChange}
              enableTextmarks={true}
              enableTextBuildingBlocks={true}
              applyZoomLevel={false}
              config={{
                heightMin: 200,
                requestHeaders: {
                  'x-work4all-mandant': activeTenant,
                },
                imageUploadURL: generateFileUploadUrl(user.baseUrl, user.token),
                events: {
                  drop: function (dropEvent) {
                    dropEvent.preventDefault();
                  },
                },
              }}
            />
          )}
        />
      </Collapse>
    </>
  );
}

export const useEMailSignatureRequestData = (id: string | number) => {
  return useMemo<DataRequest>(() => {
    const EMailSignatureRequestData: EMailSignature<EMode.query> = {
      id: null,
      name: null,
      body: null,
    };
    const filter = [{ id: { $eq: id } }];
    const data = EMailSignatureRequestData;
    return {
      entity: Entities.eMailSignature,
      data,
      filter,
      keysArguments: EMAIL_SIGNATURE_KEYS_ARGUMENTS,
    };
  }, [id]);
};
