import { Children, cloneElement } from 'react';
import {
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
  Path,
  UseFormStateReturn,
} from 'react-hook-form';

import { useControllerPlus } from './use-controller-plus';

export interface ControllerPlusRenderProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends ControllerRenderProps<TFieldValues, TName> {
  required: boolean;
  error?: string;
}

export interface ControllerPlusPropsRender<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  ({
    field,
    fieldState,
    formState,
  }: {
    field: ControllerPlusRenderProps<TFieldValues, TName>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<TFieldValues>;
  }): React.ReactElement;
}

export interface ControllerPlusProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends Omit<ControllerProps<TFieldValues, TName>, 'render'> {
  control: ControllerProps<TFieldValues, TName>['control'];
  render?: ControllerPlusPropsRender<TFieldValues, TName>;
  children?: React.ReactElement;
}

export function ControllerPlus<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>
>(props: ControllerPlusProps<TFieldValues, TName>): JSX.Element {
  const { render, children } = props;

  const propsPlus = useControllerPlus(props);

  if (render) {
    return render(propsPlus);
  }

  const child = Children.only(children);

  return cloneElement(child, propsPlus.field);
}
