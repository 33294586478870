import React from 'react';

import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { NotificationListItemReApproval } from './NotificationListItemApproval/NotificationListItemReApproval';
import { NotificationListItemVacationApproval } from './NotificationListItemApproval/NotificationListItemVacationApproval';

export interface NotificationListItemApprovalProps {
  notification: Notification;
  className?: string;
  style?: React.CSSProperties;
  count?: number;
  onClick: () => void;
}

export function NotificationListItemApproval(
  props: NotificationListItemApprovalProps
) {
  const { notification } = props;
  const type = notification.parentObjectType;

  if (type === ObjectType.VACATION_REQUEST) {
    return <NotificationListItemVacationApproval {...props} />;
  } else if (type === ObjectType.RECHNUNGSEINGAENGE) {
    return <NotificationListItemReApproval {...props} />;
  } else {
    return null;
  }
}
