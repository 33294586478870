import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton/IconButton';

import { useEmailTemplateButton } from './EmailTemplateButtonProvider';

export const EmailTemplateIconButton = (props: { disabled?: boolean }) => {
  const { emailTempaltePickerFieldRef, onClick } = useEmailTemplateButton();
  return (
    <IconButton
      ref={emailTempaltePickerFieldRef}
      color="primary"
      onClick={onClick}
      {...props}
    >
      <SendIcon />
    </IconButton>
  );
};
