import React from 'react';

import {
  ISlideTransitionProps,
  SlideTransition,
} from '../../../../utils/slide-transition/SlideTransition';
import { useHistoryStack } from '../../../history-stack';

interface ITransitionProps {
  children?: React.ReactNode;
  classes?: ISlideTransitionProps['classes'];
}

export const Transition: React.FC<ITransitionProps> = (props) => {
  const { direction, current, currentStackIndex } = useHistoryStack();

  if (!current) {
    return null;
  }

  return (
    <SlideTransition
      classes={props.classes}
      currentKey={currentStackIndex.toString()}
      direction={direction}
    >
      {props.children}
    </SlideTransition>
  );
};
