import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Appointment } from './Appointment.entity';
import { Sickness } from './Sickness.entity';
import { User } from './User.entity';
import { Vacation } from './Vacation.entity';

export class ResourceUtilizationInfo<T extends EMode = EMode.entity> {
  /** Alias: date */
  date?: string;
  /** Alias: userCode */
  userId?: number;
  /** Alias: planedValue */
  planedValue?: number;
  /** Alias: utilizedValue */
  utilizedValue?: number;
  /** Alias: user */
  user?: User<T>;
  /** Alias: appointments */
  appointmentList?: Appointment<T>[];
  /** Alias: vacation */
  vacationList?: Vacation<T>[];
  /** Alias: absence */
  absenceList?: Sickness<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ResourceUtilizationInfo> = {
  date: {
    alias: 'date',
  },
  userId: {
    alias: 'userCode',
  },
  planedValue: {
    alias: 'planedValue',
  },
  utilizedValue: {
    alias: 'utilizedValue',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  appointmentList: {
    alias: 'appointments',
    entity: Entities.appointment,
  },
  vacationList: {
    alias: 'vacation',
    entity: Entities.vacation,
  },
  absenceList: {
    alias: 'absence',
    entity: Entities.sickness,
  },
  __typename: {
    alias: '__typename',
  },
};

export const resourceUtilizationInfoEntityDefinition: EntitiyDefinition<ResourceUtilizationInfo> =
  {
    local: {},
    remote: {
      queryName: 'getResourceUtilizationInfo',
      fragmentName: 'ResourceUtilizationInfo',
      withPaginationWrapper: false,
      params: [
        { name: 'userCodes', type: '[Int]!' },
        { name: 'startRange', type: 'Date!' },
        { name: 'endRange', type: 'Date!' },
        { name: 'usePlaningAppointments', type: 'Boolean' },
        { name: 'useNonPlaningAppointments', type: 'Boolean' },
        { name: 'useAppointmentPlaningValue', type: 'Boolean' },
      ],
    },
    fieldDefinitions: fields,
  };
