import styles from './OverviewSection.module.scss';

import React from 'react';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  IOverviewItemProps,
  OverviewItem,
} from '../overview-item/OverviewItem';

export interface IOverviewSectionProps {
  title: string;
  items: IOverviewItemProps[];
  editMode: boolean;
  onItemActiveChange: (entity: Entities, value, entityVariant?: string) => void;
}

export const OVERVIEW_ROW_HEADER_HEIGHT_REM = 3.5;

export const OverviewSection: React.FC<IOverviewSectionProps> = (props) => {
  const { title, items, editMode, onItemActiveChange } = props;

  return (
    <>
      <div
        className={styles['section-title']}
        style={{ height: remToPx(OVERVIEW_ROW_HEADER_HEIGHT_REM) }}
      >
        <Divider title={title} />
      </div>
      <div className={styles['overview-item-wrap']}>
        {items.map((item, idx) => {
          if (item.active || editMode) {
            return (
              <div key={idx} className={styles['overview-item']}>
                <OverviewItem
                  {...item}
                  editMode={editMode}
                  onActiveChange={onItemActiveChange}
                />
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};
