import { useEventCallback } from '@mui/material/utils';

import { useEntityEventsContext } from '@work4all/data/lib/entity-events/entity-events-context';

import { ObjectTypeByEntity } from '@work4all/models';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useSetFavoriteItemMutation } from './use-set-favorite-item-mutation';

type MutateFunctionProps = {
  id: string | number;
  entity: Entities;
  isFavorite: boolean;
};

export const useToggleItemFavorability = () => {
  const { emit } = useEntityEventsContext();
  const [mutate] = useSetFavoriteItemMutation();

  return useEventCallback(({ id, entity, isFavorite }: MutateFunctionProps) => {
    return mutate({
      variables: {
        objectType: ObjectTypeByEntity[entity],
        codeOrId: String(id),
        comment: isFavorite ? null : '',
      },
    }).then((data) => {
      emit({
        type: 'upsert',
        entity: Entities.favoriteItem,
        data: data.data.setFavoriteItem,
      });
    });
  });
};
