import SettingsIcon from '@mui/icons-material/Settings';
import { useEventCallback } from '@mui/material/utils';
import { useMemo } from 'react';

import { useDialogs } from '@work4all/components';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ControlViewLeftAction } from '../../../components/data-tables/control-view-left';
import { LeftAreaVisibleFiltersPopover } from '../components/left-area-visible-filters-popover';

import { useLeftAreaVisibleFiltersState } from './use-left-area-visible-filters-state';

type Props = {
  entity: Entities;
  filters: { id: string; label: string }[];
};

type Return = {
  action: ControlViewLeftAction;
  isFilterVisible: (filter: string) => boolean;
};

export const useLeftAreaVisibleFilters = ({
  entity,
  filters,
}: Props): Return => {
  const dialogs = useDialogs();
  const filterIds = useMemo(
    () => filters.map((filter) => filter.id),
    [filters]
  );

  const { isFilterVisible } = useLeftAreaVisibleFiltersState({
    entity,
    filterIds,
  });

  const openSettingsPopover = useEventCallback(
    (anchorEl: EventTarget & HTMLButtonElement) => {
      const dialog = dialogs.open(LeftAreaVisibleFiltersPopover, {
        onClose: () => dialogs.close(dialog.id),
        entity,
        anchorEl,
        filters,
      });
    }
  );

  return {
    action: {
      icon: <SettingsIcon />,
      onClick: openSettingsPopover,
    },
    isFilterVisible,
  };
};
