import i18next from 'i18next';
import { uuid } from 'short-uuid';

import { IFavoriteLink, IFavoriteLinksFolder } from './types';

export interface CreateLinkOptions {
  name: string;
  translationKey?: string;
  href: string;
}

export function createLink({
  name,
  translationKey,
  href,
}: CreateLinkOptions): IFavoriteLink {
  return {
    kind: 'link',
    id: uuid(),
    name: translationKey ? i18next.t(translationKey) : name,
    translationKey,
    href,
  };
}

export interface CreateFolderOptions {
  name: string;
  links?: CreateLinkOptions[];
}

export function createFolder({
  name,
  links = [],
}: CreateFolderOptions): IFavoriteLinksFolder {
  return {
    kind: 'folder',
    id: uuid(),
    name,
    links: links.map((link) => createLink(link)),
  };
}
