import { useMemo, useState } from 'react';

import { BottomViews } from '../components';

import { UseBottomViewProps, UseBottomViewReturn } from './types';

export const useBottomViews = ({
  views,
  skip,
  size,
}: UseBottomViewProps): UseBottomViewReturn => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const viewsWithIndexAsAnId = useMemo(() => {
    return views.map((view, id) => {
      return { ...view, id: `${id}` };
    });
  }, [views]);

  const bottomViews = useMemo(() => {
    return (
      <BottomViews
        views={viewsWithIndexAsAnId}
        setIsCollapsed={setIsCollapsed}
      />
    );
  }, [viewsWithIndexAsAnId]);

  return useMemo(() => {
    if (skip) return undefined;

    return {
      content: bottomViews,
      resizable: !isCollapsed,
      size,
    };
  }, [skip, bottomViews, isCollapsed, size]);
};
