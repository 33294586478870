import { DateTime } from 'luxon';

export function dateModifier(
  value: string,
  section: number,
  direction: 'up' | 'down' | 'none'
) {
  // This function just modifies that date it doesn't validate it, the validationis being implemented after
  const parsedValue = parseInt(value, 10) || 0;
  const date = DateTime.now().set({ day: 1, month: 1 });
  const directionValue = direction === 'none' ? 0 : direction === 'up' ? 1 : -1;
  if (section === 0) {
    if (!parsedValue) return date.set({ day: 1 }).toFormat('dd');

    const newDate = date.set({ day: parsedValue });
    // Check if day overflowed
    if (newDate.day !== parsedValue) {
      return date.set({ day: 1 }).toFormat('dd'); // Default 1st if invalid
    }

    return newDate.plus({ day: directionValue }).toFormat('dd');
  } else if (section === 1) {
    // Check if month overflowed
    if (!parsedValue || parsedValue < 1 || parsedValue > 12) {
      return date.set({ month: 1 }).toFormat('MM'); // Default to January if invalid
    }

    return date
      .set({ month: parsedValue })
      .plus({ months: directionValue })
      .toFormat('MM');
  } else if (section === 2) {
    if (!parsedValue) return date.toFormat('yyyy');
    return date
      .set({ year: parsedValue })
      .plus({ year: directionValue })
      .toFormat('yyyy');
  }
  return value;
}
