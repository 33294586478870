import React from 'react';
import { useTranslation } from 'react-i18next';

import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { DocumentTemplateGroup } from '@work4all/models/lib/Classes/DocumentTemplateGroup.entity';
import { LetterTemplateGroup } from '@work4all/models/lib/Classes/LetterTemplateGroup.entity';
import { TemplateGroup } from '@work4all/models/lib/Classes/TemplateGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityPickerField } from './EntityPickerField';

export type PickerProps = IPickerProps<TemplateGroup, false>;

interface Props extends Omit<PickerProps, 'multiple'> {
  error?: string;
  disabled?: boolean;
  label?: string;
  entity?: Entities.documentTemplateGroup | Entities.letterTemplateGroup;
}

export const TemplateGroupPickerField = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { entity = Entities.documentTemplateGroup } = props;
    const { t } = useTranslation();
    return (
      <EntityPickerField
        ref={ref}
        label={props.label ?? t('COMMON.GROUP')}
        entity={entity}
        data={TEMPLATE_GROUP_FIELDS}
        {...props}
      />
    );
  }
);

const TEMPLATE_GROUP_FIELDS:
  | TemplateGroup
  | DocumentTemplateGroup
  | LetterTemplateGroup = {
  id: null,
  name: null,
};
