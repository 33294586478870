import { useMemo } from 'react';

import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

export const getBodyContent = (html: string) => {
  return html?.split('<body>')?.[1]?.split('</body>')?.[0] || html;
};

export const PDFMAKE_TABLE_LAYOUTS = {
  positionsList: {
    hLineWidth: (i, node) => {
      if (i === 0 || i === node.table.body.length) {
        return 0;
      }
      return i === node.table.headerRows ? 0.5 : 0;
    },
    vLineWidth: (i, node) => {
      if (i === 0 || i === node.table.widths.length) {
        return 0;
      }
      return 0.5;
    },
    hLineColor: () => {
      return '#aaa';
    },
    vLineColor: () => {
      return '#aaa';
    },
    paddingLeft: (i) => {
      return i === 0 ? 0 : 6;
    },
    paddingRight: (i, node) => {
      return i === node.table.widths.length - 1 ? 0 : 6;
    },
    paddingTop: (i) => (i === 0 ? 4 : 12),
    paddingBottom: (i) => (i === 0 ? 4 : 0),
  },
  summary: {
    hLineWidth: () => {
      return 0;
    },
    vLineWidth: () => {
      return 0;
    },
    paddingLeft: (i) => {
      return i === 0 ? 0 : 12;
    },
    paddingRight: (i, node) => {
      return i === node.table.widths.length - 1 ? 0 : 12;
    },
    paddingTop: () => 4,
    paddingBottom: () => 4,
  },
};

export const bzObjToEntityType: Record<BzObjType, Entities> = {
  [BzObjType.ANGEBOT]: Entities.offer,
  [BzObjType.UNKNOWN]: null,
  [BzObjType.KALKULATION]: Entities.calculation,
  [BzObjType.AUFTRAG]: Entities.contract,
  [BzObjType.RECHNUNG]: Entities.invoice,
  [BzObjType.LIEFERSCHEIN]: Entities.deliveryNote,
  [BzObjType.BESTELLUNG]: Entities.order,
  [BzObjType.BEDARFSANFORDERUNG]: Entities.demand,
  [BzObjType.EINGANGSLIEFERSCHEIN]: Entities.inboundDeliveryNote,
};

export const nonTotalPriceErpPositionTypes = [
  ErpPositionsKind.TITEL,
  ErpPositionsKind.TITEL_INTERN,
  ErpPositionsKind.TITEL_OHNE_NUMMER,
  ErpPositionsKind.UMWANDLUNGSHISTORIE,
  ErpPositionsKind.TEXTZEILE,
];

export const nonCalculationErpPositionTypes = [
  ErpPositionsKind.TITEL,
  ErpPositionsKind.TITELSUMME,
  ErpPositionsKind.TITEL_INTERN,
  ErpPositionsKind.TITEL_OHNE_NUMMER,
  ErpPositionsKind.UMWANDLUNGSHISTORIE,
  ErpPositionsKind.TEXTZEILE,
  ErpPositionsKind.ZWISCHENSUMME,
];

export const titleErpPositionTypes = [
  ErpPositionsKind.TITEL,
  ErpPositionsKind.TITELSUMME,
  ErpPositionsKind.TITEL_INTERN,
  ErpPositionsKind.TITEL_OHNE_NUMMER,
  ErpPositionsKind.UMWANDLUNGSHISTORIE,
];

export const groupPositionsByVAT = (positionList: Position[]) => {
  return positionList.reduce((result, currentValue) => {
    (result[currentValue.vat] = result[currentValue.vat] || []).push(
      currentValue
    );
    return result;
  }, {});
};

function groupBy<T>(keys) {
  return (array: T[]) =>
    array.reduce((objectsByKeyValue, obj) => {
      const value = keys.map((key) => obj[key]).join('-');
      objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
      return objectsByKeyValue;
    }, {});
}

export const useVatTiers = (positionList: Position[]) => {
  const groups = useMemo(
    () => groupBy<Position>(['vat'])(positionList),
    [positionList]
  );

  const result = useMemo<
    {
      vatRate: number;
      totalPriceGross: number;
      totalPriceNet: number;
      vatValue: number;
    }[]
  >(() => {
    const result = [];
    Object.keys(groups).forEach((vat) => {
      if (Number.isNaN(parseInt(vat))) return;
      const arr = groups[vat];

      const totalPriceNet = arr
        .map((x) => x.totalPriceNet)
        .reduce((prev, next) => {
          return prev + next;
        }, 0);

      const vatValue = (totalPriceNet / 100) * parseFloat(vat);

      const totalPriceGross = totalPriceNet + vatValue;

      result.push({
        totalPriceGross,
        totalPriceNet,
        vatRate: parseFloat(vat),
        vatValue,
      });
    });
    return result;
  }, [groups]);

  return result;
};
