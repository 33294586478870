export const SETTING_NAME_PREFIX = 'w4a2.';

export const SETTING_SCOPES = [
  'global',
  'user',
  'tenant',
  'global-tenant',
] as const;

/**
 * Some of the personal settings do not follow the common "w4a2.name" naming
 * pattern. We add them as exceptions and treat them as normal user settings.
 *
 * These settings should only be used with scope "user", because there is no way
 * to save the tenant information in the setting name.
 */
export const UNPREFIXED_USER_SETTINGS = [
  'WorkingDaySetting_CalendarDayStart',
  'WorkingDaySetting_CalendarDayEnd',
  'WorkingDaySetting_CalendarWorkDayStart',
  'WorkingDaySetting_CalendarWorkDayEnd',
];
