import { ApolloError } from '@apollo/client';
import { UseFormReturn } from 'react-hook-form';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ValidationErrors } from '../../../../apollo/ValidationErrors';

export const makeOnMutationError =
  <T>(form: UseFormReturn<T, object>, entity: Entities) =>
  (e: ApolloError) => {
    const parsedErrors = ValidationErrors.parseErrors<T>(
      e.graphQLErrors,
      entity
    );
    parsedErrors.forEach((x) => {
      const property: keyof T = x.property;
      if (!property) return;
      //@ts-expect-error keyof T is subset of Path<T>
      form.setError(property, {
        message: ValidationErrors.translateError(x.code),
        type: 'apiValidation',
      });
    });
  };
