import { minBy } from 'lodash';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { ArticleLedgerAccount } from '@work4all/models/lib/Classes/ArticleLedgerAccount.entity';
import { PriceGroup } from '@work4all/models/lib/Classes/PriceGroup.entity';
import { SinglePrice } from '@work4all/models/lib/Classes/SinglePrice.entity';
import { TaxGroup } from '@work4all/models/lib/Classes/TaxGroup.entity';
import { Unit } from '@work4all/models/lib/Classes/Unit.entity';
import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../settings';

import { ArticleMaskFormValue } from './types';

export function useNewArticleData({
  enabled,
  presetFields,
}: {
  enabled: boolean;
  presetFields?: Article;
}) {
  // Unit

  const units = useDataProvider<Unit>(UNITS_REQUEST, !enabled);

  const defaultUnit = useMemo<Unit>(() => {
    if (!units.data) return null;

    return minBy(units.data, (unit) => unit.order) ?? null;
  }, [units.data]);

  // VatRate

  const defaultVatRateValue = useSetting(settings.defaultVatRateValue());

  const vatRates = useDataProvider<VatRate>(VAT_RATES_REQUEST, !enabled);

  const defaultVatRate = useMemo<VatRate>(() => {
    if (!vatRates.data || defaultVatRateValue.value == null) return null;
    const defaultVat = defaultVatRateValue.value;
    return vatRates.data.find((v) => v.sentence === defaultVat) ?? null;
  }, [vatRates.data, defaultVatRateValue.value]);

  // Single prices

  const priceGroups = useDataProvider<PriceGroup>(
    PRICE_GROUPS_REQUEST,
    !enabled
  );

  const defaultSinglePrices = useMemo<SinglePrice[]>(() => {
    return priceGroups.data.map((priceGroup) => {
      const price: SinglePrice = {
        priceGroup: priceGroup,
        price: 0,
        surcharge: 0,
        minuteWage: 0,
      };

      return price;
    });
  }, [priceGroups.data]);

  // Article Ledger Accounts

  const taxGroups = useDataProvider<TaxGroup>(TAX_GROUPS_REQUEST, !enabled);

  const articleLedgerAccounts = useMemo<ArticleLedgerAccount[]>(() => {
    return taxGroups.data.map((taxGroup) => {
      const account: ArticleLedgerAccount = {
        taxGroup: taxGroup,
        vatRate: null,
        ledgerAccount: null,
      };

      return account;
    });
  }, [taxGroups.data]);

  return useMemo(() => {
    const data: ArticleMaskFormValue = {
      articleKind: ArticleKind.NORMAL,
      name: '',
      longtext: '',
      internalText: '',
      number: '',
      factor: 1,
      isNoStockArticle: false,
      hideInShop: false,
      shopOrderWithoutStock: false,
      isRentalArticle: false,
      isCharge: false,
      isOutsideService: false,
      isShutDown: false,
      internalArticle: false,
      serialNumberInbound: false,
      articleGroup: null,
      groupId: 0,
      unit: defaultUnit,
      unitId: defaultUnit?.id ?? 0,
      maintenanceShare: 0,
      customsTariffNumber: '',
      summarizeForPackingLists: false,
      isArticleForRessourcePlaning: false,
      ressourceClass: null,
      ressourceClassId: 0,
      manufacturer: null,
      manufacturerId: 0,
      manufacturerNumber: '',
      ean: '',
      costCenterNew: null,
      costCenterId: 0,
      landOfOrigin: 'DE',
      vatRate: defaultVatRate,
      vatId: defaultVatRate?.id ?? 0,
      articleLedgerAccountAssignements: articleLedgerAccounts,
      articleTextList: [],
      articleDocumentList: [],
      customFieldList: [],
      entryPrice: 0,
      articlePrices: {
        singlePriceList: defaultSinglePrices,
        purchasePriceList: [],
        customerIndividualPriceList: [],
      },
      discountable: false,
      hasNoCommission: true,
      noSkonto: true,
      standardSupplierId: 0,
      standardPurchasePriceAsEntryPrice: 0,
      ...presetFields,
    };

    return data;
  }, [
    defaultUnit,
    defaultVatRate,
    articleLedgerAccounts,
    defaultSinglePrices,
    presetFields,
  ]);
}

const UNIT_FIELDS: Unit = {
  id: null,
  name: null,
  factor: null,
  order: null,
};

const UNITS_REQUEST: DataRequest = {
  operationName: 'GetUnits',
  data: UNIT_FIELDS,
  entity: Entities.unit,
  sort: [{ field: 'order', direction: SortDirection.ASCENDING }],
};

const VAT_FIELDS: VatRate<EMode.query> = {
  id: null,
  sentence: null,
  sequence: null,
  disabled: null,
};

const VAT_RATES_REQUEST: DataRequest = {
  operationName: 'GetVatRates',
  data: VAT_FIELDS,
  entity: Entities.vatRate,
  completeDataResponse: true,
};

const PRICE_GROUP_FIELDS: PriceGroup<EMode.query> = {
  id: null,
  name: null,
};

const PRICE_GROUPS_REQUEST: DataRequest = {
  operationName: 'GetPriceGroups',
  data: PRICE_GROUP_FIELDS,
  entity: Entities.priceGroup,
  completeDataResponse: true,
};

const TAX_GROUP_FIELDS: TaxGroup<EMode.query> = {
  id: null,
  name: null,
  type: null,
};

const TAX_GROUPS_REQUEST: DataRequest = {
  operationName: 'GetTaxGroups',
  data: TAX_GROUP_FIELDS,
  entity: Entities.taxGroup,
  completeDataResponse: true,
  vars: {
    includeStaticGroups: true,
  },
};
