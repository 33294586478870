import type { Group } from '@work4all/models/lib/Classes/Group.entity';

import { GrouPickerItem } from '../../components/entity-picker/GroupPicker';

import type { TreeNode } from './types';

export const ROOT_ID = '<root>';

export const toNodeId = <T>(id: T) => {
  return id === 0 || id === undefined ? ROOT_ID : id?.toString();
};

export const createTreeData = <TId = number>(
  groups: GrouPickerItem<TId>[] | null
) => {
  const groupById = new Map<string, GrouPickerItem<TId>>();
  const groupsByParentId = new Map<string | null, string[]>();

  if (!groups) {
    return { treeData: [], groupById, groupsByParentId };
  }

  for (const group of groups) {
    const id = toNodeId(group.id);
    const parentId = toNodeId(group.parentId);

    groupById.set(id, group);

    if (!groupsByParentId.has(parentId)) {
      groupsByParentId.set(parentId, []);
    }

    groupsByParentId.get(parentId).push(id);
  }

  const compareGroupsByNameOrIndex = (
    a: GrouPickerItem<TId>,
    b: GrouPickerItem<TId>
  ) => {
    if (a.index !== undefined && b.index !== undefined) {
      return a.index - b.index;
    }
    return a.name.localeCompare(b.name);
  };

  function mapItem(group: GrouPickerItem<TId>): TreeNode {
    const id = toNodeId(group.id);

    const treeItem: TreeNode = {
      id,
      parentId: group.parentId,
      label: group.name,
      index: group.index,
      children: groupsByParentId
        .get(id)
        ?.map((id) => groupById.get(id))
        .sort(compareGroupsByNameOrIndex)
        .map((group) => mapItem(group)),
    };

    return treeItem;
  }

  const rootGroups = groupsByParentId.get(ROOT_ID) ?? [];

  const treeData = rootGroups
    .map((id) => groupById.get(id))
    .sort(compareGroupsByNameOrIndex)
    .map((group) => mapItem(group));

  return { treeData, groupById, groupsByParentId };
};

export const mapTreeToGroups = (tree: TreeNode[]): Group[] => {
  return tree.flatMap((node) => {
    const group = {
      code: node.id ? Number(node.id) : null,
      name: node.label as string,
      parentCode: node.parentId,
      index: node.index,
    };

    return [
      group,
      ...(node.children ? mapTreeToGroups(node.children as TreeNode[]) : []),
    ];
  });
};

export const reindexTree = (tree: TreeNode[], globalIndex = { value: 0 }) => {
  tree.forEach((node) => {
    node.index = globalIndex.value++;
    if (node.children) {
      reindexTree(node.children as TreeNode[], globalIndex);
    }
  });
  return tree;
};

export const removeNodeBy = (
  tree: TreeNode[],
  value: string | number,
  key: keyof TreeNode = 'id'
): TreeNode[] => {
  return tree
    .filter((node) => node[key] !== value)
    .map((node) => ({
      ...node,
      children: removeNodeBy(
        node.children ? [...node.children] : [],
        value,
        key
      ),
    }));
};

export const findNodeBy = (
  tree: TreeNode[],
  value: string | number,
  key: keyof TreeNode = 'id'
): TreeNode => {
  for (const node of tree) {
    if (node[key] === value) {
      return node;
    }
    if (node.children) {
      const foundNode = findNodeBy(
        node.children ? [...node.children] : [],
        value,
        key
      );
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return null;
};

export const replaceNodeBy = (
  tree: TreeNode[],
  modifiedNode: TreeNode,
  key: keyof TreeNode = 'id'
): TreeNode[] => {
  return tree.map((node) => {
    if (node[key] === modifiedNode[key]) {
      return modifiedNode;
    }
    if (node.children) {
      return {
        ...node,
        children: replaceNodeBy(
          node.children ? [...node.children] : [],
          modifiedNode,
          key
        ),
      };
    }
    return node;
  });
};

export const insertNode = (
  tree: TreeNode[],
  nodeToInsert: TreeNode,
  parentId: string | null,
  index: number,
  isBottomDrag: boolean
): TreeNode[] => {
  return tree.map((node) => {
    if (node.id === parentId) {
      const updatedChildren = [...(node.children || [])];
      const childRealIndex = node.children?.findIndex(
        (child) => child.index === index
      );

      if (childRealIndex === -1) {
        updatedChildren.push({
          ...nodeToInsert,
          parentId: Number(parentId),
        });
      } else {
        const targetIndex = isBottomDrag ? childRealIndex + 1 : childRealIndex;
        updatedChildren.splice(targetIndex, 0, {
          ...nodeToInsert,
          parentId: Number(parentId),
        });
      }

      return { ...node, children: updatedChildren };
    }
    return {
      ...node,
      children: node.children
        ? insertNode(
            node.children as TreeNode[],
            nodeToInsert,
            parentId,
            index,
            isBottomDrag
          )
        : node.children,
    };
  });
};
