import { gql, useMutation } from '@apollo/client';

import { ObjectTypesUnion } from '@work4all/models';
import { FavoriteItem } from '@work4all/models/lib/Classes/FavoriteItem.entity';

const SET_FAVORITE_ITEM = gql`
  mutation SetFavoriteItem(
    $objectType: ObjectType!
    $codeOrId: String!
    $comment: String
  ) {
    setFavoriteItem(
      objectType: $objectType
      codeOrId: $codeOrId
      comment: $comment
    ) {
      id
      objCode
      objId
      objectType
      dateTime
      comment
      userCode
      user {
        code
        anzeigename
      }
    }
  }
`;

type SetFavoriteItemArgs = {
  objectType: ObjectTypesUnion;
  codeOrId: string;
  comment: string | null;
};

export const useSetFavoriteItemMutation = () => {
  return useMutation<{ setFavoriteItem: FavoriteItem }, SetFavoriteItemArgs>(
    SET_FAVORITE_ITEM
  );
};
