import { UseFormReturn } from 'react-hook-form';

import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Currency } from '@work4all/models/lib/Classes/Currency.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { SalesOpportunityGroup } from '@work4all/models/lib/Classes/SalesOpportunityGroup.entity';
import { SalesOpportunityRatingStatus } from '@work4all/models/lib/Classes/SalesOpportunityRatingStatus.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  getValuesOnProbabilityChange,
  getValuesOnRatingStatusChange,
  getValuesOnStatusChange,
} from '../../../../../../components/data-tables/sales-opportunities-table/sales-opportunities-evaluation-picker/utils';
import { useFormUpdate } from '../../../utils/use-form-update';
import { SalesOpportunitiesMaskFormValue } from '../types';

export const useSalesOpportunitiesFormUpdate = (
  form: UseFormReturn<SalesOpportunitiesMaskFormValue>
) => {
  const { getValues } = form;
  useFormUpdate(
    {
      businessPartner: (businessPartner: BusinessPartner) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType =
          businessPartner?.businessPartnerType ?? SdObjType.KUNDE;
        const contact = businessPartner?.data?.isPrivateCustomer
          ? null
          : businessPartner?.data?.mainContact ?? null;

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;
        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;

        const businessPartnerField = getValues('businessPartner');

        if (!businessPartnerField) {
          const businessPartner = project?.customer ?? null;

          return {
            projectId,
            projectProcess: null,
            businessPartner: businessPartner
              ? {
                  id: businessPartner.id,
                  data: businessPartner,
                  businessPartnerType: SdObjType.KUNDE,
                }
              : null,
          };
        }

        return { projectId, projectProcess: null };
      },
      group: (group: SalesOpportunityGroup) => {
        return {
          groupId: group?.id || 0,
        };
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;

        return { projectProcessId };
      },
      probabilityPercent: (probabilityPercent: number | string) => {
        const status = form.getValues('status');
        return getValuesOnProbabilityChange(status, probabilityPercent);
      },
      status: getValuesOnStatusChange,
      currency: (currency: Currency) => {
        return {
          currencyId: currency?.id || 0,
        };
      },
      user: (user: User) => {
        return {
          userId: user?.id || 0,
        };
      },
      user2: (user: User) => {
        return {
          user2Id: user?.id || 0,
        };
      },
      ratingStatus: (status: SalesOpportunityRatingStatus) => {
        if (!status)
          return {
            ratingStatusId: EMPTY_UID,
          };
        return getValuesOnRatingStatusChange(status.id, status.ratingValue);
      },
    },
    form
  );
};
