import styles from './Sorter.module.scss';

import React from 'react';

import { ReactComponent as DownIcon } from '@work4all/assets/icons/arrow_drop_down.svg';
import { ReactComponent as UpIcon } from '@work4all/assets/icons/arrow_drop_up.svg';

import {
  Tooltip,
  Truncation,
} from '../../../../../../components/tooltip/Tooltip';
import { ColumnInstance } from '../../../../types';

interface ISorterProps extends Truncation {
  children?: React.ReactNode;
  column: ColumnInstance;
}

export const Sorter: React.FC<ISorterProps> = (props) => {
  const { title: _, ...sorterProps } =
    props.column.getSortByToggleProps?.() ?? {};

  return (
    <div className={styles.sorter} {...sorterProps}>
      {props.column.isSorted ? (
        props.column.isSortedDesc ? (
          <DownIcon className={styles.icon} />
        ) : (
          <UpIcon className={styles.icon} />
        )
      ) : undefined}
      <Tooltip title={''} showOnlyOnOverflow onTruncation={props.onTruncation}>
        <div className={styles.content}>{props.children}</div>
      </Tooltip>
    </div>
  );
};
