import React from 'react';
import { TableInstance } from 'react-table';

import { NumberCell } from '@work4all/components/lib/dataDisplay/basic-table/utils/cells/NumberCell';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/timeTracking-table-schema.json';

import { useTimeTrackingTableActions } from './hooks';
import { TimeTrackingCopyButtonCell } from './TimeTrackingCopyButtonCell';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const TimeTrackingTable = React.forwardRef<TableInstance, IEntityTable>(
  function TimeTrackingTable(_props, ref) {
    const dataTable = useEntityDataTable<TimeTracking, never>({
      schema: schema as never,
      defaultSort,
      cells: {
        Number: (cell) => <NumberCell {...cell} maximumFractionDigits={2} />,
        CopyButton: TimeTrackingCopyButtonCell,
      },
    });

    const actions = useTimeTrackingTableActions(
      dataTable.selectedEntities?.[0]
    );

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        {..._props}
        actions={actions}
        overrides={{ areas: null }}
      />
    );
  }
);
