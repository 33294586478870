import { Tab, Tabs } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useBottomViewsContext } from '../hooks';

export const BottomViewsTabs = () => {
  const { t } = useTranslation();
  const {
    views,
    activeView,
    setActiveViewId,
    isCollapsed,
    toggleCollapsibility,
  } = useBottomViewsContext();

  const renderTabs = useCallback(() => {
    return views.map((view) => {
      return (
        <Tab
          key={view.id}
          value={view.id}
          label={t(view.titleTranslationKey)}
        />
      );
    });
  }, [t, views]);

  return (
    <Tabs
      value={isCollapsed ? false : activeView.id}
      onChange={(_, id) => {
        if (isCollapsed) {
          toggleCollapsibility();
        }
        setActiveViewId(id);
      }}
    >
      {renderTabs()}
    </Tabs>
  );
};
