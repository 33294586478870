import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputGroup } from './InputGroup.entity';

export class MutateSupplierGroups<T extends EMode = EMode.entity> {
  /** Alias: structure */
  structure?: InputGroup<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<MutateSupplierGroups> = {
  structure: {
    alias: 'structure',
  },
  __typename: {
    alias: '__typename',
  },
};

export const mutateSupplierGroupsEntityDefinition: EntitiyDefinition<MutateSupplierGroups> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'MutateSupplierGroups',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
