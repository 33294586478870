import styles from './SalesOpportunitiesRatingStatusList.module.scss';

import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';

import { NavigationOverlayHeader } from '../../../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { NoAdminRightsBanderole } from '../../../../../components/banderoles/NoAdminRightsBanderole';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../../../mask-tabs';
import { MaskContent } from '../../../../components/MaskContent/MaskContent';
import { SelectedPositionProvider } from '../../../erp/components/tab-panels/positions/components/hooks/use-selected-position';

import { SalesOpportunitiesRatingStatusList } from './SalesOpportunitiesRatingStatusList';

export function SalesOpportunityRatingStatusSettings() {
  const { t } = useTranslation();
  const user = useUser();

  return (
    <MaskTabContext defaultValue="general">
      <NavigationOverlayHeader
        title={t('SETTINGS.SETTINGS')}
        subTitle={t('COMMON.SALESOPPORTUNITIES')}
        breadcrumbsChildren={<div></div>}
        tabs={<SettingsTabs />}
      />
      {!user.isMaster && <NoAdminRightsBanderole />}
      <Content />
    </MaskTabContext>
  );
}

const SettingsTabs = memo(function Tabs() {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab
        value="personal"
        label={t('COMMON.PERSONAL')}
        disabled={true}
      ></MaskTab>
      <MaskTab value="general" label={t('COMMON.GENERAL')} />
    </MaskTabs>
  );
});

const Content = memo(function TabPanels() {
  return (
    <MaskContent>
      <MaskTabPanel value="personal" />
      <MaskTabPanel
        value="general"
        className={styles['mask-tab-panel-override']}
      >
        <SelectedPositionProvider>
          <SalesOpportunitiesRatingStatusList />
        </SelectedPositionProvider>
      </MaskTabPanel>
    </MaskContent>
  );
});
