import styles from './EmailTemplatesPopover.module.scss';

import { Popover } from '@mui/material';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';

import {
  EmailTemplatePicker,
  StandardEmailTemplates,
} from '../entity-picker/email-template-picker/EmailTemplatePicker';

interface EmailTemplatesPopoverProps {
  anchorEl: Element;
  onClose: () => void;
  onTemplateClick: (template: EMailTemplate | null) => void;
  setStandardTemplates: (templates: StandardEmailTemplates) => void;
  standardTemplates: StandardEmailTemplates;
  eMailTemplateKind: EMailTemplateKind;
  noTemplate?: boolean;
}

export const EmailTemplatesPopover = ({
  anchorEl,
  onClose,
  onTemplateClick,
  setStandardTemplates,
  standardTemplates,
  eMailTemplateKind,
  noTemplate,
}: EmailTemplatesPopoverProps) => {
  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{
        root: styles.root,
      }}
    >
      <EmailTemplatePicker
        value={{ eMailTemplateKind }}
        onChange={onTemplateClick}
        standardTemplates={standardTemplates}
        setStandardTemplates={setStandardTemplates}
        noTemplate={noTemplate}
        layout="simple"
        eMailTemplateKind={eMailTemplateKind}
        className={styles.pickerList}
      />
    </Popover>
  );
};
