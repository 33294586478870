import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { TIME_TRACKING_DATA } from './constants';

export const useTimeTrackingData = (id: number | undefined): TimeTracking => {
  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetTimeTracking',
      entity: Entities.timeTracking,
      skip: !id,
      data: TIME_TRACKING_DATA,
      filter: [{ id: { $eq: id } }],
    };
  }, [id]);

  const { data } = useDataProvider<TimeTracking>(request);

  return data?.[0];
};
