import { useMemo } from 'react';

import {
  SelectableTree,
  TreeNode,
} from '@work4all/components/lib/dataDisplay/tree/SelectableTree';

import { useDataProvider } from '@work4all/data';

import { Category } from '@work4all/models/lib/Classes/Category.entity';
import { CategoryClass } from '@work4all/models/lib/Classes/CategoryClass.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface CategoryPickerProps {
  value: Category[] | null;
  onChange: (value: Category[]) => void;
  /** You can override the default entity type if there is a separate GraphQL
   * type and query to get the categories you want.
   *
   * @default Entities.categoryClass
   * */
  entity?: Entities;
  filter?: unknown[] | null;
  multiple?: boolean;
  expanded?: string[];
  onNodeToggle?: (event: React.SyntheticEvent, nodeIds: string[]) => void;
}

export function CategoryPicker(props: CategoryPickerProps) {
  const {
    value,
    onChange,
    entity = Entities.categoryClass,
    filter,
    multiple = true,
    expanded,
    onNodeToggle,
  } = props;

  const requestData: DataRequest = useMemo(() => {
    return {
      entity,
      data: CATEGORY_CLASS_FIELDS,
      filter,
    };
  }, [entity, filter]);

  const response = useDataProvider<CategoryClass>(requestData);

  const { data: allCategoryClasses } = response;

  // Remove category classes without any categories in them.
  const categoryClasses = useMemo<CategoryClass[]>(() => {
    return allCategoryClasses.filter((categoryClass) => {
      return categoryClass.categoryList?.length > 0;
    });
  }, [allCategoryClasses]);

  const { treeData, originalById } = useMemo(() => {
    const originalById = new Map<string, CategoryClass | Category>();

    // TODO The categories returned from the API are not sorted. You can sort
    // the root category classes by providing the sort options, but the nested
    // categories are not sorted anyway. For now we just sort all the items
    // client-side.
    function compareCategoriesByName(
      a: Category | CategoryClass,
      b: Category | CategoryClass
    ) {
      return a.name.localeCompare(b.name);
    }

    function mapTreeItem(item: CategoryClass | Category): TreeNode {
      const id = item.id.toString();

      const treeItem: TreeNode = {
        id,
        label: item.name,
        children: isCategoryClass(item)
          ? [...item.categoryList]
              .sort(compareCategoriesByName)
              .map(mapTreeItem)
          : null,
      };

      originalById.set(id, item);

      return treeItem;
    }

    const treeData = [...categoryClasses]
      .sort(compareCategoriesByName)
      .map(mapTreeItem);
    return { treeData, originalById };
  }, [categoryClasses]);

  const selected = useMemo<string[]>(() => {
    if (!value) {
      return [];
    }

    const selectedIds = value.map((item) => item.id.toString());

    // HACK We need to manually add the ids of fully selected group nodes here,
    // so that MUI considers them selected for the purposes of selection state
    // management. Otherwise there will be no way to deselect them.
    //
    // This should be removed after reworking SelectableTree to handle all
    // selection logic internally.

    const selectedCategoryClassIds = categoryClasses
      .filter((categoryClass) => {
        return categoryClass.categoryList.every((category) =>
          selectedIds.includes(category.id.toString())
        );
      })
      .map((categoryClass) => categoryClass.id.toString());

    return [...selectedIds, ...selectedCategoryClassIds];
  }, [value, categoryClasses]);

  // This is for the most part copied from CustomerGroupPicker and will be
  // removed after SelectableTree component is reworked.

  const handleChange = (newSelected: string[] = []) => {
    const removedIds = selected.filter((id) => !newSelected.includes(id));
    const addedIds = newSelected.filter((id) => !selected.includes(id));

    function flatMapCategories(id: string): Category[] {
      const original = originalById.get(id);

      if (!original) {
        return [];
      }

      if (isCategoryClass(original)) {
        return original.categoryList ?? [];
      } else {
        return [original];
      }
    }

    const removed = removedIds.flatMap((id) => flatMapCategories(id));
    const added = addedIds.flatMap((id) => flatMapCategories(id));

    const draft = new Set(value);

    for (const category of removed) {
      draft.delete(category);
    }

    for (const category of added) {
      draft.add(category);
    }

    const asArray = [...draft];

    onChange(asArray);
  };

  return (
    <SelectableTree
      multiple={multiple}
      selectable={multiple ? 'all' : 'leaf'}
      data={treeData}
      selected={selected}
      onChange={(value) => {
        Array.isArray(value) ? handleChange(value) : handleChange([value]);
      }}
      expanded={expanded}
      onNodeToggle={onNodeToggle}
    />
  );
}

function isCategoryClass(
  item: CategoryClass | Category
): item is CategoryClass {
  return 'categoryList' in item;
}

const CATEGORY_CLASS_FIELDS: CategoryClass = {
  id: null,
  name: null,
  categoryList: [
    {
      id: null,
      name: null,
    },
  ],
};
