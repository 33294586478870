import styles from './Chip.module.scss';

import ClearIcon from '@mui/icons-material/Clear';
import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material';
import clsx from 'clsx';

export interface ChipProps extends Pick<MuiChipProps, 'color' | 'classes'> {
  label: React.ReactNode;
  caption?: string;
  handleDelete?: () => void;
  onClick?: () => void;
  className?: string;
  maxWidth?: 8 | 18 | false;
  withSeperator?: boolean;
  disabled?: boolean;
  /**
   * The color of the component.
   * @default 'default'
   */
  color?: 'default' | 'primary';
}

export const Chip = (props: ChipProps) => {
  const {
    label,
    caption,
    handleDelete,
    onClick,
    maxWidth = 8,
    withSeperator = true,
    disabled = false,
    color,
  } = props;
  return (
    <MuiChip
      className={clsx(
        styles.chip,
        props.className,
        styles[`constrained--${maxWidth}`]
      )}
      classes={{ label: styles.label, ...props.classes }}
      deleteIcon={
        handleDelete && <ClearIcon className={styles['clear-icon']} />
      }
      label={
        <>
          <span>{label}</span>
          {caption && (
            <span className={styles.caption}>
              {withSeperator ? <>&nbsp;|&nbsp;</> : <>&nbsp;</>}
              {caption}
            </span>
          )}
        </>
      }
      color={color}
      onDelete={!disabled && handleDelete ? handleDelete : undefined}
      onClick={onClick}
    />
  );
};
