import { Stack } from '@mui/material';

import {
  EntityTableView,
  resolveEntityVariant,
} from '../../../file-entities-lists';

export const RE_APPROVAL_ENTITY = 'reApproval';

export const InvoicesApprovalContainer = () => {
  const resolved = resolveEntityVariant(RE_APPROVAL_ENTITY);
  const { entityType, entityVariant, prefilter } = resolved;

  return (
    <Stack height="100%" width="100%">
      <EntityTableView
        entityType={entityType}
        prefilter={prefilter}
        entityVariant={entityVariant}
      />
    </Stack>
  );
};
