import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { LetterTemplateType } from '../Enums/LetterTemplateType.enum';

import { FileEntity } from './FileEntity.entity';
import { TemplateGroup } from './TemplateGroup.entity';

export class WordDocumentTemplate<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: templateFilename */
  templateFilename?: string;
  /** Alias: body */
  body?: string;
  /** Alias: title */
  title?: string;
  /** Alias: serialLetter */
  serialLetter?: boolean;
  /** Alias: templateType */
  templateType?: LetterTemplateType;
  /** Alias: groupCode */
  groupId?: number;
  /** Alias: folder */
  folder?: string;
  /** Alias: documentSubClass */
  documentSubClass?: string;
  /** Alias: templateGroup */
  templateGroup?: TemplateGroup<T>;
  /** Alias: fileInfos */
  fileInfos?: FileEntity<T>;
  /** Alias: settings */
  settings?: string;

  __typename?: string;
}

const fields: FieldDefinitions<WordDocumentTemplate> = {
  id: {
    alias: 'code',
  },
  templateFilename: {
    alias: 'templateFilename',
  },
  body: {
    alias: 'body',
  },
  title: {
    alias: 'title',
  },
  serialLetter: {
    alias: 'serialLetter',
  },
  templateType: {
    alias: 'templateType',
  },
  groupId: {
    alias: 'groupCode',
  },
  folder: {
    alias: 'folder',
  },
  documentSubClass: {
    alias: 'documentSubClass',
  },
  templateGroup: {
    alias: 'templateGroup',
    entity: Entities.templateGroup,
  },
  fileInfos: {
    alias: 'fileInfos',
    entity: Entities.fileEntity,
  },
  settings: {
    alias: 'settings',
  },
  __typename: {
    alias: '__typename',
  },
};

export const wordDocumentTemplateEntityDefinition: EntitiyDefinition<WordDocumentTemplate> =
  {
    local: {},
    remote: {
      queryName: 'getWordDocumentTemplates',
      fragmentName: 'WordDocumentTemplate',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertDocumentTemplate',
          args: [
            { name: 'input', type: 'InputWordDocumentTemplate!' },
            { name: 'relations', type: 'InputWordTemplateRelation' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
