import { useContext, useMemo } from 'react';

import { IResponse, useDataProvider } from '@work4all/data';
import { FavoritesContext } from '@work4all/data/lib/hooks/favorites/FavoritesProvider';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import {
  DataRequest,
  KeysArguments,
  SortDirection,
} from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData } from '@work4all/models/lib/File';

import { usePatchedDefaultData } from '../../../../../../dataDisplay/basic-table';
import { usePickerSettings } from '../../../../hooks/use-picker-settings';
import { EntityLike } from '../../../../types';
import { IFilterPickerConfig } from '../../../list-entity-picker/use-filter-picker-config';
import {
  prepareRequest,
  prepareSettingsFilters,
} from '../../../list-entity-picker/utils';

import { filterAndSortResult } from './filter-and-sort-result';

const PAGE_SIZE = 100;
const MAX_SEARCH_HISTORY_ITEMS = 5;

export interface UseEntityPickerRegularResultProps<Value> {
  entity: Entities;

  data: Value;

  /**
   * You can configure this picker to be used as a "smart filter" by providing
   * parent entity type, the name of the property of the parent entity that this
   * picker is used for and the value of the current filter.
   *
   * When this config is provided, the picker will only display items that are
   * not filtered out by the existing filter and will also display the number of
   * entities that will be matched if a given list item is selected.
   */
  filterConfig?: IFilterPickerConfig;

  /**
   * Field name that will be used to specify sort for GraphQL query.
   */
  sortBy: string;

  /**
   * Field name that will be used to specify sort direction for GraphQL query.
   */
  sortByDirection?: SortDirection;

  /** Field name that will be used to create a filter for GraphQL query. */
  filterBy: string | string[];

  query: string;

  showFavorites?: boolean;

  /**
   * Any additional filters to send in GraphQL query.
   *
   * As these filters are prepended at the beginning of the filters list,
   * if you add a filter with the same field as in `filterBy`,
   * it will be overrriden by the generated filter.
   */
  prefilter?: unknown[];

  /**
   * This is prefilter which is applied when search query exist instead of prefilter field.
   * If it's empty prefilter will be applied as default.
   */
  searchPrefilter?: unknown[];

  /**
   * @default false
   */
  useSearchHistory?: boolean;

  keysArguments?: KeysArguments;

  vars?: Record<string, unknown>;

  /**
   * Transform the value returned from `useDataProvider` before using it. This
   * can be useful if you want to construct a custom picker that doesn't have a
   * well suited API query, or if you want to map the results to some other
   * entity type.
   *
   * Due to how the `useDataProvider` works, this function can be called with
   * data loaded for a different tabs (when using multiple tabs). If you load
   * differently shaped data in different tabs, you should make sure that this
   * function can gracefully fall back when called with an unsupported dataset.
   */
  transformResponse?: (response: IResponse<Value>) => IResponse<Value>;
}

export function useEntityPickerRegularResult<Value extends EntityLike>(
  props: UseEntityPickerRegularResultProps<Value>
) {
  const {
    entity,
    data,
    filterConfig = null,
    sortBy,
    sortByDirection,
    filterBy,
    query,
    prefilter,
    searchPrefilter,
    vars = {},
    keysArguments,
    showFavorites = false,
    useSearchHistory: useSearchHistoryProp,
    transformResponse: transformResponseProp,
  } = props;

  const definition = entityDefinition[entity];
  const completeDataResponse = !definition.remote.withPaginationWrapper;

  const { lastSearchItems } = useSearchHistory();

  const { filteredEntitiesSetting, sortedPickerEntities } =
    usePickerSettings(entity);

  const hasFilterContext = filterConfig !== null;

  const favoritesContext = useContext(FavoritesContext);

  const enableSearchHistory =
    query === '' &&
    !!useSearchHistoryProp &&
    !hasFilterContext &&
    !showFavorites;

  const searchHistory = useMemo<FileData[]>(() => {
    if (!enableSearchHistory) return [];
    return lastSearchItems[entity]?.slice(0, MAX_SEARCH_HISTORY_ITEMS) ?? [];
  }, [enableSearchHistory, lastSearchItems, entity]);

  const preparedSettingsFilter = useMemo(
    () => prepareSettingsFilters(filteredEntitiesSetting),
    [filteredEntitiesSetting]
  );

  const dataRequest = useMemo<DataRequest>(() => {
    return prepareRequest({
      entity,
      query,
      prefilter,
      data,
      filterBy,
      searchPrefilter,
      settingsFilter: preparedSettingsFilter,
      sortBy: sortedPickerEntities ?? sortBy,
      sortByDirection,
      searchHistory,
      skip: hasFilterContext,
      vars,
      showFavorites,
      keysArguments,
      completeDataResponse,
    });
  }, [
    completeDataResponse,
    data,
    entity,
    filterBy,
    hasFilterContext,
    keysArguments,
    prefilter,
    preparedSettingsFilter,
    query,
    searchHistory,
    searchPrefilter,
    showFavorites,
    sortBy,
    sortByDirection,
    sortedPickerEntities,
    vars,
  ]);

  const transformedDataRequest = showFavorites
    ? favoritesContext.transformDataRequest({ ...dataRequest })
    : dataRequest;

  const result: IResponse<Value> = usePatchedDefaultData(
    useDataProvider<Value>(transformedDataRequest, undefined, PAGE_SIZE),
    PAGE_SIZE
  );

  const transformedResult = useMemo(() => {
    if (transformResponseProp) {
      return transformResponseProp(result);
    }

    return result;
  }, [result, transformResponseProp]);

  const resultSortedManually = useMemo(() => {
    if (!completeDataResponse) {
      return transformedResult;
    }

    return filterAndSortResult(transformedResult, {
      query,
      filterBy,
      sortBy,
      sortByDirection,
    });
  }, [
    completeDataResponse,
    filterBy,
    query,
    transformedResult,
    sortBy,
    sortByDirection,
  ]);

  const resultSortedByHistory = useMemo(() => {
    if (searchHistory.length === 0) {
      return resultSortedManually;
    }

    return sortBySearchHistoryOrder(resultSortedManually);

    function sortBySearchHistoryOrder(
      result: IResponse<Value>
    ): IResponse<Value> {
      const map = new Map(result.data.map((item) => [item.id, item]));

      const itemsSorted: Value[] = [];

      for (const searchHistoryItem of searchHistory) {
        const id = Number(searchHistoryItem.id);
        const item = map.get(id);

        if (item) {
          itemsSorted.push(item);
          map.delete(id);
        }
      }

      itemsSorted.push(...map.values());

      return { ...result, data: itemsSorted };
    }
  }, [searchHistory, resultSortedManually]);

  return resultSortedByHistory;
}
