import styles from './TimePicker.module.scss';

import { Capacitor } from '@capacitor/core';
import { Datepicker } from '@mobiscroll/react';
import { Paper, Theme, useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import React from 'react';

import { useMobiscrollLanguage } from '../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { timeUtils } from '../time-input-picker/timeUtils';

export interface ITimePickerProps {
  timeValue?: DateTime | string;
  onTimeSelect?: (dt: DateTime) => void;
  onDestroyPopover?: () => void;
}

export const TimePicker: React.FC<ITimePickerProps> = (props) => {
  const { stringToTime } = timeUtils;

  let defaultValue = null;
  let dt: DateTime | null = null;
  if (typeof props.timeValue === 'string') {
    dt = stringToTime(props.timeValue);
  } else if (props.timeValue) {
    dt = props.timeValue;
  }

  defaultValue = dt && dt.isValid ? dt : undefined;
  const language = useMobiscrollLanguage();

  const platform = Capacitor.getPlatform();
  const isMobileApp = platform === 'android' || platform === 'ios';
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Paper className={styles.root}>
      <Datepicker
        locale={language}
        touchUi={true}
        display="inline"
        controls={['time']}
        defaultValue={defaultValue}
        timeFormat="HH:mm"
        onChange={(ev) => {
          if (props.onTimeSelect) {
            props.onTimeSelect(DateTime.fromJSDate(ev.value as Date));
          }
        }}
        {...(isMobileApp || isMobile
          ? { onDestroy: props.onDestroyPopover }
          : undefined)}
      />
    </Paper>
  );
};
