import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import { MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ReactComponent as DuplicateIcon } from '@work4all/assets/icons/duplicate.svg';
import { ReactComponent as AddIcon } from '@work4all/assets/icons/outline-add-24.svg';

import { SplitButton } from '@work4all/components/lib/components/split-button/SplitButton';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { ChecklistPositionKind } from '@work4all/models/lib/Enums/ChecklistPositionKind.enum';

import { RowSizeMode } from '../../../../../../../../../../settings/settings';
import { EditStateContext } from '../../../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import { RowSizePicker } from '../../../../../../erp/components/tab-panels/positions/components/row-size-picker/RowSizePicker';

import { CheckListPositionsTableHeaderColumnsVisibility } from './CheckListPositionsTableHeaderColumnsVisibility';

type Props = {
  onAdd: (context: EditStateContext) => void;
  onDelete: () => void;
  onClone: () => void;
  deleteDisabled: boolean;
  cloneDisabled: boolean;
  tableInstanceRef: MutableRefObject<TableInstance> | undefined;
  rowSize: RowSizeMode;
  onRowSizeChange: (value: RowSizeMode) => void;
  automaticNumbering: boolean;
  onAutomaticNumberingChange: (value: boolean) => void;
};

export const CheckListPositionsTableHeader = ({
  onAdd,
  onClone,
  onDelete,
  deleteDisabled,
  cloneDisabled,
  tableInstanceRef,
  rowSize,
  onRowSizeChange,
  automaticNumbering,
  onAutomaticNumberingChange,
}: Props) => {
  const { t } = useTranslation();

  const splitButtonOptions = useMemo(() => {
    return [
      {
        id: 'add-row',
        name: t('CHECK_LIST_POSITIONS_TABLE.LINE'),
        onClick: () => onAdd({ kind: ChecklistPositionKind.DEFAULT }),
        icon: <AddIcon />,
      },
      {
        id: 'add-default-row',
        name: t('CHECK_LIST_POSITIONS_TABLE.NEW_LINE'),
        onClick: () => onAdd({ kind: ChecklistPositionKind.DEFAULT }),
      },
      {
        id: 'add-title-row',
        name: t('CHECK_LIST_POSITIONS_TABLE.NEW_TITLE_LINE'),
        onClick: () => onAdd({ kind: ChecklistPositionKind.HEADLINE }),
      },
      {
        id: 'add-empty-row',
        name: t('CHECK_LIST_POSITIONS_TABLE.NEW_EMPTY_LINE'),
        onClick: () => onAdd({ kind: ChecklistPositionKind.EMPTY_ROW }),
      },
    ];
  }, [onAdd, t]);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      paddingLeft="12px"
      alignItems="center"
    >
      <Box display="flex">
        <SplitButton
          firstIsTitle
          hideSelection
          hideGroupLabels
          variant="outlined"
          options={splitButtonOptions}
          onClick={(option) => {
            option.onClick();
          }}
          renderItem={(option) => {
            return (
              <Box display="flex" gap="0.75rem" alignItems="center">
                {option.icon}
                {option.name}
              </Box>
            );
          }}
        />

        <IconButton onClick={onClone} disabled={cloneDisabled}>
          <DuplicateIcon />
        </IconButton>

        <IconButton onClick={onDelete} disabled={deleteDisabled}>
          <DeleteIcon color="primary" />
        </IconButton>
      </Box>

      <Box display="flex" alignItems="center">
        <CheckboxRadioItem
          checked={automaticNumbering}
          onChange={(e) => {
            onAutomaticNumberingChange(e.target.checked);
          }}
          label={t('CHECK_LIST_POSITIONS_TABLE.AUTOMATIC_NUMBERS')}
          horizontalPadding
          verticalPadding
        />

        <RowSizePicker value={rowSize} onChange={onRowSizeChange} />

        <CheckListPositionsTableHeaderColumnsVisibility
          tableInstanceRef={tableInstanceRef}
        />
      </Box>
    </Box>
  );
};
