import { Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { ProjectProcessPicker } from '@work4all/components/lib/components/entity-picker/project-process-picker/ProjectProcessPicker';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';

import { PickerTargetButton } from './PickerTargetButton';

type Props = {
  disabled?: boolean;
  value: ProjectProcess;
  onChange: (value: ProjectProcess | null) => void;
  projectId: number;
};

export function ProjectProcessPickerCell({
  disabled,
  value,
  onChange,
  projectId,
}: Props) {
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <ProjectProcessPicker
          value={value}
          onChange={onChange}
          multiple={false}
          projectId={projectId}
        />
      }
    >
      <PickerTargetButton disabled={disabled}>
        <Typography style={{ display: 'flex', alignItems: 'center' }}>
          {value?.process}
        </Typography>
      </PickerTargetButton>
    </EntityPickerPopover>
  );
}
