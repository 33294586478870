import styles from './DescriptionContent.module.scss';

import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { DateTimeInputPicker } from '@work4all/components/lib/input/date-time-input-picker';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { UserPickerField } from '../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlus } from '../../../../../../form-plus/controller-plus';
import { Collapse, ControlWrapper } from '../../../../../components';

export const DescriptionContent = () => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Collapse title={t('COMMON.DESCRIPTION')} defaultOpen>
      <ControlWrapper columns={2}>
        <ControllerPlus
          control={control}
          name="title"
          render={({ field }) => {
            return <LabeledInput {...field} label={t('COMMON.SUBJECT')} />;
          }}
        />

        <ControlWrapper columns={2}>
          <ControllerPlus
            control={control}
            name="user"
            render={({ field }) => {
              return <UserPickerField {...field} label={t('COMMON.USER')} />;
            }}
          />

          <ControlWrapper
            columns={2}
            className={styles.dateAndIsSampleTemplateWrapper}
          >
            <ControllerPlus
              control={control}
              name="date"
              render={({ field }) => {
                return (
                  <DateTimeInputPicker
                    {...field}
                    withTime={false}
                    clearable={false}
                    dateLabel={t('COMMON.DATE')}
                  />
                );
              }}
            />

            <ControllerPlus
              control={control}
              name="isSampleTemplate"
              render={({ field }) => {
                return (
                  <CheckboxRadioItem
                    label={t('FIELD.SAMPLE_TEMPLATE')}
                    horizontalPadding={false}
                    control="checkbox"
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                    checked={field.value ?? false}
                  />
                );
              }}
            />
          </ControlWrapper>
        </ControlWrapper>
      </ControlWrapper>
    </Collapse>
  );
};
