import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { VatRate } from './VatRate.entity';

export class TaxKey<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: steuerschluesselValue */
  taxKeyValue?: number;
  /** Alias: mwst */
  vat?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: kreditor */
  creditor?: boolean;
  /** Alias: konto */
  accountId?: number;
  /** Alias: mehrwertsteuerSatz */
  vatRate?: VatRate<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TaxKey> = {
  id: {
    alias: 'code',
  },
  taxKeyValue: {
    alias: 'steuerschluesselValue',
  },
  vat: {
    alias: 'mwst',
  },
  note: {
    alias: 'notiz',
  },
  creditor: {
    alias: 'kreditor',
  },
  accountId: {
    alias: 'konto',
  },
  vatRate: {
    alias: 'mehrwertsteuerSatz',
    entity: Entities.vatRate,
  },
  __typename: {
    alias: '__typename',
  },
};

export const taxKeyEntityDefinition: EntitiyDefinition<TaxKey> = {
  local: {},
  remote: {
    queryName: 'getSteuerschluessel',
    fragmentName: 'Steuerschluessel',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
