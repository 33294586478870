import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useProjectCaptions } from '../../../../../hooks';
import { settings, useSetting } from '../../../../../settings';
import {
  customerDisplayItems,
  projectDisplayItems,
  supplierDisplayItems,
} from '../file-info-panel/consts';
import { IFileInfoPanelItems } from '../file-info-panel/types';

interface FileSetting {
  id: IFileInfoPanelItems;
  label: string;
  disabled?: boolean;
}

export function useFileSettingsGroups(entity: Entities) {
  const { t } = useTranslation();
  const {
    internalProjectManagerCaption,
    externalProjectManagerCaption,
    eventStartEndDateCaption,
    projectStartEndDateCaption,
  } = useProjectCaptions();

  const allGroups = useMemo(() => {
    const groups: FileSetting[][] = [
      [
        {
          id: IFileInfoPanelItems.Address,
          label: t('FILE_CONTACTS.ADDRESS'),
        },
        {
          id: IFileInfoPanelItems.Number,
          label: t('INPUTS.NUMBER'),
        },
        {
          id: IFileInfoPanelItems.Kind,
          label: t('COMMON.KIND'),
        },
        {
          id: IFileInfoPanelItems.ProjectDirectory,
          label: t('COMMON.PROJECT_DIRECTORY'),
        },
        {
          id: IFileInfoPanelItems.ParentProject,
          label: t('FILE_CONTACTS.PARENT_PROJECT'),
        },
        {
          id: IFileInfoPanelItems.Status,
          label: t('FILE_CONTACTS.STATUS'),
        },
        {
          id: IFileInfoPanelItems.Phone,
          label: t('INPUTS.PHONE'),
        },
        {
          id: IFileInfoPanelItems.ContactOptions,
          label: t('INPUTS.CONTACT_PERSON'),
        },
      ],
      [
        {
          id: IFileInfoPanelItems.Group,
          label: t('FILE_CONTACTS.GROUP'),
        },
        {
          id: IFileInfoPanelItems.CustomerSupplier,
          label: t('COMMON.BUSINESS_PARTNER'),
        },
        {
          id: IFileInfoPanelItems.FirstContact,
          label: t('FILE_CONTACTS.FIRSTCONTACT'),
        },
        {
          id: IFileInfoPanelItems.SubscriptionContract,
          label: t('FILE_SETTINGS.SUBSCRIPTION_CONTRACT'),
        },
        {
          id: IFileInfoPanelItems.Opportunities,
          label: t('FILE_SETTINGS.OPPORTUNITIES'),
        },
        {
          id: IFileInfoPanelItems.InternalProjectLeaders,
          label: internalProjectManagerCaption,
        },
        {
          id: IFileInfoPanelItems.CustomerProjectLeaders,
          label: externalProjectManagerCaption,
        },
      ],
      [
        {
          id: IFileInfoPanelItems.Payment,
          label: t('INPUTS.PAYMENT'),
        },
        {
          id: IFileInfoPanelItems.OpenDuePositions,
          label: t('FILE_CONTACTS.OPEN_POSITIONS'),
        },
        {
          id: IFileInfoPanelItems.Sales,
          label: t('DASHBOARD.SALES'),
        },
      ],
      [
        {
          id: IFileInfoPanelItems.ProjectPeriod,
          label: projectStartEndDateCaption,
        },
        {
          id: IFileInfoPanelItems.EventPeriod,
          label: eventStartEndDateCaption,
        },
        {
          id: IFileInfoPanelItems.Budget,
          label: t('COMMON.BUDGET'),
        },
        {
          id: IFileInfoPanelItems.ContactList,
          label: t('FILE_CONTACTS.CONTACTS'),
        },
      ],
      [
        {
          id: IFileInfoPanelItems.Categories,
          label: t('INPUTS.CATEGORIES'),
        },
      ],
      [
        {
          id: IFileInfoPanelItems.Relations,
          label: t('MASK.RELATIONS'),
        },
      ],
      [
        {
          id: IFileInfoPanelItems.Note,
          label: t('INPUTS.NOTE'),
        },
      ],
      [
        {
          id: IFileInfoPanelItems.ProjectPlanning,
          label: t('COMMON.PROJECT_PLANNING'),
        },
      ],
    ];

    return groups;
  }, [
    eventStartEndDateCaption,
    externalProjectManagerCaption,
    internalProjectManagerCaption,
    projectStartEndDateCaption,
    t,
  ]);

  const items =
    entity === Entities.project
      ? projectDisplayItems
      : entity === Entities.supplier
      ? supplierDisplayItems
      : customerDisplayItems;

  const outputGroups: FileSetting[][] = [];
  allGroups.forEach((group) => {
    const finalGroup = group.filter((x) => items.includes(x.id));
    if (finalGroup.length > 0) outputGroups.push(finalGroup);
  });
  return outputGroups;
}

export const useFilePreview = (entity: Entities) => {
  const groups = useFileSettingsGroups(entity);
  const setting = useSetting(settings.fileSettings(entity));

  const outputGroups: FileSetting[][] = [];
  groups.forEach((group) => {
    const finalGroup = group.filter((x) => setting.value[x.id]);
    if (finalGroup.length > 0) outputGroups.push(finalGroup);
  });
  return outputGroups;
};
