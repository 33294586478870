import { useTableStateBag } from '@work4all/components';

import { throwInDev } from '@work4all/utils';

import { settings, useSetting } from '../../../../../settings';
import { createHandleColumnFilterChange } from '../../utils';
import { QUICK_COLUMN_FILTER_PICKERS_CONFIGS_BY_ENTITY } from '../constants';
import {
  QuickColumnFilterPickerConfigs,
  QuickColumnFilterPickerProps,
} from '../types';

export const QuickColumnFilterPicker = ({
  entity,
  type,
  multiple,
}: QuickColumnFilterPickerProps) => {
  const configs = QUICK_COLUMN_FILTER_PICKERS_CONFIGS_BY_ENTITY[entity]?.[type];

  const treeViewExpandedItems = useSetting(
    settings.treeViewExpandedItems(entity, type)
  );

  const additionalProps = ['group', 'category'].includes(type)
    ? {
        expanded: treeViewExpandedItems.value,
        onNodeToggle: (_, ids) => treeViewExpandedItems.set(ids),
      }
    : {};

  if (!configs) {
    throwInDev(
      `Missing QuickColumnFilterPicker configs for entity ${entity} and type ${type} `
    );

    return null;
  }

  return (
    <QuickColumnFilterPickerChild
      configs={configs}
      multiple={multiple}
      additionalProps={additionalProps}
    />
  );
};

export const QuickColumnFilterPickerChild = ({
  configs,
  multiple,
  additionalProps,
}: {
  configs: QuickColumnFilterPickerConfigs;
} & Pick<QuickColumnFilterPickerProps, 'multiple'> & {
    additionalProps?;
  }) => {
  const tableStateBag = useTableStateBag();

  const column = tableStateBag.columnsById[configs.id];
  const value = column?.filterValue?.value ?? null;
  const handleChange = createHandleColumnFilterChange(column);

  const Picker = configs.picker;

  return (
    <Picker
      value={value}
      onChange={handleChange}
      multiple={multiple}
      {...additionalProps}
    />
  );
};
