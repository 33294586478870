import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useProjectWithProcesses = (projectIdList: number[]) => {
  const req = useMemo<DataRequest>(() => {
    return {
      data: PROJECT_DATA,
      entity: Entities.project,
      filter: projectIdList.length
        ? [
            {
              id: { $in: projectIdList.filter(Boolean) },
            },
          ]
        : undefined,
    };
  }, [projectIdList]);

  return useDataProvider<Project>(req, projectIdList.length === 0);
};

const PROJECT_DATA: Project = {
  id: null,
  name: null,
  number: null,
  startDateInner: null,
  startDateOuter: null,
  endDateInner: null,
  endDateOuter: null,
  projectProcessList: [
    {
      id: null,
      process: null,
      duration: null,
      startDatum: null,
      endDateInner: null,
      parentId: null,
      parent: {
        id: null,
        process: null,
      },
      comment: null,
      kindId: null,
      isClosed: null,
      number: null,
      planningAmount: null,
      planningCosts: null,
      ressource: null,
      projectId: null,
      ressourceClassId: null,
      index: null,
      customer: {
        id: null,
        name: null,
        number: null,
      },
      supplier: {
        id: null,
        name: null,
        number: null,
      },
      project: {
        id: null,
        name: null,
        number: null,
      },
      completionFeedbacks: [
        {
          id: null,
          completionPercent: null,
        },
      ],
      projectProcessAppointmentList: [
        {
          id: null,
          projectProcessDuration: null,
        },
      ],
      ressourceClass: {
        id: null,
        color: null,
        name: null,
        hexColorPair: null,
      },
      projectStepLinksIncomming: [
        {
          id: null,
          linkType: null,
          projectId: null,
          projectProcess1Id: null,
          projectProcess2Id: null,
        },
      ],
      projectStepLinksOutgoing: [
        {
          id: null,
          linkType: null,
          projectId: null,
          projectProcess1Id: null,
          projectProcess2Id: null,
        },
      ],
    },
  ],
};
