import { Warning } from '@mui/icons-material';
import ModeIcon from '@mui/icons-material/Mode';
import { IconButton, Theme, Tooltip, useMediaQuery } from '@mui/material';
import produce from 'immer';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InboundIcon } from '@work4all/assets/icons/email-actions/inbound.svg';
import { ReactComponent as OutboundIcon } from '@work4all/assets/icons/email-actions/outbound.svg';

import { FlagCell } from '@work4all/components';
import {
  CardWidget,
  ICardWidgetColumnDef,
  ICardWidgetProps,
  ICardWidgetRenderCellParams,
  IGetRowModifiers,
} from '@work4all/components/lib/dataDisplay/card-widget';
import { WidgetDragDropProvider } from '@work4all/components/lib/dataDisplay/card-widget/hooks/use-widget-drag-drop-context';

import {
  AppParts,
  useCanView,
  useInaccessibleFields,
  useNavigate,
  widgetArrayColumns,
} from '@work4all/data';
import { usePermissions } from '@work4all/data/lib/hooks/use-permissions';
import { canFlagEntityRecordsAsFavorite } from '@work4all/data/lib/utils/can-flag-entity-records-as-favorite';

import {
  EntityByLayoutType,
  ILayoutDefinition,
  RowModifierCondition,
  RowModifierConditionRule,
} from '@work4all/models';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { invariant } from '@work4all/utils';

import { useToggleItemFavorability } from '../../../../../../hooks/use-toggle-Item-favorability';
import { settings, useSetting } from '../../../../../../settings';
import { FileContext } from '../../FileContext';
import { useEntityLinks } from '../hooks/useEntityLinks';
import { SelectedItemContext } from '../selected-item-context';

import { BookingsTotalAmountPercentage, InlineFlagCell } from './components';
import { useCardDnd } from './hooks/use-card-dnd';

type ICardWidgetContainerGeneratedProps<T> = Pick<
  ICardWidgetProps<T>,
  | 'isSelected'
  | 'getItemHref'
  | 'listHref'
  | 'newHref'
  | 'onItemClick'
  | 'onItemDoubleClick'
  | 'columns'
  | 'getRowModifiers'
  | 'getCellModifiers'
  | 'entity'
>;

export function CardWidgetContainer<T extends { id?: number | string }>(
  props: Omit<
    ICardWidgetProps<T>,
    keyof ICardWidgetContainerGeneratedProps<T>
  > & {
    definition: ILayoutDefinition;
  }
) {
  const { definition, ...propsWithoutDefinition } = props;

  const { item, setItem } = useContext(SelectedItemContext);

  const navigate = useNavigate();

  const { columns, getRowModifiers, entityType } = useWidgetConfig(definition);
  const isEditableEntity = useCanView(AppParts.EDIT_MASK_OF_ENTITY, entityType);

  const { listHref, newHref, getItemHref } = useEntityLinks(
    definition,
    entityType
  );

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { canAdd: canAddChecker, canEdit } = usePermissions();
  const canAdd = canAddChecker({ entity: entityType });
  const dndValue = useCardDnd(entityType);

  const fileContext = useContext(FileContext);

  const businessPartnerLocked = (fileContext?.data as Customer | Supplier)
    ?.locked;

  const fileLockedDisableDeliveryNote = useSetting(
    settings.fileLockedDisableDeliveryNote()
  );

  const fileLockedDisableContract = useSetting(
    settings.fileLockedDisableContract()
  );

  const fileLockedDisableOrder = useSetting(settings.fileLockedDisableOrder());

  let widgetLocked = false;
  let lockedLabel = null;

  if (businessPartnerLocked) {
    if (entityType === Entities.contract && fileLockedDisableContract.value) {
      widgetLocked = true;
      lockedLabel = t('FILE.CONTRACT_LOCKED');
    }
    if (
      entityType === Entities.deliveryNote &&
      fileLockedDisableDeliveryNote.value
    ) {
      widgetLocked = true;
      lockedLabel = t('FILE.DELIVERYNOTE_LOCKED');
    }
    if (entityType === Entities.order && fileLockedDisableOrder.value) {
      widgetLocked = true;
      lockedLabel = t('FILE.ORDER_LOCKED');
    }
  }

  return (
    <WidgetDragDropProvider value={dndValue}>
      <CardWidget<T>
        entity={entityType}
        getItemHref={getItemHref}
        listHref={listHref}
        newHref={newHref}
        columns={columns}
        isSelected={({ id }) => id === item?.id && entityType === item?.type}
        onItemClick={({ id }) => {
          setItem({ widgetId: definition.id, id, type: entityType });
        }}
        onItemDoubleClick={(clickedRecord) => {
          const userHasEditRight = canEdit({
            entity: entityType,
            record: clickedRecord,
          });

          if (!isEditableEntity) {
            if (clickedRecord) {
              const { id } = clickedRecord;
              setItem({ widgetId: definition.id, id, type: entityType });
            }
          } else if (userHasEditRight) {
            navigate(getItemHref(clickedRecord));
          } else {
            const entity = entityType.toUpperCase();
            const entityTranslation = i18n.exists(`MORE.${entity}`)
              ? t(`MORE.${entity}`)
              : t(`MORE.${entity}S`);

            enqueueSnackbar(
              t('ERROR.MISSING_RIGHTS', {
                entity: entityTranslation,
              }),
              { variant: 'error', autoHideDuration: 6000 }
            );
          }
        }}
        {...propsWithoutDefinition}
        getRowModifiers={getRowModifiers}
        maxRows={props.maxRows}
        disabledAdd={{
          disabled: !canAdd || widgetLocked,
        }}
        additionalActions={
          widgetLocked && (
            <Tooltip title={lockedLabel}>
              <IconButton>
                <Warning color="error" />
              </IconButton>
            </Tooltip>
          )
        }
      />
    </WidgetDragDropProvider>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cellRenderers: Record<string, ICardWidgetColumnDef<any>['renderCell']> = {
  'email-kind': renderEMailKindIcon,
  'invoice-credit': renderInvoiceCreditValue,
  'bookings-total-amount-percentage': renderBookingsTotalAmountPercentage,
  flag: renderFlag,
  'inline-flag': renderInlineFlag,
};

export function useWidgetConfig<T>(definition: ILayoutDefinition) {
  // The widget definition config is typed as an array, but at the moment only
  // widgets with a single entity are supported.
  const entity = definition.config.entities[0];

  invariant(entity, 'Invalid widget config');
  const { isInaccessible } = useInaccessibleFields();

  const entityType = EntityByLayoutType[entity.entityTypeName];
  const columnsDef = entity.ui.columns;

  const fileContext = useContext(FileContext);
  const { arrayColumnsUIConfig } = widgetArrayColumns({
    entity: entityType,
    fileType: fileContext.type,
  });
  const toggleItemFavorability = useToggleItemFavorability();

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  const columns = useMemo(() => {
    if (!columnsDef) return [];

    const canFlagAsFavorite = canFlagEntityRecordsAsFavorite(entityType);

    const _columns = produce(columnsDef, (draft) => {
      if (canFlagAsFavorite) {
        if (isDesktop) {
          draft.splice(draft.length - 1, 0, {
            accessor: 'favoriteItem.id',
            type: 'flag',
            primary: false,
            modifiers: [],
          });
        } else {
          const primaryColumn = draft.find((column) => column.primary);
          if (primaryColumn) {
            primaryColumn.type = 'inline-flag';
          }
        }
      }
    });

    /**
     * This is a workaround for the `widgetArrayColumns` because
     * we don't have an `order` property to order each column.
     *
     * Full context: https://work4all.atlassian.net/browse/WW-4577
     */
    const reorderedColumns = (() => {
      if (
        entityType !== Entities.reViewModel ||
        arrayColumnsUIConfig.length === 0
      ) {
        return [..._columns];
      }

      return _columns.flatMap((column, index) => {
        if (index + 1 !== _columns.length) {
          return column;
        }

        return [...arrayColumnsUIConfig, column];
      });
    })();

    const columns: ICardWidgetColumnDef<T>[] = reorderedColumns
      .filter((def) => {
        return !isInaccessible(entityType, def.accessor);
      })
      .map((def) => {
        const {
          accessor: field,
          primary = false,
          type = 'string',
          align = type === 'number' ? 'right' : 'left',
          modifiers = [],
        } = def;

        const renderCell = cellRenderers[type];

        const column: ICardWidgetColumnDef<T> = {
          field,
          primary,
          type,
          align,
          modifiers:
            modifiers?.reduce((acc, name) => {
              acc[name] = true;
              return acc;
            }, {}) ?? null,
          renderCell: renderCell
            ? (params) =>
                renderCell({
                  ...params,
                  fileId: Number(fileContext.id),
                  onClick:
                    type !== 'flag'
                      ? undefined
                      : () =>
                          toggleItemFavorability({
                            id: (params.row as { id: string | number }).id,
                            entity: entityType,
                            isFavorite: params.value,
                          }),
                })
            : undefined,
        };

        return column;
      });

    return columns;
  }, [
    arrayColumnsUIConfig,
    columnsDef,
    entityType,
    fileContext.id,
    isInaccessible,
    isDesktop,
    toggleItemFavorability,
  ]);

  const getRowModifiers = useMemo<IGetRowModifiers<T>>(() => {
    const rowModifiers = entity.ui.rowModifiers;

    if (!rowModifiers || rowModifiers.length === 0) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getRowModifiers: IGetRowModifiers<any> = ({ row }) => {
      const modifiers = {};

      rowModifiers
        .filter((modifier) => modifier.type === 'StyleModifier')
        .forEach((modifier) => {
          for (const rule of modifier.rules) {
            if (testStyleModifier(rule.condition, row)) {
              for (const name of rule.styles) {
                modifiers[name] = true;
              }
            }
          }
        });

      return modifiers;
    };

    return getRowModifiers;
  }, [entity.ui.rowModifiers]);

  return {
    entityType,
    columns,
    getRowModifiers,
  };
}

function renderInvoiceCreditValue(
  params: ICardWidgetRenderCellParams<Invoice>
) {
  return params.value < 0 ? 'G' : '';
}

function renderBookingsTotalAmountPercentage(
  params: ICardWidgetRenderCellParams<ReViewModel>
) {
  return <BookingsTotalAmountPercentage {...params} />;
}

function renderFlag(
  params: ICardWidgetRenderCellParams<{ id: string | number }>
) {
  return (
    <FlagCell
      {...params}
      column={{
        cellParams: {
          onClick: params.onClick,
        },
      }}
    />
  );
}

function renderInlineFlag(
  params: ICardWidgetRenderCellParams<object, unknown>
) {
  return <InlineFlagCell {...params} />;
}

function renderEMailKindIcon(params: ICardWidgetRenderCellParams<EMail>) {
  const kind: EMailKind = params.value;

  switch (kind) {
    case EMailKind.AUSGEHEND:
      return <OutboundIcon title="Outbound" />;
    case EMailKind.EINGEHEND_HTML:
    case EMailKind.EINGEHEND:
      return <InboundIcon title="Inbound" />;
    case EMailKind.ENTWURF:
    case EMailKind.ENTWURF_HTML:
      return <ModeIcon titleAccess="Draft" />;
    default:
      return '?';
  }
}

function testStyleModifier(
  condition: RowModifierCondition,
  row: unknown
): boolean {
  const keys = Object.keys(condition);

  return keys.every((key) => {
    return testStyleModifierOperator(key, condition[key], row);
  });
}

function testStyleModifierOperator(
  path: string,
  rule: RowModifierConditionRule,
  row: unknown
): boolean {
  const value = get(row, path);

  //ToDo what is a good way to express eg: if a porject.closesStatus does not eq 0, but if no project is assinged at all it doesnt cound neither
  //currently in these cases we validate undefined!=0, which is technically not correct because the closedStatus simply does not exist
  if (value === undefined) return false;

  if ('$eq' in rule) {
    return value === rule.$eq;
  } else if ('$ne' in rule) {
    return value !== rule.$ne;
  } else if ('$in' in rule) {
    return rule.$in.includes(value);
  } else if ('$nin' in rule) {
    return !rule.$nin.includes(value);
  } else {
    // The condition has been validated at this point, so this should never
    // happen.
    throw new Error(`Unknown operator`);
  }
}
