import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationArea } from '../../../organisation-area';
import { TableAreas } from '../../table/Table';

import { useProjectBottomArea } from './use-project-bottom-area';

interface IProps {
  selectedProjectList: Project[];
  selectedRessourceClassCell?: { ressourceClass: LookUp; project: Project };
  date: Date;
  selectedUtilizationUserIds?: number[];
}

export const useProjectTableAreas = (props: IProps) => {
  const {
    selectedProjectList,
    selectedRessourceClassCell,
    date,
    selectedUtilizationUserIds,
  } = props;

  const { t } = useTranslation();

  const bottomArea = useProjectBottomArea({
    selectedEntities: selectedProjectList,
    selectedRessourceClassCell,
    date,
    onDateChange: () => null,
    selectedUtilizationUserIds,
  });

  return useMemo<TableAreas>(() => {
    return {
      left: {
        title: t('COMMON.ORGANISATION'),
        content: <OrganisationArea entity={Entities.project} />,
        resizable: true,
        collapsible: true,
        custom: true,
      },
      bottom: bottomArea,
    };
  }, [bottomArea, t]);
};
