export function parseAsFloat(text: string | number | undefined, locale = 'de') {
  if (!text) return 0;
  if (typeof text === 'number') return text;

  // TODO: This works for "en" and "de".
  // TICKET: https://work4all.atlassian.net/browse/WW-4326
  // There are other formats that use different number of characters between thousand separators (like 2 or 4).
  // And those won't work here correctly.
  // Get the thousands and decimal separator characters used in the locale.
  const [, thousandsSeparator, , , , decimalSeparator] =
    (1111.1).toLocaleString(locale);
  // Remove thousand separators, and put a point where the decimal separator occurs
  text = Array.from(text, (c) =>
    c === thousandsSeparator ? '' : c === decimalSeparator ? '.' : c
  ).join('');

  // Now it can be parsed
  const result = parseFloat(text);
  return Number.isNaN(result) ? 0 : result;
}

const genZeros = (n: number) => (n && n > 0 ? '0'.repeat(n) : '');

function formatNumber(n: string, separator?: string) {
  const isNegative = n[0] === '-';
  // format number 1000000 to 1,234,567
  let formattedNumber = n.replace(/\D/g, '');
  if (separator)
    formattedNumber = formattedNumber.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      separator
    );
  return isNegative ? '-' + formattedNumber : formattedNumber;
}

interface FormatAsFloatOptions {
  locale?: string;
  isFinished?: boolean;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export function formatAsFloat(
  input: string | number | undefined,
  options: FormatAsFloatOptions = {}
) {
  // TODO: refactor when we will do NumberInput component and use Intl
  // if(!options.isFinished) {
  //   options.minimumFractionDigits = 0;
  // }
  // const currencyFormat = new Intl.NumberFormat('de-DE', options);

  // const numberInput = typeof input === 'number' ? input : parseAsFloat(input);

  // return currencyFormat.format(numberInput);

  const {
    locale = 'de',
    isFinished,
    maximumFractionDigits = 0,
    minimumFractionDigits = 0,
  } = options;

  // get input value
  let inputVal =
    typeof input === 'number'
      ? input.toLocaleString(locale).replace(/\s+/g, '')
      : input || '';

  const [, thousandsSeparator, , , , decimalSeparator] =
    (1111.1).toLocaleString(locale);

  // don't validate empty input
  if (inputVal === '') {
    return;
  }

  // check for decimal
  if (inputVal.indexOf(decimalSeparator) >= 0) {
    // get position of first decimal
    // this prevents multiple decimals from
    // being entered
    const decimal_pos = inputVal.indexOf(decimalSeparator);

    // split number by decimal point
    let leftSide = inputVal.substring(0, decimal_pos);
    let rightSide = inputVal.substring(decimal_pos);

    // add commas to left side of number
    leftSide = formatNumber(leftSide, thousandsSeparator);

    // validate right side, no thousand needed
    rightSide = formatNumber(rightSide);

    // On blur make sure 2 numbers after decimal
    if (isFinished) {
      rightSide += genZeros(minimumFractionDigits - rightSide.length);
    }

    // Limit decimal to only 2 digits
    rightSide = rightSide.substring(0, maximumFractionDigits);

    // join number by thousandsSeparator
    inputVal = leftSide + decimalSeparator + rightSide;
  } else {
    // no decimal entered
    // add commas to number
    // remove all non-digits
    inputVal = formatNumber(inputVal, thousandsSeparator);

    // final formatting
    if (isFinished && minimumFractionDigits > 0) {
      inputVal += `${decimalSeparator}${genZeros(minimumFractionDigits)}`;
    }
  }

  return inputVal;
}

export function formatInputValueAsFloat(
  currentTarget: HTMLInputElement,
  options?: FormatAsFloatOptions
) {
  // get input value
  let inputVal = currentTarget.value || '';

  // don't validate empty input
  if (inputVal === '') {
    return;
  }

  // original length
  const original_len = inputVal.length;

  // initial caret position
  let caret_pos = currentTarget.selectionStart;

  inputVal = formatAsFloat(inputVal, options);

  // send updated string to input
  currentTarget.value = inputVal;

  // put caret back in the right position
  const updated_len = inputVal.length;
  caret_pos = updated_len - original_len + caret_pos;

  if (currentTarget === document.activeElement) {
    currentTarget.setSelectionRange(caret_pos, caret_pos);
  }
  return inputVal;
}
