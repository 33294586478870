import { useEffect } from 'react';

import { PageZoomContext } from '@work4all/data';

import { settings, useSetting } from '../settings';

/**
 * The Zoom Setting is used in attachments, texteditor, and some other reusable components
 * they will adjust their render size according to it, but the setting it self is stored app sepecific
 * so this provider ties the appspecific setting together with the gerneric configuration of the components lib
 **/

export const W4AAppPageZoomProvider = (props) => {
  const { value: zoomLevel, set: setZoomLevel } = useSetting(
    settings.pageZoomLevel()
  );

  useEffect(() => {
    if (!zoomLevel) {
      return;
    }
    const fontSize = getCalculatedFontSize(zoomLevel);
    document.documentElement.style.fontSize = fontSize;
    document.body.style.fontSize = fontSize;
  }, [zoomLevel]);

  return (
    <PageZoomContext.Provider value={{ zoomLevel, setZoomLevel }}>
      {props.children}
    </PageZoomContext.Provider>
  );
};

/**
 * Map zoomLevel to font sizes.
 * This is a bit changed to support mobiscroll autosizing.
 */
function getCalculatedFontSize(zoomLevel: number) {
  switch (zoomLevel) {
    case 80:
      return '11.2px';
    case 90:
      return '12.8px';
    case 100:
      return '14px';
    case 110:
      return '15.2px';
    default:
      return '14px';
  }
}
