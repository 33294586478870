import { get, set } from 'lodash';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ArrayCell,
  ArticleKindCell,
  ArticleSinglePriceCell,
  AvatarsCell,
  CheckboxCell,
  CurrencyCell,
  CurrencyFooterCell,
  DataType,
  DefaultGroupLabel,
  EnumGroupLabel,
  FileIconCell,
  FilterType,
  FlagCell,
  ForbiddenPriceCell,
  HookedDateCell,
  PlaceholderCell,
  RequiredTimeCell,
  TextCell,
  TimeCell,
  TranslatedCell,
  YesOrNoGroupLabel,
} from '@work4all/components';
import { PrepareTableRowModifiers } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useRowDisplayModifiers';

import { useInaccessibleFields, useUser } from '@work4all/data';
import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { FieldDefinitions } from '@work4all/models/lib/DataProvider';
import { CardConfig } from '@work4all/models/lib/table-schema/card-config';
import {
  GroupLabelElementTableConfig,
  GroupLabelElementType,
  ICustomCellConfigBase,
  IDefaultCellConfig,
  ITableColumnConfig,
  ITableRowModifier,
  ITableRowReadStatusModifier,
  ITableRowStyleModifier,
  ITableSchema,
} from '@work4all/models/lib/table-schema/table-schema';

import { assertNever } from '@work4all/utils';

import { CustomFieldCell } from './cell-renderer/CustomFieldCell';
import { UserIconCell } from './cell-renderer/userIconCell';
import { ComposeCell } from './ComposeCell';
import { genericColumns } from './generic-column/GenericColumns';
import { DataTableColumnConfig } from './table/DataTableColumnConfig';
import { useTableColumns } from './use-table-Columns';
import { getColumnId } from './utils';

export type IUseTableConfigOptions<
  CustomColumns extends Record<string, ICustomCellConfigBase>
> = {
  layout: 'table' | 'cards' | 'board';

  schema: ITableSchema<CustomColumns>;

  customFields?: ParsedCustomFieldConfig[];

  /**
   * Custom cells that are specific for this table. Can be added to extend the
   * built-in functionality with logic that is too entity-specific to add
   * to the base `useTableConfig`.
   *
   * The initial value is memoized on the first render and all subsequent
   * changes will be ignored.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cells?: Record<keyof CustomColumns, React.ComponentType<any>>;

  /**
   * Map of [cell type] -> [function] that lets you specify the entity's
   * fields required to render the given cell. The returned array of field
   * names will be used to construct the request for `useDataProvider`.
   *
   * The initial value is memoized on the first render and all subsequent
   * changes will be ignored.
   */
  cellProcessors?: Partial<{
    [ColumnType in keyof CustomColumns]: (
      config: ITableColumnConfig<CustomColumns[ColumnType]>,
      entity?: string
    ) => string[];
  }>;

  /**
   * Read mode colummns which extend table.
   * Use when needed to connect two diferent entities in one table.
   */
  additionalColumns?: DataTableColumnConfig[];

  groupLabelElements?: Record<
    keyof CustomColumns,
    React.ComponentType<unknown>
  >;
};

export type IUseTableConfigReturn = {
  fields: IRequestFieldsDefinition;
  columnConfigs: DataTableColumnConfig[];
  cardConfig: CardConfig;
  prepareRowDisplayModifiers: PrepareTableRowModifiers;
};

type IRequestFieldsDefinition = {
  [key: string]: null | IRequestFieldsDefinition;
};

const DEFAULT_CUSTOM_FIELDS: ParsedCustomFieldConfig[] = [];

export function useTableConfig<
  CustomColumns extends Record<string, ICustomCellConfigBase>
>(options: IUseTableConfigOptions<CustomColumns>): IUseTableConfigReturn {
  const {
    layout,
    schema,
    customFields = DEFAULT_CUSTOM_FIELDS,
    cells,
    cellProcessors,
    additionalColumns,
    groupLabelElements,
  } = options;
  const initialPropsRef = useRef({
    cells,
    cellProcessors: { ...defaultCellProcessors, ...cellProcessors },
    groupLabelElements,
  });

  const { t } = useTranslation();
  const { isInaccessible, isSomeInaccessible } = useInaccessibleFields();

  const allColumns = useTableColumns<CustomColumns>(
    schema.columns,
    customFields
  );

  const fields = useMemo(() => {
    const { cellProcessors } = initialPropsRef.current;

    const fields = new Set<string>();

    function processColumnConfig(
      config: ITableColumnConfig | ITableColumnConfig<CustomColumns[string]>
    ) {
      const resolveCellType = (
        config: ITableColumnConfig | ITableColumnConfig<CustomColumns[string]>
      ): string | undefined => {
        const cell = config.cell;

        if (cell === undefined || typeof cell === 'string') {
          // @ts-expect-error Unable to correctly infer type because of no strict null checks
          return cell;
        }

        return cell.type;
      };

      const cellType = resolveCellType(config);

      if (cellType !== undefined) {
        if (cellProcessors?.[cellType]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          cellProcessors[cellType](config as any, schema.entity).forEach(
            (field) => {
              fields.add(field);
            }
          );
          return;
        }
      }

      const accessor = config.accessor;

      if (typeof accessor === 'string') {
        const isArrayPath = /(.*\.)(\d*)\.(.*)/.exec(accessor);
        if (isArrayPath) {
          fields.add(isArrayPath[1] + isArrayPath[3]);
          return;
        }

        fields.add(accessor);
      } else {
        // This is a custom field.
        fields.add(`customFieldList.key`);
        fields.add(`customFieldList.value`);
      }
    }

    function processRowModifier(config: ITableRowModifier) {
      const configType = config.type;

      switch (configType) {
        case 'StyleModifier': {
          config.rules.forEach((rule) => {
            fields.add(rule.field);
          });

          break;
        }

        case 'ReadStatus':
          fields.add(config.params.field);
          fields.add(config.params.ownerIdField);

          break;

        default:
          assertNever(
            configType,
            `Unknown row modifier of type "${configType}"`
          );
      }
    }

    allColumns.forEach(processColumnConfig);
    schema.card?.virtualColumns?.forEach(processColumnConfig);
    schema.rowModifiers.forEach(processRowModifier);

    const initialFieldsDefinition: IRequestFieldsDefinition = {};
    fields.forEach((field) => {
      set(initialFieldsDefinition, field, null);
    });

    function createFinalRequestConfig(
      config: IRequestFieldsDefinition,
      entity: string
    ): IRequestFieldsDefinition {
      const fields =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        entityDefinition[entity].fieldDefinitions as FieldDefinitions<any>;

      /**
       * there are pseudo object that do not have ids e.g. (BusinessParterContactCombined) they only serve for fragmenting on the real entity
       * for all otheres we request the id automatically for better caching
       * */
      const result = fields.id ? { id: null } : {};

      for (const [key, value] of Object.entries(config)) {
        const field = fields[key];

        if (!field) {
          throw new Error(`Field ${key} not found in entity ${entity}`);
        }

        // A scalar value
        if (field.entity === undefined) {
          if (value !== null) {
            throw new Error(
              `Expected to receive null for scalar field ${key} in entity ${entity}, but instead got ${value}`
            );
          }

          result[key] = null;
        }
        // A nested entity
        else if (typeof field.entity === 'string') {
          if (value === null) {
            throw new Error(
              `Expected to receive object config for relation field ${key} in entity ${entity}, but instead got ${value}`
            );
          }

          result[key] = createFinalRequestConfig(value, field.entity);
        }
        // A union of entities
        else if (Array.isArray(field.entity)) {
          result[key] = {};

          for (const entity of field.entity) {
            result[key][entity] = createFinalRequestConfig(value, entity);
          }
        }
      }

      return result;
    }

    const finalFieldsDefinition = createFinalRequestConfig(
      initialFieldsDefinition,
      schema.entity
    );

    return finalFieldsDefinition;
  }, [schema, allColumns]);

  const isColumnAccessible = useCallback(
    function isColumnAccessible(
      column: ITableColumnConfig | ITableColumnConfig<CustomColumns[string]>
    ): boolean {
      const accessor = column.accessor;

      return (
        typeof accessor !== 'string' || !isInaccessible(schema.entity, accessor)
      );
    },
    [isInaccessible, schema.entity]
  );

  const cardConfig = useMemo(() => {
    const card = schema.card;

    if (!card) {
      return null;
    }

    const {
      icon = null,
      secondaryIcons = [],
      title,
      content,
      info = [],
    } = card;

    const columnsById = new Map(
      allColumns
        .filter(isColumnAccessible)
        .map((config) => [getColumnId(config), config])
    );

    const explicitlyConfiguredColumns = [
      icon,
      ...secondaryIcons,
      title,
      content,
    ]
      .flat()
      .filter(Boolean);

    const infoAutoColumns = new Set(
      allColumns.filter(isColumnAccessible).map(getColumnId)
    );
    for (const col of explicitlyConfiguredColumns) {
      infoAutoColumns.delete(col);
    }
    const config: CardConfig = {
      icon,
      secondaryIcons,
      title,
      content,
      info: [
        ...info
          .filter(
            (config) => !isSomeInaccessible(schema.entity, config.columns)
          )
          .map((config) => {
            const { key, title, columns, defaultHidden } = config;

            return {
              key,
              title: Array.isArray(title) ? t(...title) : t(title),
              columns,
              defaultHidden,
            };
          }),

        ...[...infoAutoColumns].map((columnId) => {
          const column = columnsById.get(columnId);

          return {
            key: columnId,
            title: Array.isArray(column.title)
              ? t(...column.title)
              : t(column.title),
            columns: [columnId],
            type: normalizeCellConfig(column.cell).cellType,
          };
        }),
      ],
    };

    return config;
  }, [
    schema.card,
    allColumns,
    schema.entity,
    isColumnAccessible,
    isSomeInaccessible,
    t,
  ]);

  const columnConfigs = useMemo(() => {
    const { cells, groupLabelElements } = initialPropsRef.current;

    type ResolvedColumnConfig = (typeof allColumns)[number];

    let sortOrder = 0;

    function mapColumn(
      config: ResolvedColumnConfig,
      overrides: Partial<
        ResolvedColumnConfig & { disableColumnVisibility: boolean }
      > = {}
    ): DataTableColumnConfig {
      const mergedConfig = { ...config, ...overrides };

      const {
        id,
        title = '',
        Header,
        accessor,
        sortable,
        groupable = true,
        path,
        filterable,
        defaultHidden,
        cell,
        width,
        minWidth,
        maxWidth,
        sticky,
        disableColumnVisibility,
        quickSearchable,
        required,
        disableFooterSum,
        filterField,
        groupLabelElement,
      } = mergedConfig;

      const { cellType, cellParams } = normalizeCellConfig(cell);

      const { groupLabelElementType, groupLabelElementParams } =
        normalizeGroupLabelElementConfig(groupLabelElement);

      const column: DataTableColumnConfig = {
        id: resolveColumnId(id, accessor),
        title: typeof title === 'string' ? t(title) || title : t(...title),
        Header,
        accessor,
        defaultHidden,
        Cell: cellType ? cells?.[cellType] ?? defaultCells[cellType] : TextCell,
        Footer: cellType ? defaultFooterCells[cellType] : undefined,
        cellParams,
        width,
        minWidth,
        maxWidth,
        disableSortBy: !sortable,
        disableGroupBy: !groupable,
        disableColumnVisibility,
        dataType: DataType[cellType] ?? cellType,
        filterType: filterable ? FilterType[filterable.type] : undefined,
        isPrimaryFilter: filterable ? filterable.primary : undefined,
        filterParams:
          filterable && 'params' in filterable ? filterable.params : undefined,
        columnSubGroupPath: path,
        sticky,
        quickSearchable,
        disableFooterSum,
        order: cellType === 'Custom' ? ++sortOrder : undefined,
        required,
        filterField,
        GroupLabelElement:
          groupLabelElements?.[groupLabelElementType] ??
          defaultGroupLabelElements[groupLabelElementType] ??
          DefaultGroupLabel,
        groupLabelElementParams: groupLabelElementParams,
      };

      return column;
    }

    const columnsById = new Map(
      allColumns.map((config) => [getColumnId(config), config])
    );

    if (layout !== 'table' && cardConfig !== null) {
      const { icon, secondaryIcons, title, content, info } = cardConfig;

      const conditionalTitle = Array.isArray(title) ? title : [title];

      const fixedColumnIds = [
        icon,
        ...secondaryIcons,
        ...conditionalTitle,
        content,
      ].filter(Boolean);
      const fixedColumns = allColumns
        .filter((column) => {
          return fixedColumnIds.includes(getColumnId(column));
        })
        .map((column) => {
          return mapColumn(column, { disableColumnVisibility: true });
        });

      const infoVirtualColumns = info
        .filter((info) => {
          // If the virtual column only has 1 real column inside of with with the
          // same id, there is no reason to create the virtual column separately.
          // Everything can be handled by the real column instead.
          return !(info.columns.length === 1 && info.columns[0] === info.key);
        })
        .map((column) => {
          return mapColumn({
            id: column.key,
            title: column.title,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            accessor: (() => null) as any,
            sortable: false,
            groupable: false,
            filterable: false,
            defaultHidden: column.defaultHidden ?? false,
            width: 0,
            minWidth: 0,
          });
        });
      schema.card?.virtualColumns?.forEach((col) => {
        infoVirtualColumns.push(
          mapColumn(col, {
            disableColumnVisibility: true,
            sortable: false,
            groupable: false,
            filterable: false,
            width: 0,
            minWidth: 0,
          })
        );
      });

      const infoRealColumns = info.flatMap((info) => {
        return info.columns
          .map((columnId) => {
            const column = columnsById.get(columnId);

            if (!column) {
              return null;
            }

            return mapColumn(column, {
              /**
               * todo, we need to be able to default show differnt colums here as well that are not virtual
               * currently the convetion is, what has not explicitly been configured is not to be seen
               * **/
              defaultHidden: true,
              disableColumnVisibility: false,
            });
          })
          .filter(Boolean);
      });

      //remove duplicates that may have been introduced by multiple virtual colums referencing the same real column
      const allConfiguredColumns = [
        ...fixedColumns,
        ...infoVirtualColumns,
        ...infoRealColumns,
      ];
      const removedDuplicates: Record<
        string,
        DataTableColumnConfig<Work4AllEntity>
      > = allConfiguredColumns.reduce((curr, col) => {
        curr[col.id] = col;
        return curr;
      }, {});
      const realColumsWithoutDuplicates = Object.values(removedDuplicates);

      return realColumsWithoutDuplicates;
    }

    const columns = allColumns
      .filter(isColumnAccessible)
      .map((column) => mapColumn(column));

    additionalColumns?.forEach((c) => columns.push(c));
    genericColumns.forEach((c) => columns.push(c));

    return columns;
  }, [
    schema,
    allColumns,
    layout,
    cardConfig,
    t,
    isColumnAccessible,
    additionalColumns,
  ]);

  const user = useUser();

  const prepareRowDisplayModifiers = useMemo(() => {
    const styleRules = schema.rowModifiers.find(isStyleModifier)?.rules ?? [];

    const readStatusModifier = schema.rowModifiers.find(isReadStatusModifier);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const prepareRowDisplayModifiers: PrepareTableRowModifiers<any> = (row) => {
      const appliedStyles = {};

      styleRules.forEach(({ field, value, style }) => {
        const fieldValue = get(row, field);

        if (fieldValue === undefined) {
          return;
        }

        if (value.includes(fieldValue)) {
          style.forEach((styleName) => {
            appliedStyles[styleName] = true;
          });
        }
      });

      if (readStatusModifier) {
        const ownerId = get(row, readStatusModifier.params.ownerIdField);
        const isRead = get(row, readStatusModifier.params.field);

        if (ownerId === user.benutzerCode && isRead === false) {
          appliedStyles['isBold'] = true;
        }
      }

      const objectLock = get(row, 'objectLock');
      if (objectLock) appliedStyles['isShade3'] = true;

      return appliedStyles;
    };

    return prepareRowDisplayModifiers;
  }, [schema, user.benutzerCode]);

  return { fields, columnConfigs, cardConfig, prepareRowDisplayModifiers };
}

export function isStyleModifier(
  modifier: ITableRowModifier
): modifier is ITableRowStyleModifier {
  return modifier.type === 'StyleModifier';
}

export function isReadStatusModifier(
  modifier: ITableRowModifier
): modifier is ITableRowReadStatusModifier {
  return modifier.type === 'ReadStatus';
}

function normalizeCellConfig(
  creator: string | IDefaultCellConfig | ICustomCellConfigBase
): {
  cellType: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cellParams: any;
} {
  if (creator === undefined) {
    return { cellType: undefined, cellParams: undefined };
  }

  if (typeof creator === 'string') {
    return { cellType: creator, cellParams: undefined };
  }

  return { cellType: creator.type, cellParams: creator.params };
}

function normalizeGroupLabelElementConfig(
  config: GroupLabelElementTableConfig | undefined = {
    type: null,
  }
): {
  groupLabelElementType: GroupLabelElementType;
  groupLabelElementParams: Omit<GroupLabelElementTableConfig, 'type'>;
} {
  const { type, ...groupLabelElementParams } = config;

  return { groupLabelElementType: type, groupLabelElementParams };
}

const defaultCells = {
  Currency: CurrencyCell,
  ForbiddenPrice: ForbiddenPriceCell,
  ArticleSinglePrice: ArticleSinglePriceCell,
  Date: HookedDateCell,
  Checkbox: CheckboxCell,
  Translated: TranslatedCell,
  Compose: ComposeCell,
  Time: TimeCell,
  FileIcon: FileIconCell,
  ArrayCell: ArrayCell,
  UserIcon: UserIconCell,
  Text: TextCell,
  Avatars: AvatarsCell,
  Custom: CustomFieldCell,
  Placeholder: PlaceholderCell,
  RequiredTime: RequiredTimeCell,
  ArticleKind: ArticleKindCell,
  Flag: FlagCell,
};

const defaultGroupLabelElements: Record<
  GroupLabelElementType,
  React.ComponentType<unknown>
> = {
  YesOrNo: YesOrNoGroupLabel,
  Enum: EnumGroupLabel,
};

const defaultFooterCells = {
  Currency: CurrencyFooterCell,
};

const defaultCellProcessors = {
  Currency: (config) => {
    const params = config?.cell?.params;
    if (params && params.currencyField) {
      return [config.accessor, params.currencyField];
    }
    return [config.accessor];
  },
  Date: (config, entity) => {
    const params = config?.cell?.params;
    const def = entityDefinition[entity];
    const fieldsToRequest = [config.accessor];
    if (params && params.spanEnd) {
      fieldsToRequest.push(params.spanEnd);
    }

    if (def && def.fieldDefinitions && def.fieldDefinitions.isWholeDay) {
      fieldsToRequest.push('isWholeDay');
    }

    return fieldsToRequest;
  },
  FileIcon: (config) => {
    const params = config?.cell?.params;
    if (params && params.fileName) {
      return [config.accessor, params.fileName];
    }
    return [config.accessor];
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function resolveColumnId(id: string, accessor: string | ((obj: any) => any)) {
  if (typeof id === 'string') return id;
  if (typeof accessor === 'string') return accessor;
  throw new Error("Can't resolve id for column with function accessor");
}
