import {
  ArticleGroupPicker,
  CustomerGroupPicker,
  ProjectGroupPicker,
  SupplierGroupPicker,
  TextBuildingBlockGroupPicker,
  UserGroupPicker,
} from '@work4all/components/lib/components/entity-picker/entity-group-picker/EntityGroupPicker';
import { UserClassPicker } from '@work4all/components/lib/components/entity-picker/user-class-picker/UserClassPicker';
import { UserStatusPicker } from '@work4all/components/lib/components/entity-picker/user-status-picker/UserStatusPicker';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CustomerCategoryPicker } from '../../../filters/CustomerCategoryPicker';
import { ProjectCategoryPicker } from '../../../filters/ProjectCategoryPicker';
import { SupplierCategoryPicker } from '../../../filters/SupplierCategoryPicker';
import { WordDocumentTemplateGroupPicker } from '../../../filters/WordDocumentTemplateGroupPicker';
import { WordLetterTemplateGroupPicker } from '../../../filters/WordLetterTemplateGroupPicker';

import {
  QuickColumnFilterPickersConfigs,
  QuickColumnFilterPickersConfigsByEntity,
} from './types';

const CUSTOMER_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: CustomerGroupPicker,
    },
    category: {
      id: 'categoryAssignmentList.categoryId',
      picker: CustomerCategoryPicker,
    },
  };

const SUPPLIER_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: SupplierGroupPicker,
    },
    category: {
      id: 'categoryAssignmentList.categoryId',
      picker: SupplierCategoryPicker,
    },
  };

const PROJECT_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: ProjectGroupPicker,
    },
    category: {
      id: 'categoryAssignmentList.categoryId',
      picker: ProjectCategoryPicker,
    },
  };

const TEXT_BUILDING_BLOCK_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: TextBuildingBlockGroupPicker,
    },
  };

const ARTICLE_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: ArticleGroupPicker,
    },
  };

const USER_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    type: {
      id: 'userKind',
      picker: UserClassPicker,
    },
    group: {
      id: 'group.id',
      picker: UserGroupPicker,
    },
    status: {
      id: 'hasLeft',
      picker: UserStatusPicker,
    },
  };

const DOCUMENT_TEMPLATE_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: WordDocumentTemplateGroupPicker,
    },
  };

const LETTER_TEMPLATE_QUICK_COLUMN_FILTER_PICKERS_CONFIGS: QuickColumnFilterPickersConfigs =
  {
    group: {
      id: 'groupId',
      picker: WordLetterTemplateGroupPicker,
    },
  };

export const QUICK_COLUMN_FILTER_PICKERS_CONFIGS_BY_ENTITY: QuickColumnFilterPickersConfigsByEntity =
  {
    [Entities.customer]: CUSTOMER_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.supplier]: SUPPLIER_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.project]: PROJECT_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.article]: ARTICLE_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.textBuildingBlock]:
      TEXT_BUILDING_BLOCK_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.fullUser]: USER_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.wordDocumentTemplate]:
      DOCUMENT_TEMPLATE_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
    [Entities.wordLetterTemplate]:
      LETTER_TEMPLATE_QUICK_COLUMN_FILTER_PICKERS_CONFIGS,
  };
