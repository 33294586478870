import { Box } from '@mui/material';

import { Project } from '@work4all/models/lib/Classes/Project.entity';

import { formatAsFloat } from '@work4all/utils';

import { useProjectPlanningData } from './planning-columns/components/ProjectPlanningDataProvider';

interface ProjectTimeTrackingCellProps {
  children?: React.ReactNode;
  value: string | number;
  column?: {
    id: string;
  };
  row?: {
    original: Project;
  };
}

export const ProjectTimeTrackingCell = (
  props: ProjectTimeTrackingCellProps
) => {
  const {
    row: { original: project },
  } = props;
  const { timeTrackingList } = useProjectPlanningData();
  if (!project) {
    return;
  }
  const relevantProjectIds = [project, ...(project.childProjects || [])]?.map(
    (pr) => pr?.id
  );
  const total = timeTrackingList
    .filter((x) => relevantProjectIds.includes(x.projectId))
    ?.map((x) => x.amount)
    ?.reduce((a, b) => a + b, 0);

  return (
    <Box textAlign="right">
      {total
        ? formatAsFloat(total, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 3,
          }) + ' h'
        : null}{' '}
    </Box>
  );
};
