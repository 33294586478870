import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';

import { useOrganisationAreaContext } from '../hooks';
import { useOrganisationAreaSectionEditability } from '../hooks/use-organisation-area-section-editability';
import { OrganisationAreaSectionEditActionProps } from '../types';

export const OrganisationAreaSectionEditAction = ({
  section,
}: OrganisationAreaSectionEditActionProps) => {
  const { isEditable } = useOrganisationAreaSectionEditability();
  const { setEditOverlayOpen } = useOrganisationAreaContext();

  return (
    <IconButton
      disabled={!isEditable(section)}
      onClick={() => {
        setEditOverlayOpen(true);
      }}
    >
      <EditIcon />
    </IconButton>
  );
};
