import { Box, Divider, Skeleton } from '@mui/material';
import { Fragment, useCallback, useMemo, useRef } from 'react';
import { AutoSizer, InfiniteLoader } from 'react-virtualized';
import { VariableSizeList } from 'react-window';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { ApprovalEvent } from '@work4all/models/lib/Classes/ApprovalEvent.entity';
import { Mention } from '@work4all/models/lib/Classes/Mention.entity';
import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';
import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { NotificationType } from '@work4all/models/lib/Enums/NotificationType.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import {
  markSectionsByDate,
  SectionInfo,
} from '@work4all/utils/lib/mark-sections-by-date';

import {
  MIN_NOTIFICATION_HEIGHT_PX,
  NotificationListItem,
} from '../components/NotificationListItem';
import { resolveNotificationData } from '../components/NotificationListItem/utils/resolve-notification-data';

import { NOTIFICATION_PAGE_QUERY_PAGE_SIZE } from './constant';

const DIVIDER_HEIGHT_PX = remToPx(1.5);

export interface NotificationListVirtualizedProps {
  items: Notification[];
  total: number;
  onNotificationClick: (notification: Notification) => void;
  fetchMore(startIndex: number, stopIndex: number): Promise<unknown>;
}

type MarkedNotifications = {
  notification: Notification;
  section: SectionInfo;
};

// TODO Consider merging this implementation with the one in <BasicTable>.

export function NotificationListVirtualized(
  props: NotificationListVirtualizedProps
) {
  const { items, total, fetchMore: fetchMoreProp, onNotificationClick } = props;

  const markedItems = useMemo<MarkedNotifications[]>(() => {
    return markSectionsByDate({
      items,
      getDate(notification) {
        return notification.insertTime;
      },
      mapFn(notification, section) {
        return { notification, section };
      },
    });
  }, [items]);

  // Render up to 1 placeholder notification.
  const rowCount = Math.min(total, markedItems.length + 1);

  const infiniteLoaderRef = useRef<InfiniteLoader>(null);

  const calcItemSize = useCallback(
    (index: number) => {
      const item = markedItems[index];

      const notification = item?.notification;

      /**
       * If the notification contains Customer, Supplier, or Project
       * we add 1rem for each item so the notification size can be accurate.
       */
      const extraNotificationHeight = () => {
        if (!notification) return 0;

        let extras = [];

        if (notification.notificationType === NotificationType.MENTION) {
          const data = resolveNotificationData(
            (notification.object as Mention).parentObject
          );

          extras = [data?.customer, data?.supplier, data?.project];
        }

        if (
          notification.notificationType ===
          NotificationType.FAILED_SAVE_SEND_MAIL_JOB
        ) {
          const data = (notification.object as SaveSendMailJob).createdMail;

          extras = [data?.businessPartner, data?.project];
        }

        if (notification.notificationType === NotificationType.APPROVAL_EVENT) {
          const isVacation =
            notification.parentObjectType === ObjectType.VACATION_REQUEST;
          const data = (notification.object as ApprovalEvent)
            ?.refObject as VacationRequestEntity;

          extras = isVacation ? [data?.dateFrom && data?.dateTo] : [];
        }

        const numberOfExtars = extras.filter((extra) => Boolean(extra)).length;

        return remToPx(numberOfExtars);
      };

      const notificationHeight =
        MIN_NOTIFICATION_HEIGHT_PX + extraNotificationHeight();

      if (item && item.notification != null && item.section.isSectionStart) {
        return notificationHeight + DIVIDER_HEIGHT_PX;
      }

      return notificationHeight;
    },
    [markedItems]
  );

  const fetchMore = useCallback(
    (startIndex, stopIndex) => {
      return fetchMoreProp(startIndex, stopIndex);
    },
    [fetchMoreProp]
  );

  const itemData = useMemo<RenderRowData>(() => {
    return {
      items: markedItems,
      onNotificationClick: onNotificationClick,
    };
  }, [markedItems, onNotificationClick]);

  return (
    <AutoSizer>
      {({ width, height }) => {
        return (
          <InfiniteLoader
            ref={infiniteLoaderRef}
            isRowLoaded={({ index }) => {
              return markedItems[index] != null;
            }}
            rowCount={rowCount}
            loadMoreRows={({ startIndex }) => {
              return fetchMore(
                startIndex,
                startIndex + NOTIFICATION_PAGE_QUERY_PAGE_SIZE - 1
              );
            }}
          >
            {({ onRowsRendered, registerChild }) => (
              <VariableSizeList
                ref={registerChild}
                width={width}
                height={height}
                itemSize={calcItemSize}
                estimatedItemSize={MIN_NOTIFICATION_HEIGHT_PX}
                itemCount={rowCount}
                overscanCount={3}
                itemData={itemData}
                onItemsRendered={({
                  visibleStartIndex: startIndex,
                  visibleStopIndex: stopIndex,
                }) => {
                  return onRowsRendered({ startIndex, stopIndex });
                }}
              >
                {RenderRow}
              </VariableSizeList>
            )}
          </InfiniteLoader>
        );
      }}
    </AutoSizer>
  );
}

type RenderRowData = {
  items: MarkedNotifications[];
  onNotificationClick(notification: Notification): void;
};

type RenderRowProps = {
  style: React.CSSProperties;
  index: number;
  data: RenderRowData;
};

function RenderRow(props: RenderRowProps) {
  const {
    style,
    index,
    data: { items, onNotificationClick },
  } = props;

  const item = items[index];

  if (!item) {
    return (
      <div key={index} style={{ ...style, padding: '0.5rem 1rem' }}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
      </div>
    );
  }

  const notification = item.notification;
  const sectionLabel = item.section.sectionLabel;

  const handleClick = () => {
    onNotificationClick(notification);
  };

  return item.section.isSectionStart
    ? renderSectionStartRow()
    : renderRegularRow();

  function renderSectionStartRow() {
    // When rendering with a separator we need to manually patch the CSS styles
    // provided by the virtualized list component. Since the separator is not
    // its own row, but rendered as a part of current row and is already
    // included in this row's height calculations. We need to subtract the
    // separator height from the row hight and move the row itself down by the
    // same amount.

    return (
      <Fragment key={index}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
          style={{ ...style, height: DIVIDER_HEIGHT_PX }}
        >
          <Box sx={{ px: '1rem', flex: '1' }}>
            <Divider textAlign="left">{sectionLabel}</Divider>
          </Box>
        </Box>

        <NotificationListItem
          style={{
            ...style,
            height: (style.height as number) - DIVIDER_HEIGHT_PX,
            top: (style.top as number) + DIVIDER_HEIGHT_PX,
          }}
          notification={notification}
          onClick={handleClick}
        />
      </Fragment>
    );
  }

  function renderRegularRow() {
    return (
      <NotificationListItem
        key={index}
        style={style}
        notification={notification}
        onClick={handleClick}
      />
    );
  }
}
