import { useEventCallback } from '@mui/material/utils';
import { useCallback } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../settings';

type Props = {
  entity: Entities;
  filterIds: string[];
};

type Return = {
  isFilterVisible: (filter: string) => boolean;
  toggleFilterVisibility: (filter: string) => void;
};

export const useLeftAreaVisibleFiltersState = ({
  entity,
  filterIds,
}: Props): Return => {
  const { value, set } = useSetting(
    settings.leftAreaVisibleFilters(entity, filterIds)
  );

  const isFilterVisible = useCallback(
    (filterId: string) => {
      return value.includes(filterId);
    },
    [value]
  );

  const toggleFilterVisibility = useEventCallback((filterId: string) => {
    const newValue = isFilterVisible(filterId)
      ? value.filter((_filterId) => _filterId !== filterId)
      : [...value, filterId];

    set(newValue);
  });

  return { isFilterVisible, toggleFilterVisibility };
};
