import React from 'react';
import { TableInstance } from 'react-table';

import { Letter } from '@work4all/models/lib/Classes/Letter.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/letter-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const LettersTable = React.forwardRef<TableInstance, IEntityTable>(
  function LettersTable(_props, ref) {
    const dataTable = useEntityDataTable<Letter, never>({
      schema: schema as never,
      defaultSort,
      forceRequestFields: {
        fileInfos: {
          fileServiceProviderInfos: {
            id: null,
            fspUrl: null,
          },
        },
      },
    });

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        {..._props}
        onRowDoubleClick={() => {
          if (
            dataTable.selectedEntity?.fileInfos?.fileServiceProviderInfos
              ?.fspUrl
          ) {
            window.open(
              dataTable.selectedEntity?.fileInfos?.fileServiceProviderInfos
                ?.fspUrl,
              '_blank'
            );
            return true;
          } else {
            return false;
          }
        }}
      />
    );
  }
);
