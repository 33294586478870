import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { PromptResult } from '@work4all/models/lib/Enums/PromptResult.enum';
import { PromptType } from '@work4all/models/lib/Enums/PromptType.enum';

import { UserPickerField } from '../../../../../../../../../../../components/entity-picker/UserPickerField';
import { ControllerPlusPropsRender } from '../../../../../../../../../form-plus/controller-plus';
import { ErpData } from '../../../../../../ErpData';
import { useShadowBzObjectApiContext } from '../../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';

export const ResponsiblePickerField: ControllerPlusPropsRender<
  ErpData,
  'user2'
> = (props) => {
  const { field, fieldState } = props;
  const { t } = useTranslation();
  const dialogs = useDialogs();
  const shadowBzObjectApi = useShadowBzObjectApiContext();
  const positions = shadowBzObjectApi.positions;
  const promptResult = shadowBzObjectApi.promptResult;

  return (
    <UserPickerField
      {...field}
      onChange={async (input) => {
        const execute = () => {
          field.onChange(input);
          promptResult.current.push({
            result: PromptResult.YES,
            type: PromptType.BZ_OBJECT_CHANGE_RESPONSIBLE_PERSON_IN_POSITIONS_AS_WELL,
          });
        };
        if (positions.length > 0) {
          const confirmed = await dialogs.confirm({
            title: t('MASK.WARNING'),
            description: t('ALERTS.ERP.RESPONSIBLE'),
          });
          if (confirmed) {
            execute();
          }
        } else {
          execute();
        }
      }}
      label={t('INPUTS.RESPONSIBLE')}
      error={fieldState?.error?.message}
      clearable={false}
    />
  );
};
