import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ErpStatusMobileEditing } from '../Enums/ErpStatusMobileEditing.enum';
import { InboundDeliveryNoteKind } from '../Enums/InboundDeliveryNoteKind.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { ArchivePdf } from './ArchivePdf.entity';
import { BankDetails } from './BankDetails.entity';
import { BaseDataLanguage } from './BaseDataLanguage.entity';
import { BusinessPartnerContactCombined } from './BusinessPartnerContactCombined.entity';
import { CostCenter } from './CostCenter.entity';
import { Currency } from './Currency.entity';
import { CustomField } from './CustomField.entity';
import { DeliveryKind } from './DeliveryKind.entity';
import { ErpAttachment } from './ErpAttachment.entity';
import { FavoriteItem } from './FavoriteItem.entity';
import { InboundPosition } from './InboundPosition.entity';
import { PriceGroup } from './PriceGroup.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { User } from './User.entity';

export class InboundDeliveryNote<T extends EMode = EMode.entity> {
  /** Alias: abgeschlossen */
  isClosed?: boolean;
  /** Alias: archivPdf */
  archivePdf?: ArchivePdf<T>[];
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzer2 */
  user2?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: benutzerCode2 */
  user2Id?: number;
  /** Alias: code */
  id?: number;
  /** Alias: createdByLoginId */
  createdByLoginId?: string;
  /** Alias: datum */
  date?: string;
  /** Alias: eingangslieferscheinArt */
  inboundDeliveryNoteKind?: InboundDeliveryNoteKind;
  /** Alias: eingefroren */
  frozen?: boolean;
  /** Alias: erpAnhaenge */
  erpAttachmentList?: ErpAttachment<T>[];
  /** Alias: feld1 */
  individualField1?: string;
  /** Alias: feld2 */
  individualField2?: string;
  /** Alias: feld3 */
  individualField3?: string;
  /** Alias: feld4 */
  individualField4?: string;
  /** Alias: feld5 */
  individualField5?: string;
  /** Alias: feld6 */
  individualField6?: string;
  /** Alias: feld7 */
  individualField7?: string;
  /** Alias: hauptAdresseAbteilungCode */
  businessPartnerDepartmentId?: number;
  /** Alias: hauptadresse */
  businessPartnerContactCombined?: BusinessPartnerContactCombined<T>;
  /** Alias: hauptadresseAnPostfach */
  businessPartnerToPostalBox?: boolean;
  /** Alias: hauptadresseApCode */
  contactId?: number;
  /** Alias: hauptadresseCompanyCode */
  businessPartnerId?: number;
  /** Alias: hauptadresseCompanyType */
  businessPartnerType?: SdObjType;
  /** Alias: hauptadresseText */
  businessPartnerText?: string;
  /** Alias: ihrZeichen */
  yourSign?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kopftext */
  headText?: string;
  /** Alias: kostenstelle */
  costCenter?: CostCenter<T>;
  /** Alias: kurs */
  course?: number;
  /** Alias: kursDatum */
  courseDate?: string;
  /** Alias: leistungsbeginn */
  serviceStartDate?: string;
  /** Alias: leistungsende */
  serviceEndDate?: string;
  /** Alias: leistungsort */
  serviceLocation?: string;
  /** Alias: lieferDatum */
  deliveryDate?: string;
  /** Alias: lieferscheinNummer */
  deliveryNoteNumber?: string;
  /** Alias: lieferterminAbgehend */
  outgoingDeliveryDate?: string;
  /** Alias: mobileBearbeitung */
  mobileEditing?: ErpStatusMobileEditing;
  /** Alias: nebenadresse1AnPostfach */
  additionalAddress1ToPostalBox?: boolean;
  /** Alias: nebenadresse2AnPostfach */
  additionalAddress2ToPostalBox?: boolean;
  /** Alias: nebenadresse3AnPostfach */
  additionalAddress3ToPostalBox?: boolean;
  /** Alias: niederlassungsCode */
  branchOfficeId?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: positionen */
  positionList?: InboundPosition<T>[];
  /** Alias: preisgruppeItem */
  priceGroup?: PriceGroup<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektVorgangCode */
  projectProcessId?: number;
  /** Alias: reCode */
  invoiceId?: number;
  /** Alias: rtfKopftext */
  rtfHeadText?: string;
  /** Alias: rtfSchlusstext */
  rtfFooterText?: string;
  /** Alias: schlusstext */
  footerText?: string;
  /** Alias: status1 */
  status1?: number;
  /** Alias: status2 */
  status2?: number;
  /** Alias: status3 */
  status3?: number;
  /** Alias: status4 */
  status4?: number;
  /** Alias: status5 */
  status5?: number;
  /** Alias: unserZeichen */
  ourSign?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: waehrungCode */
  currencyId?: number;
  /** Alias: wartungsCode */
  serviceId?: number;
  /** Alias: webShopOrderDefinitionData */
  shopOrderDefinitionData?: string;
  /** Alias: mietdauer */
  rentDuration?: number;
  /** Alias: mietfaktor */
  rentFactor?: number;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: bankverbindung */
  bankDetails?: BankDetails<T>;
  /** Alias: lieferungArt */
  deliveryKind?: DeliveryKind<T>;
  /** Alias: bruttowert */
  grossValue?: number;
  /** Alias: sprache */
  language?: BaseDataLanguage<T>;
  /** Alias: vorgesetzter */
  supervisor?: User<T>;
  /** Alias: kostenstelleCode */
  costCenterId?: number;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: headerTextConverted */
  headerTextConverted?: string;
  /** Alias: footerTextConverted */
  footerTextConverted?: string;
  /** Alias: favoriteItem */
  favoriteItem?: FavoriteItem<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InboundDeliveryNote> = {
  isClosed: {
    alias: 'abgeschlossen',
  },
  archivePdf: {
    alias: 'archivPdf',
    entity: Entities.archivePdf,
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  user2: {
    alias: 'benutzer2',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  user2Id: {
    alias: 'benutzerCode2',
  },
  id: {
    alias: 'code',
  },
  createdByLoginId: {
    alias: 'createdByLoginId',
  },
  date: {
    alias: 'datum',
  },
  inboundDeliveryNoteKind: {
    alias: 'eingangslieferscheinArt',
  },
  frozen: {
    alias: 'eingefroren',
  },
  erpAttachmentList: {
    alias: 'erpAnhaenge',
    entity: Entities.erpAttachment,
  },
  individualField1: {
    alias: 'feld1',
  },
  individualField2: {
    alias: 'feld2',
  },
  individualField3: {
    alias: 'feld3',
  },
  individualField4: {
    alias: 'feld4',
  },
  individualField5: {
    alias: 'feld5',
  },
  individualField6: {
    alias: 'feld6',
  },
  individualField7: {
    alias: 'feld7',
  },
  businessPartnerDepartmentId: {
    alias: 'hauptAdresseAbteilungCode',
  },
  businessPartnerContactCombined: {
    alias: 'hauptadresse',
    entity: Entities.businessPartnerContactCombined,
  },
  businessPartnerToPostalBox: {
    alias: 'hauptadresseAnPostfach',
  },
  contactId: {
    alias: 'hauptadresseApCode',
  },
  businessPartnerId: {
    alias: 'hauptadresseCompanyCode',
  },
  businessPartnerType: {
    alias: 'hauptadresseCompanyType',
  },
  businessPartnerText: {
    alias: 'hauptadresseText',
  },
  yourSign: {
    alias: 'ihrZeichen',
  },
  insertTime: {
    alias: 'insertTime',
  },
  headText: {
    alias: 'kopftext',
  },
  costCenter: {
    alias: 'kostenstelle',
    entity: Entities.costCenter,
  },
  course: {
    alias: 'kurs',
  },
  courseDate: {
    alias: 'kursDatum',
  },
  serviceStartDate: {
    alias: 'leistungsbeginn',
  },
  serviceEndDate: {
    alias: 'leistungsende',
  },
  serviceLocation: {
    alias: 'leistungsort',
  },
  deliveryDate: {
    alias: 'lieferDatum',
  },
  deliveryNoteNumber: {
    alias: 'lieferscheinNummer',
  },
  outgoingDeliveryDate: {
    alias: 'lieferterminAbgehend',
  },
  mobileEditing: {
    alias: 'mobileBearbeitung',
  },
  additionalAddress1ToPostalBox: {
    alias: 'nebenadresse1AnPostfach',
  },
  additionalAddress2ToPostalBox: {
    alias: 'nebenadresse2AnPostfach',
  },
  additionalAddress3ToPostalBox: {
    alias: 'nebenadresse3AnPostfach',
  },
  branchOfficeId: {
    alias: 'niederlassungsCode',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  positionList: {
    alias: 'positionen',
    entity: Entities.inboundPosition,
  },
  priceGroup: {
    alias: 'preisgruppeItem',
    entity: Entities.priceGroup,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektVorgangCode',
  },
  invoiceId: {
    alias: 'reCode',
  },
  rtfHeadText: {
    alias: 'rtfKopftext',
  },
  rtfFooterText: {
    alias: 'rtfSchlusstext',
  },
  footerText: {
    alias: 'schlusstext',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  status3: {
    alias: 'status3',
  },
  status4: {
    alias: 'status4',
  },
  status5: {
    alias: 'status5',
  },
  ourSign: {
    alias: 'unserZeichen',
  },
  updateTime: {
    alias: 'updateTime',
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  currencyId: {
    alias: 'waehrungCode',
  },
  serviceId: {
    alias: 'wartungsCode',
  },
  shopOrderDefinitionData: {
    alias: 'webShopOrderDefinitionData',
  },
  rentDuration: {
    alias: 'mietdauer',
  },
  rentFactor: {
    alias: 'mietfaktor',
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  bankDetails: {
    alias: 'bankverbindung',
    entity: Entities.bankDetails,
  },
  deliveryKind: {
    alias: 'lieferungArt',
    entity: Entities.deliveryKind,
  },
  grossValue: {
    alias: 'bruttowert',
  },
  language: {
    alias: 'sprache',
    entity: Entities.baseDataLanguage,
  },
  supervisor: {
    alias: 'vorgesetzter',
    entity: Entities.user,
  },
  costCenterId: {
    alias: 'kostenstelleCode',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  headerTextConverted: {
    alias: 'headerTextConverted',
  },
  footerTextConverted: {
    alias: 'footerTextConverted',
  },
  favoriteItem: {
    alias: 'favoriteItem',
    entity: Entities.favoriteItem,
  },
  __typename: {
    alias: '__typename',
  },
};

export const inboundDeliveryNoteEntityDefinition: EntitiyDefinition<InboundDeliveryNote> =
  {
    local: {},
    remote: {
      queryName: 'getEingangslieferschein',
      fragmentName: 'Eingangslieferschein',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
        { name: 'addFavSort', type: 'Boolean' },
        { name: 'onlyFavItems', type: 'Boolean' },
      ],
    },
    fieldDefinitions: fields,
  };
