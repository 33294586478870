import PhoneIcon from '@mui/icons-material/Phone';
import SendIcon from '@mui/icons-material/Send';
import { IconButton, Stack } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useCallModalContext } from './call-modal/useCallModalContext';
import { useOpenEmailMaskControllerContext } from './contact-email-mask/useOpenEmailMaskControllerContext';

export class ContactActionOptions {
  displayEMail?: boolean;
  displayPhone?: boolean;
  noButtonPadding?: boolean;
}

export class ContactActionProps {
  businessPartnerType?: SdObjType;
  businessPartnerId?: number;
  id?: number;
  contact?: Contact;
  eMail?: string;
  isPrivatePartner?: boolean;
}

interface CallModalDataProps {
  project?: Project;
  businessPartner?: BusinessPartner;
  projectProcess?: ProjectProcess;
  topicMarkList?: TopicSimple;
  contact?: Contact;
}

export const useContactActions = (
  props: ContactActionProps,
  options?: ContactActionOptions,
  callModalData?: CallModalDataProps
) => {
  const {
    businessPartnerType,
    businessPartnerId,
    id,
    eMail,
    isPrivatePartner,
  } = props || {};
  const {
    displayEMail = false,
    displayPhone = true,
    noButtonPadding = false,
  } = options || {};

  const { businessPartner, contact, project, projectProcess, topicMarkList } =
    callModalData || {};

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: {
        sdObjType: businessPartnerType,
        sdObjMemberCode: businessPartnerId,
        contactCode: id,
      },
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [businessPartnerId, businessPartnerType, id]);

  const completeTelephoneNumbersResponse = useDataProvider(
    completeTelephonenumbersNumbersRequest,
    (!id && !isPrivatePartner) || !businessPartnerId
  );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResponse?.data as unknown as ContactTelephonenumbers;

  const { setCallModal } = useCallModalContext();
  const openEmailMaskController = useOpenEmailMaskControllerContext();

  const result = useMemo(() => {
    if (!id && !isPrivatePartner) {
      return null;
    }

    const actions = [];

    if (eMail && displayEMail) {
      actions.push(
        <IconButton
          key="email"
          sx={{ padding: noButtonPadding ? 0 : undefined }}
          onClick={(e) => {
            e.stopPropagation();
            openEmailMaskController({
              businessPartner: businessPartner,
              project: project,
              contactId: id,
            });
          }}
        >
          <SendIcon color="primary" />
        </IconButton>
      );
    }
    if (
      displayPhone &&
      Object.keys(completeTelephoneNumbers).find(
        (key) =>
          !key.startsWith('__') &&
          completeTelephoneNumbers[key] !== null &&
          completeTelephoneNumbers[key].length > 0
      )
    ) {
      actions.push(
        <IconButton
          key="phone"
          sx={{ padding: noButtonPadding ? 0 : undefined }}
          onClick={(e) => {
            e.stopPropagation();
            setCallModal({
              data: props,
              telephoneNumbers: completeTelephoneNumbers,
              businessPartner,
              contact,
              project,
              projectProcess,
              topicMarkList,
            });
          }}
        >
          <PhoneIcon color="primary" />
        </IconButton>
      );
    }

    return actions;
  }, [
    id,
    isPrivatePartner,
    eMail,
    displayEMail,
    displayPhone,
    completeTelephoneNumbers,
    noButtonPadding,
    openEmailMaskController,
    businessPartner,
    setCallModal,
    props,
    contact,
    project,
    projectProcess,
    topicMarkList,
  ]);

  return {
    actions: result?.length ? <Stack direction="row">{result}</Stack> : null,
    completeTelephoneNumbers,
  };
};
