import { useEffect, useMemo } from 'react';
import { DeepPartial, UnpackNestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { EntityLike } from '@work4all/components/lib/components/entity-picker/types';
import { useLock } from '@work4all/components/lib/hooks';

import { useFormPlus, usePermissions, useUser } from '@work4all/data';
import { ParsedCustomFieldConfig } from '@work4all/data/lib/custom-fields';
import { useTempFileManager } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { IAttachmentEntity } from '@work4all/models';
import { CustomField } from '@work4all/models/lib/Classes/CustomField.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';

import { CustomValidationRules, useJSONSchemaResolver } from '@work4all/utils';

import { usePageTitle } from '../../../../hooks';
import { formatPageTitle } from '../../../../utils/format-page-title';
import { normalizeCustomFields } from '../components/custom-fields/normalize-custom-fields';
import { useMaskContextValue } from '../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../hooks/use-confrm-before-close-mask';
import { normalizeFormValue } from '../hooks/useExtendedFormContext';
import { useInitialFormValue } from '../hooks/useInitialFormValue';
import { MaskControllerProps, MaskExtendedConfig } from '../types';

export interface UseMaskOverlayProps<T> extends MaskControllerProps {
  mask: MaskExtendedConfig;
  newEntityData: T;
  request: DataRequest;
  customRules?: CustomValidationRules;
  customFields?: ParsedCustomFieldConfig[]; // I am thinking about moving it in
  getSubTitle?: (entity: T) => string;
  getTempFileInitialData?: (entity: T) => IAttachmentEntity[];
  normalizeData?: (entity: T, newEntity: T, isCreateMode: boolean) => T;
}

interface ItemType extends EntityLike {
  customFieldList?: CustomField[];
}
const CONST_ARRAY = [];

export function useMaskOverlay<T extends ItemType>(
  props: UseMaskOverlayProps<T>
) {
  const {
    newEntityData,
    request,
    customRules,
    customFields,
    mask,
    getSubTitle,
    getTempFileInitialData,
    normalizeData,
  } = props;
  const { t, i18n } = useTranslation();

  const initialFormValue = useInitialFormValue<T>(request, mask.isCreateMode);

  const dataRaw = useMemo(() => {
    return normalizeData
      ? normalizeData(initialFormValue.value, newEntityData, mask.isCreateMode)
      : mask.isCreateMode
      ? newEntityData
      : initialFormValue.value ?? newEntityData;
  }, [normalizeData, mask.isCreateMode, initialFormValue.value, newEntityData]);

  const data = useMemo(() => {
    const normalizedData = normalizeFormValue(dataRaw);
    if (customFields)
      return normalizeCustomFields(normalizedData, customFields) as T;
    return normalizedData;
  }, [dataRaw, customFields, normalizeData]);

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);
  const form = useFormPlus<T>({
    // @ts-expect-error Resolver ts issue.
    resolver,
    mode: 'onChange',
    defaultValues: data as UnpackNestedValue<DeepPartial<T>>,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  // update data when new
  useEffect(
    () => form.reset(data as UnpackNestedValue<DeepPartial<T>>),
    [form, data]
  );

  // Update Title
  const { params } = mask;
  const { customizedTitle } = params;
  let title = mask.entityVariant || t(`COMMON.${mask.entity.toUpperCase()}`);
  if (customizedTitle) {
    title = i18n.exists(customizedTitle) ? t(customizedTitle) : customizedTitle;
  }
  const subTitle = getSubTitle(data) || null;
  const maskTitle = formatPageTitle([title, subTitle]);
  usePageTitle(maskTitle, { priority: 1 });

  // Use Lock
  const { locked } = useLock();

  // generate mask context
  const maskContext = useMaskContextValue({
    ...mask,
    data,
    customFields,
  });

  // tempfile
  const tempFileInitialData = useMemo(() => {
    return getTempFileInitialData?.(data) ?? CONST_ARRAY;
  }, [getTempFileInitialData, data]);

  const tempFileManager = useTempFileManager(tempFileInitialData);
  // TODO: consider to move here  useAttachementsRelation

  // isDirty
  const isDirty = form.formState.isDirty || tempFileManager.fileListDirty;
  useConfirmBeforeCloseMask(isDirty);

  // entity rights
  const user = useUser();
  const { canAdd, canEdit, canDelete } = usePermissions();
  const entityRights = useMemo(() => {
    return {
      create: canAdd({ entity: mask.entity }),
      read: true,
      update: canEdit({ entity: mask.entity, record: data }),
      delete: canDelete({ entity: mask.entity, record: data }),
    };
  }, [data, user, mask.entity]);

  const isAllowedToSave = mask.isEditMode
    ? entityRights.update
    : entityRights.create;

  // submit button props
  const submitButtonProps: {
    disabled: boolean;
    isDirty?: boolean;
    disableReason: string;
    onClick?: () => void;
  } = {
    disabled: !isDirty || !isAllowedToSave,
    disableReason: !isAllowedToSave ? t('RIGHTS.MISSING') : null,
  };

  return {
    form,
    maskContext,
    data,
    title,
    subTitle,
    submitButtonProps,
    entityRights,
    mask,
    tempFileManager,
    dataRaw,
    initialFormValue,
    locked,
    loading: initialFormValue.loading,
    ...props,
  };
}
