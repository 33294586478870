import { useMemo } from 'react';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';

import { settings, useSetting } from '../../../settings';

import { ProjectPlanningColumnHeader } from './components/ProjectPlanningColumnHeader';
import { useResourceClasses } from './use-resource-classes';

export interface ProjectPlanningColumnsProps {
  onCellSelect?: (props: { project: Project; ressourceClass: LookUp }) => void;
  selectedRessourceClassCell?: { project: Project; ressourceClass: LookUp };
  onUtilizationClick?: (userIds: number[], project: Project) => void;
}

export const useProjectPlanningColumns = (
  props: ProjectPlanningColumnsProps
) => {
  const { onCellSelect, selectedRessourceClassCell, onUtilizationClick } =
    props;
  const { data: ressourceClasses } = useResourceClasses();

  const projectPlanningTargetSource = useSetting(
    settings.projectPlanningTargetSource()
  );

  const resourcePlanningColoumnTimeUnit = useSetting(
    settings.resourcePlanningColoumnTimeUnit()
  );

  const result = useMemo(
    () =>
      ressourceClasses.map((rc) => ({
        title: [rc.name],
        displayTitle: rc.name,
        cell: {
          type: 'ProjectPlanning',
          params: {
            entity: projectPlanningTargetSource.value,
            onUtilizationClick,
            onCellSelect,
            rc,
            resourcePlanningColoumnTimeUnit:
              resourcePlanningColoumnTimeUnit.value,
            selectedRessourceClassCell,
          },
        },
        id: rc.id.toString(),
        path: ['COMMON.RESSOURCECLASS_plural'],
        Header: () => <ProjectPlanningColumnHeader ressourceClass={rc} />,
        width: 200,
        sortable: false,
        groupable: false,
        quickSearchable: false,
        defaultHidden: true,
        filterable: {
          type: 'None',
        },
        disableFooterSum: false,
      })),
    [
      ressourceClasses,
      projectPlanningTargetSource.value,
      onCellSelect,
      onUtilizationClick,
      resourcePlanningColoumnTimeUnit.value,
      selectedRessourceClassCell,
    ]
  );

  return result;
};
