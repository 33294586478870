import { CopyButtonCell } from '../CopyButtonCell';

import { useOpenTimeTrackingMask } from './hooks';

type Props = {
  value: number;
};

export const TimeTrackingCopyButtonCell = ({ value }: Props) => {
  const openTimeTrackingMask = useOpenTimeTrackingMask();

  return (
    <CopyButtonCell
      onClick={() => {
        openTimeTrackingMask({ id: value, mode: 'clone' });
      }}
    />
  );
};
