import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EMAIL_TEMPLATE_PICKER_DATA,
  EmailTemplatePicker,
  EmailTemplatePickerProps,
} from '@work4all/components/lib/components/entity-picker/email-template-picker/EmailTemplatePicker';

import { useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { canAddEmailTemplate } from '@work4all/utils/lib/permissions';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';
import { useEmailTemplaterContext } from '../../containers/mask-overlays/mask-overlay/views/email/email-templater/email-templater-context';
import { settings } from '../../settings';

import { EntityPickerActions } from './EntityPickerActions';

interface EmailTemplatePickerFieldProps
  extends Omit<
    EmailTemplatePickerProps,
    'standardTemplates' | 'setStandardTemplates'
  > {
  label?: string;
  error?: string;
  disabled?: boolean;
  input?: React.ReactElement;
}

export const EmailTemplatePickerField = React.forwardRef<
  HTMLDivElement,
  EmailTemplatePickerFieldProps
>(function EmailTemplatePickerField(props, ref) {
  const { error, disabled = false, label, input, ...pickerProps } = props;
  const {
    value,
    onChange: pickerOnChange,
    data,
    clearable = true,
  } = pickerProps;

  const { t } = useTranslation();
  const user = useUser();

  const popoverRef = useRef<EntityPickerPopover>(null);

  const { value: standardTemplates, set: setStandardTemplates } = useSetting(
    settings.standardEmailTemplates()
  );
  const { isGeneralEmailSource, initialTemplate } = useEmailTemplaterContext();

  const allFields = useMemo(() => {
    return { ...EMAIL_TEMPLATE_PICKER_DATA, ...data };
  }, [data]);

  const field = input ? (
    input
  ) : (
    <PickerTargetButton
      ref={ref}
      value={value ? value.name : ''}
      label={label ?? t('INPUTS.TEMPLATE')}
      error={error}
      onClear={
        disabled || !clearable
          ? undefined
          : () => {
              pickerOnChange(null);
            }
      }
    />
  );

  if (disabled) {
    return field;
  }

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <EmailTemplatePicker
          {...pickerProps}
          isGeneralEmailSource={isGeneralEmailSource}
          initialTemplateKind={initialTemplate?.eMailTemplateKind}
          standardTemplates={standardTemplates}
          setStandardTemplates={setStandardTemplates}
        />
      }
      footer={
        <EntityPickerActions
          disableAddButton={!canAddEmailTemplate(user)}
          entity={Entities.eMailTemplate}
          multiple={false}
          data={allFields}
          prefilter={
            value?.eMailTemplateKind
              ? [
                  {
                    $or: [
                      { eMailTemplateKind: { $eq: value?.eMailTemplateKind } },
                      { eMailTemplateKind: { $eq: EMailTemplateKind.KEINE } },
                    ],
                  },
                ]
              : []
          }
          onChange={(value) => {
            pickerOnChange(value as EMailTemplate);
            popoverRef.current?.close();
          }}
        />
      }
    >
      {field}
    </EntityPickerPopover>
  );
});
