import { uniqBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import short from 'short-uuid';

import { settings, useSetting } from '../../../settings';
import { AppointmentCalendarView, AppointmentCalendarViewInit } from '../types';

export function useCalendarViews(): {
  views: AppointmentCalendarView[];
  createView: (init: AppointmentCalendarViewInit) => string;
  deleteView: (id: string) => void;
} {
  const { value: viewsOriginal, set: setViews } = useSetting(
    settings.calendarViews()
  );

  // Views saved in the old version had `hiddenUserIds` instead of
  // `focusedUserIds`, so patch invalid configs here.
  const views = useMemo(() => {
    return viewsOriginal.map((view) => {
      if (view.params.focusedUserIds !== undefined) {
        return view;
      }

      const {
        userIds,
        groupMode,
        range,
        appointmentStates,
        planningAppointments,
      } = view.params;

      return {
        ...view,
        params: {
          userIds,
          focusedUserIds: [],
          groupMode,
          range,
          appointmentStates,
          planningAppointments,
        },
      };
    });
  }, [viewsOriginal]);

  const createView = useCallback(
    (init: AppointmentCalendarViewInit) => {
      const id = short.generate();

      const view: AppointmentCalendarView = { ...init, id };

      // Replace the old view with the same name if there are conflicts.
      const nextViews = uniqBy([view, ...views], (view) => view.name).sort(
        (a, b) => {
          return a.name.localeCompare(b.name);
        }
      );

      setViews(nextViews);

      return id;
    },
    [views, setViews]
  );

  const deleteView = useCallback(
    (id: string) => {
      const remainingViews = views.filter((view) => view.id !== id);

      setViews(remainingViews);
    },
    [views, setViews]
  );

  return {
    views,
    createView,
    deleteView,
  };
}
