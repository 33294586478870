import { useCallback, useRef, useState } from 'react';
import { Options, useHotkeys } from 'react-hotkeys-hook';
import { VariableSizeList } from 'react-window';

import { RenderItemData } from '../../components/PickerListItem';

const config: Options = {
  enableOnTags: ['INPUT'],
};

//react-hook-keys optimizes the mgmt of key events on the document level
//we export this to make the <IgnoreKeys> component of the maskOverlayContent leave those keys through
export const defaultPickerHandledKey = ['up', 'down', 'enter'];

type RenderableItems = RenderItemData<{
  id?: string | number;
  unselectable?: boolean;
}>['items'];

const getNextSelectable = (
  all: RenderableItems,
  currentIdx: number,
  dir: number
) => {
  const activeEl = all[currentIdx];
  const selectables = all.filter((el) => !el.unselectable);
  const activeIdx = selectables.indexOf(activeEl);
  if (dir < 0) {
    const nextIdx = Math.max(0, activeIdx + dir);
    return all.indexOf(selectables[nextIdx]);
  } else {
    const nextIdx = Math.min(selectables.length - 1, activeIdx + dir);
    return all.indexOf(selectables[nextIdx]);
  }
};

export const usePickerKeyNav = (
  total: number,
  onToggle: RenderItemData<unknown>['onToggle'],
  items: RenderableItems
) => {
  const itemsRef = useRef(items);
  itemsRef.current = items;
  const [activeRowIndex, setActiveRowIndex] = useState(
    items.findIndex((el) => !el.unselectable)
  );
  const infiniteListRef = useRef<VariableSizeList>();
  const activeRowIndexRef = useRef(activeRowIndex);
  activeRowIndexRef.current = activeRowIndex;
  const totalRef = useRef(total);
  totalRef.current = total;

  const goTo = useCallback((dir: number) => {
    const nextIdx = getNextSelectable(
      itemsRef.current,
      activeRowIndexRef.current,
      dir
    );
    setActiveRowIndex(nextIdx);
    //scroll one item fruther in the direction to reveal hidden unselectable
    infiniteListRef.current?.scrollToItem(nextIdx + dir);
  }, []);

  const select = useCallback(() => {
    onToggle(itemsRef.current[activeRowIndexRef.current]);
  }, [onToggle]);

  const callback = useCallback(
    (e, handler) => {
      switch (handler.key) {
        case 'up':
          e.preventDefault();
          goTo(-1);
          break;
        case 'down':
          e.preventDefault();
          goTo(+1);
          break;
        case 'enter':
          e.preventDefault();
          onToggle(itemsRef.current[activeRowIndexRef.current]);
          break;
        default:
          setActiveRowIndex(0);
          infiniteListRef.current?.scrollToItem(0);
      }
    },
    [goTo, onToggle]
  );

  const keyScopeRef = useHotkeys<HTMLInputElement>(
    defaultPickerHandledKey.join(', '),
    callback,
    config
  );
  return {
    infiniteListRef,
    keyScopeRef,
    activeRowIndex,
    goTo,
    select,
  };
};
