import React from 'react';
import { TableInstance } from 'react-table';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/checkList-table-schema.json';

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const FORCE_REQUEST_FIELDS: CheckList<EMode.query> = {
  done: null,
};

export const ChecklistsTable = React.forwardRef<TableInstance, IEntityTable>(
  function ChecklistsTable(_props, ref) {
    const dataTable = useEntityDataTable<CheckList, never>({
      schema: schema as never,
      defaultSort,
      forceRequestFields: FORCE_REQUEST_FIELDS,
      rowModifiers: (value) => {
        return {
          isFaded: value.done,
        };
      },
    });

    return <EntityTable ref={ref} {...dataTable} {..._props} />;
  }
);
