import { SelectedPositionProvider } from '../../../../../../erp/components/tab-panels/positions/components/hooks/use-selected-position';

import { CheckListPositionsTableContent } from './CheckListPositionsTableContent';

export const CheckListPositionsTable = () => {
  return (
    <SelectedPositionProvider>
      <CheckListPositionsTableContent />
    </SelectedPositionProvider>
  );
};
