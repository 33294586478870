import { Box, Typography } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import { useTranslation } from 'react-i18next';

import { ChecklistePositionPrioritaet } from '@work4all/models/lib/Enums/ChecklistePositionPrioritaet.enum';

import { getCheckListPositionPriorityIcon } from '../../../utils/getCheckListPositionPriorityIcon';
import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';

interface Props
  extends Omit<
    IPickerProps<{ id: string; name: string }, false>,
    'value' | 'onChange' | 'multiple'
  > {
  value: ChecklistePositionPrioritaet | null;
  onChange: (value: ChecklistePositionPrioritaet | null) => void;
}

const PRIORITIES = Object.values(ChecklistePositionPrioritaet);

const renderItem = (value) => {
  return (
    <Box alignItems="center" display="flex">
      <Box width={30}>{getCheckListPositionPriorityIcon(value.id)}</Box>
      <Typography>{value.name}</Typography>
    </Box>
  );
};

export function CheckListPositionPriorityPicker(props: Props) {
  const { value, onChange, ...rest } = props;

  const { t } = useTranslation();

  const dataSet = PRIORITIES.map((priority) => {
    return {
      id: priority,
      name: t(`CHECK_LIST_POSITIONS_TABLE.${priority}`),
    };
  });

  const onChangeHandler = useEventCallback(
    (value: { id: string; name: string }) => {
      onChange((value?.id as ChecklistePositionPrioritaet) ?? null);
    }
  );

  return (
    <FixedDataPicker
      value={value ? { id: value, name: value } : null}
      onChange={onChangeHandler}
      dataSet={dataSet}
      renderItemContent={renderItem}
      multiple={false}
      {...rest}
    />
  );
}
