import { round } from 'lodash';
import { useMemo, useRef, useState } from 'react';

import { throwInDev } from '@work4all/utils';

import { useSaveCursorPosition } from './use-save-cursor-position';

export interface UseNumberInputProps {
  value: number;
  onChange?: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  decimals?: number;
  prefix?: string;
  suffix?: string;
  decimalSeparator?: string;
  thousandSeparator?: string;
  autoSelect?: boolean;
}

const DEFAULT_THOUSAND_SEPARATOR = '';
const DEFAULT_DECIMAL_SEPARATOR = '.';

interface InputProps {
  ref: React.Ref<HTMLInputElement>;
  value: string;
  onKeyDown(event: React.KeyboardEvent<HTMLInputElement>): void;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  onFocus(event: React.FocusEvent<HTMLInputElement>): void;
  onBlur(event: React.FocusEvent<HTMLInputElement>): void;
}

export interface UseNumberInputReturn {
  getInputProps(): InputProps;
  canIncrement: boolean;
  canDecrement: boolean;
  increment(): void;
  decrement(): void;
}

export function useNumberInput(
  props: UseNumberInputProps
): UseNumberInputReturn {
  const {
    thousandSeparator,
    decimalSeparator,
    min,
    max,
    step = 1,
    decimals,
    prefix = '',
    suffix = '',
    autoSelect,
  } = validateProps(props);

  const emitChange = (value: number) => {
    if (props.value !== value) {
      props.onChange?.(value);
    }
  };

  const allowNegative = min == null || min < 0;

  const inputRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<null | {
    valueAsString: string;
    valueAsNumber: number;
  }>(null);

  const saveCursorPosition = useSaveCursorPosition(inputRef);

  const { valueAsNumber, valueAsString } = useMemo(() => {
    const valueAsNumber = state ? state.valueAsNumber : props.value;

    const valueAsString = state
      ? state.valueAsString
      : formatValue(props.value, {
          decimals,
          thousandSeparator,
          decimalSeparator,
          prefix,
          suffix,
        });

    return {
      valueAsNumber,
      valueAsString,
    };
  }, [
    props.value,
    state,
    thousandSeparator,
    decimalSeparator,
    decimals,
    prefix,
    suffix,
  ]);

  const canIncrement = max == null || valueAsNumber < max;
  const canDecrement = min == null || valueAsNumber > min;

  function incrementValueBy(value: number) {
    let nextValue = clamp(valueAsNumber + value, min, max);

    if (decimals != null) {
      nextValue = round(nextValue, decimals);
    }

    const formatted = formatValue(nextValue, {
      decimals,
      thousandSeparator,
      decimalSeparator,
      prefix,
      suffix,
    });

    setState({
      valueAsNumber: nextValue,
      valueAsString: formatted,
    });

    emitChange(nextValue);
  }

  function increment() {
    incrementValueBy(step);
  }

  function decrement() {
    incrementValueBy(-step);
  }

  function handleChange(value: string) {
    const processed = processInputValue(value, {
      decimals,
      decimalSeparator,
      thousandSeparator,
      prefix,
      suffix,
      min: undefined,
      max: undefined,
      allowNegative: allowNegative,
    });

    if (Number.isNaN(processed.valueAsNumber)) {
      throw new Error("Parsed value isn't a number");
    }

    const { valueAsNumber, valueAsString } = processed;

    const cursor = inputRef.current.selectionStart;

    const digits = countDigits(value.substring(0, cursor));
    let newCursor = skipDigits(processed, digits);

    if (
      isCursorAfterDecimalSeparator() &&
      isNewCursorDirectlyBeforeDecimalSeparator()
    ) {
      newCursor += decimalSeparator.length;
    }

    function isCursorAfterDecimalSeparator() {
      const index = value.indexOf(decimalSeparator);
      return index !== -1 && cursor >= index + decimalSeparator.length;
    }

    function isNewCursorDirectlyBeforeDecimalSeparator() {
      return (
        processed.valueAsString.substring(
          newCursor,
          newCursor + decimalSeparator.length
        ) === decimalSeparator
      );
    }

    saveCursorPosition(newCursor);
    emitChange(processed.valueAsNumber);
    setState({ valueAsNumber, valueAsString });
  }

  function handleDeleteEvent(
    event: React.KeyboardEvent<HTMLInputElement>,
    direction: 'backward' | 'forward'
  ) {
    if (
      event.currentTarget.selectionStart === event.currentTarget.selectionEnd
    ) {
      event.preventDefault();

      const value = event.currentTarget.value;
      const _cursor = event.currentTarget.selectionStart;
      const cursor = direction === 'forward' ? _cursor : _cursor - 1;
      const delta = direction === 'forward' ? 1 : -1;

      for (let i = cursor; i >= 0 && cursor < value.length; i += delta) {
        const char = value.charAt(i);
        const isDeletableChar =
          char === '-' || char === decimalSeparator || isDigit(char);

        if (isDeletableChar) {
          event.currentTarget.setSelectionRange(i, i);

          const before = value.substring(0, i);
          const after = value.substring(i + 1);

          handleChange(before + after);

          break;
        }
      }
    }
  }

  function getInputProps(): InputProps {
    return {
      ref: inputRef,
      value: valueAsString,
      onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        switch (event.key) {
          case 'ArrowUp':
            event.preventDefault();
            increment();
            break;
          case 'ArrowDown':
            event.preventDefault();
            decrement();
            break;
          case 'Backspace': {
            handleDeleteEvent(event, 'backward');
            break;
          }
          case 'Delete': {
            handleDeleteEvent(event, 'forward');
            break;
          }
        }
      },
      onChange(event: React.ChangeEvent<HTMLInputElement>) {
        handleChange(event.target.value);
      },
      onFocus(event: React.FocusEvent<HTMLInputElement>) {
        const valueAsString = formatValue(props.value, {
          decimals,
          thousandSeparator,
          decimalSeparator,
          prefix,
          suffix,
        });

        setState({
          valueAsNumber: props.value,
          valueAsString,
        });

        if (autoSelect) {
          event.currentTarget.select();
        }
      },
      onBlur(_event: React.FocusEvent<HTMLInputElement>) {
        const processed = processInputValue(state.valueAsString, {
          decimals,
          decimalSeparator,
          thousandSeparator,
          prefix,
          suffix,
          min,
          max,
          allowNegative,
        });

        const { valueAsNumber } = processed;

        emitChange(valueAsNumber);

        setState(null);
      },
    };
  }

  return {
    getInputProps,
    canIncrement,
    canDecrement,
    increment,
    decrement,
  };
}

function processInputValue(
  value: string,
  options: {
    decimals: number | undefined;
    decimalSeparator: string;
    thousandSeparator: string;
    prefix: string;
    suffix: string;
    min: number | undefined;
    max: number | undefined;
    allowNegative: boolean;
  }
): ProcessedValue {
  const parsed = parseValue(value, options);
  const formatted = formatParsedValue(parsed, options);
  return { ...parsed, ...formatted };
}

function parseValue(
  value: string,
  options: {
    decimals: number | undefined;
    decimalSeparator: string;
    min: number | undefined;
    max: number | undefined;
    allowNegative: boolean;
  }
): ParsedValue {
  const { decimals, decimalSeparator, min, max, allowNegative } = options;

  const isNegative = allowNegative && isNegativeNumber(value);

  // If decimals are not allowed, remove all decimal separators from the string
  // before parsing so that the decimal part is not cut off.
  if (decimals === 0) {
    value = value.split(decimalSeparator).join('');
  }

  let decimalSeparatorIndex = value.indexOf(decimalSeparator);

  if (decimalSeparatorIndex === -1) {
    decimalSeparatorIndex = value.length;
  }

  const wholePartRaw = value.substring(0, decimalSeparatorIndex);
  const decimalPartRaw = value.substring(decimalSeparatorIndex + 1);

  const sign = isNegative ? '-' : '';
  const wholePart = wholePartRaw.replace(/\D/g, '');

  const decimalPart = decimalPartRaw.replace(/\D/g, '').substring(0, decimals);

  const valueAsString = `${sign}${wholePart}.${decimalPart}0`;
  const valueAsNumber = clamp(Number(valueAsString), min, max);

  if (Number.isNaN(valueAsNumber)) {
    return {
      valueAsNumber: clamp(0, min, max),
      isNegative: false,
      sign: '',
      wholePart: '',
      decimalPart: '',
      hasDecimalPart: false,
    };
  }

  return {
    valueAsNumber,
    isNegative,
    sign,
    wholePart,
    decimalPart,
    hasDecimalPart: decimalSeparatorIndex !== value.length,
  };
}

interface ParsedValue {
  valueAsNumber: number;
  isNegative: boolean;
  sign: string;
  wholePart: string;
  decimalPart: string;
  hasDecimalPart: boolean;
}

interface FormattedValue {
  valueAsString: string;
}

interface ProcessedValue extends ParsedValue, FormattedValue {}

function formatParsedValue(
  parsed: ParsedValue,
  options: {
    decimals: number | undefined;
    decimalSeparator: string;
    thousandSeparator: string;
    prefix: string;
    suffix: string;
  }
): FormattedValue {
  const { sign, wholePart, decimalPart, hasDecimalPart } = parsed;

  const { decimals, decimalSeparator, thousandSeparator, prefix, suffix } =
    options;

  let result = '';

  const first = wholePart.length % 3 || 3;
  const offset = 3 - first;

  for (let i = 0; i < Math.ceil(wholePart.length / 3); i++) {
    if (i === 0) {
      result += wholePart.substring(0, first);
    } else {
      result += thousandSeparator;
      result += wholePart.substring(i * 3 - offset, i * 3 + 3 - offset);
    }
  }

  if (hasDecimalPart && (decimals == null || decimals > 0)) {
    result += decimalSeparator;
    result += decimalPart;
  }

  if (result) {
    if (prefix) {
      result = prefix + result;
    }

    if (suffix) {
      result = result + suffix;
    }

    if (sign) {
      result = sign + result;
    }
  }

  return {
    valueAsString: result,
  };
}

function formatValue(
  value: number,
  options: {
    decimals: number | undefined;
    decimalSeparator: string;
    thousandSeparator: string;
    prefix: string;
    suffix: string;
  }
): string {
  const { decimals, decimalSeparator, thousandSeparator, prefix, suffix } =
    options;

  if (value == null || Number.isNaN(value)) {
    return '';
  }

  // Do not truncate decimal places if the value has more than `decimals`
  // decimal places and display it as is.
  const valueAsString = getValueAsString(value, { decimals });

  const parsed = parseValue(valueAsString, {
    decimals: undefined,
    decimalSeparator: '.',
    min: undefined,
    max: undefined,
    allowNegative: true,
  });

  const formatted = formatParsedValue(parsed, {
    decimals: undefined,
    decimalSeparator,
    thousandSeparator,
    prefix,
    suffix,
  });

  return formatted.valueAsString;
}

function getValueAsString(
  value: number,
  options: {
    decimals: number | undefined;
  }
): string {
  const { decimals } = options;

  const valueToString = value.toString();

  if (decimals == null) {
    return valueToString;
  }

  const valueToFixed = value.toFixed(decimals);

  return valueToString.length > valueToFixed.length
    ? valueToString
    : valueToFixed;
}

function isNegativeNumber(value: string): boolean {
  try {
    const chars = [...value];
    const minusCharCount = chars.filter((char) => char === '-').length;
    return minusCharCount % 2 === 1;
  } catch (e) {
    return false;
  }
}

function clamp(
  value: number,
  min: number | undefined,
  max: number | undefined
): number {
  if (min != null && value < min) {
    return min;
  }
  if (max != null && value > max) {
    return max;
  }
  return value;
}

function isDigit(char: string): boolean {
  return char >= '0' && char <= '9';
}

function countDigits(str: string): number {
  let digits = 0;

  for (const char of str) {
    if (isDigit(char)) {
      digits++;
    }
  }

  return digits;
}

function skipDigits(processed: ProcessedValue, digits: number) {
  let cursor = processed.isNegative ? 1 : 0;

  for (; digits > 0 && cursor < processed.valueAsString.length; cursor += 1) {
    if (isDigit(processed.valueAsString.charAt(cursor))) {
      digits -= 1;
    }
  }

  return cursor;
}

function validateProps(props: UseNumberInputProps): UseNumberInputProps {
  const result = { ...props };

  if (typeof result.decimalSeparator !== 'string') {
    result.decimalSeparator = DEFAULT_DECIMAL_SEPARATOR;
  }

  if (result.decimalSeparator.length !== 1) {
    throwInDev('Decimal separator must be a single character');
    result.decimalSeparator = DEFAULT_DECIMAL_SEPARATOR;
  }

  if (typeof result.thousandSeparator !== 'string') {
    result.thousandSeparator = DEFAULT_THOUSAND_SEPARATOR;
  }

  if (result.thousandSeparator === result.decimalSeparator) {
    throwInDev('Thousand separator must be different from decimal separator');
    result.thousandSeparator = DEFAULT_THOUSAND_SEPARATOR;
  }

  return result;
}
