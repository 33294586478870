import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { useBoard } from '../table/components/kanban-board/hooks/use-board';
import { useEditTicketStatus } from '../TicketStatusCell';

const statuses = Object.values(TicketStatus);

export const useTicketBoard = () => {
  const board = useBoard();
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return statuses.map((status) => ({
      key: status,
      status: t(`TICKET_STATUS.${status}`),
      prefilter: [
        {
          status1: { $eq: status },
        },
      ],
    }));
  }, [t]);

  const onDrop = useEditTicketStatus('status1');
  return { onDrop, columns, ...board };
};
