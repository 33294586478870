import { useTranslation } from 'react-i18next';

type Props = {
  value: string;
};

export const SalesOpportunityStatusCell = (props: Props) => {
  const { value } = props;
  const { t, i18n } = useTranslation();
  if (!i18n.exists(`COMMON.SALESOPPORTUNITIES.${value}`)) return <></>;
  return <div>{t(`COMMON.SALESOPPORTUNITIES.${value}`)}</div>;
};
