import { useEventCallback } from '@mui/material/utils';
import { useEffect, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { TopicSimple } from '@work4all/models/lib/Classes/TopicSimple.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { MaskExtendedConfig } from '../../../types';
import {
  formatTopicMarkName,
  titleWithTopicMark,
} from '../../../utils/titleWithTopicMark';
import { useFormUpdate } from '../../../utils/use-form-update';
import { CrmMaskFormValue } from '../types';

function getBusinessPartnerType(
  businessPartner: Customer | Supplier
): SdObjType {
  if (businessPartner) {
    type GraphQLEntity = { __typename: string };

    const typename = (businessPartner as GraphQLEntity).__typename;

    if (typename === 'Kunde') {
      return SdObjType.KUNDE;
    }
  }

  return SdObjType.LIEFERANT;
}

export const useCrmFormUpdate = (
  form: UseFormReturn<CrmMaskFormValue>,
  data: CrmMaskFormValue,
  mask: MaskExtendedConfig<string>
) => {
  const { getValues, getFieldState } = form;

  const skipUpdatingDate = useEventCallback(
    (key: 'date' | 'endDate', value: string) => {
      return (
        mask.entity !== Entities.task ||
        (mask.isEditMode && !data.id) ||
        data?.[key] === value
      );
    }
  );

  const usedTopicMark = useRef<string>();
  useEffect(() => {
    usedTopicMark.current = data?.topicMarkList?.[0]
      ? formatTopicMarkName(data.topicMarkList[0].name)
      : undefined;
  }, [data]);

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType = getBusinessPartnerType(businessPartner);
        const contact = businessPartner?.isPrivateCustomer
          ? null
          : businessPartner?.mainContact ?? null;

        return {
          businessPartnerId,
          businessPartnerType,
          contact,
        };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues('businessPartner.data');
        const projectProcessField = getValues('projectProcess');
        const projectProcess =
          project?.id === projectProcessField?.projectId
            ? projectProcessField
            : null;

        if (businessPartnerField !== null) {
          return { projectId, projectProcess };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectProcess,
            'businessPartner.data': businessPartner,
          };
        }
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;
        return { projectProcessId };
      },
      user: (user: User) => {
        const userId = user?.id ?? 0;

        return { userId: userId };
      },
      topicMarkList: (topicMarkList: TopicSimple[]) => {
        const { title, topicMark } = titleWithTopicMark(
          getValues('title'),
          topicMarkList,
          usedTopicMark.current,
          !getFieldState('topicMarkList').isDirty
        );

        usedTopicMark.current = topicMark;

        if (title) return { title };
      },
      date: (date: string) => {
        if (skipUpdatingDate('date', date)) return;

        const todaysDate = Date.now();
        const endDate = getValues('endDate');
        if (
          Date.parse(date) > todaysDate &&
          (!endDate || Date.parse(endDate) < Date.parse(date))
        ) {
          return {
            endDate: date,
          };
        }
      },
      endDate: (endDate: string) => {
        if (skipUpdatingDate('endDate', endDate)) return;

        const startDate = getValues('date');

        if (Date.parse(endDate) < Date.parse(startDate) && endDate) {
          return {
            date: endDate,
          };
        }
      },
    },
    form
  );
};
