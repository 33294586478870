import { useEventCallback } from '@mui/material/utils';
import { DateTime } from 'luxon';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TimeInputPicker,
  TimeInputPickerProps,
} from '@work4all/components/lib/input/time-input-picker';

import { useUser } from '@work4all/data';

import { UserPresenceTimeInfoItem } from '@work4all/models/lib/Classes/UserPresenceTimeInfoItem.entity';

import { useTimeEntryMutation } from './hooks/useTimeEntryMutation';
import { isValidPause } from './utils';

type OnChangeHandlerProps = {
  id: number;
  newValue: string | undefined;
};

type Props = {
  field: 'start' | 'end';
  startTimeStamp: UserPresenceTimeInfoItem;
  endTimeStamp: UserPresenceTimeInfoItem | undefined;
} & TimeInputPickerProps;

export const PauseTimeInputPicker = ({
  field,
  startTimeStamp,
  endTimeStamp,
  ...rest
}: Props) => {
  const user = useUser();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { update, onBlur } = useTimeEntryMutation();

  const stamp = useMemo(() => {
    return field === 'start' ? startTimeStamp : endTimeStamp;
  }, [endTimeStamp, field, startTimeStamp]);

  const [value, setValue] = useState(DateTime.fromISO(stamp.timestamp));

  const showMinimumPauseError = useEventCallback(() => {
    enqueueSnackbar(
      t('ERROR.MINIMUM_PAUSE_TIME', {
        value: user.workTimeTrackingMinBreakTime,
      }),
      {
        variant: 'error',
        autoHideDuration: 5000,
      }
    );
  });

  const onChangeHandler = useEventCallback(
    ({ id, newValue }: OnChangeHandlerProps) => {
      const start = field === 'start' ? newValue : startTimeStamp?.timestamp;
      const end = field === 'end' ? newValue : endTimeStamp?.timestamp;

      const isValid = isValidPause({
        start,
        end,
        minimumPauseAmount: user.workTimeTrackingMinBreakTime,
      });

      let value = DateTime.fromISO(stamp.timestamp);

      if (isValid) {
        const stringValue = field === 'start' ? start : end;
        value = DateTime.fromISO(stringValue);

        update(id, stringValue);
      } else {
        showMinimumPauseError();
      }

      setValue(value);
    }
  );

  return (
    <TimeInputPicker
      {...rest}
      onBlur={onBlur}
      onDestroyPopover={onBlur}
      onChange={(e) => {
        onChangeHandler({
          id: stamp.id,
          newValue: e.currentTarget?.value,
        });
      }}
      value={value}
    />
  );
};
