import styles from './FileListPreview.module.scss';

import { IconButton } from '@mui/material';
import { MouseEventHandler, useEffect } from 'react';

import { ReactComponent as EditCopyIcon } from '@work4all/assets/icons/edit-pdf.svg';

import { useDataMutation } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ConvertPopover } from '../../convert-popover/ConvertPopover';
import {
  PreviewInputsProvider,
  PreviewTitleInput,
} from '../components/preview-input';
import { RequestedDocumentData } from '../document-preview/consts/RequestDocumentData';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { FileListGalleryPagination } from './FileListGalleryPagination';
import {
  FileListPreviewContent,
  FileListPreviewContentProps,
} from './FileListPreviewContent';
import { useSelectedIndex } from './use-selected-index';

export interface FileListPreviewProps
  extends Omit<
      FileListPreviewContentProps,
      'selectedIndex' | 'onSelectedIndexChange'
    >,
    EntityPreviewProps<Document> {
  title: string;
  onPdfEditClicked?: MouseEventHandler<HTMLButtonElement>;
  onDeleteClicked?: MouseEventHandler<HTMLButtonElement>;
  onSelectedIndexChange?: (index: number) => void;
  entries: Document[];
}

export function FileListPreview(props: FileListPreviewProps) {
  const {
    title,
    files,
    iconProps,
    onPdfEditClicked,
    onDeleteClicked,
    onSelectedIndexChange,
    entries,
    ...handlers
  } = props;

  const [_index, setIndex] = useSelectedIndex({ length: files.length });
  const index = files.length === 1 ? 0 : _index;

  useEffect(() => {
    onSelectedIndexChange?.(_index);
  }, [_index, onSelectedIndexChange]);

  const convertPopoverState = usePopoverState('bottom');

  const [mutate] = useDataMutation<Document, EMode.upsert>({
    entity: Entities.document,
    mutationType: EMode.upsert,
    responseData: RequestedDocumentData as unknown as Document,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.document,
    entries: entries,
    mutate,
  });

  const { canEditAllSelected } = entityPreview;

  const { onShareClicked, convertProps, onEditClicked } = handlers;

  function renderHeader() {
    return (
      <PreviewInputsProvider
        {...entityPreview}
        entity={Entities.document}
        entries={entries}
      >
        {convertProps && (
          <ConvertPopover
            {...convertProps}
            popoverState={convertPopoverState}
          />
        )}

        <PreviewTitleInput
          accessor="note"
          {...handlers}
          leftActions={
            onPdfEditClicked ? (
              <IconButton
                color="primary"
                onClick={onPdfEditClicked}
                className={styles['fileheader-icon']}
                size="small"
              >
                <EditCopyIcon />
              </IconButton>
            ) : null
          }
          rightActions={
            files.length > 1 &&
            index !== -1 && (
              <FileListGalleryPagination
                page={index}
                count={files.length}
                onChange={setIndex}
              />
            )
          }
        />
      </PreviewInputsProvider>
    );
  }

  function renderContent() {
    return (
      <FileListPreviewContent
        files={files}
        selectedIndex={index}
        onSelectedIndexChange={setIndex}
        iconProps={iconProps}
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      {renderHeader()}
      {renderContent()}
    </div>
  );
}
