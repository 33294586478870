import styles from './ApprovalPage.module.scss';

import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useNavigate, useUser } from '@work4all/data';

import {
  canApproveVacations,
  canViewInboundInvoicesApprovalCenter,
} from '@work4all/utils/lib/permissions';

import { NoAccess } from '../NoAccess';

import { TopBar } from './components/top-bar/TopBar';
import { InvoicesApprovalContainer } from './containers/invoices/InvoicesApprovalContainer';
import { VacationsApprovalContainer } from './containers/vacations/VacationsApprovalContainer';

export enum ApprovalSource {
  reViewModel = 'reViewModel',
  vacation = 'vacation',
}

const isApprovalSource = (value: any): value is ApprovalSource => {
  return Object.values(ApprovalSource).includes(value);
};

const useIsApprovalPath = () => {
  const location = useLocation();
  return Object.values(ApprovalSource).some((value) =>
    location.pathname.includes(value)
  );
};

export function ApprovalPage() {
  const { subEntityType } = useParams<{
    subEntityType: ApprovalSource;
  }>();

  const user = useUser();
  const navigate = useNavigate();

  const isApprovalPath = useIsApprovalPath();

  useEffect(() => {
    if (!isApprovalSource(subEntityType) && !isApprovalPath) {
      navigate('/home');
    }
  }, [isApprovalPath, navigate, subEntityType]);

  const sourceComp = useMemo(() => {
    switch (subEntityType) {
      case ApprovalSource.reViewModel:
        if (canViewInboundInvoicesApprovalCenter(user))
          return <InvoicesApprovalContainer />;
        return <NoAccess reason="route" />;
      case ApprovalSource.vacation:
        if (canApproveVacations(user)) return <VacationsApprovalContainer />;
        return <NoAccess reason="route" />;
      default:
        return null;
    }
  }, [subEntityType, user]);

  return (
    <div className={styles.root}>
      <TopBar view={subEntityType} user={user} />
      <div className={styles.wrap}>
        <div className={styles.tableWrapper}>{sourceComp}</div>
      </div>
    </div>
  );
}
