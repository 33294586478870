import styles from './TableCell.module.scss';

import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import React, { CSSProperties, forwardRef } from 'react';
import { TableCommonProps } from 'react-table';

import { ChecklistPositionKind } from '@work4all/models/lib/Enums/ChecklistPositionKind.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { RowSizeMode } from '../../../../../../../../../../../../settings/settings';
import { EditTablePositionKind } from '../../types';

export type ITableCellProps = TableCommonProps & {
  onDoubleClick?: React.MouseEventHandler<HTMLDivElement>;
  onClick?: (element: HTMLElement | React.MouseEvent<HTMLElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLDivElement>;
  children?: React.ReactNode;
  id?: string;
  kind?: EditTablePositionKind;
  shouldWrap?: boolean;
  rowInEditMode?: boolean;
  rowSizeMode?: RowSizeMode;
  expandable?: boolean;
  innerRef?: (instance: HTMLElement) => void;
  toggleExpanded?: () => void;
  expanded?: boolean;
};

export const TableCell = forwardRef<HTMLDivElement, ITableCellProps>(
  function TableCell(props, ref) {
    const {
      className,
      id,
      kind,
      style,
      shouldWrap,
      rowSizeMode,
      rowInEditMode,
      expandable,
      innerRef,
      toggleExpanded,
      expanded,
      ...rest
    } = props;

    const singleLine =
      rowSizeMode === 'SINGLE-AUTO' ? !rowInEditMode : rowSizeMode !== 'AUTO';
    const css = renderCellByType({ id: id as Columns, kind });

    if (shouldWrap) {
      const lineClass =
        singleLine && !expanded ? styles['single-line'] : styles['multi-line'];
      return (
        <div
          ref={ref}
          style={{ ...style, ...css, position: 'relative' }}
          className={clsx(styles['table-cell'], className)}
          {...rest}
        >
          <div
            ref={innerRef}
            className={clsx('inside-row', lineClass)}
            onDoubleClick={
              expandable && rowSizeMode === 'SINGLE'
                ? (e) => {
                    toggleExpanded();
                    props.onClick(e.currentTarget);
                  }
                : undefined
            }
          >
            {expandable && rowSizeMode === 'SINGLE' ? (
              <>
                {React.Children.map(rest.children, (child) => {
                  if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                      //@ts-expect-error wrong interface
                      shrinked: !expanded,
                      css,
                    });
                  }
                  return child;
                })}
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    toggleExpanded();
                    props.onClick(e.currentTarget.parentElement);
                  }}
                  className={styles.expandable}
                >
                  {expanded ? <CheckIcon /> : <EditIcon />}
                </IconButton>
              </>
            ) : (
              <>
                {React.Children.map(rest.children, (child) => {
                  if (React.isValidElement(child)) {
                    //@ts-expect-error wrong interface
                    return React.cloneElement(child, { css });
                  }
                  return child;
                })}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <div
        ref={ref}
        style={{ ...style, ...css }}
        className={clsx(styles['table-cell'], className)}
        {...rest}
        children={
          <>
            {React.Children.map(rest.children, (child) => {
              if (React.isValidElement(child)) {
                //@ts-expect-error wrong interface
                return React.cloneElement(child, { css });
              }
              return child;
            })}
          </>
        }
      />
    );
  }
);

type Columns =
  | 'selection'
  | 'number'
  | 'longtext'
  | 'amount'
  | 'unit'
  | 'singlePriceNet'
  | 'discount'
  | 'insteadOfTotalPrice'
  | 'totalPriceNet';

interface RenderCellByTypeProps {
  id?: Columns;
  kind?: EditTablePositionKind;
}

interface CellDefinition {
  selection: CSSProperties;
  number: CSSProperties;
  longtext: CSSProperties;
  amount: CSSProperties;
  unit: CSSProperties;
  singlePriceNet: CSSProperties;
  discount: CSSProperties;
  insteadOfTotalPrice: CSSProperties;
  totalPriceNet: CSSProperties;
  positionNumber: CSSProperties;
  name: CSSProperties;
}

export function renderCellByType(
  props: RenderCellByTypeProps
): CSSProperties | undefined {
  const { kind, id } = props;

  if (!kind) return undefined;

  const standard = {};
  const title = {
    selection: { color: 'var(--03)' },
    number: { fontWeight: '700' },
    longtext: { fontWeight: '700' },
    amount: { color: 'var(--03)' },
    unit: { color: 'var(--03)' },
    singlePriceNet: { color: 'var(--03)' },
    discount: { color: 'var(--03)' },
    insteadOfTotalPrice: { color: 'var(--03)' },
    totalPriceNet: {
      fontWeight: '700',
    },
  };

  const checkListTitle = {
    positionNumber: { fontWeight: '700' },
    name: { fontWeight: '700' },
  };

  const sum = {
    ...title,
    number: { color: 'var(--03)' },
    longtext: { fontWeight: '700', color: 'var(--text03)' },
    totalPriceNet: {
      fontWeight: '700',
      color: 'var(--text03)',
    },
  };

  const titleDef = {
    ...title,
    totalPriceNet: { color: 'var(--03)' },
  };
  const definitions: Record<
    ErpPositionsKind & ChecklistPositionKind,
    Partial<CellDefinition>
  > = {
    AUTO_EK_PROZENT: standard,
    BELEGRABATT: standard,
    BELEGZUSCHLAG: standard,
    BEZUGSKOSTEN: standard,
    EXCEL_ARTIKEL: standard,
    INTERNE_POSITION: standard,
    INTERNE_STUECKLISTE: standard,
    RABATTPOSITION: standard,
    SEITENUMBRUCH: standard,
    STANDARD: standard,
    STUECKLISTE: standard,
    STUECKLISTE_OHNE_BERECHNUNG: standard,
    TEILRECHNUNGSPOSITION: standard,
    TEXTZEILE: {
      ...title,
      longtext: undefined,
      totalPriceNet: { color: 'var(--03)' },
    },
    TITEL: titleDef,
    TITELSUMME: sum,
    TITEL_INTERN: standard,
    TITEL_OHNE_NUMMER: standard,
    UMWANDLUNGSHISTORIE: standard,
    ZWISCHENSUMME: sum,
    [ChecklistPositionKind.DEFAULT]: standard,
    [ChecklistPositionKind.EMPTY_ROW]: standard,
    [ChecklistPositionKind.HEADLINE]: checkListTitle,
  };

  const def = definitions[kind];
  const cellDef = def[id];
  return cellDef;
}
