import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Country<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: kennzeichen */
  shortName?: string;
  /** Alias: kennzeichen2 */
  shortName2?: string;
  /** Alias: kennzeichen3 */
  shortName3?: string;
  /** Alias: steuer */
  tax?: number;
  /** Alias: langtext */
  longtext?: string;
  /** Alias: steuerschluessel1 */
  taxKey1?: number;
  /** Alias: steuerschluessel2 */
  taxKey2?: number;
  /** Alias: steuerschluessel3 */
  taxKey3?: number;
  /** Alias: angelsaechsisch */
  anglosaxon?: boolean;
  /** Alias: telefonvorwahl */
  areacode?: string;
  /** Alias: reisekostenUeber24H */
  travelExpenseAbove24h?: number;
  /** Alias: reisekostenUeber14H */
  travelExpenseAbove14h?: number;
  /** Alias: reisekostenUeber8H */
  travelExpenseAbove8h?: number;
  /** Alias: reisekostenUnter24H */
  travelExpenseBelow24h?: number;
  /** Alias: uebernachtungsPauschale */
  overnightAccommodationAllowance?: number;
  /** Alias: fruehstueck */
  breakfast?: number;
  /** Alias: abzugEssen */
  reductionFood?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Country> = {
  id: {
    alias: 'code',
  },
  shortName: {
    alias: 'kennzeichen',
  },
  shortName2: {
    alias: 'kennzeichen2',
  },
  shortName3: {
    alias: 'kennzeichen3',
  },
  tax: {
    alias: 'steuer',
  },
  longtext: {
    alias: 'langtext',
  },
  taxKey1: {
    alias: 'steuerschluessel1',
  },
  taxKey2: {
    alias: 'steuerschluessel2',
  },
  taxKey3: {
    alias: 'steuerschluessel3',
  },
  anglosaxon: {
    alias: 'angelsaechsisch',
  },
  areacode: {
    alias: 'telefonvorwahl',
  },
  travelExpenseAbove24h: {
    alias: 'reisekostenUeber24H',
  },
  travelExpenseAbove14h: {
    alias: 'reisekostenUeber14H',
  },
  travelExpenseAbove8h: {
    alias: 'reisekostenUeber8H',
  },
  travelExpenseBelow24h: {
    alias: 'reisekostenUnter24H',
  },
  overnightAccommodationAllowance: {
    alias: 'uebernachtungsPauschale',
  },
  breakfast: {
    alias: 'fruehstueck',
  },
  reductionFood: {
    alias: 'abzugEssen',
  },
  __typename: {
    alias: '__typename',
  },
};

export const countryEntityDefinition: EntitiyDefinition<Country> = {
  local: {},
  remote: {
    queryName: 'getCountries',
    fragmentName: 'Land',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
