import { createContext, useContext } from 'react';

import { ApprovalStatuses } from './types';

interface ApprovalContactValue {
  onClick: (id: string, state: ApprovalStatuses) => void;
}

const Context = createContext<ApprovalContactValue>(null);

export const ApprovalContextProvider = Context.Provider;

export function useApprovalContext(): ApprovalContactValue {
  const context = useContext(Context);

  if (context === null) {
    throw new Error(
      'useApprovalContext must be used within a <ApprovalContextProvider>'
    );
  }

  return context;
}
