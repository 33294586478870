import styles from './ComponentsMask.module.scss';

import { ArrowBack, Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import { useState } from 'react';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';

import { useEditTable } from '../../../../erp/components/tab-panels/positions/components/edit-table/components/edit-table-provider/EditTableProvider';
import {
  EditTableEntry,
  EditTableMask,
} from '../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { BOMComponentPosition } from '../types';

export interface ComponentsMaskProps
  extends EditTableMask<BOMComponent & EditTableEntry> {}

export const ComponentsMask = (props: ComponentsMaskProps) => {
  const { item, onCloseClick, editableCols } = props;
  const { onRemovePosition, onEditPosition } = useEditTable();

  const [editItem, setItem] =
    useState<Partial<Record<keyof BOMComponent, unknown>>>(item);
  return (
    <div className={styles.outerWrap}>
      <div className={styles.header}>
        <IconButton onClick={onCloseClick}>
          <ArrowBack />
        </IconButton>
        <div className={styles.titleContainer}>{t('COMMON.POSITION')}</div>
        <IconButton
          color="primary"
          onClick={(e) => {
            onRemovePosition([item?.id]);
            onCloseClick(e);
          }}
        >
          <Delete />
        </IconButton>
      </div>
      <div className={styles.scrollContainer}>
        <LabeledInput
          value={item?.name}
          label={t('INPUTS.DESCRIPTION')}
          disabled={!editableCols.includes('name')}
        />
        <LabeledInput
          value={item?.article?.articleKind}
          label={t('COMMON.KIND')}
          disabled={!editableCols.includes('article.articleKind')}
        />

        <LabeledInput
          value={item?.article?.number}
          label={t('COMMON.NUMBER')}
          disabled={!editableCols.includes('article.number')}
        />

        <LabeledInput
          autoFocus
          value={`${editItem?.amount}`}
          label={t('POS_LIST.AMOUNT')}
          disabled={!editableCols.includes('amount')}
          onChange={(e) => {
            setItem({ ...editItem, amount: e.target.value });
          }}
          onBlur={(e) => {
            onEditPosition({
              position: {
                id: item.id,
                amount: parseInt(e.target.value),
              },
            });
          }}
        />

        <LabeledInput
          value={item?.article?.unit?.name}
          label={t('COMMON.ERP.UNIT')}
          disabled={!editableCols.includes('article.unit.name')}
        />

        <LabeledInput
          value={(item as BOMComponentPosition)?.prurchasePrice}
          label={t('COMMON.ERP.PURCHASE_PRICE')}
          disabled={!editableCols.includes('prurchasePrice')}
        />

        <LabeledInput
          value={item?.article?.entryPrice}
          label={t('FIELDS.entryPrice')}
          disabled={!editableCols.includes('article.entryPrice')}
        />

        <LabeledInput
          value={item?.article?.netPrice}
          label={t('FILTER.groups.price.purchase')}
          disabled={!editableCols.includes('article.netPrice')}
        />

        <LabeledInput
          value={item?.article?.netPrice2}
          label={t('FILTER.groups.price.buy')}
          disabled={!editableCols.includes('article.netPrice2')}
        />

        <LabeledInput
          value={item?.article?.netPrice3}
          label={t('FILTER.groups.price.cloud')}
          disabled={!editableCols.includes('article.netPrice3')}
        />

        <LabeledInput
          value={item?.note}
          label={t('COMMON.NOTE')}
          disabled={!editableCols.includes('note')}
        />

        <CheckboxRadioItem
          label={t('COMMON.ERP.ONLY_SHOPPING')}
          checked={item?.onlyShopping}
          disabled={!editableCols.includes('onlyShopping')}
        />

        <LabeledInput
          value={item?.width}
          label={t('COMMON.ERP.WIDTH')}
          disabled={!editableCols.includes('width')}
        />

        <LabeledInput
          value={item?.length}
          label={t('COMMON.ERP.LENGTH')}
          disabled={!editableCols.includes('length')}
        />
      </div>
    </div>
  );
};
