import { ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import { MutableRefObject, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

interface CreateUserActionProps {
  open: boolean;
  onCreate: (action: UserClass) => void;
  onClose: () => void;
  rootRef?: MutableRefObject<HTMLButtonElement>;
}

export const CreateUserAction = (props: CreateUserActionProps) => {
  const { open, onCreate, onClose, rootRef } = props;
  const { t } = useTranslation();

  const groups = useMemo(() => {
    return [UserClass.BENUTZER, UserClass.RESSOURCE].map((x) => ({
      id: x,
      name: t(`ENUMS.${x.toUpperCase()}`),
      disabled: UserClass.RESSOURCE === x,
    }));
  }, [t]);

  const onClick = (action: UserClass) => {
    onClose();
    onCreate(action);
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={rootRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {groups.map((action) => (
        <ListItem key={action.id} disablePadding>
          <ListItemButton
            role={undefined}
            onClick={() => onClick(action.id)}
            disabled={action.disabled}
          >
            <ListItemText primary={action.name} />
          </ListItemButton>
        </ListItem>
      ))}
    </Popover>
  );
};
