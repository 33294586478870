import styles from '../row-cell-styles.module.scss';

import clsx from 'clsx';
import React, { MouseEventHandler } from 'react';

import { Link } from '@work4all/data';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { useWidgetDrag } from '../../../../hooks/use-widget-drag-drop';
import { ICardWidgetRowModifiersConfig } from '../../../../types';

export interface IItemRowProps<T> {
  selected?: boolean;
  modifiers?: ICardWidgetRowModifiersConfig;
  /**
   * If provided the root element will be a `<Link>`.
   * Otherwise it will be a `<div>`.
   */
  href?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onDoubleClick?: MouseEventHandler<HTMLElement>;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  item: T;
}

export function Row<T>(props: IItemRowProps<T>) {
  const {
    children,
    onClick,
    onDoubleClick,
    selected,
    style,
    item,
    modifiers = {},
  } = props;

  const { activeTenant } = useTenant();

  const { drag } = useWidgetDrag(item);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //default link behaviour
    event.preventDefault();
    if (event.ctrlKey || event.metaKey) {
      window.open(`t-${activeTenant}${props.href}`);
      return;
    }

    onClick(event);
  };

  const handleDoubleClick = (event: React.MouseEvent<HTMLElement>) => {
    onDoubleClick(event);
  };

  const activeStyleMap = {
    [styles.done]: modifiers.done,
    [styles.inactive]: modifiers.inactive,
    [styles.selected]: selected,
  };

  const className = clsx(styles.row, activeStyleMap);

  if (props.href !== undefined) {
    return (
      <div ref={drag}>
        <Link
          onClick={onClick ? handleClick : undefined}
          onDoubleClick={onDoubleClick ? handleDoubleClick : undefined}
          to={props.href}
          className={className}
          style={{
            display: 'grid',
            ...style,
          }}
          role="row"
        >
          {children}
        </Link>
      </div>
    );
  }

  return (
    <div
      ref={drag}
      style={{
        display: 'grid',
        ...style,
      }}
      className={className}
      role="row"
    >
      {children}
    </div>
  );
}
