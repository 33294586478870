import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

type Props = {
  field: string;
  entity: Entities;
  value: string;
};

export const useEntitySuggestions = ({ field, entity, value }: Props) => {
  const requestData = useMemo<DataRequest>(() => {
    return {
      filter: [
        { [field]: { $eq: value + '%' } },
        { [field]: { $ne: '' } },
        { [field]: { $ne: ' ' } },
      ],
      entity: entity,
      data: [field],
      sort: [{ field, direction: SortDirection.ASCENDING }],
    };
  }, [entity, field, value]);

  const response = useDataProvider<string>(
    requestData,
    !value || value?.trim().length === 0
  );

  const suggestions = useMemo(() => {
    let result: Set<string> = new Set(response.data.map((x) => x[field]));

    if (value) {
      result = new Set([value, ...result]);
    }

    return [...result];
  }, [field, response.data, value]);

  return suggestions;
};
