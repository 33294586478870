import { DateTime } from 'luxon';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import {
  BirthdayFormatterReturn,
  BirthdayType,
  QueredBirthday,
} from '../types';

export const birthdayFormatter = (
  birthday: QueredBirthday
): BirthdayFormatterReturn => {
  const { isToday, formattedDate: date } = birthdayDateInfo(
    birthday.geburtsdatum
  );

  const id = birthday.code;
  const email = birthday.eMail;
  const phone = birthday.telefon;

  let type: BirthdayType;
  let name: string;
  let businessPartnerId: number;
  let businessPartnerType: SdObjType;

  if (birthday.__typename === 'Benutzer') {
    type = Entities.user;
    name = birthday.anzeigename;
  } else if (birthday.__typename === 'Ansprechpartner') {
    const businessName = birthday.geschaeftspartner?.data.name;

    type = Entities.contact;
    name = businessName
      ? `${birthday.anzeigename} | ${businessName}`
      : birthday.anzeigename;
    businessPartnerId = birthday.geschaeftspartner?.code;
    businessPartnerType = birthday.geschaeftspartner?.sdObjType;
  } else if (birthday.__typename === 'Kunde') {
    type = Entities.customer;
    name = birthday.name;
    businessPartnerId = id;
    businessPartnerType = SdObjType.KUNDE;
  } else if (birthday.__typename === 'Lieferant') {
    type = Entities.supplier;
    name = birthday.name;
    businessPartnerId = id;
    businessPartnerType = SdObjType.LIEFERANT;
  }

  return {
    isToday,
    date,
    type,
    data: {
      id,
      name,
      email,
      phone,
      businessPartnerId,
      businessPartnerType,
      __typename: birthday.__typename,
    },
  };
};

const birthdayDateInfo = (date: string) => {
  const birthdayDateTime = DateTime.fromISO(date)
    .set({
      year: DateTime.now().year,
    })
    .startOf('day');

  const currentDateTime = DateTime.now().startOf('day');

  const isToday = birthdayDateTime.toMillis() === currentDateTime.toMillis();
  const formattedDate = birthdayDateTime.toISO();

  return { isToday, formattedDate };
};
