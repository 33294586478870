import { PropsWithChildren } from 'react';

export const OrganisationAreaHeader = ({ children }: PropsWithChildren) => {
  return (
    <div
      style={{
        display: 'flex',
        padding: '0.25rem 1rem',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 'calc(3.25rem - 2px)',
      }}
    >
      {children}
    </div>
  );
};
