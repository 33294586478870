import React from 'react';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { FileType } from '@work4all/models/lib/File';

export enum FilePageViewType {
  basedata = 'basedata',
}

export type IMainContactInfo = {
  isPrivateCustomer: boolean;
  addressText: string;
} & Pick<
  Customer | Supplier,
  | 'phoneNumber'
  | 'phoneNumber2'
  | 'eMail'
  | 'eMail2'
  | 'mainContactId'
  | 'website'
  | 'group'
  | 'firstContactDate'
  | 'firstContactPerson'
  | 'paymentKind'
>;

export type IFileContactMetadata = {
  mainContact: IMainContactInfo;
  contacts: Contact[];
  categories: CategoryAssignment[];
  selectedContact: number | null;
  note: string;
  onSelectedContactChange: (contact: number | null) => void;
};

export const FileContext = React.createContext<{
  id: string;
  type: FileType;
  title: string;
  pending: boolean;
  contactMetadata: IFileContactMetadata | null;
  onActiveViewChange: (activeView: FilePageViewType | null) => void;
  activeView: FilePageViewType;
  data?: Project | Customer | Supplier;
  childProjectData?: Project[];
} | null>(null);
