import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ReportBzObjType } from '../Enums/ReportBzObjType.enum';

import { ReportOption } from './ReportOption.entity';

export class Report<T extends EMode = EMode.entity> {
  /** Alias: abfrageFeld */
  field?: string;
  /** Alias: anzahl */
  amount?: number;
  /** Alias: bzObjType */
  bzObjType?: ReportBzObjType;
  /** Alias: code */
  id?: number;
  /** Alias: crwQuery */
  crwQuery?: string;
  /** Alias: hideInApp */
  hideInApp?: boolean;
  /** Alias: mail */
  mail?: boolean;
  /** Alias: mailImmerAn */
  mailAlwaysTo?: string;
  /** Alias: mitSelektion */
  withSelection?: boolean;
  /** Alias: moduloKontonummer */
  moduloAccountNumber?: string;
  /** Alias: moduloTeilnehmernummer */
  moduloAttendeeNumber?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: port */
  port?: string;
  /** Alias: port1 */
  port1?: string;
  /** Alias: port2 */
  port2?: string;
  /** Alias: reportFileName */
  reportFileName?: string;
  /** Alias: reportFileName2 */
  reportFileName2?: string;
  /** Alias: reportFileName3 */
  reportFileName3?: string;
  /** Alias: reportId */
  reportId?: string;
  /** Alias: schachtFolgende */
  schachtFolgende?: number;
  /** Alias: schachtFolgende1 */
  schachtFolgende1?: number;
  /** Alias: schachtFolgende2 */
  schachtFolgende2?: number;
  /** Alias: schachtSeite1 */
  schachtSeite1?: number;
  /** Alias: schachtSeite1_1 */
  schachtSeite1_1?: number;
  /** Alias: schachtSeite1_2 */
  schachtSeite1_2?: number;
  /** Alias: slKomponenten */
  slKomponenten?: boolean;
  /** Alias: sortierung */
  sortierung?: string;
  /** Alias: standardReport */
  standardReport?: boolean;
  /** Alias: standardReportId */
  standardReportId?: string;
  /** Alias: standardReportIsDeleted */
  standardReportIsDeleted?: boolean;
  /** Alias: standardReportModificationDate */
  standardReportModificationDate?: string;
  /** Alias: zeigenInLagerscanner */
  zeigenInLagerscanner?: boolean;
  /** Alias: zusatzInfo1 */
  zusatzInfo1?: string;
  /** Alias: zusatzInfo2 */
  zusatzInfo2?: string;
  /** Alias: zusatzInfo3 */
  zusatzInfo3?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: reportOptions */
  reportOptions?: ReportOption<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<Report> = {
  field: {
    alias: 'abfrageFeld',
  },
  amount: {
    alias: 'anzahl',
  },
  bzObjType: {
    alias: 'bzObjType',
  },
  id: {
    alias: 'code',
  },
  crwQuery: {
    alias: 'crwQuery',
  },
  hideInApp: {
    alias: 'hideInApp',
  },
  mail: {
    alias: 'mail',
  },
  mailAlwaysTo: {
    alias: 'mailImmerAn',
  },
  withSelection: {
    alias: 'mitSelektion',
  },
  moduloAccountNumber: {
    alias: 'moduloKontonummer',
  },
  moduloAttendeeNumber: {
    alias: 'moduloTeilnehmernummer',
  },
  note: {
    alias: 'notiz',
  },
  port: {
    alias: 'port',
  },
  port1: {
    alias: 'port1',
  },
  port2: {
    alias: 'port2',
  },
  reportFileName: {
    alias: 'reportFileName',
  },
  reportFileName2: {
    alias: 'reportFileName2',
  },
  reportFileName3: {
    alias: 'reportFileName3',
  },
  reportId: {
    alias: 'reportId',
  },
  schachtFolgende: {
    alias: 'schachtFolgende',
  },
  schachtFolgende1: {
    alias: 'schachtFolgende1',
  },
  schachtFolgende2: {
    alias: 'schachtFolgende2',
  },
  schachtSeite1: {
    alias: 'schachtSeite1',
  },
  schachtSeite1_1: {
    alias: 'schachtSeite1_1',
  },
  schachtSeite1_2: {
    alias: 'schachtSeite1_2',
  },
  slKomponenten: {
    alias: 'slKomponenten',
  },
  sortierung: {
    alias: 'sortierung',
  },
  standardReport: {
    alias: 'standardReport',
  },
  standardReportId: {
    alias: 'standardReportId',
  },
  standardReportIsDeleted: {
    alias: 'standardReportIsDeleted',
  },
  standardReportModificationDate: {
    alias: 'standardReportModificationDate',
  },
  zeigenInLagerscanner: {
    alias: 'zeigenInLagerscanner',
  },
  zusatzInfo1: {
    alias: 'zusatzInfo1',
  },
  zusatzInfo2: {
    alias: 'zusatzInfo2',
  },
  zusatzInfo3: {
    alias: 'zusatzInfo3',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  reportOptions: {
    alias: 'reportOptions',
    entity: Entities.reportOption,
  },
  __typename: {
    alias: '__typename',
  },
};

export const reportEntityDefinition: EntitiyDefinition<Report> = {
  local: {},
  remote: {
    queryName: 'getReports',
    fragmentName: 'Report',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
