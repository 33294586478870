import { Delete } from '@mui/icons-material';
import { IconButtonProps } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import { useEntityRightsContext } from '../hooks/use-entity-rights';

interface MaskOverlayDeleteButtonProps extends IconButtonProps {}

export const MaskOverlayDeleteButton = (
  props: MaskOverlayDeleteButtonProps
) => {
  const { t } = useTranslation();
  const rights = useEntityRightsContext();

  return (
    <Tooltip
      title={!rights.delete ? t('RIGHTS.MISSING') : undefined}
      activateForDisabled={true}
    >
      <IconButton size="large" {...props}>
        <Delete />
      </IconButton>
    </Tooltip>
  );
};
