import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { Article } from './Article.entity';
import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { CostCenter } from './CostCenter.entity';
import { CustomField } from './CustomField.entity';
import { ProductionContractGroup } from './ProductionContractGroup.entity';
import { Project } from './Project.entity';
import { User } from './User.entity';

export class ProductionContract<T extends EMode = EMode.entity> {
  /** Alias: abgeschlossenDurchBenutzerCode */
  closedByUserId?: number;
  /** Alias: angelegtdurchBenutzerCode */
  appliedByUserId?: number;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: bearbeiterBenutzerCode */
  editorUserId?: number;
  /** Alias: bzObjMemberCode */
  bzObjMemberId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: erstelltdatum */
  creationDate?: string;
  /** Alias: fertigstellungsdatum */
  finishedDate?: string;
  /** Alias: freigabe */
  release?: string;
  /** Alias: freigabePruefauftragCode */
  releaseAuditContractId?: number;
  /** Alias: grCode */
  groupId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: istMenge */
  actualAmount?: number;
  /** Alias: kostenstellenCode */
  costCenterId?: number;
  /** Alias: letzteAenderung */
  lastEdit?: string;
  /** Alias: lookupCode */
  lookupId?: number;
  /** Alias: name */
  name?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: positionenCode */
  positionId?: number;
  /** Alias: prioritaetLookupCode */
  priorityLookupId?: number;
  /** Alias: produktionsdatum */
  productionDate?: string;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: sollMenge */
  targetAmount?: number;
  /** Alias: status */
  status?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: artikel */
  article?: Article<T>;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: bearbeiter */
  editor?: User<T>;
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: kostenstelle */
  costCenter?: CostCenter<T>;
  /** Alias: gruppe */
  productionContractGroup?: ProductionContractGroup<T>;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<ProductionContract> = {
  closedByUserId: {
    alias: 'abgeschlossenDurchBenutzerCode',
  },
  appliedByUserId: {
    alias: 'angelegtdurchBenutzerCode',
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  articleId: {
    alias: 'artikelCode',
  },
  editorUserId: {
    alias: 'bearbeiterBenutzerCode',
  },
  bzObjMemberId: {
    alias: 'bzObjMemberCode',
  },
  id: {
    alias: 'code',
  },
  creationDate: {
    alias: 'erstelltdatum',
  },
  finishedDate: {
    alias: 'fertigstellungsdatum',
  },
  release: {
    alias: 'freigabe',
  },
  releaseAuditContractId: {
    alias: 'freigabePruefauftragCode',
  },
  groupId: {
    alias: 'grCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  actualAmount: {
    alias: 'istMenge',
  },
  costCenterId: {
    alias: 'kostenstellenCode',
  },
  lastEdit: {
    alias: 'letzteAenderung',
  },
  lookupId: {
    alias: 'lookupCode',
  },
  name: {
    alias: 'name',
  },
  note: {
    alias: 'notiz',
  },
  number: {
    alias: 'nummer',
  },
  positionId: {
    alias: 'positionenCode',
  },
  priorityLookupId: {
    alias: 'prioritaetLookupCode',
  },
  productionDate: {
    alias: 'produktionsdatum',
  },
  projectId: {
    alias: 'projektCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  targetAmount: {
    alias: 'sollMenge',
  },
  status: {
    alias: 'status',
  },
  updateTime: {
    alias: 'updateTime',
  },
  article: {
    alias: 'artikel',
    entity: Entities.article,
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  editor: {
    alias: 'bearbeiter',
    entity: Entities.user,
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  costCenter: {
    alias: 'kostenstelle',
    entity: Entities.costCenter,
  },
  productionContractGroup: {
    alias: 'gruppe',
    entity: Entities.productionContractGroup,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  __typename: {
    alias: '__typename',
  },
};

export const productionContractEntityDefinition: EntitiyDefinition<ProductionContract> =
  {
    local: {},
    remote: {
      queryName: 'getProduktionsauftrag',
      fragmentName: 'Produktionsauftrag',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
