import styles from './PositionsTable.module.scss';

import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import InsertPageBreakIcon from '@mui/icons-material/InsertPageBreak';
import { Box, IconButton, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { useUser } from '@work4all/data';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';
import { ErpPositionSlMode } from '@work4all/models/lib/Enums/ErpPositionSlMode.enum';

import { formatCurrency, formatNumberAsCurrency } from '@work4all/utils';
import { canViewERPPrices } from '@work4all/utils/lib/permissions';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';
import { ShadowObjectAddPositionArgs } from '../../../../../hooks/use-bz-shadow-object-api';
import { useShadowBzObjectApiContext } from '../../../../../hooks/use-bz-shadow-object-api/use-shadow-bz-object-api-context';
import { EditTable } from '../edit-table/EditTable';
import { STUECKLISTE_GROUP } from '../edit-table/hooks/use-editable-state';
import {
  EditTableColumns,
  EditTableEntry,
  EditTableProps,
  IdArray,
  IEditTable,
  OnEditPosition,
} from '../edit-table/types';
import { useSelectedPositionState } from '../hooks/use-selected-position';
import { PositionMask } from '../position-mask/PositionMask';
import { handleOrderChange } from '../utils/handle-order-change';

import { defaultErpGetRelation } from './defaultErpGetRelation';

export const ForbiddenPriceCell: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('ALERTS.NOT_AUTHORIZED_TO_SEE_PRICES')}>
      <Box textAlign="center">-</Box>
    </Tooltip>
  );
};

export type ForwardedEditTableProps = Pick<
  EditTableProps<Position>,
  'showSelectionColumn' | 'refs' | 'showBorders' | 'allowedColumns'
>;

export type IPositionsTableProps = {
  disabled?: boolean;
  showPositionMask?: boolean;
  positions: Position[];
  onMovePosition: (id: string, index: number) => void;
  onPickPosition?: (article?: Article) => void;
  onRemovePosition: (positionId: IdArray) => void;
  onAddPosition?: (props: ShadowObjectAddPositionArgs) => void;
  onEditPosition: (result: OnEditPosition<Position>) => void;
  onCollapsePosition: (result: Position) => void;
  tableInstanceRef: React.RefObject<TableInstance>;
  columns: EditTableColumns<Position>[];
  refs?: {
    editTable: React.MutableRefObject<IEditTable>;
  };
  total: number;
  singleLine?: boolean;
} & ForwardedEditTableProps;

const editableRows = {
  TITEL: ['longtext'],
  TITELSUMME: ['longtext'],
  ZWISCHENSUMME: ['longtext'],
  TEXTZEILE: ['longtext'],
  UMWANDLUNGSHISTORIE: ['longtext'],
};

export const PositionsTable = (props: IPositionsTableProps) => {
  const {
    disabled = false,
    positions,
    onAddPosition,
    onEditPosition,
    onCollapsePosition,
    showPositionMask = true,
    showBorders = true,
    columns,
    allowedColumns,
    showSelectionColumn,
    singleLine = true,
    total,
  } = props;

  const { t } = useTranslation();
  const { pending, loading } = useShadowBzObjectApiContext();

  const mask = useMaskContext();

  const user = useUser();
  const showPrices = canViewERPPrices(user);

  const { watch } = useFormContextPlus<ErpData>();
  const currency = watch('currency');
  const { setSelectedPositionIds } = useSelectedPositionState();

  const renderMobileFooter = useCallback(() => {
    return (
      <tr className={clsx(styles.title, styles.mobilePositionRow)}>
        <td></td>
        <td></td>
        <td className={clsx(styles['tr-border'], styles.mobilePositionRow)}>
          <span style={{ float: 'right' }}>
            {formatNumberAsCurrency(total)} {formatCurrency(currency, t)}
          </span>

          <div style={{ whiteSpace: 'pre-line' }}>{t('COMMON.TOTAL')}</div>
        </td>
      </tr>
    );
  }, [currency, total, t]);

  const renderMobilePosition = useCallback(
    (
      position: Position,
      setEditedPosition: (position: Position) => void,
      allPositions: Position[]
    ) => {
      const key = position.id;

      const bomComponentList = position.posId
        ? allPositions.filter((x) => x.posId === position.posId)
        : null;
      const isLastSubPosition =
        bomComponentList &&
        bomComponentList.findIndex((x) => x.id === position.id) ===
          bomComponentList.length - 1;

      switch (position.positionKind) {
        case ErpPositionsKind.SEITENUMBRUCH:
          return (
            <tr key={key}>
              <td
                colSpan={3}
                className={clsx(styles['tr-border'], styles.mobilePositionRow)}
              >
                <div
                  className={styles['page-break']}
                  onClick={() => {
                    setEditedPosition(position);
                    setSelectedPositionIds([position.id]);
                  }}
                  style={{ width: 'fit-content' }}
                >
                  <InsertPageBreakIcon />
                  <div>{t('COMMON.PAGE_BREAK')}</div>
                </div>
              </td>
            </tr>
          );
        case ErpPositionsKind.TITEL:
        case ErpPositionsKind.TITELSUMME:
        case ErpPositionsKind.ZWISCHENSUMME:
          return (
            <tr
              key={key}
              onClick={() => {
                setEditedPosition(position);
                setSelectedPositionIds([position.id]);
              }}
              className={clsx(styles.title, styles.mobilePositionRow, {
                [styles.text01]:
                  position.positionKind === ErpPositionsKind.TITEL,
              })}
            >
              <td></td>
              <td>{position.number}</td>
              <td style={{ width: '100%' }}>
                {position.positionKind !== ErpPositionsKind.TITEL &&
                  showPrices && (
                    <span style={{ float: 'right' }}>
                      {formatNumberAsCurrency(position.totalPriceNet)}{' '}
                      {formatCurrency(currency, t)}
                    </span>
                  )}
                <div style={{ whiteSpace: 'pre-line' }}>
                  {position.longtext}
                </div>
              </td>
            </tr>
          );
        default:
          return (
            <tr key={key} className={styles.mobilePositionRow}>
              <td>
                {STUECKLISTE_GROUP.includes(position.positionKind) && (
                  <IconButton
                    size="small"
                    onClick={() => onCollapsePosition(position)}
                    style={{
                      marginTop: '-0.25rem',
                    }}
                  >
                    {allPositions.find((x) => x.posId === position.id) ? (
                      <KeyboardArrowDown />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </IconButton>
                )}
              </td>
              <td
                className={styles.positionNumberCell}
                onClick={() => {
                  setEditedPosition(position);
                  setSelectedPositionIds([position.id]);
                }}
              >
                {position.number}
                {position.posId ? (
                  <>
                    <div
                      className={clsx(styles.subPositionLineVert, {
                        [styles.last]: isLastSubPosition,
                      })}
                    ></div>
                    <div className={styles.subPositionLineHor}></div>
                  </>
                ) : null}
              </td>
              <td
                style={{ width: '100%' }}
                onClick={() => {
                  setEditedPosition(position);
                  setSelectedPositionIds([position.id]);
                }}
              >
                <div style={{ whiteSpace: 'pre-line' }}>
                  {position.longtext}
                </div>

                <div className={styles.subline}>
                  <div className={styles.left}>
                    <span>
                      {position.amount} {position.unit}
                    </span>
                    {showPrices && (
                      <span
                        className={clsx({
                          [styles.strikethrough]: position.insteadOfTotalPrice,
                        })}
                      >
                        {formatNumberAsCurrency(position.singlePriceNet)}{' '}
                        {formatCurrency(currency, t)}
                      </span>
                    )}
                    {showPrices && position.discount > 0 && (
                      <span>{position.discount} %</span>
                    )}
                  </div>
                  {showPrices && (
                    <div className={styles.right}>
                      {position.insteadOfTotalPrice ? (
                        position.insteadOfTotalPrice
                      ) : (
                        <>
                          {formatNumberAsCurrency(position.totalPriceNet)}{' '}
                          {formatCurrency(currency, t)}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </td>
            </tr>
          );
      }
    },
    [currency, showPrices, onCollapsePosition, setSelectedPositionIds, t]
  );

  const classes = useMemo(
    () => ({
      mobile: styles.mobilePositionsTable,
    }),
    []
  );

  const loadingRows = useMemo(
    () =>
      (pending.type === 'modifyPosition' &&
        ['singlePriceNet', 'amount', 'discount'].some((x) =>
          pending.properties.includes(x)
        )) ||
      pending.type === 'movePosition'
        ? [pending.positionId]
        : [],
    [pending]
  );

  const decorators = useMemo(() => {
    const isEditableCell = (cell: string, original: Position) => {
      if (
        original.positionKind === ErpPositionsKind.STANDARD &&
        cell === 'number'
      )
        return false;

      if (
        (original.positionKind === ErpPositionsKind.INTERNE_POSITION ||
          original.positionKind === ErpPositionsKind.INTERNE_STUECKLISTE) &&
        cell === 'singlePriceNet'
      )
        return false;

      if (
        cell === 'longtext' &&
        STUECKLISTE_GROUP.includes(original.positionKind) &&
        original.partsListType ===
          ErpPositionSlMode.STUECKLISTE_MIT_SUB_POSITIONEN_IM_LANGTEXT
      ) {
        return false;
      }

      const relation = defaultErpGetRelation(original);
      if (cell === 'amount' && relation === 'child') {
        return false;
      }
      if (relation === 'parent') {
        return !['partsListAmount', 'singlePriceNet'].includes(cell);
      }

      return true;
    };

    return { getRelation: defaultErpGetRelation, isEditableCell };
  }, []);

  const onRowOut = useCallback(
    (position: Position, dir: 1 | -1) => {
      const notCachedPositions = positions.filter(
        (x) => !(x as EditTableEntry).cacheOnly
      );
      const isLast =
        notCachedPositions[notCachedPositions.length - 1]?.id === position?.id;
      if (isLast) {
        if (showBorders)
          onAddPosition({
            positionType: ErpPositionsKind.TEXTZEILE,
            article: { id: 0 } as Article,
          });
        setTimeout(() => {
          props.refs.editTable.current.setEditMode('undefined', 'longtext', [
            'longtext',
          ]);
        }, 100);
      } else {
        const positionIndex = notCachedPositions.findIndex(
          (x) => x.id === position?.id
        );
        const nextPosition = notCachedPositions[positionIndex + dir];
        if (nextPosition)
          props.refs.editTable.current.setEditMode(
            nextPosition.id.toString(),
            'longtext'
          );
      }
      // scroll to the left to see longtext popover
      if (props.refs.scroll?.current) props.refs.scroll.current.scrollLeft = 0;
    },
    [
      positions,
      props.refs.scroll,
      props.refs.editTable,
      showBorders,
      onAddPosition,
    ]
  );
  const erpSize = useSetting(settings.erpRowSize());

  const columnSettings = useSetting(settings.erpPositionsConfig(mask.entity));
  const noIdEditableRows = useMemo(
    () => ['longtext', 'amount', 'shortText', 'unit', 'internalText'],
    []
  );

  return !loading ? (
    <Box display="flex" flex="1" flexDirection="column" overflow="hidden">
      <EditTable<Position>
        // Editable state
        onAddPosition={props.onAddPosition}
        onRemovePosition={props.onRemovePosition}
        onEditPosition={onEditPosition}
        onMovePosition={props.onMovePosition}
        onCollapsePosition={onCollapsePosition}
        onDropItem={props.onPickPosition}
        // refs
        ref={props.refs.editTable}
        refs={props.refs}
        tableInstanceRef={props.tableInstanceRef}
        // mask & mobile
        renderMobileItem={renderMobilePosition}
        renderMobileFooter={renderMobileFooter}
        mask={showPositionMask ? PositionMask : undefined}
        // Others
        disabled={disabled}
        columns={columns}
        items={positions}
        editableRows={editableRows}
        onSelectedItemIdsChange={setSelectedPositionIds}
        classes={classes}
        loadingRows={loadingRows}
        itemSize="auto"
        noIdEditableCells={noIdEditableRows}
        decorators={decorators}
        showBorders={showBorders}
        showSelectionColumn={showSelectionColumn}
        allowedColumns={allowedColumns}
        onRowOut={onRowOut}
        columnSettings={columnSettings}
        handleOrderChange={handleOrderChange}
        rowSizeMode={singleLine ? erpSize.value : 'AUTO'}
        entity={Entities.position}
        bordersKey="ERP"
      />
    </Box>
  ) : null;
};
