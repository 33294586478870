import { memo, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FileViewMode,
  useWidgetsDataBag,
} from '@work4all/data/lib/hooks/useWidgetsBag';

import { ILayoutGroupDefinition } from '@work4all/models/lib/Layout';

import { settings, useSetting } from '../../../../../../settings';
import { FileContext } from '../../FileContext';
import { WidgetGroup } from '../WidgetGroup';
import { WidgetGroupContent } from '../WidgetGroupContent';

export const WidgetsController = memo(function WidgetsController(props: {
  viewMode: FileViewMode;
}) {
  const { widgetsDefinitions } = useWidgetsDataBag();
  const { t } = useTranslation();

  const { viewMode } = props;

  function getGroupDisplayName(group: ILayoutGroupDefinition): string {
    if (group.type === 'default' && group.i18nKey) {
      return t(group.i18nKey);
    } else {
      return group.name;
    }
  }

  const widgetGroups = widgetsDefinitions.definition;

  const fileContext = useContext(FileContext);
  const hiddenWidgets = useSetting(
    settings.hiddenWidgets({ fileType: fileContext.type })
  );

  const visibleWidgetGroups = useMemo(() => {
    return widgetGroups.filter((gr) => {
      return gr.widgets.find((w) => !hiddenWidgets.value[w.id]);
    });
  }, [hiddenWidgets.value, widgetGroups]);

  /**
   * For rendering issues the view has to be reset when hiddenWidgets is changed
   */

  if (!widgetsDefinitions) {
    return null;
  }

  switch (visibleWidgetGroups.length) {
    case 0:
      return null;
    case 1:
      return (
        <WidgetGroupContent
          widgets={visibleWidgetGroups[0].widgets}
          viewMode={viewMode}
        />
      );
    default:
      return (
        <>
          {visibleWidgetGroups.map((group) => (
            <WidgetGroup
              key={group.id}
              id={group.id}
              name={getGroupDisplayName(group)}
              defaultCollapsed={group.collapsed}
              widgets={group.widgets}
              viewMode={viewMode}
            />
          ))}
        </>
      );
  }
});
