import styles from './ColumnsMenuItem.module.scss';

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import { Typography } from '@mui/material';

type Props = {
  label: string;
  isChecked: boolean;
  hasSections: boolean;
};

export const ColumnsMenuItem = ({ label, isChecked, hasSections }: Props) => {
  if (hasSections) {
    return (
      <div className={styles.nestedItemWrapper}>
        <Typography className={styles.label}>{label}</Typography>
        <ChevronRight className={styles.chevronRightIcon} />
      </div>
    );
  }

  return (
    <div className={styles.normalItemWrapper}>
      <div className={styles.checkBoxIcon}>
        {isChecked ? (
          <CheckBox color="primary" />
        ) : (
          <CheckBoxOutlineBlank color="disabled" />
        )}
      </div>

      <Typography className={styles.label}>{label}</Typography>
    </div>
  );
};
