import { useMemo, useState } from 'react';

import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityLike } from '../../types';

import {
  EntityPickerListProps,
  PickerBase,
  PickerCommonProps,
} from './PickerBase';
import { filterAndSortResult } from './utils/hooks/filter-and-sort-result';

// TODO Update types
interface EntityPickerAllProps<
  Value extends EntityLike,
  Multiple extends boolean
> extends Omit<
      PickerCommonProps<Value, Multiple>,
      'filterConfig' | 'onFavoritesToggled'
    >,
    Omit<
      EntityPickerListProps<Value>,
      | 'entity'
      | 'regularResult'
      | 'filterResult'
      | 'searchPrefilter'
      | 'label'
      | 'filterBy'
      | 'useSearchHistory'
      | 'favorites'
    > {
  sortBy: string;
  sortByDirection: SortDirection;
  filterBy: string | string[];
  suppressFilter?: boolean;
}

type EntityPickerState = {
  query: string;
};

interface EntityPickerProps<Value extends EntityLike, Multiple extends boolean>
  extends Omit<EntityPickerAllProps<Value, Multiple>, keyof EntityPickerState> {
  options: Value[];
}

/**
 * A picker with fixed list of options.
 *
 * The options can be filtered and sorted by any of the fields.
 *
 * See [Pickers on Wiki](https://dev.azure.com/work4all-tfs/work4all%20mobile/_wiki/wikis/App%20wiki/59/Pickers)
 */
export function Picker<Value, Multiple extends boolean>(
  props: EntityPickerProps<Value, Multiple>
) {
  const {
    onSearchValueChange,
    options,
    filterBy,
    sortBy,
    sortByDirection,
    suppressFilter = false,
    ...otherProps
  } = props;

  const [query, setQuery] = useState('');

  const result = useMemo(() => {
    return filterAndSortResult(
      { data: options },
      { query, filterBy, sortBy, sortByDirection, suppressFilter }
    );
  }, [options, filterBy, query, sortBy, sortByDirection, suppressFilter]);

  return (
    // eslint-disable-next-line
    // @ts-ignore The generics don't resolve correctly here for some reason.
    <PickerBase
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      entity={'custom' as any}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      regularResult={result as any}
      filterResult={null}
      filterBy={filterBy}
      onSearchValueChange={(query) => {
        setQuery(query);
        onSearchValueChange?.(query);
      }}
      {...otherProps}
    />
  );
}
