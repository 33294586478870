import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import { currencyAsSign, formatNumberAsCurrency } from '@work4all/utils';
import { canViewERPPrices } from '@work4all/utils/lib/permissions';

import { createFooterCell } from './create-footer-cell';
import { ForbiddenPriceCell } from './ForbiddenPriceCell';

export type ICurrencyCellProps = {
  children?: React.ReactNode;
  value: string | number;
  column?: {
    id: string;
    cellParams: {
      currencyField?: string;
      courseField?: string;
      hideUnit?: boolean;
      rightRequired?: boolean;
    };
  };
  row?: {
    original: unknown;
  };
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  hideUnit?: boolean;
  className?: string;
  onEdit?: () => void;
  currency?: string;
};

function index(obj, i) {
  return obj?.[i];
}

export const CurrencyCell: React.FC<ICurrencyCellProps> = (props) => {
  const {
    value,
    column,
    row,
    minimumFractionDigits = 2,
    maximumFractionDigits = 2,
    hideUnit = false,
    className = '',
    currency: initCurrency = '€',
  } = props;
  let currency = initCurrency;

  const cellParams = column?.cellParams;
  const rowData = row?.original;

  const user = useUser();
  const hasViewingRight = canViewERPPrices(user);

  if (rowData && cellParams?.currencyField) {
    const accessorKey = cellParams?.currencyField;
    const key = accessorKey?.split('.');
    const accessor = key.splice(1);
    const valueObj = rowData[key[0]] as unknown[];
    currency = accessor.reduce(index, valueObj);
  }

  let course = 1;
  if (rowData && cellParams?.courseField) {
    const accessorKey = cellParams?.courseField;
    const key = accessorKey?.split('.');
    const accessor = key.splice(1);
    const valueObj = rowData[key[0]] as unknown[];
    course = accessor.reduce(index, valueObj ?? course);
  }

  currency = currencyAsSign(currency);

  const displayValue = useMemo(() => {
    if (!value || (typeof value === 'string' && value === '0')) {
      return '';
    } else {
      return formatNumberAsCurrency(
        (typeof value === 'string' ? parseInt(value, 10) : value) * course,
        {
          minimum: minimumFractionDigits,
          maximum: maximumFractionDigits,
        }
      );
    }
  }, [course, maximumFractionDigits, minimumFractionDigits, value]);

  if (cellParams?.rightRequired && !hasViewingRight) {
    return <ForbiddenPriceCell />;
  }

  return (
    <div
      style={{ textAlign: 'right' }}
      className={className}
      onClick={props.onEdit}
    >
      {displayValue}
      {displayValue !== ''
        ? !hideUnit && !cellParams?.hideUnit && <>&nbsp;{currency}</>
        : null}
    </div>
  );
};

export const CurrencyFooterCell = createFooterCell(CurrencyCell, 0);
