import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputBenutzerSyncOptionsRelation } from './InputBenutzerSyncOptionsRelation.entity';

export class InputBenutzerRelation<T extends EMode = EMode.entity> {
  /** Alias: userImageFromTempFile */
  userImageFromTempFile?: string;
  /** Alias: syncOptions */
  syncOptions?: InputBenutzerSyncOptionsRelation<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputBenutzerRelation> = {
  userImageFromTempFile: {
    alias: 'userImageFromTempFile',
  },
  syncOptions: {
    alias: 'syncOptions',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputBenutzerRelationEntityDefinition: EntitiyDefinition<InputBenutzerRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputBenutzerRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
