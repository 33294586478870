import { useEventCallback } from '@mui/material/utils';

import { useDialogs } from '@work4all/components';

import { TimeTrackingMask } from '../time-tracking-mask';

type OpenMaskFunctionProps =
  | { mode: 'create' }
  | { mode: 'edit'; id: number }
  | { mode: 'clone'; id: number };

export const useOpenTimeTrackingMask = () => {
  const dialogs = useDialogs();

  return useEventCallback((props: OpenMaskFunctionProps) => {
    const dialog = dialogs.open(TimeTrackingMask, {
      ...props,
      close,
    });

    function close() {
      dialogs.close(dialog.id);
    }
  });
};
