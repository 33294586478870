import styles from './GeneralTabPanel.module.scss';

import PersonIcon from '@mui/icons-material/Person';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddPictureIcon } from '@work4all/assets/icons/add_picture.svg';

import { ConfirmDialog } from '@work4all/components';
import { useFullAvatarUrl } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { useSecureBlobUrl } from '@work4all/data';
import { useAuthHeaders } from '@work4all/data/lib/auth';
import { getObjectUrlAuthed } from '@work4all/data/lib/utils';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { AvatarMenu } from '../../../../../../../components/user-menu/views/Profile/components/Avatar/AvatarMenu';
import {
  useAvatar,
  useUploadAvatar,
} from '../../../../../../../hooks/useAvatar';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import { useMaskContext } from '../../../../hooks/mask-context';
import { UserFormValue } from '../../types';

export const UserImage = () => {
  const { id } = useMaskContext();
  const { t } = useTranslation();
  const avatar = useAvatar(typeof id === 'number' ? id : parseInt(id));

  const avatarUrl = useFullAvatarUrl({ url: avatar?.avatarUrl, size: 128 });
  const { blobUrl } = useSecureBlobUrl(avatarUrl);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { removeAvatar, uploadAvatar, tempUrl } = useUserImage();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleConfirmRemove = () => {
    removeAvatar();
    setIsDialogOpen(false);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const finalUrl = tempUrl || tempUrl === null ? tempUrl : blobUrl;

  return (
    <div
      onClick={(event) => {
        if (!finalUrl) return;
        if (!anchorEl) setAnchorEl(event.currentTarget);
      }}
      className={clsx(styles.image, {
        [styles.clickable]: !!finalUrl,
      })}
      style={{ backgroundImage: `url(${finalUrl})` }}
    >
      {!finalUrl && (
        <>
          <Button
            onClick={() => {
              fileInputRef.current.click();
            }}
            variant="text"
            startIcon={<AddPictureIcon />}
            className={styles.imageButton}
          >
            {t('INPUTS.ADD_PICTURE')}
          </Button>
          <PersonIcon className={styles.backdrop} />
        </>
      )}
      <input
        type="file"
        accept="image/*"
        hidden
        ref={fileInputRef}
        onChange={uploadAvatar}
      />

      <AvatarMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        fileInputRef={fileInputRef}
        setIsDialogOpen={setIsDialogOpen}
      />
      <ConfirmDialog
        open={isDialogOpen}
        title={t('PROFILE.DELETE_PROFILE_PICTURE')}
        description={t('PROFILE.CONFIRM_DELETE_PORFILE_PICTURE')}
        onConfirm={handleConfirmRemove}
        onCancel={() => setIsDialogOpen(false)}
      />
    </div>
  );
};

export const useUserImage = () => {
  const httpHeaders = useAuthHeaders();
  const [tempUrl, setTempUrl] = useState<string>();
  const { setValue } = useFormContextPlus<UserFormValue>();

  const uploadAvatar = useUploadAvatar(async (userImageFromTempFile, url) => {
    setValue('userImageFromTempFile', userImageFromTempFile, {
      shouldDirty: true,
    });
    const object = await getObjectUrlAuthed(url, httpHeaders);
    setTempUrl(object);
  });

  const removeAvatar = useCallback(() => {
    setValue('userImageFromTempFile', EMPTY_UID, { shouldDirty: true });
    setTempUrl(null);
  }, [setValue]);

  return {
    uploadAvatar,
    removeAvatar,
    tempUrl,
  };
};
