import { ApprovalEvent } from '@work4all/models/lib/Classes/ApprovalEvent.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { ApprovalNotificationEventType } from '@work4all/models/lib/Enums/ApprovalNotificationEventType.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

interface ResolveApprovalNotificationDataResult {
  refObject?: VacationRequestEntity | InboundInvoice;
  creator?: User | null;
  approver?: User | null;
  newCreated: boolean;
  isApproved: boolean;
  isRejected: boolean;
  isForwarded: boolean;
}

export function resolveApprovalNotificationData(
  object: ApprovalEvent,
  type: ObjectType.VACATION_REQUEST | ObjectType.RECHNUNGSEINGAENGE
): ResolveApprovalNotificationDataResult {
  if (object === null) {
    return null;
  }

  const refObject =
    type === ObjectType.VACATION_REQUEST
      ? (object.refObject as VacationRequestEntity)
      : (object.refObject as InboundInvoice);
  const creator = refObject?.createdByUser;
  const approver =
    type === ObjectType.VACATION_REQUEST
      ? refObject?.releasedByUser
      : (refObject as InboundInvoice)?.approvedByUser;
  const eventType = object.approvalEventType;
  const newCreated =
    eventType === ApprovalNotificationEventType.ITEM_TO_APPROVE_CREATED;
  const isApproved =
    eventType === ApprovalNotificationEventType.APPROVAL_APPROVED;
  const isRejected =
    eventType === ApprovalNotificationEventType.APPROVAL_REJECTED;
  const isForwarded =
    eventType === ApprovalNotificationEventType.APPROVAL_FORWARED;

  return {
    refObject,
    creator,
    approver,
    newCreated,
    isApproved,
    isRejected,
    isForwarded,
  };
}
