import { useCallback } from 'react';

import {
  doesUserHavePermission,
  doSomePermissionRulesApply,
  getEntityPermissionsConfigs,
} from '../utils';

import {
  UsePermissionsFunctionCallback,
  UsePermissionsFunctionProps,
} from './types';

export const usePermissionsFunction = <T>({
  user,
  rights,
  permission,
}: UsePermissionsFunctionProps) => {
  const callback = useCallback<UsePermissionsFunctionCallback>(
    ({ entity, context, record }) => {
      const { isConfigsMissing, permissionsFunctions, denyPermissionRules } =
        getEntityPermissionsConfigs({ entity, context });

      if (isConfigsMissing) {
        return false;
      }

      const shouldDenyPermission = doSomePermissionRulesApply({
        rights,
        permission,
        permissionRules: denyPermissionRules,
      });

      if (shouldDenyPermission) {
        return false;
      }

      return doesUserHavePermission({
        user,
        entity,
        record,
        permission,
        permissionsFunctions,
      });
    },
    [rights, permission, user]
  );

  return callback as T;
};
