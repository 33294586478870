import { useState } from 'react';

import { AppParts, useCanView, useNavigate, useUser } from '@work4all/data';

import { EntityByObjectType } from '@work4all/models';
import { ApprovalEvent } from '@work4all/models/lib/Classes/ApprovalEvent.entity';
import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { InputNotificationKey } from '@work4all/models/lib/Classes/InputNotificationKey.entity';
import { Mention } from '@work4all/models/lib/Classes/Mention.entity';
import { Notification } from '@work4all/models/lib/Classes/Notification.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';
import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { NotificationType } from '@work4all/models/lib/Enums/NotificationType.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { assertNever, isDev } from '@work4all/utils';
import {
  canApproveVacations,
  canViewInboundInvoicesApprovalCenter,
} from '@work4all/utils/lib/permissions';

import {
  stringifyTableSettings,
  TableSettings,
} from '../../../../components/data-tables/make-table-settings';
import { useSetNotificationReadMutation } from '../../hooks/use-set-notification-read-mutation';

interface NotificationMaskState {
  open: boolean;
  entity: Entities;
  id: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params?: Record<string, any>;
}

const ENTITIES_WITHOUT_HISTORY_TAB = [Entities.ticket];

export function NotificationActions({
  onMutationCompleted,
  children,
}: {
  onMutationCompleted: () => void;
  children: (props: {
    mask: NotificationMaskState;
    onCloseMask: () => void;
    onNotificationClick: (notificationList: Notification[]) => void;
    onMarkAllAsReadClick: () => void;
  }) => JSX.Element;
}) {
  const [mask, setMask] = useState<NotificationMaskState>({
    open: false,
    entity: null,
    id: null,
    params: {},
  });

  const user = useUser();
  const canViewVacations = useCanView(AppParts.VACATIONS);
  const canViewRe = useCanView(AppParts.INCOMING_INVOICE_LEDGER);
  const canViewReApprovalCenter =
    canViewVacations && canViewInboundInvoicesApprovalCenter(user);
  const canViewVacationApprovalCenter = canViewRe && canApproveVacations(user);

  const navigate = useNavigate();

  function handleCloseMask() {
    setMask((mask) => {
      return { ...mask, open: false };
    });
  }

  const [setNotificationRead] = useSetNotificationReadMutation({
    onCompleted: onMutationCompleted,
  });

  function markMultipleAsRead(notificationIds: InputNotificationKey[]): void {
    setNotificationRead({
      variables: { notificationIds },
    });
  }

  function markAllAsRead(): void {
    setNotificationRead({ variables: { all: true } });
  }

  function handleNotificationClick(notificationList: Notification[]) {
    const markAsRead: InputNotificationKey[] = notificationList
      .filter((item) => !item.isRead)
      .map((item) => {
        return {
          entityId: item.id,
          notificationType: item.notificationType,
        };
      });

    if (markAsRead.length > 0) {
      markMultipleAsRead(markAsRead);
    }

    const notification = notificationList[0];

    switch (notification.notificationType) {
      case NotificationType.MENTION: {
        const mention = notification.object as Mention;

        const entity = EntityByObjectType[mention.parentObjectType];
        const id = mention.parentObjectCode || mention.parentObjectId;

        setMask({
          open: true,
          entity,
          id,
          params: {
            tab: ENTITIES_WITHOUT_HISTORY_TAB.includes(entity)
              ? 'general'
              : 'history',
          },
        });

        break;
      }

      case NotificationType.FAILED_SAVE_SEND_MAIL_JOB: {
        const job = notification.object as SaveSendMailJob;

        const entity = Entities.eMail;
        const id = job.createdMail.id;

        setMask({
          open: true,
          entity,
          id,
        });

        break;
      }

      case NotificationType.APPROVAL_EVENT: {
        const object = notification.object as ApprovalEvent;
        const isVacationNotification =
          notification.parentObjectType === ObjectType.VACATION_REQUEST;
        const isReNotification =
          notification.parentObjectType === ObjectType.RECHNUNGSEINGAENGE;
        const creator = object?.refObject?.createdByUser;
        const isCreator = user.benutzerCode === creator?.id;

        const defaultFilter: TableSettings = {
          filter: [
            {
              id: 'user.id',
              value: [
                {
                  id: creator?.id?.toString(),
                  displayName: creator?.displayName,
                },
              ],
            },
          ],
        };

        if (isVacationNotification) {
          const approver = (object?.refObject as VacationRequestEntity)
            ?.releasedByUser;
          const isApprover = user.benutzerCode === approver?.id;

          const approvalFilter: TableSettings = {
            filter: [
              ...defaultFilter.filter,
              {
                id: 'releasedByUser.id',
                value: [
                  {
                    id: approver?.id?.toString(),
                    displayName: approver?.displayName,
                  },
                ],
              },
            ],
          };

          if (isApprover && canViewVacationApprovalCenter) {
            navigate(
              `/more/approval/vacation?settings=${stringifyTableSettings(
                approvalFilter
              )}`
            );
          } else if (canViewVacations) {
            navigate('/more/vacation');
          } else {
            break;
          }
        } else if (isReNotification) {
          const approver = (object?.refObject as InboundInvoice)
            ?.approvedByUser;
          const isApprover = user.benutzerCode === approver?.id;

          const approvalFilter: TableSettings = {
            filter: [
              ...defaultFilter.filter,
              {
                id: 'approvedByUser.id',
                value: [
                  {
                    id: approver?.id?.toString(),
                    displayName: approver?.displayName,
                  },
                ],
              },
            ],
          };

          if (isApprover && canViewReApprovalCenter) {
            navigate(
              `/more/approval/reViewModel?settings=${stringifyTableSettings(
                approvalFilter
              )}`
            );
          } else if (canViewRe && isCreator) {
            setMask({
              open: true,
              entity: Entities.reViewModel,
              id: object.refObject?.id,
              params: {
                tab: 'general',
              },
            });
          } else {
            break;
          }
        }
        break;
      }

      default:
        if (isDev()) {
          assertNever(
            notification.notificationType,
            `Unknown notification type "${notification.notificationType}"`
          );
        }

        break;
    }
  }

  function handleReadAllClick() {
    markAllAsRead();
  }

  return children({
    onNotificationClick: handleNotificationClick,
    onMarkAllAsReadClick: handleReadAllClick,
    mask: mask,
    onCloseMask: handleCloseMask,
  });
}
