import styles from './left-area-visible-filters-popover.module.scss';

import { Popover, PopoverProps } from '@mui/material';
import { useMemo } from 'react';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLeftAreaVisibleFiltersState } from '../hooks/use-left-area-visible-filters-state';

type Props = Pick<PopoverProps, 'anchorEl' | 'onClose' | 'open'> & {
  entity: Entities;
  filters: { id: string; label: string }[];
};

export const LeftAreaVisibleFiltersPopover = ({
  open,
  filters,
  entity,
  anchorEl,
  onClose,
}: Props) => {
  const filterIds = useMemo(
    () => filters.map((filter) => filter.id),
    [filters]
  );

  const { isFilterVisible, toggleFilterVisibility } =
    useLeftAreaVisibleFiltersState({
      entity,
      filterIds,
    });

  return (
    <Popover
      open={open}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      classes={{ paper: styles.paper }}
    >
      <div className={styles.checkboxs}>
        {filters.map((filter, index) => {
          return (
            <CheckboxRadioItem
              key={index}
              label={filter.label}
              control="checkbox"
              onChange={() => {
                toggleFilterVisibility(filter.id);
              }}
              checked={isFilterVisible(filter.id)}
              horizontalPadding={false}
            />
          );
        })}
      </div>
    </Popover>
  );
};
