import { useMaskConfig } from '../../../hooks/mask-context';
import { OverlayController } from '../../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../../overlay-controller/use-mask-overlay';
import {
  useCheckListFormUpdate,
  useCheckListMaskOverlayNewEntityData,
  useCheckListMaskOverlayRequest,
  useCheckListSubmitHandler,
} from '../hooks';

import { CheckListOverlayControllerContent } from './CheckListOverlayControllerContent';
import { CheckListOverlayControllerTabs } from './CheckListOverlayControllerTabs';
import { ControllerTabs } from './enums';
import {
  CheckListMaskFormValue,
  CheckListOverlayControllerProps,
} from './types';

export const CheckListOverlayController = (
  props: CheckListOverlayControllerProps
) => {
  const mask = useMaskConfig(props);
  const request = useCheckListMaskOverlayRequest(mask.id);
  const newEntityData = useCheckListMaskOverlayNewEntityData(mask);
  const overlay = useMaskOverlay<CheckListMaskFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    getSubTitle: (x) => x.title,
  });

  useCheckListFormUpdate({ form: overlay.form });

  const onSubmit = useCheckListSubmitHandler({
    isCreateMode: mask.isCreateMode,
    dirtyFields: overlay.form.formState.dirtyFields,
    onAfterSave: props.onAfterSave,
    defaultValues: overlay.data,
  });

  return (
    <OverlayController<CheckListMaskFormValue>
      {...overlay}
      onSubmit={async (data) => {
        onSubmit(data);
      }}
      tabs={<CheckListOverlayControllerTabs />}
      openTab={mask.params?.tab ?? ControllerTabs.General}
    >
      <CheckListOverlayControllerContent />
    </OverlayController>
  );
};
