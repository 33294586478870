import styles from './use-resizable.module.scss';

import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

export const useResizable = () => {
  const [height, setHeight] = useState(undefined);
  const resizableRef = useRef(null);
  const startY = useRef(0);
  const startHeight = useRef(0);

  const handleMouseMove = useCallback(
    (e) => {
      const newHeight = startHeight.current + (e.clientY - startY.current);
      setHeight(newHeight);
    },
    [setHeight]
  );

  const handleMouseUp = useCallback(() => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = useCallback(
    (e) => {
      startY.current = e.clientY;
      startHeight.current = height || e.target.parentElement.clientHeight;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp, height]
  );

  const handleElement = useMemo(
    () => <div className={styles.handle} onMouseDown={handleMouseDown} />,
    [handleMouseDown]
  );

  return useMemo(() => {
    const style: React.CSSProperties = {};
    if (height) {
      style.maxHeight = undefined;
      style.height = `${height}px`;
    }
    return {
      resizableRef,
      handleElement,
      style,
      height,
      setHeight,
    };
  }, [handleElement, height]);
};

export interface ResizableContextType {
  resizableRef: React.RefObject<HTMLElement> | null;
  handleElement: JSX.Element | null;
  style: React.CSSProperties;
  height: number | undefined;
  setHeight: (height?: number) => void;
}
// Create a context with default values for resizableState.
export const ResizableContext = createContext<ResizableContextType>({
  resizableRef: null,
  handleElement: null,
  style: {},
  height: undefined,
  setHeight: () => {},
});

// Create a custom hook to access the context.
export const useResizableContext = () => {
  return useContext(ResizableContext);
};
