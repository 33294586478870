import { Capacitor } from '@capacitor/core';
import {
  AddRounded,
  ContentPaste,
  Event,
  ListRounded,
  Timer,
} from '@mui/icons-material';
import { Button, IconButton, Stack, Theme, useMediaQuery } from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams } from 'react-router-dom';

import { GlobalSearchField, SpecialSearch } from '@work4all/components';
import { NavigationWrapper } from '@work4all/components/lib/navigation/navigation-wrapper/NavigationWrapper';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import {
  AppParts,
  useCanView,
  useLocation,
  useModuleRights,
  useNavigate,
} from '@work4all/data';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { SearchHistoryResults } from '@work4all/data/lib/settings/types';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useFavoriteLinks } from '../../containers/more/data/favorite-links/favorite-links-context';
import PullRefresh from '../../containers/pull-refresh/PullRefresh';
import { TimeTracker } from '../../containers/time-tracker/TimeTracker';
import { InsertFromClipboard } from '../entity-picker/InsertFromClipboardModal';
import { MaskModal } from '../entity-picker/MaskModal';
import { PickFromListModal } from '../entity-picker/PickFromListModal';
import { FavoriteLinks } from '../more/favorite-links/FavoriteLinks';
import { UserMenu } from '../user-menu/UserMenu';

import { findActiveRoute, isActiveFavoriteLink } from './find-active-route';

export type INavigationWrapperActiveRouteMatcherProps = {
  isLoggedIn: boolean;
  children: JSX.Element;
};

const pathMapping = {
  [Entities.customer]: '/customers',
  [Entities.supplier]: '/suppliers',
  [Entities.project]: '/projects',
};

export function NavigationWrapperActiveRouteMatcher({
  isLoggedIn,
  children,
}: INavigationWrapperActiveRouteMatcherProps) {
  const { favoriteLinks } = useFavoriteLinks();
  const location = useLocation();

  const platform = Capacitor.getPlatform();
  const isMobileApp = platform === 'android' || platform === 'ios';

  const { t } = useTranslation();
  const { rights } = useModuleRights();
  const navigate = useNavigate();
  const [defaultOpen, setDefaultOpen] = useState<Entities | SpecialSearch>(
    SpecialSearch.ALL
  );

  const canViewVacation = useCanView(AppParts.VACATIONS);

  const [maskOpenFor, setMaskOpenFor] = useState<Entities | null>(null);
  const [listModalOpenFor, setListModalOpenFor] = useState<Entities | null>(
    null
  );
  const [insertfromClipboardOpenFor, setInsertFromClipboardOpenFor] =
    useState<Entities>();
  const [defaultValue, setDefaultValue] = useState({});

  const { lastSearchItems, saveSearchItem } = useSearchHistory();

  const lastItemsMap = useMemo<SearchHistoryResults>(() => {
    return {
      customer: lastSearchItems.customer?.slice(0, 3) || [],
      supplier: lastSearchItems.supplier?.slice(0, 3) || [],
      project: lastSearchItems.project?.slice(0, 3) || [],
      article: lastSearchItems.article?.slice(0, 3) || [],
    };
  }, [lastSearchItems]);

  const footerMap = useMemo(() => {
    const getActionStack = (
      entity: Entities,
      createName,
      listName,
      insertName?
    ) => {
      return (
        <Stack
          direction={'row'}
          justifyContent="center"
          gap="1rem"
          padding={'.5rem 1rem'}
        >
          <Button
            startIcon={<AddRounded />}
            onClick={() => setMaskOpenFor(entity)}
          >
            {createName}
          </Button>

          {insertName && (
            <Button
              startIcon={<ContentPaste />}
              onClick={() => setInsertFromClipboardOpenFor(entity)}
            >
              {insertName}
            </Button>
          )}

          <Button
            startIcon={<ListRounded />}
            onClick={() => setListModalOpenFor(entity)}
          >
            {listName}
          </Button>
        </Stack>
      );
    };
    return {
      [Entities.customer]: getActionStack(
        Entities.customer,
        t('CUSTOMER.ACTIONS.CREATE_NEW'),
        t('CUSTOMER.ACTIONS.LIST'),
        t('CUSTOMER.ACTIONS.INSERT_CUSTOMER')
      ),
      [Entities.supplier]: getActionStack(
        Entities.supplier,
        t('SUPPLIER.ACTIONS.CREATE_NEW'),
        t('SUPPLIER.ACTIONS.LIST'),
        t('SUPPLIER.ACTIONS.INSERT_SUPPLIER')
      ),
      [Entities.project]: getActionStack(
        Entities.project,
        t('PROJECT.ACTIONS.CREATE_NEW'),
        t('PROJECT.ACTIONS.LIST')
      ),
    };
  }, [t]);

  const canViewCustomerFiles = useCanView(AppParts.CUSTOMERFILES);
  const canViewSupplierFiles = useCanView(AppParts.SUPPLIERFILES);
  const canViewProjectFiles = useCanView(AppParts.PROJECTFILES);
  const canViewTravelReceipts = useCanView(AppParts.TRAVEL_RECEIPTS);
  const canViewHome = useCanView(AppParts.HOME);
  const canViewControlCenter = useCanView(AppParts.CONTROL_CENTER);
  const canViewCalendar = useCanView(AppParts.CALENDAR);
  const canViewSearchBar = useCanView(AppParts.SEARCH_BAR);
  const canViewTimeTracking = useCanView(AppParts.TIME_TRACKING);
  const canViewProjectTimeTracking = useCanView(AppParts.PROJECTTIMETRACKING);

  const isDownLg = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xl')
  );

  const isDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const availableRoutes = useMemo(() => {
    if (isTimeTrackingUser(rights)) {
      return [
        { title: 'NAVIGATION.TIMETRACKING', pathname: '/more/timetracking' },
        canViewVacation
          ? { title: 'NAVIGATION.VACATION', pathname: '/more/vacation' }
          : undefined,
      ].filter(Boolean);
    }
    return [
      canViewHome ? { title: 'NAVIGATION.HOME', pathname: '/home' } : undefined,
      !canViewHome && (canViewProjectTimeTracking || canViewTimeTracking)
        ? { title: 'NAVIGATION.TIMETRACKING', pathname: '/more/timetracking' }
        : undefined,
      !canViewHome && canViewVacation
        ? { title: 'NAVIGATION.VACATION', pathname: '/more/vacation' }
        : undefined,
      canViewCustomerFiles
        ? { title: 'NAVIGATION.CUSTOMER', pathname: '/customers' }
        : undefined,
      canViewSupplierFiles
        ? { title: 'NAVIGATION.SUPPLIER', pathname: '/suppliers' }
        : undefined,
      canViewProjectFiles
        ? { title: 'NAVIGATION.PROJECT', pathname: '/projects' }
        : undefined,
      canViewTravelReceipts && !canViewHome && !canViewControlCenter
        ? {
            title: 'NAVIGATION.TRAVELRECEIPTS',
            pathname: '/more/entity/travelReceipts',
          }
        : undefined,
      canViewControlCenter
        ? { title: 'NAVIGATION.MORE', pathname: '/more', manualMatch: true }
        : undefined,
      isDownMd && canViewCalendar
        ? { title: 'NAVIGATION.CALENDAR', pathname: '/more/calendar' }
        : undefined,
    ].filter(Boolean);
  }, [
    rights,
    canViewHome,
    canViewProjectTimeTracking,
    canViewVacation,
    canViewCustomerFiles,
    canViewSupplierFiles,
    canViewProjectFiles,
    canViewTravelReceipts,
    canViewControlCenter,
    isDownMd,
    canViewCalendar,
  ]);

  const activeRoute = useMemo(() => {
    //alter global searches default opening mode
    let ent: Entities | SpecialSearch = SpecialSearch.ALL;
    if (location.pathname.startsWith('/customers')) ent = Entities.customer;
    if (location.pathname.startsWith('/suppliers')) ent = Entities.supplier;
    if (location.pathname.startsWith('/projects')) ent = Entities.project;
    setDefaultOpen(ent);
    return findActiveRoute(favoriteLinks, location.pathname, availableRoutes);
  }, [availableRoutes, favoriteLinks, location.pathname]);

  return (
    <NavigationWrapper
      isLoggedIn={isLoggedIn}
      availableRoutes={availableRoutes}
      globalSearch={
        canViewSearchBar && (
          <GlobalSearchField
            defaultEntity={defaultOpen}
            lastUsedItemsMap={lastItemsMap}
            footerMap={footerMap}
            onElementSelected={(data, type) => {
              //TODO why is this a seperate type and does not use the entities?

              if (
                type === Entities.customer ||
                type === Entities.supplier ||
                type === Entities.project
              ) {
                saveSearchItem(type, data);
              }
            }}
            pathMapping={pathMapping}
          />
        )
      }
      timeTracker={
        isDownLg && canViewTimeTracking ? (
          <IconButton onClick={() => navigate('/more/timetracking')}>
            <Timer
              sx={{
                color: 'white',
              }}
            />
          </IconButton>
        ) : (
          <TimeTracker />
        )
      }
      calendar={
        canViewCalendar && (
          <IconButton onClick={() => navigate('/more/calendar')}>
            <Event style={{ color: 'white' }} />
          </IconButton>
        )
      }
      userMenu={<UserMenu />}
      favoriteLinks={
        <FavoriteLinks
          activeLink={isActiveFavoriteLink(activeRoute) ? activeRoute : null}
        />
      }
    >
      {isMobileApp ? <PullRefresh>{children}</PullRefresh> : children}

      {listModalOpenFor && (
        <PickFromListModal<Customer | Supplier | Project>
          open={true}
          entity={listModalOpenFor}
          multiple
          onClose={() => {
            setListModalOpenFor(null);
          }}
          onConfirm={(selected) => {
            setListModalOpenFor(null);

            const ids = selected.map((entity) => String(entity.id));
            navigate({
              pathname: `${pathMapping[listModalOpenFor]}/${ids[0]}`,
              search: ids.length > 1 ? `${createSearchParams({ ids })}` : null,
            });
          }}
        />
      )}
      {maskOpenFor && (
        <MaskModal
          params={{ presetFields: JSON.stringify(defaultValue) }}
          entity={maskOpenFor}
          open={true}
          onClose={() => {
            setMaskOpenFor(null);
          }}
          onAfterSave={(result: Customer | Supplier | Project) => {
            setMaskOpenFor(null);

            navigate(`${result.id}`);
          }}
        />
      )}

      {insertfromClipboardOpenFor && (
        <InsertFromClipboard
          entity={insertfromClipboardOpenFor}
          onSuccess={(value) => {
            setDefaultValue(value);
            setMaskOpenFor(insertfromClipboardOpenFor);
            setInsertFromClipboardOpenFor(undefined);
          }}
          onClose={() => setInsertFromClipboardOpenFor(undefined)}
        />
      )}
    </NavigationWrapper>
  );
}
