import { buildQuery } from '@work4all/data/lib/hooks/data-provider/utils/buildQuery';

import { ObjectTypeByEntity } from '@work4all/models';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { GROUPED_ROW_FOOTER_DATA_REQUEST_DATA } from './constants';
import { prepareGroupedRowFooterDataFields } from './prepare-grouped-row-footer-data-fields';
import { prepareGroupedRowFooterDataFilter } from './prepare-grouped-row-footer-data-filter';
import { BuildGroupedRowFooterDataQueryProps } from './types';

export const buildGroupedRowFooterDataQuery = ({
  columns,
  entity,
  filter,
}: BuildGroupedRowFooterDataQueryProps) => {
  const request: DataRequest = {
    operationName: 'GetGroupedRowFooterData',
    entity: Entities.groupQueryResult,
    data: GROUPED_ROW_FOOTER_DATA_REQUEST_DATA,
    vars: {
      query: {
        entityType: ObjectTypeByEntity[entity],
        groupKeyFields: prepareGroupedRowFooterDataFields({ columns, entity }),
        filter: prepareGroupedRowFooterDataFilter({ filter, entity }),
      },
    },
  };

  return buildQuery(request, null);
};
