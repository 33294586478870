import { useEffect } from 'react';

import { ErpPreview } from '@work4all/components/lib/components/entity-preview/erp-preview/ErpPreview';
import { useErpPreviewData } from '@work4all/components/lib/components/entity-preview/erp-preview/hooks/use-erp-preview-data';

import { useEntityActions } from '../../../../../../components/data-tables/entity-table/EntityActionProvider';
import {
  ConvertEntity,
  useCloneConvertErpActions,
} from '../../../../../../components/data-tables/offers-table/hooks/use-clone-convert-erp';
import { useLanguageManager } from '../../../../../../providers/LanguageManagerProvider';
import { PdfOverlayMaskProvider } from '../../../../../mask-overlays/mask-overlay/views/pdf-editor/PdfOverlayMaskProvider';
import { FilePreviewPanelProps } from '../FilePreviewPanelProps';

type ErpPreviewContainerProps = FilePreviewPanelProps & {
  isApprovalCenter?: boolean;
};

export const ErpPreviewContainer = (props: ErpPreviewContainerProps) => {
  const {
    entityIds,
    entityType,
    openInFullscreen = false,
    ...commonProps
  } = props;
  const { resources: languageResources } = useLanguageManager();

  const previewData = useErpPreviewData({
    ids: entityIds as number[],
    entity: entityType,
  });

  const { actions } = useCloneConvertErpActions({
    entity: entityType as ConvertEntity,
    data: previewData.data,
  });

  const { addActions } = useEntityActions();

  useEffect(() => {
    addActions({
      left: actions,
    });
  }, [actions, addActions]);

  return (
    <PdfOverlayMaskProvider>
      <ErpPreview
        {...commonProps}
        openInFullscreen={openInFullscreen}
        ids={entityIds as number[]}
        entity={entityType}
        previewData={previewData}
        languageResources={languageResources}
      />
    </PdfOverlayMaskProvider>
  );
};
