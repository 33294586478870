import styles from './emailPreview.module.scss';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation, usePageZoom } from '@work4all/data';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { ConvertTempfileTarget } from '@work4all/models/lib/Enums/ConvertTempfileTarget.enum';
import { CreateTempFileOriginType } from '@work4all/models/lib/Enums/CreateTempFileOriginType.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { Chip } from '../../../dataDisplay/chip/Chip';
import { Attachments } from '../../attachments';
import { EmailStatus } from '../../email-status/EmailStatus';
import { InfoCard, InfoCards, PreviewWrapper } from '../components';
import {
  PreviewInputsProvider,
  PreviewTitleInput,
} from '../components/preview-input';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';
import { setDefaultHeadStyles } from '../utils';

import { RequestedEmailData } from './RequestedEmailData';

export interface IEmailPreviewProps extends EntityPreviewProps<EMail> {
  hideHeader?: boolean;
  disableConvertingAttachments?: boolean;
}

export function EmailPreview(props: IEmailPreviewProps) {
  const {
    entries,
    hideHeader = false,
    disableConvertingAttachments = false,
    ...handlers
  } = props;

  const email = entries[0];

  const toList = useMemo(() => {
    let to = [''];
    if (email?.to == null || email?.to === '') return null;

    to = email?.to
      .trim()
      .split(';')
      .filter((x) => x.trim().length > 0);

    return to;
  }, [email]);

  const mailDate = useMemo(
    () =>
      DateTime.fromISO(email?.date).toLocaleString(DateTimeCustom.DATE_TIME),
    [email]
  );

  const ccList = useMemo(() => {
    let cc = [''];
    if (email?.cc == null || email?.cc === '') {
      return null;
    } else {
      cc = email?.cc
        .trim()
        .split(';')
        .filter((x) => x.trim().length > 0);
    }
    return cc;
  }, [email]);

  const { t } = useTranslation();
  const [showAllTo, setShowAllTo] = useState<boolean>(false);
  const [showAllCc, setShowAllCc] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<boolean>(false);
  const emailIFrameRef = useRef<HTMLIFrameElement>(null);

  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('xl'));

  useEffect(() => {
    setShowAllTo(false);
    setShowAllCc(false);
  }, [email]);

  const cleanedPersistantAttachmentList = useMemo(() => {
    return email?.attachmentList?.map((x) => ({
      ...x,
      fileName: x.displayFilename,
      __typename: undefined,
    }));
  }, [email?.attachmentList]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const { zoomLevel } = usePageZoom();

  const metaData = useMemo(
    () => (
      <>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
          <InfoCards>
            <InfoCard staticField label="Absender">
              {email?.from}
            </InfoCard>
            {email?.date ? (
              <InfoCard staticField label="Datum/Uhrzeit">
                {t('TIME.TIME_DATE', {
                  dateTimeString: mailDate,
                })}
              </InfoCard>
            ) : null}
          </InfoCards>
          {!showMore && !isDesktop ? (
            <IconButton
              size="large"
              color="primary"
              onClick={() => setShowMore(true)}
            >
              <ExpandMore />
            </IconButton>
          ) : null}
        </div>

        {isDesktop || showMore ? (
          <div className={styles.infoCardsWrapper}>
            <InfoCard staticField label="AN">
              {toList === null && <Typography>-</Typography>}
              <Stack flexDirection="row" flexWrap="wrap">
                {(showAllCc ? toList : toList?.slice(0, 3))?.map(
                  (x, i: number) => (
                    <Chip key={i} label={x} maxWidth={18} />
                  )
                )}
                {!showAllTo && toList?.length > 3 && (
                  <Typography>
                    <span
                      className={styles.blueAction}
                      onClick={() => setShowAllTo(true)}
                    >
                      {'+' + (toList?.length - 3) + ' ' + t('FAV_LINKS.MORE')}
                    </span>
                  </Typography>
                )}
              </Stack>
            </InfoCard>

            <InfoCard staticField label="CC">
              {ccList === null && <Typography>-</Typography>}
              <Stack flexDirection="row" flexWrap="wrap">
                {(showAllCc ? ccList : ccList?.slice(0, 3))?.map(
                  (x, i: number) => (
                    <Chip key={i} label={x} maxWidth={18} />
                  )
                )}
                {!showAllCc && ccList?.length > 3 && (
                  <Typography>
                    <span
                      className={styles.blueAction}
                      onClick={() => setShowAllCc(true)}
                    >
                      {'+' + (ccList?.length - 3) + ' ' + t('FAV_LINKS.MORE')}
                    </span>
                  </Typography>
                )}
              </Stack>
            </InfoCard>
          </div>
        ) : null}
        {showMore && !isDesktop ? (
          <IconButton
            size="large"
            color="primary"
            onClick={() => setShowMore(false)}
          >
            <ExpandLess />
          </IconButton>
        ) : null}
      </>
    ),
    [
      ccList,
      email?.date,
      email?.from,
      isDesktop,
      mailDate,
      showAllCc,
      showAllTo,
      showMore,
      t,
      toList,
    ]
  );

  const emailBody = email?.rewrittenBodyHtml || email.bodyHtml || email.body;

  const [mutate] = useDataMutation<EMail, EMode.upsert>({
    entity: Entities.eMail,
    mutationType: EMode.upsert,
    responseData: RequestedEmailData as unknown as EMail,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.eMail,
    entries: [email],
    mutate,
  });

  const { onPopoverClose, onEdit } = entityPreview;

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.eMail}
      entries={entries}
    >
      <TempFileManagerContext.Provider value={tempFileManager}>
        <PreviewWrapper>
          <div className={styles.wrap}>
            {!hideHeader ? (
              <PreviewTitleInput accessor="subject" {...handlers} />
            ) : null}

            <EmailStatus
              kind={email?.kind}
              saveSendMailJob={email?.saveSendMailJob}
              options={{
                iconOnly: false,
                errorAction: handlers.onEditClicked,
              }}
            />

            {!isDesktop && (
              <>
                {metaData}
                <Divider orientation="horizontal" />
              </>
            )}

            <iframe
              ref={emailIFrameRef}
              className={styles.content}
              title="E-Mail Preview"
              srcDoc={setDefaultHeadStyles({
                value: emailBody || '',
                zoom: zoomLevel,
              })}
              src="/"
            ></iframe>
            {tempFileManager.fileList.length ? (
              <div className={styles.attachmentsWrapper}>
                <Attachments
                  entity={Entities.eMail}
                  disableAddAction={true}
                  disableRemoveAction={true}
                  layout="compact"
                  disableEditAction={true}
                  convertTempfileTarget={ConvertTempfileTarget.DOKUMENT}
                  createTempFileOriginType={
                    CreateTempFileOriginType.E_MAIL_ATTACHEMENT
                  }
                  convertFileAdditionalInfo={{
                    projectId: email?.projectId,
                    businessPartnerId: email?.businessPartnerId,
                    businessPartnerType: email?.businessPartnerType,
                    contactId: email?.contactId,
                  }}
                  disableConvertingAttachments={disableConvertingAttachments}
                />
              </div>
            ) : null}

            {isDesktop && (
              <>
                <Divider orientation="horizontal" />
                {metaData}
              </>
            )}
          </div>
        </PreviewWrapper>
      </TempFileManagerContext.Provider>
    </PreviewInputsProvider>
  );
}
