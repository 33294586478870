import React from 'react';
import { TableInstance } from 'react-table';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { RELedgerAccountSplit } from '@work4all/models/lib/Classes/RELedgerAccountSplit.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/rELedgerAccountSplit-table-schema.json';
import { ITableProps } from '../table/Table';

const defaultSort = [{ field: 'id', direction: SortDirection.DESCENDING }];

export const ReBookingsTable = React.forwardRef<TableInstance, IEntityTable>(
  function ReBookingsTable(_props, ref) {
    const dataTable = useEntityDataTable<RELedgerAccountSplit, never>({
      schema: schema as never,
      defaultSort,
      innerEntityQuery: {
        parentEntity: Entities.reViewModel,
        fieldName: nameof<ReViewModel>('bookings'),
      },
    });

    const overrides: Partial<ITableProps> = {
      layout: 'table',
      areas: null,
      hideSearch: true,
      cardConfig: null,
      selectableMultiple: false,
      displayFooter: false,
      actions: {
        groupingEnabled: null,
      },
    };

    return (
      <EntityTable ref={ref} {...dataTable} {..._props} overrides={overrides} />
    );
  }
);
