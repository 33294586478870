import styles from './Grouped.module.scss';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import React from 'react';

import { GroupLabelElementParams } from '@work4all/models/lib/table-schema/table-schema';

import { Body2 } from '../../../../../../typography/body2/Body2';
import { Caption } from '../../../../../../typography/caption/Caption';
import { TableRow } from '../../../../types';
import { RowIndent } from '../../../row-indent/RowIndent';
import { TableRow as TableRowComponent } from '../../../table-row/TableRow';

export const GROUP_ROW_MARKER = 'table-row-group-marker';

interface Props {
  row: TableRow;
  depth: number;
  cellClassName?: string;
}

export const Grouped: React.FC<Props> = (props) => {
  const { row, depth } = props;

  const {
    groupByID,
    groupByLabel: label,
    groupByTotalCount,
    groupByVal: value,
  } = row;

  const cell = row.allCells.find((cell) => cell.column.id === groupByID);

  if (!cell) {
    console.error('cell not found');
    return null;
  }

  const attr = { [`data-${GROUP_ROW_MARKER}`]: true };
  return (
    <TableRowComponent isSelected={row.isSelected} isGrouped={true} {...attr}>
      <div className={styles.groupedRowContainer}>
        <RowIndent depth={depth} />

        <IconButton size="small" {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
        </IconButton>

        <Body2 className={styles.title}>
          <div className={styles.header}>{cell.column.Header as string}:</div>
          {cell?.column?.render('GroupLabelElement', {
            value,
            label,
            ...(
              cell?.column as {
                groupLabelElementParams?: GroupLabelElementParams;
              }
            )?.groupLabelElementParams,
          })}
          <Caption className={styles.count}>({groupByTotalCount})</Caption>
        </Body2>
      </div>
    </TableRowComponent>
  );
};
