import { createContext, PropsWithChildren, useContext } from 'react';

const TableCellClickContext = createContext<DateCellClickContextProps>({
  onDateCellClick: undefined,
});

interface DateCellClickContextProps {
  onDateCellClick: ((value: Date) => void) | undefined;
}

export const TableCellClickProvider = (
  props: PropsWithChildren<Partial<DateCellClickContextProps>>
) => {
  const { children, onDateCellClick } = props;

  return (
    <TableCellClickContext.Provider value={{ onDateCellClick }}>
      {children}
    </TableCellClickContext.Provider>
  );
};

export const useDateCellClick = () => useContext(TableCellClickContext);
