import styles from './../../MaskOverlay.module.scss';

import { gql, useMutation } from '@apollo/client';
import SaveIcon from '@mui/icons-material/Save';
import SendIcon from '@mui/icons-material/Send';
import { pick } from 'lodash';
import {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LABEL_VISIBILITY, MaskActionButton } from '@work4all/components';
import { AttachmentsUploadButton } from '@work4all/components/lib/components/attachments/AttachmentsUploadButton';
import { EmailStatus } from '@work4all/components/lib/components/email-status/EmailStatus';
import { useLock } from '@work4all/components/lib/hooks';
import { htmlParser } from '@work4all/components/lib/input/format-text/TextEditor/utils/html-parser';

import {
  prepareResponse,
  useDataMutation,
  useDataProvider,
  useFormPlus,
  useUser,
} from '@work4all/data';
import { useEntityEventsContext } from '@work4all/data/lib/entity-events/entity-events-context';
import {
  TempFileManagerContext,
  useTempFileManager,
} from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useSyncEmailStatus } from '@work4all/data/lib/hooks/use-sync-email-status';
import { useEntityJsonSchema } from '@work4all/data/lib/json-schema/EntityJsonSchemasContext';

import { IAttachmentEntity } from '@work4all/models';
import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { InputEMailAnhangAttachementsRelation } from '@work4all/models/lib/Classes/InputEMailAnhangAttachementsRelation.entity';
import { InputEMailRelation } from '@work4all/models/lib/Classes/InputEMailRelation.entity';
import { InputTopicMarkRelation } from '@work4all/models/lib/Classes/InputTopicMarkRelation.entity';
import { SaveSendMailJob } from '@work4all/models/lib/Classes/SaveSendMailJob.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMailAttachToEntityType } from '@work4all/models/lib/Enums/EMailAttachToEntityType.enum';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SaveSendMailJobMode } from '@work4all/models/lib/Enums/SaveSendMailJobMode.enum';

import { useJSONSchemaResolver } from '@work4all/utils';
import {
  canAddEmail,
  canDeleteEmail,
  canEditEmail,
} from '@work4all/utils/lib/permissions';

import { usePageTitle } from '../../../../../hooks';
import { useBinaryTransfer } from '../../../../../hooks/useBinaryTransfer';
import { formatPageTitle } from '../../../../../utils/format-page-title';
import {
  MaskTab,
  MaskTabContext,
  MaskTabPanel,
  MaskTabs,
} from '../../../mask-tabs';
import { Form } from '../../components/form';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { MaskOverlayDeleteButton } from '../../components/MaskOverlayDeleteButton';
import { MaskOverlayFullscreenToggleButton } from '../../components/MaskOverlayFullscreenToggleButton';
import { MaskOverlayHeader } from '../../components/MaskOverlayHeader/MaskOverlayHeader';
import { MaskOverlaySubmitButton } from '../../components/MaskOverlaySubmitButton';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import {
  MaskContextProvider,
  useMaskConfig,
  useMaskContextValue,
} from '../../hooks/mask-context';
import { useConfirmBeforeCloseMask } from '../../hooks/use-confrm-before-close-mask';
import { EntityRightsContext } from '../../hooks/use-entity-rights';
import { useStandardDeleteEntityHandler } from '../../hooks/use-standard-delete-entity-handler';
import { MaskControllerProps } from '../../types';
import { useAssignableTemplateEntity } from '../../utils/use-assignable-template-entity';

import { CopyAttachmentsDialog } from './components/copy-attachments-dialog/CopyAttachmentsDialog';
import { GeneralTabPanel } from './components/tab-panels//general/GeneralTabPanel';
import {
  commonEmailRequestData,
  emailRequestData,
  emailRequestVars,
} from './email-request-data';
import { EmailTemplater } from './email-templater/EmailTemplater';
import { EmailActions, EMAILMODES } from './EmailActions';
import { useConvertAttachments } from './hooks/useConvertAttachments';
import { EmailMaskFormValue } from './types';
import { useEmailFormUpdate } from './use-email-form-update';
import { useEmailMaskInit } from './use-form-initial-values';

const removeHtmlComments = (val: string) => {
  return val?.replace(/<![\s\S]*?>/g, '') || '';
};

const START_JOB_SAVE_SEND_MAIL = gql`
  mutation startJobSaveSendMail(
    $jobAction: SaveSendMailJobMode!
    $mail: InputEMail!
    $mailRelation: InputEMailRelation
    $mailServiceId: ID
    $senderMailAddress: String
    $attachToEntityType: EMailAttachToEntityType
    $attachToEntityPK: PrimaryKey
  ) {
    startJobSaveSendMail(
      jobAction: $jobAction
      mail: $mail
      mailRelation: $mailRelation
      mailServiceId: $mailServiceId
      senderMailAddress: $senderMailAddress
      attachToEntityType: $attachToEntityType
      attachToEntityPK: $attachToEntityPK
    ) {
      id
      createdMail {
        ${prepareResponse(Entities.eMail, emailRequestData)}
      }
    }
  }
`;

const templateIdAccessor = 'templateId';
const actionModeAccessor = 'mode';
const basedon = 'basedon';
const emailTemplateAccessor = 'emailTemplate';
const ticketTemplateContextAccessor = 'ticketTemplateContext';
const senderAddress = 'senderAddress';
const tempFileAttachements = 'tempFileAttachements';
const receivers = 'receivers';
const forceNoTemplate = 'forceNoTemplate';
const eMailTemplateKind = 'eMailTemplateKind';
const processedMailTemplateArgs = 'processedMailTemplateArgs';

export type EmailMaskCustomParams =
  | 'tab'
  | 'overrideRecipients'
  | typeof templateIdAccessor
  | typeof actionModeAccessor
  | typeof basedon
  | typeof ticketTemplateContextAccessor
  | typeof emailTemplateAccessor
  | typeof senderAddress
  | typeof tempFileAttachements
  | typeof receivers
  | typeof forceNoTemplate
  | typeof eMailTemplateKind
  | typeof processedMailTemplateArgs;

export const EmailOverlayController = (
  props: MaskControllerProps<EmailMaskCustomParams>
) => {
  const { emit: emitEntityEvent } = useEntityEventsContext();

  const { t } = useTranslation();
  const mask = useMaskConfig(props);
  const { params } = mask;
  const {
    basedon,
    tempFileAttachements,
    processedMailTemplateArgs,
    forceNoTemplate,
  } = params;

  const bt = useBinaryTransfer();

  const user = useUser();

  const { templateId: previousEmailId = null, mode: actionMode = null } =
    mask.params;

  const clickedOnSend = useRef(false);
  const [startJobSaveSendMail] = useMutation<{
    startJobSaveSendMail: SaveSendMailJob;
  }>(START_JOB_SAVE_SEND_MAIL);

  const [isTemplateInitialized, setTemplateInitialized] = useState(false);

  const { saveSearchItemFromEnityData } = useSearchHistory();
  const [upsert] = useDataMutation<EMail, EMode.upsert, InputEMailRelation>({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: emailRequestData as unknown as EMail<EMode.entity>,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }
    },
  });

  const previousEmailRequest = useMemo<DataRequest>(() => {
    const filter = [{ id: { $eq: previousEmailId } }];
    let data = emailRequestData;

    if (actionMode === EMAILMODES.forward) {
      data = commonEmailRequestData;
    }

    return {
      operationName: 'GetPreviousEmail',
      entity: Entities.eMail,
      data,
      filter,
      vars: emailRequestVars,
    };
  }, [actionMode, previousEmailId]);

  const previousEmail = useDataProvider<EMail>(
    previousEmailRequest,
    previousEmailId == null
  );

  const template = useAssignableTemplateEntity(mask);
  const activeTicketContext: Ticket = useMemo(() => {
    if (params[ticketTemplateContextAccessor]) {
      return JSON.parse(params[ticketTemplateContextAccessor]);
    }
    return null;
  }, [params]);

  const maskInit = useEmailMaskInit({
    mask,
    template,
    activeTicketContext,
    previousEmail,
    additionalTemplateVariables: processedMailTemplateArgs
      ? JSON.parse(processedMailTemplateArgs)
      : undefined,
  });

  const data = maskInit.values;

  const overrideRecipients = props.params?.overrideRecipients;
  if (overrideRecipients) {
    data.to = overrideRecipients;
    data.toMailContactsInstance = overrideRecipients.split(';').map((mail) => {
      return {
        id: 'Email_' + mail.trim(),
        mailAddress: mail.trim(),
      };
    });
  }

  const customRules = useCallback(
    (data: EmailMaskFormValue) => {
      if (!clickedOnSend.current) {
        return true;
      }
      if (!data.from) {
        return {
          from: {
            message: t('ERROR.NO_EMAIL_SENDER'),
            type: 'customValidation',
          },
        };
      }
      const recipient = data.to;
      if (recipient == null || recipient.length === 0) {
        return {
          to: {
            message: t('ERROR.MIN_ONE_RECIPIENT'),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [t]
  );

  const schema = useEntityJsonSchema(mask.entity);
  const resolver = useJSONSchemaResolver(schema, customRules);

  const form = useFormPlus<EmailMaskFormValue>({
    resolver,
    mode: 'onChange',
    defaultValues: data,
    shouldFocusError: false,
    context: {
      schema,
    },
  });

  const lock = useLock();

  useEffect(() => {
    if (!lock.locked) {
      maskInit.refresh();
    }
    // Should only be called when the current mask acquires the lock.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lock.locked]);

  const { handleSubmit, reset, formState, resetField } = form;

  useEffect(() => {
    if (!isTemplateInitialized) {
      reset(data);
    } else {
      Object.entries(data).forEach((x) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        resetField(x[0] as any, { defaultValue: x[1] });
      });
    }
  }, [reset, data, isTemplateInitialized, resetField]);

  useSyncEmailStatus({
    disabled: mask.isCreateMode,
    shouldSync({ id }) {
      // If it's the currently opened email and the send job is either in
      // progress or failed.
      return id === data.id && data.saveSendMailJob != null;
    },
  });

  const isDirty = Object.keys(formState.dirtyFields).length > 0;

  useConfirmBeforeCloseMask(isDirty);

  useEmailFormUpdate(form, mask);

  const entityName = t(`COMMON.${mask.entity.toUpperCase()}`);
  const maskTitle = formatPageTitle([entityName, data?.subject]);

  usePageTitle(maskTitle, { priority: 1 });

  const isDraft =
    data?.kind === EMailKind.ENTWURF || data?.kind === EMailKind.ENTWURF_HTML;

  const handleDeleteEntitiesClick = useStandardDeleteEntityHandler(mask);

  // All existing attachments should be copied when using "Send again" or
  // "Forward".
  const [shouldCopyAttachments, setShouldCopyAttachments] = useState(
    actionMode === EMAILMODES.sendAgain || actionMode === EMAILMODES.forward
  );

  const cleanedPersistantAttachmentList = useMemo(() => {
    if (shouldCopyAttachments) return [];

    return [
      ...(data?.attachmentList?.map((x) => ({
        ...x,
        fileName: x.displayFilename,
        __typename: undefined,
      })) || []),
    ];
  }, [data?.attachmentList, shouldCopyAttachments]);

  const tempFileManager = useTempFileManager(cleanedPersistantAttachmentList, {
    maxAttachmentTotalSize: 50 * 1024 * 1024,
  });

  const currentTopicMarkList = useWatch({
    name: 'topicMarkList',
    control: form.control,
  });

  useEffect(() => {
    // The mask can only be initialized once. If init is not null, we don't need
    // to do anything.

    if (basedon === 'transfer' && bt.transferData) {
      const files = bt.transferData;

      if (!(files?.length > 0)) {
        console.warn('No files were found in the BinaryTransfer');
        return;
      }

      tempFileManager.resetChanges();
      tempFileManager.uploadFiles(files);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Copy existing attachments.
  const { convertAttachmentsEntity, convertAttachmentsIds } = useMemo(() => {
    if (
      data?.attachmentList &&
      data.attachmentList.length > 0 &&
      shouldCopyAttachments
    ) {
      return {
        convertAttachmentsEntity: Entities.eMail,
        convertAttachmentsIds: data.attachmentList.map((attachment) =>
          attachment.id.toString()
        ),
      };
    }

    if (template?.entity === Entities.document && template?.data) {
      const childDocuments =
        template?.data?.childDocuments?.map((doc) => doc.id.toString()) ?? [];

      return {
        convertAttachmentsEntity: Entities.document,
        convertAttachmentsIds: [template.data.id.toString(), ...childDocuments],
      };
    }

    if (template?.entity === Entities.letter && template?.data) {
      return {
        convertAttachmentsEntity: Entities.letter,
        convertAttachmentsIds: [template.data.id.toString()],
      };
    }

    return { convertAttachmentsEntity: null, convertAttachmentsIds: [] };
  }, [data?.attachmentList, shouldCopyAttachments, template]);

  const convertedAttachments = useConvertAttachments(
    convertAttachmentsEntity,
    convertAttachmentsIds,
    !shouldCopyAttachments
  );

  useEffect(() => {
    const forwardedTempFiles = tempFileAttachements
      ? (JSON.parse(tempFileAttachements) as IAttachmentEntity[])
      : [];
    if (forwardedTempFiles.length)
      tempFileManager.setTemporaryFileUploads(forwardedTempFiles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFileAttachements]);

  useEffect(() => {
    const forwardedTempFiles = tempFileAttachements
      ? (JSON.parse(tempFileAttachements) as IAttachmentEntity[])
      : [];
    if (forwardedTempFiles.length)
      tempFileManager.setTemporaryFileUploads([
        ...forwardedTempFiles,
        ...convertedAttachments,
      ]);
    else tempFileManager.setTemporaryFileUploads(convertedAttachments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertedAttachments]);

  const relations = useMemo(() => {
    //email attachments can not be updated (e.g. renamed) just be added or removed
    let reassignedAttachemnts = [];
    if (mask.isCreateMode && shouldCopyAttachments) {
      /**
       * in case of forward email attachments are "copied" too and need to result in another relation param
       * therefore find the ones that are acutial email attachments and not tempfiles already
       */
      reassignedAttachemnts = tempFileManager.fileList?.filter((el) => {
        return el.__typename === 'EMailAnhang';
      });
    }

    const hasAttachmentToAdd =
      tempFileManager.temporaryFileUploads.length > 0 ||
      reassignedAttachemnts.length > 0;
    const hasAttachmentToRemove = tempFileManager.fileIdsToDelete.length > 0;

    const attachements: InputEMailAnhangAttachementsRelation = {
      add: hasAttachmentToAdd
        ? [
            ...tempFileManager.temporaryFileUploads.map((x) => ({
              tempFileId: x.id as string,
              name: x.fileName,
            })),
            ...reassignedAttachemnts.map((x) => ({
              eMailAttachementCode: x.id,
            })),
          ]
        : undefined,
      remove: hasAttachmentToRemove
        ? (tempFileManager.fileIdsToDelete as number[])
        : undefined,
    };
    const topic: InputTopicMarkRelation = {
      set: currentTopicMarkList ? currentTopicMarkList[0]?.id : 0,
    };

    const result = { attachements, topic };
    return result;
  }, [
    currentTopicMarkList,
    mask.isCreateMode,
    shouldCopyAttachments,
    tempFileManager.fileIdsToDelete,
    tempFileManager.fileList,
    tempFileManager.temporaryFileUploads,
  ]);

  const afterSaveOrSend = useCallback(async () => {
    props.onAfterSave?.(data);
  }, [data, props]);

  const onSubmit = async (_data: EmailMaskFormValue) => {
    const data = {
      ..._data,
    };

    data.date = new Date().toISOString();

    if (mask.isCreateMode && activeTicketContext) {
      //enhance email body with hidden fields to keep ticket context
      //todo should we send the context of all template based emails? if so activeTicketContext > template
      data.bodyHtml += `
      <div style="display:none;" id="w4a-ticket${user.kundennummer}|${
        activeTicketContext.id
      }">&nbsp;</div>
      ${
        activeTicketContext.project &&
        activeTicketContext.project.id !== undefined
          ? `<div style="display:none;" id="w4a-project${user.kundennummer}|${activeTicketContext.project?.id}">&nbsp;</div>`
          : ''
      }
      `;
    }

    const update = mask.isCreateMode
      ? data
      : pick(data, ['id', ...Object.keys(formState.dirtyFields)]);

    if (!update.userId) {
      update.userId = user.benutzerCode;
    }

    if (update.bodyHtml != null) {
      update.bodyHtml = removeHtmlComments(update.bodyHtml);
      update.bodyHtml = htmlParser.applyInlineStyles(update.bodyHtml);
      update.bodyHtml = htmlParser.removeTextmarkStyle(update.bodyHtml);
    }
    const saved = await upsert(update, relations ? { relations } : undefined);

    if (!saved) {
      throw new Error('upsertEmail failed');
    }

    if (clickedOnSend.current) {
      const attachToEntityType =
        activeTicketContext?.__typename === 'Ticket'
          ? EMailAttachToEntityType.TICKET
          : EMailAttachToEntityType.NONE;

      startJobSaveSendMail({
        variables: {
          mail: { code: saved.id },
          senderMailAddress: data?.from,
          jobAction: SaveSendMailJobMode.SAVE_AND_SEND,
          attachToEntityType,
          attachToEntityPK: activeTicketContext?.id,
        },
      });
    }

    emitEntityEvent({
      type: 'upsert',
      entity: Entities.eMail,
      data: undefined,
    });

    afterSaveOrSend();
  };

  const entityRights = useMemo(
    () => ({
      create: canAddEmail(user),
      read: false, //todo needed at all?
      update: canEditEmail(user, data as unknown as EMail),
      delete: canDeleteEmail(user, data as unknown as EMail),
    }),
    [data, user]
  );

  const commonActions = (
    <>
      <MaskOverlayFullscreenToggleButton />

      {mask.isEditMode && (
        <MaskOverlayDeleteButton
          disabled={!entityRights.delete || mask.wip}
          onClick={handleDeleteEntitiesClick}
        />
      )}
    </>
  );

  const maskContext = useMaskContextValue({
    ...mask,
    data,
    isLoading: maskInit.isLoading,
  });

  const draftDisabled =
    !entityRights.create || //user cant create a draft
    !entityRights.update ||
    mask.wip; //user cant update this draft (may not be their own)
  const draftDisabledReason = draftDisabled ? t('RIGHTS.MISSING') : undefined;

  const [isFroalaUploadingImage, setIsFroalaUploadingImage] = useState(false);

  const draftActions = (
    <>
      <AttachmentsUploadButton
        disabled={draftDisabled}
        disabledReason={draftDisabledReason}
      />

      <MaskActionButton
        type="submit"
        labelVisibility={LABEL_VISIBILITY.HIDE}
        label={t('MASK.SAVE_AS_DRAFT')}
        icon={<SaveIcon />}
        color="primary"
        onClick={() => (clickedOnSend.current = false)}
        disabledReason={draftDisabledReason}
        disabled={
          draftDisabled ||
          formState.isSubmitting ||
          tempFileManager.currentUploadingFiles > 0 ||
          (!formState.isDirty && !tempFileManager.fileListDirty)
        }
      />

      <MaskActionButton
        labelVisibility={LABEL_VISIBILITY.SHOW}
        disabledReason={draftDisabledReason}
        type="submit"
        variant="contained"
        color="primary"
        label={t('MASK.SEND')}
        icon={<SendIcon />}
        onClick={() => {
          clickedOnSend.current = true;
        }}
        disabled={
          isFroalaUploadingImage ||
          draftDisabled ||
          formState.isSubmitting ||
          tempFileManager.currentUploadingFiles > 0 ||
          (data.kind !== EMailKind.ENTWURF &&
            data.kind !== EMailKind.ENTWURF_HTML &&
            !formState.isDirty) ||
          (!formState.isValid && formState.isSubmitted)
        }
      />

      {commonActions}
    </>
  );

  const editActions = (
    <>
      {!formState.isSubmitting && (
        <EmailActions
          hasRight={entityRights.create}
          id={data?.id}
          outbound={data?.kind === 'AUSGEHEND' ? true : false}
        />
      )}

      <MaskOverlaySubmitButton
        disableReason={!entityRights.update ? t('RIGHTS.MISSING') : undefined}
        disabled={
          !entityRights.update ||
          mask.wip ||
          formState.isSubmitting ||
          !formState.isDirty ||
          !formState.isValid
        }
      />

      {commonActions}
    </>
  );

  const businessPartnerLanguageId =
    data?.businessPartner?.data?.languageId ?? 0;

  const emailTemplaterDefaults = useMemo(() => {
    let additionalTemplateVariables = processedMailTemplateArgs
      ? JSON.parse(processedMailTemplateArgs)
      : undefined;

    if (basedon !== 'EmailTemplate') {
      return { ...additionalTemplateVariables };
    }

    if (activeTicketContext) {
      additionalTemplateVariables = {
        ticketId: activeTicketContext?.id,
        ...additionalTemplateVariables,
      };
    }

    const initialTemplate: EMailTemplate = params?.[emailTemplateAccessor]
      ? (JSON.parse(params[emailTemplateAccessor]) as EMailTemplate)
      : null;

    const initialSignature = params?.[emailTemplateAccessor]
      ? (JSON.parse(params[emailTemplateAccessor]) as EMailTemplate)?.signature
      : null;

    return {
      additionalTemplateVariables,
      initialTemplate,
      initialSignature,
    };
  }, [processedMailTemplateArgs, basedon, activeTicketContext, params]);

  const [isCopyAttachmentsDialogOpen, setIsCopyAttachmentsDialogOpen] =
    useState(false);

  useEffect(() => {
    setIsCopyAttachmentsDialogOpen(
      convertAttachmentsEntity === Entities.document ||
        convertAttachmentsEntity === Entities.letter
    );
  }, [convertAttachmentsEntity]);

  return (
    <MaskContextProvider value={maskContext}>
      <EntityRightsContext.Provider value={entityRights}>
        <FormProvider {...form}>
          <TempFileManagerContext.Provider value={tempFileManager}>
            <EmailTemplater
              forceNoTemplate={forceNoTemplate === 'true'}
              disabled={!isDraft && !mask.isCreateMode}
              isCreateMode={mask.isCreateMode}
              isDraftMode={isDraft}
              eMailMode={actionMode}
              previousEMail={previousEmail?.data?.[0]}
              preloadDone={!maskInit.isLoading}
              onInitialized={() => setTemplateInitialized(true)}
              languageId={businessPartnerLanguageId ?? 0}
              {...emailTemplaterDefaults}
            >
              <MaskTabContext defaultValue={mask.params?.tab ?? 'general'}>
                <Form
                  className={styles.maskForm}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <MaskOverlayHeader
                    title={entityName}
                    actions={isDraft ? draftActions : editActions}
                    tabs={<EmailTabs isCreateMode={mask.isCreateMode} />}
                  />

                  <CopyAttachmentsDialog
                    open={isCopyAttachmentsDialogOpen}
                    onConfirm={() => {
                      setShouldCopyAttachments(true);
                      setIsCopyAttachmentsDialogOpen(false);
                    }}
                    onCancel={() => {
                      setIsCopyAttachmentsDialogOpen(false);
                    }}
                  />

                  {data.saveSendMailJob != null && (
                    <EmailStatus
                      kind={data.kind}
                      saveSendMailJob={data.saveSendMailJob}
                      options={{
                        iconOnly: false,
                        errorAction: () => {
                          clickedOnSend.current = true;
                          handleSubmit(onSubmit)();
                        },
                      }}
                    />
                  )}

                  <Content
                    setIsFroalaUploadingImage={setIsFroalaUploadingImage}
                  />
                </Form>
              </MaskTabContext>
            </EmailTemplater>
          </TempFileManagerContext.Provider>
        </FormProvider>
      </EntityRightsContext.Provider>
    </MaskContextProvider>
  );
};

const EmailTabs = memo(function EmailTabs({
  isCreateMode,
}: {
  isCreateMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')}></MaskTab>

      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
    </MaskTabs>
  );
});

const Content = memo(function EmailTabPanels({
  setIsFroalaUploadingImage,
}: {
  setIsFroalaUploadingImage: Dispatch<SetStateAction<boolean>>;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel
          setIsFroalaUploadingImage={setIsFroalaUploadingImage}
        />
      </MaskTabPanel>

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});
