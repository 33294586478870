import styles from './CustomListEntityPicker.module.scss';

import { ListItem } from '@mui/material';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

interface HeaderRowProps {
  content: ReactNode;
}

export const HeaderRow: FC<HeaderRowProps> = ({ content }) => {
  return (
    <ListItem className={clsx(styles.row, styles.headerRow, styles.muted)}>
      {content}
    </ListItem>
  );
};
