import styles from './Security.module.scss';

import { gql, useMutation } from '@apollo/client';
import { Email, GppGood, PhoneAndroid } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { useDialogs } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useDataProvider, useUser } from '@work4all/data';
import { logoutUser } from '@work4all/data/lib/actions/user-actions';

import { InputSetMfaMode } from '@work4all/models/lib/Classes/InputSetMfaMode.entity';
import { SetMfaModeResponse } from '@work4all/models/lib/Classes/SetMfaModeResponse.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MfaMode } from '@work4all/models/lib/Enums/MfaMode.enum';

import { usePasswordChangeDialog } from '../../components/PasswordChangeDialog/use-password-change-dialog';

import { DisableConfirmDialog } from './DisableConfirmDialog';
import { Force2FACheckbox } from './Force2FACheckbox';
import { MfaConfirmDialog, MfaConfirmDialogProps } from './MfaConfirmDialog';

const SET_TOKEN_MFA_AUTH = gql`
  mutation setTokenMfaAuth($input: InputSetMfaMode!) {
    updateUserMfaMode(input: $input) {
      ok
      errorMessage
      qrCodeBase64
      secret
    }
  }
`;

export function Security() {
  const { t } = useTranslation();

  const dialogs = useDialogs();

  const user = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const passwordDialog = usePasswordChangeDialog();

  const mfa = useMfa({ userId: user.benutzerCode });
  const { activateMfa } = mfa;

  const userReq = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      filter: [
        {
          id: {
            $eq: user.benutzerCode,
          },
        },
      ],
      data: {
        id: null,
        mfaMode: null,
      } as User,
    };
  }, [user.benutzerCode]);

  const { data: userData } = useDataProvider<User>(userReq);

  const currentMfaMode = userData?.[0]?.mfaMode;

  const dispatch = useDispatch();

  const handleDisableAuth = useCallback(async () => {
    const onDisableConfirmDialogClose = async (success: boolean) => {
      if (success) {
        await activateMfa(MfaMode.NORMAL);
        /**
         * User is logged out due to token invalidation
         */
        dispatch(logoutUser());
      }

      dialogs.close(disableConfirmDialogId);
    };
    const { id: disableConfirmDialogId } = dialogs.open(DisableConfirmDialog, {
      onClose: onDisableConfirmDialogClose,
    });
  }, [activateMfa, dispatch]);

  const handleManagePassword = async () => {
    const passwordChanged = await passwordDialog();
    // if success
    if (passwordChanged) {
      enqueueSnackbar(t('ALERTS.PASSWORD_CHANGED'), {
        variant: 'success',
        autoHideDuration: 1000,
      });
      setTimeout(async () => {
        /**
         * User is logged out due to token invalidation
         */
        dispatch(logoutUser());
      }, 1000);
    }
  };

  return (
    <>
      <Box sx={{ padding: '1rem' }}>
        <Stack alignItems="baseline" gap="1rem" width="100%">
          <Divider title={t('MFA.TITLE')} style={{ width: '100%' }} />
          <Stack
            direction="row"
            gap="0.25rem"
            className={clsx({
              [styles.success]:
                currentMfaMode && currentMfaMode !== MfaMode.NORMAL,
            })}
          >
            {currentMfaMode && currentMfaMode !== MfaMode.NORMAL ? (
              <Box paddingLeft="0.5rem">
                <GppGood />
              </Box>
            ) : null}
            <Typography
              className={clsx({
                [styles.success]:
                  currentMfaMode && currentMfaMode !== MfaMode.NORMAL,
              })}
            >
              {t(
                !currentMfaMode || currentMfaMode === MfaMode.NORMAL
                  ? 'MFA.DESC'
                  : 'MFA.DESC_SUCCESS'
              )}
              {currentMfaMode !== MfaMode.NORMAL &&
                ': ' +
                  t(
                    currentMfaMode === MfaMode.MAIL
                      ? 'MFA.ACTIVATE_EMAIL'
                      : 'MFA.ACTIVATE_AUTH_APP'
                  )}
            </Typography>
          </Stack>
          {currentMfaMode === MfaMode.NORMAL ? (
            <MfaButtons currentMfaMode={currentMfaMode} {...mfa} />
          ) : null}
          {currentMfaMode !== MfaMode.NORMAL && (
            <Stack direction="row" alignItems="center" gap="2rem">
              <BaseActionButton
                onClick={() =>
                  activateMfa(
                    currentMfaMode === MfaMode.MAIL
                      ? MfaMode.TOKEN
                      : MfaMode.MAIL
                  )
                }
                icon={
                  currentMfaMode === MfaMode.MAIL ? <PhoneAndroid /> : <Email />
                }
              >
                {t('MFA.SWITCH_TO')}:&nbsp;
                {t(
                  currentMfaMode === MfaMode.MAIL
                    ? 'MFA.ACTIVATE_AUTH_APP'
                    : 'MFA.ACTIVATE_EMAIL'
                )}
              </BaseActionButton>
              <BaseActionButton onClick={handleDisableAuth}>
                {t('MFA.DEACTIVATE')}
              </BaseActionButton>
            </Stack>
          )}
        </Stack>
      </Box>

      {user.isMaster && (
        <Box sx={{ padding: '1rem' }}>
          <Divider title={t('MORE.ADMINISTRATION')} style={{ width: '100%' }} />
          <Force2FACheckbox />
        </Box>
      )}

      <Box sx={{ paddingY: '1rem', paddingRight: '1rem' }}>
        <Divider
          title={t('USER_MENU_SECTION.SETTINGS.PASSWORD_SECTION')}
          style={{ width: '100%', paddingLeft: '1rem' }}
        />
        <List sx={{ width: '100%', paddingTop: '0.5rem' }}>
          <ListItem disablePadding>
            <ListItemButton onClick={handleManagePassword}>
              <ListItemText primary={t('USER.MANAGE_PASSWORD')} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );
}

export const useMfa = ({ userId }: { userId: number }) => {
  const [mfaMode, setMfaMode] = useState<MfaMode>(null);
  const [inProgress, setInProgress] = useState<MfaMode>(null);
  const [mfaConfirmDialogOpen, setMfaConfirmDialogOpen] = useState(false);
  const [mfaUpdateResult, setMfaUpdateResult] =
    useState<SetMfaModeResponse>(null);
  const [mutate] = useMutation<
    { updateUserMfaMode: SetMfaModeResponse },
    { input: InputSetMfaMode }
  >(SET_TOKEN_MFA_AUTH);

  const activateMfa = useCallback(
    async (mfaMode: MfaMode) => {
      setInProgress(mfaMode);
      const res = await mutate({
        variables: {
          input: {
            mfaMode,
            userCode: userId,
          } as InputSetMfaMode,
        },
      });
      setInProgress(null);

      setMfaUpdateResult(res.data.updateUserMfaMode);

      if (res?.data?.updateUserMfaMode?.ok) {
        setMfaMode(mfaMode);
        if (mfaMode !== MfaMode.NORMAL) {
          setMfaConfirmDialogOpen(true);
        }
      }
    },
    [mutate, userId]
  );

  return {
    mfaMode,
    inProgress,
    mfaConfirmDialogOpen,
    mfaUpdateResult,
    activateMfa,
    setMfaConfirmDialogOpen,
  };
};

interface MfaButtonsProps extends ReturnType<typeof useMfa> {
  currentMfaMode: MfaMode;
  validate?: MfaConfirmDialogProps['validate'];
  onClose?: MfaConfirmDialogProps['onClose'];
}

export const MfaButtons = (props: MfaButtonsProps) => {
  const {
    mfaMode,
    currentMfaMode,
    activateMfa,
    inProgress,
    mfaUpdateResult,
    mfaConfirmDialogOpen,
    setMfaConfirmDialogOpen,
    validate,
    onClose,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleAuthValidation = useCallback(
    (success: boolean) => {
      setMfaConfirmDialogOpen(false);

      if (success === false) {
        enqueueSnackbar(t('MFA.VALIDATION_FAILED'), {
          variant: 'error',
          autoHideDuration: 3000,
        });
      }
      if (success) {
        /**
         * User is logged out due to token invalidation
         */
        dispatch(logoutUser());
      }
    },
    [dispatch, enqueueSnackbar, t]
  );

  return (
    <>
      <MfaConfirmDialog
        open={mfaConfirmDialogOpen}
        onClose={onClose ? onClose : handleAuthValidation}
        qrCode={mfaUpdateResult?.qrCodeBase64}
        secret={mfaUpdateResult?.secret}
        mfaMode={mfaMode}
        validate={validate}
      />
      <ToggleButtonGroup
        value={currentMfaMode}
        exclusive
        sx={{ flex: 'none', width: '100%' }}
      >
        <ToggleButton
          value={MfaMode.TOKEN}
          onClick={() => activateMfa(MfaMode.TOKEN)}
          sx={{ width: '100%', display: 'flex', gap: '0.25rem' }}
          disabled={inProgress !== null}
        >
          {inProgress === MfaMode.TOKEN ? (
            <CircularProgress size="1.25rem" />
          ) : (
            <PhoneAndroid />
          )}
          {t('MFA.ACTIVATE_AUTH_APP')}
        </ToggleButton>
        <ToggleButton
          value={MfaMode.MAIL}
          onClick={() => activateMfa(MfaMode.MAIL)}
          sx={{ width: '100%', display: 'flex', gap: '0.25rem' }}
          disabled={inProgress !== null}
        >
          {inProgress === MfaMode.MAIL ? (
            <CircularProgress size="1.25rem" />
          ) : (
            <Email />
          )}
          {t('MFA.ACTIVATE_EMAIL')}
        </ToggleButton>
      </ToggleButtonGroup>
    </>
  );
};
