import { ProjectProcessColumn, ProjectProcessColumnType } from './types';

export const projectProcessColumnLabelTKey: Record<
  ProjectProcessColumnType,
  string
> = {
  [ProjectProcessColumnType.Description]: 'PROJECT_PROCESS.DESCRIPTION',
  [ProjectProcessColumnType.StartDate]: 'PROJECT_PROCESS.START',
  [ProjectProcessColumnType.EndDate]: 'PROJECT_PROCESS.END',
  [ProjectProcessColumnType.Duration]: 'PROJECT_PROCESS.DURATION',
  [ProjectProcessColumnType.Progress]: 'PROJECT_PROCESS.PROGRESS',
  [ProjectProcessColumnType.Resource]: 'PROJECT_PROCESS.RESOURCE',
  [ProjectProcessColumnType.TargetValue]: 'PROJECT_PROCESS.TARGET_VALUE',
  [ProjectProcessColumnType.ActualValue]: 'COMMON.PLANNING.PLANNED.SHORT',
  [ProjectProcessColumnType.Difference]: 'COMMON.PLANNING.REST.SHORT',
};

export const DEFAULT_LEFT_AREA_COLS: ProjectProcessColumn[] = [
  {
    type: ProjectProcessColumnType.Description,
    hidden: false,
    width: 250,
  },
  {
    type: ProjectProcessColumnType.StartDate,
    hidden: false,
    width: 70,
  },
  {
    type: ProjectProcessColumnType.EndDate,
    hidden: false,
    width: 70,
  },
  {
    type: ProjectProcessColumnType.Duration,
    hidden: false,
    width: 50,
  },
  {
    type: ProjectProcessColumnType.TargetValue,
    hidden: true,
    width: 70,
  },
  {
    type: ProjectProcessColumnType.ActualValue,
    hidden: true,
    width: 70,
  },
  {
    type: ProjectProcessColumnType.Difference,
    hidden: true,
    width: 70,
  },
  {
    type: ProjectProcessColumnType.Progress,
    hidden: true,
    width: 70,
  },
  {
    type: ProjectProcessColumnType.Resource,
    hidden: true,
    width: 120,
  },
];

const TYPE_LIST = DEFAULT_LEFT_AREA_COLS.map((x) => x.type);

export const mergePersistedProjectProcessCols = (
  cols: ProjectProcessColumn[]
) => {
  const colTypes = cols.map((x) => x.type);
  const cleanPersitedCols = cols.filter((x) => TYPE_LIST.includes(x.type));
  const additionalCols = DEFAULT_LEFT_AREA_COLS.filter(
    (x) => !colTypes.includes(x.type)
  ).map((x) => ({ ...x, hidden: true }));
  return [...cleanPersitedCols, ...additionalCols];
};
