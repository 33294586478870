import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import InfoIcon from '@mui/icons-material/Info';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as NoteIcon } from '@work4all/assets/icons/note.svg';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useReleaseNotesDialogContext } from '../../../../contexts/ReleaseNotesDialogContext';
import { DebugInfoDialog } from '../../components/DebugInfoDialog/DebugInfoDialog';
import { Design } from '../Design';
import { DevTools } from '../DevTools/DevTools';
import { ExchangeSynchronization } from '../exchange-synchronization';
import { Firm } from '../Firm/Firm';
import { HomeScreen } from '../HomeScreen';
import { Security } from '../security/Security';

export const Settings = () => {
  const { t } = useTranslation();

  const [isDebugInfoDialogOpen, setDebugInfoDialogOpen] = useState(false);
  const { releaseNotesFile, version, openReleaseNotesDialog } =
    useReleaseNotesDialogContext();

  const { go } = useHistoryStack();

  return (
    <List>
      <Divider style={{ padding: '1rem 1rem 1rem 0' }}>
        {t('USER_MENU_SECTION.SETTINGS')}
      </Divider>

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            go({
              title: t('USER.DESIGN'),
              view: <Design />,
            });
          }}
        >
          <ListItemText primary={t('USER.DESIGN')} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            go({
              title: t('INPUTS.FIRM'),
              view: <Firm />,
            });
          }}
        >
          <ListItemText primary={t('INPUTS.FIRM')} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            go({
              title: t('USER.HOME_SCREEN'),
              view: <HomeScreen />,
            });
          }}
        >
          <ListItemText primary={t('USER.HOME_SCREEN')} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            go({
              title: t('USER.SECURITY'),
              view: <Security />,
            });
          }}
        >
          <ListItemText primary={t('USER.SECURITY')} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            go({
              title: t('USER.EXCHANGE_SYNCHRONIZATION'),
              view: <ExchangeSynchronization />,
            });
          }}
        >
          <ListItemText primary={t('USER.EXCHANGE_SYNCHRONIZATION')} />
        </ListItemButton>
      </ListItem>

      <Divider style={{ padding: '1rem 1rem 1rem 0' }}>
        {t('USER_MENU_SECTION.SUPPORT')}
      </Divider>

      <ListItem disablePadding>
        <ListItemButton
          disabled={!releaseNotesFile || !releaseNotesFile?.length}
          onClick={() => {
            openReleaseNotesDialog();
          }}
        >
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText primary={t('RELEASE_NOTES.title', { version })} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => {
            setDebugInfoDialogOpen(true);
          }}
        >
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary={t('DEBUG_INFO.TITLE')} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton
          onClick={() => go({ title: 'DevTools', view: <DevTools /> })}
        >
          <ListItemIcon>
            <DeveloperModeIcon />
          </ListItemIcon>
          <ListItemText primary={'DevTools'} />
        </ListItemButton>
      </ListItem>

      <DebugInfoDialog
        open={isDebugInfoDialogOpen}
        onClose={() => {
          setDebugInfoDialogOpen(false);
        }}
      />
    </List>
  );
};
