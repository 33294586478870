import type { UseNumberInputProps } from './use-number-input';

export function useNumberInputProps<T extends UseNumberInputProps>(
  props: T
): {
  numberInputProps: UseNumberInputProps;
  otherProps: Omit<T, keyof UseNumberInputProps>;
} {
  const {
    value,
    onChange,
    min,
    max,
    step,
    prefix,
    suffix,
    decimals,
    decimalSeparator,
    thousandSeparator,
    autoSelect,
    ...otherProps
  } = props;

  const numberInputProps: UseNumberInputProps = {
    value,
    onChange,
    min,
    max,
    step,
    prefix,
    suffix,
    decimals,
    decimalSeparator,
    thousandSeparator,
    autoSelect,
  };

  return { numberInputProps, otherProps };
}
