import styles from './statistics-styles.module.scss';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { StatisticSalesVolumeYear } from '@work4all/models/lib/Classes/StatisticSalesVolumeYear.entity';
import { StatisticSalesVolumeYearItem } from '@work4all/models/lib/Classes/StatisticSalesVolumeYearItem.entity';

import { BasicTable } from '../BasicTable';
import { BasicTableColumn, ICssClasses } from '../types';
import { CurrencyCell } from '../utils';

import { getStatisticFormatedDate } from './getStatisticFormatedDate';

interface StatisticSalesVolumeYearTableProps {
  data: StatisticSalesVolumeYear[];
  tableClasses?: ICssClasses;
}

const GetTotalSum = (yearItem: StatisticSalesVolumeYearItem[]) => {
  let result = 0;
  yearItem?.forEach((item: StatisticSalesVolumeYearItem) => {
    result += item.amount;
  });
  return result;
};

const GetTotalMonthValue = (
  data: StatisticSalesVolumeYear[],
  month: number
) => {
  let result = 0;

  data.forEach((row) => {
    result += row.items[month].amount;
  });

  return (
    <CurrencyCell
      className={clsx({ [styles.red]: result < 0 })}
      value={result}
      maximumFractionDigits={0}
      minimumFractionDigits={0}
      hideUnit={false}
    />
  );
};

const GetTotalTotalSumValue = (data: StatisticSalesVolumeYear[]) => {
  let result = 0;

  data.forEach((row) => {
    result += GetTotalSum(row.items);
  });

  return (
    <CurrencyCell
      className={clsx({ [styles.red]: result < 0 })}
      value={result}
      maximumFractionDigits={0}
      minimumFractionDigits={0}
      hideUnit={false}
    />
  );
};

export const StatisticSalesVolumeYearTable: React.FC<
  StatisticSalesVolumeYearTableProps
> = (props) => {
  const { data, tableClasses } = props;

  const { t } = useTranslation();

  const columns = useMemo<BasicTableColumn[]>(() => {
    const result: BasicTableColumn[] = [
      {
        Header: t('COMMON.YEAR'),
        accessor: 'start',
        Cell: ({ value }: { value: string }) => {
          return DateTime.fromJSDate(new Date(value)).toFormat('yyyy');
        },
        sticky: 'left',
        width: 70,
      },
    ];

    result.push({
      Header: t('COMMON.SUM'),
      accessor: 'items',
      Footer: GetTotalTotalSumValue(data),
      alignment: 'right',
      Cell: ({ value }: { value: StatisticSalesVolumeYearItem[] }) => {
        if (!value) {
          return '';
        }
        const displayValue = GetTotalSum(value);
        return (
          <CurrencyCell
            className={clsx({ [styles.red]: displayValue < 0 })}
            value={displayValue}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            hideUnit={true}
          />
        );
      },
      width: 130,
    });

    data?.[0]?.items?.forEach((item: StatisticSalesVolumeYearItem, index) => {
      result.push({
        Header: getStatisticFormatedDate(new Date(item.month), 'MMMM'),
        alignment: 'right',
        accessor: 'items[' + index + '].amount',
        Footer: GetTotalMonthValue(data, index),
        Cell: ({ value }: { value: number }) => {
          if (!value) {
            return '';
          }
          return (
            <CurrencyCell
              className={clsx({ [styles.red]: value < 0 })}
              value={parseInt(value.toFixed(0))}
              maximumFractionDigits={0}
              minimumFractionDigits={0}
              hideUnit={true}
            />
          );
        },
        width: 130,
      });
    });

    return result;
  }, [data, t]);

  const maxItems = data.length || 100;

  const apiRef = useRef<TableInstance>(null);

  return (
    data.length > 0 && (
      <BasicTable
        displayFooter={true}
        mode="client"
        ref={apiRef}
        reordableColumns={true}
        resizableColumns={false}
        selectableRows={true}
        data={data}
        columns={columns}
        allItemsCount={maxItems}
        cardsView={false}
        selectableMultiple={false}
        className={styles.table}
        classes={tableClasses}
      />
    )
  );
};
