import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const useUpdateSearchParams = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const updateSearchParams = (key: string, value?: number | null) => {
    const newParams = new URLSearchParams(searchParams);

    if (value != null && value !== undefined) {
      newParams.set(key, value.toString());
    } else {
      newParams.delete(key);
    }

    const search = newParams.toString();

    navigate({ pathname, search }, { replace: true });
  };

  return updateSearchParams;
};
