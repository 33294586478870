import styles from './TopBar.module.scss';

import { ArrowDropDown } from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  MenuItem,
  Theme,
  useMediaQuery,
} from '@mui/material';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '@work4all/assets/icons/outline-arrow-24.svg';
import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import {
  SelectableTree,
  TreeNode,
} from '@work4all/components/lib/dataDisplay/tree/SelectableTree';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import { useModuleRights, useNavigate } from '@work4all/data';

import { settings, useSetting } from '../../../../settings';
import { CheckboxRadioItem } from '../../../mask-overlays/locked-inputs';
import {
  getStatisticDefByStatisticType,
  statisticDefinitions,
  StatisticType,
} from '../../data/StatisticDefinitions';
import { useGenerateStatisticTreeNode } from '../../util/use-generate-statistic-tree-node';

export interface TopBarProps {
  showSettingdeliveryPeriod: boolean;
}

export function TopBar(props: TopBarProps) {
  const showSettingDeliveryPeriod = props.showSettingdeliveryPeriod;

  const { t } = useTranslation();
  const folderPickerRef = useRef(null);
  const statisticPickerRef = useRef(null);
  const contextMenuRef = useRef(null);

  const treeData: TreeNode[] = useGenerateStatisticTreeNode({
    definifion: statisticDefinitions,
  });
  const navigate = useNavigate();

  const [selectedTreeNodeId, setSelectedTreeNodeId] = useState<string>(null);
  const [statisticPickerOpen, setStatisticPickerOpen] =
    useState<boolean>(false);
  const [folderPickerOpen, setFolderPickerOpen] = useState<boolean>(false);
  const [contextMenuPickerOpen, setContextMenuOpen] = useState<boolean>(false);

  const isUpSm = useMediaQuery<Theme>((theme) => theme.breakpoints.up('sm'));

  const { statisticType } = useParams<{
    statisticType: StatisticType;
  }>();

  useEffect(() => {
    setSelectedTreeNodeId(statisticType);
  }, [statisticType]);

  const statisticDef = getStatisticDefByStatisticType(statisticType);

  const statisticConsiderInvoiceLedger = useSetting(
    settings.statisticConsiderInvoiceLedger()
  );

  const statisticUseDeliveryPeriod = useSetting(
    settings.statisticUseDeliveryPeriod()
  );

  const tablesLayoutBorders = useSetting(
    settings.tablesLayoutBordersStatistics()
  );

  const { rights } = useModuleRights();
  const canSeeOptions = !isTimeTrackingUser(rights);

  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
          className={styles.goBackButton}
          size="large"
        >
          <ArrowIcon />
        </IconButton>
        {isUpSm && (
          <>
            {t('MORE.STATISTICS')}
            <span className={styles.divider}>|</span>
          </>
        )}
        {statisticDef.parent && (
          <span
            className={clsx(styles.statisticSelector, {
              [styles.clickable]: canSeeOptions,
            })}
            onClick={
              canSeeOptions ? () => setFolderPickerOpen(true) : undefined
            }
            ref={folderPickerRef}
          >
            <span className={styles.statisticTitle}>
              {t('STATISTIC.' + statisticDef.parent.title)}
            </span>
            {canSeeOptions && <ArrowDropDown />}
            &nbsp;
          </span>
        )}
        <span
          className={clsx(styles.statisticSelector, {
            [styles.clickable]: canSeeOptions,
          })}
          onClick={
            statisticDef.parent.children.length > 1 && canSeeOptions
              ? () => setStatisticPickerOpen(true)
              : undefined
          }
          ref={statisticPickerRef}
        >
          <span className={styles.statisticTitle}>
            {t('STATISTIC.' + statisticDef.item.title)}
          </span>
          {statisticDef.parent.children.length > 1 && canSeeOptions && (
            <ArrowDropDown />
          )}
        </span>
      </div>

      <div className={styles.right} ref={contextMenuRef}>
        <IconButton
          onClick={() => {
            setContextMenuOpen(true);
          }}
        >
          <SettingsIcon />
        </IconButton>
      </div>

      <NavigationPopover
        open={folderPickerOpen}
        onClose={() => setFolderPickerOpen(false)}
        anchorEl={folderPickerRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        initialView={{
          view: (
            <Box p="0.5rem 0">
              <SelectableTree
                selectable="leaf"
                data={treeData}
                selected={selectedTreeNodeId}
                multiple={false}
                onChange={(nodeId) => {
                  setSelectedTreeNodeId(nodeId);
                  setFolderPickerOpen(false);
                  navigate(`/more/statistic/${nodeId}`);
                }}
              />
            </Box>
          ),
        }}
      />

      <NavigationPopover
        open={statisticPickerOpen}
        onClose={() => setStatisticPickerOpen(false)}
        anchorEl={statisticPickerRef.current}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
        initialView={{
          view: (
            <Box p="0.5rem 0">
              <SelectableTree
                selectable="leaf"
                data={useGenerateStatisticTreeNode({
                  definifion: statisticDef.parent.children,
                })}
                selected={selectedTreeNodeId}
                multiple={false}
                onChange={(nodeId) => {
                  setSelectedTreeNodeId(nodeId);
                  setStatisticPickerOpen(false);
                  navigate(`/more/statistic/${nodeId}`);
                }}
              />
            </Box>
          ),
        }}
      />

      <NavigationPopover
        open={contextMenuPickerOpen}
        onClose={() => setContextMenuOpen(false)}
        anchorEl={contextMenuRef.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        initialView={{
          view: (
            <List>
              <MenuItem>
                <CheckboxRadioItem
                  label={t('COMMON.INVOICE_LEDGER')}
                  checked={statisticConsiderInvoiceLedger.value}
                  onChange={(e) => {
                    statisticConsiderInvoiceLedger.set(e.target.checked);
                  }}
                />
              </MenuItem>
              {showSettingDeliveryPeriod && (
                <Tooltip title={t('STATISTIC.TOOLTIP.USEDELIVERYPERIOD')}>
                  <MenuItem>
                    <CheckboxRadioItem
                      label={t('SETTINGS.STATISTICS.USEDELIVERYPERIOD')}
                      checked={statisticUseDeliveryPeriod.value}
                      onChange={(e) => {
                        statisticUseDeliveryPeriod.set(e.target.checked);
                      }}
                    />
                  </MenuItem>
                </Tooltip>
              )}
              <Divider title={t('SETTINGS.TABLES.LAYOUT')} horizontalPadding />
              <MenuItem>
                <CheckboxRadioItem
                  label={t('SETTINGS.TABLES.LAYOUT.BORDERS.VERTICAL')}
                  checked={tablesLayoutBorders.value.vertical}
                  onChange={(e) =>
                    tablesLayoutBorders.set({
                      ...tablesLayoutBorders.value,
                      vertical: e.target.checked,
                    })
                  }
                />
              </MenuItem>

              <MenuItem>
                <CheckboxRadioItem
                  label={t('SETTINGS.TABLES.LAYOUT.BORDERS.HORIZONTAL')}
                  checked={tablesLayoutBorders.value.horizontal}
                  onChange={(e) =>
                    tablesLayoutBorders.set({
                      ...tablesLayoutBorders.value,
                      horizontal: e.target.checked,
                    })
                  }
                />
              </MenuItem>
            </List>
          ),
        }}
      />
    </div>
  );
}
