import { useSnackbar } from 'notistack';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDataMutation, useInaccessibleFields } from '@work4all/data';

import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { AccesorsOf } from '@work4all/utils/lib/paths-of/paths-of';

import { EntityPickerPopover } from '../../entity-picker/components';
import { useEntityPreview } from '../hooks/use-entity-preview';

import { InfoCard } from './info-card/InfoCard';
import { InfoCards } from './info-cards/InfoCards';
import { BeforeContentElement } from './preview-input/components/before-content-element/BeforeContentElement';
import { usePreviewInputsContext } from './preview-input/hooks';
import { PreviewInputsProvider } from './preview-input/providers/preview-inputs-provider/preview-inputs-provider';
import { PreviewInputsType } from './preview-input/types';

export interface PreviewContentField<T extends object> {
  accessor: AccesorsOf<T, 2>;
  label: string | ((entry: T) => string);
  entity?: Entities;
  disabled?: boolean;
  truncate?: boolean;
  formatter?: (field: T[keyof T], entry: T) => string;
  getPicker?: (props: GetPickerProps<T>) => JSX.Element;
  getData?: (entry: T) => object;
  isCommon?: (commonFields: string[]) => boolean;
  disabledReason?: string;
}

export interface PreviewContentProps<T extends object> {
  entity: Entities;
  entries: T[];
  fields: PreviewContentField<T>[];
  style?: React.CSSProperties;
  mutate?: <TInput extends object>(input: TInput, otherArgs) => Promise<void>;
}

export function PreviewContent<T extends object>(
  props: PreviewContentProps<T>
) {
  const { entity, fields, entries, style, mutate: mutateAll } = props;

  const [mutate] = useDataMutation<T, EMode.upsert>({
    entity,
    mutationType: EMode.upsert,
    responseData: { id: null } as unknown as T,
  });

  // TODO: is it even needed?
  const openPicker = useCallback(() => {}, []);
  const entityPreviewResult = useEntityPreview({
    subEntityType: entity,
    entries,
    openPicker,
    mutate,
    mutateAll,
  });
  const { isInaccessible } = useInaccessibleFields();

  const cards = entries[0]
    ? fields
        .filter((field) => !isInaccessible(entity, field.accessor))
        .map((field) => {
          return (
            <PickerCardInfo
              key={field.accessor}
              entity={entity}
              field={field}
              entry={entries[0]}
              entityPreviewResult={entityPreviewResult}
            />
          );
        })
    : null;

  return (
    <PreviewInputsProvider
      {...entityPreviewResult}
      entries={entries}
      entity={entity}
    >
      <InfoCards style={style}>{cards}</InfoCards>
    </PreviewInputsProvider>
  );
}

// TODO: hopefully this is only
interface GetPickerProps<T> {
  isCommon: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEdit: (field: any, otherArgs?: any) => Promise<void>;
  entry: T;
}

interface PickerCardInfo<T extends object> {
  field: PreviewContentField<T>;
  entityPreviewResult: PreviewInputsType;
  entry: T;
  entity: Entities;
}

export function PickerCardInfo<T extends object>(props: PickerCardInfo<T>) {
  const { field, entityPreviewResult, entry, entity } = props;
  const { handleLock, commonFields, isMultiselect, onPopoverClose, onEdit } =
    entityPreviewResult;
  const {
    getPicker,
    disabled = true,
    truncate = true,
    formatter,
    accessor: key,
    label,
    isCommon: isCommonFunc,
    disabledReason,
  } = field;

  const isCommon = isCommonFunc
    ? isCommonFunc(commonFields)
    : commonFields.includes(key);

  const { t } = useTranslation();
  const popoverRef = useRef();

  const { enqueueSnackbar } = useSnackbar();

  const cardInfoComponent = (
    <InfoCard
      key={key}
      disabled={disabled}
      truncate={truncate}
      label={typeof label === 'function' ? label(entry) : label}
      beforeContentElement={
        !isMultiselect && field.entity && field.getData ? (
          <BeforeContentElement
            previewEntity={entity}
            inputEntity={field.entity}
            accessor=""
            value={field.getData(entry)}
          />
        ) : undefined
      }
    >
      {isMultiselect && !isCommon
        ? `(${t('COMMON.MULTIPLE')})`
        : formatter
        ? formatter(getValueByPath(entry, key), entry)
        : (getValueByPath(entry, key) as string)}
    </InfoCard>
  );

  if (disabled && disabledReason) {
    return (
      <button
        onClick={() => {
          enqueueSnackbar(disabledReason, {
            variant: 'error',
            autoHideDuration: 5000,
          });
        }}
        style={{ all: 'unset' }}
      >
        {cardInfoComponent}
      </button>
    );
  }

  if (!getPicker) return cardInfoComponent;

  return (
    <EntityPickerPopover
      ref={popoverRef}
      disabled={disabled}
      onTargetClick={() => {
        handleLock(key);
      }}
      onClose={onPopoverClose}
      // TODO: better centrilize place for pickers like we have get controller / table
      picker={getPicker({ isCommon, onEdit, entry })}
    >
      {cardInfoComponent}
    </EntityPickerPopover>
  );
}

type PreviewInfoCardProps<T extends object> = PreviewContentField<T>;

export function PreviewInfoCard<T extends object>(
  props: PreviewInfoCardProps<T>
) {
  const entityPreviewResult = usePreviewInputsContext();
  return (
    <PickerCardInfo
      entity={entityPreviewResult.entity}
      entry={entityPreviewResult.entries[0] as T}
      field={props}
      entityPreviewResult={entityPreviewResult}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getValueByPath<T>(obj: T, path: string): any {
  return path.split('.').reduce((acc, key) => {
    if (acc && typeof acc === 'object') {
      return acc[key];
    }
    return undefined;
  }, obj);
}
