import styles from './UserPicker.module.scss';

import { Typography } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useMemo } from 'react';

import { User } from '@work4all/models/lib/Classes/User.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { UserClass } from '@work4all/models/lib/Enums/UserClass.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { HookedUserIcon } from '../../user-icon/useUserIconRegister';
import { EntityPicker } from '../components/v2/EntityPicker';
import { IPickerProps } from '../types';

export type IUserPickerProps<TMultiple extends boolean> = IPickerProps<
  User,
  TMultiple
> & {
  userClasses?: UserClass[];
  hasLeft?: boolean;
  vars?: Record<string, unknown>;
};

export function UserPicker<TMultiple extends boolean>(
  props: IUserPickerProps<TMultiple>
) {
  const {
    data,
    userClasses = DEFAULT_USER_CLASSES,
    hasLeft = false,
    prefilter: prefilterProp,
    ...otherProps
  } = props;

  const prefilter = useMemo(() => {
    return [
      { hasLeft: { $eq: hasLeft } },
      { $or: userClasses.map((uc) => ({ userKind: { $eq: uc } })) },
      ...(prefilterProp ?? []),
    ];
  }, [hasLeft, userClasses, prefilterProp]);

  const allFields = useMemo(() => {
    return { ...USER_FIELDS, ...data };
  }, [data]);

  function renderUser(user: User) {
    return <UserRow user={user} />;
  }

  return (
    <EntityPicker
      entity={Entities.user}
      data={allFields}
      filterBy="displayName"
      sortBy="displayName"
      layout={'advanced'}
      prefilter={prefilter}
      renderItemContent={renderUser}
      {...otherProps}
    />
  );
}

const DEFAULT_USER_CLASSES = [UserClass.BENUTZER, UserClass.MITARBEITER];

const USER_FIELDS: User = {
  id: null,
  displayName: null,
  shortName: null,
  firstName: null,
  lastName: null,
  hasLeft: null,
};

export function UserRow({ user }: { user: User }) {
  return (
    <div className={styles.listItem}>
      <ListItemAvatar>
        <HookedUserIcon userId={user.id} size="l" />
      </ListItemAvatar>

      <Tooltip title={user.displayName}>
        <Typography noWrap>{user.displayName}</Typography>
      </Tooltip>
    </div>
  );
}
