import { useTranslation } from 'react-i18next';

import { NumberInputBase, NumberInputBaseProps } from './NumberInputBase';

export interface NumberInputProps
  extends Omit<NumberInputBaseProps, 'thousandSeparator' | 'decimalSeparator'> {
  locale?: string;
}

export function NumberInput(props: NumberInputProps) {
  const { locale, ...otherProps } = props;

  const { i18n } = useTranslation();

  const localeProps = LOCALE_PRESETS[locale ?? i18n.language];

  return <NumberInputBase {...localeProps} {...otherProps} />;
}

type LocalePreset = { thousandSeparator: string; decimalSeparator: string };
type LocalePresets = Record<string, LocalePreset>;

const LOCALE_PRESETS: LocalePresets = {
  en: { thousandSeparator: ',', decimalSeparator: '.' },
  de: { thousandSeparator: '.', decimalSeparator: ',' },
};
