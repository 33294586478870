import { translateFilter } from '../../translate-utils';

import { PrepareGroupedRowFooterDataFilter } from './types';

export const prepareGroupedRowFooterDataFilter = ({
  filter,
  entity,
}: PrepareGroupedRowFooterDataFilter) => {
  if (filter.length === 0) {
    return null;
  }

  return JSON.stringify(translateFilter(filter, entity));
};
