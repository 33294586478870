import styles from './HistoryWidget.module.scss';

import { ChevronLeft } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import clsx from 'clsx';

import { FilterType } from '@work4all/components';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTableView } from '../file-entities-lists';

import { ProjectTimeWidgetTabBar } from './components/widget-tab-bar/WidgetTabBar';

export interface HistoryWidgetProps {
  mobileMode?: boolean;
  onCollapse?: () => void;
}

export const HistoryWidget = (props: HistoryWidgetProps) => {
  const { mobileMode, onCollapse } = props;
  const user = useUser();
  const { close } = useHistoryStack();

  return (
    <div className={clsx(styles.container, mobileMode && styles.mobile)}>
      <div className={styles.headerContainer}>
        {mobileMode ? (
          <IconButton onClick={close} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        ) : null}

        <ProjectTimeWidgetTabBar />

        {onCollapse && (
          <IconButton onClick={onCollapse}>
            <ChevronLeft />
          </IconButton>
        )}
      </div>

      <EntityTableView
        entityType={Entities.timeTracking}
        initialState={{
          filters: [
            {
              id: 'user.id',
              value: {
                filterType: FilterType.Picker,
                value: [
                  {
                    id: `${user.benutzerCode}`,
                    displayName: user.displayName,
                  },
                ],
              },
            },
          ],
        }}
      />
    </div>
  );
};
