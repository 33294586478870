import styles from '../document-preview/DocumentPreview.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo, useState } from 'react';

import { usePermissions } from '@work4all/data';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { RaViewModel } from '@work4all/models/lib/Classes/RaViewModel.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { EInvoiceFormat } from '@work4all/models/lib/Enums/EInvoiceFormat.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { InvoiceForm } from '@work4all/models/lib/Enums/InvoiceForm.enum';
import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';
import { LanguageResource } from '@work4all/utils/lib/i18n/LanguageResource.enum';

import { MIME_TYPES } from '../../../preview/Preview';
import { useEntityTemplate } from '../../entity-template-provider/EntityTemplateProvider';
import { ApprovalStatusBanderole } from '../components/approval-banderole/ApprovalStatusBanderole';
import { ActionStatuses } from '../components/approval-banderole/types';
import { PreviewContent } from '../components/PreviewContent';
import { DocumentPreviewContent } from '../document-preview/components/DocumentPreviewContent';
import { DocumentPreviewTitle } from '../document-preview/components/DocumentPreviewTitle';
import { useDocumentPreview } from '../document-preview/hooks/use-document-preview';
import { EntityHistory } from '../EntityHistory';
import { EntityPreviewContainerProps } from '../types';

import { DocumentCopyBanderole } from './components/DocumentCopyBanderole';
import { ErpPreviewTab } from './components/ErpPreviewTab';
import { SimpleReportWithFullScren } from './components/SimpleReportWithFullScreen';
import { useErpPreviewData } from './hooks/use-erp-preview-data';
import { useErpPreviewFields } from './hooks/use-erp-preview-fields';
import { useInboundInvoicePdfEditRights } from './hooks/use-inbound-invoice-pdf-edit-rights';
import { useMutateBzObject } from './hooks/use-mutate-bz-object';
import { usePdfOverlay } from './hooks/use-pdf-overlay';
import { usePreviewNavigation } from './hooks/use-preview-navigation';
import { useSimpleReportActions } from './hooks/use-simple-report-actions';

interface ErpPreviewProps extends EntityPreviewContainerProps {
  entity: Entities;
  languageResources: LanguageResource[];
  previewData: ReturnType<typeof useErpPreviewData>;
  showEditIcon?: boolean;
  showFullscreenIcon?: boolean;
  showDownloadIcon?: boolean;
  isApprovalCenter?: boolean;
}

export function ErpPreview(props: ErpPreviewProps) {
  const {
    entity,
    onCloseClick,
    onEditClicked,
    openInFullscreen = false,
    convertProps,
    languageResources,
    previewData,
    onVisibilityToggle,
    showEditIcon = true,
    showFullscreenIcon = true,
    showDownloadIcon = true,
    isApprovalCenter = false,
  } = props;

  const { canEdit } = usePermissions();

  const {
    data: entitiesData,
    refetch,
    fileList,
    requestEntityData,
  } = previewData;

  const { file, component } = usePreviewNavigation(fileList || []);

  const fileInfos = file?.fileInfos;
  const mimeType = file?.mimeType;

  const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));

  const re =
    entity === Entities.reViewModel
      ? (entitiesData?.[0] as ReViewModel)
      : undefined;

  const inboundInvoicePdfEditAllowed = useInboundInvoicePdfEditRights({
    fileName: file?.fileInfos?.fileEntityFilename,
    documentUserId: entitiesData?.[0]?.userId,
    datevDate: re?.invoiceDate,
  });

  const docId = useMemo(() => {
    const selectedEntity = re;

    const correctReceipt = selectedEntity?.receipts?.find(
      (x) => x.parentId === file?.id
    );

    return correctReceipt?.id || selectedEntity?.receipts?.[0]?.id;
  }, [file, re]);

  const [showPreview, setShowPreview] = useState(true);

  const reloadPreview = useCallback(async () => {
    await refetch();
    setShowPreview(false);
    setTimeout(() => setShowPreview(true));
  }, [refetch]);

  const { component: pdfOverlay, open: openPdfOverlay } = usePdfOverlay({
    entityId: re?.id,
    entityType: Entities.reViewModel,
    docId,
    onClose: reloadPreview,
  });

  const selectedRowsIdsList = useMemo(() => {
    return entitiesData?.map((x) => x.id) ?? [];
  }, [entitiesData]);

  const showEdit =
    isSmUp &&
    showEditIcon &&
    entitiesData?.length === 1 &&
    canEdit({ entity, record: entitiesData?.[0] });

  const subtitleComp = useMemo(() => {
    if (entity === Entities.reViewModel) {
      const data = previewData?.data?.[0];
      const status = data?.permitStatus;

      const userName =
        status === RePermitStatus.APPROVED
          ? data?.approvedByUser?.displayName
          : status === RePermitStatus.REJECTED
          ? data?.blockedByUser?.displayName
          : null;

      const date =
        (status === RePermitStatus.APPROVED ||
          status === RePermitStatus.REJECTED) &&
        data.permitDate
          ? DateTime.fromISO(data.permitDate).toLocaleString(
              DateTimeCustom.DATE_SHORT
            )
          : null;

      return (
        <>
          {!!file?.parentId && <DocumentCopyBanderole />}
          {isApprovalCenter &&
            (status === RePermitStatus.APPROVED ||
              status === RePermitStatus.REJECTED) && (
              <ApprovalStatusBanderole
                userName={userName}
                date={date}
                status={status.toLocaleLowerCase() as ActionStatuses}
              />
            )}
        </>
      );
    }

    return null;
  }, [entity, file?.parentId, isApprovalCenter, previewData?.data]);

  const fields = useErpPreviewFields({
    entity,
    entitiesData,
  });

  const [tab, setTab] = useState<'ORVERVIEW' | 'HISTORY'>('ORVERVIEW');

  const customer: Customer =
    (entitiesData?.[0] as Invoice)?.additionalAddress2?.businessPartner?.data ||
    (entitiesData?.[0] as RaViewModel)?.invoice?.additionalAddress2
      ?.businessPartner?.data;

  const isXInvoice =
    customer?.invoiceForm === InvoiceForm.ELEKTRONISCH &&
    [EInvoiceFormat.X_RECHNUNG_CII, EInvoiceFormat.X_RECHNUNG_UBL].includes(
      customer?.eInvoiceFormat
    );

  const fileUrl = isXInvoice
    ? (entitiesData?.[0] as RaViewModel)?.invoice?.xInvoicePreviewUrl ||
      (entitiesData?.[0] as Invoice)?.xInvoicePreviewUrl
    : fileInfos?.previewUrl;

  const documentPreviewProps = useDocumentPreview(
    isXInvoice ? '' : fileInfos?.fileEntityFilename
  );

  const sharedProps = {
    onCloseClick,
    onEditClicked: showEdit ? onEditClicked : undefined,
    url: fileUrl,
    filePath: isXInvoice ? '' : fileInfos?.fileEntityFilename,
    mimeType: isXInvoice
      ? MIME_TYPES.pdf
      : ((fileInfos?.previewMimeType || mimeType) as MIME_TYPES),
    title:
      entitiesData?.[0]?.note || fileInfos?.fileServiceProviderInfos?.fileName,
    noPreviewUrl:
      entity !== Entities.reViewModel
        ? fileInfos?.fileServiceProviderInfos?.fspUrl || fileInfos?.downloadUrl
        : fileInfos?.downloadUrl,
    downloadUrl: isXInvoice ? fileUrl : fileInfos?.downloadUrl,
    fspUrl: isXInvoice ? null : fileInfos?.fileServiceProviderInfos?.fspUrl,
    exists: Boolean(fileInfos?.downloadUrl),
  };

  const entityTemplate = useEntityTemplate();

  const url = documentPreviewProps.isLinkable
    ? sharedProps.filePath
    : sharedProps.url;

  const mutate = useMutateBzObject();

  const noBzEntities = [Entities.reViewModel, Entities.raViewModel];
  const bzObjType = noBzEntities.includes(entity)
    ? null
    : convertEntityToBzObjType(entity);
  const mutateAll = useCallback(
    async (change: object) => {
      const ids = (entitiesData || []).map((x) => x.id);
      await mutate({
        variables: {
          ...change,
          ids,
          bzObjType,
        },
        onCompleted: () => {
          refetch();
        },
      });
    },
    [mutate, entitiesData, bzObjType, refetch]
  );

  const { actions, download, setSimpleReportBlob, fileName } =
    useSimpleReportActions({
      customActions: props.customActions,
      entitiesData,
      entity,
      file,
      setFullscreen: documentPreviewProps.setFullscreen,
      url,
      languageResources,
      showEditIcon,
      showFullscreenIcon,
      showDownloadIcon,
    });

  return (
    <React.Fragment>
      {pdfOverlay}
      {showPreview && (
        <div className={styles.wrapper}>
          <DocumentPreviewTitle
            {...documentPreviewProps}
            {...sharedProps}
            entries={entitiesData}
            entity={entity}
            entityData={requestEntityData}
            titleKeyField="note"
            openInFullscreen={openInFullscreen}
            navigation={component}
            erpPreview={true}
            showFullscreenButton={false}
            showDownloadButton={showDownloadIcon}
            onPdfEditClicked={
              entity === Entities.reViewModel && inboundInvoicePdfEditAllowed
                ? () => openPdfOverlay()
                : undefined
            }
            customActions={actions}
            convertProps={convertProps}
            selectedRowsIdsList={selectedRowsIdsList}
            mutate={mutateAll}
            onVisibilityToggle={onVisibilityToggle}
          />
          {subtitleComp}

          {entity !== Entities.raViewModel && (
            <>
              {entitiesData?.length === 1 && (
                <ErpPreviewTab tab={tab} setTab={setTab} />
              )}
              {tab === 'ORVERVIEW' && (
                <PreviewContent
                  style={{
                    borderBottom: '1px solid var(--ui04)',
                    background: 'var(--ui01)',
                    flex: 'unset',
                  }}
                  entries={entitiesData || []}
                  entity={entity}
                  fields={fields}
                  mutate={mutateAll}
                />
              )}

              {tab === 'HISTORY' && entitiesData?.[0] && (
                <EntityHistory
                  fitContainer={false}
                  entity={entity}
                  fullWidth
                  id={entitiesData[0].id}
                  className={styles.hist}
                />
              )}
            </>
          )}
          {(entity === Entities.reViewModel ||
            entity === Entities.raViewModel) &&
          tab === 'ORVERVIEW' ? (
            <DocumentPreviewContent
              {...documentPreviewProps}
              {...sharedProps}
              iconProps={{
                showDownloadIcon,
                showFullscreenIcon,
              }}
            />
          ) : (
            entitiesData?.length === 1 &&
            tab === 'ORVERVIEW' &&
            (url ? (
              <DocumentPreviewContent
                {...documentPreviewProps}
                {...sharedProps}
              />
            ) : (
              entitiesData?.[0] && (
                <SimpleReportWithFullScren
                  onClose={() => documentPreviewProps.setFullscreen(false)}
                  onDownloadClick={download}
                  onEditClicked={sharedProps.onEditClicked}
                  open={documentPreviewProps.detailInFullsceen}
                  fileName={fileName}
                  preview={entityTemplate.renderSimpleReport({
                    data: entitiesData[0],
                    bzObjType,
                    onPdfBlobChange: setSimpleReportBlob,
                  })}
                />
              )
            ))
          )}
        </div>
      )}
    </React.Fragment>
  );
}
