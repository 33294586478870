import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { FileSettingsMaskController } from '../../../files/detail/components/file-settings/FileSettingsMaskController';
import { ProjectTimeMask } from '../../../time-tracker/ProjectTimeMask';
import { VacationMask } from '../../../vacations/VacationMask';
import { RestOverlay } from '../RestOverlay';
import { AppointmentOverlayController } from '../views/appointment/AppointmentOverlayController';
import { ArticleOverlayController } from '../views/article/ArticleOverlayController';
import { BusinessPartnerOverlayController } from '../views/businessPartners/BusinessPartnerOverlayController';
import { CheckListOverlayController } from '../views/check-list';
import { ContactsOverlayController } from '../views/contacts/ContactsOverlayController';
import { CRMOverlayController } from '../views/crm/CrmOverlayController';
import { DocumentTemplateOverlayController } from '../views/document-template/DocumentTemplateOverlayController';
import { DocumentsOverlayController } from '../views/documents/DocumentsOverlayController';
import { EmailOverlayController } from '../views/email/EmailOverlayController';
import { EMailSignatureOverlayController } from '../views/email-signature/EMailSignatureOverlayController';
import { EmailTemplateOverlayController } from '../views/email-template/EMailTemplateOverlayController';
import { ERPOverlayController } from '../views/erp/ERPOverlayController';
import { InboundInvoiceOverlayController } from '../views/inbound-invoice/InboundInvoiceOverlayController';
import { ProjectOverlayController } from '../views/projects';
import { SalesOpportunitiesOverlayController } from '../views/sales-opportunities/SalesOpportunitiesOverlayController';
import { TextBuildingBlockOverlayController } from '../views/text-building-block/TextBuildingBlockOverlayController';
import { TicketOverlayController } from '../views/ticket/TicketOverlayController';
import { TravelReceiptsOverlayController } from '../views/travel-receipts/TravelReceiptsOverlayController';
import { UserOverlayController } from '../views/user/UserOverlayController';

import { MaskType } from './MaskType';

export function getMaskControllerComponent(entity: MaskType) {
  switch (entity) {
    case Entities.article:
      return ArticleOverlayController;
    case Entities.eMail:
      return EmailOverlayController;
    case Entities.eMailTemplate:
      return EmailTemplateOverlayController;
    case Entities.eMailSignature:
      return EMailSignatureOverlayController;
    case Entities.textBuildingBlock:
      return TextBuildingBlockOverlayController;
    case Entities.note:
    case Entities.task:
    case Entities.callMemo:
      return CRMOverlayController;
    case Entities.contact:
      return ContactsOverlayController;
    case Entities.customer:
    case Entities.supplier:
      return BusinessPartnerOverlayController;
    case Entities.appointment:
      return AppointmentOverlayController;
    case Entities.project:
      return ProjectOverlayController;
    case Entities.deliveryNote:
    case Entities.inboundDeliveryNote:
    case Entities.calculation:
    case Entities.invoice:
    case Entities.contract:
    case Entities.offer:
    case Entities.order:
    case Entities.demand:
      return ERPOverlayController;
    case Entities.reViewModel:
      return InboundInvoiceOverlayController;
    case Entities.letter:
    case Entities.document:
      return DocumentsOverlayController;
    case Entities.ticket:
      return TicketOverlayController;
    case Entities.vacationRequest:
      return VacationMask;
    case Entities.timeTrackingOverviewItem:
      return ProjectTimeMask;
    case Entities.salesOpportunities:
      return SalesOpportunitiesOverlayController;
    case Entities.fullUser:
      return UserOverlayController;
    case Entities.travelReceipts:
      return TravelReceiptsOverlayController;
    case 'file-settings':
      return FileSettingsMaskController;
    case Entities.wordDocumentTemplate:
    case Entities.wordLetterTemplate:
      return DocumentTemplateOverlayController;
    case Entities.checkList:
      return CheckListOverlayController;
    default:
      if (!warnedAboutMissingController.has(entity)) {
        warnedAboutMissingController.add(entity);
        console.warn(`No mask component configured for entity ${entity}.`);
      }
      return RestOverlay;
  }
}

const warnedAboutMissingController = new Set<string>();
