import styles from './ExchangeSynchronization.module.scss';

import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { useMailboxesData } from '../../../../../hooks/use-mailboxes-data';
import { useOpenLinkMailboxDialog } from '../hooks';

import { MailboxExchangeSynchronization } from './MailboxExchangeSynchronization';

export const ExchangeSynchronization = () => {
  const { t } = useTranslation();
  const { go } = useHistoryStack();
  const { mailboxes } = useMailboxesData();
  const openLinkMailboxDialog = useOpenLinkMailboxDialog();

  return (
    <List>
      {mailboxes.map((mailbox, index) => {
        return (
          <ListItem key={index} disablePadding>
            <ListItemButton
              onClick={() => {
                go({
                  title: mailbox.mailboxPrimaryAddress,
                  view: <MailboxExchangeSynchronization mailbox={mailbox} />,
                });
              }}
              className={styles.itemButton}
            >
              <ListItemText primary={mailbox.mailboxPrimaryAddress} />
              <SettingsIcon color="primary" />
            </ListItemButton>
          </ListItem>
        );
      })}

      <ListItem disablePadding>
        <ListItemButton
          onClick={openLinkMailboxDialog}
          className={styles.itemButton}
        >
          <AddIcon color="primary" />
          <Typography color="primary">
            {t('EXCHANGE_SYNCHRONIZATION.LINK_MAILBOX')}
          </Typography>
        </ListItemButton>
      </ListItem>
    </List>
  );
};
