import i18next from 'i18next';

import { DEFAULT_HTML_FONT_SIZE } from './utils/html-parser';

const undoButtons = ['undo', 'redo'];

const fontSizeButtons = ['fontFamily', 'fontSize'];

const fontFormatButtons = ['bold', 'underline', 'strikeThrough'];

const fontColorButtons = ['backgroundColor', 'textColor'];

const alignButtons = ['alignLeft', 'alignCenter', 'alignRight', 'alignJustify'];
const listButtons = ['formatUL', 'formatOL', 'indent', 'outdent'];

const toolbarDefault = {
  // Key represents the more button from the toolbar.
  moreText: {
    // List of buttons used in the  group.
    buttons: [
      ...undoButtons,
      '|',
      ...fontFormatButtons,
      ...fontSizeButtons,
      'italic',
      'clearFormatting',
      '|',
      ...fontColorButtons,
    ],

    // Alignment of the group in the toolbar.
    align: 'left',

    buttonsVisible: 20,
  },

  moreParagraph: {
    buttons: ['|', ...listButtons],
    align: 'left',
    buttonsVisible: 10,
  },

  moreRich: {
    buttons: [],
    align: 'left',
    buttonsVisible: 0,
  },

  moreMisc: {
    buttons: ['|', ...alignButtons],
    align: 'left',
    buttonsVisible: 10,
  },
};

const toolbarLG = {
  ...toolbarDefault,
  moreText: {
    ...toolbarDefault.moreText,
    buttons: [
      ...undoButtons,
      '|',
      ...fontSizeButtons,
      '|',
      ...fontFormatButtons,
      'italic',
      'clearFormatting',
      '|',
      '|',
      ...fontColorButtons,
    ],
    buttonsVisible: 20,
  },
  moreParagraph: {
    ...toolbarDefault.moreParagraph,
    buttonsVisible: 10,
  },
  moreRich: {
    ...toolbarDefault.moreRich,
    buttons: ['|', ...alignButtons],
    buttonsVisible: 0,
  },
  moreMisc: {
    ...toolbarDefault.moreMisc,
    buttons: [],
    buttonsVisible: 13,
  },
};

const toolbarMD = toolbarLG;

const toolbarSM = {
  ...toolbarDefault,
  moreText: {
    ...toolbarDefault.moreText,
    buttons: [
      ...undoButtons,
      '|',
      ...fontSizeButtons,
      '|',
      ...fontFormatButtons,
      'italic',
      'clearFormatting',
      '|',
      '|',
      ...fontColorButtons,
    ],
    buttonsVisible: 15,
  },
  moreParagraph: {
    ...toolbarDefault.moreParagraph,
    buttonsVisible: 2,
  },
  moreRich: {
    ...toolbarDefault.moreRich,
    buttons: ['|', ...alignButtons],
    buttonsVisible: 0,
  },
  moreMisc: {
    ...toolbarDefault.moreMisc,
    buttons: [],
    buttonsVisible: 2,
  },
};

const toolbarXS = {
  ...toolbarDefault,
  moreText: {
    ...toolbarDefault.moreText,
    buttonsVisible: 5,
  },
  moreParagraph: {
    ...toolbarDefault.moreParagraph,
    buttons: [...toolbarDefault.moreParagraph.buttons, '|', ...alignButtons],
    buttonsVisible: 1,
  },
  moreRich: {
    ...toolbarDefault.moreRich,
    buttons: [],
    buttonsVisible: 3,
  },
  moreMisc: {
    ...toolbarDefault.moreMisc,
    buttons: [],
    buttonsVisible: 0,
  },
};

const defaultConfig = {
  useClasses: false,
  fontFamily: {
    'Arial,Helvetica,sans-serif': 'Arial',
    "'Avant Garde',serif": 'Avant Garde',
    'Brush Script MT': 'Brush Script MT',
    'Bookman,serif': 'Bookman',
    'Calibri,sans-serif': 'Calibri',
    "'Courier New',monospace": 'Courier New',
    'Garamond,serif': 'Garamond',
    'Georgia,serif': 'Georgia',
    'Impact,Charcoal,sans-serif': 'Impact',
    'Tahoma,Geneva,sans-serif': 'Tahoma',
    "'Trebuchet MS',sans-serif": 'Trebuchet MS',
    "'Times New Roman',Times,serif": 'Times New Roman',
    'Verdana,Geneva,sans-serif': 'Verdana',
    'Palatino,serif': 'Palatino',
  },
  fontSizeUnit: 'pt',
  fontSizeSelection: true,
  fontSizeDefaultSelection: DEFAULT_HTML_FONT_SIZE,
  fontFamilyDefaultSelection: 'Calibri',
  key: process.env.NX_PUBLIC_FROALA_KEY,
  attribution: false,
  // Inlined too allow tree-shaking.
  // enter: Froalaeditor.ENTER_DIV
  enter: 1,
  toolbarSticky: true,
  // zIndex: 2501, //removed because it was interfering tih overlaying components -> eg time tracking mask overlay from tickets mask overlay - why was it here in the first place?
  // iframe: true, // removed because it caused problems with destroy() func in React StrictMode
  imageDefaultAlign: 'left',
  toolbarButtonsXS: toolbarXS,
  toolbarButtonsSM: toolbarSM,
  toolbarButtonsMD: toolbarMD,
  toolbarButtons: toolbarLG,
  // Remove "imageAlt" button.
  imageEditButtons: [
    'imageReplace',
    'imageAlign',
    'imageCaption',
    'imageRemove',
    '|',
    'imageLink',
    'linkOpen',
    'linkEdit',
    'linkRemove',
    '-',
    'imageDisplay',
    'imageStyle',
    'imageSize',
  ],
  listAdvancedTypes: false,
  charCounterCount: false,
  language: i18next?.languages?.[1],
};

export { defaultConfig as TEXT_EDITOR_DEFAULT_CONFIG };
