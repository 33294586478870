import { EntityPreviewContainerProps } from '@work4all/components/lib/components/entity-preview/types';

import { useMailboxContentManagerContext } from './mailbox-content-manager-context';
import { MailboxContentPreview } from './MailboxContentPreview';
import { useReadMail } from './use-read-mail';

type Props = EntityPreviewContainerProps;

export const MailboxContentPreviewContainer = (props: Props) => {
  const { ids = [], onCloseClick, onVisibilityToggle } = props;

  const managerContext = useMailboxContentManagerContext();

  const mailboxContentState = managerContext.state.itemsById[ids[0]];

  const { data } = useReadMail({
    folderId: managerContext.state.folder.folder,
    mailboxId: managerContext.state.folder.mailbox,
    mailId: mailboxContentState.data.id,
  });

  if (!data?.readMail) {
    return null;
  }

  return (
    <MailboxContentPreview
      mailboxContent={data.readMail}
      onCloseClick={onCloseClick}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
};
