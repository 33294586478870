import styles from './TimeTemplatesWidget.module.scss';

import { ChevronLeft } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Popover } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useTrackHistory } from '../../hooks/use-time-track-history';

import TemplatesCategory from './components/TemplatesCategory';
import TemplatesSettings from './components/TemplatesSettings';
import { ProjectTimeWidgetTabBar } from './components/widget-tab-bar/WidgetTabBar';
import { ProjectTimeMaskInit } from './ProjectTimeMask';

export type categoryType =
  | 'last'
  | Entities.task
  | Entities.contract
  | Entities.project
  | Entities.appointment
  | Entities.ticket;

interface TimeTemplateWidgetProps {
  onTemplateSelect: (init: ProjectTimeMaskInit, createdFrom: Entities) => void;
  mobileMode?: boolean;
  onCollapse?: () => void;
}

export const TimeTemplatesWidget: React.FC<TimeTemplateWidgetProps> = ({
  onTemplateSelect,
  mobileMode = false,
  onCollapse,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const { close } = useHistoryStack();
  const { t } = useTranslation();

  const { categoriesSettings } = useTrackHistory();

  const categories = useMemo(() => {
    const result = [
      {
        title: t('COMMON.TIMETRACKING.LAST_USED_TEMPLATES'),
        type: 'last' as categoryType,
      },
      {
        title: t('COMMON.TASKS'),
        type: Entities.task as categoryType,
      },
      {
        title: t('COMMON.CONTRACT_plural'),
        type: Entities.contract as categoryType,
      },
      {
        title: t('COMMON.APPOINTMENTS'),
        type: Entities.appointment as categoryType,
      },
      {
        title: t('COMMON.TICKETS'),
        type: Entities.ticket as categoryType,
      },
    ];

    return result;
  }, [t]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={clsx(styles.root, { [styles.mobile]: mobileMode })}>
      <div className={clsx(styles.header, { [styles.mobile]: mobileMode })}>
        {mobileMode ? (
          <IconButton onClick={close} className={styles.closeButton}>
            <CloseIcon />
          </IconButton>
        ) : null}
        <ProjectTimeWidgetTabBar />
        <div className={styles.actionContainer}>
          <IconButton
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onClick={open ? handlePopoverClose : handlePopoverOpen}
          >
            <SettingsIcon />
          </IconButton>
          {onCollapse && (
            <IconButton onClick={onCollapse}>
              <ChevronLeft />
            </IconButton>
          )}
        </div>

        <Popover
          id="mouse-over-popover"
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <TemplatesSettings categories={categories} />
        </Popover>
      </div>
      <div className={clsx(styles.content, { [styles.mobile]: mobileMode })}>
        {categories.map((c) =>
          categoriesSettings[c.type].show ? (
            <TemplatesCategory
              key={c.type}
              mobileMode={mobileMode}
              onTemplateSelect={onTemplateSelect}
              type={c.type}
              title={c.title}
            />
          ) : null
        )}
      </div>
    </div>
  );
};

export default TimeTemplatesWidget;
