import { MoreVert } from '@mui/icons-material';
import { Box, IconButton, Popover } from '@mui/material';
import { t } from 'i18next';
import { useRef } from 'react';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Divider } from '../../../dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '../../../input/checkbox-radio-item';

export interface PositionPickerSettingsProps {
  descriptionField?: 'longtext' | 'shortText';
  onDescriptionFieldChange?: (value: 'longtext' | 'shortText') => void;
}

export function PositionPickerSettings(props: PositionPickerSettingsProps) {
  const { descriptionField, onDescriptionFieldChange } = props;

  const popoverState = usePopoverState();
  const settingsBtnRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <Popover {...popoverState} anchorEl={settingsBtnRef.current}>
        <Box p="0 1rem" minWidth="12rem">
          <Divider title={t(`INPUTS.DESCRIPTION`)} />
          <CheckboxRadioItem
            control="radio"
            label={t(`POSITIONS_PICKER.COLUMNS.longtext`)}
            checked={descriptionField === 'longtext'}
            onChange={() => onDescriptionFieldChange?.('longtext')}
          />
          <CheckboxRadioItem
            control="radio"
            label={t(`POSITIONS_PICKER.COLUMNS.shortText`)}
            checked={descriptionField === 'shortText'}
            onChange={() => onDescriptionFieldChange?.('shortText')}
          />
        </Box>
      </Popover>

      {onDescriptionFieldChange && (
        <IconButton
          onClick={() => popoverState.handleClick()}
          ref={settingsBtnRef}
        >
          <MoreVert />
        </IconButton>
      )}
    </>
  );
}
