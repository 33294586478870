import { useMemo, useState } from 'react';

import {
  MenuItem,
  MenuSection,
  UseSearchedItemsSectionProps,
  UseSearchedItemsSectionReturn,
} from './types';

const getSectionItems = <TItem,>(
  section: MenuSection<TItem>
): MenuItem<TItem>[] => {
  return section.items.flatMap((item) => {
    if (item.sections) {
      return item.sections.flatMap((section) => {
        return getSectionItems(section);
      });
    }

    return item;
  });
};

export const useSearchedItemsSection = <TItem,>({
  sections: sectionsProps,
}: UseSearchedItemsSectionProps<TItem>): UseSearchedItemsSectionReturn<TItem> => {
  const [searchedValue, setSearchValue] = useState<string>('');

  const searchableItems: MenuItem<TItem>[] = useMemo(() => {
    return sectionsProps.flatMap((section) => {
      return getSectionItems(section);
    });
  }, [sectionsProps]);

  const sections: MenuSection<TItem>[] = useMemo(() => {
    if (searchedValue.length === 0) {
      return sectionsProps;
    }

    const items = searchableItems.filter((item) =>
      item.label
        .toLocaleLowerCase()
        .startsWith(searchedValue.toLocaleLowerCase())
    );

    return [{ items }];
  }, [searchableItems, searchedValue, sectionsProps]);

  return { sections, searchedValue, setSearchValue };
};
