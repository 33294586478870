import { DateTime, Interval } from 'luxon';

import { TimeTrackingKind } from '@work4all/models/lib/Enums/TimeTrackingKind.enum';

const suggestionSettings = (suggestion, selectedDay) => {
  let amount: number;

  // day settings
  const day = {
    workHoursAmount: 8,
    workStartHour: 9,
    workEndHour: 17,
  };
  const dayStartDateTime = DateTime.fromJSDate(selectedDay.from).set({
    hour: day.workStartHour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const dayEndDateTime = DateTime.fromJSDate(selectedDay.from).set({
    hour: day.workEndHour,
    minute: 0,
    second: 0,
    millisecond: 0,
  });

  // suggestion interval
  const suggestionInterval = Interval.fromDateTimes(
    DateTime.fromISO(suggestion.startDate),
    DateTime.fromISO(suggestion.endDate)
  );

  // amount calculation
  if (suggestion.isWholeDay) {
    amount = day.workHoursAmount;
  } else if (suggestionInterval?.length('hours') >= day.workHoursAmount) {
    const workDayInterval = Interval.fromDateTimes(
      dayStartDateTime,
      dayEndDateTime
    );
    amount = workDayInterval.intersection(suggestionInterval)?.length('hours');
  } else {
    amount = DateTime.fromISO(suggestion.endDate)
      .startOf('minute')
      .diff(DateTime.fromISO(suggestion.startDate).startOf('minute'))
      .as('hours');
  }

  // check if is it a all day suggestion
  const isAllDay =
    amount >= day.workHoursAmount ||
    suggestionInterval?.length('hours') >= day.workHoursAmount;

  // new dates calculation
  const dates = isAllDay
    ? {
        value: DateTime.fromJSDate(selectedDay.from).toFormat('yyyy-MM-dd'),
        start: dayStartDateTime,
        end: dayEndDateTime,
      }
    : {
        value: DateTime.fromISO(suggestion.startDate).toFormat('yyyy-MM-dd'),
        start: DateTime.fromISO(suggestion.startDate),
        end: DateTime.fromISO(suggestion.endDate),
      };

  // time tracking kind
  const timeTrackingKind = isAllDay
    ? TimeTrackingKind.ANZAHL
    : TimeTrackingKind.VON_BIS;

  return { amount, dates, isAllDay, timeTrackingKind };
};

export default suggestionSettings;
