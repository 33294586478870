import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { IconButton, Radio } from '@mui/material';

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
};

export const RadioCell = ({ value, onChange }: Props) => {
  const commonProps = {
    onClick: () => onChange(!value),
    style: { height: '0.75rem', width: '0.75rem' },
  };

  if (value) {
    return (
      <IconButton {...commonProps}>
        <CheckCircleIcon color="success" />
      </IconButton>
    );
  }

  return <Radio checked={false} {...commonProps} />;
};
