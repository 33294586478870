import { SortOption } from '../DataProvider';
import { LayoutTypesUnion } from '../GraphQLEntities';

export enum LayoutDefinitionTypes {
  EntityList = 'EntityList',
}

export enum LayoutTypes {
  Supplier = 'LIEFERANT',
  Customer = 'KUNDE',
  Project = 'PROJEKT',
  Personal = 'PERSONAL',
  NoType = 'NOTYPE',
}

export type ILayout = {
  id: string;
  tenant: number;
  layoutType: LayoutTypes;
  name: string;
  defaultLayout: boolean;
  definition: string;
  insertTime: string;
  updateTime: string;
};

export interface WidgetsDefinitions extends Omit<ILayout, 'definition'> {
  definition: ILayoutGroupDefinition[];
}

export interface ILayoutEntity {
  /**
   * @deprecated type is inconsistent with our layout types, use entityTypeName instead
   */
  type: LayoutTypesUnion;
  entityTypeName: LayoutTypesUnion;
  sortOptions: SortOption[];
  filters: Array<Record<string, Record<string, string>>>;
  ui: ILayoutEntityUIConfig;
}

export type ILayoutEntityUIConfigColumn = {
  accessor: string;
  type: string;
  primary?: boolean;
  modifiers?: string[];
  align?: 'left' | 'center' | 'right';
};
export interface ILayoutEntityUIConfig {
  columns: ILayoutEntityUIConfigColumn[];
  rowModifiers?: CardRowModifier[];
}

export type CardRowStyleModifier = {
  type: 'StyleModifier';
  rules: Array<{ condition: RowModifierCondition; styles: string[] }>;
};

export type RowModifierCondition = Record<string, RowModifierConditionRule>;

export type RowModifierConditionRule =
  | RowModifierRuleEquals
  | RowModifierRuleNotEquals
  | RowModifierRuleIn
  | RowModifierRuleNotIn;

export type RowModifierRuleEquals = { $eq: unknown };
export type RowModifierRuleNotEquals = { $ne: unknown };
export type RowModifierRuleIn = { $in: unknown[] };
export type RowModifierRuleNotIn = { $nin: unknown[] };

export type CardRowModifier = CardRowStyleModifier;

type WidgetGroup = {
  id: string;
  type: string;
  name: string;
  collapsed: boolean;
  widgets: ILayoutDefinition[];
};

export interface IDefaultLayoutGroupDefinition extends WidgetGroup {
  type: 'default';
  i18nKey?: string;
}

export interface ICustomLayoutGroupDefinition extends WidgetGroup {
  type: 'custom';
}

export type ILayoutGroupDefinition =
  | IDefaultLayoutGroupDefinition
  | ICustomLayoutGroupDefinition;
export interface ILayoutDefinition {
  title: LayoutTypesUnion;
  id: string;
  type: 'EntityList';
  derivate?: {
    field: string;
    value: string;
  };
  config: {
    sortOrder: 'desc' | 'asc';
    entities: ILayoutEntity[];
  };
}
