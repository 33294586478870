import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ApprovalNotificationEventType } from '../Enums/ApprovalNotificationEventType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { ObjectType } from '../Enums/ObjectType.enum';

import { ApprovalEventRefObject } from './ApprovalEventRefObject.entity';
import { InboundInvoice } from './InboundInvoice.entity';
import { VacationRequestEntity } from './VacationRequestEntity.entity';

export class ApprovalEvent<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: any;
  /** Alias: insertTime */
  insertTime?: any;
  /** Alias: updateTime */
  updateTime?: any;
  /** Alias: objectType */
  objectType?: ObjectType;
  /** Alias: approvalEventType */
  approvalEventType?: ApprovalNotificationEventType;
  /** Alias: objectCode */
  objectCode?: any;
  /** Alias: objectId */
  objectId?: any;
  /** Alias: fromUser */
  fromUser?: any;
  /** Alias: toUser */
  toUser?: any;
  /** Alias: comment */
  comment?: any;
  /** Alias: refObject */
  refObject?: T extends EMode.query
    ? ApprovalEventRefObject<T>
    : VacationRequestEntity<T> | InboundInvoice<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ApprovalEvent> = {
  id: {
    alias: 'id',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  objectType: {
    alias: 'objectType',
  },
  approvalEventType: {
    alias: 'approvalEventType',
  },
  objectCode: {
    alias: 'objectCode',
  },
  objectId: {
    alias: 'objectId',
  },
  fromUser: {
    alias: 'fromUser',
  },
  toUser: {
    alias: 'toUser',
  },
  comment: {
    alias: 'comment',
  },
  refObject: {
    alias: 'refObject',
    entity: [Entities.vacationRequestEntity, Entities.inboundInvoice],
  },
  __typename: {
    alias: '__typename',
  },
};

export const approvalEventEntityDefinition: EntitiyDefinition<ApprovalEvent> = {
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ApprovalEvent',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
