import React, { useEffect } from 'react';

import {
  AppointmentPreview,
  AppointmentPreviewFields,
} from '@work4all/components/lib/components/entity-preview/appointment-preview';
import { useEntityPreviewData } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '@work4all/components/lib/components/entity-preview/types';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { EMailTemplateKind } from '@work4all/models/lib/Enums/EMailTemplateKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useAppointmentEmailTemplate } from '../../../../../../components/data-tables/appointments-table/hooks/use-appointment-email-template';
import { useGetAppoinmentEmailsParams } from '../../../../../../components/data-tables/appointments-table/hooks/use-get-appoinment-emails-params';
import { useEntityActions } from '../../../../../../components/data-tables/entity-table/EntityActionProvider';

type IAppointmentPreviewContainerProps = EntityPreviewContainerProps;

export function AppointmentPreviewContainer(
  props: IAppointmentPreviewContainerProps
) {
  const { ids: appointmentIds, ...others } = props;
  const appointments = useEntityPreviewData<Appointment>(
    appointmentIds,
    Entities.appointment,
    AppointmentPreviewFields
  );

  const getEmailParams = useGetAppoinmentEmailsParams(appointments?.[0]);
  const { action, component } = useAppointmentEmailTemplate({
    getEmailParams,
    eMailTemplateKind: EMailTemplateKind.APPOINTMENT_NOTIFICATION,
    noTemplate: true,
    appointments,
  });

  const { addActions } = useEntityActions();

  useEffect(() => {
    addActions({
      right: [action],
    });
  }, [action, addActions]);

  if (!appointments) {
    return null;
  }
  return (
    <React.Fragment>
      {component}
      <AppointmentPreview entries={appointments} {...others} />
    </React.Fragment>
  );
}
