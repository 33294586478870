import { GridView, Search, Undo } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import produce from 'immer';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import {
  SelectableTree,
  TreeNode,
} from '@work4all/components/lib/dataDisplay/tree';
import { FileHorizontalSelect } from '@work4all/components/lib/input/horizontal-select/custom/FileHorizontalSelect';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useWidgetsDataBag } from '@work4all/data';

import { settings, useSetting } from '../../../../../settings';
import { useFileViewModeSetting } from '../../hooks/useFileViewModeSetting';
import { FileContext } from '../FileContext';

export const WidgetSettingsDialog = () => {
  const fileContext = useContext(FileContext);

  const [searchTerm, setSearchTerm] = useState('');
  const [expandedIds, setExpandedIds] = useState([]);

  const hiddenWidgets = useSetting(
    settings.hiddenWidgets({ fileType: fileContext.type })
  );
  const collapsedWidgets = useSetting(
    settings.collapsedWidgets({ fileType: fileContext.type })
  );

  const persistedFileWidgetLayouts = useSetting(settings.fileWidgetLayouts());

  const { widgetsDefinitions: currentWidgetDef } = useWidgetsDataBag();

  const selectedIdList = useMemo(() => {
    const ids = currentWidgetDef.definition
      .flatMap((item) => item.widgets)
      .map((x) => x.id);
    const result = ids.filter((id) => hiddenWidgets.value[id] !== true);

    return result;
  }, [currentWidgetDef.definition, hiddenWidgets]);

  const { t } = useTranslation();

  const treeData = useMemo<TreeNode[]>(() => {
    const result = currentWidgetDef.definition
      .map((header) => {
        const children = header.widgets
          .filter((widget) =>
            t(widget.title).toLowerCase().includes(searchTerm.toLowerCase())
          )
          .map((widget) => {
            return {
              id: widget.id,
              label: t(widget.title),
            } as TreeNode;
          });
        return children.length
          ? ({
              id: header.id,
              label: t(header.name),
              children,
            } as TreeNode)
          : null;
      })
      .filter(Boolean);
    return result;
  }, [currentWidgetDef.definition, searchTerm, t]);

  const handleNodeChange = useCallback(
    (selectedIds: string[]) => {
      const ids = currentWidgetDef.definition
        .flatMap((item) => item.widgets)
        .map((x) => x.id);

      const deselectedIds = ids.filter((id) => !selectedIds.includes(id));
      const selected = {};
      const deselected = {};
      for (const id of selectedIds) {
        selected[id] = false;
      }
      for (const id of deselectedIds) {
        deselected[id] = true;
      }

      hiddenWidgets.set({
        ...deselected,
        ...selected,
      });
    },
    [currentWidgetDef.definition, hiddenWidgets]
  );

  useEffect(() => {
    if (searchTerm.length > 0) {
      setExpandedIds(treeData.map((item) => item.id));
    } else {
      setExpandedIds([]);
    }
  }, [searchTerm.length, treeData]);

  const handleReset = useCallback(() => {
    const layouts =
      produce(persistedFileWidgetLayouts.value?.layouts, (layouts) => {
        layouts.forEach((layout) => {
          for (const size in layout) {
            layout[size] = layout[size].filter((widget) => {
              return !currentWidgetDef.definition
                .flatMap((item) => item.widgets)
                .find((w) => w.id === widget.i);
            });
          }
        });
      }) || [];

    persistedFileWidgetLayouts.set({
      layouts,
    });

    const newWidgets = {
      ...collapsedWidgets.value,
    };
    currentWidgetDef.definition
      .flatMap((item) => item.widgets)
      .forEach((w) => (newWidgets[w.id] = false));

    collapsedWidgets.set(newWidgets);
    hiddenWidgets.set(newWidgets);
  }, [
    collapsedWidgets,
    currentWidgetDef.definition,
    hiddenWidgets,
    persistedFileWidgetLayouts,
  ]);

  const handleAutoPosition = useCallback(() => {
    const layouts =
      persistedFileWidgetLayouts.value?.layouts.map((layout) => {
        return produce(layout, (draft) => {
          for (const size in draft) {
            draft[size] = draft[size].filter((widget) => {
              return !currentWidgetDef.definition
                .flatMap((item) => item.widgets)
                .find((w) => w.id === widget.i);
            });
          }
          return draft;
        });
      }) || [];

    persistedFileWidgetLayouts.set({
      layouts,
    });
  }, [currentWidgetDef.definition, persistedFileWidgetLayouts]);

  const { viewMode, setViewMode } = useFileViewModeSetting();

  return (
    <>
      <Box p="1rem">
        <FileHorizontalSelect viewMode={viewMode} setViewMode={setViewMode} />
      </Box>
      {viewMode === 'individual' && (
        <>
          <Stack p="0.5rem">
            <LabeledInput
              autoFocus
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              label={t('FILEPAGE.LAYOUT.WIDGET')}
              endAdornment={<Search />}
              style={{ background: 'transparent', border: 0 }}
            />
            <Divider style={{ padding: 0 }} />
          </Stack>
          <SelectableTree
            data={treeData}
            selected={selectedIdList}
            multiple
            selectable="leaf"
            onChange={handleNodeChange}
            expanded={expandedIds}
            onNodeToggle={(_, ids) => setExpandedIds(ids)}
          />
          <Stack p="0.5rem">
            <Divider style={{ padding: 0 }} />
            <Button
              color="inherit"
              startIcon={<GridView color="action" />}
              style={{ width: 'fit-content' }}
              onClick={handleAutoPosition}
            >
              {t('COMMON.AUTOSIZE_WIDGETS')}
            </Button>
            <Button
              color="inherit"
              startIcon={<Undo color="action" />}
              style={{ width: 'fit-content' }}
              onClick={handleReset}
            >
              {t('COMMON.RESET_WIDGETS')}
            </Button>
          </Stack>
        </>
      )}
    </>
  );
};
