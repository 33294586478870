import styles from './Item.module.scss';

import { Radio, Tooltip, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import clsx from 'clsx';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { ListChildComponentProps } from 'react-window';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';

interface ItemData {
  items: EMailTemplate[];
  activeRowIndex: number;
  clearable: boolean;
  Picker;
  isSelected: (item: EMailTemplate) => boolean;
  onToggle: (item: EMailTemplate) => void;
  onStandardChange?: (item: EMailTemplate) => void;
  standardTemplate?: string;
  disabledItemsIds?: (number | string)[];
}

export const Item = memo(function ItemItem(
  props: ListChildComponentProps<ItemData>
) {
  const { data, index, style } = props;
  const {
    items,
    activeRowIndex,
    clearable,
    isSelected,
    onToggle,
    onStandardChange,
    standardTemplate,
  } = data;
  const value = items[index];

  const { t } = useTranslation();

  if (!value) {
    return (
      <ListItem style={style} disablePadding>
        <Skeleton variant="text" height="100%" width="100%" />
      </ListItem>
    );
  }

  const active = activeRowIndex === index;
  const selected = isSelected(value);

  const isStandardChecked = standardTemplate && standardTemplate === value.id;
  const standardAction = onStandardChange && Number(value.id) !== 0 && (
    <Tooltip title={t('INPUTS.SET_STANDART')} placement="right">
      <Radio
        checked={!!isStandardChecked}
        onClick={(e) => {
          e.stopPropagation();
          onStandardChange(value);
        }}
        value={value.id}
        className={styles.standardAction}
      />
    </Tooltip>
  );

  return (
    <ListItem
      key={value.id}
      style={style}
      disablePadding
      secondaryAction={standardAction}
      className={clsx(styles.listItem, {
        [styles.listItemVisible]: isStandardChecked,
      })}
    >
      <ListItemButton
        className={clsx(styles['button'], {
          [styles['button-active']]: active,
        })}
        dense
        role={undefined}
        onClick={() => onToggle(value)}
        selected={selected}
      >
        <Stack alignItems={'center'} direction="row" overflow="hidden" flex={1}>
          <div style={{ overflow: 'hidden', width: '100%' }}>
            <Tooltip title={value.name ?? ''} enterDelay={1000}>
              <Typography
                variant="body2"
                noWrap
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {value.name}
              </Typography>
            </Tooltip>
          </div>
        </Stack>

        {clearable && selected ? (
          <ClearIcon className={styles['deselect-icon']} />
        ) : null}
      </ListItemButton>
    </ListItem>
  );
});
