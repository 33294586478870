import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SouthIcon from '@mui/icons-material/South';

import { ChecklistePositionPrioritaet } from '@work4all/models/lib/Enums/ChecklistePositionPrioritaet.enum';

export const getCheckListPositionPriorityIcon = (
  priority: ChecklistePositionPrioritaet
) => {
  switch (priority) {
    case ChecklistePositionPrioritaet.NIEDRIG:
      return <SouthIcon color="primary" />;

    case ChecklistePositionPrioritaet.HOCH:
      return <PriorityHighIcon color="error" />;

    default:
      return null;
  }
};
