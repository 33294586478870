import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { ChecklistePositionPrioritaet } from '../Enums/ChecklistePositionPrioritaet.enum';
import { ChecklistPositionKind } from '../Enums/ChecklistPositionKind.enum';
import { EMode } from '../Enums/EMode.enum';

import { InputCustomField } from './InputCustomField.entity';

export class InputChecklistePosition<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: checklisteCode */
  checklisteCode?: number;
  /** Alias: name */
  name?: string;
  /** Alias: datumErledigt */
  datumErledigt?: string;
  /** Alias: benutzerCodeErledigt */
  benutzerCodeErledigt?: number;
  /** Alias: notiz */
  notiz?: string;
  /** Alias: art */
  art?: ChecklistPositionKind;
  /** Alias: nummer */
  nummer?: number;
  /** Alias: positionsNummer */
  positionsNummer?: string;
  /** Alias: kategorie1 */
  kategorie1?: string;
  /** Alias: kategorie2 */
  kategorie2?: string;
  /** Alias: farbe */
  farbe?: string;
  /** Alias: benutzerCodeGeplant */
  benutzerCodeGeplant?: number;
  /** Alias: bisWann */
  bisWann?: string;
  /** Alias: personGeplant */
  personGeplant?: string;
  /** Alias: checked */
  checked?: boolean;
  /** Alias: lieferantenCode */
  lieferantenCode?: number;
  /** Alias: prioritaet */
  prioritaet?: ChecklistePositionPrioritaet;
  /** Alias: vorlageProjektDatumBezug */
  vorlageProjektDatumBezug?: string;
  /** Alias: projektePlanungCode */
  projektePlanungCode?: number;
  /** Alias: customFields */
  customFields?: InputCustomField<T>[];
  /** Alias: beginntAm */
  beginntAm?: string;

  __typename?: string;
}

const fields: FieldDefinitions<InputChecklistePosition> = {
  code: {
    alias: 'code',
  },
  checklisteCode: {
    alias: 'checklisteCode',
  },
  name: {
    alias: 'name',
  },
  datumErledigt: {
    alias: 'datumErledigt',
  },
  benutzerCodeErledigt: {
    alias: 'benutzerCodeErledigt',
  },
  notiz: {
    alias: 'notiz',
  },
  art: {
    alias: 'art',
  },
  nummer: {
    alias: 'nummer',
  },
  positionsNummer: {
    alias: 'positionsNummer',
  },
  kategorie1: {
    alias: 'kategorie1',
  },
  kategorie2: {
    alias: 'kategorie2',
  },
  farbe: {
    alias: 'farbe',
  },
  benutzerCodeGeplant: {
    alias: 'benutzerCodeGeplant',
  },
  bisWann: {
    alias: 'bisWann',
  },
  personGeplant: {
    alias: 'personGeplant',
  },
  checked: {
    alias: 'checked',
  },
  lieferantenCode: {
    alias: 'lieferantenCode',
  },
  prioritaet: {
    alias: 'prioritaet',
  },
  vorlageProjektDatumBezug: {
    alias: 'vorlageProjektDatumBezug',
  },
  projektePlanungCode: {
    alias: 'projektePlanungCode',
  },
  customFields: {
    alias: 'customFields',
  },
  beginntAm: {
    alias: 'beginntAm',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputChecklistePositionEntityDefinition: EntitiyDefinition<InputChecklistePosition> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputChecklistePosition',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
