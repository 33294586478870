import { useCallback, useMemo, useState } from 'react';

import { httpClient } from '@work4all/data';

import { SetMfaModeResponse } from '@work4all/models/lib/Classes/SetMfaModeResponse.entity';
import { ValidateSetMfaModeResponse } from '@work4all/models/lib/Classes/ValidateSetMfaModeResponse.entity';
import { MfaMode } from '@work4all/models/lib/Enums/MfaMode.enum';

import { ApplicationName } from '../../../utils/ApplicationName.enum';

interface SetMfaModeApiResponse {
  response: SetMfaModeResponse;
  signature: string;
  token: string;
}

interface UseRestMfaProps {
  apiUrl: string;
  baseUrl: string;
  passwordHash: string;
  username: string;
}

interface SetMfaModeBody {
  username: string;
  passwordHash: string;
  application: ApplicationName.work4all20;
}

export const useRestMfa = (props: UseRestMfaProps) => {
  const { apiUrl, baseUrl, passwordHash, username } = props;
  const [mfaMode, setMfaMode] = useState<MfaMode>(null);
  const [inProgress, setInProgress] = useState<MfaMode>(null);
  const [mfaConfirmDialogOpen, setMfaConfirmDialogOpen] = useState(false);

  const [setMfaModeApiResponse, setSetMfaModeApiResponse] =
    useState<SetMfaModeApiResponse>(null);

  const mfaUpdateResult = useMemo(
    () => setMfaModeApiResponse?.response,
    [setMfaModeApiResponse]
  );

  const activateMfa = useCallback(
    async (mfaMode: MfaMode) => {
      setInProgress(mfaMode);
      const res = await httpClient.put<SetMfaModeApiResponse, SetMfaModeBody>({
        url: `${apiUrl}/Auth/SetMfaMode?mfaMode=${mfaMode}`,
        body: {
          username,
          passwordHash,
          application: ApplicationName.work4all20,
        },
        headers: {
          'x-work4all-apiurl': baseUrl,
        },
        contentType: 'application/json',
      });
      setInProgress(null);

      setSetMfaModeApiResponse(res.data);

      if (res?.data?.response?.ok) {
        setMfaMode(mfaMode);
        setMfaConfirmDialogOpen(true);
      }
    },
    [apiUrl, baseUrl, passwordHash, username]
  );

  const validate = useCallback(
    async ({ mfaCode }) => {
      const url = new URL(`${apiUrl}/Auth/ValidateSetMfaMode`);
      const params = new URLSearchParams({
        mfaCode,
        token: setMfaModeApiResponse.token,
        signature: setMfaModeApiResponse.signature,
      });
      url.search = params.toString();
      const res = await httpClient.put<ValidateSetMfaModeResponse>({
        url: url.toString(),
        headers: {
          'x-work4all-apiurl': baseUrl,
        },
        contentType: 'application/json',
      });

      return res.data;
    },
    [setMfaModeApiResponse]
  );

  return {
    mfaMode,
    inProgress,
    mfaConfirmDialogOpen,
    mfaUpdateResult,
    setMfaConfirmDialogOpen,
    activateMfa,
    validate,
  };
};
