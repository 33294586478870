import style from './EntityPickerField.module.scss';

import clsx from 'clsx';
import React, { ReactElement, Ref, useMemo } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  EntityPicker,
  IEntityPicker,
} from '@work4all/components/lib/components/entity-picker/EntityPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

export interface EntityPickerFieldProps<T>
  extends Omit<IEntityPicker<T, false>, 'multiple'> {
  label?: string;
  error?: string;
  disabled?: boolean;
  view?: 'field' | 'cell';
}

/**
 * Generic Picker Field to reuse for simple pickers.
 * TODO: https://work4all.atlassian.net/browse/WW-4012
 */

export const EntityPickerField = React.forwardRef<
  HTMLDivElement,
  EntityPickerFieldProps<unknown>
>(<T,>(props, ref) => {
  const { view, label, error, disabled, ...pickerProps } = props;
  const { value, onChange } = pickerProps;

  const displayProperty = useMemo(() => {
    return props.overrides?.displayProperty
      ? props.overrides?.displayProperty
      : 'name';
  }, [props.overrides?.displayProperty]);

  const viewProps =
    view === 'cell'
      ? {
          className: clsx(style.entityPickerFieldButton, {
            [style.cellButtonWrapper]: true,
          }),
          inputButtonClassName: style.inputCellButton,
          buttonClassName: style.cellButton,
        }
      : {};

  return (
    <EntityPickerPopover
      picker={<EntityPicker {...pickerProps} multiple={false} />}
    >
      <PickerTargetButton
        ref={ref}
        disabled={disabled}
        onClear={
          props.clearable
            ? () => {
                onChange(null);
              }
            : null
        }
        label={label}
        error={error}
        value={`${value?.[displayProperty] ?? ''}`}
        {...viewProps}
      />
    </EntityPickerPopover>
  );
}) as <T>(
  p: EntityPickerFieldProps<T> & { ref?: Ref<HTMLDivElement> }
) => ReactElement;
