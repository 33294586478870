import { useMemo } from 'react';

import { useOverlayRouteMatch } from '@work4all/components/lib/navigation/router-navigation-overlay';

import { IRouteParams } from '../types';

export function useOverlayRouteParams(): IRouteParams {
  const match = useOverlayRouteMatch();

  const params = useMemo(() => {
    if (!match) {
      return null;
    }

    return decodeRouteParams(match.params as unknown as IRouteParams);
  }, [match]);

  return params;
}

// Process all params with decodeURIComponent to parse special characters.
function decodeRouteParams(params: IRouteParams): IRouteParams {
  return Object.keys(params).reduce((acc, key) => {
    acc[key] = decodeURIComponent(params[key]);
    return acc;
  }, {} as IRouteParams);
}
