import { ActionStatuses } from '@work4all/components/lib/components/entity-preview/components/approval-banderole/types';

import { RePaymentStatus } from '@work4all/models/lib/Enums/RePaymentStatus.enum';
import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';

export const mapSelectedRowStatus = (
  status?: RePermitStatus,
  paymentStatus?: RePaymentStatus
): ActionStatuses => {
  switch (status) {
    case RePermitStatus.APPROVED:
      return ActionStatuses.approved;
    case RePermitStatus.REJECTED:
      return ActionStatuses.rejected;
    case RePermitStatus.EMPTY:
      if (
        paymentStatus === RePaymentStatus.PAYED ||
        paymentStatus === RePaymentStatus.PAYED_CREDIT ||
        paymentStatus === RePaymentStatus.ZERO_INVOICE
      )
        return ActionStatuses.openAndPaid;
      return ActionStatuses.open;
    default:
      return ActionStatuses.open;
  }
};
