import React from 'react';
import { useTranslation } from 'react-i18next';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import {
  ITopicPickerProps,
  TopicPicker,
} from '@work4all/components/lib/components/entity-picker/topic-picker/TopicPicker';

import { PickerTargetButton } from '../../containers/mask-overlays/locked-inputs';

interface Props extends Omit<ITopicPickerProps<false>, 'multiple'> {
  error?: string;
}

export const TopicPickerField = React.forwardRef<HTMLDivElement, Props>(
  function TopicPickerField(props, ref) {
    const { t } = useTranslation();
    const { error, ...pickerProps } = props;
    const { value, onChange } = pickerProps;

    return (
      <EntityPickerPopover
        picker={<TopicPicker {...pickerProps} multiple={false} />}
      >
        <PickerTargetButton
          ref={ref}
          onClear={() => {
            onChange(null);
          }}
          data-test-id="category-picker-field"
          label={t('COMMON.CATEGORY')}
          error={error}
          value={value?.name || ''}
        />
      </EntityPickerPopover>
    );
  }
);
