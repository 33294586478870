import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, Theme, Typography, useMediaQuery } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import deepmerge from 'deepmerge';
import produce from 'immer';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ReactComponent as AssignTicketIcon } from '@work4all/assets/icons/assign-ticket.svg';
import { ReactComponent as NewTicketIcon } from '@work4all/assets/icons/new-ticket.svg';

import {
  ITableStateBag,
  TableRowDisplayModifiers,
  useDialogs,
  usePatchedDefaultData,
  useTableStateBag,
} from '@work4all/components';
import type {
  CustomToolbar,
  CustomToolbarAction,
} from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { GroupedItems } from '@work4all/components/lib/dataDisplay/basic-table/hooks/query-table-data/types';
import { PrepareTableRowModifiers } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useRowDisplayModifiers';
import { relativeDateToDate } from '@work4all/components/lib/dataDisplay/basic-table/utils/filters/filterConversion';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useDataProvider, useDeleteEntity } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailboxFolder } from '@work4all/models/lib/Classes/MailboxFolder.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';
import { MailboxFolderType } from '@work4all/models/lib/Enums/MailboxFolderType.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';
import { useOnWindowFocus } from '@work4all/utils/lib/hooks/use-on-window-focus';

import { TicketOverlayController } from '../../../containers/mask-overlays/mask-overlay/views/ticket/TicketOverlayController';
import { useMailboxesData } from '../../../hooks/use-mailboxes-data';
import { SelectedMailboxFolder, settings, useSetting } from '../../../settings';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { LinkMailboxDialog } from '../LinkMailboxDialog';
import _schema from '../schemata/mailbox-content-table-schema.json';
import { useTableLayoutState } from '../table-layout';
import { TableNoRowsPlaceholder } from '../TableNoRowsPlaceholder';
import { useSelectedTableRows } from '../use-selected-table-rows';
import { useTableConfig } from '../use-table-config';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { AssignToTicketDialog } from './AssignToTicketDialog';
import { BlockSenderPopover } from './BlockSenderPopover/BlockSenderPopover';
import {
  MailboxContentAssignButtonCell,
  MailboxContentBodyCell,
  MailboxContentContactCell,
  MailboxContentProjectCell,
  MailboxContentStatusCell,
  MailboxContentStatusChangeButtonCell,
} from './cells';
import {
  isBusinessPartner,
  isContact,
} from './cells/contact-or-business-partner';
import { createTicketFromMail } from './create-ticket-from-mail';
import { extendMailboxFolders } from './extend-mailbox-folders';
import { filterOutInvalidSenders } from './filter-out-invalid-senders';
import { MAILBOX_CONTENT_REQUIRED_FIELDS } from './mailbox-content-graphql-fields';
import { useMailboxContentManager } from './mailbox-content-manager';
import { MailboxContentItem } from './mailbox-content-manager/types';
import { MailboxContentManagerContextProvider } from './mailbox-content-manager-context';
import {
  MailboxContentTableQuickFilters,
  MailboxContentTableQuickFiltersProps,
} from './MailboxContentTableQuickFilters';
import { MobileFolderSidebar } from './MobileFolderSidebar';
import { ExtendedMailbox, ExtendedMailboxFolder } from './types';

export const MailboxContentTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function MailboxContentTable(props, ref) {
  const schema: ITableSchema<never> = _schema as ITableSchema<never>;

  if (!schema) return null;
  return <MailboxContentTableInner {...props} ref={ref} schema={schema} />;
});

export const PAGE_SIZE = 30;

const FROM_COLUMN_ID = 'from';
const PROJECT_COLUMN_ID = 'project';

export const defaultSort = [
  { field: 'date', direction: SortDirection.ASCENDING },
];

export const MailboxContentTableInner = React.forwardRef<
  TableInstance,
  IEntityTable
>((props, ref) => {
  const { schema: schemaOriginal } = props;
  const { entity: entityType } = schemaOriginal;

  const { t } = useTranslation();

  const dialogs = useDialogs();

  const isMdUp = useMediaQuery<Theme>((t) => t.breakpoints.up('md'));

  const tableStateBag = useTableStateBag();

  const layoutState = useTableLayoutState();
  const [layout] = layoutState;

  const { mailboxes, refetch: refetchMailboxes } = useMailboxesData();

  const extendedMailboxes = useExtendedMailboxes(mailboxes);

  const { value: selectedFolderSaved, set: setSelectedFolder } = useSetting(
    settings.selectedMailboxFolder()
  );

  const [isLinkMailboxDialogOpen, setLinkMailboxDialogOpen] = useState(false);

  useOnWindowFocus(refetchMailboxes);

  // Try to select the first inbox automatically.
  useEffect(() => {
    if (mailboxes.length === 0) return;
    if (
      selectedFolderSaved == null ||
      !findSelectedFolder(extendedMailboxes, selectedFolderSaved)
    ) {
      const folder =
        findInboxFolder(extendedMailboxes) ?? findAnyFolder(extendedMailboxes);

      // Do not call with `null` if `selectedFolder` is already `null`.
      if (folder !== selectedFolderSaved) {
        setSelectedFolder(folder);
      }
    }
  }, [
    extendedMailboxes,
    mailboxes.length,
    selectedFolderSaved,
    setSelectedFolder,
  ]);

  const { selectedFolderReal, selectedFolderObject } = useMemo(() => {
    const found = findSelectedFolder(extendedMailboxes, selectedFolderSaved);

    return {
      selectedFolderReal: selectedFolderSaved,
      selectedFolderObject: found,
    };
  }, [extendedMailboxes, selectedFolderSaved]);

  const { value: isEmailProjectRequired } = useSetting(
    settings.isEmailProjectRequired()
  );

  const schemaPatched = useMemo(() => {
    return produce(schemaOriginal, (draft) => {
      // Replace From column with To if the Sent Items folder is selected.
      if (selectedFolderObject?.folderType === MailboxFolderType.SENT_ITEMS) {
        const fromColumn = draft.columns.find(
          (column) => column.id === FROM_COLUMN_ID
        );

        if (fromColumn) {
          fromColumn.accessor = 'to';
          fromColumn.title = ['MASK.TO'];
        }
      }

      // Make project column required if `isEmailProjectRequired`  is `true`.
      if (isEmailProjectRequired) {
        const projectColumn = draft.columns.find(
          (column) => column.id === PROJECT_COLUMN_ID
        );

        if (projectColumn) {
          projectColumn.required = true;
        }
      }
    });
  }, [
    isEmailProjectRequired,
    schemaOriginal,
    selectedFolderObject?.folderType,
  ]);

  const {
    columnConfigs,
    fields: extractedFields,
    cardConfig,
  } = useTableConfig({
    layout,
    schema: schemaPatched,
    cells: {
      MailboxContentStatus: MailboxContentStatusCell,
      MailboxContentAssignButton: MailboxContentAssignButtonCell,
      MailboxContentProject: MailboxContentProjectCell,
      MailboxContentContact: MailboxContentContactCell,
      MailboxContentBody: MailboxContentBodyCell,
      MailboxContentStatusChangeButton: MailboxContentStatusChangeButtonCell,
    },
  });

  const prepareRowDisplayModifiers = useCallback<
    PrepareTableRowModifiers<MailboxContent>
  >((row) => {
    const isAssigned =
      row.assignStatus === MailAssignStatus.ALREADY_ASSIGNED ||
      row.assignStatus === MailAssignStatus.ASSIGNED_TO_OBJECT ||
      row.assignStatus === MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL;
    const isIgnored = row.assignStatus === MailAssignStatus.ITEM_IGNORED;
    const isFaded = isIgnored;
    const isShade1 = isAssigned;

    const isBold = row.isRead === false;

    const modifiers: TableRowDisplayModifiers = { isShade1, isFaded, isBold };

    return modifiers;
  }, []);

  const fields = useMemo(() => {
    return deepmerge(extractedFields, MAILBOX_CONTENT_REQUIRED_FIELDS);
  }, [extractedFields]);

  const [userConfig] = useUserColumnConfigs({
    layout,
    entityType,
    columnConfigs,
  });

  const [deleteEntity] = useDeleteEntity(false);

  const { startDate, endDate, filterStatus } = useFilters(tableStateBag);

  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetMailboxContents',
      entity: entityType,
      skip: mailboxes.length === 0,
      data: fields,
      sort: defaultSort,
      vars: {
        mailboxId: selectedFolderSaved?.mailbox,
        folderId: selectedFolderSaved?.folder,
        dateFrom: startDate,
        dateTo: endDate,
        filterStatus,
      },
    };
  }, [
    entityType,
    fields,
    selectedFolderSaved,
    mailboxes.length,
    startDate,
    endDate,
    filterStatus,
  ]);

  const responseRaw = useDataProvider<MailboxContent>(
    request,
    false,
    PAGE_SIZE
  );

  const response = usePatchedDefaultData(responseRaw, PAGE_SIZE);

  const { data: rawData, fetchMore, pending, total } = response;

  const data = useMemo(() => {
    // Filter out suggested contacts of type User as they are not supported.
    const _data = filterOutInvalidSenders(rawData);
    // Remove from the list results whose status does not match the statuses
    // selected in the filters. This is a workaround to improve the user experience
    // when the list of results with updated item statuses is reloaded in the background.
    if (!filterStatus || !filterStatus.length) return _data;
    return _data?.filter((d) => filterStatus?.includes(d?.assignStatus));
  }, [filterStatus, rawData]);

  const { selectedEntities } = useSelectedTableRows<MailboxContent>({
    data: data as GroupedItems,
    tableStateBag,
  });

  const selectedIds = useMemo(() => {
    const selectedRowIds = tableStateBag?.tableState?.selectedRowIds;

    if (!selectedRowIds) return [];

    return Object.keys(selectedRowIds);
  }, [tableStateBag?.tableState?.selectedRowIds]);

  const manager = useMailboxContentManager(
    {
      items: data,
      selectedIds,
      folder: selectedFolderReal,
      refetch: response.refetch,
    },
    useFilters(tableStateBag)
  );

  const {
    getSelected,
    canAssignSelected,
    canAssignSelectedToTicket,
    canIgnoreSelected,
    canResetSelected,
    canDeleteSelected,
    assignSelected,
    ignoreSelected,
    resetSelected,
    deleteSelected,
    markRead,
    loading,
  } = manager;

  const lastDeleatedRef = useRef<MailboxContent | null>(null);
  const previousSelectedRef = useRef<MailboxContent | null>(null);

  const handleDeleteSelected = useEventCallback(async () => {
    const confirmed = await dialogs.confirm({
      title: t('ASSIGN_INCOMING_EMAILS.DELETE_ALERT.TITLE'),
      description: t('ASSIGN_INCOMING_EMAILS.DELETE_ALERT.DESCRIPTION'),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });

    if (confirmed) {
      lastDeleatedRef.current = previousSelectedRef.current;
      await deleteSelected();
      await response.refetch();
    }
  });

  const handleStatusActionChange = useCallback(
    async (action: () => Promise<void>) => {
      await action();
      await response.refetch();
    },
    [response]
  );

  const handleSelectedChange = useEventCallback(() => {
    const currentSelected =
      selectedEntities != null && selectedEntities.length === 1
        ? selectedEntities[0]
        : null;

    const previousSelected = previousSelectedRef.current;

    if (
      previousSelected != null &&
      lastDeleatedRef.current?.id !== previousSelected.id
    ) {
      const shouldMarkAsRead =
        previousSelected.isRead === false &&
        selectedEntities != null &&
        (selectedEntities.length === 0 ||
          (selectedEntities.length === 1 &&
            previousSelected.id !== selectedEntities[0].id));

      if (shouldMarkAsRead) {
        markRead([previousSelected.id]);
      }
    }

    previousSelectedRef.current = currentSelected;
  });

  useEffect(() => {
    handleSelectedChange();
  }, [handleSelectedChange, selectedEntities]);

  const canAssign = canAssignSelected();
  const canAssignToTicket = canAssignSelectedToTicket();
  const canIgnore = canIgnoreSelected();
  const canReset = canResetSelected();
  const canDelete = canDeleteSelected();
  const canBlockSender = getSelected().length === 1;

  const [isAssignToTicketDialogOpen, setAssignToTicketDialogOpen] = useState<{
    open: boolean;
    loading: boolean;
    error: boolean;
    businessPartner?: Customer | Supplier;
    mailBoxId?: string;
  }>({ open: false, loading: false, error: false, businessPartner: null });

  const getBusinessPartnerData = useCallback(
    (mailboxContent: MailboxContentItem): Customer | Supplier | null => {
      if (isBusinessPartner(mailboxContent.contact)) {
        return mailboxContent.contact;
      }

      if (isContact(mailboxContent.contact)) {
        return mailboxContent.contact.businessPartner?.data;
      }

      return null;
    },
    []
  );

  const handleAssignToTicketClick = useCallback(() => {
    const [selected] = getSelected();

    if (!selected) {
      return;
    }

    const businessPartner = getBusinessPartnerData(selected);

    setAssignToTicketDialogOpen({
      open: true,
      loading: false,
      error: false,
      businessPartner: businessPartner,
      mailBoxId: selected.data.id,
    });
  }, [getBusinessPartnerData, getSelected]);

  const handleAssignToTicket = useEventCallback(async (ticketId: string) => {
    setAssignToTicketDialogOpen((state) => {
      return { ...state, loading: true, open: true };
    });

    try {
      const id = isAssignToTicketDialogOpen.mailBoxId;

      if (!id) {
        setAssignToTicketDialogOpen({
          open: true,
          error: true,
          loading: false,
        });
        return;
      }

      await manager.assignToTicket(id, ticketId);

      setAssignToTicketDialogOpen((state) => {
        return { ...state, open: false };
      });
    } catch {
      setAssignToTicketDialogOpen({
        open: true,
        error: true,
        loading: false,
      });
    }
  });

  function handleCloseAssignToTicketDialog(): void {
    setAssignToTicketDialogOpen((state) => {
      return { ...state, open: false };
    });
  }

  const [blockSenderPopoverAddress, setBlockSenderPopoverAddress] =
    useState<string>('');

  const blockSenderPopoverState = usePopoverState();

  const handleBlockSenderClick = useEventCallback(
    (_value: unknown, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const [selected] = getSelected();

      if (!selected) {
        return;
      }

      const address = selected.data.from;

      setBlockSenderPopoverAddress(address);
      blockSenderPopoverState.handleClick(event);
    }
  );

  const onCloseRef = useRef<() => void>();
  const registerOnClose = useCallback((callback: () => void) => {
    onCloseRef.current = callback;
  }, []);

  const handleCreateTicketClick = useCallback(() => {
    const [selected] = getSelected();

    if (!selected) {
      return;
    }

    const preset = createTicketFromMail(selected);

    const businessPartner = getBusinessPartnerData(selected);
    setAssignToTicketDialogOpen((state) => {
      return {
        ...state,
        businessPartner: businessPartner,
        mailBoxId: selected.data.id,
      };
    });

    const { id } = dialogs.open(NavigationOverlay, {
      initialView: {
        view: (
          <TicketOverlayController
            id={null}
            entity={Entities.ticket}
            onAfterSave={(ticket: Ticket) => {
              handleAssignToTicket(ticket.id);
              dialogs.close(id);
            }}
            params={{ presetFields: JSON.stringify(preset) }}
            registerOnClose={registerOnClose}
            timeTrackingAction={false}
          />
        ),
      },
      close() {
        onCloseRef.current?.();
        dialogs.close(id);
      },
    });
  }, [
    dialogs,
    getSelected,
    handleAssignToTicket,
    registerOnClose,
    getBusinessPartnerData,
  ]);

  const customToolbar = useMemo<CustomToolbar>(() => {
    const actions: CustomToolbarAction[] = [];

    if (canAssign) {
      actions.push({
        actionKey: 'assign',
        title: t('ASSIGN_INCOMING_EMAILS.ASSIGN'),
        IconComponent: CheckCircleOutlineIcon,
        handler: () => handleStatusActionChange(assignSelected),
        mode: 'Add',
      });
    }

    if (canIgnore) {
      actions.push({
        actionKey: 'ignore',
        title: t('ASSIGN_INCOMING_EMAILS.IGNORE'),
        IconComponent: CloseIcon,
        handler: () => handleStatusActionChange(ignoreSelected),
        mode: 'Add',
      });
    }

    if (canAssignToTicket) {
      actions.push(
        {
          actionKey: 'new-ticket',
          title: t('ASSIGN_INCOMING_EMAILS.CREATE_TICKET'),
          IconComponent: NewTicketIcon,
          handler: handleCreateTicketClick,
          mode: 'Add',
        },
        {
          actionKey: 'assign-ticket',
          title: t('ASSIGN_INCOMING_EMAILS.ASSIGN_TICKET'),
          IconComponent: AssignTicketIcon,
          handler: handleAssignToTicketClick,
          mode: 'Add',
        }
      );
    }

    if (canReset) {
      actions.push({
        actionKey: 'undo',
        title: 'Undo',
        IconComponent: UndoIcon,
        handler: () => handleStatusActionChange(resetSelected),
        mode: 'Add',
      });
    }

    if (canDelete) {
      actions.push({
        actionKey: 'delete',
        title: t('ASSIGN_INCOMING_EMAILS.DELETE'),
        IconComponent: DeleteIcon,
        handler: handleDeleteSelected,
        mode: 'Delete',
        sections: ['toolbar'],
      });
    }

    if (canBlockSender) {
      actions.push({
        actionKey: 'block-sender',
        title: t('ASSIGN_INCOMING_EMAILS.BLOCK_SENDER'),
        IconComponent: PersonOffIcon,
        handler: handleBlockSenderClick,
        mode: 'Add',
        sections: ['toolbar'],
      });
    }

    const toolbar: CustomToolbar = {
      hideDivider: true,
      left: actions,
    };

    return toolbar;
  }, [
    canAssign,
    canIgnore,
    canAssignToTicket,
    canReset,
    canDelete,
    canBlockSender,
    t,
    handleStatusActionChange,
    assignSelected,
    ignoreSelected,
    handleAssignToTicketClick,
    resetSelected,
    handleDeleteSelected,
    handleBlockSenderClick,
    handleCreateTicketClick,
  ]);

  const handleDeleteMailbox = async (mailbox: Mailbox) => {
    await deleteEntity({
      type: Entities.mailbox,
      ids: [mailbox.id],
    });

    refetchMailboxes();
  };

  if (!userConfig) return null;

  const folderSidebarProps: MailboxContentTableQuickFiltersProps = {
    selectedFolder: selectedFolderSaved,
    onSelectedFolderChange: setSelectedFolder,
    mailboxes: extendedMailboxes,
    onDeleteMailbox: handleDeleteMailbox,
    onAfterBlockedSendersChange() {
      response.refetch();
    },
  };

  const customVisibleColumns = ['possibleSenders', 'possibleProjects'];

  return mailboxes.length === 0 ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <LinkMailboxDialog
        open={isLinkMailboxDialogOpen}
        onClose={() => setLinkMailboxDialogOpen(false)}
      />
      <Typography variant="h3" align="center">
        {t('ASSIGN_INCOMING_EMAILS.MAILBOX_NOT_LINKED')}
      </Typography>

      <Box mt={2}>
        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setLinkMailboxDialogOpen(true)}
        >
          {t('ASSIGN_INCOMING_EMAILS.LINK_MAILBOX')}
        </Button>
      </Box>
    </Box>
  ) : (
    <MailboxContentManagerContextProvider value={manager}>
      {isMdUp ? null : <MobileFolderSidebar {...folderSidebarProps} />}
      <BlockSenderPopover
        address={blockSenderPopoverAddress}
        popoverState={blockSenderPopoverState}
        onAfterBlockedSendersChange={() => {
          response.refetch();
        }}
      />
      <AssignToTicketDialog
        open={isAssignToTicketDialogOpen.open}
        loading={isAssignToTicketDialogOpen.loading}
        error={isAssignToTicketDialogOpen.error}
        businessPartner={isAssignToTicketDialogOpen.businessPartner}
        onClose={handleCloseAssignToTicketDialog}
        onAssign={handleAssignToTicket}
      />
      <EntityTable
        pending={pending || loading}
        noRowsRenderer={() => <TableNoRowsPlaceholder />}
        ref={ref}
        layout={layout}
        cardConfig={cardConfig}
        areas={{
          left: {
            content: (
              <MailboxContentTableQuickFilters {...folderSidebarProps} />
            ),
            resizable: true,
            title: t('COMMON.FILTER'),
          },
        }}
        actions={{
          custom: customToolbar,
          remove: undefined,
        }}
        columnConfigs={userConfig}
        customVisibleColumns={customVisibleColumns}
        fetchMore={fetchMore}
        prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        data={data}
        total={total}
        noLockableEntity={true}
        onRowDoubleClick={(_id) => {
          return true;
        }}
        entityType={entityType}
        selectedEntities={selectedEntities}
      />
    </MailboxContentManagerContextProvider>
  );
});

// Helpers

function isInboxFolder(folder: MailboxFolder) {
  return folder.folderType === MailboxFolderType.INBOX;
}

function findInboxFolder(mailboxes: Mailbox[]): SelectedMailboxFolder | null {
  const firstInbox = mailboxes.find((mailbox) => {
    return mailbox.folder?.some(isInboxFolder);
  });

  if (!firstInbox) return null;

  const firstFolder = firstInbox.folder.find(isInboxFolder);

  return {
    mailbox: firstInbox.id,
    folder: firstFolder.id,
  };
}

function findAnyFolder(mailboxes: Mailbox[]): SelectedMailboxFolder | null {
  const firstInbox = mailboxes.find((mailbox) => {
    return mailbox.folder?.length > 0;
  });

  if (!firstInbox) return null;

  const firstFolder = firstInbox.folder[0];

  return {
    mailbox: firstInbox.id,
    folder: firstFolder.id,
  };
}

function findSelectedFolder(
  mailboxes: ExtendedMailbox[],
  selected: SelectedMailboxFolder
): ExtendedMailboxFolder | null {
  const mailbox = mailboxes.find((mailbox) => {
    return mailbox.id === selected?.mailbox;
  });

  if (!mailbox) return null;

  const folder = mailbox.folder?.find((folder) => {
    return folder.id === selected?.folder && folder.isVisible;
  });

  if (!folder) return null;

  return folder;
}

function useExtendedMailboxes(mailboxes: Mailbox[]) {
  const setting = useSetting(settings.mailboxFolderVisibility());

  // Remove ignored folders, mark hidden folders and don't process mailboxes
  // with errors, as these won't have the folders info.
  const extendedMailboxes = useMemo(() => {
    return extendMailboxFolders(mailboxes, setting.value);
  }, [mailboxes, setting.value]);

  return extendedMailboxes;
}

export interface MailboxContentFilters {
  startDate: string | null;
  endDate: string | null;
  filterStatus: string[] | null;
}

function useFilters(tableStateBag: ITableStateBag): MailboxContentFilters {
  const { startDate, endDate } = useMemo<{
    startDate: string | null;
    endDate: string | null;
  }>(() => {
    const dateFilter = tableStateBag.tableState?.filters?.find((col) => {
      return col.id === 'date';
    });

    if (!dateFilter) {
      return { startDate: null, endDate: null };
    }

    const {
      startDate = null,
      endDate = null,
    }: { startDate: Date | RelativeDateFilter; endDate: Date } =
      dateFilter.value?.value ?? {};

    const date = relativeDateToDate(startDate, endDate);

    return {
      startDate: !date?.startDate ? null : date.startDate.toISODate(),
      endDate: !date?.endDate ? null : date.endDate.toISODate(),
    };
  }, [tableStateBag.tableState?.filters]);

  const { filterStatus } = useMemo<{
    filterStatus: string[] | null;
  }>(() => {
    const statusFilter = tableStateBag.tableState?.filters?.find((col) => {
      return col.id === 'status';
    });

    if (!statusFilter) {
      return { filterStatus: null };
    }

    const ids = statusFilter.value.value.map((item) => item.id) ?? null;

    return { filterStatus: ids };
  }, [tableStateBag.tableState?.filters]);

  return {
    startDate,
    endDate,
    filterStatus,
  };
}
