import { ArrowBack } from '@mui/icons-material';
import DownloadIcon from '@mui/icons-material/Download';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IPreviewProps, MIME_TYPES, Preview } from '../../../preview/Preview';

import { EditableAttachmentFileName } from './EditableAttachmentFileName';
import { EditableAttachmentNote } from './EditableAttachmentNote';
import { FileListGallery } from './FileListGallery';
import { FileAndThumbnail } from './types';

export interface FileListPreviewContentProps
  extends Pick<IPreviewProps, 'iconProps'> {
  files: FileAndThumbnail[];
  selectedIndex: number;
  onSelectedIndexChange: (index: number) => void;
}

export function FileListPreviewContent(props: FileListPreviewContentProps) {
  const { files, selectedIndex, onSelectedIndexChange, iconProps } = props;

  const selectedFile = selectedIndex === -1 ? null : files[selectedIndex];

  const { t } = useTranslation();

  const [isFullscreen, setFullscreen] = useState(false);

  if (selectedFile !== null) {
    const exists = selectedFile.file.fileServiceProviderInfos?.exists;
    const url = selectedFile.file.previewUrl;
    const mimeType = selectedFile.file.previewMimeType as MIME_TYPES;
    const noPreviewUrl =
      selectedFile.file.fileServiceProviderInfos?.fspUrl ||
      selectedFile.file.downloadUrl;
    const downloadUrl = selectedFile.file.downloadUrl;
    const fspUrl = selectedFile.file.fileServiceProviderInfos?.fspUrl;
    const fileId =
      selectedFile.id ?? selectedFile.file.fileServiceProviderInfos?.id;
    const fileName = selectedFile.file.fileEntityFilename;

    const mimeTypeValues = Object.values(MIME_TYPES);
    const canPreview = mimeTypeValues.includes(mimeType);
    const canDownload = !!downloadUrl;
    const hasMultipleFiles = files.length > 1;

    return (
      <Stack direction="column" flex="1" overflow="hidden">
        <Stack direction="row" alignItems="center">
          {hasMultipleFiles && (
            <Box flex="none">
              <IconButton
                color="primary"
                onClick={() => onSelectedIndexChange(-1)}
                size="small"
              >
                <ArrowBack />
              </IconButton>
            </Box>
          )}

          <Box flex="1" width="100%" overflow="hidden">
            <EditableAttachmentFileName
              fileId={fileId}
              originalFileName={fileName}
            />
          </Box>

          {canPreview && (
            <IconButton
              size="small"
              color="primary"
              onClick={() => setFullscreen(true)}
            >
              <FullscreenIcon />
            </IconButton>
          )}

          {!canPreview && canDownload && (
            <IconButton
              size="small"
              color="primary"
              href={downloadUrl}
              download
            >
              <DownloadIcon />
            </IconButton>
          )}

          {iconProps?.showPreviewExternallyIcon !== false && fspUrl ? (
            <IconButton
              size="small"
              color="primary"
              href={fspUrl}
              target="_blank"
            >
              <OpenInNewIcon />
            </IconButton>
          ) : null}
        </Stack>
        <Box flex="1" overflow="hidden">
          <Preview
            onFullscreenClose={() => {
              setFullscreen(false);
            }}
            openInFullscreen={isFullscreen}
            url={url}
            mimeType={mimeType}
            fileName={fileName}
            filePath={fileName}
            exists={exists}
            fspUrl={fspUrl}
            downloadProps={
              downloadUrl
                ? {
                    url: downloadUrl,
                    filePath: fileName,
                  }
                : undefined
            }
            noPreview={
              exists || canDownload
                ? t('MASK.NO_PREVIEW_DOWNLOAD')
                : t('MASK.NO_PREVIEWFILE')
            }
            noPreviewUrl={noPreviewUrl}
            isLinkable={false}
            iconProps={iconProps}
          />
        </Box>
        <Box>
          <EditableAttachmentNote fileId={fileId} />
        </Box>
      </Stack>
    );
  } else {
    return (
      <FileListGallery
        files={files}
        onSelect={(file) => {
          onSelectedIndexChange(files.indexOf(file));
        }}
      />
    );
  }
}
