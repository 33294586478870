import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';

import { BaseDataAttachment } from './BaseDataAttachment.entity';
import { BusinessPartner } from './BusinessPartner.entity';
import { CategoryAssignment } from './CategoryAssignment.entity';
import { CustomField } from './CustomField.entity';
import { Department } from './Department.entity';
import { PaginationListAddressConnection } from './PaginationListAddressConnection.entity';
import { Salutation } from './Salutation.entity';

export class Contact<T extends EMode = EMode.entity> {
  /** Alias: abteilung */
  department?: Department<T>;
  /** Alias: abteilungCode */
  departmentId?: number;
  /** Alias: abteilungInAdresseZeigen */
  showDepartmentInAddress?: boolean;
  /** Alias: adressErweiterung */
  addressExtension?: string;
  /** Alias: anrede */
  salutation?: Salutation<T>;
  /** Alias: anredeCode */
  salutationId?: number;
  /** Alias: anzeigename */
  displayName?: string;
  /** Alias: briefanrede */
  letterSalutation?: string;
  /** Alias: code */
  id?: number;
  /** Alias: eMail */
  eMail?: string;
  /** Alias: eMailPrivat */
  eMailPrivate?: string;
  /** Alias: entlassen */
  layedOff?: boolean;
  /** Alias: faxPrivat */
  faxPrivate?: string;
  /** Alias: funktion */
  role?: string;
  /** Alias: funktionInAdresseZeigen */
  showFunctionInAddress?: boolean;
  /** Alias: geburtsdatum */
  birthdayDate?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kategorieZuordnungen */
  categoryAssignmentList?: CategoryAssignment<T>[];
  /** Alias: letzteAenderung */
  lastEdit?: string;
  /** Alias: mailanPrivat */
  mailToPrivate?: number;
  /** Alias: mailsperre */
  mailLock?: boolean;
  /** Alias: mobilPrivat */
  mobileNumberPrivate?: string;
  /** Alias: mobilfunk */
  mobileNumber?: string;
  /** Alias: name */
  name?: string;
  /** Alias: namenserweiterung */
  nameExtension?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: notizRTF */
  noteRTF?: string;
  /** Alias: ort */
  city?: string;
  /** Alias: plz */
  postalCode?: string;
  /** Alias: primaereAdresse */
  primaryAddress?: number;
  /** Alias: serienbriefsperre */
  serialLetterLock?: boolean;
  /** Alias: skypename */
  skypeName?: string;
  /** Alias: staat */
  country?: string;
  /** Alias: strasse */
  street?: string;
  /** Alias: telefax */
  faxNumber?: string;
  /** Alias: telefon */
  phoneNumber?: string;
  /** Alias: telefon2 */
  phoneNumber2?: string;
  /** Alias: telefon3 */
  phoneNumber3?: string;
  /** Alias: telefonPrivat */
  phoneNumberPrivate?: string;
  /** Alias: titelerweiterung */
  titleExtension?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: vorname */
  firstName?: string;
  /** Alias: sdObjType */
  businessPartnerType?: SdObjType;
  /** Alias: geschaeftspartnerCode */
  businessPartnerId?: number;
  /** Alias: hauptansprechpartner */
  isMainContact?: boolean;
  /** Alias: anzeigenameAlternativ */
  displayNameAlternative?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: PaginationListverknuepfungen */
  addressConnectionList?: PaginationListAddressConnection<T>;
  /** Alias: attachements */
  attachements?: BaseDataAttachment<T>[];
  /** Alias: telefonResolved */
  telephoneResolved?: string;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: geburtstagTag */
  birthdayDay?: number;
  /** Alias: geburtstagMonat */
  birthdayMonth?: number;
  /** Alias: geburtstagJahr */
  birthdayYear?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Contact> = {
  department: {
    alias: 'abteilung',
    entity: Entities.department,
  },
  departmentId: {
    alias: 'abteilungCode',
  },
  showDepartmentInAddress: {
    alias: 'abteilungInAdresseZeigen',
  },
  addressExtension: {
    alias: 'adressErweiterung',
  },
  salutation: {
    alias: 'anrede',
    entity: Entities.salutation,
  },
  salutationId: {
    alias: 'anredeCode',
  },
  displayName: {
    alias: 'anzeigename',
  },
  letterSalutation: {
    alias: 'briefanrede',
  },
  id: {
    alias: 'code',
  },
  eMail: {
    alias: 'eMail',
  },
  eMailPrivate: {
    alias: 'eMailPrivat',
  },
  layedOff: {
    alias: 'entlassen',
  },
  faxPrivate: {
    alias: 'faxPrivat',
  },
  role: {
    alias: 'funktion',
  },
  showFunctionInAddress: {
    alias: 'funktionInAdresseZeigen',
  },
  birthdayDate: {
    alias: 'geburtsdatum',
  },
  insertTime: {
    alias: 'insertTime',
  },
  categoryAssignmentList: {
    alias: 'kategorieZuordnungen',
    entity: Entities.categoryAssignment,
  },
  lastEdit: {
    alias: 'letzteAenderung',
  },
  mailToPrivate: {
    alias: 'mailanPrivat',
  },
  mailLock: {
    alias: 'mailsperre',
  },
  mobileNumberPrivate: {
    alias: 'mobilPrivat',
  },
  mobileNumber: {
    alias: 'mobilfunk',
  },
  name: {
    alias: 'name',
  },
  nameExtension: {
    alias: 'namenserweiterung',
  },
  note: {
    alias: 'notiz',
  },
  noteRTF: {
    alias: 'notizRTF',
  },
  city: {
    alias: 'ort',
  },
  postalCode: {
    alias: 'plz',
  },
  primaryAddress: {
    alias: 'primaereAdresse',
  },
  serialLetterLock: {
    alias: 'serienbriefsperre',
  },
  skypeName: {
    alias: 'skypename',
  },
  country: {
    alias: 'staat',
  },
  street: {
    alias: 'strasse',
  },
  faxNumber: {
    alias: 'telefax',
  },
  phoneNumber: {
    alias: 'telefon',
  },
  phoneNumber2: {
    alias: 'telefon2',
  },
  phoneNumber3: {
    alias: 'telefon3',
  },
  phoneNumberPrivate: {
    alias: 'telefonPrivat',
  },
  titleExtension: {
    alias: 'titelerweiterung',
  },
  updateTime: {
    alias: 'updateTime',
  },
  firstName: {
    alias: 'vorname',
  },
  businessPartnerType: {
    alias: 'sdObjType',
  },
  businessPartnerId: {
    alias: 'geschaeftspartnerCode',
  },
  isMainContact: {
    alias: 'hauptansprechpartner',
  },
  displayNameAlternative: {
    alias: 'anzeigenameAlternativ',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  addressConnectionList: {
    alias: 'verknuepfungen',
    entity: Entities.addressConnection,
  },
  attachements: {
    alias: 'attachements',
    entity: Entities.baseDataAttachment,
  },
  telephoneResolved: {
    alias: 'telefonResolved',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  birthdayDay: {
    alias: 'geburtstagTag',
  },
  birthdayMonth: {
    alias: 'geburtstagMonat',
  },
  birthdayYear: {
    alias: 'geburtstagJahr',
  },
  __typename: {
    alias: '__typename',
  },
};

export const contactEntityDefinition: EntitiyDefinition<Contact> = {
  local: {},
  remote: {
    queryName: 'getAnsprechpartner',
    fragmentName: 'Ansprechpartner',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertAnsprechpartner',
        args: [
          { name: 'input', type: 'InputAnsprechpartner!' },
          { name: 'relations', type: 'InputAnsprechpartnerRelation' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
