import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';

import { settings, useSetting } from '../../../../../../../../settings';
import {
  CrystalReportOptConf,
  CrystalReportOptValues,
} from '../../../../../../../../settings/settings';

interface UseReportValuesProps {
  reportId: number;
  bzObjectType: BzObjType;
}

export const useReportValues = (props: UseReportValuesProps) => {
  const { reportId, bzObjectType } = props;

  const crystalReportOptGlobalConf = useSetting(
    settings.crystalReportOptGlobalConf()
  );

  const crystalReportOptUserConf = useSetting(
    settings.crystalReportOptUserConf()
  );

  const globalReportOptSettings = useMemo<CrystalReportOptConf>(() => {
    const persistedBzObjSettings: CrystalReportOptConf[] =
      crystalReportOptGlobalConf?.value?.[bzObjectType];

    const persistedReportOptSettings = persistedBzObjSettings?.find(
      (x) => x.reportId === reportId
    );

    if (persistedReportOptSettings) {
      return persistedReportOptSettings;
    }

    return {
      reportId,
      options: [],
    };
  }, [bzObjectType, crystalReportOptGlobalConf?.value, reportId]);

  const userReportOptSettings = useMemo<CrystalReportOptValues>(() => {
    const persistedBzObjSettings: CrystalReportOptValues[] =
      crystalReportOptUserConf?.value?.[bzObjectType];

    const persistedReportOptSettings = persistedBzObjSettings?.find(
      (x) => x.reportId === reportId
    );

    if (persistedReportOptSettings) {
      return persistedReportOptSettings;
    }

    return {
      reportId,
      options: [],
    };
  }, [bzObjectType, crystalReportOptUserConf?.value, reportId]);

  // use it for checkbox management
  const [values, setValues] = useState<Record<number, boolean>>({});

  // initialization only
  useEffect(() => {
    if (Object.keys(values).length || !reportId) return;
    const returnValue: Record<number, boolean> = {};
    for (const opt of userReportOptSettings.options) {
      returnValue[opt.optionId] = opt?.value;
    }
    for (const opt of globalReportOptSettings.options) {
      returnValue[opt.optionId] ||= opt?.defaultValue;
    }

    setValues((values) => {
      if (JSON.stringify(values) === JSON.stringify(returnValue)) return values;
      return returnValue;
    });
  }, [
    globalReportOptSettings.options,
    values,
    reportId,
    userReportOptSettings.options,
  ]);

  const handleOnChange = useCallback(
    (optId: number, value: boolean) => {
      const persistedSettings = cloneDeep(crystalReportOptUserConf?.value);
      if (!persistedSettings[bzObjectType]) {
        persistedSettings[bzObjectType] = [];
      }

      const persistedReportConf = persistedSettings[bzObjectType]?.find(
        (x) => x.reportId === reportId
      );
      if (!persistedReportConf) {
        persistedSettings[bzObjectType].push({
          reportId,
          options: [],
        });
      }

      const persistedReportOptions = persistedSettings[bzObjectType].find(
        (x) => x.reportId === reportId
      ).options;

      const persistedReportOptConf = persistedReportOptions.find(
        (x) => x.optionId === optId
      );
      if (!persistedReportOptConf) {
        persistedReportOptions.push({
          value: value,
          optionId: optId,
        });
      } else {
        persistedReportOptConf.value = value;
      }

      crystalReportOptUserConf.set(persistedSettings);
      setValues((state) => ({ ...state, [optId]: value }));
    },
    [bzObjectType, crystalReportOptUserConf, reportId, setValues]
  );

  return useMemo(
    () => ({
      globalReportOptSettings,
      values: values,
      onChange: handleOnChange,
    }),
    [globalReportOptSettings, handleOnChange, values]
  );
};
