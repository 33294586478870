import { ActionStatuses } from '@work4all/components/lib/components/entity-preview/components/approval-banderole/types';

import { VacationRequestStatus } from '@work4all/models/lib/Enums/VacationRequestStatus.enum';

export const mapSelectedRowStatus = (
  status?: VacationRequestStatus
): ActionStatuses => {
  if (status === VacationRequestStatus.DONE) return ActionStatuses.approved;
  if (status === VacationRequestStatus.REJECTED) return ActionStatuses.rejected;
  return ActionStatuses.open;
};
