import { gql, useApolloClient } from '@apollo/client';
import React from 'react';
import { TableInstance } from 'react-table';

import { Document } from '@work4all/models/lib/Classes/Document.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/document-table-schema.json';

export const DocumentsTable = React.forwardRef<TableInstance, IEntityTable>(
  function DocumentsTable(_props, ref) {
    const dataTable = useEntityDataTable<Document, never>({
      schema: schema as never,
      defaultSort,
      forceRequestFields: {
        childDocuments: [{ id: null }],
      },
    });

    const client = useApolloClient();

    const handleRowDoubleClick = async (id: string | number) => {
      if (dataTable.selectedEntity?.childDocuments?.length) {
        return;
      }

      const documentFspQueryResponse = await client.query({
        fetchPolicy: 'no-cache',
        query: GET_DOCUMENT_WITH_FSP,
        variables: {
          filter: JSON.stringify([{ code: { $eq: id } }]),
        },
      });

      const documentFspQueryUrl =
        documentFspQueryResponse?.data?.getDokument?.data?.[0]?.fileInfos
          .fileServiceProviderInfos.fspUrl;

      // If there's fsp document data, open the URL in a new tab
      if (documentFspQueryUrl) {
        window.open(documentFspQueryUrl, '_blank');
      }
    };

    return (
      <EntityTable
        ref={ref}
        {...dataTable}
        {..._props}
        onRowDoubleClick={(id) => {
          handleRowDoubleClick(id);
          return false;
        }}
      />
    );
  }
);

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

const GET_DOCUMENT_WITH_FSP = gql`
  query GetDokument($filter: String) {
    getDokument(filter: $filter) {
      data {
        code
        fileInfos {
          fileServiceProviderInfos {
            fspUrl
            id
          }
        }
      }
    }
  }
`;
