import i18next from 'i18next';

import { MenuItem, MenuSection } from '@work4all/components';
import { ColumnInstance } from '@work4all/components/lib/dataDisplay/basic-table/types';

const getLabel = (column: ColumnInstance & { title?: string }) => {
  return typeof column.Header === 'string'
    ? column.Header
    : typeof column.title === 'string'
    ? column.title
    : column.id;
};

const getPath = (column: ColumnInstance) => {
  if (!column.filterSubgroupPath || column.filterSubgroupPath.length === 0) {
    return 'itemsWithoutPath';
  }

  if (column.filterSubgroupPath[0] === 'MASK.INDIVIDUAL') {
    return 'individualItems';
  }

  const path = column.filterSubgroupPath.join('');

  if (path === '') {
    return 'itemsWithoutPath';
  }

  return path;
};

const columnsToMenuItem = (column: ColumnInstance) => {
  return {
    ...column,
    id: column.id,
    label: getLabel(column),
  };
};

const sortItemsByLabel = (
  items: MenuItem<ColumnInstance>[]
): MenuItem<ColumnInstance>[] => {
  return items.sort((a, b) => {
    return (a.label ?? 0) > (b.label ?? 0)
      ? 1
      : (a.label ?? 0) === (b.label ?? 0)
      ? 0
      : -1;
  });
};

export const columnsToMenuSections = (
  columns: ColumnInstance[]
): MenuSection<ColumnInstance>[] => {
  const organizedItems: Record<string, MenuItem<ColumnInstance>[]> =
    columns.reduce(
      (obj, column) => {
        const path = getPath(column);
        const item = columnsToMenuItem(column);
        const pathValue = obj[path] ?? [];

        return { ...obj, [path]: [...pathValue, item] };
      },
      { itemsWithoutPath: [], individualItems: [] }
    );

  const { itemsWithoutPath, individualItems, ...rest } = organizedItems;

  const other: MenuItem<ColumnInstance>[] = Object.keys(rest).map((key) => {
    return {
      id: key,
      label: i18next.t(key),
      sections: [{ items: sortItemsByLabel(rest[key]) }],
    };
  });

  const sections: MenuSection<ColumnInstance>[] = [];

  if (itemsWithoutPath.length > 0) {
    sections.push({ items: sortItemsByLabel(itemsWithoutPath) });
  }

  if (individualItems.length > 0 && other.length > 0) {
    sections.push({
      items: [
        ...sortItemsByLabel(other),
        {
          id: 'MASK.INDIVIDUAL',
          label: i18next.t('MASK.INDIVIDUAL'),
          sections: [{ items: sortItemsByLabel(individualItems) }],
        },
      ],
    });
  } else if (individualItems.length > 0) {
    sections.push({
      items: [
        {
          id: 'MASK.INDIVIDUAL',
          label: i18next.t('MASK.INDIVIDUAL'),
          sections: [{ items: sortItemsByLabel(individualItems) }],
        },
      ],
    });
  } else if (other.length > 0) {
    sections.push({ items: other });
  }

  return sections;
};
