import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { useDialogs } from '@work4all/components';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { useEntityEventsContext } from '@work4all/data/lib/entity-events/entity-events-context';

import { Offer } from '@work4all/models/lib/Classes/Offer.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

import { SalesOpportunitiesOverlayController } from '../../../../containers/mask-overlays/mask-overlay/views/sales-opportunities/SalesOpportunitiesOverlayController';

const CONVERT_BZ_OBJECT_TO_SALES_OPPORTUNITY = gql`
  mutation ConvertBzObjectToSalesOpportunity(
    $request: ErpObjectToSalesOpportunityConversionRequest!
  ) {
    convertBzObjectToSalesOpportunity(request: $request) {
      code
    }
  }
`;

interface ConvertBzObjectToSalesOpportunityResponse {
  convertBzObjectToSalesOpportunity: {
    code: number;
  };
}

interface ConvertBzObjectToSalesOpportunityVars {
  request: {
    bzObjCodes: number[];
    bzObjType: BzObjType;
  };
}

export const useConvertBzObjectToSalesOpportunity = () => {
  const [mutate] = useMutation<
    ConvertBzObjectToSalesOpportunityResponse,
    ConvertBzObjectToSalesOpportunityVars
  >(CONVERT_BZ_OBJECT_TO_SALES_OPPORTUNITY);

  const dialogs = useDialogs();
  const { emit: emitEntityEvent } = useEntityEventsContext();

  const mutateToSales = useCallback(
    (entity: Entities, data: Offer[]) => {
      mutate({
        variables: {
          request: {
            bzObjCodes: data.map((x) => x.id),
            bzObjType: convertEntityToBzObjType(entity),
          },
        },
        onCompleted: (data) => {
          const dialog = dialogs.open(NavigationOverlay, {
            initialView: {
              view: (
                <SalesOpportunitiesOverlayController
                  id={data.convertBzObjectToSalesOpportunity.code}
                  entity={Entities.salesOpportunities}
                  onAfterSave={closeMaskOverlay}
                />
              ),
            },
            close: closeMaskOverlay,
          });
          function closeMaskOverlay() {
            dialogs.close(dialog.id);
          }

          emitEntityEvent({
            type: 'upsert',
            entity,
            data: { id: data.convertBzObjectToSalesOpportunity.code },
          });
        },
      });
    },
    [mutate]
  );

  return mutateToSales;
};
