import { IRow, Path } from '../../../types';

type Props = {
  path: Path;
  data: {
    items: IRow[];
    total: number;
  };
  footerData: object;
};

type Return = {
  items: IRow[];
  total: number;
};

export const mergeDataWithFooterData = ({
  path,
  data,
  footerData = {},
}: Props): Return => {
  if (Object.keys(footerData).length === 0) {
    return data;
  }

  return {
    ...data,
    total: data.total + 1,
    items: [
      ...data.items,
      {
        ...footerData,
        meta: { path, isGroupFooter: true },
      },
    ],
  };
};
