import { createContext, ReactNode, useContext, useMemo } from 'react';

import { useTableStateBag } from '@work4all/components';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';

import { settings, useSetting } from '../../../../settings';
import { useProjectAppointments } from '../hooks/useProjectAppointments';
import { useProjectErpObjects } from '../hooks/useProjectErpObjects';
import { useProjectTimeTrackings } from '../hooks/useProjectTimeTrackings';

const PROJECT_TIME_COL_ID = 'projectTimeTracking';

interface ProjectPlanningDataProviderProps {
  projectList: Project[];
  children: ReactNode;
}

interface ProjectPlanningDataContextType {
  erpObjectList: Contract[] | Calculation[];
  erpObjectListLoading: boolean;
  appointmentList: Appointment[];
  appointmentLoading: boolean;
  timeTrackingList: TimeTracking[];
  timeTrackingListLoading: boolean;
}

const ProjectPlanningDataContext =
  createContext<ProjectPlanningDataContextType | null>(null);
const PROJECT_EMPTY_LIST = [];

// TODO: Leo we need to query all relevant data + query it once per projectId
export const ProjectPlanningDataProvider = ({
  projectList = PROJECT_EMPTY_LIST,
  children,
}: ProjectPlanningDataProviderProps) => {
  const projectIdList = useMemo(() => {
    return [
      ...projectList,
      ...projectList.flatMap((pr) => pr?.childProjects || []),
    ]
      ?.map((pr) => pr?.id)
      .filter(Boolean);
  }, [projectList]);

  const projectPlanningTargetSource = useSetting(
    settings.projectPlanningTargetSource()
  );
  const entity = projectPlanningTargetSource.value;

  const projectPlanningColumns = useSetting(settings.projectPlanningColumns());

  const tableStateBag = useTableStateBag();
  const skipProjectTime = useMemo(() => {
    return (
      !projectPlanningColumns.value.actual &&
      !tableStateBag?.visibleColumns?.some(
        (col) => col.id === PROJECT_TIME_COL_ID
      )
    );
  }, [projectPlanningColumns.value.actual, tableStateBag?.visibleColumns]);

  const { data: erpObjectList, pending: erpObjectListLoading } =
    useProjectErpObjects({
      projectIdList,
      entity,
      skip: !projectPlanningColumns.value.target,
    });

  const { data: appointmentList, pending: appointmentLoading } =
    useProjectAppointments({
      projectIdList,
      skip: !projectPlanningColumns.value.planned,
    });

  const { data: timeTrackingList, pending: timeTrackingListLoading } =
    useProjectTimeTrackings({
      projectIdList,
      skip: skipProjectTime,
    });

  return (
    <ProjectPlanningDataContext.Provider
      value={{
        erpObjectList,
        erpObjectListLoading,
        appointmentList,
        appointmentLoading,
        timeTrackingList,
        timeTrackingListLoading,
      }}
    >
      {children}
    </ProjectPlanningDataContext.Provider>
  );
};

export const useProjectPlanningData = () => {
  const context = useContext(ProjectPlanningDataContext);
  if (!context) {
    throw new Error(
      'useProjectPlanningData must be used within a ProjectPlanningDataProvider'
    );
  }
  return context;
};
