import styles from './InlineFlagCell.module.scss';

import { get } from 'lodash';

import { FlagIcon } from '@work4all/components';
import { ICardWidgetRenderCellParams } from '@work4all/components/lib/dataDisplay/card-widget/types';

type InlineFlagCellProps = ICardWidgetRenderCellParams<object, unknown>;

export function InlineFlagCell(props: InlineFlagCellProps) {
  const { value, row } = props;

  const firstLine = value?.toString().split('\n')[0];
  const isFlagged = !!get(row, 'favoriteItem.id');

  return (
    <div className={styles.root}>
      <span className={styles.text}>{firstLine}</span>
      {isFlagged && <FlagIcon isFlagged />}
    </div>
  );
}
