import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputChecklistePosition } from './InputChecklistePosition.entity';

export class InputChecklistPositionRelation<T extends EMode = EMode.entity> {
  /** Alias: delete */
  delete?: number[];
  /** Alias: modify */
  modify?: InputChecklistePosition<T>[];
  /** Alias: add */
  add?: InputChecklistePosition<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<InputChecklistPositionRelation> = {
  delete: {
    alias: 'delete',
  },
  modify: {
    alias: 'modify',
  },
  add: {
    alias: 'add',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputChecklistPositionRelationEntityDefinition: EntitiyDefinition<InputChecklistPositionRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputChecklistPositionRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
