import styles from './GridLayout.module.scss';

import { useTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { Responsive, ResponsiveProps, WidthProvider } from 'react-grid-layout';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

export const DEFAULT_GRID_ROW_HEIGHT_REM = 2;
export const DEFAULT_GRID_GAP_REM = 1;

export const GridLayout = (
  props: ResponsiveProps & {
    children: React.ReactNode;
    rowHeightPx?: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sizeDeps?: any[];
  }
) => {
  const { sizeDeps = [] } = props;

  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [...sizeDeps]
  );

  const theme = useTheme();
  const muiBreakpoints = theme.breakpoints.values;

  // Map MUI breakpoints to react-grid-layout
  const breakpoints = {
    lg: 1500,
    md: muiBreakpoints.md,
    sm: muiBreakpoints.sm,
    xs: muiBreakpoints.xs,
  };

  const rem = remToPx(1);

  return (
    <div className={styles.reactGrid}>
      <ResponsiveReactGridLayout
        className="layout"
        breakpoints={breakpoints}
        margin={[rem, 0]}
        rowHeight={remToPx(DEFAULT_GRID_ROW_HEIGHT_REM)}
        useCSSTransforms={false}
        resizeHandles={['se']}
        draggableHandle=".drag-handle"
        {...props}
      >
        {props.children}
      </ResponsiveReactGridLayout>
    </div>
  );
};
