import { createContext, useContext } from 'react';

import { MeasureFunction } from './types';

export const RowMeasurerContext = createContext<{
  measure: MeasureFunction;
  getRowHeight: (index: number) => number;
}>(null);

export function useRowMeasurerContext() {
  const context = useContext(RowMeasurerContext);
  if (!context) {
    throw new Error(
      'useRowMeasurerContext must be used within a RowMeasurerContext'
    );
  }
  return context;
}
