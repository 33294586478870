import { createContext, useContext, useMemo } from 'react';

import { IStackItem } from '@work4all/components/lib/navigation/history-stack';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../settings';

import { HistoryWidget } from './HistoryWidget';
import { ProjectTimeMaskInit } from './ProjectTimeMask';
import TimeTemplatesWidget from './TimeTemplatesWidget';

export interface TimeTemplatesAndHistoryOverlayProps {
  open: boolean;
  onClose: () => void;
  onTemplateSelect: (init: ProjectTimeMaskInit, createdFrom: Entities) => void;
}

const VIEW: IStackItem = {
  view: <TimeTemplatesAndHistoryOverlayContent />,
};

export function TimeTemplatesAndHistoryOverlay(
  props: TimeTemplatesAndHistoryOverlayProps
) {
  const { open, onClose, onTemplateSelect } = props;

  const context = useMemo(() => ({ onTemplateSelect }), [onTemplateSelect]);

  return (
    <TimeTemplatesAndHistoryOverlayContext.Provider value={context}>
      <NavigationOverlay open={open} initialView={VIEW} close={onClose} />
    </TimeTemplatesAndHistoryOverlayContext.Provider>
  );
}

type TimeTemplatesAndHistoryOverlayContextValue = Pick<
  TimeTemplatesAndHistoryOverlayProps,
  'onTemplateSelect'
>;

const TimeTemplatesAndHistoryOverlayContext =
  createContext<TimeTemplatesAndHistoryOverlayContextValue>(null);

function TimeTemplatesAndHistoryOverlayContent() {
  const context = useContext(TimeTemplatesAndHistoryOverlayContext);

  const projectTimeTrackingWidget = useSetting(
    settings.projectTimeTrackingWidget()
  );

  switch (projectTimeTrackingWidget.value) {
    case 'history':
      return <HistoryWidget mobileMode />;
    case 'templates':
      return (
        <TimeTemplatesWidget
          mobileMode
          onTemplateSelect={context.onTemplateSelect}
        />
      );
    default:
      return null;
  }
}
