import styles from './ReportPicker.module.scss';

import { Star } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider/useDataProvider';

import { Report } from '@work4all/models/lib/Classes/Report.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ReportBzObjType } from '@work4all/models/lib/Enums/ReportBzObjType.enum';

import { FixedDataPicker } from '../fixed-data-picker/FixedDataPicker';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IReportPickerProps<TMultiple extends boolean> = Omit<
  IPickerProps<Report, TMultiple> & {
    reportBzObjectType?: ReportBzObjType;
  },
  'onListChanged'
>;

const mapToOption = (item: Report) => ({
  id: item.id,
  name: item.note,
});

export function ReportPicker<TMultiple extends boolean>(
  props: IReportPickerProps<TMultiple>
) {
  const { data, reportBzObjectType, value, onChange, ...rest } = props;

  const { options: reports, data: orginals } =
    useReportOptions(reportBzObjectType);

  const mappedValue = !value
    ? value
    : Array.isArray(value)
    ? value.map(mapToOption)
    : mapToOption(value);

  const handleSelection = (
    selection: Selection<{ id: number; name: string }, TMultiple>
  ) => {
    if (Array.isArray(selection)) {
      const ids = selection.map((x) => x.id);
      const orginal: Report[] = orginals.filter((x) => ids.includes(x.id));
      onChange(orginal as Selection<Report, TMultiple>);
      return;
    }
    const single: Report = orginals.find((x) => x.id === selection.id);
    onChange(single as Selection<Report, TMultiple>);
  };

  return (
    <FixedDataPicker
      dataSet={reports}
      {...rest}
      value={mappedValue as Selection<{ id: number; name: string }, TMultiple>}
      onChange={handleSelection}
      renderItemContent={(option) => {
        const report = orginals.find((x) => x.id === option.id);
        return (
          <Box display="flex" justifyContent="space-between">
            <Typography className={styles.description}>
              {option.name}
            </Typography>
            {report.standardReport ? (
              <Star className={styles.favActive} />
            ) : null}
          </Box>
        );
      }}
    />
  );
}

export const useReportOptions = (bzObjType?: ReportBzObjType) => {
  const requestData = useMemo<DataRequest>(() => {
    const filterBy = [bzObjType];

    if (
      bzObjType === ReportBzObjType.ANGEBOT ||
      bzObjType === ReportBzObjType.AUFTRAG
    ) {
      filterBy.push(ReportBzObjType.ANGEBOT_AUFTRAG);
    }

    return {
      entity: Entities.report,
      data: REPORT_FIELDS,
      filter: bzObjType
        ? [
            {
              bzObjType: { $in: filterBy },
            },
          ]
        : undefined,
      sort: [
        {
          direction: SortDirection.ASCENDING,
          field: 'note',
        },
      ],
    };
  }, [bzObjType]);

  const { data } = useDataProvider<Report>(requestData);

  const sortedData = useMemo(() => {
    const result = data.filter((x) => x.reportFileName.endsWith('.rpt'));
    result.sort((a, b) => Number(b.standardReport) - Number(a.standardReport));
    return result;
  }, [data]);

  const options = useMemo(() => {
    return sortedData.map(mapToOption);
  }, [sortedData]);

  return { options, data: sortedData };
};

export const REPORT_FIELDS: Report = {
  id: null,
  bzObjType: null,
  reportFileName: null,
  note: null,
  standardReport: null,
  reportOptions: [
    {
      displayname: null,
      fullname: null,
      optionId: null,
      visibleForBzObjectType: null,
    },
  ],
};
