import styles from '../appointmentview/AppointmentView.module.scss';

import { Grid, Stack } from '@mui/material';
import React, { useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TeamsIcon } from '@work4all/assets/icons/teams.svg';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { AppointmentStatePicker } from '@work4all/components/lib/components/entity-picker/appointmentStatePicker/AppointmentStatePicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';
import { Link } from '@work4all/components/lib/typography/link/Link';

import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { TeamsMeetingCheckbox } from '../../../../../../../../../../components/TeamsMeetingCheckbox';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  LabeledInput,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { TabPanelSectionProps } from '../../../../../../..';
import { AppointmentMaskFormValue, TeamsMeeting } from '../../../../../types';

const collapseClasses = {
  root: styles.collapseContainer,
  wrapperInner: styles.collapseWrapperInner,
};

export const Description: React.FC<TabPanelSectionProps> = () => {
  const { t } = useTranslation();
  const stateWrapperRef = useRef<HTMLDivElement>();

  const { control, register } = useFormContextPlus<AppointmentMaskFormValue>();
  const isPrivate = useWatch({ control, name: 'privat' });

  return (
    <Collapse
      classes={collapseClasses}
      title={t('MASK.DESCRIPTION')}
      className={styles.collapse}
      defaultOpen={true}
    >
      <Card>
        <Grid container spacing="1rem">
          <Grid item xs={12} md={12} lg={6}>
            <LabeledInput {...register('title')} label={t('COMMON.SUBJECT')} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <LabeledInput {...register('city')} label={t('COMMON.CITY')} />
          </Grid>
          <Grid item xs={6} md={4} lg={2} ref={stateWrapperRef}>
            <ControllerPlus
              control={control}
              name="appointmentState"
              render={({ field: appointmentState }) => {
                return (
                  <EntityPickerPopover
                    anchorEl={stateWrapperRef}
                    picker={
                      <AppointmentStatePicker
                        value={appointmentState?.value}
                        onChange={(value) => {
                          appointmentState.onChange(value);
                        }}
                        multiple={false}
                      />
                    }
                  >
                    <PickerTargetButton
                      onClear={() => appointmentState.onChange(null)}
                      value={appointmentState.value?.name || ''}
                      label={uppercaseFirstLetter(t('COMMON.STATUS'))}
                    />
                  </EntityPickerPopover>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <CheckboxRadioItem
              {...register('privat')}
              label={t('COMMON.PRIVATE_APPOINTMENT')}
              defaultChecked={isPrivate}
            />
          </Grid>
          <Grid item xs={12}>
            <LabeledInput
              {...register('note')}
              label={t('INPUTS.NOTE')}
              multiline={true}
            />
          </Grid>
        </Grid>

        <ControllerPlus
          control={control}
          name="meetingUrl"
          render={({ field: meetingUrlField }) => {
            if (meetingUrlField.value) {
              try {
                const teamsMeeting: TeamsMeeting = JSON.parse(
                  meetingUrlField.value
                );
                if (teamsMeeting.Url)
                  return (
                    <Stack direction="row" alignItems="center" gap="0.25rem">
                      <TeamsIcon />
                      <Body1>{t('COMMON.TEAMS_TERMIN')}:</Body1>
                      <Link sliceTo={41} href={teamsMeeting.Url} />
                    </Stack>
                  );
              } catch (error) {
                console.error(error);
              }
            }

            return (
              <ControllerPlus
                control={control}
                name="exchangeMeeting"
                render={({ field }) => (
                  <TeamsMeetingCheckbox
                    checked={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.checked);
                    }}
                  />
                )}
              />
            );
          }}
        />
      </Card>
    </Collapse>
  );
};
