import styles from './SlideTransition.module.scss';

import clsx from 'clsx';
import React, { useRef } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const TRANSITION_DURATION_MS = 250;

interface IClasses {
  content?: string;
}

type Direction = 'push' | 'pop';

export interface ISlideTransitionProps {
  children?: React.ReactNode;
  currentKey: string;
  direction: Direction;
  classes?: IClasses;
}

export const SlideTransition: React.FC<ISlideTransitionProps> = (props) => {
  const { currentKey, classes, direction, children } = props;

  // Be careful with class names.
  // CSSTransition have issues when className updates dynamically
  // (both in children components and CSSTransition component)
  // Unfortunately issue is still open.
  // https://github.com/reactjs/react-transition-group/issues/318
  return (
    <TransitionGroup
      className={clsx(styles.root, styles[direction])}
      component="div"
      style={{
        '--transitionDuration': `${TRANSITION_DURATION_MS}ms`,
      }}
    >
      <TransitionWrapper
        key={currentKey}
        className={clsx(styles.content, classes?.content)}
        children={children}
      />
    </TransitionGroup>
  );
};

// Create a wrapper component for the transition for easier ref management
function TransitionWrapper(props) {
  const { className, children, ...otherProps } = props;

  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <CSSTransition
      timeout={TRANSITION_DURATION_MS}
      classNames="slide"
      mountOnEnter={false}
      unmountOnExit={true}
      nodeRef={nodeRef}
      {...otherProps}
    >
      <div ref={nodeRef} className={className} children={children} />
    </CSSTransition>
  );
}
