import { TimeTracking } from '@work4all/models/lib/Classes/TimeTracking.entity';
import { TimeTrackingKind } from '@work4all/models/lib/Enums/TimeTrackingKind.enum';

import { ProjectTimeMaskInit } from '../../../../../containers/time-tracker/ProjectTimeMask';

export const timeTrackingToProjectTimeMaskInit = (
  data: TimeTracking
): ProjectTimeMaskInit => {
  const kind =
    data.timeTrackingKind === TimeTrackingKind.VON_BIS
      ? TimeTrackingKind.VON_BIS
      : TimeTrackingKind.ANZAHL;

  const date = new Date();

  return {
    kind,
    date,
    projectProcess: data.projectProcess,
    project: data.project,
    customer: data.customer,
    ticket: data.ticket,
    contract: data.contract,
    activityArticle: data.activityArticle,
    activityArticleId: data.activityArticle?.id,
    position: data.position,
    comment: data.comment,
    customFieldList: data.customFieldList,
  };
};
