import { useDataProvider } from '@work4all/data';

import { TaxKey } from '@work4all/models/lib/Classes/TaxKey.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const REQUEST = {
  data: { id: null, taxKeyValue: null, vat: null },
  entity: Entities.taxKey,
};

export const useTaxKeys = (): TaxKey[] => {
  const { data } = useDataProvider<TaxKey>(REQUEST);

  return data;
};
