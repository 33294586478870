import { gql, useApolloClient } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';
import { useEffect, useMemo, useState } from 'react';

import { FieldDefinitions } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileType } from '@work4all/models/lib/File';
import { WidgetsDefinitions } from '@work4all/models/lib/Layout';

import { useEntityEvents } from '../../entity-events/use-entity-events';
import { useRefetchOnEntityChanges } from '../../hooks';
import { fieldDefinitions } from '../../hooks/data-provider/definitons/fieldDefinitions';

import { widgetsRequest } from './widgets-request';

export const useLayoutsData = (
  fileId: number,
  contactId: number | null,
  definitions: WidgetsDefinitions | undefined,
  querySize: number,
  fileType: FileType,
  querySizeByWidgetId?: Record<string, number>,
  filterFileWidgetsByFavoriteRecords?: boolean
) => {
  const [loadingRequest, setLoadingRequest] = useState<Record<number, boolean>>(
    {}
  );

  const requestData = useMemo(() => {
    return widgetsRequest({
      definitions,
      fileId,
      contactId,
      fileType,
      querySize,
      querySizeByWidgetId,
      filterFileWidgetsByFavoriteRecords,
    });
  }, [
    definitions,
    fileId,
    contactId,
    querySize,
    querySizeByWidgetId,
    fileType,
    filterFileWidgetsByFavoriteRecords,
  ]);

  const client = useApolloClient();
  useEffect(() => {
    const dataSubscriptions = [];
    if (requestData.requestByGroup) {
      Object.keys(requestData.requestByGroup).forEach(
        (groupRequestsKey, idx) => {
          const groupRequests = requestData.requestByGroup[groupRequestsKey];
          setLoadingRequest((current) => ({ ...current, [idx]: true }));
          const response = client.watchQuery({
            query: gql`
          query getInlinedLayoutData${idx} {
            ${groupRequests.join('\n')}
          }
          `,
            context: {
              batch: false,
            },
          });

          const sub = response.subscribe((response) => {
            //update all the data that belongs to your set of widgets
            setWidgetsData((current) => {
              return { ...current, ...response.data };
            });
            setLoadingRequest((current) => ({ ...current, [idx]: false }));
          });
          dataSubscriptions.push(sub);
        }
      );

      return () => {
        dataSubscriptions.forEach((sub) => {
          sub.unsubscribe();
        });
      };
    }
  }, [
    client,
    requestData.allQuery,
    requestData.reqMap,
    requestData.requestByGroup,
  ]);

  const refetchQueries = useEventCallback(() => {
    client.refetchQueries({
      include: Object.keys(requestData.requestByGroup).map(
        (_el, idx) => `getInlinedLayoutData-${idx}`
      ),
    });
  });

  useRefetchOnEntityChanges({
    entity: requestData.entities,
    refetch: refetchQueries,
  });

  useEntityEvents((event) => {
    if (event.entity === Entities.favoriteItem) {
      refetchQueries();
    }
  });

  const [widgetsData, setWidgetsData] = useState<
    Record<
      string,
      {
        widgetTitle: string;
        widgetId: string;
        total: number;
        //eslint-disable-next-line
        data: Record<string, any>[];
      }
    >[]
  >([]);

  return useMemo(() => {
    const mappedData: Record<
      string,
      {
        widgetTitle: string;
        widgetId: string;
        totalCount: number;
        //eslint-disable-next-line
        data: Record<string, any>[];
      }
    > = Object.keys(widgetsData).reduce((acc, reqId) => {
      const data = widgetsData[reqId];
      const widgetDefinition = requestData.reqMap[reqId];

      if (widgetDefinition) {
        //the widget definitions may be gone when logging our but data is requested
        acc[widgetDefinition.id] = {
          widgetId: widgetDefinition.id,
          widgetTitle: widgetDefinition.title,
          totalCount: data.total,
          data: data.data,
        };
      }
      return acc;
    }, {});

    return {
      data: mappedData,
      loading:
        Object.keys(loadingRequest).find(
          (key) => loadingRequest[key] === true
        ) !== undefined,
    };
  }, [loadingRequest, requestData.reqMap, widgetsData]);
};

/**
 * Adds `id` field to the fields list if the it is not present and the entity
 * has a definition for it. Recursively repeats the process for nested entities.
 *
 * This is required for the Apollo cache normalization to work properly.
 */
//eslint-disable-next-line
function addIdFields(fields: any, entity: Entities): void {
  //eslint-disable-next-line
  const definition = fieldDefinitions[entity] as FieldDefinitions<any>;

  // If the entity does not have `id` field, there is nothing to do. There is no
  // reason to check nested entities, because it will be impossible to normalize
  // the cache anyway.
  if (!definition?.id) {
    return;
  }

  fields.id = null;

  // If the entity has nested entities, add `id` field to the fields list.
  const nestedFields = Object.entries(fields).filter(
    ([_, value]) => value !== null
  );

  for (const [key, value] of nestedFields) {
    const def = definition[key.toLowerCase()];

    if (def && def.entity) {
      addIdFields(
        value,
        Array.isArray(def.entity) ? def.entity[0] : def.entity
      );
    }
  }
}
