import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '../types';

import { TaskPreview } from './TaskPreview';

type TaskPreviewContainerProps = EntityPreviewContainerProps;

export const taskPreviewFields: Task<EMode.query> = {
  id: null,
  title: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  topicMarkList: [
    {
      id: null,
      name: null,
    },
  ],
  user: {
    id: null,
    displayName: null,
  },
  creatorUserId: null,
  creator: {
    id: null,
    displayName: null,
  },
  note: null,
  status: null,
  date: null,
  endDate: null,
};

export function TaskPreviewContainer(props: TaskPreviewContainerProps) {
  const {
    ids: taskIds,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const tasks = useEntityPreviewData<Task>(
    taskIds,
    Entities.task,
    taskPreviewFields
  );

  if (!tasks) {
    return null;
  }

  return (
    <TaskPreview
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      entries={tasks}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
