import styles from './ProjectProcessPicker.module.scss';

import { Typography } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ProjectProcessKind } from '@work4all/models/lib/Enums/ProjectProcessKind.enum';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { HeaderRow } from '../components/custom-list-entity-picker/HeaderRow';
import { ItemRow } from '../components/custom-list-entity-picker/ItemRow';
import { EntityPicker } from '../components/v2/EntityPicker';
import { IPickerProps } from '../types';
import { Selection } from '../utils/selection-model';

export type IProjectProcessPickerProps = IPickerProps<ProjectProcess, false> & {
  projectId: number;
  allowSelection?: ProjectProcessKind[];
  onChange: (projectProcess: Selection<ProjectProcess, false>) => void;
};

// This is a trimmed down version of ListEntityPicker with customizations.
export function ProjectProcessPicker(props: IProjectProcessPickerProps) {
  const {
    projectId,
    prefilter: prefilterProp,
    data,
    allowSelection,
    ...otherProps
  } = props;
  const allFields = useMemo(
    () => ({ ...PROJECTPROCESS_FIELDS, ...data }),
    [data]
  );

  const { t } = useTranslation();

  const prefilter: unknown[] = useMemo(() => {
    let result: unknown[] = [{ projectId: { $eq: projectId } }];
    if (prefilterProp) {
      result = [...result, ...prefilterProp];
    } else {
      result = [
        ...result,
        {
          $or: [
            { kindId: { $eq: ProjectProcessKind.VORGANG } },
            { kindId: { $eq: ProjectProcessKind.MEILENSTEIN } },
          ],
        },
      ];
    }
    return result;
  }, [projectId, prefilterProp]);

  const isInteractive = useCallback(
    (item: ProjectProcess) =>
      allowSelection
        ? allowSelection.includes(item.kindId)
        : item.kindId === ProjectProcessKind.VORGANG,
    [allowSelection]
  );

  const renderRow = (projectProcess: ProjectProcess, all: ProjectProcess[]) => {
    return (
      <ItemRow
        key={projectProcess.id}
        isInteractive={isInteractive(projectProcess)}
        prevWasInteractive={isInteractive(
          all[Math.max(1, all.indexOf(projectProcess)) - 1]
        )}
        title={projectProcess.process}
        content={
          <>
            <Typography
              variant={!isInteractive(projectProcess) ? 'h4' : 'body1'}
              className={clsx(styles.process, {
                [styles.withPadding]: !isInteractive(projectProcess),
              })}
            >
              {projectProcess.process}
            </Typography>
            <Typography
              variant={!isInteractive(projectProcess) ? 'h4' : 'body1'}
              className={clsx(styles.startDate, {
                [styles.withPadding]: !isInteractive(projectProcess),
              })}
            >
              {projectProcess.startDatum &&
                DateTime.fromISO(projectProcess.startDatum).toLocaleString(
                  DateTimeCustom.DATE_SHORT
                )}
            </Typography>
          </>
        }
      />
    );
  };

  return (
    <EntityPicker
      entity={Entities.projectProcess}
      data={allFields}
      prefilter={prefilter}
      filterBy={'process'}
      sortBy={'startDatum'}
      transformResponse={(resp) => {
        return {
          ...resp,
          data: resp.data.map((el) => {
            //todo, should we move the unselectable into an extra array?
            return {
              ...el,
              unselectable: !isInteractive(el),
            } as unknown as ProjectProcess<EMode.entity>;
          }),
        };
      }}
      placeholder={t('PROJECT_PROCESS.SEARCH.PLACEHOLDER')}
      renderItemContent={renderRow}
      prependSelected={false}
      prependList={
        <HeaderRow
          content={
            <>
              <Typography variant="h4" className={styles.process}>
                {t('PROJECT_PROCESS.COLUMNS.NAME')}
              </Typography>
              <Typography variant="h4" className={styles.startDate}>
                {t('PROJECT_PROCESS.COLUMNS.START_DATE')}
              </Typography>
            </>
          }
        />
      }
      {...otherProps}
    />
  );
}

const PROJECTPROCESS_FIELDS: ProjectProcess = {
  id: null,
  process: null,
  startDatum: null,
  kindId: null,
};
