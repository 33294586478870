import { PreviewInputsContext } from '../../contexts';
import { PreviewInputsProviderProps } from '../../types';

export const PreviewInputsProvider = ({
  children,
  ...contextValue
}: Omit<PreviewInputsProviderProps, 'numberOfSelectedRows'>) => {
  return (
    <PreviewInputsContext.Provider
      value={{
        ...contextValue,
        numberOfSelectedRows: contextValue.entries.length,
      }}
    >
      {children}
    </PreviewInputsContext.Provider>
  );
};
