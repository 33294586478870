import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { StatisticCustomerDatasheetColumn } from './StatisticCustomerDatasheetColumn.entity';

export class StatisticCustomerDatasheet<T extends EMode = EMode.entity> {
  /** Alias: sdobjMemberCode */
  sdObjMemberId?: number;
  /** Alias: columns */
  columns?: StatisticCustomerDatasheetColumn<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<StatisticCustomerDatasheet> = {
  sdObjMemberId: {
    alias: 'sdobjMemberCode',
  },
  columns: {
    alias: 'columns',
    entity: Entities.statisticCustomerDatasheetColumn,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticCustomerDatasheetEntityDefinition: EntitiyDefinition<StatisticCustomerDatasheet> =
  {
    local: {},
    remote: {
      queryName: 'getCustomerDatasheet',
      fragmentName: 'StatisticCustomerDatasheet',
      withPaginationWrapper: false,
      params: [
        { name: 'sdobjMemberCode', type: 'Int!' },
        { name: 'rangeStart', type: 'Date' },
      ],
    },
    fieldDefinitions: fields,
  };

export const statisticSupplierDatasheetEntityDefinition: EntitiyDefinition<StatisticCustomerDatasheet> =
  {
    local: {},
    remote: {
      queryName: 'getSupplierDatasheet',
      fragmentName: 'StatisticSupplierDatasheet',
      withPaginationWrapper: false,
      params: [
        { name: 'sdobjMemberCode', type: 'Int!' },
        { name: 'rangeStart', type: 'Date' },
      ],
    },
    fieldDefinitions: fields,
  };
