import { Badge } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { UserIcon } from '@work4all/components/lib/components/user-icon/UserIcon';
import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { InboundInvoice } from '@work4all/models/lib/Classes/InboundInvoice.entity';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';

import { NotificationDate } from '../components/NotificationDate';
import { NotificationIcon } from '../components/NotificationIcon';
import { NotificationLayout } from '../components/NotificationLayout';
import { NotificationText } from '../components/NotificationText';
import { NotificationTitle } from '../components/NotificationTitle';
import { NotificationListItemApprovalProps } from '../NotificationListItemApproval';

import { resolveApprovalNotificationData } from './utils/resolve-approval-notification-data';

export function NotificationListItemReApproval(
  props: NotificationListItemApprovalProps
) {
  const { className, style, notification, onClick, count = 1 } = props;

  const { t } = useTranslation();

  const {
    refObject,
    creator,
    approver,
    newCreated,
    isApproved,
    isRejected,
    isForwarded,
  } = resolveApprovalNotificationData(
    notification.object,
    ObjectType.RECHNUNGSEINGAENGE
  );

  const parentObject = refObject as InboundInvoice;
  const note = parentObject?.note;
  const number = parentObject?.originalInvoiceNumber ?? '';

  return (
    <NotificationLayout
      className={className}
      style={style}
      unread={!notification.isRead}
      onClick={onClick}
    >
      <NotificationIcon>
        <Badge
          badgeContent={count.toString()}
          color={notification.isRead ? 'secondary' : 'primary'}
          invisible={count <= 1}
        >
          {notification.fromUser ? (
            <HookedUserIcon userId={notification.fromUser.id} />
          ) : (
            <UserIcon
              shortName="?"
              bgColor="var(--brand01)"
              fontColor="white"
            />
          )}
        </Badge>
      </NotificationIcon>
      <NotificationTitle unread={!notification.isRead}>
        {newCreated || isForwarded ? t('MORE.APPROVAL_CENTER.RE') : note}
      </NotificationTitle>
      <NotificationText>
        {newCreated &&
          t(`NOTIFICATION.APPROVAL.RE.NEW_REQUEST`, {
            user: creator?.displayName,
            number,
          })}
        {isForwarded &&
          t(`NOTIFICATION.APPROVAL.RE.FORWARDED`, {
            user: notification.fromUser?.displayName,
            number,
          })}
        {isApproved && (
          <Trans
            i18nKey={'NOTIFICATION.APPROVAL.RE.APPROVED'}
            values={{
              user: approver?.displayName,
            }}
          />
        )}
        {isRejected && (
          <Trans
            i18nKey={'NOTIFICATION.APPROVAL.RE.REJECTED'}
            values={{
              user: approver?.displayName,
            }}
          />
        )}
      </NotificationText>

      <NotificationDate date={notification.insertTime} />
    </NotificationLayout>
  );
}
