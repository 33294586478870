import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryAssignment } from '@work4all/models/lib/Classes/CategoryAssignment.entity';
import { CategoryClass } from '@work4all/models/lib/Classes/CategoryClass.entity';

export const useCategoryAssignmentCustomRules = (props: {
  mandatoryCategoryClasses: CategoryClass[];
  skip: boolean;
}) => {
  const { mandatoryCategoryClasses, skip } = props;

  const { t } = useTranslation();
  const customRules = useCallback(
    (data) => {
      const categoryAssignmentList: CategoryAssignment[] =
        data.categoryAssignmentList;

      const includesMandatoryCategoryClasses = mandatoryCategoryClasses.every(
        (cl) => categoryAssignmentList.some((ca) => ca.categoryKindId === cl.id)
      );

      if (!includesMandatoryCategoryClasses && !skip) {
        return {
          categoryAssignmentList: {
            message: t('ERROR.MIN_ONE_CATEGORY_PER_BUSINESSPARTNER', {
              categoryNames: mandatoryCategoryClasses
                .map((x) => x.name)
                .join(', '),
            }),
            type: 'customValidation',
          },
        };
      }
      return true;
    },
    [mandatoryCategoryClasses, skip, t]
  );

  return customRules;
};
