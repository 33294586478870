import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';

import { Breakpoint } from '@work4all/utils/lib/variables';

import { AppointmentsGroupMode, AppointmentsTimeRange } from './types';

export const APPOINTMENT_TIME_RANGES: AppointmentsTimeRange[] = [
  'day',
  'week-5',
  'week-7',
  'month',
];
export const DEFAULT_APPOINTMENT_TIME_RANGE: AppointmentsTimeRange = 'week-5';
export const DEFAULT_PLANNING_APPOINTMENT_VALUE = true;

export const APPOINTMENT_GROUP_MODES: AppointmentsGroupMode[] = [
  'grouped',
  'horizontal',
  'vertical',
];
export const DEFAULT_APPOINTMENT_GROUP_MODE: AppointmentsGroupMode = 'grouped';

export const DEFAULT_CALENDAR_WORK_DAY_START = '08:00';
export const DEFAULT_CALENDAR_WORK_DAY_END = '17:00';
export const MAX_HOURS_PRESENT = 20;

export const DEFAULT_APPOINTMENT_STATE: AppointmentState = {
  id: 0,
  name: 'Standard',
  hexColorForeground: '#A1A4B1',
  outOfOffice: false,
};

export const CALENDAR_CSS_BREAKPOINTS: Breakpoint[] = ['xs', 'md', 'lg'];

export const WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000;
