import { cloneDeep } from 'lodash';
import { createContext, useCallback, useEffect, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { Filters } from 'react-table';

import { FilterSortState } from '@work4all/components';
import {
  LayoutResponsiveBreakpoint,
  LayoutResponsiveDefinition,
} from '@work4all/components/lib/utils/react-grid-layout/react-grid-layout-models';

import { useUser } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../settings';
import { deserializeFilter } from '../../../../utils/deserializeFilter';

import { HomeWidgetType } from './HomeWidgetType.enum';
import { DEFAULT_HOME_WIDGET_LAYOUTS } from './layouts';
import { DEFAULT_HOME_WIDGETS } from './widgets';

export const HomeWidgetsContext = createContext<HomeWidgetsContextValue>({
  layouts: {
    lg: [],
    md: [],
    sm: [],
    xs: [],
    xxs: [],
  },
  addWidget: () => null,
  breakpoint: '',
  cols: {
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0,
    xxs: 0,
  },
  draggedWidget: null,
  hoveredWidget: null,
  modifyConf: () => null,
  removeWidgets: () => null,
  removeWidgetsFromLayout: () => null,
  setBreakpoint: () => null,
  setDraggedWidgetId: () => null,
  setHoveredWidgetId: () => null,
  updateLayout: () => null,
  widgets: [],
  resetWidgets: () => null,
});

export interface HomeWidgetsContextProviderProps {
  children: React.ReactNode;
  tenant: number;
}

const cols: Record<LayoutResponsiveBreakpoint, number> = {
  lg: 12,
  md: 9,
  sm: 6,
  xs: 4,
  xxs: 2,
};

export interface EntityWidgetDefinition
  extends Pick<FilterSortState, 'filter'> {
  entity: Entities;
  variant?: string;
  filters?: Filters<never>;
  prefilter?: unknown[];
  tableUrl?: string;
}

export interface HomeWidget {
  id: string;
  type: HomeWidgetType;
  definition?: EntityWidgetDefinition;
  title?: string;
  subWidgetIds?: string[];
}

export interface ModifyConf {
  widgets?: {
    add?: HomeWidget[];
    remove?: string[];
    set?: HomeWidget[];
    update?: Record<string, HomeWidget>;
  };
  layout?: {
    add?: string[];
    remove?: string[];
    set?: LayoutResponsiveDefinition;
    update?: Record<string, Layout>;
  };
}

export interface HomeWidgetsContextValue {
  layouts: LayoutResponsiveDefinition;
  breakpoint: string;
  widgets: HomeWidget[];
  setBreakpoint: (breakpoint: LayoutResponsiveBreakpoint) => void;
  resetWidgets: () => void;
  addWidget: (widget: HomeWidget) => void;
  removeWidgets: (id: string[]) => void;
  removeWidgetsFromLayout: (id: string[]) => void;
  updateLayout: (layout: Layout[]) => void;
  cols: Record<LayoutResponsiveBreakpoint, number>;
  draggedWidget: HomeWidget | null;
  setDraggedWidgetId: (id: string) => void;
  hoveredWidget: HomeWidget | null;
  setHoveredWidgetId: (id: string) => void;
  modifyConf: (val: ModifyConf) => void;
}

export function HomeWidgetsContextProvider(
  props: HomeWidgetsContextProviderProps
) {
  const { children } = props;
  const [breakpoint, setBreakpoint] =
    useState<LayoutResponsiveBreakpoint>('lg');

  const persistedHomeWidgetLayouts = useSetting(settings.homeWidgetLayouts());
  const { widgets: persistedWidgets, layouts: persistedLayouts } =
    persistedHomeWidgetLayouts.value;

  const deserializeWidgets = useCallback((widgets: HomeWidget[]) => {
    return widgets.map((widget) => {
      return {
        ...widget,
        definition: {
          ...widget.definition,
          filters: (widget.definition?.filters || []).map((filter) => {
            return {
              ...filter,
              value: {
                ...filter.value,
                ...deserializeFilter(filter.value, filter.value.filterType),
              },
            };
          }),
        },
      } as HomeWidget;
    });
  }, []);

  const [widgets, setWidgets] = useState<HomeWidget[]>(
    deserializeWidgets(persistedWidgets)
  );

  const [layouts, setLayouts] =
    useState<LayoutResponsiveDefinition>(persistedLayouts);

  const [draggedWidget, setDraggedWidget] = useState(null);
  const [hoveredWidget, setHoveredWidget] = useState(null);

  const setDraggedWidgetId = useCallback(
    (id: string) => {
      setDraggedWidget(widgets.find((x) => x.id === id) || null);
    },
    [widgets]
  );

  const setHoveredWidgetId = useCallback(
    (id: string) => {
      setHoveredWidget(widgets.find((x) => x.id === id) || null);
    },
    [widgets]
  );

  const user = useUser();

  useEffect(() => {
    setWidgets(deserializeWidgets(persistedWidgets));
    setLayouts(persistedLayouts);
  }, [persistedLayouts, deserializeWidgets, persistedWidgets, user]);

  const modifyConf = useCallback(
    (props: ModifyConf) => {
      const {
        widgets: w = { add: [], remove: [] },
        layout: l = { add: [], remove: [] },
      } = props;
      const {
        add: addWidgets = [],
        remove: removeWidgets = [],
        set: widgetsSet,
      } = w;
      const {
        add: addLayouts = [],
        remove: removeLayouts = [],
        set: layoutsSet,
      } = l;

      let newWidgets: HomeWidget[] = null;
      if (widgetsSet) {
        newWidgets = widgetsSet;
      } else {
        newWidgets = [...widgets, ...addWidgets].filter(
          (x) => !removeWidgets.includes(x.id)
        );

        newWidgets.map((w) => {
          w.subWidgetIds?.filter((x) => !removeWidgets.includes(w.id));
          return w;
        });
      }

      let newLayouts: LayoutResponsiveDefinition = null;

      if (layoutsSet) {
        newLayouts = layoutsSet;
      } else {
        newLayouts = cloneDeep(layouts);
        for (const key in newLayouts) {
          for (const widgetId of addLayouts) {
            newLayouts[key]?.push<Layout>({
              i: widgetId,
              x: 0,
              y: Infinity,
              h: 3,
              w: 4,
              minW: 2,
            });
          }
          newLayouts[key] = newLayouts[key].filter(
            (x) => ![...removeLayouts, ...removeWidgets].includes(x.i)
          );
        }
        setLayouts(newLayouts);
      }

      setWidgets(newWidgets);
      persistedHomeWidgetLayouts.set({
        layouts: newLayouts,
        widgets: newWidgets,
      });
    },
    [layouts, persistedHomeWidgetLayouts, widgets]
  );

  const addWidget = useCallback(
    (widget: HomeWidget) => {
      const newWidgets = [...widgets, widget];
      setWidgets(newWidgets);

      const newLayouts: LayoutResponsiveDefinition = cloneDeep(layouts);
      for (const key in newLayouts) {
        newLayouts[key]?.push<Layout>({
          i: widget.id,
          x: 1,
          y: Infinity,
          h: 3,
          w: 5,
          minW: 2,
        });
      }
      setLayouts(newLayouts);

      persistedHomeWidgetLayouts.set({
        layouts: newLayouts,
        widgets: newWidgets,
      });
    },
    [layouts, persistedHomeWidgetLayouts, widgets]
  );

  const removeWidgets = useCallback(
    (ids: string[]) => {
      const newLayouts = cloneDeep(layouts);
      for (const key in newLayouts) {
        newLayouts[key] = newLayouts[key].filter((x) => !ids.includes(x.i));
      }
      setLayouts(newLayouts);

      const newWidgets = widgets.filter((x) => !ids.includes(x.id));
      setWidgets(newWidgets);

      persistedHomeWidgetLayouts.set({
        layouts: newLayouts,
        widgets: newWidgets,
      });
    },
    [layouts, persistedHomeWidgetLayouts, widgets]
  );

  const removeWidgetsFromLayout = useCallback(
    (ids: string[]) => {
      const newLayouts = cloneDeep(layouts);
      for (const key in newLayouts) {
        newLayouts[key] = newLayouts[key].filter((x) => !ids.includes(x.i));
      }
      setLayouts(newLayouts);

      persistedHomeWidgetLayouts.set({
        layouts: newLayouts,
        widgets,
      });
    },
    [layouts, persistedHomeWidgetLayouts, widgets]
  );

  const updateLayout = useCallback(
    (layout: Layout[]) => {
      const newLayouts = cloneDeep({ ...layouts, [breakpoint]: layout });
      setLayouts(newLayouts);
      persistedHomeWidgetLayouts.set({
        ...persistedHomeWidgetLayouts.value,
        layouts: newLayouts,
      });
    },
    [breakpoint, layouts, persistedHomeWidgetLayouts]
  );

  const resetWidgets = useCallback(() => {
    persistedHomeWidgetLayouts.set({
      layouts: DEFAULT_HOME_WIDGET_LAYOUTS,
      widgets: DEFAULT_HOME_WIDGETS,
    });
  }, [persistedHomeWidgetLayouts]);

  return (
    <HomeWidgetsContext.Provider
      value={{
        layouts,
        cols,
        addWidget,
        removeWidgets,
        removeWidgetsFromLayout,
        updateLayout,
        breakpoint,
        setBreakpoint,
        widgets,
        draggedWidget,
        setDraggedWidgetId,
        hoveredWidget,
        setHoveredWidgetId,
        modifyConf,
        resetWidgets,
      }}
    >
      {children}
    </HomeWidgetsContext.Provider>
  );
}
