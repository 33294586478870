export enum ApiValidationErrorType {
  GENERAL_REQUIRED_FIELD_MISSING = 'GENERAL_REQUIRED_FIELD_MISSING',
  GENERAL_ENTITY_NOT_FOUND = 'GENERAL_ENTITY_NOT_FOUND',
  GENERAL_ENTITY_PROTECTION_CONFLICT = 'GENERAL_ENTITY_PROTECTION_CONFLICT',
  GENERAL_USER_RIGHT_CONFLICT = 'GENERAL_USER_RIGHT_CONFLICT',
  GENERAL_FIELD_NOT_FOUND = 'GENERAL_FIELD_NOT_FOUND',
  GENERAL_UNEXPECTED_EXCEPTION = 'GENERAL_UNEXPECTED_EXCEPTION',
  GENERAL_INSERT_NOT_ALLOWED = 'GENERAL_INSERT_NOT_ALLOWED',
  GENERAL_ILLEGAL_VALUE = 'GENERAL_ILLEGAL_VALUE',
  GENERAL_DESERIALIZE_ERROR = 'GENERAL_DESERIALIZE_ERROR',
  GENERAL_VALIDATION_ERROR = 'GENERAL_VALIDATION_ERROR',
  GENERAL_CODE_OR_ID_ALREADY_EXISTS = 'GENERAL_CODE_OR_ID_ALREADY_EXISTS',
  GENERAL_START_DATE_GREATER_END_DATE = 'GENERAL_START_DATE_GREATER_END_DATE',
  GENERAL_ITEM_ALREAD_EXISTS = 'GENERAL_ITEM_ALREAD_EXISTS',
  GENERAL_PARENT_CHANGE_NOT_ALLOWED = 'GENERAL_PARENT_CHANGE_NOT_ALLOWED',
  GENERAL_CONNECTED_ITEMS_BELONGS_TO_OTHER_ENTITY = 'GENERAL_CONNECTED_ITEMS_BELONGS_TO_OTHER_ENTITY',
  GENERAL_CONFLICTING_DATA = 'GENERAL_CONFLICTING_DATA',
  GENERAL_FIELD_CHANGE_NOT_ALLOWED = 'GENERAL_FIELD_CHANGE_NOT_ALLOWED',
  GENERAL_NOT_ALLOWED_ON_NEW = 'GENERAL_NOT_ALLOWED_ON_NEW',
  GENERAL_ONLY_OWN_ITEMS_ARE_ALLOWED = 'GENERAL_ONLY_OWN_ITEMS_ARE_ALLOWED',
  GENERAL_MUTATION_WITHOUT_CHANGES = 'GENERAL_MUTATION_WITHOUT_CHANGES',
  GENERAL_ILLEGAL_FILE_TYPE = 'GENERAL_ILLEGAL_FILE_TYPE',
  TICKET_SOLUTION_IS_REQUIRED = 'TICKET_SOLUTION_IS_REQUIRED',
  TASK_NOT_ALLOWED_TO_CHANGE_UNREAD_STATE = 'TASK_NOT_ALLOWED_TO_CHANGE_UNREAD_STATE',
  TIME_TRACKING_SELECTED_PROJECT_CLOSED = 'TIME_TRACKING_SELECTED_PROJECT_CLOSED',
  TIME_TRACKING_SELECTED_PROJECT_STEP_CLOSED = 'TIME_TRACKING_SELECTED_PROJECT_STEP_CLOSED',
  TIME_TRACKING_SELECTED_PROJECT_STEP_WOULD_BE_OVERBOOKED = 'TIME_TRACKING_SELECTED_PROJECT_STEP_WOULD_BE_OVERBOOKED',
  TIME_TRACKING_SELECTED_POSITION_IS_SET_AS_COMPLETED = 'TIME_TRACKING_SELECTED_POSITION_IS_SET_AS_COMPLETED',
  TIME_TRACKING_EDIT_NOT_ALLOWED = 'TIME_TRACKING_EDIT_NOT_ALLOWED',
  LETTER_TEMPLATE_CODE_AND_TEMP_FILE_ID_NOT_ALLOWED_AT_SAME_TIME = 'LETTER_TEMPLATE_CODE_AND_TEMP_FILE_ID_NOT_ALLOWED_AT_SAME_TIME',
  SEND_MAIL_JOB_FAILED = 'SEND_MAIL_JOB_FAILED',
  GROUP_QUERY_AGGREGATION_ON_FIELD_NOT_VALID = 'GROUP_QUERY_AGGREGATION_ON_FIELD_NOT_VALID',
  GROUP_QUERY_SORT_DEFINITION_WITHOUT_AGGREGATION = 'GROUP_QUERY_SORT_DEFINITION_WITHOUT_AGGREGATION',
  GROUP_QUERY_GROUP_KEY_NOT_FOUND_IN_ENTITY = 'GROUP_QUERY_GROUP_KEY_NOT_FOUND_IN_ENTITY',
  GROUP_QUERY_COUNT_ON_CODE_FIELDS_ARE_ALWAYS_42 = 'GROUP_QUERY_COUNT_ON_CODE_FIELDS_ARE_ALWAYS_42',
  GROUP_QUERY_USING_PAGE_OR_SIZE_NEEDS_SORTING_INFO = 'GROUP_QUERY_USING_PAGE_OR_SIZE_NEEDS_SORTING_INFO',
  GROUP_QUERY_GROUP_ON_TYPE_NEBENADRESSE_NOT_POSSIBLE = 'GROUP_QUERY_GROUP_ON_TYPE_NEBENADRESSE_NOT_POSSIBLE',
  GROUP_QUERY_GROUP_KEY_NESTING_GREATER_THEN_2 = 'GROUP_QUERY_GROUP_KEY_NESTING_GREATER_THEN_2',
  WORK_TIME_TRACKING_TIMESTAMP_BEFORE_OTHER_TIMESTAMP_IN_SAME_BLOCK = 'WORK_TIME_TRACKING_TIMESTAMP_BEFORE_OTHER_TIMESTAMP_IN_SAME_BLOCK',
  WORK_TIME_TRACKING_TIMESTAMP_TOO_EARLY_WOULD_REORDER_EXISTING_ITEMS = 'WORK_TIME_TRACKING_TIMESTAMP_TOO_EARLY_WOULD_REORDER_EXISTING_ITEMS',
  COMPANY_NUMMER_ALREADY_EXISTS = 'COMPANY_NUMMER_ALREADY_EXISTS',
  PROJECT_CONTACT_CODE_WITH_COMPANY_CODE = 'PROJECT_CONTACT_CODE_WITH_COMPANY_CODE',
  APPOINTMENT_UNABLE_TO_CHANGE_FOREIGN_PRIVATE_APPOINTMENTS = 'APPOINTMENT_UNABLE_TO_CHANGE_FOREIGN_PRIVATE_APPOINTMENTS',
  EMAIL_ATTACHMENT_BELONGS_TO_OTHER_ENTITY = 'EMAIL_ATTACHMENT_BELONGS_TO_OTHER_ENTITY',
  EMAIL_ATTACHEMENT_MISSING_TEMP_FILE_ID_OR_E_MAIL_ATTACHEMENT_CODE = 'EMAIL_ATTACHEMENT_MISSING_TEMP_FILE_ID_OR_E_MAIL_ATTACHEMENT_CODE',
  EMAIL_RECIPIENT_ADDRESS_INVALID = 'EMAIL_RECIPIENT_ADDRESS_INVALID',
  APPOINTMENT_ADD_ATTENDEE_WITH_SD_OBJ_MEMBER_CODE_NEEDS_SD_OBJ_TYPE = 'APPOINTMENT_ADD_ATTENDEE_WITH_SD_OBJ_MEMBER_CODE_NEEDS_SD_OBJ_TYPE',
  APPOINTMENT_UNABLE_TO_DELETE_ATTENDEE = 'APPOINTMENT_UNABLE_TO_DELETE_ATTENDEE',
  BZ_OBJECT_NUMBER_ALREADY_EXIST = 'BZ_OBJECT_NUMBER_ALREADY_EXIST',
  BZ_OBJECT_CURRENCY_NOT_SET_OR_DOES_NOT_EXIST = 'BZ_OBJECT_CURRENCY_NOT_SET_OR_DOES_NOT_EXIST',
  BZ_OBJECT_SOURCES_WITH_MULTIPLE_BUSINESS_PARTNER = 'BZ_OBJECT_SOURCES_WITH_MULTIPLE_BUSINESS_PARTNER',
  DOCUMENT_MULTIPLE_FILES_ONLY_ON_NEW_RECORD = 'DOCUMENT_MULTIPLE_FILES_ONLY_ON_NEW_RECORD',
  DOCUMENT_CHILD_DOCUMENT_UNABLE_TO_MANIPULATE_VALUE = 'DOCUMENT_CHILD_DOCUMENT_UNABLE_TO_MANIPULATE_VALUE',
  RA_OBJECT_IS_DATEV_EXPORTED = 'RA_OBJECT_IS_DATEV_EXPORTED',
  RE_OBJECT_IS_DATEV_EXPORTED = 'RE_OBJECT_IS_DATEV_EXPORTED',
  RE_NUMBER_ALREADY_EXISTS = 'RE_NUMBER_ALREADY_EXISTS',
  TRAVEL_RECEIPT_HEADER_NOT_FOUND = 'TRAVEL_RECEIPT_HEADER_NOT_FOUND',
  TRAVEL_RECEIPT_HEADER_ALREADY_CLOSED = 'TRAVEL_RECEIPT_HEADER_ALREADY_CLOSED',
  TRAVEL_RECEIPT_SUPPLIER_IS_NOT_A_USER_SUPPLIER = 'TRAVEL_RECEIPT_SUPPLIER_IS_NOT_A_USER_SUPPLIER',
  EVENT_ITEM_ONLY_ALLOWED_FOR_COMMENTS = 'EVENT_ITEM_ONLY_ALLOWED_FOR_COMMENTS',
  INVOICE_ALREADY_IN_ACCOUNTING = 'INVOICE_ALREADY_IN_ACCOUNTING',
  PROJECT_STEP_LINK_PROJECT_STEP_CODES_INVALID = 'PROJECT_STEP_LINK_PROJECT_STEP_CODES_INVALID',
  PROJECT_STEP_LINK_PROJECT_STEP_CODES_EQUAL = 'PROJECT_STEP_LINK_PROJECT_STEP_CODES_EQUAL',
  RA_PAYMENT_ALREADY_EXPORTED_TO_ACCOUNTING = 'RA_PAYMENT_ALREADY_EXPORTED_TO_ACCOUNTING',
  RA_PAYMENT_VALUE_0_NOT_ALLOWED = 'RA_PAYMENT_VALUE_0_NOT_ALLOWED',
  PROJECT_DIRECTORY_ALREADY_SET = 'PROJECT_DIRECTORY_ALREADY_SET',
  PROJECT_DIRECTORY_NOT_IDENTIFIABLE = 'PROJECT_DIRECTORY_NOT_IDENTIFIABLE',
  PROJECT_DIRECTORY_DOES_NOT_EXIST = 'PROJECT_DIRECTORY_DOES_NOT_EXIST',
  BZ_OBJECT_MUTATION_BZ_OBJ_TYPE_NOT_ALLOWED = 'BZ_OBJECT_MUTATION_BZ_OBJ_TYPE_NOT_ALLOWED',
  BZ_OBJECT_MUTATION_NOT_FOUND_OR_ACCESS_LOCKED = 'BZ_OBJECT_MUTATION_NOT_FOUND_OR_ACCESS_LOCKED',
  BZ_OBJECT_MUTATION_INVALID_FOR_INVOICE = 'BZ_OBJECT_MUTATION_INVALID_FOR_INVOICE',
  ARTICLE_ONLY_POSSIBLE_FOR_BOMS = 'ARTICLE_ONLY_POSSIBLE_FOR_BOMS',
  ARTICLE_ATTACHMENT_TO_DELETE_WRONG_TYPE = 'ARTICLE_ATTACHMENT_TO_DELETE_WRONG_TYPE',
  X_RECHNUNG_TO_PDF_FILE_NAME_EMPTY = 'X_RECHNUNG_TO_PDF_FILE_NAME_EMPTY',
  X_RECHNUNG_TO_PDF_FILE_EXTENSION_INVALID = 'X_RECHNUNG_TO_PDF_FILE_EXTENSION_INVALID',
  MUTATE_GROUPS_NO_ROOT_ELEMENT = 'MUTATE_GROUPS_NO_ROOT_ELEMENT',
  MUTATE_GROUPS_GROUP_IN_STRUCTURE_AND_DELETE = 'MUTATE_GROUPS_GROUP_IN_STRUCTURE_AND_DELETE',
  CRM_DEPARTEMENT_DOES_NOT_EXIST = 'CRM_DEPARTEMENT_DOES_NOT_EXIST',
  FULL_USER_MIXED_TIME_TARGET_NOT_ALLOWED = 'FULL_USER_MIXED_TIME_TARGET_NOT_ALLOWED',
  FULL_USER_E_MAIL_MUST_BE_SET = 'FULL_USER_E_MAIL_MUST_BE_SET',
  FULL_USER_NAME_MUST_BE_SET = 'FULL_USER_NAME_MUST_BE_SET',
  FULL_USER_ONE_MASTER_MUST_BE_LEFT = 'FULL_USER_ONE_MASTER_MUST_BE_LEFT',
  FULL_USER_NUMBER_MUST_BE_UNIQUE = 'FULL_USER_NUMBER_MUST_BE_UNIQUE',
  FULL_USER_E_MAIL_ALREADY_IN_USE = 'FULL_USER_E_MAIL_ALREADY_IN_USE',
  FULL_USER_SYNC_OPTION_MAILBOX_ID_MUST_BE_SET = 'FULL_USER_SYNC_OPTION_MAILBOX_ID_MUST_BE_SET',
  FULL_USER_SYNC_OPTION_ONLY_CURRENT_USER = 'FULL_USER_SYNC_OPTION_ONLY_CURRENT_USER',
  FULL_USER_ABBREVIATION_MUST_BE_UNIQUE = 'FULL_USER_ABBREVIATION_MUST_BE_UNIQUE',
  DOCUMENT_LETTER_TEMPLATE_INVALID_FILENAME = 'DOCUMENT_LETTER_TEMPLATE_INVALID_FILENAME',
  DOCUMENT_LETTER_TEMPLATE_FILE_ALREADY_EXISTS = 'DOCUMENT_LETTER_TEMPLATE_FILE_ALREADY_EXISTS',
  BENUTZER_CONTACTS_EXPORT_NOT_ALLOWED = 'BENUTZER_CONTACTS_EXPORT_NOT_ALLOWED',
  FAVORITE_ENTITY_NOT_POSSIBLE = 'FAVORITE_ENTITY_NOT_POSSIBLE',
}
