import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  canAddArticle,
  canAddCustomer,
  canAddCustomerContact,
  canAddEmailSignature,
  canAddEmailTemplate,
  canAddProductionContract,
  canAddProject,
  canAddSupplier,
  canAddSupplierContact,
  canAddTextBuildingBlock,
  canAddTimeTrackingForProjects,
  canAddTravelExpenses,
  canAddUser,
  canAddWordDocumentTemplate,
  canAddWordLetterTemplate,
  canDeleteArticle,
  canDeleteCustomer,
  canDeleteCustomerContact,
  canDeleteEmailSignature,
  canDeleteEmailTemplate,
  canDeleteProductionContract,
  canDeleteProject,
  canDeleteSupplier,
  canDeleteSupplierContact,
  canDeleteTextBuildingBlock,
  canDeleteTravelExpenses,
  canDeleteUser,
  canDeleteWordDocumentTemplate,
  canDeleteWordLetterTemplate,
  canEditArticle,
  canEditCustomer,
  canEditCustomerContact,
  canEditEmailSignature,
  canEditEmailTemplate,
  canEditProductionContract,
  canEditProject,
  canEditSupplier,
  canEditSupplierContact,
  canEditTextBuildingBlock,
  canEditTimeTrackingForOthers,
  canEditTravelExpenses,
  canEditUser,
  canEditWordLDocumentTemplate,
  canEditWordLetterTemplate,
} from '@work4all/utils/lib/permissions';

import { ALL_PERMISSIONS_TYPES } from '../../constants';
import { PermissionsConfigsByEntity } from '../types';

export const GENERAL_PERMISSIONS_CONFIGS: PermissionsConfigsByEntity = {
  [Entities.customer]: {
    permissionsFunctions: {
      canAdd: canAddCustomer,
      canEdit: canEditCustomer,
      canDelete: canDeleteCustomer,
    },
    denyPermissionRules: {
      canAccessCrm: {
        applyWhenValue: false,
        applyWithPermissions: ALL_PERMISSIONS_TYPES,
      },
    },
  },
  [Entities.supplier]: {
    permissionsFunctions: {
      canAdd: canAddSupplier,
      canEdit: canEditSupplier,
      canDelete: canDeleteSupplier,
    },
    denyPermissionRules: {
      canAccessCrm: {
        applyWhenValue: false,
        applyWithPermissions: ALL_PERMISSIONS_TYPES,
      },
    },
  },
  [Entities.project]: {
    permissionsFunctions: {
      canAdd: canAddProject,
      canEdit: canEditProject,
      canDelete: canDeleteProject,
    },
    denyPermissionRules: {
      canAccessProject: {
        applyWhenValue: false,
        applyWithPermissions: ALL_PERMISSIONS_TYPES,
      },
    },
  },
  [Entities.article]: {
    permissionsFunctions: {
      canAdd: canAddArticle,
      canEdit: canEditArticle,
      canDelete: canDeleteArticle,
    },
  },
  [Entities.eMailSignature]: {
    permissionsFunctions: {
      canAdd: canAddEmailSignature,
      canEdit: canEditEmailSignature,
      canDelete: canDeleteEmailSignature,
    },
  },
  [Entities.eMailTemplate]: {
    permissionsFunctions: {
      canAdd: canAddEmailTemplate,
      canEdit: canEditEmailTemplate,
      canDelete: canDeleteEmailTemplate,
    },
  },
  [Entities.productionContract]: {
    permissionsFunctions: {
      canAdd: canAddProductionContract,
      canEdit: canEditProductionContract,
      canDelete: canDeleteProductionContract,
    },
  },
  [Entities.textBuildingBlock]: {
    permissionsFunctions: {
      canAdd: canAddTextBuildingBlock,
      canEdit: canEditTextBuildingBlock,
      canDelete: canDeleteTextBuildingBlock,
    },
  },
  [Entities.travelExpenses]: {
    permissionsFunctions: {
      canAdd: canAddTravelExpenses,
      canEdit: canEditTravelExpenses,
      canDelete: canDeleteTravelExpenses,
    },
  },
  [Entities.wordDocumentTemplate]: {
    permissionsFunctions: {
      canAdd: canAddWordDocumentTemplate,
      canEdit: canEditWordLDocumentTemplate,
      canDelete: canDeleteWordDocumentTemplate,
    },
  },
  [Entities.wordLetterTemplate]: {
    permissionsFunctions: {
      canAdd: canAddWordLetterTemplate,
      canEdit: canEditWordLetterTemplate,
      canDelete: canDeleteWordLetterTemplate,
    },
  },
  [Entities.travelReceipts]: {
    permissionsFunctions: {
      canAdd: canAddTravelExpenses,
      canEdit: canEditTravelExpenses,
      canDelete: canDeleteTravelExpenses,
    },
    denyPermissionRules: {
      canAccessTravelReceipts: {
        applyWhenValue: false,
        applyWithPermissions: ALL_PERMISSIONS_TYPES,
      },
    },
  },
  [Entities.contact]: {
    [Entities.customer]: {
      permissionsFunctions: {
        canAdd: canAddCustomerContact,
        canEdit: canEditCustomerContact,
        canDelete: canDeleteCustomerContact,
      },
    },
    [Entities.supplier]: {
      permissionsFunctions: {
        canAdd: canAddSupplierContact,
        canEdit: canEditSupplierContact,
        canDelete: canDeleteSupplierContact,
      },
    },
  },
  [`${Entities.fullUser}`]: {
    permissionsFunctions: {
      canAdd: canAddUser,
      canEdit: canEditUser,
      canDelete: canDeleteUser,
    },
  },
  [Entities.timeTracking]: {
    permissionsFunctions: {
      canAdd: canAddTimeTrackingForProjects,
      canEdit: canEditTimeTrackingForOthers,
      canDelete: canEditTimeTrackingForOthers,
    },
  },
};
