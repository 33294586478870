import styles from './BirthdaysMenu.module.scss';

import { Person, Phone, Send } from '@mui/icons-material';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';
import { useOpenEmailMaskControllerContext } from '@work4all/components/lib/hooks/contact-email-mask/useOpenEmailMaskControllerContext';

import { useDataProvider } from '@work4all/data';

import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { BirthdayData, BirthdaysData } from '../hooks/use-birthdays';

interface BirthdaysMenuProps {
  data: BirthdaysData;
}

export function BirthdaysMenu({ data }: BirthdaysMenuProps) {
  const [selected, setSelected] = useState<BirthdayData>();

  const { t } = useTranslation();

  const { setCallModal, callModal } = useCallModalContext();
  const openEmailMaskController = useOpenEmailMaskControllerContext();

  useEffect(() => {
    if (!callModal) setSelected(undefined);
  }, [callModal]);

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: {
        sdObjType: selected?.businessPartnerType,
        sdObjMemberCode: selected?.businessPartnerId,
        contactCode: selected?.id,
      },
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [selected]);

  const completeTelephoneNumbersResult =
    useDataProvider<ContactTelephonenumbers>(
      completeTelephonenumbersNumbersRequest,
      !selected
    );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResult.data as unknown as ContactTelephonenumbers;

  useEffect(() => {
    if (completeTelephoneNumbers?.directDial !== undefined && selected) {
      setCallModal({
        data: selected,
        telephoneNumbers: completeTelephoneNumbers,
      });
    }
  }, [
    selected,
    completeTelephoneNumbers,
    completeTelephoneNumbersResult,
    completeTelephoneNumbersResult?.total,
    data,
    setCallModal,
  ]);

  return (
    <List dense={true} className={styles.optionsWrap}>
      {Object.keys(data).map((date) => {
        const prepend: string = DateTime.fromISO(date)
          .startOf('day')
          .equals(DateTime.now().startOf('day'))
          ? `${t('COMMON.TODAY')}, `
          : DateTime.fromISO(date)
              .startOf('day')
              .equals(DateTime.now().plus({ day: 1 }).startOf('day'))
          ? `${t('COMMON.TOMORROW')}, `
          : '';

        const birthdays = data[date];

        return (
          <React.Fragment>
            <Divider
              title={`${prepend}${DateTime.fromISO(date).toFormat(
                'cccc'
              )}, ${DateTime.fromISO(date).toLocaleString(
                DateTimeCustom.DATE_SHORT
              )}`}
            />

            {birthdays.map((birthday) => {
              return (
                <ListItem
                  disableGutters
                  secondaryAction={
                    <React.Fragment>
                      <IconButton
                        edge={false}
                        aria-label="call"
                        color="primary"
                        disabled={!birthday.data.phone}
                        onClick={() => setSelected(birthday.data)}
                      >
                        <Phone />
                      </IconButton>

                      <IconButton
                        edge="end"
                        aria-label="email"
                        color="primary"
                        onClick={() =>
                          openEmailMaskController({
                            businessPartner: {
                              id: birthday.data.businessPartnerId,
                              businessPartnerType:
                                birthday.data.businessPartnerType,
                            },
                            contactId:
                              birthday.data.id !==
                              birthday.data.businessPartnerId
                                ? birthday.data.id
                                : undefined,
                            email:
                              !birthday.data.businessPartnerId &&
                              birthday.type !== Entities.user
                                ? birthday.data.email
                                : undefined,
                            entityType: birthday.type,
                          })
                        }
                        disabled={!birthday.data.email}
                      >
                        <Send />
                      </IconButton>
                    </React.Fragment>
                  }
                >
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText
                    className={styles.name}
                    primary={birthday.data.name}
                  />
                </ListItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </List>
  );
}
