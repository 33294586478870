import React, { MouseEventHandler } from 'react';
import {
  Cell,
  Column,
  IdType as ReactTableIdType,
  TableInstance,
  TableState,
} from 'react-table';

import { ColumnAdditionalData } from '@work4all/components';
import { EditableCellNumericProps } from '@work4all/components/lib/dataDisplay/basic-table/components/row-render/components/editable-cell/EditableCell';
import {
  EditModeConfig,
  EditModeTableInstance,
} from '@work4all/components/lib/dataDisplay/basic-table/plugins/useEditMode';

import { ChecklistPositionKind } from '@work4all/models/lib/Enums/ChecklistPositionKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import {
  RowSizeMode,
  TablesLayoutBordersSettingsKey,
} from '../../../../../../../../../../settings/settings';

import { EditTableProviderProps } from './components/edit-table-provider/EditTableProvider';
import { UseEditableColumnsSettingsProps } from './hooks/use-editable-columns-settings';

export type IdArray = string[] | number[];
export type EditTablePositionKind = ErpPositionsKind | ChecklistPositionKind;

export interface EditTableMask<T extends object> {
  item?: T;
  onCloseClick?: MouseEventHandler<HTMLButtonElement>;
  editableCols: string[];
}

// eslint-disable-next-line @typescript-eslint/ban-types

export interface EditTableEntry {
  id?: unknown; // Unique identifier for the entry.
  positionKind?: EditTablePositionKind; // Type of position, e.g., title, summary, or normal entry.
  posId?: EditTableEntry['id']; // Parent or related position ID.
  index?: number; // Order index of the entry.

  /**
   * Indicates that the entry exists only locally and has not been saved to the server.
   */
  cacheOnly?: boolean;

  /**
   * Fields in this entry that have been changed and should not be merged.
   */
  dirtyFields?: Record<string, unknown>;

  /**
   * Local UUID for temporary identification before server persistence.
   */
  localId?: string;
  /**
   * Whether the entry is collapsed in the UI.
   */
  collapsed?: boolean;
  /**
   *  Whether the entry can be selected.
   */
  disableSelect?: boolean;

  /**
   * Property use to focous expandable rows.
   */
  firstVistiFocus?: boolean;
}

export interface OnEditPosition<T> {
  position: T;
  /**
   * With this flag state will be updated always, even if value is equal to initial value.
   * Flag by defualt is set to false and update state only with diff.
   */
  forceUpdate?: boolean;
}

export interface EditTablePropsDecorators<T> {
  /**
   * Edit Table supports 1 level goruping based on relation.
   * Grouping is done by defining relations in flat array of data.
   * @param pos original data
   * @returns relation type
   */
  getRelation?: (pos: T) => 'parent' | 'child' | 'normal';
  /**
   * Callback to calculate if speficic Cell in row is editable.
   * Use if if you need disable specific row, cell in grid based on data.
   */
  isEditableCell?: (cell: string, original: T) => boolean;
}

export interface EditTableProps<T extends EditTableEntry> {
  columns: EditTableColumns<T>[];
  items: T[];
  tableInstanceRef: React.RefObject<TableInstance<T>>;
  mask?: React.ComponentType<EditTableMask<T>>;
  renderMobileItem?: (
    item: T,
    select: (item: T) => void,
    allPositions: T[]
  ) => JSX.Element;
  renderMobileFooter?: (allPositions: T[]) => JSX.Element;

  classes?: {
    tbody?: string;
    mobile?: string;
    table?: string;
    thead?: string;
    tfoot?: string;
    innerInfiniteTable?: string;
  };
  refs?: {
    scroll?: React.MutableRefObject<HTMLDivElement | null>;
  };

  disabled?: boolean;
  /**
   * Triggered when selection is changed.
   * @param selectedPositionIds LocalId of row.
   */
  onSelectedItemIdsChange?: (selectedPositionIds: string[]) => void;
  /**
   * You can drop anything on that table which is TABLE_ROW_DRAG_DROP_KEY. (react-dnd)
   */
  onDropItem?: (item?: unknown) => void;
  loadingRows?: T['id'][];
  noIdEditableCells?: string[];
  itemSize?: 'auto' | ((index: number) => number);

  defaultFocusedColumn?: string;
  /**
   * Determines which columns of row is editable per type.
   * If not set, all cells with type are editable by default.
   */
  editableRows?:
    | Partial<Record<ErpPositionsKind, string[]>>
    | Partial<Record<ChecklistPositionKind, string[]>>;
  decorators?: EditTablePropsDecorators<T>;
  allowedColumns?: string[];
  /**
   * Triggered when navigate out of the row.
   * @param row original data of row
   * @param dir 1 - next index, -1 - prev index
   */
  onRowOut?: (row: T, dir: 1 | -1) => void;
  /**
   * Determines how rows are shown in single or multilines.
   */
  rowSizeMode?: RowSizeMode;
  /**
   * Updater of handle column order chagned event.
   * Allows to dynamically chagned order of column after chagned was done.
   * F.e. for moving 2 columns together.
   */
  handleOrderChange?: (columns: ReactTableIdType<T>[]) => ReactTableIdType<T>[];
  /**
   * Settings are used to managed column visibility.
   */
  columnSettings?: UseEditableColumnsSettingsProps<T>['columnSettings'];
  /**
   * Part of settings key  - used for saving info about if Mask is Visible.
   * Each Table has unique settings per Entity.
   */
  entity?: Entities;
  /**
   * Part of settings key  - used for saving info about if borders are visible.
   */
  bordersKey?: TablesLayoutBordersSettingsKey;
  /**
   * Table will use given space to make adjust height.
   */
  fitSpace?: boolean;
  /**
   * It will fire onEditPositin callback whenever row or cell is edited.
   * @default row
   */
  updateMode?: 'row' | 'cell';
  /**
   * Determines whether borders are visible or not.
   */
  showBorders?: boolean;
  /**
   * Determines selection columns is visible.
   */
  showSelectionColumn?: boolean;

  /**
   * Initial state of use-table hook.
   */
  initialState?: Partial<Pick<TableState<T>, 'selectedRowIds'>>;
  tableMaxHeight?: string;
}
export interface IEditTable {
  scrollTo: (index?: number, columnToFoucs?: string) => void;
  setEditMode: (
    rowId: string,
    autofocus?: EditModeConfig['autoFocus'],
    columns?: EditModeConfig['columns']
  ) => void;
}
export const NO_BORDER_KIND = [
  ErpPositionsKind.TITEL,
  ErpPositionsKind.TITELSUMME,
  ErpPositionsKind.ZWISCHENSUMME,
  ErpPositionsKind.UMWANDLUNGSHISTORIE,
  ChecklistPositionKind.HEADLINE,
  ChecklistPositionKind.EMPTY_ROW,
];

/**
 * Comprehensive props for the EditTable component, merging provider and standard props.
 * @template T The type of the table entry.
 */
export type EditTablePropsAll<T> = EditTableProps<T> &
  EditTableProviderProps<T> & {
    ref?: React.ForwardedRef<IEditTable>;
  };

interface CellDefinition<T extends EditTableEntry = EditTableEntry>
  extends EditableCellNumericProps {
  type?: 'date' | 'text' | 'number' | 'picker' | 'time';
  isLongText?: boolean;
  multiLinesRowKindsAllowed?: EditTablePositionKind[];
  style?: React.CSSProperties;
  editable?: boolean;
  render?: (
    input: Cell<T>,
    options: {
      isEditMode: boolean;
      tableInstance: EditModeTableInstance;
    }
  ) => JSX.Element;
  skeleton?: boolean;
  shouldRender?: (input: T) => boolean;
}

export type EditTableColumns<T extends EditTableEntry = EditTableEntry> =
  Column<T> & ColumnAdditionalData & CellDefinition<T>;
