import styles from './SearchInput.module.scss';

import { IconButton } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '@work4all/assets/icons/search.svg';

import { useDebouncedCallback } from '@work4all/utils/lib/hooks/use-debounce-callback';

export interface ISearchInputProps {
  value: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.FC<ISearchInputProps> = (props) => {
  const { value, onChange } = props;
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState(value);

  const handleClearClick: React.MouseEventHandler = () => {
    setInputValue('');
    onChange('');
    inputRef.current?.focus();
  };

  const debouncedOnChange = useDebouncedCallback(onChange, 500);

  return (
    <div className={styles['root']}>
      <input
        ref={inputRef}
        autoFocus
        className={styles['input']}
        placeholder={t('SEARCH.SEARCH_TERM')}
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
          debouncedOnChange(event.target.value);
        }}
      />

      <IconButton
        disabled={!inputValue}
        onClick={handleClearClick}
        size="large"
      >
        {inputValue ? (
          <CloseIcon className={styles['icon-override']} />
        ) : (
          <SearchIcon className={styles['icon-override']} />
        )}
      </IconButton>
    </div>
  );
};
