export enum ProjectProcessColumnType {
  Description = 'Description',
  StartDate = 'StartDate',
  EndDate = 'EndDate',
  Duration = 'Duration',
  Progress = 'Progress',
  Resource = 'Resource',
  TargetValue = 'TargetValue',
  ActualValue = 'ActualValue',
  Difference = 'Difference',
}

export interface ProjectProcessColumn {
  type: ProjectProcessColumnType;
  width: number;
  hidden: boolean;
}
