import styles from './CheckListPositionsTableContent.module.scss';

import { Box, Theme, useMediaQuery } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ColumnVisibilityContext } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';

import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';

import { Collapse } from '../../../../../../../components';
import { EditTable } from '../../../../../../erp/components/tab-panels/positions/components/edit-table/EditTable';
import { IEditTable } from '../../../../../../erp/components/tab-panels/positions/components/edit-table/types';
import {
  useCheckListPositionsColumnHelpers,
  useCheckListPositionsColumns,
  useCheckListPositionsState,
} from '../hooks';

import { CheckListPositionsTableHeader } from './CheckListPositionsTableHeader';

export const CheckListPositionsTableContent = () => {
  const { t } = useTranslation();

  const editTableRef = useRef<IEditTable>();
  const tableInstanceRef = useRef<TableInstance>();

  const state = useCheckListPositionsState({ editTableRef });
  const { columnSettings, columnVisibilityManager } =
    useCheckListPositionsColumnHelpers({
      tableInstanceRef,
    });
  const columns = useCheckListPositionsColumns({
    onEdit: state.onEditPosition,
    columnSettings: columnSettings.value,
  });

  const disableActions = state.selectedPositionIds.length === 0;

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  return (
    <Box marginTop="1.5rem" display="flex" flex="1" flexDirection="column">
      <ColumnVisibilityContext.Provider value={columnVisibilityManager}>
        <Collapse
          title={t('MASK.CHECKLIST')}
          className={styles.collapse}
          classes={{
            root: styles.collapse,
            wrapper: styles.collapse,
            wrapperInner: styles.collapse,
          }}
          headerBar={
            <CheckListPositionsTableHeader
              {...state}
              onAdd={state.onAddPosition}
              onClone={state.onClonePosition}
              onDelete={state.onRemovePosition}
              deleteDisabled={disableActions}
              cloneDisabled={disableActions}
              tableInstanceRef={tableInstanceRef}
            />
          }
          defaultOpen
        >
          <Box
            marginTop="0.75rem"
            display="flex"
            flex="1"
            flexDirection="column"
          >
            <EditTable<CheckListPosition>
              {...state}
              ref={editTableRef}
              tableInstanceRef={tableInstanceRef}
              items={state.positions}
              itemSize="auto"
              columns={columns}
              columnSettings={columnSettings}
              rowSizeMode={state.rowSize}
            />
          </Box>
        </Collapse>
      </ColumnVisibilityContext.Provider>
    </Box>
  );
};
