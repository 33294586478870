import styles from './MenuPopover.module.scss';

import { ClickAwayListener, Popover, PopoverProps } from '@mui/material';
import { useMemo } from 'react';

import { MenuPopoverProps } from './types';

const POPOVER_TOP_LEFT = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
};

const POPOVER_TOP_RIGHT = {
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
};

const POPOVER_MIN_WIDTH = 200;

export const MenuPopover = (props: MenuPopoverProps) => {
  const anchorElDomRectObject = (
    props.anchorEl as Element
  )?.getBoundingClientRect();

  const popoverProps: PopoverProps = useMemo(() => {
    if (!anchorElDomRectObject) {
      return props;
    }

    if (anchorElDomRectObject.right + POPOVER_MIN_WIDTH > window.innerWidth) {
      return {
        ...props,
        ...POPOVER_TOP_LEFT,
      };
    }

    return {
      ...props,
      ...POPOVER_TOP_RIGHT,
    };
  }, [anchorElDomRectObject, props]);

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        props.onClose(e, 'backdropClick');
      }}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <Popover
        {...popoverProps}
        classes={{
          root: styles.popoverRoot,
          paper: styles.popoverPaper,
        }}
        slotProps={{
          paper: {
            onMouseLeave: props.onMouseLeave,
            style: { minWidth: POPOVER_MIN_WIDTH },
          },
        }}
      />
    </ClickAwayListener>
  );
};
