import { GroupedRowFooterDataRequestData } from './types';

export const GROUPED_ROW_FOOTER_DATA_REQUEST_DATA: GroupedRowFooterDataRequestData =
  {
    data: [
      {
        data: [
          {
            aggregationType: null,
            alias: null,
            value: null,
          },
        ],
      },
    ],
  };
