import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as AddIcon } from '@work4all/assets/icons/outline-add-24.svg';

import { FileButton } from '../../FileButton';
import { IconButtonWithTooltip } from '../IconButtonWithTooltip';
import { BaseActionProps } from '../types';

type UploadProps = BaseActionProps & {
  noTitle?: boolean;
  multiple?: boolean;
  accept?: string;
};
export const Upload: React.FC<UploadProps> = (props) => {
  const { noTitle, accept, multiple = true, ...rest } = props;
  const { t } = useTranslation();
  const title = noTitle ? undefined : t('MASK.ADD_TO');

  return (
    <FileButton
      name="upload"
      accept={accept}
      multiple={multiple}
      onChange={props.onChange}
    >
      {({ onClick }) => (
        <IconButtonWithTooltip
          tooltip={title}
          icon={<AddIcon />}
          {...rest}
          onClick={onClick}
        />
      )}
    </FileButton>
  );
};
