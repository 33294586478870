import styles from './CopyButtonCell.module.scss';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from '@mui/material';

type Props = {
  onClick: () => void;
};

export const CopyButtonCell = ({ onClick }: Props) => {
  return (
    <IconButton
      size="small"
      color="primary"
      onClick={onClick}
      className={styles.icon}
    >
      <ContentCopyIcon />
    </IconButton>
  );
};
