import { useTranslation } from 'react-i18next';

import { VacationKind } from '@work4all/models/lib/Classes/VacationKind.entity';

import { KindsFooter } from '../../../components/more/kinds-footer/KindsFooter';
import { useCalendarSelection } from '../../../hooks/useCalendarSelection';

interface FooterProps {
  kinds: VacationKind[];
  selectedKinds: (number | string)[];
  onSelectedKindsChange: (kinds: (number | string)[]) => void;
}

export const Footer = ({
  kinds,
  selectedKinds,
  onSelectedKindsChange,
}: FooterProps) => {
  const { t } = useTranslation();
  const { isSelected, isEverythingSelected, toggle, toggleAll } =
    useCalendarSelection({
      items: kinds,
      selectedItem: selectedKinds,
      onChange: onSelectedKindsChange,
    });

  return (
    <KindsFooter
      title={t('COMMON.VACATION_KIND')}
      items={kinds}
      isEverythingSelected={isEverythingSelected}
      toggle={toggle}
      toggleAll={toggleAll}
      colorAccessor="color"
      isSelected={isSelected}
      applyColor
      planningAppointments={false}
      onPlanningAppointmentsChange={() => null}
    />
  );
};
