import { gql, useQuery } from '@apollo/client';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const QUERY_NAME_BY_ENTITY: Partial<Record<Entities, string>> = {
  projectGroup: 'getProjektGruppen',
  textBuildingBlockGroup: 'getTextbausteinGruppen',
  articleGroup: 'getArtikelGruppen',
  customerGroup: 'getKundenGruppen',
  supplierGroup: 'getLieferantenGruppen',
  userGroup: 'getObjectProtectionGroups',
  documentTemplateGroup: 'getDocumentTemplateGroups',
  letterTemplateGroup: 'getLetterTemplateGroups',
};

const getGroupsQuery = (queryName: string) => gql`
    query ${queryName} {
        groups: ${queryName} {
          id: code
          name: name
          parentId: parentCode
          index
        }
    }
  `;

const getTextBuildingBlockGroupsQuery = (queryName: string) => gql`
      query ${queryName}(
        $querySize: Int
        $queryPage: Int
        $querySortOrder: SortOrderStyle
        $querySortBy: String
        $querySortByEx: [SortByPair]
        $filter: String
      ){
        ${queryName}(
          querySize: $querySize
          queryPage: $queryPage
          querySortOrder: $querySortOrder
          querySortBy: $querySortBy
          querySortByEx: $querySortByEx
          filter: $filter
        ){
            total
            size
            page
            data {
              id: code
              name: name
            }
        }
      }
    `;

const getUserGroupsQuery = (queryName: string, groupIndexLabel: string) => gql`
    query ${queryName} {
      groups: ${queryName} {
        id: code
        name: name
        index: ${groupIndexLabel}
      }
    }
  `;

export const getQuery = (entity: Entities, queryName: string) => {
  switch (entity) {
    case Entities.userGroup:
      return getUserGroupsQuery(queryName, 'grIndex');
    case Entities.letterTemplateGroup:
      return getUserGroupsQuery(queryName, 'groupIndex');
    case Entities.documentTemplateGroup:
      return getUserGroupsQuery(queryName, 'groupIndex');
    case Entities.textBuildingBlockGroup:
      return getTextBuildingBlockGroupsQuery(queryName);
    default:
      return getGroupsQuery(queryName);
  }
};

interface GetGroupsResponse {
  groups: Group[];
}

export function useGroups(entity: Entities) {
  const queryName = QUERY_NAME_BY_ENTITY[entity];

  if (!queryName) {
    throw new Error(
      `Entity ${entity} is not defined inside "QUERY_NAME_BY_ENTITY".`
    );
  }

  const query = getQuery(entity, queryName);

  return useQuery<GetGroupsResponse>(query);
}
