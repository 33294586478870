import styles from './NumberRangePicker.module.scss';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebouncedCallback } from '@work4all/utils/lib/hooks/use-debounce-callback';

import { LabeledInput } from '../../../../input/labeled-input';
import { MultiStepControls, Step } from '../../../../input/multi-step-controls';

interface INumberRangePickerProps {
  from?: number;
  to?: number;
  onChange: (from: number, to: number) => void;
}

export const NumberRangePicker: React.FC<INumberRangePickerProps> = (props) => {
  const { t } = useTranslation();
  const { from = null, to = null, onChange } = props;

  const [fromValue, setFromValue] = useState<number>(from);
  const [toValue, setToValue] = useState<number>(to);

  const debouncedOnChange = useDebouncedCallback(onChange, 500);

  return (
    <div className={styles.pickerWrap}>
      <MultiStepControls>
        <Step active={true} index={0}>
          <LabeledInput
            value={fromValue ?? ''}
            label={t('COMMON.FROM')}
            type="number"
            onChange={(e) => {
              const from = parseFloat(e.target.value);
              setFromValue(from);
              debouncedOnChange(from, toValue);
            }}
            onClear={() => {
              setFromValue(null);
              onChange(null, toValue);
            }}
          />
        </Step>
        <Step active={true} index={1}>
          <LabeledInput
            value={toValue ?? ''}
            label={t('COMMON.TO')}
            type="number"
            onChange={(e) => {
              const to = parseFloat(e.target.value);
              setToValue(to);
              debouncedOnChange(fromValue, to);
            }}
            onClear={() => {
              setToValue(null);
              onChange(fromValue, null);
            }}
          />
        </Step>
      </MultiStepControls>
    </div>
  );
};
