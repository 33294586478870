import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class InputArticleAttachmentModify<T extends EMode = EMode.entity> {
  /** Alias: code */
  code?: number;
  /** Alias: name */
  name?: string;
  /** Alias: asAttachmentCalculation */
  asAttachmentCalculation?: boolean;
  /** Alias: asAttachmentOffer */
  asAttachmentOffer?: boolean;
  /** Alias: asAttachmentOrderConfirmation */
  asAttachmentOrderConfirmation?: boolean;
  /** Alias: asAttachmentDeliveryNote */
  asAttachmentDeliveryNote?: boolean;
  /** Alias: asAttachmentInvoice */
  asAttachmentInvoice?: boolean;
  /** Alias: asAttachmentDemand */
  asAttachmentDemand?: boolean;
  /** Alias: asAttachmentOrder */
  asAttachmentOrder?: boolean;
  /** Alias: deactivated */
  deactivated?: boolean;
  /** Alias: defaultDocumentToDisplay */
  defaultDocumentToDisplay?: boolean;
  /** Alias: showInShop */
  showInShop?: boolean;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleAttachmentModify> = {
  code: {
    alias: 'code',
  },
  name: {
    alias: 'name',
  },
  asAttachmentCalculation: {
    alias: 'asAttachmentCalculation',
  },
  asAttachmentOffer: {
    alias: 'asAttachmentOffer',
  },
  asAttachmentOrderConfirmation: {
    alias: 'asAttachmentOrderConfirmation',
  },
  asAttachmentDeliveryNote: {
    alias: 'asAttachmentDeliveryNote',
  },
  asAttachmentInvoice: {
    alias: 'asAttachmentInvoice',
  },
  asAttachmentDemand: {
    alias: 'asAttachmentDemand',
  },
  asAttachmentOrder: {
    alias: 'asAttachmentOrder',
  },
  deactivated: {
    alias: 'deactivated',
  },
  defaultDocumentToDisplay: {
    alias: 'defaultDocumentToDisplay',
  },
  showInShop: {
    alias: 'showInShop',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleAttachmentModifyEntityDefinition: EntitiyDefinition<InputArticleAttachmentModify> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleAttachmentModify',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
