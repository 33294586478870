import styles from './PickerListItem.module.scss';

import { Star, StarBorder } from '@mui/icons-material';
import {
  Checkbox,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { memo } from 'react';
import { ListChildComponentProps } from 'react-window';

import { ReactComponent as ClearIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { EntityLike } from '../../../types';
import { getEntityId } from '../../list-entity-picker/utils';

export interface RenderItemData<TValue extends EntityLike> {
  items: TValue[];
  counts?: Record<string, number>;
  activeRowIndex: number;
  multiple?: boolean;
  hideCheckboxes?: boolean;
  clearable?: boolean;
  renderItemContent: (item: TValue) => React.ReactNode;
  renderCount?: (count: number, value: TValue) => React.ReactNode;
  isSelected: (item: TValue) => boolean;
  isSomethingSelected: boolean;
  onToggle: (item: TValue) => void;
  onFavToggle?: (item: TValue) => void;
  isFav?: (item: TValue) => boolean;
  disabledItemsIds?: (number | string)[];
  alignClearIcon?: boolean;
}

export const EntityPickerListItem = memo(function EntityPickerListItem<
  TValue extends EntityLike & { unselectable?: boolean }
>(props: ListChildComponentProps<RenderItemData<TValue>>) {
  const { data, index, style } = props;
  const {
    items,
    counts = {},
    activeRowIndex,
    multiple = false,
    hideCheckboxes,
    clearable = false,
    isSelected,
    isSomethingSelected,
    onToggle,
    renderItemContent,
    renderCount,
    onFavToggle,
    isFav,
    disabledItemsIds = [],
    alignClearIcon = false,
  } = data;

  const value = items[index];

  if (!value) {
    return (
      <ListItem style={style}>
        <Skeleton variant="text" height="100%" width="100%" />
      </ListItem>
    );
  }

  const active = activeRowIndex === index;
  const selected = isSelected(value);
  const count = counts[getEntityId(value)] ?? null;

  return (
    <ListItem
      style={style}
      disablePadding
      secondaryAction={
        onFavToggle && (
          <IconButton
            edge="end"
            onClick={(e) => {
              e.stopPropagation();
              onFavToggle(value);
            }}
          >
            {isFav(value) ? (
              <Star className={styles.favActive} />
            ) : (
              <StarBorder />
            )}
          </IconButton>
        )
      }
    >
      <ListItemButton
        disabled={disabledItemsIds.includes(value.id) || value.unselectable}
        className={clsx(styles['list-item-button'], {
          [styles['list-item-button-active']]: active,
          [styles['list-item-disabled']]: value.unselectable,
        })}
        dense
        role={undefined}
        onClick={() => onToggle(value)}
        selected={selected}
      >
        {multiple && !hideCheckboxes && (
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
        )}

        <Stack
          alignItems={'center'}
          direction="row"
          overflow="hidden"
          flex={1}
          height={'100%'}
        >
          <div className={styles['list-item-content-wrapper']}>
            {renderItemContent(value)}
          </div>

          {count !== null && (
            <Typography variant="body2" flex="none" ml={1}>
              ({renderCount ? renderCount(count, value) : count})
            </Typography>
          )}
        </Stack>

        {clearable &&
          !multiple &&
          (selected ? (
            <ClearIcon className={styles['deselect-icon']} />
          ) : alignClearIcon && isSomethingSelected ? (
            <span className={styles['deselect-icon']} />
          ) : null)}
      </ListItemButton>
    </ListItem>
  );
});

export const NoRowsItem = ({ text }: { text: string }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        className={styles['list-item-button']}
        dense
        role={undefined}
        disabled
      >
        <ListItemText
          primary={text}
          classes={{ primary: styles['nothing-found'] }}
        />
      </ListItemButton>
    </ListItem>
  );
};
