import styles from './ResourceUtilizationInfoDialog.module.scss';

import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@work4all/components';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';

import { Link } from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { Sickness } from '@work4all/models/lib/Classes/Sickness.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';

export const ResourceUtilizationInfoDialog = (props: {
  appointmentList: Appointment[];
  absenceList: Sickness[];
  vacationList: Vacation[];
  open: boolean;
  onClose: (event, reason: 'backdropClick' | 'escapeKeyDown') => void;
}) => {
  const { open, onClose, appointmentList, absenceList, vacationList } = props;
  const { t } = useTranslation();

  return (
    <Dialog
      title={t('COMMON.INFO')}
      open={open}
      onClose={onClose}
      classes={{
        dialog: {
          paper: styles.paper,
        },
      }}
    >
      {absenceList?.length > 0 && (
        <Box p="0 1rem">
          <Typography>{t('COMMON.ABSENT')}</Typography>
        </Box>
      )}

      {vacationList?.length > 0 && (
        <Box p="0 1rem">
          <Typography>{t('COMMON.VACATION')}</Typography>
        </Box>
      )}

      <Divider
        title={t('COMMON.APPOINTMENT', { count: 2 })}
        horizontalPadding
      />
      {appointmentList?.length === 0 && (
        <Box p="0.25rem 1rem">
          <Typography>{t('COMMON.NO_APPOINTMENTS_AVAILABLE')}</Typography>
        </Box>
      )}
      {appointmentList?.length > 0 && (
        <List disablePadding>
          {appointmentList.map((appointment) => {
            return (
              <ListItem
                key={appointment.id}
                style={{ padding: '0.25rem 1rem' }}
              >
                <Stack
                  direction="row"
                  gap="1rem"
                  width="100%"
                  overflow="hidden"
                  alignItems="center"
                >
                  <Box>
                    <Typography color="var(--text03)" whiteSpace="nowrap">
                      {DateTime.fromISO(appointment.startDate).toFormat(
                        'dd.MM.yy'
                      )}
                    </Typography>
                  </Box>

                  <Box flexGrow={1} overflow="hidden">
                    <Typography
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap"
                    >
                      {appointment.title}
                    </Typography>
                  </Box>
                  {appointment.isWholeDay ? (
                    <Box>
                      <Typography color="var(--text03)">
                        {t('COMMON.WHOLEDAY')}
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography color="var(--text03)" whiteSpace="nowrap">
                        {DateTime.fromISO(appointment.startDate).toFormat(
                          'HH:mm'
                        )}{' '}
                        -{' '}
                        {DateTime.fromISO(appointment.endDate).toFormat(
                          'HH:mm'
                        )}
                      </Typography>
                    </Box>
                  )}
                  <Box>
                    <Link
                      to={`/more/entity/appointment/details/${appointment.id}`}
                      target="_blank"
                      style={{ width: '100%' }}
                    >
                      <IconButton size="small">
                        <OpenInNew fontSize="small" color="primary" />
                      </IconButton>
                    </Link>
                  </Box>
                </Stack>
              </ListItem>
            );
          })}
        </List>
      )}
    </Dialog>
  );
};
