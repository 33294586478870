import SendIcon from '@mui/icons-material/Send';
import React, { useCallback, useMemo, useRef, useState } from 'react';

import { EmailTemplatesPopover } from '@work4all/components';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { useEmailTemplates } from '@work4all/components/lib/hooks';
import { NavigationOverlay } from '@work4all/components/lib/navigation/navigation-overlay';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { EMailTemplate } from '@work4all/models/lib/Classes/EMailTemplate.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { EmailTemplateButtonContextProps } from '../../../../containers/mask-overlays/components/email-template-button/EmailTemplateButtonProvider';
import { MaskControllerProps } from '../../../../containers/mask-overlays/mask-overlay';
import { EmailOverlayController } from '../../../../containers/mask-overlays/mask-overlay/views/email/EmailOverlayController';
import { settings, useSetting } from '../../../../settings';

interface UseEmailTemplateProps extends EmailTemplateButtonContextProps {
  appointments: Appointment[];
}

export const useAppointmentEmailTemplate = (props: UseEmailTemplateProps) => {
  const { noTemplate, eMailTemplateKind, getEmailParams } = props;

  const appointment = useDeepMemo(
    () => props.appointments?.[0],
    [props.appointments?.[0]]
  );

  const emailTemplatesRef = useRef<HTMLButtonElement>();
  const templates = useEmailTemplates(eMailTemplateKind);

  const { value: standardTemplates, set: setStandardTemplates } = useSetting(
    settings.standardEmailTemplates()
  );

  const [isEmailTemplatesPopoverOpen, setIsEmailTemplatesPopoverOpen] =
    useState(false);

  const [emailProps, setEmailProps] = useState<MaskControllerProps>();

  const openEmailMask = useCallback(
    async (emaiTemplate: EMailTemplate) => {
      const template = !emaiTemplate.id ? null : emaiTemplate;
      const { entityTemplate, params } = await getEmailParams(
        template,
        appointment
      );
      const processedMailTemplateArgs = JSON.parse(
        params.processedMailTemplateArgs
      );

      setEmailProps({
        entity: Entities.eMail,
        id: null,
        template: entityTemplate,
        params: {
          basedon: 'EmailTemplate',
          emailTemplate: template ? JSON.stringify(template) : undefined,
          processedMailTemplateArgs: JSON.stringify({
            ...processedMailTemplateArgs,
            noDirectContact: !appointment.contact,
          }),
          ...params,
        },
        onAfterSave: () => {
          setEmailProps(undefined);
        },
      });
      setIsEmailTemplatesPopoverOpen(false);
    },
    [getEmailParams, appointment]
  );

  const component = useMemo(
    () => (
      <React.Fragment>
        {emailProps && (
          <NavigationOverlay
            open={true}
            initialView={{
              view: <EmailOverlayController {...emailProps} />,
            }}
            close={() => {
              setEmailProps(undefined);
            }}
          />
        )}

        {isEmailTemplatesPopoverOpen && (
          <EmailTemplatesPopover
            eMailTemplateKind={eMailTemplateKind}
            noTemplate={noTemplate}
            anchorEl={emailTemplatesRef.current}
            onClose={() => setIsEmailTemplatesPopoverOpen(false)}
            onTemplateClick={(template) => {
              setIsEmailTemplatesPopoverOpen(false);
              openEmailMask(template);
            }}
            standardTemplates={standardTemplates}
            setStandardTemplates={setStandardTemplates}
          />
        )}
      </React.Fragment>
    ),
    [
      emailProps,
      isEmailTemplatesPopoverOpen,
      eMailTemplateKind,
      noTemplate,
      openEmailMask,
      setStandardTemplates,
      standardTemplates,
    ]
  );

  const action: CustomToolbarAction = useMemo(() => {
    return {
      rootRef: emailTemplatesRef,
      actionKey: 'EMAIL_FROM_APPOINTMENT',
      mode: 'Edit',
      IconComponent: () => <SendIcon style={{ color: 'var(--brand01)' }} />,
      handler: (_id, event) => {
        emailTemplatesRef.current = event.target as HTMLButtonElement;
        const standardTemplateId = standardTemplates[eMailTemplateKind];
        if (standardTemplateId) {
          const standardTemplate = templates?.find(
            (t) => t.id === standardTemplateId
          );
          openEmailMask(standardTemplate);
        } else {
          setIsEmailTemplatesPopoverOpen(true);
        }
      },
      sections: ['mobile', 'preview'],
    };
  }, [eMailTemplateKind, openEmailMask, standardTemplates, templates]);

  return useMemo(() => {
    return {
      action,
      component,
    };
  }, [action, component]);
};
