import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';
import { TaskStatus } from '../Enums/TaskStatus.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { CustomField } from './CustomField.entity';
import { FavoriteItem } from './FavoriteItem.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { TaskAttachment } from './TaskAttachment.entity';
import { TopicSimple } from './TopicSimple.entity';
import { User } from './User.entity';

export class Task<T extends EMode = EMode.entity> {
  /** Alias: anhaenge */
  attachmentList?: TaskAttachment<T>[];
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprpCode */
  contactId?: number;
  /** Alias: artikelCode */
  articleId?: number;
  /** Alias: autoWeiterleiten */
  forwardAutomatically?: number;
  /** Alias: bCode */
  creatorUserId?: number;
  /** Alias: bErstellt */
  creationDate?: string;
  /** Alias: bZObjMemberCode */
  bZObjMemberId?: number;
  /** Alias: bZObjType */
  bZObjType?: BzObjType;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: checklisteCode */
  checkListId?: number;
  /** Alias: checklistePositionenCode */
  checkListPositionid?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: enddatum */
  endDate?: string;
  /** Alias: erinnernDate */
  reminderDate?: string;
  /** Alias: erledigt */
  done?: number;
  /** Alias: erledigtNotiz */
  doneNote?: string;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: gespraechspunkteCode */
  talkingPointsId?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kNBerichtCode */
  customerNumberVisitationId?: number;
  /** Alias: loeschvormerkung */
  deletionReservation?: number;
  /** Alias: modificationDate */
  modificationDate?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: objGrCode */
  objGrId?: number;
  /** Alias: outlook_EntryID */
  outlook_EntryID?: string;
  /** Alias: parentCode */
  parentId?: number;
  /** Alias: prioritaet */
  priority?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektePlanungCode */
  projectProcessId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: sN */
  serialNumber?: string;
  /** Alias: status */
  status?: TaskStatus;
  /** Alias: stunden */
  hours?: number;
  /** Alias: ticketID */
  ticketId?: string;
  /** Alias: titel */
  title?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: wartungslauf */
  maintenanceRun?: number;
  /** Alias: weiterleitenAnBCode */
  forwardToUserId?: number;
  /** Alias: weiterleitenDatum */
  forwardDate?: string;
  /** Alias: weiterleitenGrCode */
  forwardGroupId?: number;
  /** Alias: weiterleitenStatusCode */
  forwardStatusId?: number;
  /** Alias: zeitbedarf */
  timeRequired?: number;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: gelesen */
  isRead?: boolean;
  /** Alias: themenZuordnungen */
  topicMarkList?: TopicSimple<T>[];
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: favoriteItem */
  favoriteItem?: FavoriteItem<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Task> = {
  attachmentList: {
    alias: 'anhaenge',
    entity: Entities.taskAttachment,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprpCode',
  },
  articleId: {
    alias: 'artikelCode',
  },
  forwardAutomatically: {
    alias: 'autoWeiterleiten',
  },
  creatorUserId: {
    alias: 'bCode',
  },
  creationDate: {
    alias: 'bErstellt',
  },
  bZObjMemberId: {
    alias: 'bZObjMemberCode',
  },
  bZObjType: {
    alias: 'bZObjType',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  userId: {
    alias: 'benutzerCode',
  },
  checkListId: {
    alias: 'checklisteCode',
  },
  checkListPositionid: {
    alias: 'checklistePositionenCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  endDate: {
    alias: 'enddatum',
  },
  reminderDate: {
    alias: 'erinnernDate',
  },
  done: {
    alias: 'erledigt',
  },
  doneNote: {
    alias: 'erledigtNotiz',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  talkingPointsId: {
    alias: 'gespraechspunkteCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  customerNumberVisitationId: {
    alias: 'kNBerichtCode',
  },
  deletionReservation: {
    alias: 'loeschvormerkung',
  },
  modificationDate: {
    alias: 'modificationDate',
  },
  note: {
    alias: 'notiz',
  },
  objGrId: {
    alias: 'objGrCode',
  },
  outlook_EntryID: {
    alias: 'outlook_EntryID',
  },
  parentId: {
    alias: 'parentCode',
  },
  priority: {
    alias: 'prioritaet',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektePlanungCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  serialNumber: {
    alias: 'sN',
  },
  status: {
    alias: 'status',
  },
  hours: {
    alias: 'stunden',
  },
  ticketId: {
    alias: 'ticketID',
  },
  title: {
    alias: 'titel',
  },
  updateTime: {
    alias: 'updateTime',
  },
  maintenanceRun: {
    alias: 'wartungslauf',
  },
  forwardToUserId: {
    alias: 'weiterleitenAnBCode',
  },
  forwardDate: {
    alias: 'weiterleitenDatum',
  },
  forwardGroupId: {
    alias: 'weiterleitenGrCode',
  },
  forwardStatusId: {
    alias: 'weiterleitenStatusCode',
  },
  timeRequired: {
    alias: 'zeitbedarf',
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  isRead: {
    alias: 'gelesen',
  },
  topicMarkList: {
    alias: 'themenZuordnungen',
    entity: Entities.topicSimple,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  favoriteItem: {
    alias: 'favoriteItem',
    entity: Entities.favoriteItem,
  },
  __typename: {
    alias: '__typename',
  },
};

export const taskEntityDefinition: EntitiyDefinition<Task> = {
  local: {},
  remote: {
    queryName: 'getAufgaben',
    fragmentName: 'Aufgabe',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertAufgabe',
        args: [
          { name: 'input', type: 'InputAufgabe!' },
          { name: 'relations', type: 'InputAufgabeRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
      { name: 'addFavSort', type: 'Boolean' },
      { name: 'onlyFavItems', type: 'Boolean' },
    ],
  },
  fieldDefinitions: fields,
};
