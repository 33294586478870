import React from 'react';
import { TableInstance } from 'react-table';

import { nameof } from '@work4all/data/lib/helper/nameof';

import { InboundInvoicePayment } from '@work4all/models/lib/Classes/InboundInvoicePayment.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/inboundInvoicePayment-table-schema.json';
import { ITableProps } from '../table/Table';

const defaultSort = [{ field: 'id', direction: SortDirection.DESCENDING }];

export const RePaymentsTable = React.forwardRef<TableInstance, IEntityTable>(
  function RePaymentsTable(_props, ref) {
    const dataTable = useEntityDataTable<InboundInvoicePayment, never>({
      schema: schema as never,
      defaultSort,
      innerEntityQuery: {
        parentEntity: Entities.reViewModel,
        fieldName: nameof<ReViewModel>('payments'),
      },
    });

    const overrides: Partial<ITableProps> = {
      layout: 'table',
      areas: null,
      hideSearch: true,
      cardConfig: null,
      selectableMultiple: false,
      displayFooter: false,
      actions: {
        groupingEnabled: null,
      },
    };

    return (
      <EntityTable ref={ref} {...dataTable} {..._props} overrides={overrides} />
    );
  }
);
