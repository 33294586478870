import styles from '../styles.module.scss';

import { Settings } from '@mui/icons-material';
import { IconButton, Paper, Popover, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../settings';

export const ProjectPlanningColumnHeader = (props: {
  ressourceClass: LookUp;
}) => {
  const { ressourceClass } = props;
  const popoverState = usePopoverState();
  const btnRef = useRef();
  const { t } = useTranslation();

  const projectPlanningColumns = useSetting(settings.projectPlanningColumns());
  const projectPlanningTargetSource = useSetting(
    settings.projectPlanningTargetSource()
  );
  const resourcePlanningColoumnTimeUnit = useSetting(
    settings.resourcePlanningColoumnTimeUnit()
  );

  return (
    <>
      <Popover {...popoverState} anchorEl={btnRef.current}>
        <Paper>
          <Stack p="0.5rem 1rem" pt={0}>
            <Divider title={t('MASK.COLUMNS')} />
            <CheckboxRadioItem
              checked={projectPlanningColumns.value.target}
              onChange={(e) => {
                projectPlanningColumns.set({
                  ...projectPlanningColumns.value,
                  target: e.target.checked,
                });
              }}
              label={t('COMMON.PLANNING.TARGET.SHORT').toUpperCase()}
              horizontalPadding={false}
            />
            <CheckboxRadioItem
              checked={projectPlanningColumns.value.planned}
              onChange={(e) => {
                projectPlanningColumns.set({
                  ...projectPlanningColumns.value,
                  planned: e.target.checked,
                });
              }}
              label={t('COMMON.PLANNING.PLANNED.SHORT').toUpperCase()}
              horizontalPadding={false}
            />
            <CheckboxRadioItem
              checked={projectPlanningColumns.value.rest}
              onChange={(e) => {
                projectPlanningColumns.set({
                  ...projectPlanningColumns.value,
                  rest: e.target.checked,
                });
              }}
              label={t('COMMON.PLANNING.REST.SHORT').toUpperCase()}
              horizontalPadding={false}
            />
            <CheckboxRadioItem
              checked={projectPlanningColumns.value.actual}
              onChange={(e) => {
                projectPlanningColumns.set({
                  ...projectPlanningColumns.value,
                  actual: e.target.checked,
                });
              }}
              label={t('COMMON.PLANNING.ACTUAL.SHORT').toUpperCase()}
              horizontalPadding={false}
            />
            <Divider title={t('PLANNING.TARGET_SOURCE')} />
            <CheckboxRadioItem
              control="radio"
              label={t(`COMMON.CALCULATION`, { count: 2 })}
              checked={
                projectPlanningTargetSource.value === Entities.calculation
              }
              onChange={() =>
                projectPlanningTargetSource.set(Entities.calculation)
              }
              horizontalPadding={false}
            />
            <CheckboxRadioItem
              control="radio"
              label={t(`COMMON.CONTRACT`, { count: 2 })}
              checked={projectPlanningTargetSource.value === Entities.contract}
              onChange={() =>
                projectPlanningTargetSource.set(Entities.contract)
              }
              horizontalPadding={false}
            />
            <Divider title={t('COMMON.TIME_UNIT')} />

            <CheckboxRadioItem
              control="radio"
              label={t(`COMMON.MINUTE`, { count: 2 })}
              checked={resourcePlanningColoumnTimeUnit.value === 'minute'}
              onChange={() => resourcePlanningColoumnTimeUnit.set('minute')}
              horizontalPadding={false}
            />

            <CheckboxRadioItem
              control="radio"
              label={t(`COMMON.HOUR`, { count: 2 })}
              checked={resourcePlanningColoumnTimeUnit.value === 'hour'}
              onChange={() => resourcePlanningColoumnTimeUnit.set('hour')}
              horizontalPadding={false}
            />

            <CheckboxRadioItem
              control="radio"
              label={t(`COMMON.DAY`, { count: 2 })}
              checked={resourcePlanningColoumnTimeUnit.value === 'day'}
              onChange={() => resourcePlanningColoumnTimeUnit.set('day')}
              horizontalPadding={false}
            />
          </Stack>
        </Paper>
      </Popover>
      <Stack
        style={{
          textAlign: 'center',
          width: '100%',
          position: 'absolute',
          left: 0,
          right: 0,
          top: 0,
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" className={styles.resourceColHeader}>
          <Typography
            variant="caption"
            padding="0.25rem"
            maxWidth="100%"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            flex={1}
          >
            {ressourceClass.name}
          </Typography>
          <IconButton
            size="small"
            className={styles.cta}
            ref={btnRef}
            onClick={() => {
              popoverState.handleClick();
            }}
          >
            <Settings fontSize="small" />
          </IconButton>
        </Stack>
        <Stack
          direction="row"
          width="100%"
          gap="0.5rem"
          borderTop="1px solid var(--ui04)"
        >
          {projectPlanningColumns.value.target && (
            <Typography variant="caption" flex="1" paddingTop="0.25rem">
              {t('COMMON.PLANNING.TARGET.SHORT').toUpperCase()}
            </Typography>
          )}
          {projectPlanningColumns.value.planned && (
            <Typography variant="caption" flex="1" paddingTop="0.25rem">
              {t('COMMON.PLANNING.PLANNED.SHORT').toUpperCase()}
            </Typography>
          )}
          {projectPlanningColumns.value.rest && (
            <Typography variant="caption" flex="1" paddingTop="0.25rem">
              {t('COMMON.PLANNING.REST.SHORT').toUpperCase()}
            </Typography>
          )}
          {projectPlanningColumns.value.actual && (
            <Typography variant="caption" flex="1" paddingTop="0.25rem">
              {t('COMMON.PLANNING.ACTUAL.SHORT').toUpperCase()}
            </Typography>
          )}
        </Stack>
      </Stack>
    </>
  );
};
