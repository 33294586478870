import styles from './MailboxExchangeSynchronization.module.scss';

import { Alert, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useUser } from '@work4all/data';

import { Mailbox } from '@work4all/models/lib/Classes/Mailbox.entity';
import { SyncOptionsContactExport } from '@work4all/models/lib/Enums/SyncOptionsContactExport.enum';

import { useUserMailboxExchangeSynchronizationOptions } from '../hooks';

type Props = {
  mailbox: Mailbox;
};

export const MailboxExchangeSynchronization = ({ mailbox }: Props) => {
  const user = useUser();
  const { t } = useTranslation();
  const {
    allowToUseOwnPrivateData,
    allowExportingCustomer,
    allowExportingSupplier,
    allowExportingEmployees,
    contactsExportMode,
    contactsAllowedExportMode,
    includeContactsPrivateData,
    allowSyncingAppointments,
    allowSyncingPrivateAppointments,
  } = useUserMailboxExchangeSynchronizationOptions({
    mailboxId: mailbox.id,
  });

  return (
    <>
      <Stack direction="column" spacing={2} padding="2.5rem">
        <Typography variant="h4">
          {t('EXCHANGE_SYNCHRONIZATION.TITLE')}
        </Typography>

        <div>
          <Divider>{t('EXCHANGE_SYNCHRONIZATION.PERMISSIONS.TITLE')}</Divider>

          <CheckboxRadioItem
            control="checkbox"
            label={t(
              'EXCHANGE_SYNCHRONIZATION.PERMISSIONS.ALLOW_TO_USE_OWN_PRIVATE_DATA'
            )}
            checked={allowToUseOwnPrivateData.value}
            onChange={() => {
              allowToUseOwnPrivateData.set(!allowToUseOwnPrivateData.value);
            }}
          />
        </div>

        <div>
          <Divider>
            {t('EXCHANGE_SYNCHRONIZATION.CONTACT_EXPORT.TITLE')}
          </Divider>

          <CheckboxRadioItem
            control="checkbox"
            label={t('EXCHANGE_SYNCHRONIZATION.CONTACT_EXPORT.CUSTOMERS')}
            checked={allowExportingCustomer.value}
            onChange={() => {
              allowExportingCustomer.set(!allowExportingCustomer.value);
            }}
          />

          <CheckboxRadioItem
            control="checkbox"
            label={t('EXCHANGE_SYNCHRONIZATION.CONTACT_EXPORT.SUPPLIERS')}
            checked={allowExportingSupplier.value}
            onChange={() => {
              allowExportingSupplier.set(!allowExportingSupplier.value);
            }}
          />

          <CheckboxRadioItem
            control="checkbox"
            label={t('EXCHANGE_SYNCHRONIZATION.CONTACT_EXPORT.EMPLOYEES')}
            checked={allowExportingEmployees.value}
            onChange={() => {
              allowExportingEmployees.set(!allowExportingEmployees.value);
            }}
          />
        </div>

        <div>
          <Divider>{t('EXCHANGE_SYNCHRONIZATION.CONTACT_TYPES.TITLE')}</Divider>

          <CheckboxRadioItem
            control="radio"
            label={t('EXCHANGE_SYNCHRONIZATION.CONTACT_TYPES.ALL_CONTACTS')}
            checked={
              contactsExportMode.value === SyncOptionsContactExport.ALL_CONTACTS
            }
            onChange={() => {
              contactsExportMode.set(SyncOptionsContactExport.ALL_CONTACTS);
            }}
            disabled={
              user.isMaster
                ? false
                : contactsAllowedExportMode.value ===
                    SyncOptionsContactExport.NO_EXPORT ||
                  contactsAllowedExportMode.value ===
                    SyncOptionsContactExport.PERSONAL_CONTACTS
            }
          />

          <CheckboxRadioItem
            control="radio"
            label={t(
              'EXCHANGE_SYNCHRONIZATION.CONTACT_TYPES.ONLY_PERSONAL_CONTACTS'
            )}
            checked={
              contactsExportMode.value ===
              SyncOptionsContactExport.PERSONAL_CONTACTS
            }
            onChange={() => {
              contactsExportMode.set(
                SyncOptionsContactExport.PERSONAL_CONTACTS
              );
            }}
            disabled={
              user.isMaster
                ? false
                : contactsAllowedExportMode.value ===
                  SyncOptionsContactExport.NO_EXPORT
            }
          />

          <CheckboxRadioItem
            control="checkbox"
            label={t(
              'EXCHANGE_SYNCHRONIZATION.CONTACT_TYPES.INCL_PRIVATE_ADDRESSES_AND_EMAIL_AND_TELEPHONE'
            )}
            checked={includeContactsPrivateData.value}
            onChange={() => {
              includeContactsPrivateData.set(!includeContactsPrivateData.value);
            }}
          />
        </div>

        <div>
          <Divider>{t('EXCHANGE_SYNCHRONIZATION.APPOINTMENTS.TITLE')}</Divider>

          <CheckboxRadioItem
            control="checkbox"
            label={t(
              'EXCHANGE_SYNCHRONIZATION.APPOINTMENTS.ALLOW_SYNCHRONIZATION'
            )}
            checked={allowSyncingAppointments.value}
            onChange={() => {
              allowSyncingAppointments.set(!allowSyncingAppointments.value);
            }}
          />

          <CheckboxRadioItem
            control="checkbox"
            label={t(
              'EXCHANGE_SYNCHRONIZATION.APPOINTMENTS.EXCLUDE_PRIVATE_APPOINTMENTS'
            )}
            checked={!allowSyncingPrivateAppointments.value}
            onChange={() => {
              allowSyncingPrivateAppointments.set(
                !allowSyncingPrivateAppointments.value
              );
            }}
          />
        </div>
      </Stack>

      <div className={styles.bottomWrapper}>
        <Alert
          variant="filled"
          severity="error"
          color="warning"
          className={styles.alert}
        >
          {t('EXCHANGE_SYNCHRONIZATION.ALERT')}
        </Alert>
      </div>
    </>
  );
};
