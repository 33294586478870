import React, { PropsWithChildren } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLockSubscription } from './LockObjectProvider';

export interface LockSubscriptionProps {
  entity: Entities;
}

/**
 * Component just to wrap hook.
 */
export const LockSubscription: React.FC<
  PropsWithChildren<LockSubscriptionProps>
> = (props: PropsWithChildren<LockSubscriptionProps>) => {
  const { children, entity } = props;
  useLockSubscription(entity);
  return children;
};
