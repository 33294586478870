import styles from './ErpInitialView.module.scss';

import { Button, CircularProgress, Typography } from '@mui/material';
import { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@work4all/components';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useDebounce } from '@work4all/utils/lib/hooks/use-debounce';

import { PartnerPickerByEntity } from '../../../../../../../components/entity-picker/PartnerPickerByEntity';
import { SupplierPickerField } from '../../../../../../../components/entity-picker/SupplierPickerField';

type State = {
  open: boolean;
  businessPartner: Customer | Supplier | null;
};

type Action =
  | { type: 'OPEN' }
  | { type: 'CLOSE' }
  | { type: 'CONFIRM'; payload: Customer | Supplier };

const initialState: State = {
  open: true,
  businessPartner: null,
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'OPEN':
      return {
        ...state,
        open: true,
      };
    case 'CLOSE':
      return {
        ...state,
        open: false,
      };
    case 'CONFIRM':
      return {
        ...state,
        open: false,
        businessPartner: action.payload,
      };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
};

export const useErpInitialView = (
  open: boolean,
  onCanel?: (data: unknown) => void
) => {
  const [isOpen, setOpen] = useState(open);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    open,
  });

  const onOpen = () => {
    dispatch({ type: 'OPEN' });
    setOpen(true);
  };

  const onClose = () => {
    dispatch({ type: 'CLOSE' });
  };

  const onConfirm = (selected: (Supplier | Customer)[]) => {
    dispatch({ type: 'CONFIRM', payload: selected[0] ?? null });
    onClose();
  };

  const closeController = !state.open && isOpen && !state.businessPartner;
  useEffect(() => {
    if (closeController && onCanel) onCanel(null);
  }, [closeController, onCanel]);

  return { onOpen, onClose, onConfirm, ...state };
};

export type ErpInitialViewProps = ReturnType<typeof useErpInitialView>;

export const ErpInitialView = (
  props: ErpInitialViewProps & {
    entity?: Entities;
    creditor?: boolean;
    loading?: boolean;
  }
) => {
  const {
    onClose,
    open,
    onConfirm,
    businessPartner,
    entity,
    creditor,
    loading,
  } = props;
  const { t } = useTranslation();

  let picker = null;
  if (creditor)
    picker = (
      <SupplierPickerField
        label={t('COMMON.CREDITOR')}
        placeholder={t('COMMON.PICK_FROM', {
          from: t('COMMON.CREDITOR'),
        })}
        onChange={(bp) => onConfirm([bp])}
        value={businessPartner as Supplier}
      />
    );
  else
    picker = (
      <PartnerPickerByEntity
        businessPartner={businessPartner}
        entity={entity}
        onChange={onConfirm}
      />
    );

  const debouncedLoading = useDebounce(loading, 300);

  return (
    <Dialog
      open={open}
      title={
        debouncedLoading
          ? `${t('COMMON.LOADING')}...`
          : t('COMMON.SELECTION_REQUIRED')
      }
      onClose={onClose}
    >
      <div className={styles.content}>
        {debouncedLoading ? (
          <div className={styles.center}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Typography variant="body1">
              {t('ALERTS.PRESELECT_INFO', {
                what: creditor
                  ? t('COMMON.CREDITOR_S')
                  : t(
                      `COMMON.${
                        entity ? `${entity?.toUpperCase()}S` : 'BUSINESSPARTNER'
                      }`
                    ),
              })}
            </Typography>
            {picker}
            <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
          </>
        )}
      </div>
    </Dialog>
  );
};
