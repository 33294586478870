import React from 'react';

import { BaseActionProps } from '@work4all/components/lib/input/actions/types';
import { LayoutHorizontalSelect } from '@work4all/components/lib/input/horizontal-select/custom/LayoutHorizontalSelect';

import { useTableLayoutState } from '../../../../../table-layout/use-table-layout-state';

export const LayoutAction: React.FC<
  BaseActionProps & { isBoardEnabled: boolean }
> = (props) => {
  const [layout, setLayout] = useTableLayoutState();
  return (
    <LayoutHorizontalSelect
      {...props}
      layout={layout}
      handleClick={setLayout}
    />
  );
};
