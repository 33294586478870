import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded';
import CircleTwoTone from '@mui/icons-material/CircleTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import UndoIcon from '@mui/icons-material/Undo';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { MailAssignStatus } from '@work4all/models/lib/Enums/MailAssignStatus.enum';

import { useMailboxContentManagerContext } from '../mailbox-content-manager-context';

export function MailboxContentStatusCell(
  props: CellProps<MailboxContent, MailAssignStatus>
) {
  const status = props.value;
  const mailboxContent = props.row.original;
  const id = mailboxContent.id;

  const { t } = useTranslation();

  const context = useMailboxContentManagerContext();

  const canAssign = context.canAssign(id);
  const canIgnore = context.canIgnore(id);
  const canReset = context.canReset(id);

  const isIgnored = status === MailAssignStatus.ITEM_IGNORED;
  const isAssigned =
    status === MailAssignStatus.ALREADY_ASSIGNED ||
    status === MailAssignStatus.ASSIGNED_TO_OBJECT ||
    status === MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL;

  const resolveStatusText = (status: MailAssignStatus) => {
    switch (status) {
      case MailAssignStatus.NOT_YET_ASSIGNED:
        if (canAssign) {
          return t('LIST.MAILBOXCONTENT.ACTION.ASSIGN');
        } else {
          return t('MAIL_ASSIGN_STATUS.NOT_YET_ASSIGNED');
        }
      case MailAssignStatus.ALREADY_ASSIGNED:
      case MailAssignStatus.ASSIGNED_TO_OBJECT:
      case MailAssignStatus.ASSIGNED_TO_OBJECT_AND_MAIL:
        return t('MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED');
      case MailAssignStatus.ITEM_IGNORED:
        return t('MAIL_ASSIGN_STATUS.ITEM_IGNORED');
    }
  };

  const buttonProps = canAssign
    ? {
        component: 'button',
        onClick: async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          await context.assign([id]);
          context.refetch();
        },
      }
    : { disabled: true };

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box display="flex">
        {isAssigned || canAssign ? (
          <Tooltip
            title={
              canAssign
                ? t('LIST.MAILBOXCONTENT.ACTION.ASSIGN')
                : t('MAIL_ASSIGN_STATUS.ALREADY_ASSIGNED')
            }
            placement="right"
          >
            <IconButton
              color="primary"
              sx={{
                flex: 'none',
                padding: 0,
                marginRight: '13px',
                cursor: canAssign ? 'pointer' : 'default',
              }}
              {...buttonProps}
            >
              {canAssign ? (
                <CircleTwoTone sx={{ color: 'var(--text03)' }} />
              ) : (
                <CheckCircleRounded sx={{ color: 'var(--success)' }} />
              )}
            </IconButton>
          </Tooltip>
        ) : (
          <Box width="32px" />
        )}
        {canAssign ? (
          <Button
            sx={{
              padding: 0,
              color: 'inherit',
              '&:hover': {
                background: 'transparent',
              },
            }}
            {...buttonProps}
          >
            <Typography noWrap variant="body1" color="inherit">
              {resolveStatusText(status)}
            </Typography>
          </Button>
        ) : (
          <Typography
            noWrap
            variant="body1"
            sx={{
              color:
                isAssigned || isIgnored ? 'var(--text03)' : 'var(--text01)',
            }}
          >
            {resolveStatusText(status)}
          </Typography>
        )}
      </Box>
      {canIgnore && (
        <Tooltip
          title={t('LIST.MAILBOXCONTENT.ACTION.IGNORE')}
          placement="right"
        >
          <IconButton
            sx={{
              flex: 'none',
              padding: 0,
              color: 'inherit',
            }}
            onClick={async () => {
              await context.ignore([id]);
              context.refetch();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      )}
      {canReset && (
        <Tooltip title={t('LIST.MAILBOXCONTENT.ACTION.UNDO')} placement="right">
          <IconButton
            color="primary"
            sx={{
              flex: 'none',
              padding: 0,
            }}
            onClick={async () => {
              await context.reset([id]);
              context.refetch();
            }}
          >
            <UndoIcon sx={{ color: 'var(--text01)' }} />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
}
