import styles from '../ProjectPlanningView.module.scss';

import { MbscCalendarColor, MbscResource } from '@mobiscroll/react';
import { useMemo } from 'react';

function getFlatResources(resources: MbscResource[]): MbscResource[] {
  let result: MbscResource[] = [];
  for (const resource of resources) {
    result.push(resource);
    if (resource.children) {
      result = result.concat(getFlatResources(resource.children));
    }
  }
  return result;
}

export const useColors = (props: {
  resources: MbscResource[];
  focusedResourceId?: number;
}) => {
  const { resources, focusedResourceId } = props;
  return useMemo<MbscCalendarColor[]>(() => {
    const flatResources = getFlatResources(resources);
    const result: MbscCalendarColor[] = [];

    const mapColor = (resource) => {
      result.push({
        resource: resource.id,
        start: '00:00',
        end: '23:59',
        recurring: {
          repeat: 'weekly',
          weekDays: 'SA,SU',
        },
        cssClass: styles.weekend,
      } as MbscCalendarColor);

      result.push({
        resource: resource.id,
        start: '00:00',
        end: '23:59',
        recurring: {
          repeat: 'weekly',
          weekDays: 'MO,TU,WE,TH,FR',
        },
        cssClass: styles.workDay,
      } as MbscCalendarColor);

      if (focusedResourceId === resource.id) {
        result.push({
          resource: resource.id,
          start: '00:00',
          end: '23:59',
          recurring: {
            repeat: 'daily',
          },
          cssClass: styles.selectedDay,
        } as MbscCalendarColor);
      }
    };

    flatResources.forEach((event) => {
      mapColor(event);
    });

    return result;
  }, [focusedResourceId, resources]);
};
