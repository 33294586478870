// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyObject = { [key: string]: any };

export const writeObject = (object: AnyObject = {}, result: AnyObject) => {
  if (!object) return;
  Object.entries(object).forEach((x) => {
    const [field, value] = x;
    if (typeof value === 'object' && result[field]) {
      writeObject(value, result[field]);
    } else result[field] = value;
  });
};
