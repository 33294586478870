import { Loader } from '@googlemaps/js-api-loader';
import i18next from 'i18next';

const loader = new Loader({
  apiKey: process.env.NX_PUBLIC_GOOGLE_API_KEY,
  version: 'weekly',
  libraries: [],
});

export async function fetchPlaceSuggestions({
  container,
  query,
  type,
  signal,
}: {
  container: HTMLDivElement;
  query: string;
  type?: string;
  signal?: AbortSignal;
}): Promise<google.maps.places.PlaceResult[]> {
  const api = await loader.importLibrary('places');

  const service = new api.PlacesService(container);

  return new Promise((resolve, reject) => {
    service.textSearch(
      {
        query,
        type,
        language: i18next.language,
      },
      (results, status) => {
        if (signal?.aborted) {
          return reject(signal.reason);
        }

        if (
          status !== google.maps.places.PlacesServiceStatus.OK &&
          status !== google.maps.places.PlacesServiceStatus.ZERO_RESULTS
        ) {
          return reject(new Error(`Failed to fetch places (Status ${status})`));
        }

        resolve(results ?? []);
      }
    );
  });
}

export async function fetchPlaceDetails({
  placeId,
  container,
  signal,
}: {
  placeId: string;
  container: HTMLDivElement;
  signal?: AbortSignal;
}): Promise<google.maps.places.PlaceResult> {
  const api = await loader.importLibrary('places');

  const service = new api.PlacesService(container);

  return new Promise((resolve, reject) => {
    service.getDetails(
      {
        placeId,
        fields: [
          'name',
          'address_components',
          'website',
          'international_phone_number',
        ],
        language: i18next.language,
      },
      (result, status) => {
        if (signal?.aborted) {
          return reject(signal.reason);
        }

        if (status !== google.maps.places.PlacesServiceStatus.OK || !result) {
          return reject(
            new Error(`Failed to fetch place details (Status ${status})`)
          );
        }

        resolve(result);
      }
    );
  });
}
