import { memo, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useDataMutation } from '@work4all/data/lib/hooks/data-provider/useDataMutation';

import { InputArticleRelation } from '@work4all/models/lib/Classes/InputArticleRelation.entity';
import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';

import { AccountingTabPanel } from './tabs/accounting/AccountingTabPanel';
import { AttachmentsTabPanel } from './tabs/attachments/AttachmentsTabPanel';
import { ArticlePickerProvider } from './tabs/components/components/ArticlePickerContext';
import { ComponentArticlePicker } from './tabs/components/components/ComponentArticlePicker';
import { ComponentsTabPanel } from './tabs/components/ComponentsTabPanel';
import { GeneralTabPanel } from './tabs/general/GeneralTabPanel';
import { MiscellaneousTabPanel } from './tabs/miscellaneous/MiscellaneousTabPanel';
import { PricesTabPanel } from './tabs/prices/PricesTabPanel';
import { ArticleMaskFormValue } from './types';
import { useArticleFormUpdate } from './use-article-form-update';
import { useArticleRequest } from './use-article-request';
import { useNewArticleData } from './use-new-article-data';
import { mapRelations } from './utils/map-relations';

export const ArticleOverlayController = (props: MaskControllerProps) => {
  const mask = useMaskConfig(props);

  const customFields = useCustomFieldsConfig({ entity: mask.entity });

  const request = useArticleRequest({ id: mask.id });

  const presetFields = useMemo(() => {
    return mask.params?.presetFields
      ? JSON.parse(mask.params.presetFields)
      : undefined;
  }, [mask?.params?.presetFields]);

  const newEntityData = useNewArticleData({
    enabled: mask.isCreateMode,
    presetFields,
  });

  const getTempFileInitialData = useCallback((data: ArticleMaskFormValue) => {
    const documents = data.articleDocumentList ?? [];

    return documents
      .map((doc) => {
        const { name, ...rest } = doc;
        return { fileName: name, ...rest };
      })
      .sort((a, b) => a.fileName?.localeCompare(b.fileName));
  }, []);

  const overlay = useMaskOverlay<ArticleMaskFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    customFields,
    getSubTitle: (x) => x.name,
    getTempFileInitialData,
  });

  const { form, data } = overlay;

  const [mutate] = useDataMutation<
    ArticleMaskFormValue,
    EMode.upsert,
    InputArticleRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: request.data,
    onCompleted: (data) => {
      props.onAfterSave(data);
    },
  });

  useArticleFormUpdate(form, mask);

  const handleSubmit = async (values: ArticleMaskFormValue) => {
    const updateRaw = mask.isCreateMode
      ? values
      : pickUpdateFields(values, form.formState.dirtyFields);

    const updateMapped = prepareInputWithCustomFields(updateRaw);
    const relations = mapRelations(data, values, overlay.tempFileManager);

    const shouldResetDefault = ![
      ...relations.attachments.add,
      ...relations.attachments.modify,
    ].some((x) => x.defaultDocumentToDisplay);
    if (shouldResetDefault) updateMapped.standardImageId = 0;
    await mutate(updateMapped, { relations });
  };

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <ArticlePickerProvider>
      <OverlayController<ArticleMaskFormValue>
        {...overlay}
        actions={<ComponentArticlePicker />}
        onSubmit={handleSubmit}
        tabs={
          <ArticleMaskTabs
            isCreateMode={mask.isCreateMode}
            renderIndividualTab={shouldRenderIndividualTab}
          />
        }
      >
        <ArticleMaskContent renderIndividualTab={shouldRenderIndividualTab} />
      </OverlayController>
    </ArticlePickerProvider>
  );
};

const ArticleMaskTabs = memo(function CrmTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();

  const showComponentsTab =
    form.watch('articleKind') === ArticleKind.STUECKLISTE;

  return (
    <MaskTabs>
      <MaskTab value="general" label={t('MASK.GENERAL')} />
      <MaskTab value="misc" label={t('MASK.MISCELLANEOUS')} />

      {showComponentsTab && (
        <MaskTab value="components" label={t('COMMON.ERP.COMPONENTS')} />
      )}

      <MaskTab value="prices" label={t('COMMON.ERP.PRICING')} />
      <MaskTab value="accounting" label={t('COMMON.ERP.BOOKING')} />
      <MaskTab value="attachments" label={t('COMMON.MASK.DOCUMENTS')} />

      {renderIndividualTab && (
        <MaskTab value="individual" label={t('MASK.INDIVIDUAL')} />
      )}
      <MaskTab
        value="history"
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      />
    </MaskTabs>
  );
});

const ArticleMaskContent = memo(function ArticleMaskContent({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value="general">
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="misc">
        <MiscellaneousTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="components">
        <ComponentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="prices">
        <PricesTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="accounting">
        <AccountingTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value="individual">
          <IndividualTabPanel />
        </MaskTabPanel>
      )}

      <MaskTabPanel value="history">
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
});
