import styles from './CheckboxRadioItem.module.scss';

import { FormControlLabel, Stack } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Body1, IBody1Props } from '../../typography/body1/Body1';
import { Body2, IBody2Props } from '../../typography/body2/Body2';
import { Caption } from '../../typography/caption/Caption';
import { Checkbox, Radio } from '../checkbox-radio/CheckboxRadio';

import { ICheckboxItem } from './types';

export type CheckboxRadioItemProps = ICheckboxItem & {
  invert?: boolean;
  fitContent?: boolean;
  dynamicHeight?: boolean;
};

export const CheckboxRadioItem = React.forwardRef<
  HTMLInputElement,
  CheckboxRadioItemProps
>(function CheckboxRadioItem(props, ref) {
  const {
    label,
    caption,
    checked,
    defaultChecked,
    control = 'checkbox',
    horizontalPadding = true,
    verticalPadding = false,
    component,
    controlMargin = 'xs',
    bold,
    className,
    icon,
    invert,
    fitContent = false,
    dynamicHeight = true,
    ...rest
  } = props;

  let Body: React.FC<IBody1Props | IBody2Props> = Body1;
  if (component) {
    Body = component === 'body1' ? Body1 : Body2;
  } else {
    Body = control === 'checkbox' ? Body1 : Body2;
  }

  const Control = control === 'checkbox' ? Checkbox : Radio;
  return (
    <Stack
      direction="row"
      alignItems="center"
      height={dynamicHeight ? undefined : '2.25rem'}
    >
      <FormControlLabel
        inputRef={ref}
        className={clsx(styles.root, className, {
          [styles.horizontalPadding]: horizontalPadding,
          [styles.verticalPadding]: verticalPadding,
          [styles.flex]: icon,
          [styles.fitContent]: fitContent,
        })}
        classes={{ label: styles.label }}
        label={
          <div
            onMouseDown={(e) => {
              /** prevent selection */
              e.preventDefault();
            }}
          >
            <Body
              className={clsx(styles.body, styles[control], {
                [styles.bold]: bold,
                [styles.flex]: icon,
                [styles.icon]: icon,
              })}
            >
              {icon}
              {label}
            </Body>
            {caption && typeof caption === 'string' ? (
              <Caption className={styles.caption}>{caption}</Caption>
            ) : (
              caption
            )}
          </div>
        }
        control={
          <Control
            key={defaultChecked ? 'checked' : 'noKey'}
            defaultChecked={invert ? !defaultChecked : defaultChecked}
            checked={invert ? !checked : checked}
            className={clsx({
              [styles[`controlMargin--${controlMargin}`]]: controlMargin,
            })}
          />
        }
        {...rest}
      />
    </Stack>
  );
});
