import { Box, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

import { LookUpPickerField } from '../../../../../../../components/entity-picker/LookUpPickerField';
import { SupplierPickerField } from '../../../../../../../components/entity-picker/SupplierPickerField';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  LabeledInput,
  LabeledInputWithUnit,
} from '../../../../../locked-inputs';
import { ArticleMaskFormValue } from '../../types';

export function MiscellaneousTabPanel() {
  const { t } = useTranslation();

  const form = useFormContextPlus<ArticleMaskFormValue>();
  const { register } = form;

  return (
    <Box sx={{ py: '1rem' }}>
      <Grid container columns={{ xs: 1, md: 2 }}>
        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.ORGANIZATIONAL')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={2} spacing="1rem">
              <Grid item xs={1}>
                <LabeledInputWithUnit
                  label={t('MASK.ARTICLE.FIELDS.maintenanceShare')}
                  unit="%"
                  {...register('maintenanceShare')}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="customsTariffNumber"
                  render={({ field }) => {
                    const label = t('MASK.ARTICLE.FIELDS.customsTariffNumber');
                    return <LabeledInput label={label} {...field} />;
                  }}
                />
              </Grid>

              <Grid item xs={2}>
                <ControllerPlus
                  control={form.control}
                  name="summarizeForPackingLists"
                  render={({ field: { value: checked, ...field } }) => {
                    const label = t(
                      'MASK.ARTICLE.FIELDS.summarizeForPackingLists'
                    );
                    const tooltip = t(
                      'MASK.ARTICLE.FIELDS.summarizeForPackingLists_description'
                    );

                    return (
                      <Tooltip title={tooltip}>
                        <CheckboxRadioItem
                          control="checkbox"
                          label={label}
                          checked={checked}
                          defaultChecked={checked}
                          {...field}
                        />
                      </Tooltip>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.REROURCES')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={2} spacing="1rem">
              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="ressourceClass"
                  render={({ field }) => {
                    return (
                      <LookUpPickerField
                        label={t('MASK.ARTICLE.FIELDS.ressourceClass')}
                        lookupType={LookupType.RESSOURCENKLASSEN}
                        multiple={false}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <LabeledInput
                  disabled
                  label={t('MASK.ARTICLE.RESOURCE_ALLOCATION')}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="isArticleForRessourcePlaning"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t(
                          'MASK.ARTICLE.FIELDS.isArticleForRessourcePlaning'
                        )}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.WARRANTY')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={{ xs: 1, md: 2 }} spacing="1rem">
              <Grid item xs={1}>
                <LabeledInputWithUnit
                  disabled
                  label={t('MASK.ARTICLE.FIELDS.WARRANTY')}
                  unit={t('COMMON.MONTHS')}
                />
              </Grid>

              <Grid item xs={1}>
                <LabeledInputWithUnit
                  disabled
                  label={t('MASK.ARTICLE.FIELDS.REPAIR_GUARANTEE')}
                  unit={t('COMMON.MONTHS')}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.W4A_SHOP')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={2} spacing="1rem">
              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="hideInShop"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.hideInShop')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="shopOrderWithoutStock"
                  render={({ field: { value: checked, ...field } }) => {
                    return (
                      <CheckboxRadioItem
                        control="checkbox"
                        label={t('MASK.ARTICLE.FIELDS.shopOrderWithoutStock')}
                        checked={checked}
                        defaultChecked={checked}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>

        <Grid item xs={1}>
          <Collapse
            title={t('MASK.ARTICLE.MANUFACTURER')}
            defaultOpen
            bottomIndent="lg"
          >
            <Grid container columns={2} spacing="1rem">
              <Grid item xs={2}>
                <ControllerPlus
                  control={form.control}
                  name="manufacturer"
                  render={({ field }) => {
                    return (
                      <SupplierPickerField
                        label={t('MASK.ARTICLE.FIELDS.manufacturer')}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <ControllerPlus
                  control={form.control}
                  name="manufacturerNumber"
                  render={({ field }) => {
                    return (
                      <LabeledInput
                        label={t('MASK.ARTICLE.FIELDS.manufacturerNumber')}
                        {...field}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={1}>
                <LabeledInput
                  label={t('MASK.ARTICLE.FIELDS.ean')}
                  {...register('ean')}
                />
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </Box>
  );
}
