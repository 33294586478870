import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useOpenMask } from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { ITableProps } from '../table/Table';
import { useTableHandlers } from '../use-table-handlers';

import { CreateUserAction } from './CreateUserAction';

const entityType = Entities.fullUser;

export const useUserActions = () => {
  const rootRef = useRef();
  const dialogs = useDialogs();
  const { t } = useTranslation();

  const onOpenMask = useOpenMask({ entityType });
  const tableHandlers = useTableHandlers(entityType, onOpenMask);

  return useMemo<ITableProps['actions']>(() => {
    return {
      add: {
        title: 'Add',
        handler: () => {
          const { id } = dialogs.open(CreateUserAction, {
            rootRef,
            onCreate: (userKind) => tableHandlers.create.handler({ userKind }),
            onClose: () => {
              dialogs.close(id);
            },
          });
        },
        rootRef,
        mode: 'Add',
      },
      remove: {
        maxCount: 1,
        handler: () => {},
        disabled: true,
      },
    };
  }, [t, dialogs]);
};
