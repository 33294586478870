import * as ReactSentry from '@sentry/react';
import { useEffect, useMemo, useRef } from 'react';

export interface UseTransactionOptions {
  name: string;
  op?: string;
  /**
   * Indicate that ongoing event is still in progress
   */
  eventInProgress: boolean;
}

/**
 * Transaction will start immediately.
 * Transaction will close by itself when falling edge occure on @eventInProgress variable
 */
export const useTransaction = (options: UseTransactionOptions) => {
  const { name, op = 'w4a2.render', eventInProgress } = options;
  const transaction = useMemo(() => {
    return createTransaction({ name, op });
  }, [name, op]);

  const previousState = useRef(false);
  useEffect(() => {
    if (!eventInProgress && previousState.current) {
      transaction?.finish();
    }
    previousState.current = eventInProgress;
  }, [transaction, eventInProgress]);
};

type CreateTransactionProps = Omit<UseTransactionOptions, 'eventInProgress'>;

export function createTransaction({
  name,
  op = 'w4a2.closure',
}: CreateTransactionProps) {
  const transaction = ReactSentry.startTransaction({
    name,
    op,
  });
  ReactSentry.getCurrentHub().configureScope((scope) =>
    scope.setSpan(transaction)
  );

  return {
    finish: () => transaction?.finish(),
    setData: (key: string, value: unknown) => transaction?.setData(key, value),
  };
}

export const captureMessage = ReactSentry.captureMessage;
