import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Cell, Column } from 'react-table';

import {
  CheckboxCell,
  CURRENCY_PARAMS,
  INumberCellProps,
  NumberCell,
} from '@work4all/components';

import { remToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

import { UseEditableStateResult } from '../../../../erp/components/tab-panels/positions/components/edit-table/hooks/use-editable-state';
import { EditTableColumns } from '../../../../erp/components/tab-panels/positions/components/edit-table/types';
import { ArticlePickerCell } from '../../../../erp/components/tab-panels/positions/components/positions-table/ArticlePickerCell';
import { ARTICLE_BOM_COMPONENT_DATA } from '../constants';
import { BOMComponentPosition } from '../types';

const makeNumberWithFooter = (key: keyof Article) => {
  return {
    Cell: NumberCell,
    type: 'number' as EditTableColumns['type'],
    editable: false,
    ...CURRENCY_PARAMS,
    Footer: (cell) => {
      return (
        <NumberCell
          cell={cell as unknown as INumberCellProps['cell']}
          value={cell.data.reduce(
            (prev, cur) => (cur.amount || 0) * (cur.article?.[key] || 0) + prev,
            0
          )}
        />
      );
    },
  };
};

type UseBomComponentsColumnsProps = Pick<
  UseEditableStateResult<BOMComponentPosition>,
  'onAddPosition'
>;

export const useBomComponentsColumns = (
  props: UseBomComponentsColumnsProps
) => {
  const { onAddPosition } = props;
  const { t } = useTranslation();
  const columns = useMemo(() => {
    const columns: EditTableColumns<BOMComponentPosition>[] = [
      {
        Header: t('INPUTS.DESCRIPTION'),
        accessor: 'name',
        type: 'text',
        editable: false,
        shouldRender: (position: BOMComponentPosition) => {
          return position.cacheOnly;
        },
        render: (
          _cell: Cell<BOMComponentPosition>,
          { isEditMode, tableInstance }
        ) => {
          return (
            <ArticlePickerCell
              cell={_cell}
              label={t('ERP.ADD_COMPONENT')}
              data={
                ARTICLE_BOM_COMPONENT_DATA as unknown as Article<EMode.entity>
              }
              isEditMode={isEditMode}
              onAddPosition={(context) =>
                onAddPosition({ ...context, amount: 1 })
              }
              tableInstance={tableInstance}
            />
          );
        },
      },
      {
        Header: t('COMMON.KIND'),
        accessor: 'article.articleKind',
      },
      {
        Header: t('COMMON.NUMBER'),
        accessor: 'article.number',
      },
      {
        Header: t('POS_LIST.AMOUNT'),
        type: 'number',
        accessor: 'amount',
        Cell: NumberCell,
        cellParams: {
          maximumFractionDigits: 3,
        },
      },
      {
        Header: t('COMMON.ERP.UNIT'),
        accessor: 'article.unit.name',
        width: remToPx(4.5),
      },
      {
        Header: t('COMMON.ERP.PURCHASE_PRICE'),
        accessor: 'prurchasePrice',
        type: 'number',
        editable: false,
        Cell: NumberCell,
        ...CURRENCY_PARAMS,
      },
      {
        Header: t('FIELDS.entryPrice'),
        accessor: 'article.entryPrice',
        ...makeNumberWithFooter('entryPrice'),
      },
      {
        Header: t('FILTER.groups.price.purchase'),
        accessor: 'article.netPrice',
        ...makeNumberWithFooter('netPrice'),
      },
      {
        Header: t('FILTER.groups.price.buy'),
        accessor: 'article.netPrice2',
        ...makeNumberWithFooter('netPrice2'),
      },
      {
        Header: t('FILTER.groups.price.cloud'),
        accessor: 'article.netPrice3',
        ...makeNumberWithFooter('netPrice3'),
      },
      {
        Header: t('COMMON.NOTE'),
        accessor: 'note',
      },
      {
        Header: t('COMMON.ERP.ONLY_SHOPPING'),
        accessor: 'onlyShopping',
        Cell: CheckboxCell,
      },
      {
        Header: t('COMMON.ERP.WIDTH'),
        accessor: 'width',
        Cell: NumberCell,
      },
      {
        Header: t('COMMON.ERP.LENGTH'),
        accessor: 'length',
        Cell: NumberCell,
      },
    ];
    return columns;
  }, [t, onAddPosition]);
  return columns as Column<BOMComponent>[];
};
