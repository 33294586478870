import styles from './TaskPreview.module.scss';

import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useDataMutation, useInaccessibleFields } from '@work4all/data';

import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { typeNameToSdObjType } from '@work4all/utils';

import {
  InfoCard,
  InfoCards,
  PreviewEditMessage,
  PreviewTextContent,
  PreviewWrapper,
} from '../components';
import {
  BusinessPartnerPreviewPickerInput,
  ContactPreviewPickerInput,
  DatePreviewPickerInput,
  PreviewInputsProvider,
  PreviewTitleInput,
  ProjectPreviewPickerInput,
  TaskStatusPreviewPickerInput,
  UserPreviewPickerInput,
} from '../components/preview-input';
import { EntityHistory } from '../EntityHistory';
import { useEntityPreview } from '../hooks/use-entity-preview';
import { EntityPreviewProps } from '../types';

import { taskPreviewFields } from './TaskPreviewContainer';

export type ITaskPreviewProps = EntityPreviewProps<Task>;

export function TaskPreview(props: ITaskPreviewProps) {
  const { entries, ...handlers } = props;
  const { t } = useTranslation();

  const { isInaccessible } = useInaccessibleFields();

  const creatorIsAccessible = !isInaccessible(Entities.task, 'creator');
  const noteIsAccessible = !isInaccessible(Entities.task, 'note');

  const [mutate] = useDataMutation<Task, EMode.upsert>({
    entity: Entities.task,
    mutationType: EMode.upsert,
    responseData: taskPreviewFields as unknown as Task,
  });

  const entityPreview = useEntityPreview({
    subEntityType: Entities.task,
    entries: entries,
    mutate,
  });

  const { isMultiselect, commonFields, onPopoverClose, onEdit } = entityPreview;

  const task = entries[0];

  const customerIsPrivate = task?.businessPartner?.data?.isPrivateCustomer;
  const customerIsCommon = commonFields.includes('businessPartner');
  const creatorIsCommon = commonFields.includes('creator');

  const callMemoContactData = {
    businessPartner: task?.businessPartner,
    project: task?.project,
    projectProcess: task?.projectProcess,
    topicMarkList: task?.topicMarkList?.[0],
  };

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.task}
      entries={entries}
    >
      <PreviewWrapper>
        <PreviewTitleInput accessor="title" {...handlers} />

        {isMultiselect && (
          <PreviewEditMessage
            entityId={String(task.id)}
            entityType={Entities.task}
          />
        )}

        <InfoCards columns={3}>
          {creatorIsAccessible && (
            <InfoCard staticField={true} label={t('COMMON.CREATOR')}>
              {creatorIsCommon
                ? task?.creator?.displayName || '-'
                : `(${t('COMMON.MULTIPLE')})`}
            </InfoCard>
          )}

          <UserPreviewPickerInput
            labelTranslationKey="COMMON.RESPONSIBLE"
            onClose={onPopoverClose}
            value={task?.user}
            onChange={(value) => {
              onEdit({
                userId: value.id,
                user: { id: value.id, displayName: value.displayName },
              });
            }}
            accessor="user"
          />

          <DatePreviewPickerInput
            labelTranslationKey="COMMON.BEGIN"
            onClose={onPopoverClose}
            value={task?.date}
            onChange={(value) => {
              onEdit({ date: new Date(value).toISOString() });
            }}
            accessor="date"
          />

          <DatePreviewPickerInput
            labelTranslationKey="FIELDS.end_task"
            onClose={onPopoverClose}
            value={task?.endDate}
            onChange={(value) => {
              onEdit({ endDate: new Date(value).toISOString() });
            }}
            accessor="endDate"
          />

          <BusinessPartnerPreviewPickerInput
            labelTranslationKey="COMMON.BUSINESS_PARTNER"
            onClose={onPopoverClose}
            value={task?.businessPartner?.data}
            onChange={(value) => {
              onEdit({
                businessPartner: value
                  ? {
                      data: value,
                      businessPartnerType: typeNameToSdObjType(
                        value.__typename
                      ),
                      id: value.id,
                    }
                  : null,
                businessPartnerId: value ? value.id : 0,
                businessPartnerType: typeNameToSdObjType(value?.__typename),
                contactId: value?.mainContact?.id ?? null,
              });
            }}
            accessor="businessPartner"
            showFooter
          />

          {!customerIsPrivate && (
            <ContactPreviewPickerInput
              labelTranslationKey="COMMON.CONTACT"
              onClose={onPopoverClose}
              value={task?.contact}
              otherProps={{ callMemoContactData }}
              onChange={(value) => {
                onEdit({
                  contact: value,
                  contactId: value ? value.id : 0,
                });
              }}
              accessor="contact"
              prefilter={[
                {
                  businessPartnerType: {
                    $eq:
                      task?.businessPartner?.businessPartnerType ??
                      SdObjType.KUNDE,
                  },
                },
                {
                  businessPartnerId: {
                    $eq: task?.businessPartner?.data.id ?? 0,
                  },
                },
              ]}
              disabled={!customerIsCommon || !task?.businessPartner?.data}
              showFooter
              entityTemplateValue={{
                id: task?.businessPartner?.data?.id,
                entity:
                  task?.businessPartner?.businessPartnerType === SdObjType.KUNDE
                    ? Entities.customer
                    : Entities.supplier,
              }}
            />
          )}

          <ProjectPreviewPickerInput
            labelTranslationKey="COMMON.PROJECT"
            onClose={onPopoverClose}
            value={task?.project}
            onChange={(value) => {
              onEdit({
                project: value,
                projectId: value && !Array.isArray(value) ? value.id : 0,
                projectProcess:
                  value?.id === task?.projectProcess?.projectId
                    ? task?.projectProcess
                    : null,
                projectProcessId:
                  value?.id === task?.projectProcess?.projectId
                    ? task?.projectProcess?.id
                    : 0,
              });
            }}
            accessor="project"
            showFooter
          />

          <TaskStatusPreviewPickerInput
            labelTranslationKey="COMMON.STATUS"
            onClose={onPopoverClose}
            value={task?.status}
            onChange={(value) => {
              onEdit({ status: value });
            }}
            accessor="status"
          />
        </InfoCards>

        <Divider orientation="horizontal" />

        {noteIsAccessible && (
          <PreviewTextContent>{task?.note}</PreviewTextContent>
        )}

        <Divider orientation="horizontal" />

        <EntityHistory
          fitContainer={false}
          entity={Entities.task}
          fullWidth
          id={task.id}
          className={styles.hist}
          breadcrumbTitle={task.title}
        />
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
