import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { VacationRequestStatus } from '../Enums/VacationRequestStatus.enum';

import { User } from './User.entity';
import { Vacation } from './Vacation.entity';

export class VacationRequestEntity<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: createdByUserCode */
  createdByUserId?: number;
  /** Alias: userCode */
  userId?: number;
  /** Alias: status */
  status?: VacationRequestStatus;
  /** Alias: user */
  user?: User<T>;
  /** Alias: vacationItems */
  vacationItems?: Vacation<T>[];
  /** Alias: approvalUserCode */
  releasedByUserId?: string;
  /** Alias: dateFrom */
  dateFrom?: string;
  /** Alias: dateTo */
  dateTo?: string;
  /** Alias: approvalUser */
  releasedByUser?: User<T>;
  /** Alias: createdByUser */
  createdByUser?: User<T>;

  __typename?: string;
}

const fields: FieldDefinitions<VacationRequestEntity> = {
  id: {
    alias: 'id',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  createdByUserId: {
    alias: 'createdByUserCode',
  },
  userId: {
    alias: 'userCode',
  },
  status: {
    alias: 'status',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  vacationItems: {
    alias: 'vacationItems',
    entity: Entities.vacation,
  },
  releasedByUserId: {
    alias: 'approvalUserCode',
  },
  dateFrom: {
    alias: 'dateFrom',
  },
  dateTo: {
    alias: 'dateTo',
  },
  releasedByUser: {
    alias: 'approvalUser',
    entity: Entities.user,
  },
  createdByUser: {
    alias: 'createdByUser',
    entity: Entities.user,
  },
  __typename: {
    alias: '__typename',
  },
};

export const vacationRequestEntityEntityDefinition: EntitiyDefinition<VacationRequestEntity> =
  {
    local: {},
    remote: {
      queryName: 'getVacationRequests',
      fragmentName: 'VacationRequestEntity',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
