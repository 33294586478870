import { gql } from '@apollo/client';

export const GET_OBJECT_LOCKS = gql`
  query getObjectLocks($objectTypes: [ObjectType]!) {
    getObjectLocks(objectTypes: $objectTypes) {
      objPrimaryKey: objPrimaryKey
      application
      user: benutzer {
        id: code
        displayName: anzeigename
        __typename
      }
      __typename
    }
  }
`;
