import { useEventCallback } from '@mui/material/utils';
import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import { settings, useSetting } from '../../../settings';
import {
  DEFAULT_APPOINTMENT_GROUP_MODE,
  DEFAULT_APPOINTMENT_TIME_RANGE,
  DEFAULT_PLANNING_APPOINTMENT_VALUE,
} from '../constants';
import { AppointmentCalendarLastParams } from '../types';

export interface UseLastCalendarStateReturn {
  lastParams: AppointmentCalendarLastParams;
  setLastParams: (params: AppointmentCalendarLastParams) => void;
}

export function useLastCalendarParams(): UseLastCalendarStateReturn {
  const user = useUser();
  const userId = user.benutzerCode;

  const lastParamsSetting = useSetting(settings.calendarLastViewParams());

  const defaultParams = useMemo<AppointmentCalendarLastParams>(() => {
    const params: AppointmentCalendarLastParams = {
      userIds: [userId],
      focusedUserIds: [],
      appointmentStates: [],
      range: DEFAULT_APPOINTMENT_TIME_RANGE,
      groupMode: DEFAULT_APPOINTMENT_GROUP_MODE,
      planningAppointments: DEFAULT_PLANNING_APPOINTMENT_VALUE,
    };

    return params;
  }, [userId]);

  const lastParams = lastParamsSetting.value ?? defaultParams;

  const setLastParams = useEventCallback(
    (params: AppointmentCalendarLastParams) => {
      lastParamsSetting.set(params);
    }
  );

  return { lastParams, setLastParams };
}
