import { UseFormReturn } from 'react-hook-form';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Position } from '@work4all/models/lib/Classes/Position.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { TicketCategory1 } from '@work4all/models/lib/Classes/TicketCategory1.entity';
import { TicketKind } from '@work4all/models/lib/Classes/TicketKind.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { BzObjType } from '@work4all/models/lib/Enums/BzObjType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { useFormUpdate } from '../../../utils/use-form-update';
import { TicketMaskFormValue } from '../types';

export const useTicketFormUpdate = (
  form: UseFormReturn<TicketMaskFormValue>
) => {
  const { getValues } = form;

  const updateContractAndServiceContract = (
    entity: Entities.contract | Entities.serviceContract
  ) => {
    const contract = getValues('contract');
    const serviceContract = getValues('serviceContract');
    const position = getValues('position');
    const isContract = entity === Entities.contract;

    const values = {
      clearServiceContract: {
        serviceContract: null,
        serviceObjectId: 0,
      },
      setServiceContract: {
        serviceObjectId: serviceContract?.id ?? 0,
      },
      clearContract: {
        contract: null,
        bZObjMemberId: 0,
      },
      setContract: {
        bZObjMemberId: contract?.id ?? 0,
        bZObjType: BzObjType.AUFTRAG,
      },
    };

    let result = {};
    if (contract && serviceContract) {
      result = isContract
        ? { ...values.setContract, ...values.clearServiceContract }
        : { ...values.setServiceContract, ...values.clearContract };
    } else {
      result = isContract ? values.setContract : values.setServiceContract;
    }

    if (position) {
      result['position'] = null;
    }

    return result;
  };

  useFormUpdate(
    {
      'businessPartner.data': (businessPartner: Customer | Supplier) => {
        const businessPartnerId = businessPartner?.id ?? 0;
        const businessPartnerType =
          businessPartner?.__typename === 'Lieferant'
            ? SdObjType.LIEFERANT
            : SdObjType.KUNDE;
        const contact = businessPartner?.mainContact ?? null;

        return { businessPartnerId, businessPartnerType, contact };
      },
      contact: (contact: Contact) => {
        const contactId = contact?.id ?? 0;

        return { contactId };
      },
      project: (project: Project) => {
        const projectId = project?.id ?? 0;
        const businessPartnerField = getValues('businessPartner.data');

        if (businessPartnerField !== null) {
          return { projectId, projectProcess: null };
        } else {
          const businessPartner =
            project?.customer ?? project?.supplier ?? null;

          return {
            projectId,
            projectProcess: null,
            'businessPartner.data': businessPartner,
          };
        }
      },
      projectProcess: (projectProcess: ProjectProcess) => {
        const projectProcessId = projectProcess?.id ?? 0;

        return { projectProcessId };
      },
      ticketKind: (ticketKind: TicketKind) => {
        const ticketKindId = ticketKind?.id ?? 0;

        return { ticketKindId };
      },
      ticketCategory1: (ticketCategory1: TicketCategory1) => {
        const category1Id = ticketCategory1?.id ?? 0;

        return { category1Id };
      },
      ticketCategory2: (ticketCategory2: LookUp) => {
        const category2Id = ticketCategory2?.id ?? 0;

        return { category2Id };
      },
      editor1: (editor1: User) => {
        const editor1Id = editor1?.id ?? 0;
        return { editor1Id };
      },
      editor2: (editor2: User) => {
        const editor2Id = editor2?.id ?? 0;
        return { editor2Id };
      },
      contract: () => {
        return updateContractAndServiceContract(Entities.contract);
      },
      serviceContract: () => {
        return updateContractAndServiceContract(Entities.serviceContract);
      },
      position: (position: Position) => {
        return { positionId: position?.id || 0 };
      },
    },
    form
  );
};
