import styles from '../EntityPicker.module.scss';

import { Box, Typography } from '@mui/material';

import { Tooltip } from '../../tooltip/Tooltip';
import { WebsiteFavicon } from '../../website-favicon/WebsiteFavicon';

export type SortedListItemProps = {
  name: string;
  number?: string | number;
  website?: string;
  isInactive: boolean;
  sortedBy: 'name' | 'number';
};

export function SortedListItem(props: SortedListItemProps) {
  const { number, name, website, isInactive, sortedBy } = props;

  return (
    <Tooltip title={name} placement="top" showOnlyOnOverflow>
      <Box display="flex" gap="0.5rem" alignItems="center">
        <WebsiteFavicon website={website} />
        {sortedBy === 'name' ? (
          <Box
            display="flex"
            justifyContent="space-between"
            width="calc(100% - 24px)"
            className={isInactive && styles.greyedStrikeThrough}
          >
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              flex={1}
              mr={2}
            >
              {name}
            </Typography>
            {number ? (
              <Typography
                variant="body2"
                flexShrink={0}
                className={styles.secondaryInfo}
              >
                {number}
              </Typography>
            ) : null}
          </Box>
        ) : (
          <Box
            display="flex"
            width="calc(100% - 24px)"
            gap={0.75}
            className={isInactive && styles.greyedStrikeThrough}
          >
            {number ? (
              <Typography
                variant="body2"
                display="flex"
                flexShrink={0}
                className={styles.secondaryInfo}
              >
                {number} |
              </Typography>
            ) : null}
            <Typography
              variant="body2"
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              flex={1}
            >
              {name}
            </Typography>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}
