import styles from './LabeledTimeInput.module.scss';

import { ButtonBase, Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';

import { ReactComponent as TimerIcon } from '@work4all/assets/icons/timer.svg';

import { ILabeledInput, LabeledInput, withDropdown } from '../labeled-input';
import { SectionLabeledInput } from '../section-labeled-input/SectionLabeledInput';
import { withDateTimeMask } from '../section-labeled-input/withDateTimeMask';
import { ITimePickerProps, TimePicker } from '../time-picker/TimePicker';

import { timeModifier } from './time-modifier';

const TimeMaskLabeledInput = withDateTimeMask(SectionLabeledInput);

interface ILabeledTimeInputProps
  extends Omit<ILabeledInput, 'iconRight' | 'value'>,
    ITimePickerProps {
  value: string;
}

const Input: React.FC<ILabeledTimeInputProps> = (props) => {
  // exclude props before passing them.
  // eslint-disable-next-line unused-imports/no-unused-vars
  const {
    timeValue: _timeValue,
    onTimeSelect: _onTimeSelected,
    ...rest
  } = props;

  const isDownMd = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  const commonProps = {
    className: clsx(styles.hideDefaultIcons, {
      [styles.disabled]: rest.disabled,
    }),
    wrapperClassName: rest.className,
    iconRight: (
      <ButtonBase
        disabled={props.disabled}
        onClick={
          props.onClick as unknown as React.MouseEventHandler<HTMLButtonElement>
        }
      >
        <TimerIcon />
      </ButtonBase>
    ),
  };

  if (isDownMd) {
    return (
      <LabeledInput
        {...rest}
        {...commonProps}
        value={
          <Typography style={{ paddingTop: '1rem' }}>{rest.value}</Typography>
        }
      />
    );
  }

  return (
    <TimeMaskLabeledInput
      {...rest}
      {...commonProps}
      mask="00:00"
      splitter=":"
      modify={timeModifier}
    />
  );
};

export const LabeledTimeInputWithDropdown = withDropdown(Input, (props) => {
  const {
    value,
    onTimeSelect: onTimeSelected,
    disabled,
    onDestroyPopover,
  } = props;

  if (disabled) {
    return null;
  }

  return (
    <TimePicker
      timeValue={value}
      onTimeSelect={onTimeSelected}
      onDestroyPopover={onDestroyPopover}
    />
  );
});
