import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import React from 'react';

import { IdArray } from '../edit-table/types';
import { useSelectedPositionState } from '../hooks/use-selected-position';

interface RemovePositionButtonProps {
  onRemovePosition: (positionId: IdArray) => void;
  disabled?: boolean;
}

export const RemovePositionButton = (props: RemovePositionButtonProps) => {
  const { onRemovePosition, disabled = false } = props;

  const { selectedPositionIds } = useSelectedPositionState();
  if (!(selectedPositionIds.length > 0)) return null;

  return (
    <IconButton
      disabled={disabled}
      color="primary"
      onClick={() => onRemovePosition(selectedPositionIds)}
    >
      <DeleteIcon />
    </IconButton>
  );
};
