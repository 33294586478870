import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';

export enum ApprovalStatuses {
  approve = 'approve',
  reject = 'reject',
  forward = 'forward',
}

export type ApprovalEntities = ReViewModel | Vacation;
