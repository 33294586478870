import styles from './ContactsPopover.module.scss';

import {
  ButtonBase,
  Divider,
  Popover,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Fragment, useMemo } from 'react';

import { useCallModalContext } from '@work4all/components/lib/hooks/call-modal/useCallModalContext';

import { useDataProvider } from '@work4all/data';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactTelephonenumbers } from '@work4all/models/lib/Classes/ContactTelephonenumbers.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

export interface ContactsPopoverData {
  businessPartner: BusinessPartner | null;
  project: Project | null;
  projectProcess: ProjectProcess | null;
  creator: User;
  editor1: User | null;
  editor2: User | null;
  highlightedExternalContact: Contact | null;
}

interface ContactsPopoverProps extends ContactsPopoverData {
  popoverConfig: {
    open: boolean;
    anchorEl: Element;
    onClose: () => void;
  };
}

export const ContactsPopover = ({
  popoverConfig,
  businessPartner,
  project,
  projectProcess,
  creator,
  editor1,
  editor2,
  highlightedExternalContact,
}: ContactsPopoverProps) => {
  const internal = useMemo(() => {
    const users = [creator, editor1, editor2].filter((contact) => contact);

    return users
      .filter(
        (contact, index) =>
          users.findIndex((_contact) => contact.id === _contact.id) === index
      )
      .map((user) => {
        return {
          highlight: false,
          item: user,
        };
      });
  }, [creator, editor1, editor2]);

  const external = useMemo(() => {
    if (!businessPartner?.data?.contactList) return null;

    const contacts = businessPartner.data.contactList
      .filter(
        (contact) =>
          !(contact.layedOff || contact.id === highlightedExternalContact?.id)
      )
      .sort((a, b) => {
        return a.displayName.localeCompare(b.displayName);
      });

    return [
      ...(highlightedExternalContact ? [highlightedExternalContact] : []),
      ...contacts,
    ].map((contact) => {
      return {
        highlight: contact.id === highlightedExternalContact?.id,
        item: contact,
      };
    });
  }, [businessPartner?.data?.contactList, highlightedExternalContact]);

  const isSmUp = useMediaQuery<Theme>((t) => t.breakpoints.up('sm'));

  return (
    <Popover
      anchorOrigin={{
        vertical: isSmUp ? 'bottom' : 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: isSmUp ? 'top' : 'bottom',
        horizontal: 'center',
      }}
      {...popoverConfig}
    >
      <div className={styles.box}>
        {external && (
          <ContactsSection
            title="external"
            data={external}
            businessPartner={businessPartner}
            project={project}
            projectProcess={projectProcess}
          />
        )}
        <ContactsSection
          title="internal"
          data={internal}
          businessPartner={businessPartner}
          project={project}
          projectProcess={projectProcess}
        />
      </div>
    </Popover>
  );
};

interface ContactsSectionProps {
  title: string;
  data: { item: User | Contact; highlight: boolean }[];
  businessPartner: BusinessPartner | null;
  project: Project | null;
  projectProcess?: ProjectProcess | null;
}

const ContactsSection = ({
  title,
  data,
  businessPartner,
  project,
  projectProcess,
}: ContactsSectionProps) => {
  return (
    <div className={styles.contactsSectionWrapper}>
      <Divider>{title}</Divider>
      <div className={styles.items}>
        {data.map((item, index) => {
          return (
            <Fragment key={index}>
              <ContactItem
                data={item.item}
                highlight={item.highlight}
                businessPartner={businessPartner}
                project={project}
                projectProcess={projectProcess}
              />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

interface ContactItem {
  data: Contact | User;
  businessPartner: BusinessPartner | null;
  highlight: boolean;
  project: Project | null;
  projectProcess?: ProjectProcess | null;
}

const ContactItem = ({
  data,
  businessPartner,
  highlight,
  project,
  projectProcess,
}: ContactItem) => {
  const { setCallModal } = useCallModalContext();

  const completeTelephonenumbersNumbersRequest = useMemo<DataRequest>(() => {
    const vars = {};

    if (data.__typename === 'Ansprechpartner') {
      vars['sdObjType'] =
        businessPartner.businessPartnerType === SdObjType.KUNDE
          ? SdObjType.KUNDE
          : SdObjType.LIEFERANT;

      vars['sdObjMemberCode'] = businessPartner.id;
      vars['contactCode'] = data.id;
    } else if (data.__typename === 'Benutzer') {
      vars['userCode'] = data.id;
    }

    return {
      entity: Entities.contactTelephonenumbers,
      completeDataResponse: true,
      vars: vars,
      data: {
        central: null,
        directDial: null,
        directFax: null,
        fax: null,
        mobile: null,
        others: null,
        privateMobile: null,
        privateTelephone: null,
      } as ContactTelephonenumbers,
    };
  }, [businessPartner, data]);

  const completeTelephoneNumbersResult =
    useDataProvider<ContactTelephonenumbers>(
      completeTelephonenumbersNumbersRequest
    );

  const completeTelephoneNumbers =
    completeTelephoneNumbersResult.data as unknown as ContactTelephonenumbers;

  return (
    <ButtonBase
      className={styles.item}
      onClick={() => {
        setCallModal({
          data: data,
          telephoneNumbers: completeTelephoneNumbers,
          businessPartner: businessPartner,
          project: project,
          projectProcess: projectProcess,
        });
      }}
    >
      <Typography
        variant="body1"
        style={{ fontWeight: highlight ? '700' : '400' }}
      >
        {data.displayName}
      </Typography>
    </ButtonBase>
  );
};
