import styles from './VacationApprovalPreviewContainer.module.scss';

import {
  CalendarNext,
  CalendarPrev,
  Datepicker,
  formatDate,
} from '@mobiscroll/react';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import PersonIcon from '@mui/icons-material/Person';
import { Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  InfoCard,
  InfoCards,
  PreviewWrapper,
} from '@work4all/components/lib/components/entity-preview/components';
import { ApprovalStatusBanderole } from '@work4all/components/lib/components/entity-preview/components/approval-banderole/ApprovalStatusBanderole';
import { ActionStatuses } from '@work4all/components/lib/components/entity-preview/components/approval-banderole/types';
import {
  PreviewInputsProvider,
  PreviewTitleInput,
} from '@work4all/components/lib/components/entity-preview/components/preview-input';
import { useEntityPreview } from '@work4all/components/lib/components/entity-preview/hooks/use-entity-preview';
import { EntityPreviewProps } from '@work4all/components/lib/components/entity-preview/types';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { usePageTheme } from '@work4all/components/lib/theme';
import { useMobiscrollLanguage } from '@work4all/components/lib/utils/use-mobiscroll-language/use-mobiscroll-language';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { VacationRequestStatus } from '@work4all/models/lib/Enums/VacationRequestStatus.enum';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { useVacationRequestState } from '../../../../containers/vacations/use-vacation-request-state';
import { useVacationStats } from '../../../../containers/vacations/use-vacation-stats';

export type VacationApprovalPreviewProps =
  EntityPreviewProps<VacationRequestEntity>;

export function VacationApprovalPreview(props: VacationApprovalPreviewProps) {
  const { entries, ...handlers } = props;
  const { t } = useTranslation();
  const pageTheme = usePageTheme();
  const language = useMobiscrollLanguage();

  const entityPreview = useEntityPreview({
    subEntityType: Entities.vacationRequestEntity,
    entries,
    openPicker: () => null,
    mutate: null,
  });

  const user = entries[0].user;
  const isApproved = entries[0].status === VacationRequestStatus.DONE;
  const isRejected = entries[0].status === VacationRequestStatus.REJECTED;

  const vacationItem = entries[0].vacationItems[0];
  const approver = vacationItem?.vacationApprover?.displayName;
  const approveDate = DateTime.fromISO(vacationItem?.approveDate);
  const approveDateString = approveDate?.isValid
    ? approveDate?.toLocaleString(DateTimeCustom.DATE_SHORT)
    : null;
  const beginDate = entries[0].dateFrom
    ? entries[0].dateFrom
    : vacationItem?.date;
  const beginDateISO = beginDate ? DateTime.fromISO(beginDate) : null;
  const dateFrom = beginDate
    ? beginDateISO.toLocaleString(DateTimeCustom.DATE_SHORT)
    : '-';
  const endDate = entries[0].dateTo
    ? entries[0].dateTo
    : entries[0].vacationItems[entries[0].vacationItems.length - 1]?.date;
  const endDateISO = endDate ? DateTime.fromISO(endDate) : null;
  const dateTo = endDate
    ? endDateISO.toLocaleString(DateTimeCustom.DATE_SHORT)
    : '-';

  const [currentMonth, setCurrentMonth] = useState(
    beginDateISO?.toJSDate() || new Date()
  );
  const [viewRange, setViewRange] = useState<[Date, Date] | null>(null);

  const today = useMemo(() => DateTime.now().startOf('day').toJSDate(), []);
  const currentYear = useMemo(() => currentMonth.getFullYear(), [currentMonth]);

  const range: [Date, Date] = [
    beginDateISO?.toJSDate(),
    endDateISO?.toJSDate(),
  ];

  const totalDaysRequested = entries[0].vacationItems?.reduce(
    (sum, item) => sum + item.amount,
    0
  );

  const calendarRef = useRef<Datepicker>(null);

  const state = useVacationRequestState({
    mode: 'view',
    range,
    viewRange,
    userId: user?.id,
    options: {
      firstHalf: false,
      secondHalf: false,
    },
    currentYear,
  });
  const { getDayInfo } = state;

  const { stats } = useVacationStats({
    userId: user?.id,
    year: new Date().getFullYear(),
  });

  return (
    <PreviewInputsProvider
      {...entityPreview}
      entity={Entities.salesOpportunities}
      entries={entries}
    >
      <PreviewWrapper>
        <PreviewTitleInput
          accessor="description"
          {...handlers}
          onShareClicked={undefined}
          disabled
          value={t('APPROVAL_CENTER.VACATION.PREVIEW.TITLE', {
            from: dateFrom,
            to: dateTo,
          })}
        />

        <Stack>
          {isApproved && (
            <ApprovalStatusBanderole
              userName={approver}
              date={approveDateString}
              status={ActionStatuses.approved}
            />
          )}
          {isRejected && (
            <ApprovalStatusBanderole status={ActionStatuses.rejected} />
          )}
          <InfoCards>
            <InfoCard label={t('COMMON.EMPLOYEE')} disabled>
              {user?.displayName}
            </InfoCard>

            <InfoCard label={t('COMMON.DEPARTMENT')} disabled>
              {user?.departmentName}
            </InfoCard>

            <InfoCard label={t('COMMON.BEGIN')} disabled>
              {dateFrom}
            </InfoCard>

            <InfoCard label={t('COMMON.END')} disabled>
              {dateTo}
            </InfoCard>

            <InfoCard label={t('VACATION.TOTAL')} disabled>
              {t('VACATION.DAYS', {
                count: stats.total,
              })}
            </InfoCard>

            <InfoCard label={t('VACATION.VACATION_DAYS.USED')} disabled>
              {t('VACATION.DAYS', {
                count: stats.carriedOver,
              })}
            </InfoCard>

            <InfoCard label={t('VACATION.REQUESTED')} disabled>
              {t('VACATION.DAYS', {
                count: totalDaysRequested,
              })}
            </InfoCard>

            <InfoCard label={t('VACATION.VACATION_DAYS.REMAINING')} disabled>
              {t('VACATION.DAYS', {
                count: stats.remaining,
              })}
            </InfoCard>
          </InfoCards>

          <Stack gap={4}>
            <Divider />
            <Stack px={1}>
              <Datepicker
                ref={calendarRef}
                className={clsx(styles.calendar, styles.disabled)}
                renderCalendarHeader={() => (
                  <Stack direction={'row'} alignItems="center" pb={1}>
                    <CalendarPrev />
                    <CalendarNext />
                    <Typography
                      variant="h5"
                      fontWeight="bold"
                      sx={{ marginLeft: 1 }}
                    >
                      {formatDate('MMMM', currentMonth, language)}
                    </Typography>
                  </Stack>
                )}
                disabled
                locale={language}
                controls={['calendar']}
                showRangeLabels={false}
                theme="material"
                themeVariant={pageTheme.value}
                select="range"
                value={range}
                renderDay={(day) => {
                  const { date } = day;
                  const {
                    isSelected,
                    isFirstHalf,
                    isSecondHalf,
                    isExcluded,
                    isHoliday,
                    isApprovedVacation,
                    isRequestedVacation,
                    isOwnVacation,
                    isColleaguesVacation,
                    colleagues,
                  } = getDayInfo(date);

                  const showColleagues = isColleaguesVacation && !isOwnVacation;

                  return (
                    <div
                      className={clsx(styles.day, {
                        [styles.today]: +date === +today,
                        [styles.selected]: isSelected,
                        [styles.excluded]: isExcluded,
                        [styles.firstHalf]: isFirstHalf,
                        [styles.secondHalf]: isSecondHalf,
                        [styles.holiday]: isHoliday,
                        [styles.approved]: isApprovedVacation,
                        [styles.pending]: isRequestedVacation,
                      })}
                      style={{ pointerEvents: 'none', opacity: 1 }}
                    >
                      <div className={styles.monthDay}>{date.getDate()}</div>
                      <div className={styles.weekDay}>
                        {formatDate('DDD', date, language)}

                        {isOwnVacation && (
                          <FlightTakeoffIcon fontSize="inherit" />
                        )}
                        {showColleagues && (
                          <div className={styles.colleagues}>
                            <PersonIcon fontSize="inherit" />
                            <span>{colleagues.length}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }}
                onChange={() => {}}
                onPageChange={({ viewStart, viewEnd, month }) => {
                  setCurrentMonth(month);
                  setViewRange([viewStart, viewEnd]);
                }}
                calendarType="month"
                calendarSize={1}
                display="inline"
                touchUi={true}
              />
            </Stack>
          </Stack>
        </Stack>
      </PreviewWrapper>
    </PreviewInputsProvider>
  );
}
