import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { NotePreview } from '../note-preview';
import { EntityPreviewContainerProps } from '../types';

export const callMemoPreviewFields: CallMemo<EMode.query> = {
  id: null,
  title: null,
  date: null,
  user: {
    id: null,
    displayName: null,
  },
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    eMail: null,
    businessPartnerId: null,
    businessPartnerType: null,
  },
  project: {
    id: null,
    name: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  topicMarkList: [
    {
      id: null,
      name: null,
    },
  ],
  note: null,
};

export type ICallMemoPreviewContainerProps = EntityPreviewContainerProps;

export function CallMemoPreviewContainer(
  props: ICallMemoPreviewContainerProps
) {
  const {
    ids: callMemoIds,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const callMemos = useEntityPreviewData<CallMemo>(
    callMemoIds,
    Entities.callMemo,
    callMemoPreviewFields
  );

  if (!callMemos) {
    return null;
  }

  // Note and CallMemo preview are exactly the same at the moment.
  return (
    <NotePreview
      isCallMemo={true}
      entries={callMemos}
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
