import { useState } from 'react';

import { OrganisationAreaContext } from '../contexts';
import {
  OrganisationAreaProviderProps,
  OrganisationAreaSectionType,
} from '../types';

export const OrganisationAreaProvider = ({
  children,
  entity,
}: OrganisationAreaProviderProps) => {
  const [multiselectableSections, setMultiselectableSections] = useState<
    OrganisationAreaSectionType[]
  >([]);
  const [isEditOverlayOpen, setEditOverlayOpen] = useState<boolean>(false);

  return (
    <OrganisationAreaContext.Provider
      value={{
        entity,
        multiselectableSections,
        setMultiselectableSections,
        isEditOverlayOpen,
        setEditOverlayOpen,
      }}
    >
      {children}
    </OrganisationAreaContext.Provider>
  );
};
