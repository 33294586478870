import { CellProps } from 'react-table';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';

import { canApproveVacations } from '@work4all/utils/lib/permissions';

import { PermitStatusIconCell } from '../../PermitStatusIconCell';
import { mapSelectedRowStatus } from '../utils';

export const VacationStatusCell = (
  cellProps: CellProps<VacationRequestEntity>,
  tableProps
) =>
  PermitStatusIconCell({
    ...cellProps,
    ...tableProps,
    value: mapSelectedRowStatus(cellProps.value),
    canApprove: canApproveVacations,
    hideForward: true,
    view: 'list',
  });
