import { useCallback, useEffect, useRef, useState } from 'react';

import { useTableStateBag } from '@work4all/components';

import { useTableLayoutState } from '../../../../table-layout';
import { BoardIdObserver } from '../BoardColumn';

export const useBoard = () => {
  const selectedIdsByStatus = useRef<Record<string, Set<number>>>({});
  const [prefilter, setPrefilter] = useState<unknown[]>();

  useEffect(() => {
    const subscription = BoardIdObserver.subscribe((event) => {
      if (!event) return;
      const { ids, status } = event;

      // Get current set of IDs for the status
      const currentIdsSet =
        selectedIdsByStatus.current[status] || new Set<number>();

      // Compare new IDs with the current set
      const newIdsSet = new Set(ids);
      const isSame =
        newIdsSet.size === currentIdsSet.size &&
        ids.every((id) => currentIdsSet.has(id));

      if (isSame) {
        return; // Skip updating if the IDs are the same
      }

      // Update the selected IDs for the given status
      selectedIdsByStatus.current[status] = newIdsSet;

      // Generate new prefilter based on all selected IDs across all statuses
      const allSelectedIds = Object.values(selectedIdsByStatus.current).flatMap(
        (set) => Array.from(set)
      );

      const newPrefilter = {
        id: {
          $in: allSelectedIds,
        },
      };
      setPrefilter([newPrefilter]);
    });

    return () => subscription.unsubscribe();
  }, []);

  const onTableChange = useCallback((tableInstance) => {
    const allSelectedIds = Object.values(selectedIdsByStatus.current).flatMap(
      (set) => Array.from(set)
    );
    const items =
      tableInstance.rows.filter((x) =>
        allSelectedIds.includes(x.original.id)
      ) || [];

    items.forEach((item) => {
      if (typeof item.toggleRowSelected === 'function') {
        // toggle after render
        setTimeout(() => item.toggleRowSelected(true), 0);
      }
    });
  }, []);

  const layoutState = useTableLayoutState();
  const [layout] = layoutState;

  const tableBag = useTableStateBag();
  useEffect(() => {
    if (layout === 'board' && tableBag.tableInstance) {
      tableBag.tableInstance.state.groupBy = [];
    }
  }, [layout]);

  const outputPrefilter = layout === 'board' ? prefilter : undefined;
  return { onTableChange, prefilter: outputPrefilter };
};
