import styles from './EmailView.module.scss';

import { LinearProgress } from '@mui/material';
import Divider from '@mui/material/Divider';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FroalaEditor from 'react-froala-wysiwyg';

import { generateFileUploadUrl } from '@work4all/components';
import { Attachments } from '@work4all/components/lib/components/attachments';
import { AttachmentsDropZone } from '@work4all/components/lib/components/attachments/AttachmentsDropZone';
import { Collapse } from '@work4all/components/lib/components/collapse';

import { useUser } from '@work4all/data';
import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { EMail } from '@work4all/models/lib/Classes/EMail.entity';
import { ConvertTempfileTarget } from '@work4all/models/lib/Enums/ConvertTempfileTarget.enum';
import { CreateTempFileOriginType } from '@work4all/models/lib/Enums/CreateTempFileOriginType.enum';
import { EMailKind } from '@work4all/models/lib/Enums/EMailKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ControllerPlus } from '../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../form-plus/use-form-context-plus';
import { TextEditor } from '../../../locked-inputs';
import { useMaskContext } from '../../hooks/mask-context';
import { useEntityRightsContext } from '../../hooks/use-entity-rights';

import { Details } from './components/details/Details';
import { Message } from './components/message/Message';
import { useEmailTemplaterContext } from './email-templater/email-templater-context';
import { EmailMaskFormValue } from './types';

export const EmailView: React.FC<{
  setIsFroalaUploadingImage: Dispatch<SetStateAction<boolean>>;
}> = ({ setIsFroalaUploadingImage }) => {
  const mask = useMaskContext<EMail>();
  const rights = useEntityRightsContext();

  const { data, isCreateMode } = mask;

  const { control, watch } = useFormContextPlus<EmailMaskFormValue>();
  const { activeTenant } = useTenant();

  const { fileList: attachmentList, uploadFiles } = useContext(
    TempFileManagerContext
  );

  const { processing } = useEmailTemplaterContext();

  const [editor, setEditor] = useState<FroalaEditor>();
  const draftMode: boolean = useMemo(() => {
    return (
      !data.id ||
      data.kind === EMailKind.ENTWURF ||
      data.kind === EMailKind.ENTWURF_HTML
    );
  }, [data]);

  useEffect(() => {
    if (editor) {
      const enabledEditing =
        (isCreateMode ? rights.create : rights.update) && draftMode;

      //ToDo: refactor this to be in TextEditor Component eg via "readOnly"-Attribute
      if (enabledEditing) {
        editor?.getEditor()?.edit?.on();
        editor?.getEditor()?.toolbar?.show();
      } else {
        editor?.getEditor()?.edit?.off();
        editor?.getEditor()?.toolbar?.hide();
      }
    }
  }, [
    data.kind,
    draftMode,
    editor,
    isCreateMode,
    rights.create,
    rights.update,
  ]);

  const user = useUser();
  const editorRef = useRef(null);

  return (
    <div className={styles.root}>
      <AttachmentsDropZone fullHeight={false}>
        <div>
          <div className={styles.mainDiv}>
            <div className={styles.message}>
              <Message />
            </div>

            <Divider className={styles.divider} orientation="vertical" />

            <div className={styles.details}>
              <Details />
            </div>
          </div>
        </div>
      </AttachmentsDropZone>
      <div className={styles.editor} ref={editorRef}>
        {attachmentList.length > 0 ? (
          <div className={styles.sectionPadding}>
            <Attachments
              entity={Entities.eMail}
              disableEditAction
              disableAddAction={!draftMode}
              disableRemoveAction={!draftMode}
              layout="compact"
              fullHeight={false}
              convertFileAdditionalInfo={{
                projectId: watch('projectId'),
                businessPartnerId: watch('businessPartnerId'),
                businessPartnerType: watch('businessPartnerType'),
                contactId: watch('contactId'),
              }}
              convertTempfileTarget={ConvertTempfileTarget.DOKUMENT}
              createTempFileOriginType={
                CreateTempFileOriginType.E_MAIL_ATTACHEMENT
              }
            />
          </div>
        ) : null}
        <div>
          {draftMode !== undefined && (
            <Collapse defaultOpen={true}>
              <div style={{ position: 'relative' }}>
                <LinearProgress
                  style={{
                    display: !mask.isLoading && processing ? 'unset' : 'none',
                    position: 'absolute',
                    right: '0',
                    left: '0',
                    zIndex: '999',
                    top: '-3px',
                  }}
                />
              </div>
              <ControllerPlus
                control={control}
                name="bodyHtml"
                render={({ field }) => (
                  <TextEditor
                    disabled={mask.isLoading || processing}
                    value={field.value}
                    onChange={field.onChange}
                    onInitialized={setEditor}
                    enableTextBuildingBlocks={true}
                    applyZoomLevel={false}
                    config={{
                      heightMin: 200,
                      requestHeaders: {
                        'x-work4all-mandant': activeTenant,
                      },
                      imageUploadURL: generateFileUploadUrl(
                        user.baseUrl,
                        user.token
                      ),
                      events: {
                        drop: function (dropEvent) {
                          dropEvent.preventDefault();
                          uploadFiles([
                            ...dropEvent.originalEvent.dataTransfer.files,
                          ]);
                        },
                        'image.beforeUpload': () => {
                          setIsFroalaUploadingImage(true);
                        },
                        'image.loaded': (img) => {
                          /*
                           * For unknown reasons `image.loaded` gets fired twice
                           * when pasting an image.
                           *
                           * For that reason we should check the `data-events`
                           * if includes an `error` event, to prevent
                           * changing the state.
                           */

                          const events = { ...img?.[0]?.['data-events'] };
                          const errorEvent = Object.keys(events).find(
                            (eventKey) => events[eventKey][0] === 'error'
                          );
                          const isError = Boolean(errorEvent);

                          if (isError) {
                            return;
                          }

                          setIsFroalaUploadingImage(false);
                        },
                      },
                    }}
                  />
                )}
              />
              <Divider orientation="horizontal" />
            </Collapse>
          )}
        </div>
      </div>
    </div>
  );
};
