import { Delete } from '@mui/icons-material';
import { Box, List, ListItemButton, ListItemText, Stack } from '@mui/material';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { NavigationOverlayHeader } from '../../../../../components/navigation-overlay-header/NavigationOverlayHeader';
import { IconButton } from '../../../../mask-overlays/locked-inputs';
import { HomeWidget, HomeWidgetsContext } from '../HomeWidgetsContextProvider';
import { HomeWidgetType } from '../HomeWidgetType.enum';

type HomeWidgetSettingsProps = {
  amplitudeEntryPoint: string;
  widgetId: string;
};

export function HomeWidgetSettings(props: HomeWidgetSettingsProps) {
  const { amplitudeEntryPoint, widgetId } = props;

  const { t } = useTranslation();

  useEffect(() => {
    sendAmplitudeData(EventType.OpenHomeWidgetSettings, {
      entryPoint: amplitudeEntryPoint,
    });
  }, [amplitudeEntryPoint]);

  const { widgets, modifyConf } = useContext(HomeWidgetsContext);

  const widget = widgets.find((x) => x.id === widgetId);

  const onChange = useCallback(
    (w: HomeWidget) => {
      const newWidgets = widgets.map((obj) => (w.id === obj.id ? w : obj));

      modifyConf({
        widgets: {
          set: newWidgets,
        },
      });
    },
    [modifyConf, widgets]
  );

  const { go } = useHistoryStack();

  if (!widget) return null;

  const title = widget.title || resolveFallbackTitle();

  function resolveFallbackTitle() {
    const entityName = widget.definition.entity.toUpperCase();
    return t([`COMMON.${entityName}S`, `COMMON.${entityName}_plural`], '');
  }

  return (
    <>
      <NavigationOverlayHeader
        title={t('SETTINGS.SETTINGS')}
        subTitle={t('SETTINGS.WIDGET')}
        breadcrumbsChildren={<div></div>}
      />
      <Box sx={{ padding: '1rem' }}>
        <Stack>
          <LabeledInput
            label={t('COMMON.TITLE')}
            defaultValue={title}
            onChange={(e) => onChange({ ...widget, title: e.target.value })}
          />
        </Stack>
      </Box>
      {widget.type !== HomeWidgetType.widgetGroup ? null : (
        <>
          <Divider
            title="Sub-Widgets"
            style={{ padding: '0.5rem 0', width: '100%' }}
          />

          <List>
            {widget.subWidgetIds.map((id) => {
              const subWidget = widgets.find((x) => x.id === id);
              if (!subWidget) {
                return null;
              }
              return (
                <Stack
                  direction="row"
                  gap={'1rem'}
                  alignItems="center"
                  style={{ width: '100%', paddingRight: '1rem' }}
                >
                  <ListItemButton
                    onClick={() => {
                      go({
                        view: <HomeWidgetSettings {...props} widgetId={id} />,
                      });
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      {subWidget.type === HomeWidgetType.entityWidget ? (
                        <ListItemText>{subWidget.title}</ListItemText>
                      ) : null}

                      {subWidget.type === HomeWidgetType.divider ? (
                        <Divider
                          title={subWidget.title}
                          style={{ width: '100%', padding: 0, height: '2rem' }}
                        />
                      ) : null}
                    </div>
                  </ListItemButton>
                  <IconButton
                    onClick={() =>
                      modifyConf({ widgets: { remove: [subWidget.id] } })
                    }
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            })}
          </List>
        </>
      )}
    </>
  );
}
