import styles from './PicFromFileOrCam.module.scss';

import { ButtonBase, Paper, Theme, useMediaQuery } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/outline-close-24-2.svg';

import { Modal } from '../../../../components/modal/Modal';
import {
  AlertState,
  InstructionsAlert,
} from '../../../../dataDisplay/instructions-alert/InstructionsAlert';
import { IUploadImageProps } from '../../types';
import { makeFile, readFile } from '../../utils/readFile';
import { Camera } from '../camera/Camera';

interface IPictureFromFileOrCam
  extends Pick<IUploadImageProps, 'onPictureTaken' | 'onImageChoosen'> {
  multiple?: boolean;
  asDataUri?: boolean;
  children: (props: {
    takePicture: () => void;
    chooseFile: React.MouseEventHandler<HTMLElement>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chooseFileProps: { [x: string]: any };
  }) => React.ReactNode;
}

const allowedFileExtensionsObj = {
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/png': ['.png'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'application/pdf': ['.pdf'],
};
const allowedFileExtensions = Object.values(allowedFileExtensionsObj).flat(1);

export const PictureFromFileOrCam: React.FC<IPictureFromFileOrCam> = (
  props
) => {
  const {
    onPictureTaken,
    onImageChoosen,
    multiple = false,
    asDataUri = true,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [startCamera, setStartCamera] = useState(false);
  const [instructionState, setInstructionState] = useState<AlertState>('NONE');

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple,
    accept: allowedFileExtensionsObj,
    onDropRejected: () =>
      enqueueSnackbar(
        t('FILE.TYPE.ERROR', {
          types: allowedFileExtensions.map((type) => `'${type}'`).join(', '),
        }),
        {
          variant: 'error',
          autoHideDuration: 10000,
        }
      ),
  });

  const onChoosenImageRef = useRef(onImageChoosen);
  useEffect(() => {
    if (!acceptedFiles.length || !onChoosenImageRef.current) {
      return;
    }

    const subscription = of(acceptedFiles[0])
      .pipe(
        mergeMap(async (file) => {
          return file;
        })
      )
      .subscribe(async (file) => {
        let f: File | string = file;
        if (asDataUri) {
          f = await readFile(file);
        }
        onChoosenImageRef.current?.(f, file.size, file.type, file.name);
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [acceptedFiles, asDataUri]);

  const takePicture = () => {
    //ToDo: this should be supported by all browsers but is not part of vscodes lib.dom types
    const cameraPermissionName = 'camera' as PermissionName;
    navigator.permissions
      .query({
        name: cameraPermissionName,
      })
      .then((res) => {
        if (res.state === 'granted') {
          setStartCamera(true);
          return;
        }
        if (res.state === 'denied') {
          setInstructionState('DENIED');
          return;
        }
        setInstructionState('PROMPT');
      });
  };

  const closeCamera = () => {
    setStartCamera(false);
  };

  const onUserAgreedToFollowInstruction = () => {
    setInstructionState('NONE');
    setStartCamera(true);
  };

  const onUserWontFollowInstruction = () => {
    setInstructionState('NONE');
  };

  const { onClick: chooseFile, ...rootProps } = getRootProps();

  const isFullScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );

  return (
    <>
      <Modal
        open={startCamera}
        isFullScreen={isFullScreen}
        onClose={closeCamera}
        keepMounted={false}
      >
        <Paper className={styles.cameraRoot}>
          <Camera
            idealFacingMode="environment"
            isImageMirror={true}
            onTakePhoto={(dataUri) => {
              onPictureTaken?.(asDataUri ? dataUri : makeFile(dataUri));
              setStartCamera(false);
            }}
          />
          <ButtonBase
            className={styles.cameraCloseButton}
            onClick={closeCamera}
          >
            <CloseIcon />
          </ButtonBase>
        </Paper>
      </Modal>
      <InstructionsAlert
        type="camera"
        state={instructionState}
        open={instructionState !== 'NONE'}
        onClose={onUserWontFollowInstruction}
        cancel={onUserWontFollowInstruction}
        accept={onUserAgreedToFollowInstruction}
      />
      <input name="docUpload" {...getInputProps()} />
      {props.children({
        chooseFile,
        takePicture,
        chooseFileProps: rootProps,
      })}
    </>
  );
};
