import { useEffect, useMemo } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useTableStateBag } from '../../dataDisplay/basic-table';
import { IColumnVisibilityContext } from '../../dataDisplay/basic-table/hooks/useColumnVisibility';

import { useLockObject } from './LockObjectProvider';

export const useLockObjectColumn = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inData: any[],
  columnsVisibilityManager: IColumnVisibilityContext,
  entityType: Entities,
  skip = false
) => {
  const { getLock } = useLockObject();

  const enrichedData = useMemo(() => {
    let isEnriched = false;
    let data = [];
    if (!skip && entityType) {
      data = inData.map((item) => {
        const lock = getLock(item.id, entityType);
        if (lock && lock.lockedBy) {
          isEnriched = true;
          return { ...item, objectLock: lock.lockedBy.id };
        }
        return item;
      });
    } else {
      data = inData;
    }
    return { data, isEnriched };
  }, [inData, getLock, skip, entityType]);

  const tableStateBag = useTableStateBag();
  const column = tableStateBag.columnsById['objectLock'];
  useEffect(() => {
    if (!column) {
      return;
    }
    const hideEmpty = !enrichedData.isEnriched && column.isVisible;
    const showAny = enrichedData.isEnriched && !column.isVisible;

    if (hideEmpty || showAny) {
      columnsVisibilityManager.toggleColumnVisibility('objectLock');
    }
  }, [enrichedData.isEnriched, columnsVisibilityManager, column]);

  return enrichedData;
};
