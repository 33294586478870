import { CheckCircleOutline, Close, Edit, Send } from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { ActionStatuses } from '@work4all/components/lib/components/entity-preview/components/approval-banderole/types';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';

import { ApprovalStatuses } from './types';

interface ApprovalActionsProps {
  seletedRowStatus?: ActionStatuses;
  canApprove: boolean;
  canForward: boolean;
  hideForward?: boolean;
  editHandler?: () => void;
  statusHandler: (id: string, state: ApprovalStatuses) => void;
}

export const MOBILE_ORDER = [
  'edit',
  ApprovalStatuses.reject,
  ApprovalStatuses.forward,
  ApprovalStatuses.approve,
];
export const DESKTOP_ORDER = [
  ApprovalStatuses.approve,
  ApprovalStatuses.reject,
  ApprovalStatuses.forward,
  'edit',
];

export const useApprovalActions = (props: ApprovalActionsProps) => {
  const {
    seletedRowStatus,
    canApprove,
    canForward,
    hideForward,
    editHandler,
    statusHandler,
  } = props;

  const { t } = useTranslation();
  const { tableState } = useTableStateBag();

  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const sortArray = isMobile ? MOBILE_ORDER : DESKTOP_ORDER;

  const id = Object.keys(tableState?.selectedRowIds ?? {})?.[0];

  const openStatuses = [ActionStatuses.open, ActionStatuses.openAndPaid];
  const isOpen = openStatuses.includes(seletedRowStatus);

  const approvalActionDefinitions: CustomToolbarAction[] =
    useMemo((): CustomToolbarAction[] => {
      if (isOpen) {
        return [
          ...(editHandler
            ? [
                {
                  actionKey: 'edit',
                  IconComponent: Edit,
                  tooltip: t('COMMON.EDIT'),
                  disabled: false,
                  handler: () => {
                    editHandler();
                  },
                  mode: 'Edit',
                },
              ]
            : []),
          {
            actionKey: ApprovalStatuses.reject,
            IconComponent: Close,
            tooltip: t('APPROVAL_CENTER.STATUS_REJECT'),
            disabled: !canApprove,
            handler: () => {
              statusHandler(id, ApprovalStatuses.reject);
            },
            mode: 'Edit',
          },
          ...(!hideForward
            ? [
                {
                  actionKey: ApprovalStatuses.forward,
                  IconComponent: Send,
                  tooltip: t('APPROVAL_CENTER.STATUS_FORWARD'),
                  disabled: !canForward,
                  handler: () => {
                    statusHandler(id, ApprovalStatuses.forward);
                  },
                  mode: 'Edit',
                },
              ]
            : []),
          {
            actionKey: ApprovalStatuses.approve,
            IconComponent: CheckCircleOutline,
            color: 'primary',
            tooltip: t('APPROVAL_CENTER.STATUS_APPROVE'),
            disabled: !canApprove,
            handler: () => {
              statusHandler(id, ApprovalStatuses.approve);
            },
            mode: 'Edit',
          },
        ].sort(
          (a, b) =>
            sortArray.indexOf(a.actionKey) - sortArray.indexOf(b.actionKey)
        ) as CustomToolbarAction[];
      }
      return editHandler
        ? [
            {
              actionKey: 'Edit',
              IconComponent: Edit,
              tooltip: t('COMMON.EDIT'),
              disabled: false,
              handler: () => {
                editHandler();
              },
              mode: 'Edit',
            },
          ]
        : [];
    }, [
      isOpen,
      editHandler,
      t,
      canApprove,
      hideForward,
      canForward,
      statusHandler,
      id,
      sortArray,
    ]);

  return { approvalActionDefinitions };
};
