import styles from './FileListGalleryItem.module.scss';

import { Tooltip, Typography } from '@mui/material';
import { useContext } from 'react';
import sanitize from 'sanitize-html';

import { AttachmentRenameContext } from './attachment-rename-context';
import { FileListGalleryItemThumbnail } from './FileListGalleryItemThumbnail';
import { FileAndThumbnail } from './types';

export interface FileListGalleryItemProps {
  file: FileAndThumbnail;
  onClick: () => void;
}

export function FileListGalleryItem(props: FileListGalleryItemProps) {
  const { file, onClick } = props;

  const renameContext = useContext(AttachmentRenameContext);
  const description = renameContext.getNote(file.id);
  const htmlDescription = sanitize(description, { allowedAttributes: false });

  return (
    <div className={styles.root}>
      <div className={styles.thumbnail} tabIndex={0} onClick={onClick}>
        <FileListGalleryItemThumbnail file={file} />
      </div>

      <Tooltip
        title={
          <div dangerouslySetInnerHTML={{ __html: htmlDescription }}></div>
        }
      >
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{ __html: htmlDescription }}
          className={styles.itemText}
        />
      </Tooltip>
    </div>
  );
}
