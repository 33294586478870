import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { usePickerSettings } from '../../../../hooks/use-picker-settings';
import {
  IFilterPickerConfig,
  useFilterPickerConfig,
} from '../../../list-entity-picker/use-filter-picker-config';

export interface EntityPickerFilterResultProps<Value> {
  entity: Entities;

  data: Value;

  /**
   * You can configure this picker to be used as a "smart filter" by providing
   * parent entity type, the name of the property of the parent entity that this
   * picker is used for and the value of the current filter.
   *
   * When this config is provided, the picker will only display items that are
   * not filtered out by the existing filter and will also display the number of
   * entities that will be matched if a given list item is selected.
   */
  filterConfig?: IFilterPickerConfig;

  /**
   * Field name that will be used to specify sort for GraphQL query.
   */
  sortBy: string;

  /**
   * Field name that will be used to specify sort direction for GraphQL query.
   */
  sortByDirection?: SortDirection;

  /** Field name that will be used to create a filter for GraphQL query. */
  filterBy: string | string[];

  query: string;
}

export type FilterResult = ReturnType<typeof useFilterPickerConfig>;

export function useEntityPickerFilterResult<Value>(
  props: EntityPickerFilterResultProps<Value>
): FilterResult {
  const {
    entity,
    data,
    filterConfig = null,
    sortBy,
    sortByDirection,
    filterBy,
    query,
  } = props;

  const { filteredEntitiesSetting, sortedPickerEntities } =
    usePickerSettings(entity);

  const result = useFilterPickerConfig(
    filterConfig
      ? {
          ...filterConfig,
          data,
          sortBy: sortedPickerEntities ?? sortBy,
          sortByDirection,
          filterBy,
          query,
          settingsFilters: filteredEntitiesSetting,
        }
      : null
  );

  return result;
}
