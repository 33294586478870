import styles from './StatusesColor.module.scss';

import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { IRow } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { RaAccountingStatus } from '@work4all/models/lib/Enums/RaAccountingStatus.enum';

type Props = CellProps<IRow, RaAccountingStatus>;

type color = 'success' | 'alert' | 'error' | 'default';
const StatusesColor: Record<RaAccountingStatus, color> = {
  [RaAccountingStatus.ACCOUNTED]: 'success',
  [RaAccountingStatus.PART_ACCOUNTED]: 'alert',
  [RaAccountingStatus.NOT_ACCOUNTED]: 'error',
  [RaAccountingStatus.ACCRUALS]: 'default',
};

export const RaAccountingStatusCell = ({ value }: Props) => {
  const { t } = useTranslation();
  const color = StatusesColor[value];

  return (
    <div className={styles[color]}>{t(`RA.ACCOUNTING_STATUSES.${value}`)}</div>
  );
};
