import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import { useBoard } from '../table/components/kanban-board/hooks/use-board';
import { useEditTaskStatus } from '../TaskStatusCell';

const statuses = [
  TaskStatus.OFFEN,
  TaskStatus.IN_BEARBEITUNG,
  TaskStatus.ERLEDIGT,
];

export const useTaskBoard = () => {
  const board = useBoard();
  const { t } = useTranslation();
  const columns = useMemo(() => {
    return statuses.map((status) => ({
      key: status,
      status: t(`TASK_STATUS.${status}`),
      prefilter: [
        {
          status: { $eq: status },
        },
      ],
    }));
  }, [t]);

  const onDrop = useEditTaskStatus();
  return { onDrop, columns, ...board };
};
