import styles from './CellTooltip.module.scss';

import { Tooltip, Typography } from '@mui/material';

import { HookedUserIcon } from '@work4all/components/lib/components/user-icon/useUserIconRegister';

import { User } from '@work4all/models/lib/Classes/User.entity';

interface Props {
  colleagues: User[];
  children: React.ReactElement;
  hideTooltip?: boolean;
}

export const CellTooltip = ({ colleagues, children, hideTooltip }: Props) => {
  if (hideTooltip) {
    return children;
  }

  const renderColleagues = () => {
    return (
      <div
        className={styles.colleaguesTooltipList}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {colleagues.map((user) => {
          return (
            <div key={user.id} className={styles.colleaguesTooltipItem}>
              <HookedUserIcon userId={user.id} />
              <Typography variant="body2">{user.displayName}</Typography>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Tooltip title={renderColleagues()} describeChild placement="top">
      {children}
    </Tooltip>
  );
};
