import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { BzObjType } from '../Enums/BzObjType.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';
import { TicketStatus } from '../Enums/TicketStatus.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { Contract } from './Contract.entity';
import { CustomField } from './CustomField.entity';
import { FavoriteItem } from './FavoriteItem.entity';
import { LookUp } from './LookUp.entity';
import { Position } from './Position.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { ServiceContract } from './ServiceContract.entity';
import { ServiceContractPosition } from './ServiceContractPosition.entity';
import { Sprint } from './Sprint.entity';
import { TicketAttachment } from './TicketAttachment.entity';
import { TicketCategory1 } from './TicketCategory1.entity';
import { TicketChecklisteMark } from './TicketChecklisteMark.entity';
import { TicketKind } from './TicketKind.entity';
import { User } from './User.entity';

export class Ticket<T extends EMode = EMode.entity> {
  /** Alias: abschlussdatum */
  completionDate?: string;
  /** Alias: abschlussdatum2 */
  completionDate2?: string;
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: ansprechpartnerCode */
  contactId?: number;
  /** Alias: attachements */
  attachmentList?: TicketAttachment<T>[];
  /** Alias: bZObjMemberCode */
  bZObjMemberId?: number;
  /** Alias: bZObjType */
  bZObjType?: BzObjType;
  /** Alias: bearbeiter1 */
  editor1?: User<T>;
  /** Alias: bearbeiter1Code */
  editor1Id?: number;
  /** Alias: bearbeiter2 */
  editor2?: User<T>;
  /** Alias: bearbeiter2Code */
  editor2Id?: number;
  /** Alias: benutzerCode */
  userId?: number;
  /** Alias: companyName */
  companyName?: string;
  /** Alias: contactPerson */
  contactPerson?: string;
  /** Alias: creationDate */
  creationDate?: string;
  /** Alias: creationDateUTC */
  creationDateUTC?: string;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: ersteller */
  creator?: User<T>;
  /** Alias: eskalationsStufeCode */
  escalationLevelId?: number;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: iD */
  id?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kategorie1Code */
  category1Id?: number;
  /** Alias: kategorie2Code */
  category2Id?: number;
  /** Alias: lastModificationDate */
  lastModificationDate?: string;
  /** Alias: loesungsText */
  solutionText?: string;
  /** Alias: nummer */
  number?: number;
  /** Alias: positionCode */
  positionId?: number;
  /** Alias: positionCodeWartung */
  positionIdService?: number;
  /** Alias: prioritaet */
  priority?: number;
  /** Alias: problembeschreibung */
  problemDescription?: string;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: sDObjMemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: status1 */
  status1?: TicketStatus;
  /** Alias: status2 */
  status2?: TicketStatus;
  /** Alias: ticketArt */
  ticketKind?: TicketKind<T>;
  /** Alias: ticketArtCode */
  ticketKindId?: number;
  /** Alias: ticketKategorie1 */
  ticketCategory1?: TicketCategory1<T>;
  /** Alias: ticketKategorie2 */
  ticketCategory2?: LookUp<T>;
  /** Alias: titel */
  title?: string;
  /** Alias: type */
  type?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: wartungsobjektCode */
  serviceObjectId?: number;
  /** Alias: wiedervorlage */
  followUpDate?: string;
  /** Alias: projektVorgangCode */
  projectProcessId?: number;
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: auftrag */
  contract?: Contract<T>;
  /** Alias: position */
  position?: Position<T>;
  /** Alias: wartungsvertrag */
  serviceContract?: ServiceContract<T>;
  /** Alias: wartungsvertragPosition */
  serviceContractPosition?: ServiceContractPosition<T>;
  /** Alias: zeitbedarf */
  timeRequired?: number;
  /** Alias: checklist */
  checklist?: TicketChecklisteMark<T>[];
  /** Alias: ertrag */
  profit?: number;
  /** Alias: zeitverbrauch */
  timeUsage?: number;
  /** Alias: sprintId */
  sprintId?: string;
  /** Alias: sprint */
  sprint?: Sprint<T>;
  /** Alias: timeTrackingTotal */
  timeTrackingTotal?: number;
  /** Alias: favoriteItem */
  favoriteItem?: FavoriteItem<T>;

  __typename?: string;
}

const fields: FieldDefinitions<Ticket> = {
  completionDate: {
    alias: 'abschlussdatum',
  },
  completionDate2: {
    alias: 'abschlussdatum2',
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  contactId: {
    alias: 'ansprechpartnerCode',
  },
  attachmentList: {
    alias: 'attachements',
    entity: Entities.ticketAttachment,
  },
  bZObjMemberId: {
    alias: 'bZObjMemberCode',
  },
  bZObjType: {
    alias: 'bZObjType',
  },
  editor1: {
    alias: 'bearbeiter1',
    entity: Entities.user,
  },
  editor1Id: {
    alias: 'bearbeiter1Code',
  },
  editor2: {
    alias: 'bearbeiter2',
    entity: Entities.user,
  },
  editor2Id: {
    alias: 'bearbeiter2Code',
  },
  userId: {
    alias: 'benutzerCode',
  },
  companyName: {
    alias: 'companyName',
  },
  contactPerson: {
    alias: 'contactPerson',
  },
  creationDate: {
    alias: 'creationDate',
  },
  creationDateUTC: {
    alias: 'creationDateUTC',
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  creator: {
    alias: 'ersteller',
    entity: Entities.user,
  },
  escalationLevelId: {
    alias: 'eskalationsStufeCode',
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  id: {
    alias: 'iD',
  },
  insertTime: {
    alias: 'insertTime',
  },
  category1Id: {
    alias: 'kategorie1Code',
  },
  category2Id: {
    alias: 'kategorie2Code',
  },
  lastModificationDate: {
    alias: 'lastModificationDate',
  },
  solutionText: {
    alias: 'loesungsText',
  },
  number: {
    alias: 'nummer',
  },
  positionId: {
    alias: 'positionCode',
  },
  positionIdService: {
    alias: 'positionCodeWartung',
  },
  priority: {
    alias: 'prioritaet',
  },
  problemDescription: {
    alias: 'problembeschreibung',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  projectId: {
    alias: 'projektCode',
  },
  businessPartnerId: {
    alias: 'sDObjMemberCode',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  status1: {
    alias: 'status1',
  },
  status2: {
    alias: 'status2',
  },
  ticketKind: {
    alias: 'ticketArt',
    entity: Entities.ticketKind,
  },
  ticketKindId: {
    alias: 'ticketArtCode',
  },
  ticketCategory1: {
    alias: 'ticketKategorie1',
    entity: Entities.ticketCategory1,
  },
  ticketCategory2: {
    alias: 'ticketKategorie2',
    entity: Entities.lookUp,
  },
  title: {
    alias: 'titel',
  },
  type: {
    alias: 'type',
  },
  updateTime: {
    alias: 'updateTime',
  },
  serviceObjectId: {
    alias: 'wartungsobjektCode',
  },
  followUpDate: {
    alias: 'wiedervorlage',
  },
  projectProcessId: {
    alias: 'projektVorgangCode',
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  contract: {
    alias: 'auftrag',
    entity: Entities.contract,
  },
  position: {
    alias: 'position',
    entity: Entities.position,
  },
  serviceContract: {
    alias: 'wartungsvertrag',
    entity: Entities.serviceContract,
  },
  serviceContractPosition: {
    alias: 'wartungsvertragPosition',
    entity: Entities.serviceContractPosition,
  },
  timeRequired: {
    alias: 'zeitbedarf',
  },
  checklist: {
    alias: 'checklist',
    entity: Entities.ticketChecklisteMark,
  },
  profit: {
    alias: 'ertrag',
  },
  timeUsage: {
    alias: 'zeitverbrauch',
  },
  sprintId: {
    alias: 'sprintId',
  },
  sprint: {
    alias: 'sprint',
    entity: Entities.sprint,
  },
  timeTrackingTotal: {
    alias: 'timeTrackingTotal',
  },
  favoriteItem: {
    alias: 'favoriteItem',
    entity: Entities.favoriteItem,
  },
  __typename: {
    alias: '__typename',
  },
};

export const ticketEntityDefinition: EntitiyDefinition<Ticket> = {
  local: {},
  remote: {
    queryName: 'getTickets',
    fragmentName: 'Ticket',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertTicket',
        args: [
          { name: 'input', type: 'InputTicket!' },
          { name: 'relations', type: 'InputTicketRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
      { name: 'addFavSort', type: 'Boolean' },
      { name: 'onlyFavItems', type: 'Boolean' },
    ],
  },
  fieldDefinitions: fields,
};
