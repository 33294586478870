import styles from './StatusesColor.module.scss';

import { useTranslation } from 'react-i18next';
import { CellProps } from 'react-table';

import { IRow } from '@work4all/components/lib/dataDisplay/basic-table/types';

import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';

type Props = CellProps<IRow, PaymentStatus>;

type color = 'success' | 'alert' | 'error' | 'default';
const StatusesColor: Record<PaymentStatus, color> = {
  [PaymentStatus.ZERO_INVOICE]: 'default',
  [PaymentStatus.OPEN_CREDIT]: 'alert',
  [PaymentStatus.PAYED_CREDIT]: 'success',
  [PaymentStatus.PARTIAL_PAYED_CREDIT]: 'alert',
  [PaymentStatus.PAYED]: 'success',
  [PaymentStatus.PARTIAL_PAYED]: 'alert',
  [PaymentStatus.OPEN]: 'alert',
  [PaymentStatus.DUE]: 'error',
};

export const PaymentStatusCell = ({ value }: Props) => {
  const { t } = useTranslation();
  const color = StatusesColor[value];

  return <div className={styles[color]}>{t(`PAYMENT_STATUSES.${value}`)}</div>;
};
