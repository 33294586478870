import styles from './SearchBar.module.scss';

import SearchIcon from '@mui/icons-material/Search';
import { InputBase, Stack, Tooltip } from '@mui/material';
import Switch from '@mui/material/Switch';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';

import { useAutoFocus } from '@work4all/data/lib/hooks/useAutoFocus';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';
import { replaceClassName } from '@work4all/utils/lib/replaceClassName';

interface IClasses {
  root?: string;
  input?: string;
  icon?: string;
  iconWrapper?: string;
}

export interface ISearchBarProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  inputValue: string | number;
  onInputClick?: React.HTMLAttributes<HTMLElement>['onClick'];
  onSearchBarClick?: React.HTMLAttributes<HTMLFormElement>['onClick'];
  disabled?: boolean;
  className?: string;
  classes?: IClasses;
  placeholder?: string;
  onSearchSubmit?: (value?: string | number) => void;
  autofocus?: boolean;
  selectAll?: boolean;
  backButton?: JSX.Element;
  googleSearchActive?: boolean;
  showGoogleSearch?: boolean;
  onGoogleSearchToggle?: (googleSearch: boolean) => void;
}

export const SearchBar = React.forwardRef<HTMLInputElement, ISearchBarProps>(
  function SearchBar(props, ref) {
    const {
      onSearchBarClick,
      inputValue,
      className,
      onChange,
      onKeyUp,
      onFocus,
      onBlur,
      onSearchSubmit,
      backButton,
      onGoogleSearchToggle,
      googleSearchActive,
      showGoogleSearch,
    } = props;

    const inputRef = useAutoFocus(props.autofocus, props.selectAll);
    const { t } = useTranslation();

    /**
     * keyCode === 13 (ENTER) is not recognized by all mobile devices
     * We encountered this problem several times. To make it work everywhere
     * we decided to use form: on submit will be fired when user hits enter
     */

    const [formId] = useState(() => {
      return nanoid();
    });

    return (
      <>
        {/**
         * Why to use portal?
         * https://stackoverflow.com/questions/3430214/form-inside-a-form-is-that-alright
         *
         **/}
        {ReactDOM.createPortal(
          <form
            id={formId}
            onClick={onSearchBarClick}
            onSubmit={(e) => {
              e.preventDefault();
              // make sure that onSubmit of parent form element is not fired.
              e.stopPropagation();

              if (onSearchSubmit) {
                onSearchSubmit(inputValue);
              }
            }}
          ></form>,
          document.getElementById('root')
        )}
        <div className={clsx(styles['searchbar'], className)}>
          {backButton ? (
            <div
              className={clsx(
                styles['searchbar__search-icon-wrapper'],
                props.classes?.iconWrapper
              )}
            >
              {backButton}
            </div>
          ) : (
            <div
              className={clsx(
                styles['searchbar__search-icon-wrapper'],
                props.classes?.iconWrapper
              )}
            >
              <SearchIcon
                className={replaceClassName(
                  styles['searchbar__search-icon'],
                  props.classes?.icon
                )}
              />
            </div>
          )}
          <InputBase
            inputRef={reactRefSetter(inputRef, ref)}
            value={inputValue}
            placeholder={props.placeholder}
            classes={{
              root: replaceClassName(
                styles['searchbar__search-root'],
                props.classes?.root
              ),
              input: replaceClassName(
                styles['searchbar__search-input'],
                props.classes?.input
              ),
            }}
            inputProps={{
              form: formId,
              'aria-label': 'search',
              size: 1,
              inputMode: 'search',
              disabled: props.disabled,
              className: styles['searchbar__input-el'],
              enterKeyHint: 'search',
              name: props.name,
            }}
            onChange={onChange}
            onKeyUp={onKeyUp}
            onFocus={onFocus}
            onBlur={onBlur}
          />

          {showGoogleSearch && (
            <Tooltip title={t('GOOGLE_PLACES.TOOLTIP')}>
              <Stack
                direction="row"
                onClick={() => onGoogleSearchToggle?.(!googleSearchActive)}
                style={{ alignSelf: 'center' }}
                alignItems="center"
                gap="0.5rem"
                padding="0 1rem"
              >
                <Switch checked={googleSearchActive} size="small" />
                <img
                  src="/assets/img/google_logo.svg"
                  alt="Google"
                  style={{ height: '1.5rem', marginTop: '0.25rem' }}
                />
              </Stack>
            </Tooltip>
          )}
        </div>
      </>
    );
  }
);
