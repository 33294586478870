import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Project } from './Project.entity';
import { User } from './User.entity';

export class ProjectAccessRights<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: projectCode */
  projectId?: number;
  /** Alias: userCode */
  userId?: number;
  /** Alias: createdByUserCode */
  createdByUserCode?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: user */
  user?: User<T>;
  /** Alias: project */
  project?: Project<T>;

  __typename?: string;
}

const fields: FieldDefinitions<ProjectAccessRights> = {
  id: {
    alias: 'id',
  },
  projectId: {
    alias: 'projectCode',
  },
  userId: {
    alias: 'userCode',
  },
  createdByUserCode: {
    alias: 'createdByUserCode',
  },
  insertTime: {
    alias: 'insertTime',
  },
  updateTime: {
    alias: 'updateTime',
  },
  user: {
    alias: 'user',
    entity: Entities.user,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  __typename: {
    alias: '__typename',
  },
};

export const projectAccessRightsEntityDefinition: EntitiyDefinition<ProjectAccessRights> =
  {
    local: {},
    remote: {
      queryName: 'getProjectAccessRights',
      fragmentName: 'ProjectAccessRights',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'setProjectAccessRights',
          args: [
            { name: 'projectCode', type: 'Int' },
            { name: 'userCodes', type: '[Int]' },
            { name: 'unsetAllForUser', type: 'Int' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
