import { useTranslation } from 'react-i18next';

import { LockProvider } from '@work4all/components/lib/hooks';

import { checkModuleRight, useModuleRights, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ModuleAccessRightType } from '@work4all/models/lib/Enums/ModuleAccessRightType.enum';
import { ObjectProtectionState } from '@work4all/models/lib/Enums/ObjectProtectionState.enum';

import { dsiplyItemsFilter } from '../utils/display-items-filter';

import { supplierDisplayItems } from './consts';
import { FileInfoPanelItem, FileInfoPanelProps } from './FileInfoPanelItem';
import {
  FilePreviewPanel,
  FilePreviewPanelBaseProps,
} from './FilePreviewPanel';
import { IFileInfoPanelItems } from './types';

export interface ISupplierFileInfoPanelProps
  extends FileInfoPanelProps,
    FilePreviewPanelBaseProps {
  supplier: Supplier;
  loading?: boolean;
}

export function SupplierFileInfoPanel(props: ISupplierFileInfoPanelProps) {
  const {
    supplier,
    onEdit,
    onCloseClick,
    onSettings,
    displayItems = supplierDisplayItems,
    loading,
    onVisibilityToggle,
  } = props;

  const { t } = useTranslation();
  const entity = Entities.supplier;

  const { rights } = useModuleRights();
  const user = useUser();

  const resultingDisplayItems = displayItems.filter((item) => {
    if (item === IFileInfoPanelItems.OpenDuePositions) {
      if (
        !checkModuleRight(
          rights,
          ModuleAccessRightType.FEATURE_ORDER_MANAGEMENT_MODULE
        )
      ) {
        return false;
      }
    }
    if (item === IFileInfoPanelItems.Sales) {
      if (
        !checkModuleRight(
          rights,
          ModuleAccessRightType.FEATURE_ORDER_MANAGEMENT_MODULE
        ) ||
        !checkModuleRight(rights, ModuleAccessRightType.FEATURE_STATISTICS)
      ) {
        return false;
      }
    }
    if (item === IFileInfoPanelItems.SubscriptionContract) {
      if (
        !checkModuleRight(
          rights,
          ModuleAccessRightType.FEATURE_MAINTENANCE_CONTRACT_MODULE
        ) ||
        !checkUserRight(UserRights.WartungsmodulNutzen, user.benutzerRechte)
      ) {
        return false;
      }
    }

    return true;
  });

  return (
    <FilePreviewPanel
      entity={entity}
      title={supplier?.name}
      website={supplier?.website}
      onEdit={
        supplier?.objectGroupProtectionState !== ObjectProtectionState.LOCKED
          ? onEdit
          : undefined
      }
      onSettings={onSettings}
      onCloseClick={onCloseClick}
      displayItems={dsiplyItemsFilter(supplier, resultingDisplayItems)}
      onVisibilityToggle={onVisibilityToggle}
      renderItem={(id) => (
        <LockProvider entity={entity} entityIds={supplier?.id}>
          <FileInfoPanelItem
            {...props}
            loading={loading}
            key={id}
            item={id}
            entity={entity}
            partner={supplier}
            salesOpportunityList={[]}
            serviceContractList={[]}
          />
        </LockProvider>
      )}
      warning={
        supplier?.extinct === -1
          ? t('FILES_PREVIEW.CLOSED_WARNING.SUPPLIER')
          : undefined
      }
    />
  );
}
