import {
  CheckBox,
  CheckBoxOutlineBlank,
  ChevronLeft,
  ChevronRight,
  Search,
  ViewColumn,
} from '@mui/icons-material';
import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
} from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ResizableArea } from '@work4all/components/lib/components/ResizableArea';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { Project } from '@work4all/models/lib/Classes/Project.entity';

import { ProjectPickerField } from '../../../../../../../entity-picker/ProjectPickerField';
import { ProjectProcessColumn, ProjectProcessColumnType } from '../types';
import { projectProcessColumnLabelTKey } from '../utils';

interface IProps {
  leftAreaCollapsed?: boolean;
  onLeftAreaCollapsedChanged?: (value: boolean) => void;
  leftAreaColumns: ProjectProcessColumn[];
  onProjectSelected?: (value: Project) => void;
  onLeftAreaColumnsChanged?: (value: ProjectProcessColumn[]) => void;
  showTableHeaders?: boolean;
}

const rightOrientationCells: ProjectProcessColumnType[] = [
  ProjectProcessColumnType.Duration,
  ProjectProcessColumnType.TargetValue,
  ProjectProcessColumnType.ActualValue,
  ProjectProcessColumnType.Difference,
];

const centerOrientationCells: ProjectProcessColumnType[] = [
  ProjectProcessColumnType.StartDate,
  ProjectProcessColumnType.EndDate,
];

export const useRenderResourceHeader = (props: IProps) => {
  const {
    leftAreaCollapsed = false,
    onLeftAreaCollapsedChanged,
    leftAreaColumns,
    onLeftAreaColumnsChanged,
    onProjectSelected,
    showTableHeaders = true,
  } = props;
  const { t } = useTranslation();

  const { handleClick, ...popoverState } = usePopoverState('bottom');

  const handleHiddenStatusChange = useCallback(
    (col: ProjectProcessColumn) => {
      const newCols = [...leftAreaColumns];
      const updatedCol = newCols.find((x) => x.type === col.type);
      updatedCol.hidden = !updatedCol.hidden;
      onLeftAreaColumnsChanged?.(newCols);
    },
    [leftAreaColumns, onLeftAreaColumnsChanged]
  );

  return useCallback(() => {
    return (
      <div>
        <Popover {...popoverState}>
          <Paper>
            <List disablePadding>
              {leftAreaColumns.map((col) => {
                return (
                  <ListItemButton
                    key={col.type}
                    onClick={() => handleHiddenStatusChange(col)}
                  >
                    <ListItemIcon>
                      {col.hidden ? (
                        <CheckBoxOutlineBlank />
                      ) : (
                        <CheckBox color="primary" />
                      )}
                    </ListItemIcon>
                    <ListItemText>
                      {t(projectProcessColumnLabelTKey[col.type])}
                    </ListItemText>
                  </ListItemButton>
                );
              })}
            </List>
          </Paper>
        </Popover>

        <Stack direction="row" justifyContent="end" gap="0.5rem">
          <Box width="100%">
            <ProjectPickerField
              onChange={onProjectSelected ? onProjectSelected : () => null}
              value={null}
              label={t('COMMON.CHOOSE', { name: t('COMMON.PROJECT') })}
              fieldComp={
                <BaseActionButton
                  icon={<Search />}
                  title={t('COMMON.CHOOSE', { name: t('COMMON.PROJECT') })}
                />
              }
              clearable
            />
          </Box>

          {showTableHeaders && (
            <IconButton onClick={handleClick} size="small">
              <ViewColumn fontSize="small" />
            </IconButton>
          )}
          <IconButton
            size="small"
            onClick={() => onLeftAreaCollapsedChanged?.(!leftAreaCollapsed)}
          >
            {leftAreaCollapsed ? (
              <ChevronRight fontSize="small" />
            ) : (
              <ChevronLeft fontSize="small" />
            )}
          </IconButton>
        </Stack>
        {showTableHeaders && (
          <Stack
            direction="row"
            paddingLeft="4em"
            marginTop="calc( + 1px)"
            borderTop={'1px solid var(--ui04)'}
            justifyContent="end"
          >
            {leftAreaColumns.map((col) => {
              if (col.hidden) {
                return null;
              }

              return (
                <ResizableArea
                  key={col.type}
                  handles="right"
                  size={{ width: col.width }}
                  minWidth={30}
                  onResize={(e) => {
                    const newCols = [...leftAreaColumns];
                    const transformedCol = newCols.find(
                      (x) => x.type === col.type
                    );
                    transformedCol.width = e.width;
                    onLeftAreaColumnsChanged(newCols);
                  }}
                >
                  <Box
                    borderLeft={'1px solid var(--ui04)'}
                    padding="0.75em 0.5em"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    fontSize="0.85rem"
                    textAlign={
                      rightOrientationCells.includes(col.type)
                        ? 'right'
                        : centerOrientationCells.includes(col.type)
                        ? 'center'
                        : 'left'
                    }
                  >
                    {t(projectProcessColumnLabelTKey[col.type])}
                  </Box>
                </ResizableArea>
              );
            })}
          </Stack>
        )}
      </div>
    );
  }, [
    handleClick,
    handleHiddenStatusChange,
    leftAreaCollapsed,
    leftAreaColumns,
    onLeftAreaCollapsedChanged,
    onLeftAreaColumnsChanged,
    onProjectSelected,
    popoverState,
    showTableHeaders,
    t,
  ]);
};
