import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Calculation } from '@work4all/models/lib/Classes/Calculation.entity';
import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const conditionalProps = {
  [Entities.calculation]: {
    date: null,
    number: null,
  } as Calculation,
  [Entities.contract]: {
    contractDate: null,
    contractNumber: null,
  } as Contract,
};

export const useProjectErpObjects = (props: {
  projectIdList: number[];
  entity: Entities.calculation | Entities.contract;
  skip?: boolean;
}) => {
  const { entity, projectIdList, skip } = props;

  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'ErpObjectsForPlanning',
      entity,
      data: {
        ...conditionalProps[entity],
        id: null,
        note: null,
        projectId: null,
        positionList: [
          {
            id: null,
            unit: null,
            amount: null,
            article: {
              id: null,
              ressourceClassId: null,
              ressourceClass: {
                id: null,
                name: null,
              },
            },
          },
        ],
      } as Contract | Calculation,
      filter: [
        {
          projectId: {
            $in: projectIdList,
          },
        },
      ],
    };
  }, [entity, projectIdList]);

  return useDataProvider<Contract | Calculation>(request, skip, 10000);
};
