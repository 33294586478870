import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationArea } from '../../../organisation-area';
import { TableAreas } from '../../table/Table';

export const useTextBuildingBlockTableAreas = () => {
  const { t } = useTranslation();

  return useMemo<TableAreas>(() => {
    return {
      left: {
        title: t('COMMON.ORGANISATION'),
        content: <OrganisationArea entity={Entities.textBuildingBlock} />,
        resizable: true,
        collapsible: true,
        custom: true,
      },
    };
  }, [t]);
};
