import { entityDefinition } from '@work4all/models/lib/Classes/entityDefinitions';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const NON_FLAGGABLE_ENTITIES = [
  Entities.customer,
  Entities.supplier,
  Entities.project,
];

export const canFlagEntityRecordsAsFavorite = (entity: Entities) => {
  if (NON_FLAGGABLE_ENTITIES.includes(entity)) {
    return false;
  }

  const definition = entityDefinition[entity];

  /*
   * If the `entityDefinition` have `favoriteItem` as a field, and
   * `onlyFavItems` as a params, we consider that the entity
   * records has the ability to be flagged as favorite.
   */
  return (
    Boolean(definition?.fieldDefinitions['favoriteItem']) &&
    Boolean(
      definition?.remote?.params?.find((param) => param.name === 'onlyFavItems')
    )
  );
};
