import { ApolloError, gql, useApolloClient, useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { ObjectTypeByEntity, ObjectTypesUnion } from '@work4all/models';
import { DeleteEntityResult } from '@work4all/models/lib/Classes/DeleteEntityResult.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLatest } from '@work4all/utils/lib/hooks/use-latest';

import { useApolloExtrasContext } from './lib/apollo-extras/apollo-extras-context';
import { evictEntityCache } from './lib/apollo-extras/evictEntityCache';
import { useEntityEventsContext } from './lib/entity-events/entity-events-context';

const DELETE_ENTITY = gql`
  mutation DeleteEntity($objectType: ObjectType!, $keys: [String]!) {
    deleteEntity(objectType: $objectType, keys: $keys) {
      deletedRecords
      errorMessages {
        primaryKey
        message
        type
      }
    }
  }
`;

type IDeleteEntityPrivateVars = {
  objectType: ObjectTypesUnion;
  keys: string[];
};

type IDeleteEntityVars = {
  type: Entities;
  fallbackType?: ObjectTypesUnion;
  readonly ids: (string | number)[];
};

type IDeleteEntityResponse = {
  deleteEntity: DeleteEntityResult;
};

type IDeleteEntityOptions = {
  onCompleted?: (data: IDeleteEntityResponse) => void;
  onError?: (error: ApolloError) => void;
};

export function useDeleteEntity(resetStore = true) {
  const client = useApolloClient();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { emit: emitEntityEvent } = useEntityEventsContext();
  const { isAutoInvalidationDisabled } = useApolloExtrasContext();
  const isAutoInvalidationDisabledRef = useLatest(isAutoInvalidationDisabled);

  const [mutate, result] = useMutation<
    IDeleteEntityResponse,
    IDeleteEntityPrivateVars
  >(DELETE_ENTITY);

  const deleteEntity = useCallback(
    (variables: IDeleteEntityVars, options?: IDeleteEntityOptions) => {
      return mutate({
        ...options,
        variables: {
          objectType:
            ObjectTypeByEntity[variables.type] || variables.fallbackType,
          keys: variables.ids.map((id) =>
            typeof id === 'number' ? String(id) : id
          ),
        },
        onCompleted(data) {
          if (data.deleteEntity?.errorMessages?.length) {
            if (
              data.deleteEntity?.errorMessages.every(
                (x) => x.type === 'VALIDATION_ERROR'
              ) &&
              [Entities.deliveryNote, Entities.inboundDeliveryNote].includes(
                variables.type
              )
            )
              enqueueSnackbar(t('ERROR.DELIVERY_NOTE_DELETE'), {
                variant: 'error',
                autoHideDuration: 3000,
              });
          }
          emitEntityEvent({
            type: 'delete',
            entity: variables.type,
            ids: variables.ids,
          });

          evictEntityCache(
            client,
            variables.type,
            resetStore && !isAutoInvalidationDisabledRef?.current
          );

          options?.onCompleted?.(data);
        },
      });
    },
    [
      client,
      emitEntityEvent,
      isAutoInvalidationDisabledRef,
      mutate,
      resetStore,
      enqueueSnackbar,
      t,
    ]
  );

  return [deleteEntity, result] as const;
}
