import styles from './ErpRessourceClassCell.module.scss';

import { Edit } from '@mui/icons-material';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useRef, useState } from 'react';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { formatAsFloat } from '@work4all/utils';

import { settings, useSetting } from '../../settings';

import { PositionCorrection } from './planning-columns/components/PositionCorrection';
import { getFactor } from './planning-columns/utils';

export type ErpRessourceClassProps = {
  children?: React.ReactNode;
  value: string | number;
  column?: {
    id: string;
    cellParams: {
      entity: Entities;
      rc: LookUp;
    };
  };
  row?: {
    original: ERPTypes;
  };
};

export const ErpRessourceClassCell = (props: ErpRessourceClassProps) => {
  const {
    row: { original: bzObj },
    column: {
      cellParams: { entity, rc },
    },
  } = props;
  const [showPositionCorrection, setShowPositionCorrection] = useState(false);
  const [loading, setLoading] = useState(false);
  const cellRef = useRef<HTMLDivElement>(null);

  const bzObjId = bzObj.id;
  const parentId = bzObj.businessPartnerId;
  const parentEntity =
    bzObj.businessPartnerType === SdObjType.KUNDE
      ? Entities.customer
      : Entities.supplier;
  const resourcePlanningColoumnTimeUnit = useSetting(
    settings.resourcePlanningColoumnTimeUnit()
  );

  const positions =
    bzObj.positionList?.filter(
      (pos) => pos?.article?.ressourceClassId === rc.id
    ) ?? [];

  const value = positions
    ?.map((x) => {
      const factor = getFactor(x.unit, resourcePlanningColoumnTimeUnit.value);
      return x.amount * factor;
    })
    ?.reduce((a, b) => {
      return a + b;
    }, 0);

  return (
    <>
      <Box ref={cellRef} textAlign="center">
        {loading ? (
          <CircularProgress size="1rem" />
        ) : (
          <Box
            className={styles.cellWrap}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowPositionCorrection(true);
            }}
          >
            <Typography flex="1">
              {value !== 0
                ? formatAsFloat(value, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 3,
                  })
                : ' '}
              {value !== 0 && <Edit className={styles.editIcon} />}
            </Typography>
          </Box>
        )}
      </Box>

      {showPositionCorrection && (
        <PositionCorrection
          positions={positions}
          entity={entity}
          parentEntity={parentEntity}
          parentId={parentId}
          bzObjId={bzObjId}
          cellRef={cellRef}
          onEditStart={() => {
            setLoading(true);
          }}
          onEditComplete={() => {
            setLoading(false);
            setShowPositionCorrection(false);
          }}
        />
      )}
    </>
  );
};
