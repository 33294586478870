import { createContext, useContext } from 'react';

export type PickerContextValue = {
  hasSelection: boolean;
};

const PickerContext = createContext<PickerContextValue>(null);

export const PickerContextProvider = PickerContext.Provider;

export function usePickerContext() {
  const ctx = useContext(PickerContext);

  if (!ctx) {
    throw new Error(
      'usePickerContext must be used within a <PickerContextProvider>'
    );
  }

  return ctx;
}
