export type IArrayCellProps = {
  column: {
    id: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accessor: (row: any) => any;
    cellParams?: {
      accessorKey?: string;
      separator?: string;
    };
  };
  row: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    original: any;
  };
};

function index(obj, i) {
  return obj?.[i];
}

export const ArrayCell = (props: IArrayCellProps) => {
  const {
    column: { id, cellParams },
    row: { original: rowData },
  } = props;

  const accessorKey = cellParams?.accessorKey;
  const separator = cellParams?.separator || ', ';
  const key = accessorKey?.split('.') || id.split('.');
  const accessor = key.splice(1);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const arrayObj = rowData[key[0]] as any[];
  const val = arrayObj
    .map((el) => accessor.reduce(index, el))
    .filter((v) => v != null)
    .sort()
    .join(separator);

  return <span title={val}>{val}</span>;
};
