import styles from './CardWidget.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import { Theme, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DisabledParams } from '../actions-bar/ToolbarTypes';

import { CardContent } from './components/card-content';
import { CardHeader } from './components/card-header';
import { useWidgetDrop } from './hooks/use-widget-drag-drop';
import {
  ICardWidgetColumnDef,
  IGetCellModifiers,
  IGetRowModifiers,
} from './types';

export interface ICardWidgetProps<T> {
  items?: T[];
  title: string;
  totalCount: number;

  getItemHref: (item: T) => string;
  listHref: string;
  newHref: string;
  isSelected?: (item: T) => boolean;

  onItemClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;
  onItemDoubleClick?: (item: T, event: React.MouseEvent<HTMLElement>) => void;

  columns: ICardWidgetColumnDef<T>[];

  /**
   * Whether or not the card can be minimized. If `true`, will display a
   * button in the header to toggle the state.
   *
   * @default false
   */
  collapsible?: boolean;

  /**
   * If `collapsible` is set to `true`, this prop controls the current card
   * state.
   *
   * @default false
   */
  collapsed?: boolean;

  /**
   * Callback fired when the expand/collapse state is changed.
   *
   * @param collapsed The `collapsed` state of the card.
   */
  onCollapsedChange?: (collapsed: boolean) => void;

  /**
   * Maximum number of rows to display in the card. If actual number of items
   * is greater than this value, the list will be truncated and "+ {N} more"
   * row will be added at the end.
   */
  maxRows: number;

  disabledAdd?: DisabledParams;

  getRowModifiers?: IGetRowModifiers<T>;
  getCellModifiers?: IGetCellModifiers<T>;
  additionalActions?: React.ReactNode;

  entity: Entities;
}

export const CardWidget = function CardWidget<T>(props: ICardWidgetProps<T>) {
  const isViewportDesktop = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up('md')
  );
  const { t } = useTranslation();

  const {
    title,
    getItemHref,
    listHref,
    newHref,
    isSelected,
    onItemClick,
    onItemDoubleClick,
    collapsible = false,
    collapsed = false,
    onCollapsedChange,

    items,
    totalCount,

    disabledAdd,
    additionalActions,

    getRowModifiers,
    getCellModifiers,

    columns,
    entity,
  } = props;

  // max rows minus card header height
  const rowsCount = useMemo(() => items.length, [items]);

  const content = (
    <CardContent
      getItemHref={getItemHref}
      items={items}
      maxRows={rowsCount}
      isSelected={isSelected}
      onItemClick={onItemClick}
      onItemDoubleClick={onItemDoubleClick}
      columns={columns}
      getRowModifiers={getRowModifiers}
      getCellModifiers={getCellModifiers}
    />
  );

  const target = t(`COMMON.${entity.toUpperCase()}`);
  const { isCorrect, isOver, canDrop, drop, isEntitiesIdentical } =
    useWidgetDrop();

  return (
    <div
      ref={drop}
      className={clsx(
        styles[isViewportDesktop ? 'card' : 'card-md'],
        collapsed && styles.collapsed
      )}
    >
      {canDrop && (
        <div
          className={clsx(styles['drop-widget'], {
            [styles['identical']]: isEntitiesIdentical,
          })}
        >
          <div
            className={clsx(styles['drop-widget-border'], {
              [styles['drop-widget-isOver']]: !isOver || !isCorrect,
            })}
          >
            {isCorrect ? (
              <React.Fragment>
                <ConvertIcon />
                <div>
                  <Typography
                    color={isOver ? 'primary' : undefined}
                    className={clsx({
                      [styles['drop-widget-isOver']]: !isOver,
                    })}
                    variant="caption"
                  >
                    {isEntitiesIdentical
                      ? t('COMMON.DUPLICATE')
                      : t('COMMON.CONVERT_TO')}
                  </Typography>
                  <Typography variant="h4">{target}</Typography>
                </div>
              </React.Fragment>
            ) : (
              <div className={styles['drop-widget-disabled']}>
                <CloseIcon color="error" />
                <Typography variant="caption">
                  {t('DRAG.NO_PERMISSON_TO', { target })}
                </Typography>
              </div>
            )}
          </div>
        </div>
      )}

      <CardHeader
        moreHref={listHref}
        totalRows={totalCount}
        getItemHref={getItemHref}
        title={title}
        totalCount={totalCount}
        collapsible={collapsible}
        collapsed={collapsed}
        onCollapsed={() => onCollapsedChange?.(!collapsed)}
        className={clsx(styles['card__header'])}
        listHref={listHref}
        newHref={newHref}
        disabledAdd={disabledAdd}
        additionalActions={additionalActions}
      />

      {isViewportDesktop && (collapsed ? null : content)}
    </div>
  );
};
