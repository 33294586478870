import { isEqual } from 'lodash';

import { EntityRelation } from '@work4all/data';
import { ITempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { ArticleLedgerAccount } from '@work4all/models/lib/Classes/ArticleLedgerAccount.entity';
import { ArticlePrices } from '@work4all/models/lib/Classes/ArticlePrices.entity';
import { ArticleText } from '@work4all/models/lib/Classes/ArticleText.entity';
import { BOMComponent } from '@work4all/models/lib/Classes/BOMComponent.entity';
import { InputArticleAttachment } from '@work4all/models/lib/Classes/InputArticleAttachment.entity';
import { InputArticleBomComponents } from '@work4all/models/lib/Classes/InputArticleBomComponents.entity';
import { InputArticleLedgerAccountsAssignmentsRelation } from '@work4all/models/lib/Classes/InputArticleLedgerAccountsAssignmentsRelation.entity';
import { InputArticlePrices } from '@work4all/models/lib/Classes/InputArticlePrices.entity';
import { InputArticlePricesSetSimplePrice } from '@work4all/models/lib/Classes/InputArticlePricesSetSimplePrice.entity';
import { InputArticleRelation } from '@work4all/models/lib/Classes/InputArticleRelation.entity';
import { InputArticleSetLedgerAccountsAssignmentRelation } from '@work4all/models/lib/Classes/InputArticleSetLedgerAccountsAssignmentRelation.entity';
import { InputArticleTextAddRelation } from '@work4all/models/lib/Classes/InputArticleTextAddRelation.entity';
import { InputArticleTextModifyRelation } from '@work4all/models/lib/Classes/InputArticleTextModifyRelation.entity';
import { InputArticleTextRelation } from '@work4all/models/lib/Classes/InputArticleTextRelation.entity';
import { ArticleKind } from '@work4all/models/lib/Enums/ArticleKind.enum';

import { EditTableEntry } from '../../erp/components/tab-panels/positions/components/edit-table/types';
import { ArticleMaskFormValue } from '../types';

export function mapRelations(
  oldValues: ArticleMaskFormValue,
  newValues: ArticleMaskFormValue,
  tempFileManager: ITempFileManagerContext
): InputArticleRelation {
  const relations: InputArticleRelation = {};

  const articleTextsRelation = mapArticleTextsRelation(
    oldValues.articleTextList,
    newValues.articleTextList
  );

  if (articleTextsRelation) {
    relations.articleTexts = articleTextsRelation;
  }

  const articlePricesRelation = mapArticlePricesRelation(
    oldValues.articlePrices,
    newValues.articlePrices
  );

  if (articlePricesRelation) {
    relations.prices = articlePricesRelation;
  }

  const ledgerAccountAssignements = mapLedgerAccountAssignmentsRelation(
    oldValues.articleLedgerAccountAssignements,
    newValues.articleLedgerAccountAssignements
  );

  if (ledgerAccountAssignements) {
    relations.ledgerAccountAssignements = ledgerAccountAssignements;
  }

  const bomComponents = mapBomComponentsRelation(
    oldValues.bomComponents,
    newValues.bomComponents
  );

  if (bomComponents) {
    const manipulateBom =
      (bomComponents.add?.length ||
        bomComponents?.modify ||
        bomComponents.remove?.length) &&
      newValues.articleKind === ArticleKind.STUECKLISTE;
    if (manipulateBom) {
      relations.bomComponents = bomComponents;
    }
  }

  const attachments = mapAttachements(tempFileManager);
  if (attachments) {
    relations.attachments = attachments;
  }

  return relations;
}

export const mapBomEditableProps = (component: BOMComponent) => ({
  id: component.id,
  name: component.name,
  articleId: component.article.id,
  amount: component.amount,
  netPrice: component.article.netPrice,
  netPrice2: component.article.netPrice2,
  netPrice3: component.article.netPrice3,
});

function mapAttachements(
  tempFileManager: ITempFileManagerContext
): InputArticleAttachment {
  return {
    add: tempFileManager.fileList
      .filter((x) => typeof x.id === 'string')
      .map(({ id, fileName, fileInfos, type, date, ...rest }) => ({
        tempFileId: id as string,
        name: fileName,
        ...rest,
      })),
    modify: tempFileManager.filesToUpdate.map(
      ({ id, fileName, fileInfos, type, date, __typename, ...rest }) => ({
        name: fileName,
        code: id as number,
        ...rest,
      })
    ),
    delete: tempFileManager.fileIdsToDelete as number[],
  };
}

function mapBomComponentsRelation(
  oldValues: BOMComponent[],
  newValues: BOMComponent[]
): InputArticleBomComponents & EntityRelation<BOMComponent> {
  if (!newValues) return null;

  let orderChanged = false;
  // order chagned
  for (let i = 0; i < newValues.length; i++) {
    const newElement = newValues[i];
    const oldElement = oldValues?.[i];
    if (newElement?.id !== oldElement?.id) orderChanged = true;
  }

  const bomComponents = newValues
    .filter((x) => !(x as EditTableEntry).cacheOnly)
    .map(mapBomEditableProps);

  if (orderChanged) {
    return {
      add: bomComponents.map((x) => ({
        articleCode: x.articleId,
        amount: x.amount,
        name: x.name,
      })),
      remove: (oldValues || []).map((x) => x.id),
      shouldTranslate: false,
    };
  }

  const modifyIds = bomComponents.map((x) => x.id);
  return {
    modify: bomComponents.map((x) => ({
      code: x.id,
      articleCode: x.articleId,
      amount: x.amount,
      name: x.name,
    })),
    remove: oldValues.map((x) => x.id).filter((x) => !modifyIds.includes(x)),
    shouldTranslate: false,
  };
}

function mapArticleTextsRelation(
  oldValues: ArticleText[],
  newValues: ArticleText[]
): InputArticleTextRelation {
  const articleTextListRelation: InputArticleTextRelation = {};

  const addedText = newValues
    .filter((text) => text.id == null)
    .map((text) => {
      const input: InputArticleTextAddRelation = {
        languageCode: text.sprachCode,
        shortText: text.kurztext,
        longText: text.longtext,
      };

      return input;
    });

  if (addedText.length > 0) {
    articleTextListRelation.add = addedText;
  }

  const modifiedText = newValues
    .filter((text) => {
      if (text.id == null) return false;

      function isTextUpdated(oldText: ArticleText, newText: ArticleText) {
        return (
          oldText.sprachCode !== newText.sprachCode ||
          oldText.kurztext !== newText.kurztext ||
          oldText.longtext !== newText.longtext
        );
      }

      const oldText = oldValues.find((oldText) => oldText.id === text.id);

      return oldText != null && isTextUpdated(oldText, text);
    })
    .map((text) => {
      const input: InputArticleTextModifyRelation = {
        code: text.id,
        shortText: text.kurztext,
        longText: text.longtext,
      };

      return input;
    });

  if (modifiedText.length > 0) {
    articleTextListRelation.modify = modifiedText;
  }

  const removedText = oldValues
    .filter((text) => {
      const newText = newValues.find((newText) => newText.id === text.id);

      return newText == null;
    })
    .map((text) => text.id);

  if (removedText.length > 0) {
    articleTextListRelation.remove = removedText;
  }

  return Object.keys(articleTextListRelation).length > 0
    ? articleTextListRelation
    : null;
}

function mapArticlePricesRelation(
  oldValues: ArticlePrices,
  newValues: ArticlePrices
): InputArticlePrices {
  if (isEqual(oldValues, newValues)) {
    return null;
  }

  const articlePricesRelation: InputArticlePrices = {};

  const simplePrices = newValues.singlePriceList?.map((price) => {
    const input: InputArticlePricesSetSimplePrice = {
      priceGroupCode: price.priceGroup.id,
      price: price.price,
      surcharge: price.surcharge,
      pricePerMinute: price.minuteWage,
    };

    return input;
  });

  if (simplePrices.length > 0) {
    articlePricesRelation.simplePrices = simplePrices;
  }

  return Object.keys(articlePricesRelation).length > 0
    ? articlePricesRelation
    : null;
}

function mapLedgerAccountAssignmentsRelation(
  oldValues: ArticleLedgerAccount[],
  newValues: ArticleLedgerAccount[]
): InputArticlePrices {
  if (isEqual(oldValues, newValues)) {
    return null;
  }

  const relation: InputArticleLedgerAccountsAssignmentsRelation = {};

  const ledgerAccounts = newValues
    .map((la) => {
      const input: InputArticleSetLedgerAccountsAssignmentRelation = {
        taxGroupCode: la.taxGroup.id,
        accountNumber: la.ledgerAccount?.number ?? 0,
        vatCode: la.vatRate?.id ?? 0,
      };

      return input;
    })
    .filter((x) => x.accountNumber !== 0 || x.vatCode !== 0);

  relation.set = ledgerAccounts;

  return relation;
}
