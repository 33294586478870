import styles from './SectionedInput.module.scss';

import {
  ChangeEvent,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { ILabeledInput } from '../labeled-input';

import { escapeCharactersRegex } from './utils';

interface InputSelection {
  start: number;
  end: number;
}

type SectionChangeEventType = 'remove' | 'digit' | 'up' | 'down' | 'none';
export interface Section extends InputSelection {
  index: number;
  event?: SectionChangeEventType;
  originalValue?: string;
}

export interface SectionChangeEvent extends ChangeEvent<HTMLInputElement> {
  section: Section;
}

function findSectionIndices(
  arr: string[],
  sectionIndex: number
): InputSelection {
  const sectionLength = arr[sectionIndex].length;
  let start = 0;

  // Find the starting index of the section + 1 cause of splitter
  for (let i = 0; i < sectionIndex; i++) {
    start += arr[i].length + 1;
  }

  // Find the ending index of the section
  const end = start + sectionLength;

  return { start, end };
}

function findSectionIndex(selectionStart: number, sections: string[]) {
  let fromBegin = 0;
  const sectionIndex = sections.findIndex((section) => {
    const current = section.length + fromBegin + 1;
    fromBegin = current;
    return selectionStart < current;
  });

  return sectionIndex;
}

export interface WithSectionProps extends ILabeledInput {
  mask: string;
  splitter: ':' | '/' | '.' | '[: ]';
  onSectionChange?: (section: Section) => void;
  section?: { value: number };
  modify: (
    value: string,
    section: number,
    direction: 'up' | 'down' | 'none'
  ) => string;
}
const handledKeys = [
  'ArrowLeft',
  'ArrowRight',
  'ArrowDown',
  'ArrowUp',
  'Backspace',
  'Enter',
  'Escape',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

export function withSection<T extends WithSectionProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const Componnet = forwardRef<any, T>((props: T, ref: any) => {
    const { mask, splitter, modify, section } = props;
    const inputRef = useRef(ref);

    const separator = useMemo(
      () => escapeCharactersRegex(splitter),
      [splitter]
    );

    const { start, end } = findSectionIndices(mask.split(separator), 0);

    // TODO: pass reference
    const [selection, setSelection] = useState<InputSelection>({
      start,
      end,
    });

    useEffect(() => {
      if (section === undefined) return;
      const { start, end } = findSectionIndices(
        mask.split(separator),
        section.value
      );
      setSelection({ start, end });
    }, [section, setSelection, mask, separator]);

    useLayoutEffect(() => {
      if (inputRef.current && inputRef.current === document.activeElement) {
        inputRef.current.setSelectionRange(
          selection.start,
          selection.end,
          'forward'
        );
      }
    }, [selection, props.value]);

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
      props.onFocus?.(event);
      event.target.setSelectionRange(selection.start, selection.end);
    };

    const handleSelection = (event) => {
      const selectionStart = event.target.selectionStart;
      const sections = event.target.value.split(separator);
      const sectionIndex = findSectionIndex(selectionStart, sections);
      const { start, end } = findSectionIndices(sections, sectionIndex);
      setSelection({ start, end });
    };

    const handleNewValue = (event) => {
      const eventKey = event.key || event.nativeEvent.data;

      if (!handledKeys.includes(eventKey)) {
        props.onKeyDown?.(event);
        return;
      }
      event.preventDefault();
      event.stopPropagation();

      let eventType: SectionChangeEventType = 'none';

      // Get the current value of the input
      let value = event.target.value;

      // Split the value into sections
      const sections = value.split(separator);

      // Get the index of the section to update based on the cursor position
      const selectionStart = event.target.selectionStart;

      const sectionIndex = findSectionIndex(selectionStart, sections);

      if (eventKey === 'Backspace') {
        eventType = 'remove';
        props.onSectionChange?.({
          ...selection,
          index: sectionIndex,
          event: eventType,
        });
        return;
      }

      if (eventKey === 'ArrowLeft' && selectionStart > 0) {
        const index = Math.max(sectionIndex - 1, 0);
        const { start, end } = findSectionIndices(
          sections,
          Math.max(sectionIndex - 1, 0)
        );
        inputRef.current.setSelectionRange(start, end, 'forward');
        props?.onSectionChange?.({ start, end, index });
        setSelection({ start, end });
        return;
      }

      if (eventKey === 'ArrowRight' && selectionStart < value.length) {
        const index = Math.min(sectionIndex + 1, sections.length - 1);
        const { start, end } = findSectionIndices(sections, index);
        inputRef.current.setSelectionRange(start, end, 'forward');
        props?.onSectionChange?.({ start, end, index });
        setSelection({ start, end });
        return;
      }

      // Handle keyboard input
      if (eventKey === 'ArrowUp') {
        eventType = 'up';
        sections[sectionIndex] = modify(
          sections[sectionIndex],
          sectionIndex,
          eventType
        );
      } else if (eventKey === 'ArrowDown') {
        eventType = 'down';
        sections[sectionIndex] = modify(
          sections[sectionIndex],
          sectionIndex,
          eventType
        );
      } else if (eventKey.length === 1 && /\d/.test(eventKey)) {
        sections[sectionIndex] += eventKey;
      }

      if (!isNaN(parseInt(eventKey))) {
        eventType = 'digit';
        sections[sectionIndex] = modify(
          sections[sectionIndex],
          sectionIndex,
          'none'
        );
      }

      value = sections.join(splitter);

      // inputRef.current.value = value;
      const { start, end } = findSectionIndices(sections, sectionIndex);
      setSelection({ start, end });

      // Provoke on change
      const change: SectionChangeEvent = {
        section: {
          start,
          end,
          index: sectionIndex,
          event: eventType,
          originalValue: eventKey,
        },
        target: {
          value,
        },
      } as unknown as SectionChangeEvent;

      props.onChange(change);
      props?.onKeyDown?.(event);
    };

    const existKey = useRef(true);

    const handleKeyDown = (e) => {
      existKey.current = e.key !== 'Unidentified';
      handleNewValue(e);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (event: any) => {
      if (!existKey.current) handleKeyDown(event);
    };

    return (
      <WrappedComponent
        {...(props as T)}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        type="text"
        classes={{
          ...props.classes,
          input: styles.input,
        }}
        inputProps={{
          ...props.inputProps,
          'max-length': mask.length,
        }}
        onChange={handleChange}
        onFocus={handleFocus}
        inputOnClick={handleSelection}
      />
    );
  });

  Componnet.displayName = `withSection(${displayName})`;
  return Componnet;
}
