import { Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { settings, useSetting } from '../../../../settings';

export enum ProjectTimeWidget {
  history = 'history',
  templates = 'templates',
}

export const ProjectTimeWidgetTabBar = () => {
  const { t } = useTranslation();

  const projectTimeTrackingWidget = useSetting(
    settings.projectTimeTrackingWidget()
  );

  return (
    <Tabs
      value={projectTimeTrackingWidget.value}
      onChange={(_, val) => {
        projectTimeTrackingWidget.set?.(val);
      }}
    >
      {[ProjectTimeWidget.history, ProjectTimeWidget.templates].map((v) => (
        <Tab key={v} value={v} label={t(`PROJECTTIME.WIDGET.${v}`)} />
      ))}
    </Tabs>
  );
};
