import { useMemo } from 'react';

import { IUser, useDataProvider } from '@work4all/data';

import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { RePaymentStatus } from '@work4all/models/lib/Enums/RePaymentStatus.enum';
import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';

export const useRePermits = (user: IUser) => {
  const requestOpenInboundInvoices: DataRequest = useMemo(() => {
    return {
      entity: Entities.reViewModel,
      data: {},
      filter: [
        {
          $and: [
            {
              'approvedByUser.id': {
                $in: [user.benutzerCode],
              },
            },
            {
              permitStatus: {
                $eq: RePermitStatus.EMPTY,
              },
            },
            {
              paymentStatus: {
                $in: [
                  RePaymentStatus.DUE,
                  RePaymentStatus.OPEN,
                  RePaymentStatus.OPEN_CREDIT,
                  RePaymentStatus.PARTIAL_PAYED,
                  RePaymentStatus.PARTIAL_PAYED_CREDIT,
                ],
              },
            },
          ],
        },
      ],
    };
  }, [user.benutzerCode]);

  const resOpenInboundInvoices = useDataProvider<ReViewModel>(
    requestOpenInboundInvoices,
    false,
    1
  );

  return resOpenInboundInvoices;
};
