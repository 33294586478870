import { Position } from '@work4all/models/lib/Classes/Position.entity';

import { STUECKLISTE_GROUP } from '../edit-table/hooks/use-editable-state';

export const defaultErpGetRelation = (position: Position) => {
  if (position.posId) return 'child';
  if (
    STUECKLISTE_GROUP.includes(position.positionKind) ||
    ('collapsed' in position && position.collapsed)
  ) {
    return 'parent';
  }
  return 'normal';
};
