import { User } from '@work4all/models/lib/Classes/User.entity';

export type AppointmentsTimeRange =
  | 'agenda'
  | 'day'
  | 'week-5'
  | 'week-7'
  | 'month';
export type AppointmentsGroupMode = 'grouped' | 'horizontal' | 'vertical';

export interface AppointmentCalendarState {
  users: User[];
  focusedUserIds: number[];
  date: Date;
  range: AppointmentsTimeRange;
  groupMode: AppointmentsGroupMode;
  appointmentStates: number[];
  planningAppointments: boolean;
}

export interface AppointmentCalendarParams {
  userIds: number[];
  focusedUserIds: number[];
  date: Date;
  range: AppointmentsTimeRange;
  groupMode: AppointmentsGroupMode;
  appointmentStates: number[];
  planningAppointments: boolean;
}

export type AppointmentCalendarLastParams = Omit<
  AppointmentCalendarParams,
  'date'
>;

export type CalendarPhase = 'initial' | 'loading' | 'ready' | 'reloading';

export interface AppointmentCalendarView {
  id: string;
  name: string;
  readOnly?: boolean;
  params: Omit<AppointmentCalendarParams, 'date'>;
}

export enum CalendarCardColorBy {
  AppointmentStatus = 'AppointmentStatus',
  User = 'User',
}

export enum CalendarUserAppearance {
  Avatar = 'Avatar',
  Initials = 'Initials',
}

export type AppointmentCalendarViewInit = Omit<
  AppointmentCalendarView,
  'id' | 'readOnly'
>;

export interface CalendarProps {
  loading?: boolean;
  users: User[];
  onUsersChange: (users: User[]) => void;
  focusedUserIds: number[];
  onFocusedUserIdsChange: (ids: number[]) => void;
  date: Date;
  onDateChange: (date: Date) => void;
  range: AppointmentsTimeRange;
  onRangeChange: (range: AppointmentsTimeRange) => void;
  groupMode: AppointmentsGroupMode;
  onGroupModeChange: (usersDisplayMode: AppointmentsGroupMode) => void;
  appointmentStates: number[];
  onAppointmentStatesChange: (states: number[]) => void;
  onOpenMask: (appointmentId: number | null, presetFields?: string) => void;
  views: AppointmentCalendarView[];
  onSelectView: (view: AppointmentCalendarView) => void;
  onCreateView: (name: string) => void;
  onDeleteView: (view: AppointmentCalendarView) => void;
  forceMobileView?: boolean;
  hideMobileAddButton?: boolean;
  hideMobileUserSelection?: boolean;
  planningAppointments: boolean;
  onPlanningAppointmentsChange: (value: boolean) => void;
}

export type AgendaSize = 'shrinked' | 'expanded';
