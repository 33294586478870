import { gql, useSubscription } from '@apollo/client';

import { EntityChangedInfo } from '@work4all/models/lib/Classes/EntityChangedInfo.entity';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectType } from '@work4all/models/lib/Enums/ObjectType.enum';
import { ObjectTypeByEntity } from '@work4all/models/lib/GraphQLEntities/Entities';

import { useTenant } from './routing/TenantProvider';

export interface UseEntityChangedOptions {
  /**
   * Allows you to disable the GraphQL subscription.
   *
   * @default false
   */
  disabled?: boolean;

  /**
   * Filter change events by a given entity type.
   */
  entity: Entities | Entities[];

  /**
   * Filter change events by a given objects type.
   * Used if there's no entity, f.e. `KUNDENANSPRECHPARTNER`.
   */
  objectsType?: ObjectType[];

  /**
   * Filter change events by given event types. Passing `null` will return all
   * events.
   *
   * @default null
   */
  changeType?: ChangeType[];

  /**
   * Filter change events for the given tenant. Passing `null` will return
   * events for all available tenants. By default will filter events only for
   * the current tenant.
   */
  tenantId?: number;

  /**
   * If the `entity` is `Entities.objectLock`, you can additionally filter by
   * the ObjectLock's entity type.
   */
  objectLockEntity?: Entities;

  /**
   * This function will be called whenever a new change event is received.
   *
   * @param info The received change event.
   */
  onEntityChanged: (info: EntityChangedInfo) => void;
}

export function useEntityChanges(options: UseEntityChangedOptions): void {
  const { activeTenant } = useTenant();

  const {
    disabled = false,
    entity = [],
    changeType = null,
    tenantId = activeTenant,
    objectLockEntity,
    onEntityChanged,
    objectsType = [],
  } = options;

  const entities = Array.isArray(entity) ? entity : [entity];
  const entitiesToObjectsType = entities.map(
    (entity) => ObjectTypeByEntity[entity] as ObjectType
  );

  const allObjectsType = [...objectsType, ...entitiesToObjectsType];

  const objectLockObjectType = objectLockEntity
    ? (ObjectTypeByEntity[objectLockEntity] as ObjectType)
    : undefined;

  useSubscription<
    EntityChangedSubscriptionData,
    EntityChangedSubscriptionVariables
  >(ENTITY_CHANGED_SUBSCRIPTION, {
    skip: disabled,
    variables: {
      changeType,
      objectType: allObjectsType,
      tenantId,
      objectLockObjectType,
    },
    onData({ data: subscriptionData }) {
      const { data } = subscriptionData;

      if (data) {
        const { entityChanged } = data;

        onEntityChanged(entityChanged);
      }
    },
  });
}

interface EntityChangedSubscriptionData {
  entityChanged: EntityChangedInfo;
}

interface EntityChangedSubscriptionVariables {
  objectType?: ObjectType[];
  changeType?: ChangeType[];
  tenantId?: number;
  objectLockObjectType: ObjectType;
}

export const ENTITY_CHANGED_SUBSCRIPTION = gql`
  subscription EntityChanged(
    $objectType: [ObjectType]
    $changeType: [ChangeType]
    $tenantId: Int
    $objectLockObjectType: ObjectType
  ) {
    entityChanged: entityChangend(
      objectType: $objectType
      changeType: $changeType
      tenantCode: $tenantId
      objectLockObjectType: $objectLockObjectType
    ) {
      changedBy
      changeId
      changeType
      tenantId: mandantenCode
      objCode
      objId
      objType
      subObjectType
    }
  }
`;
