import { useEventCallback } from '@mui/material/utils';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ColumnInstance,
  Row,
  UseColumnOrderState,
  UseResizeColumnsState,
} from 'react-table';
import { AutoSizer } from 'react-virtualized';
import { VariableSizeList } from 'react-window';

import { remToPx, useRemToPx } from '@work4all/data/lib/hooks/useRemToPx';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { CardConfig } from '@work4all/models/lib/table-schema/card-config';

import { reactRefSetter } from '@work4all/utils';

import { RowMeasurerCache } from '../../../../components/RowMeasurer';
import { IBasicTableProps } from '../../BasicTable';
import { useTableStateBag } from '../../hooks/useTableStateBag';
import { ICssClasses, TableMode, TableRow } from '../../types';
import { SEPARATOR_ROW_HEIGHT } from '../row-render/components/SeparatorRow/SeparatorRow';
import { RenderRow } from '../row-render/RenderRow';

import { InfiniteLoader } from './components/InfiniteLoader';

export interface ILoaderProps {
  cardsView: boolean;
  cardConfig: CardConfig | null;
  // selectedRowIds: UseRowSelectState<never>['selectedRowIds'];
  columnResizing: UseResizeColumnsState<never>['columnResizing'];
  columnOrder: UseColumnOrderState<never>['columnOrder'];
  prepareRow: (row: Row) => void;
  rows: TableRow[];
  allItemsCount?: IBasicTableProps['allItemsCount'];
  loadMoreItems?: (
    startIndex: number,
    stopIndex: number,
    rows: TableRow[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Promise<any> | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isItemLoaded?: (index: number, row: Row<any>) => boolean;
  onRowDoubleClick?: (id: string) => void;
  onRowClick?: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: TableRow
  ) => void;
  onRowContextMenu?: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    row: TableRow
  ) => void;
  // expandedRowIds: UseExpandedState<never>['expanded'];
  mode: TableMode;
  classes?: ICssClasses;
  /**
   * @default "2.25rem"
   */
  rowHeightRem?: string;
  visibleColumns: ColumnInstance[];
  customVisibleColumns?: string[];
  manualGroupBy?: boolean;
  isVirtual: boolean;
  fitContentHeight?: boolean;
  threshold?: number;
  minimumBatchSize?: number;
  width: number;
  onScroll?: (params: {
    scrollLeft: number;
    scrollWidth: number;
    clientWidth: number;
    scrollTop: number;
    scrollHeight: number;
    clientHeight: number;
  }) => void;
  /**
   * On mobile devices we show a toolbar at the bottom of the container. Pass
   * the toolbar's height here ot add as much bottom padding to the table
   * container, so the toolbar does not obstruct the last row.
   */
  bottomPadding?: string | null;

  scrollRef?: React.MutableRefObject<HTMLDivElement>;

  entity?: Entities;
  ignoreLongPress?: boolean;
  groupBy?: string[];
}

const DEFAULT_ROW_HEIGHT = '2.25rem';

/**
 * It's kinda dangerous to use React memo here:
 * Rows are lazy loaded. Rows can change even when "rows" array stay the same.
 * So, shallow comparison of React.memo doesn't work here.
 * But without optimizations of List performance is bad
 * "state" object in props guarantees that rows will update when their state change.
 *
 * Problem appears only with "selectedRowIds" state. Any other state change triggers re-rendering
 */

export const Loader = React.forwardRef<VariableSizeList, ILoaderProps>(
  function Loader(props, ref) {
    const { rows, bottomPadding } = props;

    const rowHeight = useRemToPx(props.rowHeightRem || DEFAULT_ROW_HEIGHT);

    const tableState = useTableStateBag();

    const isCardView = Boolean(props.cardsView && props.cardConfig);

    const listRef = useRef<VariableSizeList>(null);

    const isSectionStart = useCallback(
      (index: number) => {
        const row = rows[index];
        return row?.original.meta?.isSectionStart ?? false;
      },
      [rows]
    );

    const itemSize = useCallback(
      (
        index: number,
        getRowHeight: (index: number) => number = () => rowHeight
      ) => {
        const row = rows[index];

        if (row?.isGrouped) {
          return rowHeight;
        }

        if (isSectionStart(index)) {
          return getRowHeight(index) + SEPARATOR_ROW_HEIGHT;
        }

        if (isCardView) {
          return getRowHeight(index);
        }

        return rowHeight;
      },
      [rows, isCardView, rowHeight, isSectionStart]
    );

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [
      tableState?.tableInstance?.state?.expanded,
      tableState?.tableInstance?.rows,
      props.cardsView,
    ]);

    const onScrollProp = useEventCallback(props.onScroll);

    const total =
      props.allItemsCount === 'rowsLength'
        ? props.rows.length
        : props.allItemsCount;

    const [outerElement, setOuterElement] = useState<HTMLDivElement>(null);

    const setOuterRef = props.scrollRef
      ? reactRefSetter(setOuterElement, props.scrollRef)
      : setOuterElement;

    useEffect(() => {
      if (!outerElement) return;

      const handleScroll = () => {
        const {
          scrollLeft,
          scrollWidth,
          clientWidth,
          scrollTop,
          scrollHeight,
          clientHeight,
        } = outerElement;

        onScrollProp?.({
          scrollLeft,
          scrollWidth,
          clientWidth,
          scrollTop,
          scrollHeight,
          clientHeight,
        });
      };

      outerElement.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        outerElement.removeEventListener('scroll', handleScroll);
      };
    }, [onScrollProp, outerElement]);

    function renderContent({
      width,
      height,
      getRowHeight,
    }: {
      height: number | string;
      width: number | string;
      getRowHeight?: (index: number) => number;
    }) {
      if (!props.isVirtual) {
        return (
          <div ref={setOuterRef} style={{ height, width, overflow: 'auto' }}>
            {props.rows.map((_, index) => {
              return <RenderRow key={index} data={props} index={index} />;
            })}
          </div>
        );
      }

      return (
        <InfiniteLoader
          isItemLoaded={(index) => {
            const r = props.rows[index];
            if (props.isItemLoaded) {
              return props.isItemLoaded(index, r);
            }

            return Boolean(r && !r.original?.skeleton);
          }}
          itemCount={total}
          loadMoreItems={(...args) => {
            return props.loadMoreItems?.(...args, props.rows);
          }}
          mode={props.mode}
          rows={props.rows}
          threshold={props.threshold}
          minimumBatchSize={props.minimumBatchSize}
        >
          {({ onRowsRendered, registerChild }) => (
            <VariableSizeList
              style={{
                '--table-bottom-padding':
                  bottomPadding != null ? bottomPadding : undefined,
              }}
              ref={reactRefSetter(registerChild, ref, listRef)}
              width={width}
              height={height}
              itemSize={(i) => itemSize(i, getRowHeight)}
              estimatedItemSize={rowHeight}
              itemCount={total}
              overscanCount={3}
              itemData={props}
              onItemsRendered={({
                visibleStartIndex: startIndex,
                visibleStopIndex: stopIndex,
              }) => onRowsRendered?.({ startIndex, stopIndex })}
              outerRef={setOuterRef}
              innerElementType={
                bottomPadding != null ? InnerElementWithPadding : undefined
              }
            >
              {RenderRow}
            </VariableSizeList>
          )}
        </InfiniteLoader>
      );
    }
    if (props.fitContentHeight) {
      return (
        <RowMeasurerCache
          estimatedRowHeight={remToPx(3.5)}
          onResize={() => {
            listRef.current?.resetAfterIndex(0);
          }}
        >
          {({ getRowHeight }) =>
            renderContent({ width: '100%', height: null, getRowHeight })
          }
        </RowMeasurerCache>
      );
    } else if (!isCardView) {
      return <AutoSizer>{renderContent}</AutoSizer>;
    } else {
      return (
        <AutoSizer>
          {(autoSizerProps) => (
            <RowMeasurerCache
              estimatedRowHeight={remToPx(3.5)}
              onResize={() => {
                listRef.current?.resetAfterIndex(0);
              }}
            >
              {({ getRowHeight }) =>
                renderContent({ ...autoSizerProps, getRowHeight })
              }
            </RowMeasurerCache>
          )}
        </AutoSizer>
      );
    }
  }
);

// Add a padding at the bottom of the list on mobile so that the floating
// buttons do not obstruct the last row.
export const InnerElementWithPadding = forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(function InnerElementWithPadding({ style, ...rest }, ref) {
  return (
    <div
      ref={ref}
      style={{
        ...style,
        height: `calc(${
          style.height as number
        }px + var(--table-bottom-padding, 0px))`,
      }}
      {...rest}
    />
  );
});
