import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton } from '@mui/material';
import { ChangeEventHandler, useState } from 'react';

import { parseAsURL } from '@work4all/components/lib/input/labeled-input/parse-as-url';

import { LabeledInput } from '../../../../locked-inputs';

import { SimpleCustomFieldProps } from './types';

export function SimpleCustomTextField(props: SimpleCustomFieldProps) {
  const { onBlur, onChange, value, ...others } = props;
  const [isFocused, setIsFocused] = useState(false);
  const url = parseAsURL(value);

  return (
    <LabeledInput
      {...others}
      value={value}
      onChange={
        onChange as ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
      }
      endAdornment={
        url &&
        !isFocused && (
          <IconButton
            component="a"
            href={url}
            size="small"
            target="_blank"
            rel="noopener noreferrer"
          >
            <OpenInNewIcon color="primary" />
          </IconButton>
        )
      }
      onFocus={() => setIsFocused(true)}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(event);
        setIsFocused(false);
      }}
    />
  );
}
