import styles from './UserIcon.module.scss';

import PersonIcon from '@mui/icons-material/Person';
import { Avatar } from '@mui/material';
import clsx from 'clsx';
import React from 'react';

import { Body2 } from '../../typography/body2/Body2';

interface IUserIconInternal {
  shortName?: string;
  placeholder?: boolean;
  src?: string;
  bgColor?: string;
  fontColor?: string;
  size?: 'm' | 'l' | 'l-bold' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl' | 'xxxxxl';
  /**
   * If `true`, will display a solid border around the avatar image matching the
   * user's avatar fallback color.
   * */
  withBorder?: boolean;
  className?: string;
}

export type IUserIcon =
  | ({ shortName: string } & IUserIconInternal)
  | ({ placeholder: boolean } & IUserIconInternal);

export const UserIcon: React.FC<IUserIcon> = (props) => {
  const {
    shortName,
    src,
    size = 'l',
    bgColor,
    fontColor,
    withBorder = false,
    placeholder,
    className,
  } = props;

  return (
    <Avatar
      sx={{
        bgcolor: bgColor,
        border: withBorder ? `3px solid ${bgColor}` : undefined,
      }}
      className={clsx(
        styles.avatar,
        styles[size],
        {
          [styles.placeholder]: placeholder,
        },
        className
      )}
      src={src}
    >
      {placeholder ? (
        <PersonIcon />
      ) : (
        <Body2 className={clsx(styles.shortName)}>
          <span
            style={{
              color: fontColor,
            }}
          >
            {shortName}
          </span>
        </Body2>
      )}
    </Avatar>
  );
};
