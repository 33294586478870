import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';
import { VacationEntitlement } from '@work4all/models/lib/Classes/VacationEntitlement.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface VacationStats {
  year: number;
  base: number;
  carriedOver: number;
  total: number;
  used: number;
  remaining: number;
}

export function useVacationStats({
  userId,
  year,
}: {
  userId: number;
  year: number;
}) {
  const requestVacationEntitlement = useMemo(() => {
    const data: VacationEntitlement = {
      id: null,
      jahr: null,
      anspruch: null,
      vorjahr: null,
      bisJetzt: null,
    };

    const request: DataRequest = {
      operationName: 'GetUserVacationEntitlement',
      entity: Entities.vacationEntitlement,
      data,
      vars: {
        years: [year],
        userCode: userId,
      },
      completeDataResponse: true,
    };

    return request;
  }, [userId, year]);

  const responseVacationEntitlement = useDataProvider<VacationEntitlement>(
    requestVacationEntitlement
  );

  const requestVacationTaken = useMemo(() => {
    const data: Vacation = {
      id: null,
      amount: null,
      simpleVacationKind: {
        id: null,
        demandReducing: null,
      },
    };

    const request: DataRequest = {
      entity: Entities.vacation,
      data,
      filter: [
        { userId: { $eq: userId } },
        {
          date: {
            $gt: DateTime.now().set({ year }).startOf('year').toISO(),
          },
        },
        {
          date: {
            $lt: DateTime.now().set({ year }).endOf('year').toISO(),
          },
        },
      ],
    };

    return request;
  }, [userId, year]);

  const responseVacationTaken = useDataProvider<Vacation>(requestVacationTaken);
  const vacationTaken = responseVacationTaken.data.filter(
    (vacation) => !vacation.simpleVacationKind?.demandReducing
  );
  const refetch = responseVacationEntitlement.refetch;

  const stats = useMemo<VacationStats>(() => {
    const found = responseVacationEntitlement.data?.[0];

    const base = found?.anspruch ?? 0;
    const carriedOver = found?.vorjahr ?? 0;
    const used = vacationTaken.length
      ? vacationTaken?.map((x) => x.amount)?.reduce((a, b) => a + b)
      : 0;

    const total = base + carriedOver;
    const remaining = total - used;

    return {
      year,
      base,
      carriedOver,
      total,
      used,
      remaining,
    };
  }, [responseVacationEntitlement.data, vacationTaken, year]);

  return {
    stats,
    refetch,
    isLoading: responseVacationTaken.loading,
  };
}
