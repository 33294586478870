import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import {
  TableRowDisplayModifiers,
  useDialogs,
  useTableStateBag,
} from '@work4all/components';
import { PrepareTableRowModifiers } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useRowDisplayModifiers';

import { useDataMutation, useUser } from '@work4all/data';
import { useSetting } from '@work4all/data/lib/settings';

import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { PaymentStatus } from '@work4all/models/lib/Enums/PaymentStatus.enum';
import { RePermitStatus } from '@work4all/models/lib/Enums/RePermitStatus.enum';

import { CommentProvider } from '@work4all/utils';
import {
  canApproveOwnInboundInvoices,
  canForwardInboundInvoices,
} from '@work4all/utils/lib/permissions';

import { RE_APPROVAL_ENTITY } from '../../../containers/approval/containers/invoices/InvoicesApprovalContainer';
import { useApendCustomListSettings } from '../../../containers/file-entities-lists/useApendCustomListSettings';
import { ErpPreviewContainer } from '../../../containers/files/detail/components/file-preview-panel/containers/ErpPreviewContainer';
import { useOpenMask } from '../../../containers/mask-overlays/mask-overlay/hooks/use-open-mask';
import { usePageTitle } from '../../../hooks';
import { settings } from '../../../settings';
import { useBottomViews } from '../../bottom-views';
import {
  ApprovalContextProvider,
  ApprovalStatuses,
  useApprovalActions,
} from '../approval-actions';
import { DueDaysCell } from '../DueDaysCell';
import { EntityActionProvider } from '../entity-table/EntityActionProvider';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { GridStateProvider } from '../grid/GridStateProvider';
import { PaymentStatusCell } from '../PaymentStatusCell';
import { PreviewMobileWrapper } from '../PreviewMobileWrapper';
import schema from '../schemata/reApproval-table-schema.json';
import { useTableLayoutState } from '../table-layout';
import { useEntityHandlers } from '../use-entity-handlers';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';
import { useUserColumnConfigs } from '../use-user-column-configs';

import { ReStatusCardIconCell } from './cells/ReStatusCardIconCell';
import { ReStatusCell } from './cells/ReStatusCell';
import { ReApprovalDialog } from './reApprovalDialog';
import { ForwardConfirmDialogData } from './types';
import { mapSelectedRowStatus } from './utils';

const defaultSort = [
  { field: 'invoiceDate', direction: SortDirection.DESCENDING },
  { field: 'invoiceNumber', direction: SortDirection.DESCENDING },
];

const DISABLED_COLUMNS = ['unused'];

const forceRequestFields: ReViewModel = {
  approvedByUser: {
    avatarUrl: null,
  },
};

export const ReApprovalTable = React.forwardRef<TableInstance, IEntityTable>(
  function ReApprovalTableInner(props, ref) {
    const { t } = useTranslation();

    const user = useUser();
    const tableStateBag = useTableStateBag();
    const template = useErpHandlersTemplate('supplier.id', Entities.supplier);
    const layoutState = useTableLayoutState();
    const [layout] = layoutState;
    const dialogs = useDialogs();
    const isDesktop = useMediaQuery<Theme>((theme) =>
      theme.breakpoints.up('xl')
    );

    const entityType = Entities.reViewModel;

    const dataTable = useEntityDataTable<ReViewModel, never>({
      schema: schema as never,
      defaultSort,
      enableFooter: true,
      cells: {
        PaymentStatus: PaymentStatusCell,
        PermitStatus: ReStatusCell,
        PermitStatusCardIcon: ReStatusCardIconCell,
        DueDays: DueDaysCell,
      },
      forceRequestFields,
    });

    const bottomEreaPrefilter = [{ id: { $eq: dataTable.selectedEntity?.id } }];
    const bottomArea = useBottomViews({
      skip: !dataTable.selectedEntity,
      size: 'fill',
      views: [
        {
          type: 'table',
          entity: Entities.rELedgerAccountSplit,
          titleTranslationKey: 'COMMON.BOOKINGS',
          options: {
            prefilter: bottomEreaPrefilter,
          },
        },
      ],
    });

    const [mutate] = useDataMutation({
      entity: Entities.inboundInvoice,
      mutationType: EMode.upsert,
      responseData: { id: null },
      translateRelationInput: false,
      onCompleted: () => {
        dataTable?.refetch();
      },
    });

    const { set: setPreviewVisible } = useSetting(
      settings.tableRightAreaVisible({
        entityType,
      })
    );

    const handleStatusChange = (id: string, status: ApprovalStatuses) => {
      const { id: dialogId } = dialogs.open(ReApprovalDialog, {
        status,
        onClose: () => dialogs.close(dialogId),
        onConfirm: async (value) => {
          if (status === ApprovalStatuses.approve) {
            mutate({
              id: Number(id),
              releasedByUserId: user.benutzerCode,
              releaseNote: value.comment,
            });
          } else if (status === ApprovalStatuses.reject) {
            mutate({
              id: Number(id),
              blockedByUserId: user.benutzerCode,
              releaseNote: value.comment,
            });
          } else {
            mutate({
              id: Number(id),
              approvedByUserId: (value as ForwardConfirmDialogData).user.id,
              releaseNote: value.comment,
            });
          }
          dialogs.close(dialogId);
        },
      });
    };

    const entries = dataTable.selectedEntities ?? [];
    const entityIds = entries.map((e) => e.id);

    const onOpenMask = useOpenMask({ entityType });
    const entityHandlers = useEntityHandlers({
      entity: entityType,
      onOpenMask,
      entries,
    });

    const canApprove = canApproveOwnInboundInvoices(
      user,
      dataTable?.selectedEntity as ReViewModel
    );
    const canForward = canForwardInboundInvoices(user);

    const { approvalActionDefinitions } = useApprovalActions({
      seletedRowStatus: mapSelectedRowStatus(
        dataTable?.selectedEntity?.permitStatus
      ),
      canApprove,
      canForward,
      editHandler: entityHandlers.edit.handler,
      statusHandler: handleStatusChange,
    });

    const [userConfig, userConfigMethods] = useUserColumnConfigs({
      layout,
      entityType: 'reApproval',
      columnConfigs: dataTable?.columnConfigs,
    });

    const customActions = {
      hideDivider: true,
      left: approvalActionDefinitions,
    };

    const prepareRowDisplayModifiers = useCallback<
      PrepareTableRowModifiers<ReViewModel>
    >((row) => {
      const isApproved = row.permitStatus === RePermitStatus.APPROVED;
      const isRejected = row.permitStatus === RePermitStatus.REJECTED;
      const isPayed =
        row.paymentStatus === PaymentStatus.PAYED ||
        row.paymentStatus === PaymentStatus.PAYED_CREDIT ||
        row.paymentStatus === PaymentStatus.ZERO_INVOICE;
      const isOpenButPayed =
        row.permitStatus === RePermitStatus.EMPTY && isPayed;
      const isShade1 = isApproved || isRejected || isOpenButPayed;

      const modifiers: TableRowDisplayModifiers = { isShade1 };

      return modifiers;
    }, []);

    usePageTitle(t('APPROVAL_CENTER.REVIEWMODEL.PAGE_TITLE'));
    useApendCustomListSettings(entityType);

    return (
      <GridStateProvider>
        <ApprovalContextProvider value={{ onClick: handleStatusChange }}>
          <EntityTable
            ref={ref}
            template={template}
            areas={{
              bottom: bottomArea,
              right: {
                content: entries.length > 0 && (
                  <EntityActionProvider entity={entityType}>
                    {() => (
                      <PreviewMobileWrapper
                        active={!isDesktop}
                        actions={{
                          edit: undefined,
                          remove: undefined,
                          custom: customActions,
                        }}
                        entityIds={entityIds}
                      >
                        <Stack direction="row" height="100%">
                          <Box width="100%">
                            <CommentProvider>
                              <ErpPreviewContainer
                                onCloseClick={() =>
                                  tableStateBag.tableInstance.toggleAllRowsSelected(
                                    false
                                  )
                                }
                                onVisibilityToggle={setPreviewVisible}
                                onEditClicked={entityHandlers.edit?.handler}
                                entityIds={entityIds}
                                entityType={entityType}
                                openInFullscreen={false}
                                showEditIcon={isDesktop}
                                showFullscreenIcon={false}
                                showDownloadIcon={false}
                                isApprovalCenter
                              />
                            </CommentProvider>
                          </Box>
                        </Stack>
                      </PreviewMobileWrapper>
                    )}
                  </EntityActionProvider>
                ),
              },
            }}
            {...dataTable}
            settingsEntityType={RE_APPROVAL_ENTITY}
            displayFooter
            overrides={{
              actions: {
                custom: customActions,
                resetColumns: userConfigMethods?.remove,
              },
            }}
            disabledColumns={DISABLED_COLUMNS}
            columnConfigs={userConfig ?? []}
            selectableMultiple={false}
            prepareRowDisplayModifiers={prepareRowDisplayModifiers}
          />
        </ApprovalContextProvider>
      </GridStateProvider>
    );
  }
);
