import styles from '../PositionPicker.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import { useEventCallback } from '@mui/material/utils';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableInstance } from 'react-table';

import { ColumnInstance, useTableStateBag } from '@work4all/components';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { useTransformedArticleTableSchema } from '../../../../../../../../../../../components/data-tables/article-table/use-transformed-article-table-schema';
import schema from '../../../../../../../../../../../components/data-tables/schemata/positions-article-table-schema.json';
import { Table } from '../../../../../../../../../../../components/data-tables/table/Table';
import { useDataTable } from '../../../../../../../../../../../components/data-tables/use-data-table';
import { useUserColumnConfigs } from '../../../../../../../../../../../components/data-tables/use-user-column-configs';
import { useMaskContext } from '../../../../../../../../hooks/mask-context';
import { ARTICLE_POSITIONS_DATA } from '../../constants';

export type ArticlesTableProps = {
  searchString: string;
  groups: number[];
  disabled: boolean;
  onSelectedChange: (articles: Article[]) => void;
  onRowDoubleClick: (article: Article) => void;
  setResetColumns: (resetColumns: () => void) => void;
  customerId?: number;
};

type SelectedGroupedArticle = {
  subRows: (Article & { skeleton?: boolean })[];
};

const defaultSort = [{ field: 'name', direction: SortDirection.ASCENDING }];

const prefilter = undefined;

export const PositionArticlesTable = React.forwardRef<
  TableInstance,
  ArticlesTableProps
>(function ArticlesTable(props: ArticlesTableProps, ref) {
  const {
    searchString,
    groups,
    onSelectedChange,
    onRowDoubleClick,
    disabled,
    setResetColumns,
    customerId,
  } = props;

  const { t } = useTranslation();

  const transformedSchema = useTransformedArticleTableSchema({
    schema,
    customerId,
  });

  const tableStateBag = useTableStateBag();

  // Custom actions
  const handleSelectedRowsChange = useCallback(
    (rows: Row<Article>[]) => {
      if (onSelectedChange) {
        const articles = rows.map((row) => row.original);
        onSelectedChange(articles);
      }
    },
    [onSelectedChange]
  );

  useEffect(() => {
    if (!searchString || searchString.length === 0) {
      tableStateBag.setSearchFilterText('');
    } else {
      tableStateBag.setSearchFilterText(searchString);
    }
  }, [searchString, tableStateBag]);
  const groupColumn = tableStateBag.columnsById['groupId'];

  useEffect(() => {
    if (!groupColumn || !groups) {
      return;
    }
    if (groups.length === 0 || groups.includes(0)) {
      groupColumn.setFilter(null);
    } else {
      groupColumn.setFilter({
        value: groups.map((id) => ({ id })),
        filterType: groupColumn.filterType,
      });
    }
  }, [groups, groupColumn]);

  const isMdDown = useMediaQuery<Theme>((t) => t.breakpoints.down('md'));
  const layout = isMdDown ? 'cards' : 'table';

  const forceRequestFields: Article = useMemo(() => {
    return {
      netPrice: null,
      netPrice2: null,
      netPrice3: null,
      articlePrices: {
        singlePriceList: [
          {
            id: null,
            price: null,
            priceGroupId: null,
          },
        ],
        customerIndividualPriceList: [
          {
            id: null,
            articleId: null,
            netPrice: null,
            customerId: null,
            currency: {
              id: null,
              name: null,
            },
          },
        ],
      },
      hasBulkPrices: null,
      bulkPriceList: [
        {
          id: null,
          sdObjMemberCode: null,
          price: null,
          bulkPriceTierItem: {
            id: null,
            name: null,
            edgeValue1: null,
            edgeValue2: null,
          },
          priceGroupId: null,
        },
      ],
      internalArticle: null,
      ...ARTICLE_POSITIONS_DATA,
    };
  }, []);

  const {
    columnConfigs,
    prepareRowDisplayModifiers,
    data,
    fetchMore,
    total,
    initialSortBy,
    pending,
  } = useDataTable<Article, never>({
    layout,
    schema: transformedSchema as never,
    tableStateBag,
    prefilter,
    defaultSort,
    forceRequestFields,
  });

  useEffect(() => {
    if (!tableStateBag.tableInstance) return;
    const col = tableStateBag.tableInstance.allColumns.find(
      (x) => x.id === 'isShutDown'
    ) as ColumnInstance;
    col.setFilter({
      filterType: col.filterType,
      value: [
        {
          id: false,
          name: t('BOOLEAN_FILTER.false'),
        },
      ],
    });
  }, [t, tableStateBag.tableInstance]);

  const { id } = useMaskContext();

  const getTooltipLabel = useCallback(
    (article) => {
      if (`${article.id}` === `${id}`) {
        return t('MASK.ARTICLE.SELF_BOM');
      }
      return '';
    },
    [id, t]
  );

  const rowModifiers = useCallback(
    (value: Article) => {
      const modifiers = prepareRowDisplayModifiers(value);
      const tooltip = getTooltipLabel?.(value);
      return {
        ...modifiers,
        isFaded: value.isShutDown,
        isShade1: !!tooltip,
        tooltip: tooltip,
      };
    },
    [prepareRowDisplayModifiers, getTooltipLabel]
  );

  const [userConfig, handlers] = useUserColumnConfigs({
    layout,
    // Override entity to have separate config
    entityType: 'PositionsArticle',
    columnConfigs,
  });

  useEffect(() => {
    setResetColumns(handlers.remove);
  }, [handlers, setResetColumns]);

  const onSelect = useEventCallback((id: string) => {
    const getArticles = (data) => {
      const isGrouped = data[0]?.isGrouped as boolean;

      if (isGrouped) {
        return getArticles(
          (data as SelectedGroupedArticle[]).flatMap((group) => {
            return (group.subRows ?? []).filter((row) => !row?.skeleton);
          })
        );
      }

      return data as Article[];
    };

    const articles: Article[] = getArticles(data);

    if (Array.isArray(id)) {
      const lookupIds = id.map((x) => parseInt(x));
      const article = articles.find((x) => lookupIds.includes(x.id));
      if (article) onRowDoubleClick(article);
    } else {
      const lookupId = parseInt(id);
      const article = articles.find((x) => x.id === lookupId);
      if (article) onRowDoubleClick(article);
    }
  });

  if (!userConfig) return null;
  return (
    <Table
      ref={ref}
      pending={pending}
      columnConfigs={userConfig}
      manualGroupBy={true}
      initialSortBy={initialSortBy}
      loadMoreItems={fetchMore}
      prepareRowDisplayModifiers={rowModifiers}
      data={data}
      total={total}
      hideToolbar
      draggable={!disabled}
      selectableMultiple={false}
      onRowDoubleClick={(id) => {
        if (disabled) return true;
        onSelect(id as string);
        return true;
      }}
      actions={
        disabled
          ? undefined
          : {
              edit: {
                handler: onSelect,
              },
            }
      }
      classes={{
        root: disabled
          ? clsx(styles.table, styles.tableDisabled)
          : styles.table,
      }}
      basicClasses={{
        headerRow: styles.background,
        row: styles.background,
        headerCellRoot: styles.background,
        tableBody: styles.background,
      }}
      onSelectedRowsChange={disabled ? undefined : handleSelectedRowsChange}
    />
  );
});
