import styles from './General.module.scss';

import { useTranslation } from 'react-i18next';

import { PROJECT_DATA } from '@work4all/components/lib/components/entity-picker/project-picker/projectPickerPresets';
import { Card } from '@work4all/components/lib/dataDisplay/card';

import { CostCenterPickerField } from '../../../../../../components/entity-picker/CostCenterPickerField';
import { CurrencyPickerField } from '../../../../../../components/entity-picker/CurrencyPickerField';
import { ProjectPickerField } from '../../../../../../components/entity-picker/ProjectPickerField';
import { ControllerPlus } from '../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../form-plus/use-form-context-plus';
import { DateTimeInputPicker } from '../../../../locked-inputs';
import { Collapse, ControlWrapper } from '../../../components';
import { TravelReceiptsFormValue } from '../type';

export const Others = () => {
  const { t } = useTranslation();

  const form = useFormContextPlus<TravelReceiptsFormValue>();
  const { register, control } = form;

  return (
    <Collapse
      title={t('MASK.MISCELLANEOUS')}
      className={styles.others}
      defaultOpen
    >
      <Card>
        <ControlWrapper columns={2}>
          <DateTimeInputPicker
            dateLabel={t('INPUTS.DOCUMENT_DATE')}
            withTime={false}
            clearable={false}
            {...register('date')}
          />
        </ControlWrapper>

        <ControlWrapper columns={2}>
          <ControllerPlus
            name="costCenter"
            control={control}
            render={({ field }) => {
              return (
                <CostCenterPickerField
                  label={t('COMMON.COST_CENTER')}
                  {...field}
                />
              );
            }}
          />

          <ControllerPlus
            name="currency"
            control={control}
            render={({ field, fieldState }) => {
              return (
                <CurrencyPickerField
                  {...field}
                  error={fieldState?.error?.message}
                  onChange={(value) => {
                    field.onChange(value);
                  }}
                />
              );
            }}
          />
        </ControlWrapper>

        <ControlWrapper columns={1}>
          <ControllerPlus
            name="project"
            control={control}
            render={({ field: project }) => {
              return (
                <ProjectPickerField
                  {...project}
                  label={t('INPUTS.PROJECT')}
                  data={PROJECT_DATA}
                  clearable
                />
              );
            }}
          />
        </ControlWrapper>
      </Card>
    </Collapse>
  );
};
