import { createContext } from 'react';

import { BusinessPartner } from '@work4all/models/lib/Classes/BusinessPartner.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export interface OpenEmailMaskControllerProps {
  businessPartner: BusinessPartner;
  project?: Project;
  contactId?: number;
  email?: string;
  entityType?: Entities;
}

export const OpenEmailMaskControllerContext = createContext<
  (value: OpenEmailMaskControllerProps) => void
>(() => {});
