import style from '../ErpDialogPositions.module.scss';

import { useMemo } from 'react';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { EditTable } from '../../../../../containers/mask-overlays/mask-overlay/views/erp/components/tab-panels/positions/components/edit-table/EditTable';
import { NOT_EDITABLE_ROWS } from '../../consts';
import { GroupedPosition } from '../../types';
import { ErpDialogPositionsProps } from '../ErpDialogPositions';

import { useGroupedPositions } from './use-grouped-positions';

interface ErpDialogGroupedPositionsProps
  extends Omit<ErpDialogPositionsProps, 'entry'> {
  positionList: GroupedPosition[];
}

export const ErpDialogGroupedPositions = (
  props: ErpDialogGroupedPositionsProps
) => {
  const { positionList, onItemsChanged, ...collapseProps } = props;
  const result = useGroupedPositions({
    positionList,
    onItemsChanged,
  });

  const selectedRowIds = useMemo(() => {
    return result.items.reduce((prev, cur) => {
      prev[cur.localId] = true;
      return prev;
    }, {} as Record<string, boolean>);
  }, [result.items]);

  return (
    <Collapse {...collapseProps}>
      <EditTable<GroupedPosition>
        classes={{
          tbody: style.tbody,
        }}
        rowSizeMode={result.erpSize.value}
        showSelectionColumn
        fitSpace
        editableRows={NOT_EDITABLE_ROWS}
        initialState={{
          selectedRowIds,
        }}
        {...result}
      />
    </Collapse>
  );
};
