import { Close } from '@mui/icons-material';
import { Button, Grid, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { settings, useSetting } from '../../../../../../../../../../settings';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { FullAddressPicker } from '../../../../full-address-picker/FullAddressPicker';

interface Props {
  displayAsSection?: boolean;
}

export const ParticipantsAndAddresses: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { displayAsSection = false } = props;

  const { watch, setValue } = useFormContext();

  const businessPartner = watch(
    'businessPartnerContactCombined.businessPartner'
  );
  const address1 = watch('additionalAddress1.businessPartner');
  const address2 = watch('additionalAddress2.businessPartner');
  const address3 = watch('additionalAddress3.businessPartner');

  const contact = watch('businessPartnerContactCombined.contact');
  const address1Contact = watch('additionalAddress1.contact');
  const address2Contact = watch('additionalAddress2.contact');
  const address3Contact = watch('additionalAddress3.contact');

  const businessPartnerType = watch('businessPartnerType');
  const additionalAddress1CompanyType = watch('additionalAddress1CompanyType');
  const additionalAddress2CompanyType = watch('additionalAddress2CompanyType');
  const additionalAddress3CompanyType = watch('additionalAddress3CompanyType');

  const businessPartnerText = watch('businessPartnerText');
  const additionalAddress1Text = watch('additionalAddress1Text');
  const additionalAddress2Text = watch('additionalAddress2Text');
  const additionalAddress3Text = watch('additionalAddress3Text');

  const project = watch('project');

  const erpAdditionalAddress3Name = useSetting(
    settings.erpAdditionalAddress3Name()
  );

  const erpShowAdditionalAddress3 = useSetting(
    settings.erpShowAdditionalAddress3()
  );

  const [editMode, setEditMode] = useState(false);

  const mask = useMaskContext();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const content = useMemo(() => {
    return (
      <Grid container spacing="1rem">
        <Grid
          item
          md={erpShowAdditionalAddress3.value ? 3 : 4}
          xs={12}
          position="relative"
        >
          <FullAddressPicker
            entity={mask.entity}
            businessPartner={businessPartner?.data}
            project={project}
            contact={contact}
            businessPartnerType={businessPartnerType}
            addressString={businessPartnerText}
            expanded={editMode}
            onExpandChange={(expanded) => setEditMode(expanded)}
            onChange={(value) => {
              value.businessPartner &&
                setValue(
                  'businessPartnerContactCombined.businessPartner',
                  value.businessPartner,
                  { shouldDirty: true, shouldValidate: true }
                );
              value.contact !== undefined &&
                setValue(
                  'businessPartnerContactCombined.contact',
                  value.contact,
                  { shouldDirty: true, shouldValidate: true }
                );
              value.addressString &&
                setValue('businessPartnerText', value.addressString, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
            }}
            disableBusinessPartnerField={mask.isEditMode}
          />
        </Grid>

        {![Entities.inboundDeliveryNote].includes(mask.entity) && (
          <>
            <Grid item md={erpShowAdditionalAddress3.value ? 3 : 4} xs={12}>
              {
                <FullAddressPicker
                  label={t('COMMON.DELIVERY_ADDRESS')}
                  entity={mask.entity}
                  businessPartner={address1?.data}
                  project={project}
                  contact={address1Contact}
                  businessPartnerType={additionalAddress1CompanyType}
                  addressString={additionalAddress1Text}
                  compareBusinessPartnerId={businessPartner?.data?.id}
                  compareContactId={contact?.id}
                  compareAddressString={businessPartnerText}
                  combinedBusinessPartner
                  expanded={editMode}
                  onExpandChange={(expanded) => setEditMode(expanded)}
                  onChange={(value) => {
                    value.businessPartner &&
                      setValue(
                        'additionalAddress1.businessPartner',
                        value.businessPartner,
                        { shouldDirty: true, shouldValidate: true }
                      );
                    value.contact !== undefined &&
                      setValue('additionalAddress1.contact', value.contact, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    value.addressString &&
                      setValue('additionalAddress1Text', value.addressString, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                  }}
                  disableBusinessPartnerField={!businessPartner}
                />
              }
            </Grid>
            <Grid item md={erpShowAdditionalAddress3.value ? 3 : 4} xs={12}>
              {
                <FullAddressPicker
                  label={t('COMMON.INVOICE_ADDRESS')}
                  entity={mask.entity}
                  businessPartner={address2?.data}
                  project={project}
                  contact={address2Contact}
                  businessPartnerType={additionalAddress2CompanyType}
                  addressString={additionalAddress2Text}
                  compareBusinessPartnerId={businessPartner?.data?.id}
                  compareContactId={contact?.id}
                  compareAddressString={businessPartnerText}
                  combinedBusinessPartner
                  expanded={editMode}
                  onExpandChange={(expanded) => setEditMode(expanded)}
                  onChange={(value) => {
                    if (value.businessPartner) {
                      if (mask.isEditMode) {
                        enqueueSnackbar(
                          t('WARNINGS.CHANGING_BILLING_ADDRESS'),
                          {
                            variant: 'warning',
                            autoHideDuration: 6000,
                            action: (id) => {
                              return (
                                <>
                                  <Button
                                    sx={{
                                      color: 'var(--ui01)',
                                    }}
                                    onClick={() => closeSnackbar(id)}
                                  >
                                    {t('COMMON.OK')}
                                  </Button>

                                  <IconButton
                                    sx={{
                                      color: 'var(--ui01)',
                                    }}
                                    onClick={() => closeSnackbar(id)}
                                  >
                                    <Close />
                                  </IconButton>
                                </>
                              );
                            },
                          }
                        );
                      }

                      setValue(
                        'additionalAddress2.businessPartner',
                        value.businessPartner,
                        { shouldDirty: true, shouldValidate: true }
                      );
                    }

                    value.contact !== undefined &&
                      setValue('additionalAddress2.contact', value.contact, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    value.addressString &&
                      setValue('additionalAddress2Text', value.addressString, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                  }}
                  disableBusinessPartnerField={!businessPartner}
                />
              }
            </Grid>

            {erpShowAdditionalAddress3.value ? (
              <Grid item md={erpShowAdditionalAddress3.value ? 3 : 4} xs={12}>
                {
                  <FullAddressPicker
                    label={
                      erpAdditionalAddress3Name.value.trim() ||
                      t('ERP.ADDITIONAL_ADDRESS_DEFAULT_NAME')
                    }
                    entity={mask.entity}
                    businessPartner={address3?.data}
                    project={project}
                    contact={address3Contact}
                    businessPartnerType={additionalAddress3CompanyType}
                    addressString={additionalAddress3Text}
                    compareBusinessPartnerId={businessPartner?.data?.id}
                    compareContactId={contact?.id}
                    compareAddressString={businessPartnerText}
                    combinedBusinessPartner
                    expanded={editMode}
                    onExpandChange={(expanded) => setEditMode(expanded)}
                    onChange={(value) => {
                      value.businessPartner &&
                        setValue(
                          'additionalAddress3.businessPartner',
                          value.businessPartner,
                          { shouldDirty: true, shouldValidate: true }
                        );
                      value.contact !== undefined &&
                        setValue('additionalAddress3.contact', value.contact, {
                          shouldDirty: true,
                          shouldValidate: true,
                        });
                      value.addressString &&
                        setValue(
                          'additionalAddress3Text',
                          value.addressString,
                          {
                            shouldDirty: true,
                            shouldValidate: true,
                          }
                        );
                    }}
                    disableBusinessPartnerField={!businessPartner}
                  />
                }
              </Grid>
            ) : null}
          </>
        )}
      </Grid>
    );
  }, [
    additionalAddress1CompanyType,
    additionalAddress1Text,
    additionalAddress2CompanyType,
    additionalAddress2Text,
    additionalAddress3CompanyType,
    additionalAddress3Text,
    address1,
    address1Contact,
    address2,
    address2Contact,
    address3,
    address3Contact,
    businessPartner,
    businessPartnerText,
    businessPartnerType,
    closeSnackbar,
    contact,
    editMode,
    enqueueSnackbar,
    erpAdditionalAddress3Name.value,
    erpShowAdditionalAddress3.value,
    mask.entity,
    mask.isEditMode,
    project,
    setValue,
    t,
  ]);

  return displayAsSection ? (
    <Collapse defaultOpen={true} title={t('MASK.PARTICIPANTS_AND_ADDRESSES')}>
      {content}
    </Collapse>
  ) : (
    content
  );
};
