import { useMemo } from 'react';

import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import {
  ProjectPlanningColumnsProps,
  useProjectPlanningColumns,
} from '../../planning-columns/use-project-planning-columns';
import jsonSchema from '../../schemata/project-table-schema.json';

interface IProps {
  projectPlanningProps: Pick<
    ProjectPlanningColumnsProps,
    'onCellSelect' | 'onUtilizationClick' | 'selectedRessourceClassCell'
  >;
}

const initialschema = jsonSchema as unknown as ITableSchema<never>;

export const useProjectTableSchema = (props: IProps) => {
  const { projectPlanningProps } = props;

  const projectPlanningColumns = useProjectPlanningColumns({
    ...projectPlanningProps,
  });

  const schema = useMemo(() => {
    return {
      ...initialschema,
      columns: [...initialschema.columns, ...projectPlanningColumns],
    } as never;
  }, [projectPlanningColumns]);

  return schema;
};
