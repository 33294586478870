import { useDataProvider } from '@work4all/data';

import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

const req: DataRequest = {
  entity: Entities.appointmentState,
  data: {
    id: null,
    name: null,
  } as AppointmentState,
  completeDataResponse: true,
};

export const useAppointmentStates = () => {
  return useDataProvider<AppointmentState>(req);
};
