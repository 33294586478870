import { useCallback } from 'react';

import { ErpPositionsKind } from '@work4all/models/lib/Enums/ErpPositionsKind.enum';

import { InfiniteRow } from '../InfiniteList';
import { EditTableEntry, EditTablePropsAll } from '../types';

import { STUECKLISTE_GROUP } from './use-editable-state';

interface UseEditableRows<T extends EditTableEntry>
  extends Pick<
    EditTablePropsAll<T>,
    'decorators' | 'editableRows' | 'onMovePosition'
  > {
  data: React.MutableRefObject<T[]>;
  allCells: string[];
}

export function useEditableRow<T extends EditTableEntry>(
  options: UseEditableRows<T>
) {
  const {
    decorators,
    editableRows,
    data,
    onMovePosition: onMove,
    allCells,
  } = options;

  const isParentSelected = useCallback(
    (isChild: boolean, row: InfiniteRow<T>, rowData: InfiniteRow<T>[]) => {
      let _isParentSelected = false;
      if (isChild) {
        const parent = rowData.find(
          (x) => x.original.id?.toString() === row.original.posId.toString()
        );
        _isParentSelected = parent?.isSelected;
      }
      return _isParentSelected;
    },
    []
  );

  const calculateRowMove = useCallback(
    (row: InfiniteRow<T>, rowData: InfiniteRow<T>[]) => {
      const isMoveAllowed = (from: T, to: T) => {
        if (
          (to && from && to.posId) ||
          (STUECKLISTE_GROUP.includes(to.positionKind) && !to.collapsed)
        ) {
          if (to.posId) {
            // check if it is last of BOM
            const nextItem = rowData.find(
              (x) => x.original.index === to.index + 1
            )?.original;
            // only in down direction
            if (!nextItem?.posId && to.index > from.index) {
              return true;
            }
          }
          // in top direction moving before BOM is allowed
          if (
            to.index < from.index &&
            STUECKLISTE_GROUP.includes(to.positionKind)
          )
            return true;
          return from.positionKind === ErpPositionsKind.STANDARD;
        }

        return true;
      };

      const canDrop = (input: { id: string; index: number }): number => {
        if (!input) return 0;
        const from = data.current.find((x) => x.localId === input.id);
        return isMoveAllowed(from, row.original) ? input.index - row.index : 0;
      };

      const handleMoveRow = (id: string, index: number) => {
        const from = data.current.find((x) => x.localId === id);
        const toPosition = data.current[index];
        if (isMoveAllowed(from, toPosition)) {
          onMove(id, index);
        }
      };

      return {
        handleMoveRow,
        canDrop,
      };
    },
    [data, onMove]
  );

  const calculateEditableCols = useCallback(
    (row: T) => {
      const relation = decorators?.getRelation
        ? decorators.getRelation(row)
        : 'normal';
      const isParent = relation === 'parent';
      const isNormal = relation === 'normal';
      const isChild = relation === 'child';

      const editableCols = (editableRows[row.positionKind] ||
        allCells.filter((x) =>
          decorators?.isEditableCell ? decorators.isEditableCell(x, row) : true
        )) as string[];

      return {
        isParent,
        isNormal,
        isChild,
        editableCols,
      };
    },
    [allCells, decorators, editableRows]
  );

  const calculateRowProperties = useCallback(
    (row: InfiniteRow<T>, rowData: InfiniteRow<T>[]) => {
      const cols = calculateEditableCols(row.original);

      return {
        ...cols,
        ...calculateRowMove(row, rowData),
        isParentSelected: isParentSelected(cols.isChild, row, rowData),
      };
    },
    [calculateEditableCols, calculateRowMove, isParentSelected]
  );
  return {
    calculateRowProperties,
    calculateEditableCols,
  };
}
