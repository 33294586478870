import { Typography } from '@mui/material';
import { useRef } from 'react';

import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';
import { SupplierPicker } from '@work4all/components/lib/components/entity-picker/supplier-picker/SupplierPicker';

import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';

import { PickerTargetButton } from './PickerTargetButton';

type Props = {
  disabled?: boolean;
  value: Supplier;
  onChange: (value: Supplier | null) => void;
};

export function SupplierPickerCell({ disabled, value, onChange }: Props) {
  const popoverRef = useRef<EntityPickerPopover>(null);

  return (
    <EntityPickerPopover
      ref={popoverRef}
      picker={
        <SupplierPicker value={value} onChange={onChange} multiple={false} />
      }
    >
      <PickerTargetButton disabled={disabled}>
        <Typography style={{ display: 'flex', alignItems: 'center' }}>
          {value?.name}
        </Typography>
      </PickerTargetButton>
    </EntityPickerPopover>
  );
}
