import { useState } from 'react';

import { BottomViewsProvider } from '../providers';

import { BottomViewsContent } from './BottomViewsContent';
import { BottomViewsHeader } from './BottomViewsHeader';
import { BottomViewsTabContent } from './BottomViewsTabContent';
import { BottomViewsTabs } from './BottomViewsTabs';
import { BottomViewsToggleCollapsibilityAction } from './BottomViewsToggleCollapsibilityAction';
import { BottomViewsProps } from './types';

export const BottomViews = (props: BottomViewsProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <BottomViewsProvider
      isCollapsed={isCollapsed}
      {...props}
      setIsCollapsed={(e) => {
        setIsCollapsed(e);
        props.setIsCollapsed?.(e);
      }}
    >
      <BottomViewsHeader>
        <BottomViewsToggleCollapsibilityAction />
        <BottomViewsTabs />
      </BottomViewsHeader>

      <BottomViewsContent>
        <BottomViewsTabContent />
      </BottomViewsContent>
    </BottomViewsProvider>
  );
};
