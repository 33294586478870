import styles from './MenuItem.module.scss';

import { MenuItemProps } from './types';

export const MenuItem = ({ children, ...rest }: MenuItemProps) => {
  return (
    <div {...rest} className={styles.root}>
      {children}
    </div>
  );
};
