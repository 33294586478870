import React from 'react';
import { TableInstance } from 'react-table';

import { CallMemo } from '@work4all/models/lib/Classes/CallMemo.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { OnOpenMask } from '../../containers/mask-overlays/mask-overlay';

import { EntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/callMemo-table-schema.json';

interface Props {
  prefilter?: unknown[];
  onOpenMask: OnOpenMask;
}

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];

export const CallMemoTable = React.forwardRef<TableInstance, Props>(
  function CallMemoTable(_props, ref) {
    const dataTable = useEntityDataTable<CallMemo, never>({
      schema: schema as never,
      defaultSort,
    });

    return <EntityTable ref={ref} {...dataTable} {..._props} />;
  }
);
