export class WaitingQueue {
  private _tasks: number;
  private _waiting: Array<() => void>;

  constructor() {
    this._tasks = 0;
    this._waiting = [];
  }

  async queueSingle() {
    this._tasks++;
  }

  async waitUntilEmpty(): Promise<void> {
    if (this._tasks > 0) {
      await new Promise<void>((resolve) => this._waiting.push(resolve));
    }
  }

  releaseSingle(): void {
    if (this._tasks > 0) {
      this._tasks--;
    }

    if (this._tasks === 0) {
      // Resolve all waiting promises
      while (this._waiting.length > 0) {
        const next = this._waiting.shift();
        if (next) {
          next();
        }
      }
    }
  }
}
