import { typeNameToSdObjType } from '@work4all/utils';

import { useFormUpdate } from '../../../utils/use-form-update';

import { UseCheckListFormUpdateProps } from './types';

export const useCheckListFormUpdate = ({
  form,
}: UseCheckListFormUpdateProps) => {
  useFormUpdate(
    {
      user: (user) => {
        return { userId: user?.id ?? 0 };
      },
      'businessPartner.data': (businessPartner) => {
        return {
          businessPartnerId: businessPartner?.id ?? 0,
          businessPartnerType: typeNameToSdObjType(
            businessPartner?.__typename,
            true
          ),
          contact: businessPartner?.mainContact ?? null,
        };
      },
      contact: (contact) => {
        return { contactId: contact?.id ?? 0 };
      },
      project: (project) => {
        return { projectId: project?.id ?? 0, projectProcess: null };
      },
      projectProcess: (projectProcess) => {
        const positions = form.getValues('positionList') ?? [];

        return {
          projectProcessId: projectProcess?.id ?? 0,
          positionList: positions.map((position) => {
            return {
              ...position,
              projectProcess,
              projectProcessId: projectProcess?.id ?? 0,
            };
          }),
        };
      },
    },
    form
  );
};
