import styles from './ReferralBanner.module.scss';

import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ReferralIcon } from '@work4all/assets/icons/referral.svg';

import { useUser } from '@work4all/data';

const MAIL_TO = 'info@work4all.de';

export const ReferralBanner = () => {
  const user = useUser();
  const { t } = useTranslation();

  const subject = t('REFERRAL_EMAIL_SUBJECT');
  const body = t('REFERRAL_EMAIL_BODY', {
    userName: user.displayName,
  });

  return (
    <div data-theme="light" className={styles.wrapper}>
      <ReferralIcon />

      <div className={styles.textWrapper}>
        <div>
          <Typography variant="h4">{t('REFERRAL_BANNER_TITLE')}</Typography>
          <Typography variant="body2">
            {t('REFERRAL_BANNER_DESCRIPTION')}
          </Typography>
        </div>

        <Link
          href={`mailto:${MAIL_TO}?subject=${encodeURIComponent(
            subject
          )}&body=${encodeURIComponent(body)}`}
          underline="none"
        >
          <Typography className={styles.cta}>
            {t('REFERRAL_BANNER_CTA')}
          </Typography>
        </Link>
      </div>
    </div>
  );
};
