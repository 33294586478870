import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { DueDateClassified } from '../Enums/DueDateClassified.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { InvoiceForm } from '../Enums/InvoiceForm.enum';
import { PaymentStatus } from '../Enums/PaymentStatus.enum';
import { RaAccountingStatus } from '../Enums/RaAccountingStatus.enum';

import { BaseDataLanguage } from './BaseDataLanguage.entity';
import { CostCenter } from './CostCenter.entity';
import { Currency } from './Currency.entity';
import { Customer } from './Customer.entity';
import { Invoice } from './Invoice.entity';
import { LookUp } from './LookUp.entity';
import { PaymentKind } from './PaymentKind.entity';
import { Project } from './Project.entity';
import { RaLedgerAccountSplit } from './RaLedgerAccountSplit.entity';
import { RaPayment } from './RaPayment.entity';
import { User } from './User.entity';

export class RaViewModel<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: valueGross */
  grossValue?: number;
  /** Alias: valuePayed */
  payedValue?: number;
  /** Alias: valueNet */
  netValue?: number;
  /** Alias: valueToPay */
  valueToPay?: number;
  /** Alias: valueDiscount */
  discountValue?: number;
  /** Alias: valueVat */
  vatValue?: number;
  /** Alias: textBody */
  note?: string;
  /** Alias: sdObjMemberCode */
  sdObjMemberId?: number;
  /** Alias: branchCode */
  branchId?: number;
  /** Alias: invoiceDate */
  invoiceDate?: string;
  /** Alias: invoiceNumber */
  invoiceNumber?: number;
  /** Alias: dueDate */
  dueDate?: string;
  /** Alias: dueStateClassified */
  dueDateClassified?: DueDateClassified;
  /** Alias: dueSinceDays */
  dueDays?: number;
  /** Alias: dueDateExtended */
  dueDateExtended?: string;
  /** Alias: reminderMailCount */
  reminderMailCount?: number;
  /** Alias: reminderMailLastDate */
  reminderMailLastDate?: string;
  /** Alias: reminderFTF */
  reminderFTF?: string;
  /** Alias: reminderLocked */
  reminderLocked?: boolean;
  /** Alias: reminderLevel */
  reminderLevel?: number;
  /** Alias: reminderTolerance */
  reminderTolerance?: number;
  /** Alias: reminderNoteCustomer */
  reminderNoteCustomer?: string;
  /** Alias: reminderNoteInvoice */
  reminderNoteInvoice?: string;
  /** Alias: bookingDate */
  bookingDate?: string;
  /** Alias: createdByUserCode */
  createdByUserId?: number;
  /** Alias: accountingHandoverDate */
  accountingHandoverDate?: string;
  /** Alias: costCenterCode */
  costCenterId?: number;
  /** Alias: exchangeRate */
  exchangeRate?: number;
  /** Alias: currencyCode */
  currencyId?: number;
  /** Alias: invoiceUserCode */
  invoiceUserId?: number;
  /** Alias: invoiceProjectCode */
  invoiceProjectId?: number;
  /** Alias: customerInvoiceType */
  invoiceForm?: InvoiceForm;
  /** Alias: responsibleUserCode */
  responsibleUserId?: number;
  /** Alias: typeOfPaymentCode */
  typeOfPaymentId?: number;
  /** Alias: accountingLock */
  accountingLock?: boolean;
  /** Alias: accountedState */
  accountingStatus?: RaAccountingStatus;
  /** Alias: branch */
  branch?: LookUp<T>;
  /** Alias: customer */
  customer?: Customer<T>;
  /** Alias: createdByUser */
  createdByUser?: User<T>;
  /** Alias: invoiceUser */
  invoiceUser?: User<T>;
  /** Alias: responsibleUser */
  responsibleUser?: User<T>;
  /** Alias: costCenter */
  costCenter?: CostCenter<T>;
  /** Alias: currency */
  currency?: Currency<T>;
  /** Alias: project */
  project?: Project<T>;
  /** Alias: typeOfPayment */
  paymentKind?: PaymentKind<T>;
  /** Alias: paymentStatus */
  paymentStatus?: PaymentStatus;
  /** Alias: languageCode */
  languageCode?: number;
  /** Alias: language */
  language?: BaseDataLanguage<T>;
  /** Alias: payments */
  payments?: RaPayment<T>[];
  /** Alias: invoiceCode */
  invoiceId?: number;
  /** Alias: splittings */
  splittings?: RaLedgerAccountSplit<T>[];
  /** Alias: invoice */
  invoice?: Invoice<T>;

  __typename?: string;
}

const fields: FieldDefinitions<RaViewModel> = {
  id: {
    alias: 'code',
  },
  grossValue: {
    alias: 'valueGross',
  },
  payedValue: {
    alias: 'valuePayed',
  },
  netValue: {
    alias: 'valueNet',
  },
  valueToPay: {
    alias: 'valueToPay',
  },
  discountValue: {
    alias: 'valueDiscount',
  },
  vatValue: {
    alias: 'valueVat',
  },
  note: {
    alias: 'textBody',
  },
  sdObjMemberId: {
    alias: 'sdObjMemberCode',
  },
  branchId: {
    alias: 'branchCode',
  },
  invoiceDate: {
    alias: 'invoiceDate',
  },
  invoiceNumber: {
    alias: 'invoiceNumber',
  },
  dueDate: {
    alias: 'dueDate',
  },
  dueDateClassified: {
    alias: 'dueStateClassified',
  },
  dueDays: {
    alias: 'dueSinceDays',
  },
  dueDateExtended: {
    alias: 'dueDateExtended',
  },
  reminderMailCount: {
    alias: 'reminderMailCount',
  },
  reminderMailLastDate: {
    alias: 'reminderMailLastDate',
  },
  reminderFTF: {
    alias: 'reminderFTF',
  },
  reminderLocked: {
    alias: 'reminderLocked',
  },
  reminderLevel: {
    alias: 'reminderLevel',
  },
  reminderTolerance: {
    alias: 'reminderTolerance',
  },
  reminderNoteCustomer: {
    alias: 'reminderNoteCustomer',
  },
  reminderNoteInvoice: {
    alias: 'reminderNoteInvoice',
  },
  bookingDate: {
    alias: 'bookingDate',
  },
  createdByUserId: {
    alias: 'createdByUserCode',
  },
  accountingHandoverDate: {
    alias: 'accountingHandoverDate',
  },
  costCenterId: {
    alias: 'costCenterCode',
  },
  exchangeRate: {
    alias: 'exchangeRate',
  },
  currencyId: {
    alias: 'currencyCode',
  },
  invoiceUserId: {
    alias: 'invoiceUserCode',
  },
  invoiceProjectId: {
    alias: 'invoiceProjectCode',
  },
  invoiceForm: {
    alias: 'customerInvoiceType',
  },
  responsibleUserId: {
    alias: 'responsibleUserCode',
  },
  typeOfPaymentId: {
    alias: 'typeOfPaymentCode',
  },
  accountingLock: {
    alias: 'accountingLock',
  },
  accountingStatus: {
    alias: 'accountedState',
  },
  branch: {
    alias: 'branch',
    entity: Entities.lookUp,
  },
  customer: {
    alias: 'customer',
    entity: Entities.customer,
  },
  createdByUser: {
    alias: 'createdByUser',
    entity: Entities.user,
  },
  invoiceUser: {
    alias: 'invoiceUser',
    entity: Entities.user,
  },
  responsibleUser: {
    alias: 'responsibleUser',
    entity: Entities.user,
  },
  costCenter: {
    alias: 'costCenter',
    entity: Entities.costCenter,
  },
  currency: {
    alias: 'currency',
    entity: Entities.currency,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  paymentKind: {
    alias: 'typeOfPayment',
    entity: Entities.paymentKind,
  },
  paymentStatus: {
    alias: 'paymentStatus',
  },
  languageCode: {
    alias: 'languageCode',
  },
  language: {
    alias: 'language',
    entity: Entities.baseDataLanguage,
  },
  payments: {
    alias: 'payments',
    entity: Entities.raPayment,
  },
  invoiceId: {
    alias: 'invoiceCode',
  },
  splittings: {
    alias: 'splittings',
    entity: Entities.raLedgerAccountSplit,
  },
  invoice: {
    alias: 'invoice',
    entity: Entities.invoice,
  },
  __typename: {
    alias: '__typename',
  },
};

export const raViewModelEntityDefinition: EntitiyDefinition<RaViewModel> = {
  local: {},
  remote: {
    queryName: 'getOutgoingInvoiceViewModel',
    fragmentName: 'RaViewModel',
    withPaginationWrapper: true,
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
