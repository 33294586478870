import { useMemo } from 'react';

import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { WordLetterTemplate } from '@work4all/models/lib/Classes/WordLetterTemplate.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useDocumentTemplateRequest = ({
  id,
  entity,
}: {
  id?: string | number;
  entity: Entities;
}) => {
  return useMemo(() => {
    const filter = [{ id: { $eq: id } }];
    const data: WordDocumentTemplate | WordLetterTemplate = {
      id: null,
      title: null,
      body: null,
      groupId: null,
      templateGroup: {
        id: null,
        name: null,
      },
      fileInfos: {
        fileServiceProviderInfos: {
          id: null,
          exists: null,
          fspUrl: null,
        },
        previewUrl: null,
        previewMimeType: null,
        downloadUrl: null,
        fileSize: null,
      },
    };

    const request: DataRequest = {
      entity,
      data,
      filter,
    };
    return request;
  }, [id, entity]);
};
