import { useDataProvider } from '@work4all/data';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { LookupType } from '@work4all/models/lib/Enums/LookupType.enum';

const request: DataRequest = {
  data: {
    id: null,
    value: null,
    objKind: null,
    lookUpId: null,
    textValue: null,
    name: null,
  } as LookUp,
  entity: Entities.lookUp,
  completeDataResponse: true,
  vars: {
    type: LookupType.RESSOURCENKLASSEN,
  },
};

export const useResourceClasses = () => {
  return useDataProvider<LookUp>(request);
};
