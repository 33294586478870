import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class EMailSignature<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: body */
  body?: string;
  /** Alias: bodyTextmarkenAufgeloest */
  bodyTextmarksResolved?: string;
  /** Alias: bodyTextmarkenAufgeloestInlineImagesAufgeloest */
  bodyTextmarkenAufgeloestInlineImagesResolved?: string;

  __typename?: string;
}

const fields: FieldDefinitions<EMailSignature> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  body: {
    alias: 'body',
  },
  bodyTextmarksResolved: {
    alias: 'bodyTextmarkenAufgeloest',
  },
  bodyTextmarkenAufgeloestInlineImagesResolved: {
    alias: 'bodyTextmarkenAufgeloestInlineImagesAufgeloest',
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailSignatureEntityDefinition: EntitiyDefinition<EMailSignature> =
  {
    local: {},
    remote: {
      queryName: 'getEMailSignaturen2',
      fragmentName: 'EMailSignatur',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertEMailSignatur',
          args: [
            { name: 'input', type: 'InputEMailSignatur!' },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
