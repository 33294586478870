import { gql, useQuery } from '@apollo/client';

import { MailboxContent } from '@work4all/models/lib/Classes/MailboxContent.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

const READ_MAIL = gql`
  query ReadMail($mailboxId: String!, $folderId: String!, $mailId: String!) {
    readMail(mailboxId: $mailboxId, folderId: $folderId, mailId: $mailId) {
      id
      renderableBody
      assignStatus: assignStatus
      date: date
      to: to
      title: title
      body: body
      from: from
      cc: cc
      isRead: isRead
      convertedMail: convertedMail {
        id: code
        subject: betreff
        user: benutzer {
          id: code
          displayName: anzeigename
          __typename
        }
        creatorUserId: erstellerBenutzerCode
        insertTime: insertTime
        contact: ansprechpartner {
          id: code
          displayName: anzeigename
          businessPartner: geschaeftspartner {
            id: code
            data: data {
              ... on Kunde {
                id: code
                name: name
                __typename
              }
              ... on Lieferant {
                id: code
                name: name
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        businessPartner: geschaeftspartner {
          id: code
          data: data {
            ... on Kunde {
              id: code
              name: name
              __typename
            }
            ... on Lieferant {
              id: code
              name: name
              __typename
            }
            __typename
          }
          __typename
        }
        project: projekt {
          id: code
          name: name
          __typename
        }
        __typename
      }
      possibleSenders: possibleSenders {
        ... on Ansprechpartner {
          id: code
          displayName: anzeigename
          businessPartner: geschaeftspartner {
            id: code
            data: data {
              ... on Kunde {
                id: code
                name: name
                __typename
              }
              ... on Lieferant {
                id: code
                name: name
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        ... on Kunde {
          id: code
          name: name
          __typename
        }
        ... on Lieferant {
          id: code
          name: name
          __typename
        }
        __typename
      }
      possibleProjects: possibleProjects {
        id: code
        name: name
        customer: kunde {
          id: code
          name: name
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const useReadMail = (props: {
  mailboxId: string;
  folderId: string;
  mailId: string | null;
}) => {
  return useQuery<{ readMail: MailboxContent<EMode.entity> }>(READ_MAIL, {
    variables: props,
    skip: !props.mailId,
  });
};
