import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationAreaEntityGroups } from '../types';

export const entityToEntityGroup = (
  entity: Entities
): OrganisationAreaEntityGroups => {
  switch (entity) {
    case Entities.user:
      return Entities.userGroup;
    case Entities.customer:
      return Entities.customerGroup;
    case Entities.supplier:
      return Entities.supplierGroup;
    case Entities.project:
      return Entities.projectGroup;
    case Entities.article:
      return Entities.articleGroup;
    case Entities.textBuildingBlock:
      return Entities.textBuildingBlockGroup;
    case Entities.wordLetterTemplate:
      return Entities.letterTemplateGroup;
    case Entities.wordDocumentTemplate:
      return Entities.documentTemplateGroup;
    default:
      return null;
  }
};
