import { useMemo } from 'react';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CHECK_LIST_REQUEST_DATA } from './constants';

export const useCheckListMaskOverlayRequest = (
  id: string | number
): DataRequest => {
  return useMemo<DataRequest>(() => {
    return {
      entity: Entities.checkList,
      data: CHECK_LIST_REQUEST_DATA,
      filter: [{ id: { $eq: id } }],
    };
  }, [id]);
};
