import { Box, Button, Dialog, DialogActions, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogContent } from '@work4all/components';
import { SearchResultItem } from '@work4all/components/lib/input/search-result-item/SearchResultItem';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { fetchPlaceDetails, fetchPlaceSuggestions } from './places-api';
import {
  NewBusinessPartnerTemplate,
  prepareBusinessPartnerTemplate,
} from './places-utils';

const searchTimeoutDelay = 350;

export function PlaceSuggestions(props: {
  query: string;
  disabled?: boolean;
  showConfirmationDialog?: boolean;
  entity: Entities.customer | Entities.supplier;
  onSelect: (template: NewBusinessPartnerTemplate) => void;
}) {
  const {
    entity,
    query,
    disabled,
    onSelect,
    showConfirmationDialog = true,
  } = props;

  const searchTimeout = useRef(null);

  const { t } = useTranslation();

  const queryTrimmed = query.trim();
  const isQueryEmpty = queryTrimmed === '';

  const isActive = !disabled && !isQueryEmpty;

  const [suggestions, setSuggestions] = useState<
    google.maps.places.PlaceResult[]
  >([]);

  const attributionsElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isActive) {
      setSuggestions([]);
    }
  }, [isActive]);

  useEffect(() => {
    if (isActive && queryTrimmed.length > 2) {
      const controller = new AbortController();
      const signal = controller.signal;

      searchTimeout.current = setTimeout(() => {
        fetchPlaceSuggestions({
          query: queryTrimmed,
          // Instructs the Place Autocomplete service to return only business
          // results.
          type: 'establishment',
          container: attributionsElementRef.current,
          signal,
        }).then(
          (suggestions) => {
            // Limit suggestions to top 5 results.
            setSuggestions(suggestions.slice(0, 5));
          },
          (reason) => {
            if (reason instanceof Error && reason.name === 'AbortError') {
              return;
            }

            setSuggestions([]);

            throw reason;
          }
        );
      }, searchTimeoutDelay);

      return () => {
        clearTimeout(searchTimeout.current);
        controller.abort();
      };
    }
  }, [isActive, queryTrimmed]);

  const attributions = useMemo(() => {
    return suggestions
      .flatMap((suggestion) => suggestion.html_attributions)
      .filter(Boolean);
  }, [suggestions]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<google.maps.places.PlaceResult>(null);

  const handleSelection = useCallback(
    async (suggestion = selectedSuggestion) => {
      const details = await fetchPlaceDetails({
        placeId: suggestion.place_id,
        container: attributionsElementRef.current,
      });

      const template = prepareBusinessPartnerTemplate(details);

      setDialogOpen(false);

      onSelect(template);
    },
    [onSelect, selectedSuggestion]
  );

  if (disabled) {
    return null;
  }

  const createNewKey =
    entity === Entities.customer
      ? 'CUSTOMER.NEW_CUSTOMER'
      : 'SUPPLIER.NEW_SUPPLIER';
  const createNewText = t(createNewKey);

  return (
    <div>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        title={t('ASSIGN_INCOMING_EMAILS.LINK_MAILBOX')}
      >
        <DialogContent>
          <Typography>
            {t('SUGGESTION.CREATE_BUSINESSPARNTER_CONFIRMATION')}
          </Typography>
          <br />
          <Typography variant="h4">{selectedSuggestion?.name}</Typography>
          <Typography>{selectedSuggestion?.formatted_address}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="large"
            color="primary"
            onClick={() => setDialogOpen(false)}
          >
            {t('COMMON.NO')}
          </Button>
          <Button
            size="large"
            color="primary"
            onClick={() => {
              setDialogOpen(false);
              handleSelection();
            }}
          >
            {t('COMMON.YES')}
          </Button>
        </DialogActions>
      </Dialog>

      {suggestions.length > 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              paddingX: 2,
            }}
          >
            <Divider textAlign="left" sx={{ paddingY: 1, flex: 1 }}>
              <span>{createNewText}</span>
            </Divider>
          </Box>

          <div>
            {suggestions.map((suggestion) => {
              return (
                <SearchResultItem
                  key={suggestion.place_id}
                  id={undefined}
                  title={
                    <Typography component="span">
                      {suggestion.name}, {suggestion.formatted_address}
                    </Typography>
                  }
                  onClick={() => {
                    setSelectedSuggestion(suggestion);
                    if (showConfirmationDialog) {
                      setDialogOpen(true);
                    } else {
                      handleSelection(suggestion);
                    }
                  }}
                />
              );
            })}
          </div>
        </>
      )}

      <div ref={attributionsElementRef}>
        {attributions.map((attribution, index) => {
          // All html_attributions returned from Places API must be displayed on
          // the page.
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: attribution }}
            />
          );
        })}
      </div>
    </div>
  );
}
