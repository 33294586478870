import styles from './PreviewEditMessage.module.scss';

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useLockObject } from '../../../../hooks/object-lock-subscription/LockObjectProvider';
import EraserIcon from '../../../../icons/EraserIcon';
import { HookedUserIcon } from '../../../user-icon/useUserIconRegister';

interface Props {
  entityId: string;
  entityType: Entities;
}

export const PreviewEditMessage: React.FC<Props> = ({
  entityId,
  entityType,
}: Props) => {
  const { t } = useTranslation();

  const { getLock } = useLockObject();

  const { locked, lockedBy } = getLock(entityId, entityType);
  let message = [<EraserIcon />, <span>{t('MASK.EDIT_MESSAGE')}</span>];
  if (locked)
    message = [
      <HookedUserIcon userId={lockedBy?.id} locked={true} />,
      <div>
        <span className={styles['bold']}>{lockedBy?.displayName}</span>
        <span>{t('MASK.LOCKED_PREVIEW')}</span>
      </div>,
    ];
  return <div className={styles.root}>{message}</div>;
};
