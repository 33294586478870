import styles from './styles.module.scss';

import { ListItem } from '@mui/material';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import { ReactComponent as ChevronDownIcon } from '@work4all/assets/icons/chevron-down.svg';

import { useNavigate } from '@work4all/data';

import { reactRefSetter } from '@work4all/utils/lib/reactRefSetter';
import {
  DND_POPOVER_CLOSE_DELAY,
  DND_POPOVER_OPEN_DELAY,
  DndTypes,
} from '@work4all/utils/lib/variables';

import {
  IFavoriteLink,
  IFavoriteLinksFolder,
} from '../../../containers/more/data/favorite-links';
import { useFavoriteLinks } from '../../../containers/more/data/favorite-links/favorite-links-context';
import {
  FavoriteLinkDragObject,
  FavoriteLinksFolderDragObject,
  LinkDragObject,
} from '../../../dnd/drag-objects';
import { useEmptyDragPreview } from '../../../dnd/use-empty-drag-preview';
import { addDateParamToLink } from '../add-date-param-to-link';

import { FavoriteLinksFolderContextMenu } from './context-menu/FavoriteLinksFolderContextMenu';
import { LinksPopover } from './popover/LinksPopover';
import { PopoverList } from './popover/PopoverList';

export interface IFavoriteLinksFolderProps {
  folder: IFavoriteLinksFolder;
  activeLink: { folderId: string | null; linkId: string } | null;
}

export function FavoriteLinksFolder({
  folder,
  activeLink,
}: IFavoriteLinksFolderProps) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const {
    addFavoriteLink,
    moveFavoriteLink,
    moveFavoriteLinksFolder,
    removeFavoriteLinksFolder,
    renameFavoriteLinksFolder,
  } = useFavoriteLinks();

  const [, dropOn] = useDrop({
    accept: [DndTypes.LINK, DndTypes.FAVORITE_LINK],
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        switch (monitor.getItemType()) {
          case DndTypes.LINK: {
            const linkItem = item as LinkDragObject;

            addFavoriteLink({
              link: {
                href: linkItem.href,
                name: linkItem.text,
                translationKey: linkItem.translationKey,
              },
              position: { folder: folder.id },
            });

            break;
          }
          case DndTypes.FAVORITE_LINK: {
            const favoriteLinkItem = item as FavoriteLinkDragObject;

            moveFavoriteLink({
              target: {
                link: favoriteLinkItem.id,
                folder: favoriteLinkItem.folderId,
              },
              position: { folder: folder.id },
            });

            break;
          }
        }
      }
    },
  });

  const [{ isOver }, dropAfter] = useDrop({
    accept: [
      DndTypes.LINK,
      DndTypes.FAVORITE_LINK,
      DndTypes.FAVORITE_LINKS_FOLDER,
    ],
    drop: (item, monitor) => {
      switch (monitor.getItemType()) {
        case DndTypes.LINK: {
          const linkItem = item as LinkDragObject;
          addFavoriteLink({
            link: {
              href: linkItem.href,
              name: linkItem.text,
              translationKey: linkItem.translationKey,
            },
            position: { after: folder.id },
          });
          break;
        }
        case DndTypes.FAVORITE_LINK: {
          const favoriteLinkItem = item as FavoriteLinkDragObject;
          moveFavoriteLink({
            target: {
              link: favoriteLinkItem.id,
              folder: favoriteLinkItem.folderId,
            },
            position: { after: folder.id },
          });
          break;
        }
        case DndTypes.FAVORITE_LINKS_FOLDER: {
          const favoriteLinksFolderItem = item as FavoriteLinksFolderDragObject;

          moveFavoriteLinksFolder({
            id: favoriteLinksFolderItem.id,
            position: { after: folder.id },
          });

          break;
        }
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [, drag, preview] = useDrag<
    FavoriteLinksFolderDragObject,
    unknown,
    unknown
  >({
    type: DndTypes.FAVORITE_LINKS_FOLDER,
    item: {
      id: folder.id,
      text: folder.name,
    },
  });

  useEmptyDragPreview(preview);

  const onClick = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleLinkClick = (link: IFavoriteLink) => {
    navigate(addDateParamToLink(link.href));
    setOpen(false);
  };

  const onRenameFolder = () => {
    setEditing(true);
    setInputWidth(anchorRef.current.clientWidth);
  };

  const onRemoveFolder = () => {
    removeFavoriteLinksFolder(folder.id);
  };

  const [isEditing, setEditing] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);

  const [folderDropTriggerCollect, folderDropTriggerRef] = useDrop({
    accept: [DndTypes.LINK, DndTypes.FAVORITE_LINK],
    canDrop: () => false,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  useEffect(() => {
    if (folderDropTriggerCollect.isOver) {
      const timeout = setTimeout(() => {
        setOpen(true);
      }, DND_POPOVER_OPEN_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [folderDropTriggerCollect.isOver]);

  const [popoverDropCollect, popoverDropRef] = useDrop({
    accept: [DndTypes.LINK, DndTypes.FAVORITE_LINK],
    canDrop: () => false,
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  useEffect(() => {
    if (popoverDropCollect.isOver) {
      const timeout = setTimeout(() => {
        setOpen(false);
      }, DND_POPOVER_CLOSE_DELAY);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [popoverDropCollect.isOver]);

  return isEditing ? (
    <input
      className={styles.navItemInput}
      style={{ width: inputWidth }}
      autoFocus
      defaultValue={folder.name}
      onBlur={(event) => {
        const newName = event.currentTarget.value;
        renameFavoriteLinksFolder({ id: folder.id, name: newName });
        setEditing(false);
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          const newName = event.currentTarget.value;
          renameFavoriteLinksFolder({ id: folder.id, name: newName });
          setEditing(false);
        }
      }}
    />
  ) : (
    <>
      <FavoriteLinksFolderContextMenu
        onRename={onRenameFolder}
        onRemove={onRemoveFolder}
      >
        {({ onContextMenu }) => (
          <div className={styles.navWrapper}>
            <ListItem
              ref={reactRefSetter(
                anchorRef,
                dropOn,
                drag,
                folderDropTriggerRef
              )}
              button
              className={clsx(styles.navItem, {
                [styles.active]: activeLink?.folderId === folder.id,
              })}
              onClick={onClick}
              onContextMenu={onContextMenu}
            >
              <span>{folder.name}</span>
              <ChevronDownIcon className={styles.folderIcon} />
            </ListItem>

            <div
              ref={dropAfter}
              className={clsx(styles.dropTarget, {
                [styles.dndActive]: isOver,
              })}
            />
          </div>
        )}
      </FavoriteLinksFolderContextMenu>

      <LinksPopover
        popoverRef={popoverDropRef}
        open={open}
        anchorEl={anchorRef.current}
        onClose={onClose}
      >
        <PopoverList
          folder={folder}
          items={folder.links}
          activeLink={activeLink}
          onLinkClick={handleLinkClick}
          onFolderClick={() => void 0}
        />
      </LinksPopover>
    </>
  );
}
