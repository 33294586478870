import { Box, Grid, Slider } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse/Collapse';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input/LabeledInput';

import { ControllerPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../../../../containers/mask-overlays/form-plus/use-form-context-plus';
import { WorkDayHoursField } from '../../../../../../../../../../entity-picker/WorkDayHoursField';
import { ProjectProcessFormValue } from '../../types';

export const PlanningSection = () => {
  const { t } = useTranslation();

  const { control } = useFormContextPlus<ProjectProcessFormValue>();

  return (
    <Collapse title={t('COMMON.PLANNING_PROGRESS')} defaultOpen={false}>
      <Grid container spacing="1rem">
        <Grid item xs={4}>
          <ControllerPlus
            control={control}
            name="planningAmount"
            render={({ field }) => {
              return (
                <WorkDayHoursField
                  valueDays={field.value}
                  onValueChange={field.onChange}
                  unit="hour"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ControllerPlus
            control={control}
            name="planningAmount"
            render={({ field }) => {
              return (
                <WorkDayHoursField
                  valueDays={field.value}
                  onValueChange={field.onChange}
                  unit="day"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ControllerPlus
            control={control}
            name="planningAmount"
            render={({ field }) => {
              return (
                <WorkDayHoursField
                  valueDays={field.value}
                  onValueChange={field.onChange}
                  unit="month"
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <ControllerPlus
            control={control}
            name="progress"
            render={({ field }) => {
              return (
                <LabeledInput
                  {...field}
                  type="number"
                  label={t('PROJECT_PROCESS.PROGRESS')}
                  endAdornment={
                    <Box mt="1.125rem" pl="0.25rem" color="var(--text03)">
                      %
                    </Box>
                  }
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={8}>
          <ControllerPlus
            control={control}
            name="progress"
            render={({ field }) => {
              return (
                <Box p="0 1rem">
                  <Slider
                    {...field}
                    max={100}
                    min={0}
                    marks={[0, 25, 50, 75, 100].map((x) => ({
                      value: x,
                      label: x + ' %',
                    }))}
                    color={field.value === 100 ? 'success' : 'primary'}
                  />
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <ControllerPlus
            control={control}
            name="overBookingPossible"
            render={({ field }) => {
              return (
                <CheckboxRadioItem
                  {...field}
                  checked={Boolean(field.value)}
                  onChange={(e) => {
                    field.onChange(e.target.checked ? 1 : 0);
                  }}
                  control="checkbox"
                  label={t('PROJECT_PROCESS.OVERBOOKING_POSSIBLE')}
                />
              );
            }}
          />
        </Grid>
      </Grid>
    </Collapse>
  );
};
