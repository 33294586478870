import { Download, Fullscreen } from '@mui/icons-material';
import { cloneDeep } from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';
import { useUploadTempFile } from '@work4all/data/lib/hooks/use-upload-temp-file';
import { downloadAuthed } from '@work4all/data/lib/utils';

import { ERPTypes } from '@work4all/models/lib/Classes/ERPTypes.entity';
import { FileEntity } from '@work4all/models/lib/Classes/FileEntity.entity';
import { ReViewModel } from '@work4all/models/lib/Classes/ReViewModel.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { LanguageResource } from '@work4all/utils/lib/i18n/LanguageResource.enum';

import { CustomToolbar } from '../../../../dataDisplay/actions-bar/ToolbarTypes';

import { useErpFileName } from './use-erp-file-name';

interface UseSimpleReportActionsProps {
  url?: string;
  entitiesData: (ERPTypes & ReViewModel)[];
  entity: Entities;
  setFullscreen: (value: boolean) => void;
  customActions: CustomToolbar;
  file: {
    fileInfos?: FileEntity;
    mimeType?: string;
    id?: number;
    parentId?: number;
  };
  languageResources: LanguageResource[];
  showEditIcon?: boolean;
  showFullscreenIcon?: boolean;
  showDownloadIcon?: boolean;
}

export const useSimpleReportActions = (props: UseSimpleReportActionsProps) => {
  const {
    url,
    entitiesData,
    entity,
    setFullscreen,
    file,
    languageResources,
    showEditIcon,
    showFullscreenIcon,
    showDownloadIcon,
  } = props;
  const { t } = useTranslation();

  const singleObject = entitiesData?.[0];
  const fileName = useErpFileName({
    entityType: entity,
    data: {
      contractNumber: singleObject?.contractNumber,
      number: singleObject?.number,
      value: singleObject?.value,
      language: {
        id: singleObject?.id || 0,
      },
    },
    languageResources,
  });

  const httpHeaders = useAuthHeaders();
  const uploadTempFile = useUploadTempFile();
  const [simpleReportBlob, setSimpleReportBlob] = useState<Blob>(null);

  const download = useCallback(
    async (e) => {
      e.preventDefault();

      let downloadUrl = file?.fileInfos?.downloadUrl;
      if (!url) {
        try {
          const uploadFile = new File([simpleReportBlob], fileName);
          const { downloadUrl: tmpDownloadUrl } = await uploadTempFile(
            uploadFile
          );
          downloadUrl = tmpDownloadUrl;
        } catch (err) {
          console.warn(err);
        }
      }

      downloadAuthed(downloadUrl, fileName, httpHeaders);
    },
    [
      file?.fileInfos?.downloadUrl,
      fileName,
      httpHeaders,
      simpleReportBlob,
      uploadTempFile,
      url,
    ]
  );

  const getCustomActions = () => {
    const customActions = cloneDeep(props.customActions) ?? {};

    customActions.right ??= [];
    if (url) return customActions;
    if (showFullscreenIcon) {
      customActions.right.push({
        actionKey: 'fullscreen',
        handler: () => setFullscreen(true),
        mode: 'Edit',
        IconComponent: Fullscreen,
        title: t('COMMON.FULLSCREEN'),
      });
    }
    if (showDownloadIcon) {
      customActions.right.push({
        actionKey: 'download',
        handler: (_, e) => download(e),
        mode: 'Edit',
        IconComponent: Download,
        title: t('INPUTS.DOWNLOAD'),
      });
    }

    return customActions;
  };

  return {
    actions: getCustomActions(),
    setSimpleReportBlob,
    download,
    fileName,
  };
};
