import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

import { InputArticleAttachment } from './InputArticleAttachment.entity';
import { InputArticleBomComponents } from './InputArticleBomComponents.entity';
import { InputArticleLedgerAccountsAssignmentsRelation } from './InputArticleLedgerAccountsAssignmentsRelation.entity';
import { InputArticlePrices } from './InputArticlePrices.entity';
import { InputArticleTextRelation } from './InputArticleTextRelation.entity';

export class InputArticleRelation<T extends EMode = EMode.entity> {
  /** Alias: articleTexts */
  articleTexts?: InputArticleTextRelation<T>;
  /** Alias: prices */
  prices?: InputArticlePrices<T>;
  /** Alias: ledgerAccountAssignements */
  ledgerAccountAssignements?: InputArticleLedgerAccountsAssignmentsRelation<T>;
  /** Alias: bomComponents */
  bomComponents?: InputArticleBomComponents<T>;
  /** Alias: attachments */
  attachments?: InputArticleAttachment<T>;

  __typename?: string;
}

const fields: FieldDefinitions<InputArticleRelation> = {
  articleTexts: {
    alias: 'articleTexts',
  },
  prices: {
    alias: 'prices',
  },
  ledgerAccountAssignements: {
    alias: 'ledgerAccountAssignements',
  },
  bomComponents: {
    alias: 'bomComponents',
  },
  attachments: {
    alias: 'attachments',
  },
  __typename: {
    alias: '__typename',
  },
};

export const inputArticleRelationEntityDefinition: EntitiyDefinition<InputArticleRelation> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'InputArticleRelation',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
