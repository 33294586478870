import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';

const BUSINESS_PARTNER_REQUEST_DATA:
  | Customer<EMode.query>
  | Supplier<EMode.query> = {
  id: null,
  name: null,
  number: null,
  isPrivateCustomer: null,
  website: null,
};

export const CHECK_LIST_REQUEST_DATA: CheckList<EMode.query> = {
  id: null,
  title: null,
  date: null,
  isSampleTemplate: null,
  userId: null,
  user: {
    id: null,
    displayName: null,
  },
  businessPartnerId: null,
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: BUSINESS_PARTNER_REQUEST_DATA,
      supplier: BUSINESS_PARTNER_REQUEST_DATA,
    },
  },
  contactId: null,
  contact: {
    id: null,
    displayName: null,
  },
  projectId: null,
  project: {
    id: null,
    name: null,
    number: null,
  },
  projectProcessId: null,
  projectProcess: {
    id: null,
    number: null,
    process: null,
  },
  positionList: [
    {
      id: null,
      kind: null,
      name: null,
      positionNumber: null,
      priority: null,
      isChecked: null,
      plannedBy: null,
      date: null,
      endDate: null,
      doneByUserId: null,
      userDone: {
        id: null,
        displayName: null,
      },
      category1: null,
      category2: null,
      supplierId: null,
      supplier: {
        id: null,
        name: null,
      },
      projectProcessId: null,
      projectProcess: {
        id: null,
        process: null,
      },
      note: null,
    },
  ],
  favoriteItem: { id: null },
};
