import { useCallback, useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { Category } from '@work4all/models/lib/Classes/Category.entity';
import { CategoryClass } from '@work4all/models/lib/Classes/CategoryClass.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useDefaultCategories = (
  entity: Entities,
  contactCategories?: boolean
) => {
  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        mandatoryCustomers: null,
        mandatorySuppliers: null,
        isPersonCategory: null,
        name: null,
        categoryList: [
          {
            id: null,
            name: null,
            isStandardCustomer: null,
            isPersonCategory: null,
            isStandardSupplier: null,
            categoryClass: {
              id: null,
              name: null,
              mandatoryCustomers: null,
              mandatorySuppliers: null,
            },
          },
        ],
      } as CategoryClass,
      entity: Entities.categoryClass,
      filter: [
        contactCategories
          ? {
              isPersonCategory: {
                $eq: true,
              },
            }
          : null,
      ].filter(Boolean),
    };
  }, [contactCategories]);

  const response = useDataProvider<CategoryClass>(request);

  const filterCategories = useCallback(
    (category: Category) => {
      const property = {
        [Entities.customer]: 'isStandardCustomer',
        [Entities.supplier]: 'isStandardSupplier',
      };

      return (
        category[property[entity]] &&
        (!contactCategories || category.isPersonCategory)
      );
    },
    [contactCategories, entity]
  );

  const data = useMemo(() => {
    return response.data
      .flatMap((c) => c.categoryList)
      .filter(filterCategories);
  }, [filterCategories, response.data]);

  return {
    ...response,
    data,
  };
};
