import { MaskTabPanel } from '../../../../mask-tabs';
import { MaskContent } from '../../../components/MaskContent/MaskContent';
import { HistoryTabPanel } from '../../../components/tab-panels/history/HistoryTabPanel';

import { ControllerTabs } from './enums';
import { CheckListOverlayControllerGeneralTabContent } from './tabs-content';

export const CheckListOverlayControllerContent = () => {
  return (
    <MaskContent>
      <MaskTabPanel value={ControllerTabs.General}>
        <CheckListOverlayControllerGeneralTabContent />
      </MaskTabPanel>

      <MaskTabPanel value={ControllerTabs.History}>
        <HistoryTabPanel />
      </MaskTabPanel>
    </MaskContent>
  );
};
