import { useLayoutEffect, useRef } from 'react';

export function useSaveCursorPosition(
  inputRef: React.RefObject<HTMLInputElement>
) {
  const selectionRangeRef = useRef<null | { start: number; end: number }>(null);

  useLayoutEffect(() => {
    if (selectionRangeRef.current) {
      const { start, end } = selectionRangeRef.current;
      inputRef.current?.setSelectionRange(start, end);
      selectionRangeRef.current = null;
    }
  });

  return function setCursorPosition(position: number): void {
    selectionRangeRef.current = { start: position, end: position };
  };
}
