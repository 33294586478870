import * as ReactSentry from '@sentry/react';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { RxDatabase } from 'rxdb';
import { defer, exhaustMap, Subject } from 'rxjs';

import { components } from '../../apiModels';
import { USER_META_ID } from '../db';
import { IUser } from '../hooks';
import { httpClient, HttpClientResponse } from '../http-client';

interface Message {
  action: 'refresh_token';
}

export const initTokenrefresh = (
  db: RxDatabase,
  appName: string,
  onError: () => void
) => {
  const tokenRefreshSubject = new Subject<void>();

  const channel = new BroadcastChannel<Message>('token_refresh');
  const elector = createLeaderElection(channel);
  elector.awaitLeadership().then(() => {
    console.debug('this tab is now leader');
  });

  channel.onmessage = (data) => {
    console.debug('received refresh_token message', data);
    if (data.action === 'refresh_token' && elector.isLeader) {
      tokenRefreshSubject.next();
    }
  };

  tokenRefreshSubject
    .pipe(
      exhaustMap(() =>
        defer(async () => {
          //only one tab is responsible to refresh the users auth
          //dispatch(replicateUserCollections(activeTenant, true));

          if ((await elector.hasLeader()) && !elector.isLeader) {
            channel.postMessage({
              action: 'refresh_token',
            });
            return false;
          }

          const doc = await db.getLocal<{ user: IUser }>(USER_META_ID);
          const user = doc?._data?.data?.user;

          // if the user is already deleted or not inserted
          // nothing to do for us here
          if (!user) {
            return;
          }

          const tokenToUse = user.refreshToken;

          try {
            const response = await httpClient.put<
              components['schemas']['AuthResultV2'],
              components['schemas']['AuthRequestV2']
            >({
              url: `${user.baseUrl}/api/Auth/RefreshToken`,
              body: {
                refreshToken: tokenToUse,
                application: appName,
              },
              contentType: 'application/json',
              headers: {
                'x-work4all-apiurl': user.baseUrl,
              },
            });

            const { token, refreshToken } = response.data;
            ReactSentry.addBreadcrumb({
              level: 'info',
              message: 'got response from token refresh',
              category: 'auth',
              data: {
                hasTokenInResponse: !!token,
                hasRefresTokenInResponse: !!refreshToken,
              },
            });

            if (!token || !refreshToken) {
              const errorMessage = [
                'Invalid response received for "RefreshToken" request.',
                !token && 'Access token is undefined.',
                !refreshToken && 'Refresh token is undefined.',
              ]
                .filter(Boolean)
                .join(' ');

              const e = new Error(errorMessage);
              ReactSentry.captureException(e);

              throw e;
            }

            await doc.incrementalModify((data) => {
              if (!data.user) return { user: null };
              data.user.token = token;
              data.user.refreshToken = refreshToken;

              return data;
            });
          } catch (response) {
            const status = (
              response as HttpClientResponse<
                components['schemas']['AuthResultV2']
              >
            )?.status;

            // in case of a failed tokenrefresh we log the user out
            // as we cannot recover if the refreshToken is inalid
            if ([400, 401].indexOf(status) !== -1) {
              onError();
            }
          }
        })
      )
    )
    .subscribe();
  return tokenRefreshSubject;
};
