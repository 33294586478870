/*
 * After the WW-3766 ticket changes, the way of retrieving `filter`
 * and `searchParams` values was modified.
 *
 * This change broke `EntityWidget` instances that were created before
 * WW-3766 was implemented.
 *
 * To maintain backward compatibility, this hook ensures proper handling.
 *
 * - If `definition.filters` has a value, it means the `EntityWidget`
 *   was created before the WW-3766 changes. In that case, we use its
 *   value to determine `filter` and `searchParams`.
 * - If `definition.filters` is not set, the `EntityWidget` was created
 *   after the WW-3766 changes were merged, so we determine `filter` and
 *   `searchParams` from `definition.filter` and `definition.tableUrl`.
 */

import { prepareFilters } from '@work4all/components';

import { useDeepMemo } from '@work4all/utils/lib/hooks/use-deep-memo';

import { stringifyTableSettings } from '../../../../components/data-tables/make-table-settings';

import { EntityWidgetDefinition } from './HomeWidgetsContextProvider';

const DEFAULT_FILTER = [];

export const useEntityWidgetFilterAndSearchParams = (
  definition: EntityWidgetDefinition
) => {
  return useDeepMemo(() => {
    const filter = getEntityWidgetFilter(definition);
    const searchParams = getEntityWidgetSearchParams(definition);

    return { filter, searchParams };
  }, [definition]);
};

const getEntityWidgetFilter = (definition: EntityWidgetDefinition) => {
  const prefilter = definition.prefilter ?? DEFAULT_FILTER;
  const filter =
    (definition.filters && definition.filters.length > 0
      ? prepareFilters(definition.filters).filter
      : definition.filter) ?? DEFAULT_FILTER;

  return [...filter, ...prefilter];
};

const getEntityWidgetSearchParams = (definition: EntityWidgetDefinition) => {
  const searchParams = new URLSearchParams();

  if (definition.tableUrl) {
    searchParams.set('settings', definition.tableUrl);
  } else {
    searchParams.set(
      'settings',
      stringifyTableSettings({
        filter:
          definition.filters?.map((filter) => {
            return {
              ...filter,
              value: filter?.value?.value,
            };
          }) || [],
      })
    );
  }

  return searchParams;
};
