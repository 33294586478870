import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ResourcePlanningColumnTimeUnit } from '../../settings/settings';

import { useProjectPlanningData } from './planning-columns/components/ProjectPlanningDataProvider';
import { getFactor } from './planning-columns/utils';
import { ProjectRessourceClassCell } from './ProjectRessourceClassCell';

export type ProjectPlanningCellProps = {
  children?: React.ReactNode;
  value: string | number;
  column?: {
    id: string;
    cellParams: {
      rc: LookUp;
      resourcePlanningColoumnTimeUnit: ResourcePlanningColumnTimeUnit;
      onCellSelect?: (props: {
        project: Project;
        ressourceClass: LookUp;
      }) => void;
      selectedRessourceClassCell?: { project: Project; ressourceClass: LookUp };
      entity: Entities.calculation | Entities.contract;
      onUtilizationClick?: (userIds: number[], project: Project) => void;
    };
  };
  row?: {
    original: Project;
  };
};

export const ProjectPlanningCell = (props: ProjectPlanningCellProps) => {
  const {
    row: { original: project },
    column: {
      cellParams: {
        rc,
        resourcePlanningColoumnTimeUnit,
        onCellSelect,
        onUtilizationClick,
        selectedRessourceClassCell,
        entity,
      },
    },
  } = props;

  const {
    appointmentList,
    appointmentLoading,
    erpObjectList,
    erpObjectListLoading,
    timeTrackingList,
    timeTrackingListLoading,
  } = useProjectPlanningData();

  if (!project) {
    return;
  }
  const allProjectIds = [
    project?.id,
    ...(project.childProjects || []).map((pr) => pr?.id),
  ];

  const relevantAppointments = appointmentList
    .filter((tr) => {
      return allProjectIds.includes(tr.projectId);
    })
    ?.filter((app) => app.projectProcess?.ressourceClassId === rc.id);

  const planned =
    relevantAppointments
      .map((app) => app.projectProcessDuration)
      .reduce((a, b) => a + b, 0) *
    getFactor('day', resourcePlanningColoumnTimeUnit);

  const relevantTimeTrackings = timeTrackingList
    .filter((tr) => {
      return allProjectIds.includes(tr.projectId);
    })
    ?.filter((tr) => tr.projectProcess?.ressourceClassId === rc.id);

  const actual =
    relevantTimeTrackings.map((app) => app.amount).reduce((a, b) => a + b, 0) *
    getFactor('hour', resourcePlanningColoumnTimeUnit);

  const relevantErpObjects = erpObjectList.filter((erp) => {
    return allProjectIds.includes(erp.projectId);
  });

  const positionList = relevantErpObjects.flatMap((erp) => erp.positionList);

  const positions = positionList?.filter(
    (pos) => pos?.article?.ressourceClassId === rc.id
  );
  const target = positions
    ?.map((x) => {
      const factor = getFactor(x.unit, resourcePlanningColoumnTimeUnit);
      return x.amount * factor;
    })
    ?.reduce((a, b) => {
      return a + b;
    }, 0);

  return (
    <ProjectRessourceClassCell
      ressourceClass={rc}
      target={target}
      actual={actual}
      planned={planned}
      targetLoading={erpObjectListLoading}
      actualLoading={timeTrackingListLoading}
      plannedLoading={appointmentLoading}
      onClick={() => {
        onCellSelect?.({
          project,
          ressourceClass: rc,
        });
      }}
      selected={
        project.id === selectedRessourceClassCell?.project?.id &&
        rc.id === selectedRessourceClassCell?.ressourceClass?.id
      }
      appointmentList={relevantAppointments}
      timeTrackingList={relevantTimeTrackings}
      erpList={relevantErpObjects}
      erpEntityType={entity}
      onUtilizationClick={(userIds) => {
        onUtilizationClick?.(userIds, project);
      }}
    />
  );
};
