import React from 'react';
import { TableInstance } from 'react-table';

import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';

import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import schema from '../schemata/task-table-schema.json';
import { TaskPriorityCell } from '../TaskPriorityCell';
import { TaskStatusCell } from '../TaskStatusCell';

import { useTaskBoard } from './use-task-board';

export type ITaskPriorityCellConfig = {
  type: 'TaskPriority';
};

export type ITaskStatusCellConfig = {
  type: 'TaskStatus';
  params: {
    ownerIdField: string;
  };
};

type ITaskTableCustomColumns = {
  TaskStatus?: ITaskStatusCellConfig;
  TaskPriority?: ITaskPriorityCellConfig;
};

const defaultSort = [{ field: 'date', direction: SortDirection.DESCENDING }];
const forceRequestFields: Task = { status: null };

export const TasksTable = React.forwardRef<TableInstance, IEntityTable>(
  function TasksTable(_props, ref) {
    const { entity: entityType } = schema as never;

    const { onTableChange, prefilter, ...board } = useTaskBoard();
    const tableProps = {
      schema: schema as never,
      cells: {
        TaskStatus: TaskStatusCell,
        TaskPriority: TaskPriorityCell,
      },
      cellProcessors: {
        TaskStatus(config) {
          return [config.accessor, 'user.id'];
        },
      },
      defaultSort,
      forceRequestFields,
    };
    const dataTable = useEntityDataTable<Task, ITaskTableCustomColumns>({
      ...tableProps,
      prefilter,
    });

    return (
      <EntityTable
        ref={ref}
        entityType={entityType}
        onTableChange={onTableChange}
        {...dataTable}
        {..._props}
        board={{ ...board, ...tableProps }}
      />
    );
  }
);
