import md5 from 'md5';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { components, httpClient, useUser } from '@work4all/data';

import { PasswordChangeDialog, PasswordData } from './PasswordChangeDialog';

export const usePasswordChangeDialog = () => {
  const dialogs = useDialogs();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const user = useUser();

  const [loading, setLoading] = useState(false);

  const performPasswordChange = useCallback(
    async (data: PasswordData) => {
      const oldPasswordHash = md5(data.oldPassword);
      const newPasswordHash = md5(data.newPassword);
      const benutzerCode = user.benutzerCode;

      setLoading(true);

      try {
        const response = await httpClient.put<
          components['schemas']['DefaultResponse'],
          components['schemas']['ChangePasswordRequest']
        >({
          url: `${user.baseUrl}/api/work4all/benutzer/ChangePassword`,
          body: {
            benutzerCode,
            newPasswordHash,
            oldPasswordHash,
          },
          contentType: 'application/json',
          headers: {
            'x-work4all-apiurl': user.baseUrl,
            Authorization: `Bearer ${user.token}`,
          },
        });

        return response.data;
      } catch (error) {
        return { ok: false, message: error };
      } finally {
        setLoading(false);
      }
    },
    [user.baseUrl, user.benutzerCode, user.token]
  );

  return useCallback(
    () =>
      new Promise<boolean>((resolve) => {
        const onClose = () => {
          resolve(false);
          dialogs.close(id);
        };

        const onConfirm = async (
          data: PasswordData,
          setError: UseFormSetError<PasswordData>
        ) => {
          const { ok, message } = await performPasswordChange(data);

          if (ok) {
            resolve(true);
            dialogs.close(id);
          } else if (
            message === 'Das angegebene aktuelle Passwort ist nicht korrekt.'
          ) {
            setError('oldPassword', {
              type: 'manual',
              message: t('ALERTS.PASSWORD_INCORRECT'),
            });
          } else {
            enqueueSnackbar(t('ALERTS.ERROR.UNKNOWN'), {
              variant: 'error',
              autoHideDuration: 3000,
            });
          }
        };

        const { id } = dialogs.open(PasswordChangeDialog, {
          onConfirm,
          onClose,
          loading,
        });
      }),
    [dialogs, enqueueSnackbar, loading, performPasswordChange, t]
  );
};
