import styles from './EditableCell.module.scss';

import { Box, Input, TextareaAutosize } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import React, { useEffect, useRef, useState } from 'react';
import { CellProps } from 'react-table';

import {
  formatAsFloat,
  formatInputValueAsFloat,
  parseAsFloat,
} from '@work4all/utils';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { Unit } from '../../../../../../input/labeled-currency-input/components/unit/Unit';
import { EditModeCell } from '../../../../plugins/useEditMode';
import { ExtendedNumberCell } from '../../../../utils';

export interface EditableCellNumericProps {
  min?: number;
  max?: number;
  step?: number;
  transform?: (
    input: string | number | DateTime<true> | DateTime<false>
  ) => string | number | DateTime<true> | DateTime<false>;
  transformInputValue?: (input: string) => string;
  unit?: string;
}

interface EditableCellUserProps extends EditableCellNumericProps {
  onSubmit?: () => void;
  onExit?: () => void;
  onNext?: (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
    dir: 1 | -1
  ) => void;
  onChange?: (input: string) => void;
  onSelectionChange?: (start?: number, end?: number) => void;
  selectinRange: { start: number; end: number };
  autoFocus?: boolean;
  textarea?: boolean;
  type?: string;
  disabled?: boolean;
  isValueVisble?: boolean;
  isVisible: boolean;
  maxHeight: number;
  showZeros?: boolean;
  shrinked?: boolean;
  isEditMode?: boolean;
  isEditable?: boolean;
  maxLines?: number;
  stopPropagation?: boolean;
}

export interface EditableCellProps
  extends CellProps<Record<string, unknown>>,
    EditableCellUserProps {
  cell: EditModeCell & ExtendedNumberCell;
}

const resultWithZero = (value: string | number, result: string | number) => {
  return result;
};

const resultWithoutZero = (value: string | number, result: string | number) =>
  value?.toString() === '0' ? ' ' : result;

const dateTransform = (value: string) => {
  return DateTime.fromISO(value).toFormat('yyyy-MM-dd');
};

export const EditableCell: React.FC<Partial<EditableCellProps>> = (
  props: EditableCellProps
) => {
  const initialConvert = props.showZeros ? resultWithZero : resultWithoutZero;

  const convert = () => {
    if (props.type === 'date') return dateTransform(props.value);
    return props.type === 'number'
      ? initialConvert(
          props.value,
          formatAsFloat(props.value, {
            locale: 'de',
            isFinished: true,
            ...props.cell.column.cellParams,
          })
        )
      : props.value;
  };

  const initialValue = convert();

  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const { autoFocus, onEdit } = props.cell.getEditableCellProps?.() ?? {
    onEdit: () => {},
  };

  function transformBeforeExit(value: string) {
    const action = () => {
      if (props.type === 'date') {
        if (!value) return value;
        return DateTime.fromFormat(value, 'yyyy-MM-dd');
      }
      if (props.type === 'time') {
        return DateTime.fromFormat(value, 'HH:mm');
      }
      if (props.type === 'number') {
        const resultValue = parseAsFloat(value) || 0;
        return resultValue;
      }

      return value;
    };

    const result = action();
    if (props.transform) {
      return props.transform(result);
    }
    return result;
  }

  const setSelection = props.onSelectionChange
    ? (
        event: null | {
          currentTarget: { selectionStart: number; selectionEnd: number };
        }
      ) => {
        if (!event) {
          props.onSelectionChange();
        } else if (
          event.currentTarget?.selectionStart &&
          event.currentTarget?.selectionEnd
        )
          props.onSelectionChange(
            event.currentTarget.selectionStart,
            event.currentTarget.selectionEnd
          );
      }
    : undefined;

  useEffect(() => {
    if (props.type === 'date') {
      const transformed = DateTime.fromISO(initialValue).toFormat('yyyy-MM-dd');
      setValue(transformed);
    }
    if (props.type === 'time') {
      const transformed = DateTime.fromISO(initialValue).toLocaleString(
        DateTime.TIME_SIMPLE
      );
      setValue(transformed);
    } else if (props.type === 'number') {
      const currentValueParsed = parseAsFloat(value);
      if (props.value === currentValueParsed) {
        return;
      }
      const floatValue = formatAsFloat(initialValue, {
        locale: 'de',
        isFinished: true,
        ...props.cell.column.cellParams,
      });
      setValue(floatValue);
    } else {
      setValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.cell.column.cellParams, props.type, initialValue]);

  const onChange = (event) => {
    let value = event.target.value;
    if (props.type === 'text' && props.maxLines) {
      value = value.split('\n').slice(0, props.maxLines).join('\n');
    }
    if (props.type === 'number') {
      value = formatInputValueAsFloat(event.currentTarget, {
        locale: 'de',
        ...props.cell.column.cellParams,
      });
      if (props.transformInputValue) {
        value = props.transformInputValue(value);
      }
    }
    setValue(value);
    const resultValue = transformBeforeExit(value);
    onEdit(resultValue, 'update');
    props.onChange?.(value);
  };

  const [isFocused, setIsFocused] = useState(false);

  function onBlur(event) {
    if (props.type === 'number') {
      const result = formatInputValueAsFloat(event.currentTarget, {
        locale: 'de',
        isFinished: true,
        ...props.cell.column.cellParams,
      });
      setValue(result);
    }
    setSelection?.(null);
    const resultValue = transformBeforeExit(value);
    setIsFocused(false);
    if (props.onNext) {
      onEdit(resultValue, 'blur-update');
      return;
    }
    onEdit(resultValue, 'blur');
    props.onExit?.();
  }

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (setSelection) setTimeout(() => setSelection(event), 0);
    switch (event.key) {
      case 'Tab': {
        const resultValue = transformBeforeExit(value);
        event.stopPropagation();
        onEdit(resultValue, 'update');
        props.onNext?.(event, event.shiftKey ? -1 : 1);
        break;
      }
      case 'Enter': {
        const resultValue = transformBeforeExit(value);
        if (props.type === 'number') {
          event.stopPropagation();
          onEdit(resultValue, 'update');
          props.onExit?.();
        }
        break;
      }
    }
  };
  const onFocusHandle = (target: HTMLTextAreaElement | HTMLInputElement) => {
    if (props.selectinRange) {
      target.setSelectionRange(
        props.selectinRange.start,
        props.selectinRange.end
      );
      return;
    }
    target.select();
    setIsFocused(true);
  };

  const onFocus = (e) => {
    onFocusHandle(e.currentTarget);
    setSelection?.(e);
  };
  const focusOnVisibleCell = autoFocus && props.isVisible;

  const inputRef = useRef<HTMLTextAreaElement>();
  const innerProps = {
    autoFocus: props.autoFocus ?? focusOnVisibleCell,
    value:
      props.isValueVisble || props.isValueVisble === undefined
        ? (props.shrinked ||
            (!props.isEditMode && props.rowSizeMode !== 'AUTO')) &&
          props.type === 'text'
          ? truncateInputText(inputRef, value)
          : value
        : '',
    onChange,
    onBlur,
    onKeyDown,
    onFocus,
    type: props.type === 'number' ? 'text' : props.type,
    readOnly:
      props.disabled ||
      props.shrinked ||
      !props.isEditable ||
      !props.isEditMode,
  };

  const manualFocus = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      if (
        !isFocused &&
        innerProps.autoFocus &&
        inputRef.current &&
        !manualFocus.current
      ) {
        manualFocus.current = true;
        onFocusHandle(inputRef.current);
      }
    }, 0);
  }, [isFocused, innerProps.autoFocus]);

  const inputStrictHandlers = {
    onSelect: setSelection,
    onInput: setSelection,
    onMouseDown: setSelection,
  };
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (inputRef.current) forceUpdate();
  }, []);

  return props.textarea ? (
    <Box
      className={clsx(styles.input, {
        [styles.focused]: isFocused,
        [styles.inputPointers]: !props.stopPropagation,
        [styles.noBorder]:
          !props.isEditMode || props.shrinked || !props.isEditable,
      })}
    >
      <TextareaAutosize
        ref={inputRef}
        {...innerProps}
        {...inputStrictHandlers}
        className={clsx(styles.innerInput, styles.inputPadding, {
          // Pixel perfect, we lose somewhere 0.5px only when fontSize is 14px
          [styles.inputPaddingFix]:
            document.documentElement.style.fontSize === '14px',
          [styles.multiLine]: value?.split('\n').length > 1,
          [styles.disabled]: !props.isEditable,
        })}
        // Important that shrinked has a value and is not undefined
        minRows={
          props.maxLines ? props.maxLines : props.shrinked === false ? 4 : 1
        }
        style={{
          resize: 'none',
          maxHeight: props.maxHeight,
          overflowY: 'auto',
          ...props.css,
        }}
      />
    </Box>
  ) : (
    <Input
      inputRef={inputRef}
      {...innerProps}
      className={clsx(styles.input, styles.inputPaddingText, {
        [styles.focused]: isFocused,
        [styles.inputPointers]: !props.stopPropagation,
        [styles.noBorder]:
          !props.isEditMode || props.shrinked || !props.isEditable,
      })}
      classes={{
        input: clsx(styles.innerInput, {
          [styles.number]: props.type === 'number',
        }),
      }}
      style={props.css}
      inputProps={{
        max: props.max,
        min: props.min,
        step: props.step,
        style: props.css,
        ...inputStrictHandlers,
        className: clsx({
          [styles.disabled]: !props.isEditable,
        }),
      }}
      endAdornment={props.unit ? <Unit unit={'%'} /> : undefined}
    />
  );
};

function truncateInputText(
  areaRef: React.RefObject<HTMLTextAreaElement>,
  text = ''
): string {
  if (typeof text !== 'string') return text;
  if (!text) return text;
  if (!areaRef.current) return '';

  const input = areaRef.current;
  const font = window.getComputedStyle(input).font;

  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return '';

  context.font = font;

  const ellipsis = '...';

  // Calculate maxWidth as 90% of textarea width
  const maxWidth = input.clientWidth * 0.9;

  // Calculate max visible rows based on height and line-height
  const lineHeight = parseFloat(window.getComputedStyle(input).lineHeight);
  const parentCell = input.closest('[role="cell"]'); // Find parent cell
  if (!parentCell) return text;

  const maxRows = Math.floor(parentCell.clientHeight / lineHeight);

  const rawLines = text.split('\n'); // Keep original lines

  let truncatedText = '';
  let currentRows = 0;
  let finalText = '';

  for (const rawLine of rawLines) {
    const words = rawLine.split(' '); // Split line into words
    let newLine = '';

    for (const word of words) {
      const testLine = newLine + (newLine ? ' ' : '') + word;
      const testWidth = context.measureText(testLine).width;

      if (testWidth > maxWidth) {
        if (currentRows + 1 >= maxRows) {
          if (finalText) {
            return finalText + ellipsis;
          }
          return truncatedText + newLine.trim() + ellipsis; // Truncate last visible line
        }
        truncatedText += newLine + '\n'; // Keep original line breaks
        newLine = word;
        currentRows++;
      } else {
        newLine = testLine;
      }
    }
    currentRows++;
    truncatedText += newLine;

    if (currentRows < maxRows) {
      truncatedText += '\n';
    } else if (!finalText) {
      finalText = truncatedText.trim();
    }
  }
  if (!finalText) return truncatedText.trim();

  // Calculate all rows to decide if we need ellipsis
  if (currentRows <= maxRows) {
    return finalText;
  } else {
    return finalText + ellipsis;
  }
}
