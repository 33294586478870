import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { TableRowDisplayModifiers } from '@work4all/components';
import { PrepareTableRowModifiers } from '@work4all/components/lib/dataDisplay/basic-table/plugins/useRowDisplayModifiers';

import { useDataMutation, useDeleteEntity, useUser } from '@work4all/data';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { VacationRequestStatus } from '@work4all/models/lib/Enums/VacationRequestStatus.enum';

import { canApproveVacations } from '@work4all/utils/lib/permissions';

import { useApendCustomListSettings } from '../../../containers/file-entities-lists/useApendCustomListSettings';
import { usePageTitle } from '../../../hooks';
import { useBottomViews } from '../../bottom-views';
import { ApprovalStatuses, useApprovalActions } from '../approval-actions';
import { ApprovalContextProvider } from '../approval-actions/approval-context';
import { EntityTable, IEntityTable } from '../entity-table/EntityTable';
import { useEntityDataTable } from '../entity-table/use-entity-data-table';
import { GridStateProvider } from '../grid/GridStateProvider';
import schema from '../schemata/vacationApproval-table-schema.json';
import { useTableLayoutState } from '../table-layout';
import { useErpHandlersTemplate } from '../use-erp-handlers-template';
import { useUserColumnConfigs } from '../use-user-column-configs';
import { VacationEndDateCell } from '../VacationEndDateCell';

import { VacationStatusCardIconCell } from './cells/VacationStatusCardIconCell';
import { VacationStatusCell } from './cells/VacationStatusCell';
import { FullCalendarView } from './components/FullCalendarView';
import { mapSelectedRowStatus } from './utils';

const defaultSort = [
  { field: 'insertTime', direction: SortDirection.DESCENDING },
];

const forceRequestFields: VacationRequestEntity = {
  vacationItems: [
    {
      id: null,
      approveDate: null,
      date: null,
      vacationApprover: {
        id: null,
        displayName: null,
      },
    },
  ],
};

const DISABLED_COLUMNS = ['unused'];

export const VacationApprovalTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function VacationApprovalTable(_props, ref) {
  const { t } = useTranslation();

  const user = useUser();
  const canApprove = canApproveVacations(user);
  const layoutState = useTableLayoutState();
  const [layout] = layoutState;
  const template = useErpHandlersTemplate('supplier.id', Entities.supplier);

  const entityType = Entities.vacationRequestEntity;

  const dataTable = useEntityDataTable<VacationRequestEntity, never>({
    schema: schema as never,
    defaultSort,
    enableFooter: true,
    cells: {
      VacationStatus: VacationStatusCell,
      VacationStatusCardIcon: VacationStatusCardIconCell,
      VacationEndDate: VacationEndDateCell,
    },
    forceRequestFields,
  });

  const bottomArea = useBottomViews({
    skip: !dataTable.selectedEntity,
    size: 'fill',
    views: [
      {
        type: 'custom',
        content: (
          <FullCalendarView
            beginDate={
              dataTable.selectedEntity?.dateFrom ??
              dataTable.selectedEntity?.vacationItems[0]?.date
            }
            department={dataTable.selectedEntity?.user?.departmentName}
          />
        ),
        titleTranslationKey: 'VACATION.TITLE',
      },
    ],
  });

  const [deleteEntity] = useDeleteEntity(false);

  const [mutate] = useDataMutation({
    entity: Entities.vacation,
    mutationType: EMode.upsert,
    responseData: { id: null },
    translateRelationInput: false,
  });

  const handleStatusChange = async (id: string, status: ApprovalStatuses) => {
    const vacationDayIds = (dataTable.data as VacationRequestEntity[])
      ?.find((request) => request.id === id)
      ?.vacationItems?.map((vacationItem) => vacationItem.id);
    const dateNow = DateTime.now().toISO();

    if (status === ApprovalStatuses.approve) {
      await Promise.all(
        vacationDayIds.map((vacationId) =>
          mutate({
            id: vacationId,
            approveDate: dateNow,
          })
        )
      );
      await dataTable?.refetch();
    } else if (status === ApprovalStatuses.reject) {
      await deleteEntity({
        ids: [...vacationDayIds.map(String)],
        type: Entities.vacation,
      });
      await dataTable?.refetch();
    } else {
      return null;
    }
  };

  const { approvalActionDefinitions } = useApprovalActions({
    seletedRowStatus: mapSelectedRowStatus(dataTable?.selectedEntity?.status),
    canApprove,
    canForward: false,
    hideForward: true,
    editHandler: undefined,
    statusHandler: handleStatusChange,
  });

  const [userConfig, userConfigMethods] = useUserColumnConfigs({
    layout,
    entityType,
    columnConfigs: dataTable?.columnConfigs,
  });

  const prepareRowDisplayModifiers = useCallback<
    PrepareTableRowModifiers<VacationRequestEntity>
  >((row) => {
    const isApproved = row.status === VacationRequestStatus.DONE;
    const isRejected = row.status === VacationRequestStatus.REJECTED;
    const isShade1 = isApproved || isRejected;

    const modifiers: TableRowDisplayModifiers = { isShade1 };

    return modifiers;
  }, []);

  usePageTitle(t('APPROVAL_CENTER.VACATION.PAGE_TITLE'));
  useApendCustomListSettings(entityType);

  return (
    <GridStateProvider>
      <ApprovalContextProvider value={{ onClick: handleStatusChange }}>
        <EntityTable
          ref={ref}
          template={template}
          areas={{
            bottom: bottomArea,
          }}
          {...dataTable}
          displayFooter
          overrides={{
            actions: {
              custom: {
                hideDivider: true,
                left: approvalActionDefinitions,
              },
              resetColumns: userConfigMethods?.remove,
            },
          }}
          disabledColumns={DISABLED_COLUMNS}
          columnConfigs={userConfig ?? []}
          selectableMultiple={false}
          prepareRowDisplayModifiers={prepareRowDisplayModifiers}
        />
      </ApprovalContextProvider>
    </GridStateProvider>
  );
});
