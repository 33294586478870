import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

export const sdObjTypeToEntity = (typeName: string, throwError = false) => {
  switch (typeName) {
    case SdObjType.KUNDE:
      return Entities.customer;
    case SdObjType.LIEFERANT:
      return Entities.supplier;
    case SdObjType.PROJEKT:
      return Entities.project;
    default:
      console.warn(`There is unkown type ${typeName}`);
  }

  if (throwError) throw new Error(`Type name ${typeName} is not defined`);
  return null;
};
