import { translateField } from '../../translate-utils';

import { PrepareGroupedRowFooterDataFields } from './types';

export const prepareGroupedRowFooterDataFields = ({
  columns,
  entity,
}: PrepareGroupedRowFooterDataFields) => {
  return columns.map((field) => ({
    field: translateField(field, entity),
    alias: field,
    aggregations: ['SUM'],
    dontAggregate: true,
  }));
};
