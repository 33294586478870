import { useCallback } from 'react';

import { useDataMutation } from '@work4all/data';

import { BzObjectConversionResult } from '@work4all/models/lib/Classes/BzObjectConversionResult.entity';
import { ErpObjectConversionRequest } from '@work4all/models/lib/Classes/ErpObjectConversionRequest.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { convertEntityToBzObjType } from '@work4all/models/lib/utils/convertEntityToBzObjType';

interface UseConvertBzObjectMutationOptions {
  targetEntity: Entities;
}

interface ConvertBzObjectMutationArgs
  extends Pick<
    ErpObjectConversionRequest,
    'options' | 'positionDetails' | 'targetNewName'
  > {
  sourceEntity: Entities;
  sourceIds: number[];
  targetEntity?: Entities;
}

export const useConvertBzObjectMutation = (
  opt?: UseConvertBzObjectMutationOptions
) => {
  const { targetEntity } = opt ?? {};
  const [mutate] = useDataMutation<BzObjectConversionResult, EMode.upsert>({
    entity: Entities.bzObjectConversionResult,
    mutationType: EMode.upsert,
    // TODO: workaround - there is no difference between Input & Output types in hook ?
    wrapInput: false,
    responseData: {
      errors: [
        {
          property: null,
          data: null,
          validationDataType: null,
          apiValidationErrorType: null,
        },
      ],
      bzObject: targetEntity
        ? {
            [targetEntity]: {
              id: null,
            },
          }
        : {},
    },
  });

  const callback = useCallback(
    async (args: ConvertBzObjectMutationArgs) => {
      const { sourceEntity, targetEntity, sourceIds, ...rest } = args;

      const request: ErpObjectConversionRequest = {
        sourceBzObjectType: convertEntityToBzObjType(sourceEntity),
        sourceBzObjectCodes: sourceIds,
        targetBzObjectType: convertEntityToBzObjType(targetEntity),
        ...rest,
      };
      return (await mutate({ request })) as Promise<{ errors: [] }>;
    },
    [mutate]
  );
  return {
    mutate: callback,
  };
};
