import styles from '../StatisticPage.module.scss';

import { LinearProgress } from '@mui/material';
import { DateTime } from 'luxon';
import { useObservableState } from 'observable-hooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { StatisticSalesVolumeByCustomerTable } from '@work4all/components/lib/dataDisplay/basic-table/reporting/StatisticSalesVolumeByCustomerTable';

import { useDataProvider } from '@work4all/data';

import { CostCenter } from '@work4all/models/lib/Classes/CostCenter.entity';
import { StatisticSalesVoumeByCustomer } from '@work4all/models/lib/Classes/StatisticSalesVoumeByCustomer.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { StatisticTimeRange } from '@work4all/models/lib/Enums/StatisticTimeRange.enum';

import { CostCenterPickerField } from '../../../components/entity-picker/CostCenterPickerField';
import { settings, useSetting } from '../../../settings';
import { useTableBorders } from '../../../utils/use-table-borders/use-table-borders';
import { GeneralSetting, useGeneralSetting } from '../util/use-general-setting';
import { useOldestInvoice } from '../util/use-oldest-invoice';

import { DateWheelPickerField } from './date-wheel-picker-field/DateWheelPickerField';

function createInitialDate() {
  return DateTime.now().startOf('year').toJSDate();
}

export function StatisticSalesVolumeByCustomerContainer() {
  const statisticUseDeliveryPeriod = useSetting(
    settings.statisticUseDeliveryPeriod()
  );

  const statisticConsiderInvoiceLedger = useSetting(
    settings.statisticConsiderInvoiceLedger()
  );
  const [currentCostCenter, setCurrenCostCenter] = useState<CostCenter>(null);

  const oldestInvoice = useOldestInvoice();
  const startBusinessYearSetting = useGeneralSetting({
    name: GeneralSetting.startBusinessYear,
  });

  const { t } = useTranslation();

  const [currentDate] = useState(createInitialDate);
  const [currentYear, setCurrentYear] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    currentDate
  );
  const [maxYear, setMaxYear] = useState(currentDate);

  useEffect(() => {
    const businessYearStartMonth =
      startBusinessYearSetting?.data?.[0]?.value || 1;
    const year =
      currentDate.getMonth() < businessYearStartMonth
        ? currentDate.getFullYear() - 1
        : currentDate.getFullYear();
    const date = DateTime.local(year, businessYearStartMonth, 1).toJSDate();

    setCurrentYear(date);
    setMaxYear(date);
  }, [currentDate, setCurrentYear, setMaxYear, startBusinessYearSetting]);

  const requestData: DataRequest = useMemo(() => {
    const currentYearToDateTime = DateTime.fromJSDate(currentYear);
    const startDate = currentYearToDateTime;
    const endDate = currentYearToDateTime.plus({ month: 11 }).endOf('month');

    const data: StatisticSalesVoumeByCustomer = {
      start: null,
      end: null,
      timeSpanUnit: null,
      columnCount: null,
      columns: [
        {
          rangeStart: null,
          rangeEnd: null,
          name: null,
          rowCount: null,
          rows: [
            {
              date: null,
              objId: null,
              amount: null,
              kunde: {
                number: null,
                name: null,
                id: null,
              },
            },
          ],
        },
      ],
    };

    return {
      entity: Entities.statisticSalesVoumeByCustomer,
      data: data,
      vars: {
        rangeType: StatisticTimeRange.MONTH,
        rangeStart: startDate.toFormat('yyyy-MM-dd'),
        rangeEnd: endDate.toFormat('yyyy-MM-dd'),
        onlyRaInvoices: statisticConsiderInvoiceLedger.value,
        useDifferentBillingAddressIfUsed: false,
        costCenterFilter: currentCostCenter?.id,
        useDeliveryPeriod: statisticUseDeliveryPeriod.value,
      },
      completeDataResponse: true,
    };
  }, [
    currentCostCenter,
    currentYear,
    statisticConsiderInvoiceLedger.value,
    statisticUseDeliveryPeriod,
  ]);

  const data = useDataProvider(requestData);

  const isLoading = data.loading || data.pending;

  const tableBorders = useTableBorders();

  return (
    <>
      {isLoading && (
        <LinearProgress
          style={{ position: 'absolute', width: '100%', zIndex: 200 }}
        />
      )}
      <div className={styles.controlBar}>
        <div style={{ width: '15rem', maxWidth: '50%' }}>
          <DateWheelPickerField
            min={
              oldestInvoice?.data?.[0]?.date
                ? new Date(oldestInvoice?.data?.[0]?.date)
                : new Date()
            }
            max={maxYear}
            unit="year"
            label={t('COMMON.BUSINESS_YEAR')}
            value={currentYear}
            onChange={(value) => {
              setCurrentYear(value);
            }}
          />
        </div>
        <div style={{ width: '15rem', maxWidth: '50%' }}>
          <CostCenterPickerField
            label={`${t('COMMON.FILTER_BY')} ${t('COMMON.COST_CENTER')}`}
            value={currentCostCenter}
            onChange={(value) => {
              setCurrenCostCenter(value);
            }}
          />
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <StatisticSalesVolumeByCustomerTable
          tableClasses={tableBorders}
          data={data.data as StatisticSalesVoumeByCustomer}
        />
      </div>
    </>
  );
}
