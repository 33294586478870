import styles from './ObjectProtectionButton.module.scss';

import { Lock, LockOpen, Warning } from '@mui/icons-material';
import { IconButton, Popover, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserGroupPicker } from '@work4all/components/lib/components/entity-picker/user-group-picker/UserGroupPicker';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';

import { useDataMutation, useUser } from '@work4all/data';
import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';
import { useUsersContext } from '@work4all/data/lib/hooks/users';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { InputKundeRelation } from '@work4all/models/lib/Classes/InputKundeRelation.entity';
import { InputLieferantRelation } from '@work4all/models/lib/Classes/InputLieferantRelation.entity';
import { InputProjektRelation } from '@work4all/models/lib/Classes/InputProjektRelation.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { UserGroup } from '@work4all/models/lib/Classes/UserGroup.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ObjectProtectionState } from '@work4all/models/lib/Enums/ObjectProtectionState.enum';

const objectProtectionColor = {
  [ObjectProtectionState.LOCKED]: 'warning',
  [ObjectProtectionState.LOCKED_BUT_VISIBLE_FOR_YOU]: 'default',
  [ObjectProtectionState.NOT_LOCKED]: 'default',
} as const;

interface IObjectProtectionButtonProps {
  objectGroupProtectionState: ObjectProtectionState;
  objectGroups: UserGroup[];
  entity: Entities;
  parentId: number;
}

type RelationUnion =
  | InputKundeRelation
  | InputLieferantRelation
  | InputProjektRelation;

export const ObjectProtectionButton = (props: IObjectProtectionButtonProps) => {
  const {
    objectGroupProtectionState,
    objectGroups: initialObjectGroups = [],
    entity,
    parentId,
  } = props;

  const [objectGroups, setObjectGroups] = useState(initialObjectGroups);

  useEffect(() => {
    setObjectGroups(initialObjectGroups);
  }, [initialObjectGroups]);

  const [mutate] = useDataMutation<
    Customer | Supplier | Project,
    EMode.upsert,
    RelationUnion
  >({
    entity,
    mutationType: EMode.upsert,
    responseData: {
      objectGroupProtectionState: null,
      objectGroups: [
        {
          grIndex: null,
          grLevel: null,
          id: null,
          name: null,
        },
      ],
    } as Customer | Supplier | Project,
  });

  const { handleClick, ...popoverProps } = usePopoverState('bottom');

  const [saving, setSaving] = useState(false);

  const update = useCallback(async () => {
    setSaving(true);
    const add = objectGroups
      .filter((x) => !initialObjectGroups.find((y) => y.id === x.id))
      .map((x) => x.id);

    const remove = initialObjectGroups
      .filter((x) => !objectGroups.find((y) => y.id === x.id))
      .map((x) => x.id);

    const relations: RelationUnion = {
      objectGroups: {
        add,
        remove,
      },
    };

    await mutate({ id: parentId }, { relations });
    setSaving(false);
    popoverProps.onClose();
  }, [initialObjectGroups, mutate, objectGroups, parentId, popoverProps]);

  const [userGroups, setUserGroups] = useState<UserGroup[]>([]);

  const { t } = useTranslation();

  const lockIcon = useMemo(() => {
    return objectGroupProtectionState === ObjectProtectionState.NOT_LOCKED ? (
      <LockOpen />
    ) : (
      <Lock />
    );
  }, [objectGroupProtectionState]);

  const user = useUser();
  const { users } = useUsersContext();

  const showUpsertWarning = useMemo<boolean>(() => {
    const myGroupId = users.find((u) => u.id === user.benutzerCode)?.groupId;
    if (
      objectGroups.length > 0 &&
      !objectGroups.find((og) => og.id === myGroupId) &&
      objectGroupProtectionState !== ObjectProtectionState.LOCKED
    ) {
      return true;
    }

    return false;
  }, [objectGroupProtectionState, objectGroups, user.benutzerCode, users]);

  return (
    <>
      <Popover {...popoverProps}>
        <Stack p="0.5rem 1rem" gap="0.5rem" maxWidth="28rem">
          <Stack direction="row" gap="0.5rem">
            <Typography variant="h4">{t('FILE.OBJECT_PROTECTION')}</Typography>
          </Stack>

          <Typography variant="caption">
            {t('FILE.OBJECT_PROTECTION.ALLOW_USER_GROUPS', {
              entity: t('COMMON.' + entity.toUpperCase()),
            })}
          </Typography>
        </Stack>

        <Divider style={{ padding: 0 }} />
        <UserGroupPicker
          multiple
          onChange={setObjectGroups}
          value={objectGroups}
          maxItems={7}
          disabledItemsIds={
            objectGroupProtectionState === ObjectProtectionState.LOCKED ||
            saving
              ? userGroups.map((ug) => ug.id)
              : undefined
          }
          onListChanged={setUserGroups}
        />
        <Divider style={{ padding: 0 }} />

        <BaseActionButton
          title={t('INPUTS.SAVE')}
          className={styles.updateBtn}
          onClick={update}
          disabled={
            saving ||
            objectGroupProtectionState === ObjectProtectionState.LOCKED
          }
        />
        {showUpsertWarning && (
          <Stack
            p="0.5rem 1rem"
            direction="row"
            gap="0.25rem"
            alignItems="center"
            justifyContent="center"
          >
            <Warning color="error" />
            <Typography variant="caption" color="error">
              {t('FILE.OBJECT_PROTECTION.UPDATE_WARNING')}
            </Typography>
          </Stack>
        )}
      </Popover>
      <Tooltip
        title={t(
          'FILE.OBJECT_PROTECTION.TOOLTIP.' + objectGroupProtectionState
        )}
        placement="bottom-end"
      >
        <IconButton
          size="large"
          color={objectProtectionColor[objectGroupProtectionState]}
          onClick={handleClick}
        >
          {lockIcon}
        </IconButton>
      </Tooltip>
    </>
  );
};
