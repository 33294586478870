import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from '@work4all/data';

import { LanguageResource } from '@work4all/utils/lib/i18n/LanguageResource.enum';
import germanBaseTrans from '@work4all/utils/lib/i18n/translation-gen/de/de-base.json';
import germanBasaltTrans from '@work4all/utils/lib/i18n/translation-gen/de/de-customer-basalt-actiengesellschaft-wiedemar.json';
import germanServiceProviderTrans from '@work4all/utils/lib/i18n/translation-gen/de/de-domain-service-provider.json';
import englishBaseTrans from '@work4all/utils/lib/i18n/translation-gen/en/en-base.json';
import englishBasaltTrans from '@work4all/utils/lib/i18n/translation-gen/en/en-customer-basalt-actiengesellschaft-wiedemar.json';
import englishServiceProviderTrans from '@work4all/utils/lib/i18n/translation-gen/en/en-domain-service-provider.json';

import { settings, useSetting } from '../settings';

const customerTranslations: Record<
  number,
  {
    en: Record<string, string>;
    de: Record<string, string>;
  }
> = {
  // Basalt Actiengesellschaft Wiedemar -> we will change this to be dealt with by a server component once all related tickets of https://work4all.atlassian.net/browse/WW-4871 are done
  '68571': {
    en: englishBasaltTrans,
    de: germanBasaltTrans,
  },
};

/**
 * The LanguageManager regulates i18n ResourceBundles depending on (currently only one) setting.
 * In the future there might be further settings regulating which translation keys should be used app-wide.
 **/

export interface LanguageManagerCtx {
  addResource: (value: LanguageResource) => void;
  removeResource: (value: LanguageResource) => void;
  resources: LanguageResource[];
}
export const LanguageManagerContext =
  React.createContext<LanguageManagerCtx | null>(null);

export const LanguageManagerProvider = (props) => {
  const [resources, setResources] = useState<LanguageResource[]>([]);

  const { i18n } = useTranslation();

  const user = useUser();
  const customerId = user.kundennummer;

  const { value: isServiceProvider, set: setIsServiceProvider } = useSetting(
    settings.languageManagerIsServiceProvider()
  );

  const addResource = useCallback(
    (value: LanguageResource) => {
      switch (value) {
        case LanguageResource.ServiceProvider:
          setIsServiceProvider(true);
      }
    },
    [setIsServiceProvider]
  );

  const removeResource = useCallback(
    (value: LanguageResource) => {
      switch (value) {
        case LanguageResource.ServiceProvider:
          setIsServiceProvider(false);
      }
    },
    [setIsServiceProvider]
  );

  const updateResourceBundles = useCallback(() => {
    let germanTrans = { ...germanBaseTrans };
    let englishTrans = { ...englishBaseTrans };
    const activeResources: LanguageResource[] = [];

    if (isServiceProvider) {
      germanTrans = { ...germanTrans, ...germanServiceProviderTrans };
      englishTrans = { ...englishTrans, ...englishServiceProviderTrans };
      activeResources.push(LanguageResource.ServiceProvider);
    }

    if (customerTranslations[customerId]) {
      Object.assign(germanTrans, customerTranslations[customerId].de);
      Object.assign(englishTrans, customerTranslations[customerId].en);
    }

    setResources(activeResources);

    i18n.removeResourceBundle('de', 'translation');
    i18n.removeResourceBundle('en', 'translation');

    i18n.addResourceBundle('de', 'translation', germanTrans);
    i18n.addResourceBundle('en', 'translation', englishTrans);

    i18n.init();
  }, [customerId, i18n, isServiceProvider]);

  useEffect(() => {
    updateResourceBundles();
  }, [updateResourceBundles]);

  return (
    <LanguageManagerContext.Provider
      value={{
        addResource,
        removeResource,
        resources,
      }}
    >
      {props.children}
    </LanguageManagerContext.Provider>
  );
};

export const useLanguageManager = () => {
  return useContext(LanguageManagerContext);
};
