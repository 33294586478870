import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { CategoryClass } from '@work4all/models/lib/Classes/CategoryClass.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useMandatoryCategoryClasses = (
  entity: Entities,
  contactCategories?: boolean
) => {
  const request = useMemo<DataRequest>(() => {
    return {
      data: {
        id: null,
        mandatoryCustomers: null,
        mandatorySuppliers: null,
        name: null,
      } as CategoryClass,
      entity: Entities.categoryClass,
      completeDataResponse: false,
      filter: [
        {
          [entity === Entities.customer
            ? 'mandatoryCustomers'
            : 'mandatorySuppliers']: {
            $eq: true,
          },
        },
        contactCategories
          ? {
              isPersonCategory: {
                $eq: true,
              },
            }
          : null,
      ].filter(Boolean),
    };
  }, [contactCategories, entity]);

  return useDataProvider<CategoryClass>(request);
};
