import { useContext } from 'react';

import { OpenEmailMaskControllerContext } from './OpenEmailMaskControllerContext';

export const useOpenEmailMaskControllerContext = () => {
  const context = useContext(OpenEmailMaskControllerContext);

  if (!context) {
    throw new Error(
      'useOpenEmailMaskControllerContext must be used within OpenEmailMaskControllerProvider'
    );
  }
  return context;
};
