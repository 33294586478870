import styles from './TravelReceiptsPreview.module.scss';

import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TravelCostInvoiceKind } from '@work4all/models/lib/Classes/TravelCostInvoiceKind.entity';
import { TravelExpenseReportPaymentMethod } from '@work4all/models/lib/Classes/TravelExpenseReportPaymentMethod.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { formatCurrency, formatNumberAsCurrency } from '@work4all/utils';

import { useTableStateBag } from '../../../dataDisplay/basic-table';
import { MIME_TYPES } from '../../../preview/Preview';
import { ProjectPicker } from '../../entity-picker/project-picker/ProjectPicker';
import { formatters } from '../components/formatters';
import {
  PreviewContent,
  PreviewContentField,
} from '../components/PreviewContent';
import { DocumentPreview } from '../document-preview/DocumentPreview';
import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps, EntityPreviewProps } from '../types';

import { useGetTravelReceiptsStatus } from './use-get-travel-receipts-status';

type TravelReceiptsPreviewContainerProps = EntityPreviewContainerProps;

export const TRAVEL_RECEIPTS_DATA: TravelReceipts<EMode.query> = {
  id: null,
  note: null,
  description: null,
  file: [
    {
      id: null,
      fileInfos: {
        fileServiceProviderInfos: {
          id: null,
          exists: null,
          fspUrl: null,
        },
        previewUrl: null,
        previewMimeType: null,
        downloadUrl: null,
      },
    },
  ],
  projectId: null,
  project: { id: null, name: null, number: null },
  date: null,
  userId: null,
  paymentKind: null,
  expenditure: null,
  currency: {
    id: null,
    name: null,
  },
  travelExpenses: {
    id: null,
    user: {
      id: null,
      displayName: null,
      firstName: null,
      lastName: null,
      shortName: null,
    },
    closedByuserid: null,
    insertionREDate: null,
    businessPartner: {
      id: null,
      data: {
        supplier: {
          id: null,
          name: null,
        },
      },
    },
  },
  receiptKindTravelCost: {
    id: null,
    name: null,
  },
  paymentMethod: {
    id: null,
    name: null,
  },
  travelExpensesId: null,
  receiptKind: {
    id: null,
    name: null,
    typeOfReceiptType: null,
  },
};

export const TravelReceiptsPreviewFields: TravelReceipts<EMode.query> = {
  ...TRAVEL_RECEIPTS_DATA,
};

export function TravelReceiptsPreviewContainer(
  props: TravelReceiptsPreviewContainerProps
) {
  const { ids, ...handlers } = props;
  const entries = useEntityPreviewData<TravelReceipts>(
    ids,
    Entities.travelReceipts,
    TravelReceiptsPreviewFields
  );

  if (!entries) {
    return null;
  }

  return <TravelReceiptsPreview {...handlers} entries={entries} />;
}

type TravelReceiptsPreviewProps = EntityPreviewProps<TravelReceipts>;

const TravelReceiptsPreview = (props: TravelReceiptsPreviewProps) => {
  const tableStateBag = useTableStateBag();
  const defaultOnClose = useCallback(() => {
    if (tableStateBag?.tableInstance) {
      tableStateBag.tableInstance.toggleAllRowsSelected(false);
    }
  }, [tableStateBag]);

  const {
    onCloseClick = defaultOnClose,
    onEditClicked,
    onShareClicked,
    entries,
    onVisibilityToggle,
  } = props;

  const { t } = useTranslation();
  const getTravelReceiptsStatus = useGetTravelReceiptsStatus();

  const fields = useMemo<PreviewContentField<TravelReceipts>[]>(() => {
    return [
      {
        accessor: 'description',
        label: t('INPUTS.DESCRIPTION'),
      },
      {
        accessor: 'projectId',
        label: t('COMMON.PROJECT'),
        formatter: (_, entry) => entry.project?.name,
        disabled: false,
        getPicker: ({ entry, onEdit, isCommon }) => (
          <ProjectPicker
            multiple={false}
            value={isCommon ? entry.project : null}
            onChange={(value) =>
              onEdit({
                project: value,
                projectId: value ? value.id : 0,
              })
            }
          />
        ),
      },
      // TODO We should fix the `key` type to allow nested value.
      {
        accessor: 'id',
        label: t('COMMON.SUBMITTER'),
        formatter: (_, entry) =>
          entry.travelExpenses?.businessPartner?.data?.name,
      },
      {
        accessor: 'date',
        label: t('FIELDS.date'),
        formatter: formatters.dateSimple2year,
      },
      {
        accessor: 'receiptKind',
        label: t('COMMON.DOCUMENT_TYPE'),
        formatter(value: TravelCostInvoiceKind) {
          return value?.name;
        },
      },
      {
        closedByuserid: null,
        insertionREDate: null,
        accessor: 'id',
        label: t('COMMON.STATUS'),
        formatter(_, entry) {
          return getTravelReceiptsStatus(entry.travelExpenses).label;
        },
      },
      {
        accessor: 'paymentMethod',
        label: t('COMMON.PAYMENT_KIND'),
        formatter(value: TravelExpenseReportPaymentMethod) {
          return value?.name;
        },
      },
      {
        accessor: 'expenditure',
        label: t('COMMON.GROSS'),
        formatter(_, entry) {
          return `${formatNumberAsCurrency(entry.expenditure)} ${formatCurrency(
            entry.currency,
            t
          )}`;
        },
      },
    ];
  }, [getTravelReceiptsStatus, t]);

  const { description, fileInfos } = useMemo(() => {
    const entry = entries[0];

    return {
      description: entry?.description,
      fileInfos: entry?.file?.[0]?.fileInfos,
    };
  }, [entries]);

  const selectedRowsIdsList = useMemo(() => {
    return (
      tableStateBag?.tableInstance.selectedFlatRows?.map((x) => x.id) ?? []
    );
  }, [tableStateBag?.tableInstance.selectedFlatRows]);

  return (
    <div className={styles['preview-wrapper']}>
      {entries[0] ? (
        <DocumentPreview
          entries={entries}
          entity={Entities.travelReceipts}
          entityData={TravelReceiptsPreviewFields}
          titleKeyField="description"
          title={description}
          exists={fileInfos?.fileServiceProviderInfos?.exists}
          url={fileInfos?.previewUrl}
          mimeType={fileInfos?.previewMimeType as MIME_TYPES}
          noPreviewUrl={
            fileInfos?.fileServiceProviderInfos?.fspUrl ||
            fileInfos?.downloadUrl
          }
          downloadUrl={fileInfos?.downloadUrl}
          fspUrl={fileInfos?.fileServiceProviderInfos?.fspUrl}
          onCloseClick={onCloseClick}
          onEditClicked={onEditClicked}
          onShareClicked={onShareClicked}
          selectedRowsIdsList={selectedRowsIdsList}
          onVisibilityToggle={onVisibilityToggle}
        />
      ) : null}

      <div>
        <PreviewContent
          entries={entries}
          entity={Entities.travelReceipts}
          fields={fields}
        />
      </div>
    </div>
  );
};
