import { useEventCallback } from '@mui/material/utils';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import {
  AppParts,
  IUser,
  useCanView,
  useDataProvider,
  useRefetchOnEntityChanges,
} from '@work4all/data';

import { Appointment } from '@work4all/models/lib/Classes/Appointment.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Ticket } from '@work4all/models/lib/Classes/Ticket.entity';
import { TravelReceipts } from '@work4all/models/lib/Classes/TravelReceipts.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';
import { TicketStatus } from '@work4all/models/lib/Enums/TicketStatus.enum';

import { extendMailboxFolders } from '../../../../../components/data-tables/MailboxContentTable/extend-mailbox-folders';
import { useMailboxesData } from '../../../../../hooks/use-mailboxes-data';
import { settings, useSetting } from '../../../../../settings';
import { useProjectHoursState } from '../../../../time-tracker/ProjectHours';
import { useVacationStats } from '../../../../vacations/use-vacation-stats';

import { useRePermits } from './hooks/use-approval-center/use-re-permits';
import { useVacationPermits } from './hooks/use-approval-center/use-vacation-permits';
import { useBirthdays } from './hooks/use-birthdays';
import {
  useRefetchOnSubscription,
  useWidgetEntryData,
} from './use-widget-entry-data';

export function useRelevantData(props: {
  user: IUser;
  startDate: DateTime;
  endDate: DateTime;
  endOfWeek?: DateTime;
}) {
  const { user, startDate, endDate } = props;
  const canViewBirthdays = useCanView(AppParts.BIRTHDAYS);

  const requestDataAppointment: DataRequest['filter'] = useMemo(() => {
    return [
      { startDate: { $lt: endDate.toISO() } },
      { endDate: { $gte: startDate.toISO() } },
      {
        userId: {
          $eq: user.benutzerCode,
        },
      },
    ];
  }, [endDate, startDate, user.benutzerCode]);

  const resAppointment = useWidgetEntryData<Appointment>({
    entity: Entities.appointment,
    filter: requestDataAppointment,
    size: 1,
  });

  const requestDataTask: DataRequest['filter'] = useMemo(() => {
    return [
      {
        status: { $nin: [TaskStatus.ERLEDIGT, TaskStatus.ZURUECK_GESTELLT] },
      },
      { date: { $lte: endDate } },
      {
        userId: {
          $eq: user.benutzerCode,
        },
      },
    ];
  }, [endDate, user.benutzerCode]);

  const resTask = useWidgetEntryData<Task>({
    entity: Entities.task,
    filter: requestDataTask,
    size: 1,
  });

  const requestDataTicketS1: DataRequest = useMemo(() => {
    return {
      entity: Entities.ticket,
      data: {},
      filter: [
        {
          $and: [
            {
              status1: {
                $nin: [TicketStatus.ERLEDIGT, TicketStatus.ZURUECKGESTELLT],
              },
            },
            {
              'editor1.id': { $eq: user.benutzerCode },
            },
            { followUpDate: { $lte: endDate.toISO() } },
          ],
        },
      ],
    };
  }, [endDate, user.benutzerCode]);

  const resTicketS1 = useDataProvider<Ticket>(requestDataTicketS1, false, 1);

  const requestDataTicketS2: DataRequest = useMemo(() => {
    return {
      entity: Entities.ticket,
      data: {},
      filter: [
        {
          $and: [
            {
              status2: {
                $nin: [TicketStatus.ERLEDIGT, TicketStatus.ZURUECKGESTELLT],
              },
            },
            {
              'editor2.id': { $eq: user.benutzerCode },
            },
            { followUpDate: { $lte: endDate.toISO() } },
          ],
        },
      ],
    };
  }, [endDate, user.benutzerCode]);

  const resTicketS2 = useDataProvider<Ticket>(requestDataTicketS2, false, 1);

  const refetchTickets = useEventCallback(() => {
    resTicketS1.refetch();
    resTicketS2.refetch();
  });
  useRefetchOnSubscription({
    entity: Entities.ticket,
    refetch: refetchTickets,
  });

  const {
    stats: vacationStats,
    refetch: refetchVacationStats,
    isLoading: vacationIsLoading,
  } = useVacationStats({
    userId: user.benutzerCode,
    year: new Date().getFullYear(),
  });

  useRefetchOnSubscription({
    entity: Entities.user,
    refetch: refetchVacationStats,
  });

  const projectHoursState = useProjectHoursState({
    userToTrack: user,
  });

  useRefetchOnSubscription({
    entity: Entities.timeTrackingOverviewItem,
    refetch: projectHoursState.refetch,
  });

  const resSalesOpportunities = useWidgetEntryData<SalesOpportunities>({
    entity: Entities.salesOpportunities,
    filter: useMemo(() => {
      return [
        {
          $and: [
            { status: { $eq: SalesOpportunityStatus.ACTIVE } },
            { 'user2.id': { $eq: user.benutzerCode } },
          ],
        },
      ];
    }, [user.benutzerCode]),
  });

  const resTravelReceipts = useWidgetEntryData<TravelReceipts>({
    entity: Entities.travelReceipts,
    filter: useMemo(() => {
      return [
        {
          $and: [
            { 'travelExpenses.closedByuserid': { $eq: 0 } },
            {
              'travelExpenses.businessPartner.id': {
                $eq: user.supplierCode,
              },
            },
          ],
        },
      ];
    }, [user.supplierCode]),
  });

  const { mailboxes } = useMailboxesData(false);

  const mailboxFolderVisibilitySetting = useSetting(
    settings.mailboxFolderVisibility()
  );

  // Remove hidden folders and don't process mailboxes with errors, as these
  // won't have the folders info.
  const visibleMailboxFolders = useMemo(() => {
    const extendedMailboxes = extendMailboxFolders(
      mailboxes,
      mailboxFolderVisibilitySetting.value
    );

    const folders = extendedMailboxes
      .flatMap((mailbox) => mailbox.folder)
      .filter((folder) => folder != null && folder.isVisible);

    return folders;
  }, [mailboxes, mailboxFolderVisibilitySetting.value]);

  const unreadEmailsToAssign = useMemo(() => {
    const totalUnread = visibleMailboxFolders
      .map((folder) => folder.unreadItemsCount ?? 0)
      .reduce((acc, cur) => acc + cur, 0);

    return { totalUnread };
  }, [visibleMailboxFolders]);

  const { birthdays, refetch: refetchBirthdays } = useBirthdays({
    skip: !canViewBirthdays,
  });

  useRefetchOnEntityChanges({
    entity: [
      Entities.user,
      Entities.customer,
      Entities.supplier,
      Entities.contact,
    ],
    refetch: refetchBirthdays,
  });

  const resOpenInboundInvoices = useRePermits(user);

  const resOpenVacations = useVacationPermits(user, startDate);

  return {
    resAppointment,
    resTask,
    projectHoursState,
    vacation: { vacationStats, isLoading: vacationIsLoading },
    resTicketS1,
    resTicketS2,
    resSalesOpportunities,
    resTravelReceipts,
    unreadEmailsToAssign,
    mailboxes: mailboxes,
    birthdays,
    permits: { resOpenInboundInvoices, resOpenVacations },
  };
}
