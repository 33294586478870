import styles from './BoardColumn.module.scss';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ICustomCellConfigBase } from '@work4all/models/lib/table-schema/table-schema';

import { IUseEntityDataOptions } from '../../../entity-table/use-entity-data-table';

import { BoardColumn } from './BoardColumn';

export interface BoardProps
  extends IUseEntityDataOptions<
    unknown,
    Record<string, ICustomCellConfigBase>
  > {
  columns: {
    key: string;
    status: string;
    prefilter: unknown[];
  }[];
  onDrop: (item: unknown, key: string) => void;
}

export const Board = (props: BoardProps) => {
  const { columns, onDrop, ...tableProps } = props;

  return (
    <div className={styles.wrapper}>
      {columns.map(({ key, status, prefilter }) => (
        <BoardColumn
          key={status}
          status={status}
          prefilter={prefilter}
          onDrop={(item) => onDrop(item, key)}
          tableProps={tableProps}
        />
      ))}
    </div>
  );
};

const BOARD_ENABLED_ENTITIES = [Entities.task, Entities.ticket];
export const isBoardEnabled = (entityType: Entities) =>
  BOARD_ENABLED_ENTITIES.includes(entityType);
