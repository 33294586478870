import { useObservableState } from 'observable-hooks';
import React from 'react';
import { debounceTime, distinctUntilChanged } from 'rxjs';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntitySuggestions } from '../../hooks/use-entity-suggestions';

import {
  SuggestionPickerField,
  SuggestionPickerFieldProps,
} from './SuggestionPickerField';

interface EntitySuggestionPickerField
  extends Omit<SuggestionPickerFieldProps, 'data'> {
  field: string;
  entity: Entities;
}

export const EntitySuggestionPickerField = React.forwardRef<
  HTMLDivElement,
  EntitySuggestionPickerField
>(function EntitySuggestionPickerField(props, ref) {
  const { onChange, ...rest } = props;

  const { field, entity } = props;

  const [searchTerm, setSearchTerm] = useObservableState(
    (input$) => input$.pipe(distinctUntilChanged(), debounceTime(200)),
    ''
  );

  const suggestions = useEntitySuggestions({
    field,
    entity,
    value: searchTerm,
  });

  return (
    <SuggestionPickerField
      {...rest}
      ref={ref}
      onChange={(value) => {
        setSearchTerm(value ?? '');
        onChange(value);
      }}
      data={suggestions}
    />
  );
});
