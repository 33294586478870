import { useMemo } from 'react';

import {
  ILockInfo,
  LockContext,
  useLock,
} from '@work4all/components/lib/hooks';

interface LockOverrideProps {
  forceLock?: boolean;
  lockReason?: string;
  lockReasonTitle?: string;
  children?: React.ReactNode;
}

/**
 * Allows you to override the LockContext to force all input into a disabled
 * state.
 */
export function LockOverride({
  forceLock = false,
  lockReason,
  lockReasonTitle,
  children,
}: LockOverrideProps) {
  const ctx = useLock();

  const { locked: originalLocked, lock, unlock, user } = ctx;

  const locked = forceLock || originalLocked;

  const override = useMemo<ILockInfo>(() => {
    return { locked, lock, unlock, user, lockReason, lockReasonTitle };
  }, [locked, lock, unlock, user, lockReason, lockReasonTitle]);

  return (
    <LockContext.Provider value={override}>{children}</LockContext.Provider>
  );
}
