import { Capacitor } from '@capacitor/core';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'react-table';

import {
  LABEL_VISIBILITY,
  MaskActionButton,
} from '@work4all/components/lib/components/MaskActionButton';
import { CustomToolbarAction } from '@work4all/components/lib/dataDisplay/actions-bar/ToolbarTypes';
import { useTableStateBag } from '@work4all/components/lib/dataDisplay/basic-table';

import { useNavigate } from '@work4all/data/lib/hooks';
import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export enum FILESACTIONS {
  openInNewTap = 'openInNewTap',
}

interface FileActionsProps {
  entity: Entities;
}

export const useFileActions = (props: FileActionsProps) => {
  const { entity } = props;
  const tenant = useTenant();
  const tableStateBag = useTableStateBag();
  const { t } = useTranslation();
  const platform = Capacitor.getPlatform();
  const navigate = useNavigate();

  const selectedFlatRows = tableStateBag.tableInstance
    ?.selectedFlatRows as unknown as Row<{ id: string }>[];

  const getOpenInNewTabPath = useCallback(() => {
    const { origin } = window.location;
    const selectedId = getSelectedRowIds(selectedFlatRows)[0];
    const link = `${origin}/t-${tenant?.activeTenant}/${entity}s/${selectedId}`;
    return link;
  }, [entity, selectedFlatRows, tenant?.activeTenant]);

  const open = useCallback(() => {
    const selectedId = getSelectedRowIds(selectedFlatRows)[0];
    const link = getOpenInNewTabPath();
    if (platform === 'web') {
      window.open(link, '_blank');
    } else {
      navigate(`/${entity}s/${selectedId}`);
    }
  }, [entity, getOpenInNewTabPath, navigate, platform, selectedFlatRows]);

  const openInNewTab = useMemo(() => {
    return {
      getPath: getOpenInNewTabPath,
    };
  }, [getOpenInNewTabPath]);

  const fileActionDefinitions: CustomToolbarAction[] =
    useMemo((): CustomToolbarAction[] => {
      return [
        {
          actionKey: FILESACTIONS.openInNewTap,
          IconComponent: OpenInNewIcon,
          title: t('MASK.OPEN_IN_NEW_TAB'),
          disabled: false,
          handler: () => {
            open();
          },
          mode: 'Edit',
        },
      ];
    }, [t, open]);
  return { open, fileActionDefinitions, openInNewTab };
};

export const FileActions = (props: FileActionsProps) => {
  const { fileActionDefinitions } = useFileActions(props);
  return (
    <>
      {fileActionDefinitions.map((x) => (
        <MaskActionButton
          key={x.actionKey}
          labelVisibility={LABEL_VISIBILITY.HIDE}
          label={x.title}
          icon={<x.IconComponent />}
          disabledReason={x.disableReason}
          disabled={x.disabled}
          onClick={() => {
            x.handler(null);
          }}
        />
      ))}
    </>
  );
};

function getSelectedRowIds(rows: Row<{ id: string }>[]): string[] {
  return rows.filter((row) => !row.isGrouped).map((row) => row.original.id);
}
