import { useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { footerDataByGroupByResult } from '../../../../../../../utils/footer-data-by-group-by-result';
import { buildGroupedRowFooterDataQuery } from '../utils';

import {
  UseFetchGroupedRowFooterDataFetchFunctionProps,
  UseFetchGroupedRowFooterDataProps,
} from './types';

export const useFetchGroupedRowFooterData = ({
  entity,
  columns,
}: UseFetchGroupedRowFooterDataProps) => {
  const client = useApolloClient();

  const fetch = useCallback(
    async ({ filter }: UseFetchGroupedRowFooterDataFetchFunctionProps) => {
      const { query, variables } = buildGroupedRowFooterDataQuery({
        entity,
        columns,
        filter,
      });

      const result = await client.query({
        query,
        variables,
      });

      return footerDataByGroupByResult(result.data.groupBy);
    },
    [client, columns, entity]
  );

  return fetch;
};
