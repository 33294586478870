import styles from './ImagePreview.module.scss';

import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { UpdateAction } from 'react-quick-pinch-zoom/esm/PinchZoom/types';

import { useAuthHeaders } from '@work4all/data/lib/auth/use-auth-headers';

import { IUsePreview } from '../use-preview';

import { usePrevious } from './use-previous';

export type IImagePreviewProps = {
  url: string;
  imgClassName?: string;
} & Partial<IUsePreview['props']>;

const MIN_SCALE = 1;
const MAX_SCALE = 5;
const SCALE_FACTOR = 1.5;

export function ImagePreview({
  url,
  register,
  scale,
  onScaleChange,
  imgClassName,
}: IImagePreviewProps) {
  const [transform, setTransform] = useState<UpdateAction>({
    x: 0,
    y: 0,
    scale: 1,
  });

  const pinchZoomRef = useRef<PinchZoom>(null);

  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const [blobUrl, setBlobUrl] = useState<string>(null);

  const httpHeaders = useAuthHeaders();
  const httpHeadersRef = useRef(httpHeaders);

  useEffect(() => {
    httpHeadersRef.current = httpHeaders;
  }, [httpHeaders]);

  useEffect(() => {
    let cancelled = false;
    let blobUrl: string | null = null;

    if (url.startsWith('blob:')) {
      setBlobUrl(url);
      return;
    }

    setBlobUrl(null);

    fetch(url, {
      headers: httpHeadersRef.current,
    })
      .then((response) => response.blob())
      .then((blob) => {
        if (!cancelled) {
          blobUrl = URL.createObjectURL(blob);
          setBlobUrl(blobUrl);
        }
      });

    return () => {
      cancelled = true;

      if (blobUrl !== null) {
        URL.revokeObjectURL(blobUrl);
        blobUrl = null;
      }
    };
  }, [url]);

  useEffect(() => {
    return register?.({
      minScale: MIN_SCALE,
      maxScale: MAX_SCALE,
      scaleFactor: SCALE_FACTOR,
    });
  }, [register]);

  const previousScale = usePrevious(scale);

  const [isMounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // If component is in controlled mode (scale prop is provided)
  // update the local state to match the prop value.
  //
  // Only rescale the image if `isMounted` is `true`,
  // because otherwise image will be resized incorrectly if
  // ImagePreview is rendered inside Preview in controlled mode
  // when mimeType changes.
  useEffect(() => {
    const pinchZoom = pinchZoomRef.current;
    const container = containerRef.current;

    if (
      isMounted &&
      container &&
      pinchZoom &&
      scale !== undefined &&
      scale !== previousScale &&
      scale !== transform?.scale
    ) {
      const scaleValue = scale === 'fit' ? 1 : scale;

      const { clientWidth, clientHeight } = container;

      const x = clientWidth / 2 / transform.scale - transform.x;
      const y = clientHeight / 2 / transform.scale - transform.y;

      pinchZoom.alignCenter({ x, y, scale: scaleValue });
    }
  }, [isMounted, scale, previousScale, transform]);

  function handleUpdate(updateAction: UpdateAction) {
    ReactDOM.unstable_batchedUpdates(() => {
      onScaleChange?.(updateAction.scale);
      setTransform(updateAction);
    });
  }

  function getImageStyles(): React.CSSProperties {
    if (!transform) return null;

    const { x, y, scale } = transform;

    return { transform: make3dTransformValue({ x, y, scale }) };
  }

  return (
    <div ref={containerRef} className={styles['image-preview']}>
      {blobUrl && (
        <PinchZoom
          ref={pinchZoomRef}
          inertia={false}
          minZoom={MIN_SCALE}
          maxZoom={MAX_SCALE}
          wheelScaleFactor={550}
          onUpdate={handleUpdate}
        >
          <div
            ref={imageRef}
            style={getImageStyles()}
            className={styles['image-wrapper']}
          >
            <img
              className={clsx(styles['image'], imgClassName)}
              src={blobUrl}
              alt=""
            />
          </div>
        </PinchZoom>
      )}
    </div>
  );
}
