import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { Supplier } from './Supplier.entity';

export class StatisticsSalesVolumeBySupplierRow<
  T extends EMode = EMode.entity
> {
  /** Alias: date */
  date?: any;
  /** Alias: objCode */
  objCode?: any;
  /** Alias: amount */
  amount?: any;
  /** Alias: lieferant */
  lieferant?: Supplier<T>;

  __typename?: string;
}

const fields: FieldDefinitions<StatisticsSalesVolumeBySupplierRow> = {
  date: {
    alias: 'date',
  },
  objCode: {
    alias: 'objCode',
  },
  amount: {
    alias: 'amount',
  },
  lieferant: {
    alias: 'lieferant',
    entity: Entities.supplier,
  },
  __typename: {
    alias: '__typename',
  },
};

export const statisticsSalesVolumeBySupplierRowEntityDefinition: EntitiyDefinition<StatisticsSalesVolumeBySupplierRow> =
  {
    local: {},
    remote: {
      queryName: '',
      fragmentName: 'StatisticsSalesVolumeBySupplierRow',
      withPaginationWrapper: false,
    },
    fieldDefinitions: fields,
  };
