import styles from './CheckListPositionPriorityCell.module.scss';

import { useMemo } from 'react';

import { getCheckListPositionPriorityIcon } from '@work4all/components';
import { CheckListPositionPriorityPicker } from '@work4all/components/lib/components/entity-picker/check-list-position-priority-picker/CheckListPositionPriorityPicker';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components';

import { ChecklistePositionPrioritaet } from '@work4all/models/lib/Enums/ChecklistePositionPrioritaet.enum';

import { PickerTargetButton } from './PickerTargetButton';

type Props = {
  value: ChecklistePositionPrioritaet;
  onChange: (value: ChecklistePositionPrioritaet | null) => void;
};

export function CheckListPositionPriorityCell({ value, onChange }: Props) {
  const content = useMemo(() => {
    if (value) {
      return getCheckListPositionPriorityIcon(value);
    }

    return null;
  }, [value]);

  return (
    <EntityPickerPopover
      picker={
        <CheckListPositionPriorityPicker value={value} onChange={onChange} />
      }
    >
      <PickerTargetButton className={styles.pickerTargetButton} withoutArrow>
        {content}
      </PickerTargetButton>
    </EntityPickerPopover>
  );
}
