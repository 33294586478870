import { getValueByPath } from '@work4all/components/lib/components/entity-preview/components/PreviewContent';

interface ComposeItem {
  id: string;
  pattern?: string;
}

export interface ComposeCellProps {
  column: {
    cellParams: {
      pattern: string;
      compose: ComposeItem[];
    };
  };
  row: {
    original: Record<string, string>;
  };
}

export const ComposeCell = (props: ComposeCellProps) => {
  const {
    column: { cellParams },
    row: { original },
  } = props;

  let result: string = cellParams.pattern;
  const leaveEmpty = cellParams.compose.every(
    (x) => !getValueByPath(original, x.id)
  );
  if (leaveEmpty) return <></>;

  cellParams.compose.forEach((item, idx) => {
    let val = getValueByPath(original, item.id);
    if (val && item.pattern) {
      val = item.pattern.replace(`$${idx}`, val ?? '');
    }
    result = result.replace(`$${idx}`, val ?? '');
  });

  return <div>{result}</div>;
};
