import styles from './ApprovalStatusBanderole.module.scss';

import { Paper } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Body2 } from '../../../../typography/body2/Body2';

import { ActionStatuses } from './types';

type ApprovalStatusBanderoleProps = {
  status: ActionStatuses;
  userName?: string;
  date?: string;
};

export const ApprovalStatusBanderole: React.FC<ApprovalStatusBanderoleProps> = (
  props
) => {
  const { userName, date, status } = props;
  const { t } = useTranslation();

  let translationKey = 'APPROVAL_CENTER.BANDEROLE';

  if (date && userName) {
    translationKey = 'APPROVAL_CENTER.BANDEROLE.WITH_DATE_AND_USERNAME';
  }

  if (!date && userName) {
    translationKey = 'APPROVAL_CENTER.BANDEROLE.WITH_USERNAME';
  }

  if (date && !userName) {
    translationKey = 'APPROVAL_CENTER.BANDEROLE.WITH_DATE';
  }

  return (
    <Paper
      className={clsx(styles.root, {
        [styles.approved]: status === ActionStatuses.approved,
        [styles.rejected]: status === ActionStatuses.rejected,
      })}
    >
      <Body2>
        {t(translationKey, {
          userName,
          date,
          context: status.toUpperCase(),
        })}
      </Body2>
    </Paper>
  );
};
