import styles from '../Table.module.scss';

import { useMemo } from 'react';

import {
  BasicTableColumn,
  DataType,
  FilterType,
  HookedDateCell,
  IBasicTableProps,
} from '@work4all/components/lib/dataDisplay/basic-table';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { DataTableColumnConfig } from '../DataTableColumnConfig';
import { TableFilter } from '../TableFilter';

const supportedFilters = [
  FilterType.ClosedStatus,
  FilterType.Date,
  FilterType.Search,
  FilterType.Picker,
  FilterType.EmailKind,
  FilterType.VacationKind,
  FilterType.Boolean,
  FilterType.BooleanNumber,
  FilterType.AppointmentState,
  FilterType.Number,
  FilterType.TicketStatus,
  FilterType.TravelReceiptStatus,
  FilterType.TaskStatus,
  FilterType.EInvoiceFormat,
  FilterType.ObjectType,
  FilterType.SalesOpportunitiesGroupPicker,
  FilterType.SalesOpportunitiesStatusPicker,
  FilterType.ChronoContactPicker,
  FilterType.Check,
  FilterType.RequiredTime,
  FilterType.PaymentStatus,
  FilterType.ReAccountingStatus,
  FilterType.PermitStatus,
  FilterType.VacationStatus,
  FilterType.InvoiceKind,
  FilterType.DueDateClassified,
  FilterType.ArticleKind,
  FilterType.InvoiceForm,
  FilterType.RaAccountingStatus,
];
const isDate = (cfg: DataTableColumnConfig) => {
  return cfg.dataType === DataType.Date || cfg.filterType === FilterType.Date;
};

export const useTableColumns = ({
  columnConfigs,
  listEntityType,
}: {
  columnConfigs: DataTableColumnConfig[];
  listEntityType: Entities;
}) => {
  return useMemo<{
    columns: IBasicTableProps['columns'];
    defaultHiddenColumnids: string[];
  }>(() => {
    const defaultHiddenColumnids = [];

    const columns = columnConfigs.map((cfg) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const accessor: any = cfg.accessor;
      const col: BasicTableColumn = {
        Header: cfg.Header || cfg.title,
        title: cfg.title,
        Filter: (tableData) => (
          <TableFilter
            column={tableData.column}
            listEntityType={listEntityType}
          />
        ),
        disableFilters:
          cfg.disableFilters || supportedFilters.indexOf(cfg.filterType) === -1,
        accessor: accessor,
        //handle hoverstates
        headerClass: styles.headerClass,

        isPrimaryFilter: cfg.isPrimaryFilter,
        filterSubgroupPath: cfg.columnSubGroupPath,
        filterParams: cfg.filterParams,
        quickSearchable: cfg.quickSearchable,

        disableSortBy: cfg.disableSortBy,
        disableGroupBy: cfg.disableGroupBy,
        disableColumnVisibility: cfg.disableColumnVisibility ?? false,

        sticky: cfg.sticky,
        order: cfg.order,
        required: cfg.required,
        filterField: cfg.filterField,

        GroupLabelElement: cfg.GroupLabelElement,
        groupLabelElementParams: cfg.groupLabelElementParams,
        hasFooter: cfg.Footer != null && !cfg.disableFooterSum,
      };

      //these properties imply a different behaviour in react table if undefined, therefore check for their existence first instead of a blind set
      if (cfg.filterType) {
        col.filterType = cfg.filterType;
      }
      if (cfg.useFilterData) {
        col.useFilterData = cfg.useFilterData;
      }

      if (cfg.Cell) {
        col.Cell = cfg.Cell;

        if (cfg.cellParams) {
          col.cellParams = cfg.cellParams;
        }
      }

      if (cfg.Footer) {
        col.Footer = cfg.Footer;
      }

      if (cfg.aggregate) {
        col.aggregate = cfg.aggregate;
      }

      if (cfg.Aggregated) {
        col.Aggregated = cfg.Aggregated;
      } else if (isDate(cfg)) {
        col.Aggregated = HookedDateCell;
      }

      if (cfg.width !== undefined) {
        col.width = cfg.width;
      }
      if (cfg.minWidth !== undefined) {
        col.minWidth = cfg.minWidth;
      }
      if (cfg.maxWidth !== undefined) {
        col.maxWidth = cfg.maxWidth;
      }
      if ('disableResizing' in cfg) {
        col.disableResizing = cfg.disableResizing;
      }
      if (cfg.id) {
        col.id = cfg.id;
      }
      if (cfg.defaultHidden) {
        defaultHiddenColumnids.push(cfg.id || cfg.accessor);
      }
      if (cfg.dataType) {
        col.dataType = cfg.dataType;
      }
      return col;
    });

    return {
      columns,
      defaultHiddenColumnids,
    };
  }, [columnConfigs, listEntityType]);
};
