import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

import { useDataProvider, useDeleteEntity } from '@work4all/data';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useDeleteProjectProcess = (props: {
  ids: number[];
  onCompleted?: (data) => void;
  onAbort?: () => void;
}) => {
  const { ids = [], onCompleted, onAbort } = props;

  const [deleteEntity] = useDeleteEntity(false);
  const dialogs = useDialogs();

  const request = useMemo<DataRequest>(
    () => ({
      data: {
        id: null,
        projectProcessAppointmentList: [
          {
            id: null,
          },
        ],
        timeTrackings: [
          {
            id: null,
          },
        ],
      } as ProjectProcess,
      entity: Entities.projectProcess,
      filter: [
        {
          id: {
            $in: ids,
          },
        },
      ],
    }),
    [ids]
  );

  const { data: processData } = useDataProvider<ProjectProcess>(
    request,
    ids.length === 0
  );

  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    const appointmentList = processData.flatMap(
      (x) => x.projectProcessAppointmentList
    );
    const timeTrackingList = processData.flatMap((x) => x.timeTrackings);

    const description =
      appointmentList.length || timeTrackingList.length ? (
        <div>
          <strong>
            {t('PROJECT_PROCESS.DELETE.LINKED_OBJECTS')}:
            <ul style={{ paddingLeft: '2rem' }}>
              {appointmentList.length ? (
                <li>
                  {t('COMMON.COUNT.APPOINTMENT', {
                    count: appointmentList.length,
                  })}
                </li>
              ) : null}
              {timeTrackingList.length ? (
                <li>
                  {t('COMMON.COUNT.PROJECTTIME', {
                    count: timeTrackingList.length,
                  })}
                </li>
              ) : null}
            </ul>
          </strong>
          {t('ALERTS.DELETE.CONFIRM')}
        </div>
      ) : (
        t('ALERTS.DELETE.CONFIRM')
      );

    dialogs
      .confirm({
        title: t('ALERTS.HINT'),
        description,
      })
      .then((confirmed) => {
        if (confirmed) {
          deleteEntity(
            {
              type: Entities.projectProcess,
              ids,
            },
            {
              onCompleted,
            }
          );
        } else {
          onAbort?.();
        }
      });
  }, [deleteEntity, dialogs, ids, onCompleted, processData, t, onAbort]);

  return { handleDelete };
};
