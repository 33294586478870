import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListEntityPicker,
  ListEntityPickerWithTabsProps,
  QUERYVALUE,
} from '@work4all/components/lib/components/entity-picker/components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '@work4all/components/lib/components/entity-picker/types';

import { IResponse } from '@work4all/data';

import { Contact } from '@work4all/models/lib/Classes/Contact.entity';
import { ContactUnionWrapper } from '@work4all/models/lib/Classes/ContactUnionWrapper.entity';
import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { MailSearchContactsResultItem } from '@work4all/models/lib/Classes/MailSearchContactsResultItem.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { ContactKind } from '@work4all/models/lib/Enums/ContactKind.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  getRessourceNameByData,
  prepareRequestForPartner,
} from '../../../appointment/components/tab-panels/general/components/participant/attendee-picker/utils';

import {
  prepareRequestAll,
  prepareRequestInternal,
  PrepareRequestType,
  renderChipContent,
} from './utils';

const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

function isContact(
  item: MailSearchContactsResultItem | ContactUnionWrapper
): item is ContactUnionWrapper {
  return item.__typename === 'Ansprechpartner';
}

export const renderItemContent = (
  resource:
    | MailSearchContactsResultItem
    | (ContactUnionWrapper & { eMail: string }),
  allItems: MailSearchContactsResultItem[],
  t: (key: string) => string
): React.ReactNode => {
  const union = (isContact(resource) ? resource : resource?.contact) as User &
    Contact &
    Customer &
    Supplier;

  const mail = isContact(resource) ? resource.eMail : resource?.mailAddress;

  const type =
    allItems?.filter(
      (x) =>
        x.mailAddress?.toLocaleLowerCase() ===
        (
          (
            resource as ContactUnionWrapper & { eMail: string }
          )?.eMail?.toLocaleLowerCase() ||
          (resource as MailSearchContactsResultItem)?.mailAddress
        )?.toLocaleLowerCase()
    ).length > 1
      ? resource.id
      : null;

  const businessPartnerName = union?.businessPartner?.data?.name;

  const typeName = type?.startsWith?.('Kunde')
    ? t?.('COMMON.CUSTOMER')
    : type?.startsWith?.('Lieferant')
    ? t?.('COMMON.SUPPLIER')
    : type?.startsWith?.('Mitarbeiter')
    ? t?.('COMMON.USER')
    : null;

  let title = getRessourceNameByData(union, ContactKind.UNKNOWN);
  const subtitle = title ? ` <${mail}>` : undefined;
  title = subtitle ? title : mail;

  const textParts = [subtitle, typeName, businessPartnerName];
  const text = textParts.filter(Boolean).join(' | ');

  return (
    <Typography component="div">
      {title}
      <Typography color="text.tertiary" component="span">
        {text}
      </Typography>
    </Typography>
  );
};

export function createMailSearchResultItem(
  eMail: string
): MailSearchContactsResultItem {
  return {
    id: `Email_${eMail.toLowerCase().trim()}`,
    mailAddress: eMail.toLowerCase().trim(),
    contact: null,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getUniqueListByKey(list: Array<any>, key: string) {
  return [...new Map(list.map((item) => [item[key], item])).values()];
}

export interface IEmailAddressPickerProps<TMultiple extends boolean>
  extends IPickerProps<MailSearchContactsResultItem, TMultiple> {
  businessPartner?: Customer | Supplier | null;
}

export function EmailAddressPicker<TMultiple extends boolean>(
  props: IEmailAddressPickerProps<TMultiple>
) {
  const { businessPartner, value, ...rest } = props;
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState<string>('');

  const searchMiddleware =
    (next: PrepareRequestType) =>
    ({ search }: { search: string }): DataRequest => {
      setSearchText(search);
      return next({ search });
    };

  const transformResponse = (
    response: IResponse<MailSearchContactsResultItem>,
    idPrefix?: string
  ): IResponse<MailSearchContactsResultItem> => {
    const responseData = response.data.map((x) => ({
      ...x,
      id: idPrefix ? idPrefix + x.id : x.id,
    }));

    if (validateEmail(searchText)) {
      return {
        ...response,
        total: responseData.length,
        data: [...responseData, createMailSearchResultItem(searchText)],
      };
    }

    return { ...response, total: responseData.length, data: responseData };
  };

  const privatePartnerEmailData = useMemo(() => {
    if (!businessPartner) return [];

    return [businessPartner.eMail, businessPartner.eMail2]
      .filter(Boolean)
      .map((mail) => {
        return {
          id: createMailSearchResultItem(mail)?.id,
          mailAddress: mail ?? '',
        };
      });
  }, [businessPartner]);

  const partnerTabs = businessPartner
    ? !businessPartner.isPrivateCustomer
      ? [
          {
            label:
              businessPartner.name.substring(0, 10) +
              (businessPartner.name.length > 10 ? '...' : ''),
            data: null,
            filterBy: null,
            sortBy: null,
            entity: Entities.mailSearchContactsResultItem,
            renderItemContent: (item, allItems) =>
              renderItemContent(item, allItems, t),
            prepareRequest: searchMiddleware(
              prepareRequestForPartner(businessPartner)
            ),
            transformResponse: (data) =>
              transformResponse(
                data,
                businessPartner.__typename === 'Kunde'
                  ? 'KundeAp_'
                  : 'LieferantAp_'
              ),
          },
        ]
      : [
          {
            label:
              businessPartner.name.substring(0, 10) +
              (businessPartner.name.length > 10 ? '...' : ''),
            data: null,
            fixedDataSet: {
              data: privatePartnerEmailData,
              total: privatePartnerEmailData.length,
              loading: false,
              pending: false,
              fetchMore: () => Promise.resolve(),
              refetch: () => Promise.resolve(null),
            },
            filterBy: ['mailAddress'],
            sortBy: null,
            entity: null,
            renderItemContent: (item, allItems) =>
              renderItemContent(item, allItems, t),
          },
        ]
    : [];

  type TabProps = ListEntityPickerWithTabsProps<
    MailSearchContactsResultItem,
    TMultiple
  >['tabs'][number];
  const tabs: TabProps[] = [
    {
      label: t('COMMON.ALL'),
      data: null,
      filterBy: null,
      sortBy: null,
      entity: null,
      renderItemContent: (item, allItems) =>
        renderItemContent(item, allItems, t),
      prepareRequest: searchMiddleware(prepareRequestAll),
      transformResponse,
    },
    {
      label: t('COMMON.COLLEAGUES'),
      data: null,
      filterBy: null,
      sortBy: null,
      entity: null,
      renderItemContent: (item, allItems) =>
        renderItemContent(item, allItems, t),
      prepareRequest: searchMiddleware(prepareRequestInternal),
      transformResponse,
    },
    ...partnerTabs,
  ];

  const transformMail = (mail: MailSearchContactsResultItem) =>
    mail.id.includes('Kunde_') || mail.id.includes('Lieferant_')
      ? createMailSearchResultItem(mail.mailAddress)
      : mail;

  const mappedValue = useMemo(
    () =>
      Array.isArray(value) ? value.map(transformMail) : transformMail(value),
    [value]
  );

  return (
    <ListEntityPicker
      layout="advanced"
      tabs={tabs}
      renderChipContent={renderChipContent}
      inputType="email"
      value={mappedValue as IEmailAddressPickerProps<TMultiple>['value']}
      {...rest}
    />
  );
}

export type IEmailAddressStaticPickerProps<TMultiple extends boolean> = Omit<
  IPickerProps<MailSearchContactsResultItem, TMultiple>,
  'data' | 'filterConfig' | 'prefilter'
> & { options: string[] };

export function EmailAddressStaticPicker<TMultiple extends boolean>(
  props: IEmailAddressStaticPickerProps<TMultiple>
) {
  const { options, ...rest } = props;
  let searchTimeout = null;

  const [searchText, setSearchText] = useState<string>('');

  const dataset = useMemo(() => {
    const items = options
      .filter(
        (value) =>
          !searchText ||
          value.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      )
      .map((x) => createMailSearchResultItem(x));

    return {
      total: items.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: items,
    };
  }, [searchText, options]);

  return (
    <ListEntityPicker
      resultLayout={QUERYVALUE.ALLITEMS}
      layout="advanced"
      suppressFilter={true}
      multiple={props.multiple}
      hideCheckboxes={props.hideCheckboxes}
      entity={Entities.mailSearchContactsResultItem}
      data={null}
      fixedDataSet={dataset}
      onChange={props.onChange}
      onSearchValueChange={(newSearchText) => {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
          setSearchText((newSearchText || '').trim());
        }, 200);
      }}
      filterBy="mailAddress"
      sortBy="mailAddress"
      {...rest}
      inputType="email"
      renderChipContent={(ressource) => ressource?.mailAddress}
      renderItemContent={(ressource) => (
        <Typography variant="body2" noWrap>
          {ressource?.mailAddress}
        </Typography>
      )}
    />
  );
}
