import { pick } from 'lodash';
import {
  compressToEncodedURIComponent,
  decompressFromEncodedURIComponent,
} from 'lz-string';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Filters, TableInstance, TableState } from 'react-table';

import { isTimeUnitType } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import {
  ColumnInstance,
  FilterType,
  TableInitialState,
  TableStateBagProvider,
  useTableStateBag,
} from '@work4all/components/lib/dataDisplay/basic-table';

import { useQuery } from '@work4all/data/lib/data-retriever/hooks/useQuery';
import { PrintBagProvider } from '@work4all/data/lib/hooks/useHandlePrint';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';
import { useForceUpdate } from '@work4all/utils/lib/hooks/use-force-update';

import { ListPageContext } from '../../containers/file-entities-lists/list-page-context';
import { settings, useSetting } from '../../settings';
import { deserializeFilter } from '../../utils/deserializeFilter';

import { useListMatch } from './use-list-match';

interface IPageProps {
  renderTable: (tableApiRef?: React.Ref<TableInstance>) => JSX.Element;
  initialState?: TableInitialState;
}

const SETTINGS_PARAM_NAME = 'settings';

const TableStateURLSync = (props: { children: JSX.Element }) => {
  const { tableInstance } = useTableStateBag();
  const navigate = useNavigate();
  const query = useQuery();
  const settingsParam = query.get(SETTINGS_PARAM_NAME);
  const { isBaseListPageUrl } = useListMatch();

  // These are used to synchronize state-to-url and url-to-state effects so that
  // they don't overwrite each other.
  const canUpdateURLRef = useRef(false);
  const forceUpdate = useForceUpdate();
  const canUpdateURLTimeoutRef = useRef<number | null>(null);

  const { entityType = null } = useContext(ListPageContext) ?? {};

  const { value: hideClosedEntitiesSetting } = useSetting(
    settings.hideClosedEntities({ entityType })
  );

  const hideClosedEntitiesSettingRef = useRef(hideClosedEntitiesSetting);

  useEffect(() => {
    hideClosedEntitiesSettingRef.current = hideClosedEntitiesSetting;
  }, [hideClosedEntitiesSetting]);

  // Update the table state whenever the URL changes.
  useEffect(() => {
    if (!tableInstance) return;

    function findClosedStatusColumn() {
      return tableInstance.allColumns.find((column) => {
        return (
          (column as ColumnInstance).filterType === FilterType.ClosedStatus
        );
      }) as ColumnInstance;
    }

    if (!isBaseListPageUrl) return;
    // Mark the table as initialized. From this point on we can use table state
    // to update the URL. Updating the URL before this effect runs can result in
    // partial state being written to the URL and unnecessary rewrite loops
    // (possibly infinite).
    //
    // The table state is updated asynchronously, so we use `setTimeout` here so
    // that the next hook will receive the up-to-date table state when it tries
    // to update the URL.
    //
    // This is only needed for the case when a user goes from a file-specific
    // list page to a regular list page. The table does not unmount in this
    // case, because it's the same table essentially. Without this workaround
    // the current table state from the file-specific page would overwrite the
    // new URL state.
    //
    // Alternatively we could re-mount the table component, but with this it is
    // not necessary.
    canUpdateURLRef.current = false;
    if (canUpdateURLTimeoutRef.current) {
      window.clearTimeout(canUpdateURLTimeoutRef.current);
    }
    canUpdateURLTimeoutRef.current = window.setTimeout(() => {
      canUpdateURLRef.current = true;
      forceUpdate();
      canUpdateURLTimeoutRef.current = null;
    });

    const closedStatusColumn = findClosedStatusColumn();

    // If there is no "settings" param, reset all filters. Do not reset the sort
    // order, as all tables should always be sorted in some way. (There isn't
    // really a better way to handle this right now.)
    if (!settingsParam) {
      // eslint-disable-next-line @typescript-eslint/ban-types
      const allFilters: Filters<{}> = [];

      if (closedStatusColumn) {
        allFilters.push({
          id: closedStatusColumn.id,
          value: {
            filterType: closedStatusColumn.filterType,
            value: hideClosedEntitiesSettingRef.current,
          },
        });
      }

      tableInstance.setAllFilters?.(allFilters);
      return;
    }

    const settings = tryParseSettings(settingsParam);

    if (!settings) {
      console.warn('Could not parse the settings param.');
      return;
    }

    // If the current table state matches the serialized state, there is nothing
    // to do. (Use JSON.stringify to simplify the comparison.)
    if (
      JSON.stringify(settings) ===
      JSON.stringify(serializeTableState(tableInstance.state))
    )
      return;

    // Only update the sort order if it is present in the settings. It should
    // not be possible to remove the table sort from the UI.
    if (settings.sort && tableInstance.setSortBy) {
      tableInstance.setSortBy(settings.sort);
    }
    // eslint-disable-next-line @typescript-eslint/ban-types
    let filters: Filters<{}> = settings.filter
      ?.map((columnFilter) => {
        const column = tableInstance.allColumns.find(
          (column) => column.id === columnFilter.id
        );
        if (!column) return null;

        const { filterType } = column as ColumnInstance;

        const deserialized = deserializeFilter(columnFilter, filterType);

        // Skip all unrecognized filters.
        if (!deserialized) return null;

        return { id: column.id, value: { ...deserialized, filterType } };
      })
      .filter(Boolean);

    if (!filters) {
      filters = [];
    }

    if (
      closedStatusColumn &&
      !filters.find((filter) => filter.id === closedStatusColumn.id)
    ) {
      filters.push({
        id: closedStatusColumn.id,
        value: {
          filterType: closedStatusColumn.filterType,
          value: hideClosedEntitiesSettingRef.current,
        },
      });
    }

    tableInstance.setAllFilters?.(filters);
  }, [
    forceUpdate,
    settingsParam,
    tableInstance,
    entityType,
    isBaseListPageUrl,
  ]);

  // Update the URL whenever the table state changes.
  useEffect(() => {
    if (!tableInstance) return;
    if (!isBaseListPageUrl) return;
    // Only update the URL after we have already synchronized the table state
    // with it.
    if (!canUpdateURLRef.current) return;
    const settings = serializeTableState(tableInstance.state);

    const url = new URL(window.location.href);

    if (settings == null) {
      url.searchParams.delete(SETTINGS_PARAM_NAME);
    } else {
      const stringified = compressToEncodedURIComponent(
        JSON.stringify(settings)
      );
      url.searchParams.set(SETTINGS_PARAM_NAME, stringified);
    }

    if (url.href !== window.location.href) {
      navigate({ search: url.search }, { replace: true });
    }
  });

  return props.children;
};

export const ListPage: React.FC<IPageProps> = (props) => {
  const [tableInstance, setTableInstance] = useState<TableInstance>();
  return (
    <PrintBagProvider>
      <TableStateBagProvider
        tableInstance={tableInstance}
        initialState={props.initialState}
      >
        <TableStateURLSync>
          {props.renderTable(setTableInstance)}
        </TableStateURLSync>
      </TableStateBagProvider>
    </PrintBagProvider>
  );
};

function serializeFilter(filter: { id: string; value }) {
  switch (filter.value.filterType) {
    case FilterType.ClosedStatus: {
      return {
        id: filter.id,
        value: filter.value.value,
        readOnly: filter.value.readOnly || undefined,
      };
    }
    case FilterType.Search: {
      return {
        id: filter.id,
        value: filter.value.value,
        readOnly: filter.value.readOnly || undefined,
      };
    }
    case FilterType.EmailKind:
    case FilterType.VacationKind:
    case FilterType.EInvoiceFormat:
    case FilterType.Boolean:
    case FilterType.BooleanNumber:
    case FilterType.TaskStatus:
    case FilterType.TicketStatus:
    case FilterType.AppointmentState:
    case FilterType.SalesOpportunitiesStatusPicker:
    case FilterType.PaymentStatus:
    case FilterType.InvoiceForm:
    case FilterType.ReAccountingStatus:
    case FilterType.RaAccountingStatus:
    case FilterType.PermitStatus:
    case FilterType.VacationStatus:
    case FilterType.InvoiceKind:
    case FilterType.DueDateClassified:
    case FilterType.ArticleKind:
    case FilterType.Picker: {
      // TODO Should, probably, have entity-specific serializers here, but for
      // now just pick common properties that are usually used in UI.
      const value = filter.value.value.map((entity) =>
        pick(entity, ['id', 'name', 'displayName', '__typename'])
      );

      return {
        id: filter.id,
        value,
        readOnly: filter.value.readOnly || undefined,
      };
    }
    case FilterType.RequiredTime:
    case FilterType.Number: {
      const from = filter.value.value.from ?? null;
      const to = filter.value.value.to ?? null;

      // If the value is a time span keyword, just save the time span as a
      // string. Otherwise, save the start and end dates.
      return {
        id: filter.id,
        value: { from, to },
        readOnly: filter.value.readOnly || undefined,
      };
    }
    case FilterType.Date: {
      const startDateValue = filter.value.value.startDate;
      const endDateValue = filter.value.value.endDate;

      const startDate =
        isTimeUnitType(startDateValue) ||
        (startDateValue as RelativeDateFilter) in RelativeDateFilter
          ? startDateValue
          : startDateValue?.toISOString() ?? null;
      const endDate = isTimeUnitType(endDateValue)
        ? endDateValue
        : endDateValue?.toISOString() ?? null;

      return {
        id: filter.id,
        value: { startDate, endDate },
        readOnly: filter.value.readOnly || undefined,
      };
    }

    default:
      return filter;
  }
}

export function tryParseSettings(
  settingsParam: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): { filter?: any[]; sort?: any[] } | null {
  try {
    return JSON.parse(decompressFromEncodedURIComponent(settingsParam));
  } catch {
    return null;
  }
}

function serializeTableState(tableState: TableState) {
  const settings = {
    filter: tableState.filters?.map((filter) => {
      const serialized = serializeFilter(filter);

      return serialized;
    }),
    sort: tableState.sortBy,
  };

  if (settings.filter && settings.filter.length === 0) {
    delete settings.filter;
  }

  if (settings.sort && settings.sort.length === 0) {
    delete settings.sort;
  }

  if (Object.keys(settings).length === 0) {
    return null;
  }

  return settings;
}
