import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';

import { InboundInvoice } from './InboundInvoice.entity';
import { VacationRequestEntity } from './VacationRequestEntity.entity';

export type ApprovalEventRefObject<T extends EMode = EMode.entity> =
  T extends EMode.entity
    ? VacationRequestEntity<T> | InboundInvoice<T>
    : {
        /** Alias: VacationRequestEntity */
        vacationRequestEntity?: VacationRequestEntity<T>;
        /** Alias: Eingangsrechnung */
        inboundInvoice?: InboundInvoice<T>;
      };

const fields: FieldDefinitions<ApprovalEventRefObject<EMode.query>> = {
  vacationRequestEntity: {
    alias: 'vacationRequestEntity',
    entity: Entities.vacationRequestEntity,
  },
  inboundInvoice: {
    alias: 'inboundInvoice',
    entity: Entities.inboundInvoice,
  },
};

export const approvalEventRefObjectEntityDefinition: EntitiyDefinition<
  ApprovalEventRefObject<EMode.query>
> = {
  kind: 'union',
  local: {},
  remote: {
    queryName: '',
    fragmentName: 'ApprovalEventRefObject',
    withPaginationWrapper: false,
  },
  fieldDefinitions: fields,
};
