import { CUSTOM_FIELDS_DATA } from '../../../../../containers/mask-overlays/mask-overlay/components/custom-fields/custom-fields-data';

export const TIME_TRACKING_DATA = {
  id: null,
  date: null,
  startDateTime: null,
  insertTime: null,
  endDateTime: null,
  timeTrackingKind: null,
  creator: {
    id: null,
  },
  amount: null,
  timeUnits: null,
  comment: null,
  pause: null,
  customer: {
    id: null,
    name: null,
    number: null,
    website: null,
  },
  user: {
    id: null,
    displayName: null,
    shortName: null,
  },
  project: {
    id: null,
    name: null,
    number: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  contract: {
    id: null,
    number: null,
    contractNumber: null,
    note: null,
    positionList: [
      {
        id: null,
        index: null,
        number: null,
        shortText: null,
        longtext: null,
        positionKind: null,
        amount: null,
        unit: null,
      },
    ],
    businessPartnerId: null,
    businessPartnerType: null,
  },
  position: {
    id: null,
    number: null,
    shortText: null,
    longtext: null,
  },
  positionId: null,
  ticket: {
    id: null,
    title: null,
    number: null,
  },
  activityArticle: {
    id: null,
    name: null,
    number: null,
  },
  customFieldList: [CUSTOM_FIELDS_DATA],
};
