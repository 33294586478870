import {
  Experimental_CssVarsProvider as CssVarsProvider,
  StyledEngineProvider,
} from '@mui/material/styles';

import { theme } from './theme';

export function ThemeProvider(props: { children: React.ReactNode }) {
  return (
    <StyledEngineProvider injectFirst>
      <CssVarsProvider
        theme={theme}
        attribute="data-theme"
        defaultMode="system"
      >
        {props.children}
      </CssVarsProvider>
    </StyledEngineProvider>
  );
}
