import styles from './Popover.module.scss';

import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import UndoIcon from '@mui/icons-material/Undo';
import {
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { isEqual } from 'lodash';
import React, {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { ColumnInstance } from '@work4all/components';
import { ColumnVisibilityContext } from '@work4all/components/lib/dataDisplay/basic-table/hooks/useColumnVisibility';
import { SELECTION_COLUMN_ID } from '@work4all/components/lib/dataDisplay/basic-table/utils/makeRowsSelectable';
import { useHistoryStack } from '@work4all/components/lib/navigation/history-stack';
import { NavigationPopover } from '@work4all/components/lib/navigation/navigation-popover';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import { assertNever } from '@work4all/utils';

import {
  ERP_EXPAND_COLUMN_ID,
  OBJECT_LOCK_COLUMN_ID,
} from '../../../../../generic-column/GenericColumns';
import { VisibleColumnsContext } from '../columns-visibility/visible-columns-context';

interface IPopoverProps {
  popoverState: ReturnType<typeof usePopoverState>;
  tableInstanceRef: RefObject<TableInstance>;
  type: 'columnsVisibility' | 'columnsGrouping' | 'sortColumn';
  subgroupPath?: string[];
  onResetColumns?: () => void;
  title?: string;
}

type PopoverType = IPopoverProps['type'];

export const getLeavesAndTwigs = (
  columns: Array<ColumnInstance & { title?: string }>,
  currentPath: string[]
): { leaves: Array<ColumnInstance & { title?: string }>; twigs: string[] } => {
  const result = { leaves: [], twigs: [] };

  const currentPathString = currentPath.join('.');

  columns.forEach((col) => {
    const subGroupPathString = col.filterSubgroupPath
      ? col.filterSubgroupPath.join('.')
      : '';

    if (subGroupPathString === currentPathString) {
      result.leaves.push(col);
    } else if (
      subGroupPathString.startsWith(currentPathString) &&
      col.filterSubgroupPath.length - currentPath.length === 1 &&
      result.twigs.indexOf(col.filterSubgroupPath[currentPath.length]) === -1
    ) {
      result.twigs.push(col.filterSubgroupPath[currentPath.length]);
    }
  });

  return result;
};

const getList = (
  col: ColumnInstance,
  props: IPopoverProps,
  toggleColumnVisibility: (colId: string) => void
) => {
  switch (props.type) {
    case 'columnsGrouping':
      return {
        checkedVal: col.isGrouped,
        onChangeFunc: () =>
          props.tableInstanceRef.current.toggleGroupBy(col.id),
      };

    case 'columnsVisibility':
      return {
        checkedVal: col.isVisible,
        onChangeFunc: () => {
          toggleColumnVisibility(col.id);
        },
      };

    case 'sortColumn': {
      return {
        // HACK Using `col.isSorted` here doesn't return the correct value and
        // some columns are displayed as sorted when they are not and the other
        // way around.

        // TODO Investigate the cause of this issue. The value `isSorted`
        // property is not correct and does not match the actual `sortBy` value
        // in the table state. Sometimes there is only one column in the state,
        // but two or more columns have `isSorted` set to `true`. Sometimes none
        // of the columns have `isSorted` set to true, even though the table is
        // sorted and there is a column in the `sortBy` state. This only happens
        // in "card" list layout and I want's able to reproduce it with regular
        // "table" layout.

        // checkedVal: col.isSorted,
        checkedVal: !!props.tableInstanceRef.current?.state.sortBy.find(
          (c) => c.id === col.id
        ),
        onChangeFunc: () => {
          col.toggleSortBy(undefined, false);
        },
      };
    }

    default:
      assertNever(props.type, 'Unknown popover type');
  }
};

export const VISIBILITY_COLUMNS_EXCEPTIONS = [
  ERP_EXPAND_COLUMN_ID,
  SELECTION_COLUMN_ID,
  OBJECT_LOCK_COLUMN_ID,
];

export const PopoverSubMenu: React.FC<IPopoverProps> = (props) => {
  const { subgroupPath, type, popoverState, tableInstanceRef, onResetColumns } =
    props;

  const columnVisibilityContext = useContext(ColumnVisibilityContext);
  const visibleColumns = useContext(VisibleColumnsContext);

  const { t } = useTranslation();

  const { go } = useHistoryStack();

  const goToSubgroup = (subgroupPath: string[]) => {
    go({
      title: t(subgroupPath[subgroupPath.length - 1]),

      view: (
        <PopoverSubMenu
          type={type}
          popoverState={popoverState}
          tableInstanceRef={tableInstanceRef}
          subgroupPath={subgroupPath}
          onResetColumns={onResetColumns}
        />
      ),
    });
  };

  const leavesAndTwigs = useMemo(() => {
    switch (props.type) {
      case 'columnsGrouping':
        return getLeavesAndTwigs(
          visibleColumns.filter(
            (col) =>
              col.id !== SELECTION_COLUMN_ID &&
              !col.sticky &&
              !col.disableColumnVisibility &&
              col.canGroupBy
          ),
          subgroupPath
        );

      case 'columnsVisibility':
        return getLeavesAndTwigs(
          visibleColumns.filter(
            (col) =>
              !VISIBILITY_COLUMNS_EXCEPTIONS.includes(col.id) &&
              !col.disableColumnVisibility
          ),
          subgroupPath
        );

      case 'sortColumn':
        return getLeavesAndTwigs(
          visibleColumns.filter(
            (col) =>
              !VISIBILITY_COLUMNS_EXCEPTIONS.includes(col.id) && col.canSort
          ),
          subgroupPath
        );

      default:
        assertNever(props.type, 'Unknown popover type');
    }
  }, [props.type, visibleColumns, subgroupPath]);

  return (
    <List disablePadding>
      {leavesAndTwigs.leaves
        .sort((a, b) => {
          // Keep the original sort order for custom fields.
          if (isEqual(subgroupPath, ['MASK.INDIVIDUAL'])) {
            return (a.order ?? 0) - (b.order ?? 0);
          }

          return (a.title ?? 0) > (b.title ?? 0)
            ? 1
            : (a.title ?? 0) === (b.title ?? 0)
            ? 0
            : -1;
        })
        .filter(
          (col) => !columnVisibilityContext.hiddenColumns?.includes(col.id)
        )
        .map((col) => {
          const list = getList(
            col,
            props,
            columnVisibilityContext?.toggleColumnVisibility
          );

          return (
            <ListItem key={col.id} disablePadding>
              <ListItemButton
                disabled={columnVisibilityContext.disabledColumns?.includes(
                  col.id
                )}
                role={undefined}
                onClick={list.onChangeFunc}
              >
                <ListItemIcon>
                  {type === 'sortColumn' ? (
                    list.checkedVal ? (
                      <RadioButtonCheckedIcon color="primary" />
                    ) : (
                      <RadioButtonUncheckedIcon />
                    )
                  ) : list.checkedVal ? (
                    <CheckBox color="primary" />
                  ) : (
                    <CheckBoxOutlineBlank />
                  )}
                </ListItemIcon>
                <ListItemText
                  //eslint-disable-next-line
                  //@ts-ignore
                  primary={
                    typeof col.Header === 'string'
                      ? col.Header
                      : typeof col.title === 'string'
                      ? col.title
                      : col.id
                  }
                />
              </ListItemButton>
            </ListItem>
          );
        })}

      {leavesAndTwigs?.twigs?.length > 0 && (
        <Divider className={styles.divider} />
      )}

      {leavesAndTwigs.twigs
        .sort((a, b) => {
          // Custom fields must always be displayed as the last option.
          if (a === 'MASK.INDIVIDUAL') return 1;
          if (b === 'MASK.INDIVIDUAL') return -1;

          return t(a) > t(b) ? 1 : t(a) === t(b) ? 0 : -1;
        })
        .map((subgroup) => {
          return (
            <ListItem key={subgroup} disablePadding>
              <ListItemButton
                onClick={() => {
                  goToSubgroup([...subgroupPath, subgroup]);
                }}
              >
                <ListItemText
                  className={styles.itemText}
                  classes={{ primary: styles.primary }}
                >
                  {t(subgroup)}
                </ListItemText>

                <ChevronRight className={styles.itemIcon} />
              </ListItemButton>
            </ListItem>
          );
        })}

      {subgroupPath.length === 0 && onResetColumns && (
        <Divider classes={{ root: styles.divider }} />
      )}

      {subgroupPath.length === 0 && onResetColumns && (
        <ListItem disablePadding>
          <ListItemButton
            className={styles.resetButton}
            onClick={onResetColumns}
          >
            <UndoIcon className={styles.itemIcon} />
            <ListItemText
              primary={
                props.type === 'columnsVisibility'
                  ? t('LISTS_PAGES.RESET_COLUMNS')
                  : t('LISTS_PAGES.DELETE_GROUPING')
              }
            ></ListItemText>
          </ListItemButton>
        </ListItem>
      )}
    </List>
  );
};

// TODO This Popover component is in for an overhaul. It seems it was designed
// to be used for both "column visibility" and "grouping" popovers but now there
// is a separate component for "grouping" popover. So this one needs to be
// stripped of all unnecessary logic.
export const Popover: React.FC<IPopoverProps> = (props) => {
  const { type, popoverState, tableInstanceRef, onResetColumns, title } = props;

  //if the initial view changes while the popover is open, then the stack resets
  const [initalView, setInitialView] = useState({ title: '', view: null });

  const { t } = useTranslation();

  const POPOVER_TITLES: Record<PopoverType, string> = useMemo(
    () => ({
      columnsGrouping: t('INPUTS.GROUPING'),
      columnsVisibility: title || t('MASK.COLUMNS'),
      sortColumn: t('COMMON.SORT'),
    }),
    [t, title]
  );

  useEffect(() => {
    if (popoverState.open) return;
    setInitialView({
      title: POPOVER_TITLES[type],
      view: (
        <PopoverSubMenu
          type={type}
          popoverState={popoverState}
          tableInstanceRef={tableInstanceRef}
          onResetColumns={onResetColumns}
          subgroupPath={[]}
        />
      ),
    });
  }, [
    props.type,
    type,
    popoverState,
    tableInstanceRef,
    onResetColumns,
    POPOVER_TITLES,
  ]);

  return (
    <NavigationPopover
      anchorEl={props.popoverState.anchorEl}
      onClose={props.popoverState.onClose}
      open={Boolean(props.popoverState.anchorEl)}
      initialView={initalView}
    />
  );
};
