import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useLock } from '@work4all/components/lib/hooks';

import { TempFileManagerContext } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';

import { useMaskContext } from '../hooks/mask-context';
import { useEntityRightsContext } from '../hooks/use-entity-rights';
import { useFormStatus } from '../hooks/use-form-status';

import { MaskOverlaySubmitButton } from './MaskOverlaySubmitButton';

interface MaskOverlayStandardSubmitButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  isDirty?: boolean;
}

export const MaskOverlayStandardSubmitButton: React.FC<
  MaskOverlayStandardSubmitButtonProps
> = ({ isDirty, ...props }: MaskOverlayStandardSubmitButtonProps) => {
  const mask = useMaskContext();

  const { locked } = useLock();

  const { t } = useTranslation();
  const { formState } = useFormContext();
  const rights = useEntityRightsContext();

  const formStatus = useFormStatus(formState);

  const tempFileManager = useContext(TempFileManagerContext);

  const isLoading = formStatus.isSubmitting;
  const noRights = mask.isEditMode ? !rights.update : !rights.create;
  const isDisabled =
    props.disabled ||
    locked ||
    noRights ||
    (mask.isEditMode &&
      !isDirty &&
      (!formStatus.isValid ||
        tempFileManager?.currentUploadingFiles > 0 ||
        (!formStatus.isDirty && !tempFileManager?.fileListDirty)));

  return (
    <MaskOverlaySubmitButton
      data-test-id="mask-submit"
      loading={isLoading}
      disableReason={noRights ? t('RIGHTS.MISSING') : undefined}
      {...props}
      disabled={isDisabled}
    />
  );
};
