import AddIcon from '@mui/icons-material/Add';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ListIcon from '@mui/icons-material/List';
import { compact } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { configure, HotKeys } from 'react-hotkeys';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useNavigationType } from 'react-router-dom';
import { DeepReadonly } from 'rxdb/dist/types/types';

import { prepareSuggestionEls } from '@work4all/components/lib/components/global-search/prepareSuggestionEls';
import {
  SearchObjectType,
  useGlobalSearchQuery,
} from '@work4all/components/lib/hooks/use-global-search-query';
import { ActionType } from '@work4all/components/lib/input/search-bar-with-fullscreen/types';
import { SearchResultItem } from '@work4all/components/lib/input/search-result-item/SearchResultItem';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { useLocation, useNavigate, useSearchKeyHandlers } from '@work4all/data';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';
import { useRoutesMetaBag } from '@work4all/data/lib/hooks/useRoutesMetaBag';

import { Customer } from '@work4all/models/lib/Classes/Customer.entity';
import { Project } from '@work4all/models/lib/Classes/Project.entity';
import { Supplier } from '@work4all/models/lib/Classes/Supplier.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { FileData, FileType } from '@work4all/models/lib/File';

import { InsertFromClipboard } from '../../../components/entity-picker/InsertFromClipboardModal';
import { MaskModal } from '../../../components/entity-picker/MaskModal';
import { PickFromListModal } from '../../../components/entity-picker/PickFromListModal';
import { usePageTitle } from '../../../hooks/use-page-title';

import { SearchWrapper } from './components/SearchWrapper';
import { useSearchedFiles } from './hooks/useSearchedFiles';
import { NewBusinessPartnerTemplate } from './places-utils';
import { PlaceSuggestions } from './PlacesAutosuggestions';
import { OnElementSelected } from './types';
import { sortFilesData } from './utils/sortFilesData';

interface Props {
  lastSearchItems: DeepReadonly<FileData[]>;
  elementSelected: OnElementSelected;
  defaultActions: ActionType[];
  title: string;
  number: string;
  description: string;
  placeholder: string;
  pathname: string;
  onOpenMask: (template: NewBusinessPartnerTemplate) => void;
}

configure({
  ignoreTags: [],
  ignoreRepeatedEventsWhenKeyHeldDown: false,
  customKeyCodes: {
    13: 'NAVIGATE_TO',
  },
});

const CUSTOMER_TYPES: SearchObjectType[] = ['CUSTOMER', 'CONTACTS'];
const CustomerData: React.FC<Props> = (props) => {
  const { data, onChange, inputValue, debouncedValue } = useGlobalSearchQuery({
    types: CUSTOMER_TYPES,
  });

  const { setKeyboardLocation, keyMap, keyHandlers } = useSearchKeyHandlers(
    data.search
  );

  useEffect(() => {
    document.getElementById(data.search[0]?.id?.toString())?.scrollIntoView();
    setKeyboardLocation(0);
  }, [inputValue]);

  const list = useMemo(() => {
    return prepareSuggestionEls({
      items: data.search,
      pathname: props.pathname,
      elementSelected: props.elementSelected,
    });
  }, [data.search, props.elementSelected, props.pathname]);

  return (
    <HotKeys allowChanges keyMap={keyMap} handlers={keyHandlers}>
      <SearchWrapper
        header={{
          title: props.title,
          description: props.description,
        }}
        actions={props.defaultActions}
        footerActions={props.defaultActions}
        search={{
          inputValue,
          onChange,
          placeholder: props.placeholder,
          showGoogleSearch: true,
        }}
        list={list}
        suggestions={
          <PlaceSuggestions
            entity={Entities.customer}
            disabled={false}
            query={debouncedValue}
            onSelect={props.onOpenMask}
          />
        }
        history={{
          lastSearchItems: props.lastSearchItems,
          onSelect: (el) => props.elementSelected(el),
        }}
      />
    </HotKeys>
  );
};

const SUPPLIER_TYPES: SearchObjectType[] = ['SUPPLIER', 'CONTACTS'];

const SuppliersData: React.FC<Props> = (props) => {
  const { data, onChange, inputValue, debouncedValue } = useGlobalSearchQuery({
    types: SUPPLIER_TYPES,
  });

  const { setKeyboardLocation, keyMap, keyHandlers } = useSearchKeyHandlers(
    data.search
  );

  useEffect(() => {
    document.getElementById(data.search[0]?.id?.toString())?.scrollIntoView();
    setKeyboardLocation(0);
  }, [inputValue]);

  const list = useMemo(() => {
    return prepareSuggestionEls({
      items: data.search,
      pathname: props.pathname,
      elementSelected: props.elementSelected,
    });
  }, [data.search, props.pathname, props.elementSelected]);

  return (
    <HotKeys allowChanges keyMap={keyMap} handlers={keyHandlers}>
      <SearchWrapper
        header={{
          title: props.title,
          description: props.description,
        }}
        footerActions={props.defaultActions}
        actions={props.defaultActions}
        search={{
          inputValue,
          onChange,
          placeholder: props.placeholder,
          showGoogleSearch: true,
        }}
        list={list}
        suggestions={
          <PlaceSuggestions
            entity={Entities.supplier}
            disabled={false}
            query={debouncedValue}
            onSelect={props.onOpenMask}
          />
        }
        history={{
          lastSearchItems: props.lastSearchItems,
          onSelect: (el) => props.elementSelected(el),
        }}
      />
    </HotKeys>
  );
};

const PROJECT_TYPES: SearchObjectType[] = ['PROJECT'];
const ProjectData: React.FC<Props> = (props) => {
  const { data, onChange, inputValue } = useGlobalSearchQuery({
    types: PROJECT_TYPES,
  });

  const { setKeyboardLocation, keyMap, keyHandlers } = useSearchKeyHandlers(
    data.search
  );

  useEffect(() => {
    document.getElementById(data.search[0]?.id?.toString())?.scrollIntoView();
    setKeyboardLocation(0);
  }, [inputValue]);

  const list = useMemo(() => {
    const list = compact(data.search);
    return list.map((item) => {
      return (
        <SearchResultItem
          key={item.id}
          id={item.id?.toString()}
          number={(item.number as number)?.toString()}
          href={`${props.pathname}/${item.id}`}
          title={item.name || ''}
          onClick={() =>
            props.elementSelected({
              id: item.id?.toString() || '',
              number: item.number || '',
              name: item.name || '',
            })
          }
        ></SearchResultItem>
      );
    });
  }, [data.search, props]);

  return (
    <HotKeys allowChanges keyMap={keyMap} handlers={keyHandlers}>
      <SearchWrapper
        header={{
          title: props.title,
          description: props.description,
        }}
        actions={props.defaultActions}
        footerActions={props.defaultActions}
        search={{ inputValue, onChange, placeholder: props.placeholder }}
        list={list}
        history={{
          lastSearchItems: props.lastSearchItems,
          onSelect: (el) => props.elementSelected(el),
        }}
      />
    </HotKeys>
  );
};

export const SearchPage: React.FC = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigationType = useNavigationType();
  const navigate = useNavigate();
  const { states } = useRoutesMetaBag();
  const currentPath = `/${pathname.split('/')[1]}`;

  // If a user opened a file page before and it hasn't been closed,
  // redirect the user to that page instead of showing the search page.
  useEffect(() => {
    // Remove leading slash from the pathname
    const path = currentPath.slice(1);
    const state = states[path];

    // Do not redirect when using browser's Back and Forward buttons
    if (state && navigationType !== 'POP') {
      const params = state.params || {};
      const searchParams = createSearchParams(params);
      const search = searchParams.toString();

      navigate(`/${path}/${state.id}?${search}`, { replace: true });
    }
  }, [currentPath, navigate, navigationType, states]);

  const [listModalOpen, setListModalOpen] = useState(false);
  const [maskModalOpen, setMaskModalOpen] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [imprintInputOpen, setImprintInputOpen] = useState(false);

  const pickFromListAction = {
    icon: ListIcon,
    title: '',
    handler: () => {
      sendAmplitudeData(EventType.Search, {
        entryPoint: currentPath.substring(1, currentPath.length),
      });
      setListModalOpen(true);
    },
  };

  const createNewCustomerAction = {
    icon: AddIcon,
    title: 'CUSTOMER.ACTIONS.CREATE_NEW',
    handler: () => {
      setMaskModalOpen(true);
    },
  };

  const createNewSupplierAction = {
    icon: AddIcon,
    title: 'SUPPLIER.ACTIONS.CREATE_NEW',
    handler: () => {
      setMaskModalOpen(true);
    },
  };

  const createNewProjectAction = {
    icon: AddIcon,
    title: 'PROJECT.ACTIONS.CREATE_NEW',
    handler: () => {
      setMaskModalOpen(true);
    },
  };

  const insertCustomerActions = {
    icon: ContentPasteIcon,
    title: '',
    handler: () => {
      setImprintInputOpen(true);
    },
  };
  const insertSupplierActions = {
    icon: ContentPasteIcon,
    title: '',
    handler: () => {
      setImprintInputOpen(true);
    },
  };

  useEffect(() => {
    sendAmplitudeData(EventType.Search, {
      entryPoint: currentPath.substring(1, currentPath.length),
    });
  }, [currentPath]);

  let type: FileType = FileType.PROJECT;
  let entity: Entities = Entities.project;
  let Component = ProjectData;
  switch (currentPath) {
    case '/customers':
      type = FileType.CUSTOMER;
      entity = Entities.customer;
      pickFromListAction.title = 'CUSTOMER.ACTIONS.LIST';
      insertCustomerActions.title = 'CUSTOMER.ACTIONS.INSERT_CUSTOMER';
      Component = CustomerData;
      break;
    case '/suppliers':
      type = FileType.SUPPLIER;
      entity = Entities.supplier;
      pickFromListAction.title = 'SUPPLIER.ACTIONS.LIST';
      insertSupplierActions.title = 'SUPPLIER.ACTIONS.INSERT_SUPPLIER';

      Component = SuppliersData;
      break;
    case '/projects':
      type = FileType.PROJECT;
      entity = Entities.project;
      pickFromListAction.title = 'PROJECT.ACTIONS.LIST';
      Component = ProjectData;
      break;
  }

  usePageTitle(t(`COMMON.${type.toUpperCase()}`, { count: 2 }));

  const { lastSearchItems, saveSearchItem } = useSearchHistory();

  const lastSearchedIds = useMemo(() => {
    return lastSearchItems[type]
      .filter((item) => item?.id)
      .map((item) => item.id.toString());
  }, [lastSearchItems, type]);

  const filesData = useSearchedFiles(
    lastSearchedIds,
    type,
    entity !== Entities.project
      ? { website: null, isPrivateCustomer: null }
      : {}
  );

  const sortedLastSearchedData = useMemo(() => {
    return sortFilesData(filesData.resData.data, lastSearchedIds);
  }, [filesData, lastSearchedIds]);

  const compProps: Props = {
    lastSearchItems: sortedLastSearchedData,
    pathname: `${currentPath}`,
    defaultActions:
      currentPath === '/customers'
        ? [createNewCustomerAction, insertCustomerActions, pickFromListAction]
        : currentPath === '/suppliers'
        ? [createNewSupplierAction, insertSupplierActions, pickFromListAction]
        : [createNewProjectAction, pickFromListAction],
    number: '0',
    elementSelected: (el) => {
      saveSearchItem(entity, el);
    },
    title: t(`${type.toUpperCase()}.PAGE_TITLE`),
    description: t(`${type.toUpperCase()}.PAGE_DESCRIPTION`),
    placeholder: t(`${type.toUpperCase()}.SEARCH_PLACEHOLDER`),
    onOpenMask(template) {
      setDefaultValue(template);
      setMaskModalOpen(true);
    },
  };

  const openFilePage = (item: (Customer | Supplier | Project)[]) => {
    saveSearchItem(entity, {
      id: item[0].id.toString(),
      number: item[0].number.toString(),
      website: (item[0] as Customer | Supplier).website,
      name: item[0].name,
    });

    setListModalOpen(false);

    const ids = item.map((entity) => String(entity.id));
    navigate({
      pathname: ids[0],
      search: ids.length > 1 ? `${createSearchParams({ ids })}` : null,
    });
  };

  return (
    <>
      <PickFromListModal<Customer | Supplier | Project>
        open={listModalOpen}
        entity={entity}
        multiple
        onClose={() => {
          setListModalOpen(false);
        }}
        onConfirm={openFilePage}
        overridesTableProps={{
          onRowDoubleClick: (_, item) => {
            openFilePage([item]);
            return true;
          },
        }}
      />
      <MaskModal
        entity={entity}
        params={{ presetFields: JSON.stringify(defaultValue) }}
        open={maskModalOpen}
        onClose={() => {
          setDefaultValue({});
          setMaskModalOpen(false);
        }}
        onAfterSave={(result: Customer | Supplier | Project) => {
          setMaskModalOpen(false);

          // TODO This will redirect to the new entity page before the mask
          // close animation is finished.
          setTimeout(() => {
            navigate(`${result.id}`);
          }, 500);
        }}
      />

      {imprintInputOpen && (
        <InsertFromClipboard
          entity={entity}
          onSuccess={(value) => {
            setDefaultValue(value);
            sendAmplitudeData(EventType.AddFileFromImprint, {
              entryPoint: currentPath.substring(1, currentPath.length),
            });
            setMaskModalOpen(true);
            setImprintInputOpen(false);
          }}
          onClose={() => setImprintInputOpen(false)}
        />
      )}
      <Component {...compProps} />
    </>
  );
};
