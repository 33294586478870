import { useTranslation } from 'react-i18next';

import { MaskTab, MaskTabs } from '../../../../mask-tabs';

import { ControllerTabs } from './enums';

export const CheckListOverlayControllerTabs = () => {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value={ControllerTabs.General} label={t('MASK.GENERAL')} />
      <MaskTab value={ControllerTabs.History} label={t('MASK.HISTORY')} />
    </MaskTabs>
  );
};
