import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SdObjType } from '../Enums/SdObjType.enum';
import { TravelExpensesKind } from '../Enums/TravelExpensesKind.enum';

import { BusinessPartner } from './BusinessPartner.entity';
import { Contact } from './Contact.entity';
import { CustomField } from './CustomField.entity';
import { Project } from './Project.entity';
import { ProjectProcess } from './ProjectProcess.entity';
import { TravelReceipts } from './TravelReceipts.entity';
import { User } from './User.entity';

export class TravelExpenses<T extends EMode = EMode.entity> {
  /** Alias: abschlussBCode */
  closedByuserid?: number;
  /** Alias: abschlussDatum */
  closeDate?: string;
  /** Alias: abzug20ProzentAnzahlAuf12EUR */
  deductionAmount20PercentOn12EUR?: number;
  /** Alias: abzug20ProzentAnzahlAuf24EUR */
  deductionAmount20PercentOn24EUR?: number;
  /** Alias: abzug40ProzentAnzahlAuf12EUR */
  deductionAmount40PercentOn12EUR?: number;
  /** Alias: abzug40ProzentAnzahlAuf24EUR */
  deductionAmount40PercentOn24EUR?: number;
  /** Alias: abzugFruehstueckAnzahl */
  deductionAmountBreakfast?: number;
  /** Alias: abzugFruehstueckEUR */
  deductionBreakfastEUR?: number;
  /** Alias: ansprpCode */
  contactId?: number;
  /** Alias: anzahlFruehstueck */
  amountBreakfast?: number;
  /** Alias: anzahlUebernachtungen */
  amountNights?: number;
  /** Alias: arbeitsbeginn */
  workStart?: string;
  /** Alias: arbeitsende */
  workEnd?: string;
  /** Alias: art */
  travelExpensesKindId?: TravelExpensesKind;
  /** Alias: auszahlungsbetrag */
  payoutAmount?: number;
  /** Alias: bCode */
  creatorUserId?: number;
  /** Alias: bedarfCode */
  demandId?: number;
  /** Alias: belegSumme */
  receiptSum?: number;
  /** Alias: benutzer */
  user?: User<T>;
  /** Alias: bestellungCode */
  orderId?: number;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: dienstreiseauftragsNummer */
  businessTripContractNumber?: number;
  /** Alias: eURjeFruehstueck */
  amountPerBreakfast?: number;
  /** Alias: eURjeNacht */
  amountPernight?: number;
  /** Alias: fahrtkostenEURjekm */
  rideCostsPerKM?: number;
  /** Alias: fahrtkostenEisenbahn */
  rideCostsTrain?: number;
  /** Alias: fahrtkostenFlug */
  rideCostsFlight?: number;
  /** Alias: fahrtkostenMietwagen */
  rideCostsRentalCar?: number;
  /** Alias: fahrtkostenMitfahrerEURjekm */
  rideCostsWithDriverPerKM?: number;
  /** Alias: fahrtkostenMitfahrerkm */
  rideCostsWithDriverKM?: number;
  /** Alias: fahrtkostenMitfahrerkmEUR */
  rideCostsWithDriverEUR?: number;
  /** Alias: fahrtkostenSonstiges */
  rideCostsMisc?: number;
  /** Alias: fahrtkostenkm */
  rideCostsKM?: number;
  /** Alias: fahrtkostenkmEUR */
  rideCostsKMEUR?: number;
  /** Alias: genehmigt */
  approved?: number;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: kontaktberichtCode */
  contactReportId?: number;
  /** Alias: kontaktberichtManuell */
  contactReportManual?: string;
  /** Alias: kostenstelle */
  costCenterId?: number;
  /** Alias: kundenCode */
  customerId?: number;
  /** Alias: land */
  country?: string;
  /** Alias: landCode */
  countryId?: number;
  /** Alias: mitarbeiterCode */
  collegueId?: number;
  /** Alias: nebenkosten1Summe */
  extraCosts1Sum?: number;
  /** Alias: nebenkosten1Text */
  extraCosts1Text?: string;
  /** Alias: nebenkosten2Summe */
  extraCosts2Sum?: number;
  /** Alias: nebenkosten2Text */
  extraCosts2Text?: string;
  /** Alias: nebenkosten3Summe */
  extraCosts3Sum?: number;
  /** Alias: nebenkosten3Text */
  extraCosts3Text?: string;
  /** Alias: notiz */
  note?: string;
  /** Alias: ort */
  city?: string;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: projektvorgangsCode */
  projectProcessId?: number;
  /** Alias: reisebeginn */
  travelStart?: string;
  /** Alias: reiseende */
  travelEnd?: string;
  /** Alias: reisezweck */
  travelPurpose?: string;
  /** Alias: sDObjType */
  businessPartnerType?: SdObjType;
  /** Alias: sDObjTypeBesuchterGeschaeftspartner */
  visitedbusinessPartnerSDObjType?: SdObjType;
  /** Alias: sDObjmemberCode */
  businessPartnerId?: number;
  /** Alias: sDObjmemberCodeBesuchterGeschaeftspartner */
  visitedbusinessPartnerSDObjMemberId?: number;
  /** Alias: sachbezugEUR */
  factualReferenceAmount?: number;
  /** Alias: sachbezugText */
  factualReferenceText?: string;
  /** Alias: stunden */
  hours?: number;
  /** Alias: tage */
  days?: number;
  /** Alias: uebernahmeREBCode */
  insertionREId?: number;
  /** Alias: uebernahmeREDatum */
  insertionREDate?: string;
  /** Alias: uebernahmeRERECode */
  insertionREREId?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: verpflegungsmehraufwendungen14bis24h */
  extraCateringExpenditure14To24h?: number;
  /** Alias: verpflegungsmehraufwendungen14bis24hEUR */
  extraCateringExpenditure14To24hEUR?: number;
  /** Alias: verpflegungsmehraufwendungen14bis24hEURjeh */
  extraCateringExpenditure14to24hEURPerHh?: number;
  /** Alias: verpflegungsmehraufwendungen8bis14h */
  extraCateringExpenditure8To14h?: number;
  /** Alias: verpflegungsmehraufwendungen8bis14hEUR */
  extraCateringExpenditure8To14hEUR?: number;
  /** Alias: verpflegungsmehraufwendungen8bis14hEURjeh */
  extraCateringExpenditure8To14hEURPerH?: number;
  /** Alias: verpflegungsmehraufwendungenUeber24h */
  extraCateringExpenditureOver24h?: number;
  /** Alias: verpflegungsmehraufwendungenUeber24hEUR */
  extraCateringExpenditureOver24hEUR?: number;
  /** Alias: verpflegungsmehraufwendungenUeber24hEURjeh */
  extraCateringExpenditureOver24hEURperH?: number;
  /** Alias: verpflegungsmehraufwendungenUnter24h */
  extraCateringExpenditureUnder24h?: number;
  /** Alias: verpflegungsmehraufwendungenUnter24hEUR */
  extraCateringExpenditureUnder24hEUR?: number;
  /** Alias: verpflegungsmehraufwendungenUnter24hEURjeh */
  extraCateringExpenditureUnder24hEURperH?: number;
  /** Alias: vorschuss */
  advance?: number;
  /** Alias: projekt */
  project?: Project<T>;
  /** Alias: geschaeftspartner */
  businessPartner?: BusinessPartner<T>;
  /** Alias: ansprechpartner */
  contact?: Contact<T>;
  /** Alias: customFields */
  customFieldList?: CustomField<T>[];
  /** Alias: projektVorgang */
  projectProcess?: ProjectProcess<T>;
  /** Alias: receipts */
  receipts?: TravelReceipts<T>[];
  /** Alias: visitedContact */
  visitedContact?: BusinessPartner<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TravelExpenses> = {
  closedByuserid: {
    alias: 'abschlussBCode',
  },
  closeDate: {
    alias: 'abschlussDatum',
  },
  deductionAmount20PercentOn12EUR: {
    alias: 'abzug20ProzentAnzahlAuf12EUR',
  },
  deductionAmount20PercentOn24EUR: {
    alias: 'abzug20ProzentAnzahlAuf24EUR',
  },
  deductionAmount40PercentOn12EUR: {
    alias: 'abzug40ProzentAnzahlAuf12EUR',
  },
  deductionAmount40PercentOn24EUR: {
    alias: 'abzug40ProzentAnzahlAuf24EUR',
  },
  deductionAmountBreakfast: {
    alias: 'abzugFruehstueckAnzahl',
  },
  deductionBreakfastEUR: {
    alias: 'abzugFruehstueckEUR',
  },
  contactId: {
    alias: 'ansprpCode',
  },
  amountBreakfast: {
    alias: 'anzahlFruehstueck',
  },
  amountNights: {
    alias: 'anzahlUebernachtungen',
  },
  workStart: {
    alias: 'arbeitsbeginn',
  },
  workEnd: {
    alias: 'arbeitsende',
  },
  travelExpensesKindId: {
    alias: 'art',
  },
  payoutAmount: {
    alias: 'auszahlungsbetrag',
  },
  creatorUserId: {
    alias: 'bCode',
  },
  demandId: {
    alias: 'bedarfCode',
  },
  receiptSum: {
    alias: 'belegSumme',
  },
  user: {
    alias: 'benutzer',
    entity: Entities.user,
  },
  orderId: {
    alias: 'bestellungCode',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  businessTripContractNumber: {
    alias: 'dienstreiseauftragsNummer',
  },
  amountPerBreakfast: {
    alias: 'eURjeFruehstueck',
  },
  amountPernight: {
    alias: 'eURjeNacht',
  },
  rideCostsPerKM: {
    alias: 'fahrtkostenEURjekm',
  },
  rideCostsTrain: {
    alias: 'fahrtkostenEisenbahn',
  },
  rideCostsFlight: {
    alias: 'fahrtkostenFlug',
  },
  rideCostsRentalCar: {
    alias: 'fahrtkostenMietwagen',
  },
  rideCostsWithDriverPerKM: {
    alias: 'fahrtkostenMitfahrerEURjekm',
  },
  rideCostsWithDriverKM: {
    alias: 'fahrtkostenMitfahrerkm',
  },
  rideCostsWithDriverEUR: {
    alias: 'fahrtkostenMitfahrerkmEUR',
  },
  rideCostsMisc: {
    alias: 'fahrtkostenSonstiges',
  },
  rideCostsKM: {
    alias: 'fahrtkostenkm',
  },
  rideCostsKMEUR: {
    alias: 'fahrtkostenkmEUR',
  },
  approved: {
    alias: 'genehmigt',
  },
  insertTime: {
    alias: 'insertTime',
  },
  contactReportId: {
    alias: 'kontaktberichtCode',
  },
  contactReportManual: {
    alias: 'kontaktberichtManuell',
  },
  costCenterId: {
    alias: 'kostenstelle',
  },
  customerId: {
    alias: 'kundenCode',
  },
  country: {
    alias: 'land',
  },
  countryId: {
    alias: 'landCode',
  },
  collegueId: {
    alias: 'mitarbeiterCode',
  },
  extraCosts1Sum: {
    alias: 'nebenkosten1Summe',
  },
  extraCosts1Text: {
    alias: 'nebenkosten1Text',
  },
  extraCosts2Sum: {
    alias: 'nebenkosten2Summe',
  },
  extraCosts2Text: {
    alias: 'nebenkosten2Text',
  },
  extraCosts3Sum: {
    alias: 'nebenkosten3Summe',
  },
  extraCosts3Text: {
    alias: 'nebenkosten3Text',
  },
  note: {
    alias: 'notiz',
  },
  city: {
    alias: 'ort',
  },
  projectId: {
    alias: 'projektCode',
  },
  projectProcessId: {
    alias: 'projektvorgangsCode',
  },
  travelStart: {
    alias: 'reisebeginn',
  },
  travelEnd: {
    alias: 'reiseende',
  },
  travelPurpose: {
    alias: 'reisezweck',
  },
  businessPartnerType: {
    alias: 'sDObjType',
  },
  visitedbusinessPartnerSDObjType: {
    alias: 'sDObjTypeBesuchterGeschaeftspartner',
  },
  businessPartnerId: {
    alias: 'sDObjmemberCode',
  },
  visitedbusinessPartnerSDObjMemberId: {
    alias: 'sDObjmemberCodeBesuchterGeschaeftspartner',
  },
  factualReferenceAmount: {
    alias: 'sachbezugEUR',
  },
  factualReferenceText: {
    alias: 'sachbezugText',
  },
  hours: {
    alias: 'stunden',
  },
  days: {
    alias: 'tage',
  },
  insertionREId: {
    alias: 'uebernahmeREBCode',
  },
  insertionREDate: {
    alias: 'uebernahmeREDatum',
  },
  insertionREREId: {
    alias: 'uebernahmeRERECode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  extraCateringExpenditure14To24h: {
    alias: 'verpflegungsmehraufwendungen14bis24h',
  },
  extraCateringExpenditure14To24hEUR: {
    alias: 'verpflegungsmehraufwendungen14bis24hEUR',
  },
  extraCateringExpenditure14to24hEURPerHh: {
    alias: 'verpflegungsmehraufwendungen14bis24hEURjeh',
  },
  extraCateringExpenditure8To14h: {
    alias: 'verpflegungsmehraufwendungen8bis14h',
  },
  extraCateringExpenditure8To14hEUR: {
    alias: 'verpflegungsmehraufwendungen8bis14hEUR',
  },
  extraCateringExpenditure8To14hEURPerH: {
    alias: 'verpflegungsmehraufwendungen8bis14hEURjeh',
  },
  extraCateringExpenditureOver24h: {
    alias: 'verpflegungsmehraufwendungenUeber24h',
  },
  extraCateringExpenditureOver24hEUR: {
    alias: 'verpflegungsmehraufwendungenUeber24hEUR',
  },
  extraCateringExpenditureOver24hEURperH: {
    alias: 'verpflegungsmehraufwendungenUeber24hEURjeh',
  },
  extraCateringExpenditureUnder24h: {
    alias: 'verpflegungsmehraufwendungenUnter24h',
  },
  extraCateringExpenditureUnder24hEUR: {
    alias: 'verpflegungsmehraufwendungenUnter24hEUR',
  },
  extraCateringExpenditureUnder24hEURperH: {
    alias: 'verpflegungsmehraufwendungenUnter24hEURjeh',
  },
  advance: {
    alias: 'vorschuss',
  },
  project: {
    alias: 'projekt',
    entity: Entities.project,
  },
  businessPartner: {
    alias: 'geschaeftspartner',
    entity: Entities.businessPartner,
  },
  contact: {
    alias: 'ansprechpartner',
    entity: Entities.contact,
  },
  customFieldList: {
    alias: 'customFields',
    entity: Entities.customField,
  },
  projectProcess: {
    alias: 'projektVorgang',
    entity: Entities.projectProcess,
  },
  receipts: {
    alias: 'receipts',
    entity: Entities.travelReceipts,
  },
  visitedContact: {
    alias: 'visitedContact',
    entity: Entities.businessPartner,
  },
  __typename: {
    alias: '__typename',
  },
};

export const travelExpensesEntityDefinition: EntitiyDefinition<TravelExpenses> =
  {
    local: {},
    remote: {
      queryName: 'getReisekosten',
      fragmentName: 'Reisekostenabrechnung',
      withPaginationWrapper: true,
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
