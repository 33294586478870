import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';

export interface IConfirmDeleteEntitiesOpts {
  standard?: boolean;
}

export interface IConfirmDeleteEntitiesParams {
  /**
   * The number of entities to delete. Will be used to format the dialog
   * description text.
   *
   * @default 1
   */
  count?: number;
}

export interface IConfirmDeleteEntitiesFunction {
  (params?: IConfirmDeleteEntitiesParams): Promise<boolean>;
}

export const useConfirmDeleteEntities = (
  opts?: IConfirmDeleteEntitiesOpts
): IConfirmDeleteEntitiesFunction => {
  const { standard = false } = opts ?? {};
  const dialogs = useDialogs();

  const { t } = useTranslation();

  const confirmDeleteEntities: IConfirmDeleteEntitiesFunction = (
    params = {}
  ) => {
    const { count = 1 } = params;

    if (standard) {
      return dialogs.confirm({
        title: t('ALERTS.DELETE.TITLE'),
        description: t('ALERTS.DELETE.CONFIRM'),
        confirmLabel: t('ALERTS.YES'),
        cancelLabel: t('ALERTS.NO'),
      });
    }

    return dialogs.confirm({
      title: t('ALERTS.DELETE.TITLE'),
      description: t('ALERTS.DELETE.REASSURE', { count }),
      confirmLabel: t('ALERTS.BTN_DELETE'),
      cancelLabel: t('ALERTS.BTN_ABORT'),
    });
  };

  return confirmDeleteEntities;
};
