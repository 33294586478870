import React, { useMemo } from 'react';
import { TableInstance } from 'react-table';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import { ErpRessourceClassCell } from './ErpRessourceClassCell';
import { useCloneConvertTableErpActions } from './offers-table/hooks/use-clone-convert-erp';
import { useErpPlanningColumns } from './planning-columns/use-erp-planning-columns';
import schema from './schemata/contract-table-schema.json';
import { useErpHandlersTemplate } from './use-erp-handlers-template';

type Props = IEntityTable;
const defaultSort = [
  { field: 'contractDate', direction: SortDirection.DESCENDING },
  { field: 'contractNumber', direction: SortDirection.DESCENDING },
];

export const ContractsTable = React.forwardRef<TableInstance, Props>(
  (props, ref) => {
    const { ...rest } = props;

    const erpPlanningColumns = useErpPlanningColumns({
      entity: Entities.contract,
    });

    const transformedSchema = useMemo(() => {
      return {
        ...schema,
        columns: [...schema.columns, ...erpPlanningColumns],
      } as never;
    }, [erpPlanningColumns]);

    const { ...dataTable } = useEntityDataTable<Contract, never>({
      schema: transformedSchema,
      cells: {
        ErpRessourceClass: ErpRessourceClassCell,
      },
      defaultSort,
      enableFooter: true,
      forceRequestFields: {
        positionList: [
          {
            id: null,
            unit: null,
            amount: null,
            article: {
              id: null,
              name: null,
              number: null,
              ressourceClassId: null,
            },
          },
        ],
      } as Contract,
      rowModifiers: (value) => {
        return {
          isFaded: value.isClosed && value.frozen,
          isShade1: !value.isClosed && value.frozen,
        };
      },
    });

    const template = useErpHandlersTemplate();

    const { actions } = useCloneConvertTableErpActions({
      entity: Entities.contract,
    });

    const custom = useMemo(
      () => ({
        left: actions,
      }),
      [actions]
    );

    return (
      <EntityTable
        ref={ref}
        template={template}
        {...dataTable}
        actions={{
          custom,
        }}
        displayFooter
        {...rest}
      />
    );
  }
);
