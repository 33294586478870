import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { useUser } from '@work4all/data';

import { SdObjType } from '@work4all/models/lib/Enums/SdObjType.enum';

import { MaskExtendedConfig } from '../../../types';
import { useAssignableTemplateEntity } from '../../../utils/use-assignable-template-entity';

import { CheckListMaskFormValue } from './types';

export const useCheckListMaskOverlayNewEntityData = (
  mask: MaskExtendedConfig
): CheckListMaskFormValue => {
  const user = useUser();
  const template = useAssignableTemplateEntity(mask);

  return useMemo(() => {
    let data: CheckListMaskFormValue = {
      date: DateTime.now().toISO(),
      user: { id: user.benutzerCode, displayName: user.displayName },
      userId: user.benutzerCode,
    };

    if (template.enabled && template.data) {
      data = {
        ...data,
        businessPartner: {
          id: template.data.businessPartner?.data?.id ?? 0,
          data: template.data.businessPartner?.data
            ? { ...template.data.businessPartner?.data, mainContact: null }
            : null,
        },
        businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
        businessPartnerType:
          template.data.businessPartner?.businessPartnerType ??
          template.data.businessPartnerType ??
          SdObjType.KUNDE,
        contact: template.data.contact,
        contactId: template.data.contact?.id ?? 0,
        project: template.data.project,
        projectId: template.data.project?.id ?? 0,
        projectProcess: template.data.projectProcess,
        projectProcessId: template.data.projectProcess?.id ?? 0,
      };
    }

    return data;
  }, [user.benutzerCode, user.displayName, template.enabled, template.data]);
};
