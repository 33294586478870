import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';

export class Group<T extends EMode = EMode.entity> {
  /** Alias: code */
  id?: number;
  /** Alias: index */
  index?: number;
  /** Alias: name */
  name?: string;
  /** Alias: parentCode */
  parentId?: number;

  __typename?: string;
}

const fields: FieldDefinitions<Group> = {
  id: {
    alias: 'code',
  },
  index: {
    alias: 'index',
  },
  name: {
    alias: 'name',
  },
  parentId: {
    alias: 'parentCode',
  },
  __typename: {
    alias: '__typename',
  },
};

export const groupEntityDefinition: EntitiyDefinition<Group> = {
  local: {},
  remote: {
    queryName: 'getArtikelGruppen',
    fragmentName: 'Group',
    withPaginationWrapper: false,
    mutations: {
      upsert: {
        mutationName: 'mutateProjectGroups',
        args: [{ name: 'input', type: 'MutateProjectGroups' }],
      },
    },
  },
  fieldDefinitions: fields,
};
