import { DateTime } from 'luxon';

import { isTimeUnitType } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import { FilterType } from '@work4all/components/lib/dataDisplay/basic-table';

import { RelativeDateFilter } from '@work4all/utils/lib/date-utils/RelativeDateFilter.enum';

interface DeserializeSearchFilter {
  readOnly?: boolean;
  value: string;
}

interface DeserializeDateFilter {
  readOnly?: boolean;
  value: {
    startDate: string | null;
    endDate: string | null;
  };
}

interface DeserializeNumberFilter {
  readOnly?: boolean;
  value: {
    from: string;
    to: string;
  };
}

interface DeserializeClosedStatusFilter {
  readOnly?: boolean;
  value: boolean;
}

interface DeserializePickerFilter {
  readOnly?: boolean;
  value: string;
}

export function deserializeFilter(
  serialized:
    | DeserializeSearchFilter
    | DeserializeDateFilter
    | DeserializeNumberFilter
    | DeserializePickerFilter
    | DeserializeClosedStatusFilter,
  filterType: FilterType
) {
  switch (filterType) {
    case FilterType.ClosedStatus:
      return deserializeClosedStatusFilter(
        serialized as DeserializeClosedStatusFilter
      );
    case FilterType.Search:
      return deserializeSearchFilter(serialized as DeserializeSearchFilter);
    case FilterType.Date:
      return deserializeDateFilter(serialized as DeserializeDateFilter);
    case FilterType.RequiredTime:
    case FilterType.Number:
      return deserializeNumberFilter(serialized as DeserializeNumberFilter);
    case FilterType.TravelReceiptStatus:
      return serialized.value;
    case FilterType.EmailKind:
    case FilterType.VacationKind:
    case FilterType.EInvoiceFormat:
    case FilterType.Boolean:
    case FilterType.BooleanNumber:
    case FilterType.TaskStatus:
    case FilterType.TicketStatus:
    case FilterType.AppointmentState:
    case FilterType.SalesOpportunitiesStatusPicker:
    case FilterType.PaymentStatus:
    case FilterType.InvoiceForm:
    case FilterType.ReAccountingStatus:
    case FilterType.RaAccountingStatus:
    case FilterType.PermitStatus:
    case FilterType.VacationStatus:
    case FilterType.InvoiceKind:
    case FilterType.DueDateClassified:
    case FilterType.ArticleKind:
    case FilterType.Picker: {
      return deserializePickerFilter(serialized as DeserializePickerFilter);
    }
    default:
      console.warn(
        `Could not deserialize filter. Filter type is unknown: ${filterType}`
      );
  }
  return {};
}

function deserializeClosedStatusFilter(
  serialized: DeserializeClosedStatusFilter
) {
  const closedStatus = serialized.value;

  return {
    readOnly: !!serialized.readOnly,
    value: !!closedStatus,
  };
}

function deserializeSearchFilter(serialized: DeserializeSearchFilter) {
  const searchString = serialized.value;

  return {
    readOnly: !!serialized.readOnly,
    value: searchString,
  };
}

function deserializeDateFilter(serialized: DeserializeDateFilter) {
  const { startDate, endDate } = serialized.value;

  const startDateDate =
    startDate in RelativeDateFilter
      ? startDate
      : !startDate
      ? null
      : isTimeUnitType(startDate)
      ? DateTime.now().startOf(startDate).toJSDate()
      : new Date(startDate);
  const endDateDate = !endDate
    ? null
    : isTimeUnitType(endDate)
    ? DateTime.now().endOf(endDate).toJSDate()
    : new Date(endDate);

  return {
    readOnly: !!serialized.readOnly,
    value: {
      startDate: startDateDate,
      endDate: endDateDate,
    },
  };
}

function deserializeNumberFilter(serialized: DeserializeNumberFilter) {
  const { from, to } = serialized.value;

  return {
    readOnly: !!serialized.readOnly,
    value: {
      from: from == null ? null : parseFloat(from),
      to: to == null ? null : parseFloat(to),
    },
  };
}

function deserializePickerFilter(serialized: DeserializePickerFilter) {
  return {
    readOnly: !!serialized.readOnly,
    value: serialized.value,
  };
}
