import { Stack } from '@mui/material';

import {
  EntityTableView,
  resolveEntityVariant,
} from '../../../file-entities-lists';

export const VACATION_APPROVAL_ENTITY = 'vacationApproval';

export const VacationsApprovalContainer = () => {
  const resolved = resolveEntityVariant(VACATION_APPROVAL_ENTITY);
  const { entityType, entityVariant, prefilter } = resolved;

  return (
    <Stack height="100%" width="100%">
      <EntityTableView
        entityType={entityType}
        prefilter={prefilter}
        entityVariant={entityVariant}
      />
    </Stack>
  );
};
