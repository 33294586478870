import { useApolloClient } from '@apollo/client';
import { useEventCallback } from '@mui/material/utils';
import { cloneDeep } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useTenant } from '@work4all/data/lib/hooks/routing/TenantProvider';
import { ENTITY_CHANGED_SUBSCRIPTION } from '@work4all/data/lib/hooks/use-entity-changed';

import { ObjectTypeByEntity, ObjectTypesUnion } from '@work4all/models';
import { ChangeType } from '@work4all/models/lib/Enums/ChangeType.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { throwInDev } from '@work4all/utils';

import { useActiveInterval } from '../use-active-interval';

import { GET_OBJECT_LOCKS } from './constants';
import { LockObjectItem, ObjectLockResult } from './types';

type SubscriptionKey = Entities | 'contactSupplier' | 'contactCustomer';

export interface LockSubscription {
  closed: boolean;
  unsubscribe(): void;
  size: number;
}

type GetLock = (
  id: string | number,
  entity: SubscriptionKey
) => {
  lockedBy?: {
    id?: number;
    displayName?: string;
  };
  locked: boolean;
  sessionId?: string;
  loading?: boolean;
};

interface LockObjectContextType {
  subscribe: (entity: SubscriptionKey) => void;
  unsubscribe: (entity: SubscriptionKey) => void;
  replaceItem: (entity: SubscriptionKey, update: LockObjectItem) => void;
  getLock: GetLock;
  getLockStable: GetLock;
  lockData: Partial<Record<SubscriptionKey, LockObjectItem[]>>;
}

const LockObjectContext = createContext<LockObjectContextType | undefined>(
  undefined
);

export const LockObjectProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { activeTenant } = useTenant();
  const client = useApolloClient();

  const subscriptions = useRef<
    Partial<Record<SubscriptionKey, LockSubscription>>
  >({});
  const [lockData, setLockData] = useState<
    Partial<Record<SubscriptionKey, LockObjectItem[]>>
  >({});

  const replaceItem = useCallback(
    (entity: SubscriptionKey, updateItem: LockObjectItem) => {
      setLockData((values) => {
        const newState = cloneDeep(values);
        const collection = newState[entity] || [];
        newState[entity] = collection.filter(
          (x) => `${x.entityId}` !== `${updateItem.entityId}`
        );
        newState[entity].push(updateItem);
        return { ...newState, [entity]: newState[entity] };
      });
    },
    []
  );

  const queryLock = useCallback(
    async (entity: SubscriptionKey) => {
      const objectType = mapToObjectType(entity);
      const lockResult = await client.query<ObjectLockResult>({
        query: GET_OBJECT_LOCKS,
        fetchPolicy: 'network-only',
        variables: { objectTypes: objectType },
      });
      const apiLocks = lockResult?.data.getObjectLocks ?? [];

      const mapped = apiLocks.map<LockObjectItem>((lock) => ({
        entityId: lock.objPrimaryKey,
        user: lock.user,
        sessionId: lock.application,
      }));

      setLockData((d) => ({ ...d, [entity]: mapped }));
      return mapped;
    },
    [client]
  );

  const subscribe = useCallback(
    (entity: SubscriptionKey) => {
      queryLock(entity);
      const existingSubscription = subscriptions.current[entity];
      if (existingSubscription) {
        existingSubscription.size++;
        return;
      }
      const observable = client.subscribe({
        query: ENTITY_CHANGED_SUBSCRIPTION,
        variables: {
          changeType: [ChangeType.ITEM_INSERTED, ChangeType.ITEM_DELETED],
          objectType: ObjectTypeByEntity.objectLock,
          tenantId: activeTenant,
          objectLockObjectType: mapToObjectType(entity),
        },
      });
      const subscription = observable.subscribe({
        next({ data }) {
          if (data?.entityChanged) {
            // TODO: instead of refetch just update state
            // The entityChanged mhas no application / sessionId in it
            queryLock(entity);
          }
        },
        error(err) {
          console.error('Subscription error:', err);
        },
      }) as LockSubscription;
      subscription.size = 1;
      subscriptions.current = {
        ...subscriptions.current,
        [entity]: subscription,
      };
    },
    [client, activeTenant, queryLock, subscriptions]
  );

  const unsubscribe = useCallback((entity: Entities) => {
    const current = subscriptions.current[entity];
    if (current) {
      if (current.size == 1) {
        current.unsubscribe();
        delete subscriptions.current[entity];
      }
      current.size--;
    }
  }, []);

  const getLockFn = (id: string, entity: SubscriptionKey) => {
    const notALocker = !mapToObjectType(entity);
    if (notALocker) {
      return {
        locked: false,
        loading: false,
      };
    }

    const objectLocks = lockData[entity];
    if (!objectLocks) {
      if (!subscriptions.current[entity]) {
        console.error(`Thre is no subscription for ${entity} entity.`);
      }
      return {
        locked: true,
        loading: true,
      };
    }
    const lock = objectLocks.find((x) => `${x.entityId}` === `${id}`);
    const locked = Boolean(lock);

    return {
      lockedBy: locked ? lock?.user : null,
      locked,
      sessionId: lock?.sessionId,
    };
  };

  const getLock = useCallback(getLockFn, [lockData]);
  const getLockStable = useEventCallback(getLockFn);

  const refetchAll = useCallback(async () => {
    Object.keys(subscriptions.current).map(queryLock);
  }, []);
  useActiveInterval(refetchAll);

  return (
    <LockObjectContext.Provider
      value={{
        subscribe,
        unsubscribe,
        getLock,
        lockData,
        getLockStable,
        replaceItem,
      }}
    >
      {children}
    </LockObjectContext.Provider>
  );
};

export const useLockObject = () => useContext(LockObjectContext);

export const useLockSubscription = (
  entity: Entities,
  forcedObjectType?: ObjectTypesUnion
) => {
  const context = useContext(LockObjectContext);
  const { subscribe, unsubscribe } = context;

  useEffect(() => {
    if (!entity) {
      throwInDev('You need to subscribe to entity.');
    }

    if (entity === Entities.contact && !forcedObjectType) {
      console.error('Contact entity need to have objectType passed');
      return;
    }

    const key = mapToSubscriptionKey(entity, forcedObjectType);
    if (!mapToObjectType(key)) {
      console.error(`There is not objecType for ${key}`);
      return;
    }

    subscribe(key);
    return () => {
      unsubscribe(key);
    };
  }, [entity, subscribe, unsubscribe, forcedObjectType]);

  return context;
};

const mapToSubscriptionKey = (
  entity: Entities,
  forcedObjectType?: ObjectTypesUnion
): SubscriptionKey => {
  if (forcedObjectType === 'KUNDENANSPRECHPARTNER') {
    return 'contactCustomer';
  }
  if (forcedObjectType === 'LIEFERANTENANSPRECHPARTNER') {
    return 'contactSupplier';
  }
  if (forcedObjectType)
    throwInDev(`${entity} has unknown objectype ${forcedObjectType}`);
  return entity;
};

const mapToObjectType = (key: SubscriptionKey) => {
  if (key === 'contactCustomer') return 'KUNDENANSPRECHPARTNER';
  if (key === 'contactSupplier') return 'LIEFERANTENANSPRECHPARTNER';
  return ObjectTypeByEntity[key];
};
