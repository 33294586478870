import styles from './RowSizePicker.module.scss';

import { ListItem, ListItemButton, ListItemText, Popover } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RowSizeMode } from '../../../../../../../../../../settings/settings';
import { PickerTargetButton } from '../../../../../../../../locked-inputs';

const SIZES: RowSizeMode[] = ['SINGLE', 'AUTO'];

type Props = {
  value: RowSizeMode;
  onChange: (value: RowSizeMode) => void;
};

export const RowSizePicker = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const ref = useRef();

  const onClick = (action: RowSizeMode) => {
    setOpen(false);
    onChange(action);
  };

  return (
    <div ref={ref}>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        classes={{ paper: styles.root }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {SIZES.map((size) => (
          <ListItem key={size} disablePadding className={styles.pickerSize}>
            <ListItemButton
              className={styles.listItemButton}
              role={undefined}
              onClick={() => onClick(size)}
            >
              <ListItemText primary={t(`POSITIONS.ROW_SIZE.${size}`)} />
            </ListItemButton>
          </ListItem>
        ))}
      </Popover>
      <PickerTargetButton
        className={styles.pickerSize}
        label={t('POSITIONS.ROW_SIZE.TOOLTIP')}
        value={t(`POSITIONS.ROW_SIZE.${value}`)}
        onClick={() => setOpen(true)}
      />
    </div>
  );
};
