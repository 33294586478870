import styles from '../ProjectPlanningView.module.scss';

import { MbscCalendarEvent, MbscResource } from '@mobiscroll/react';
import { Close } from '@mui/icons-material';
import { Box, ButtonBase, IconButton, Stack } from '@mui/material';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { ProjectProcess } from '@work4all/models/lib/Classes/ProjectProcess.entity';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { ProjectProcessColumn, ProjectProcessColumnType } from '../types';

import { RESOURCE_PROJECT_ID_PRFX } from './utils';

export const useRenderResource = (props: {
  onDateChange: (data: Date) => void;
  onProjectRemoved: (projectId: number) => void;
  leftAreaColumns: ProjectProcessColumn[];
  focusedResourceId?: number;
  setFocusedResourceId: (id: number) => void;
}) => {
  const {
    onDateChange,
    leftAreaColumns,
    onProjectRemoved,
    focusedResourceId,
    setFocusedResourceId,
  } = props;
  return useCallback(
    (resource: MbscResource) => {
      const event: MbscCalendarEvent = resource.event;
      const process: ProjectProcess = resource.process;

      return (
        <Stack
          direction="row"
          justifyContent="end"
          height="100%"
          className={clsx({
            [styles.selectedRow]: focusedResourceId === resource.id,
          })}
          onClick={() => {
            setFocusedResourceId(resource.id as number);
          }}
        >
          {leftAreaColumns.map((col) => {
            if (col.hidden) {
              return null;
            }

            const isProjectResource = resource.id
              .toString()
              .startsWith(RESOURCE_PROJECT_ID_PRFX);

            const resourceIsParentOrProject =
              resource.isParent ||
              resource.id.toString().startsWith(RESOURCE_PROJECT_ID_PRFX);

            switch (col.type) {
              case ProjectProcessColumnType.Description:
                return (
                  <Box
                    key={col.type}
                    height="100%"
                    borderLeft={resource.isParent ? 0 : '1px solid var(--ui04)'}
                    width={resourceIsParentOrProject ? '100%' : col.width}
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    display="flex"
                  >
                    <Box
                      fontSize="0.75rem"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      whiteSpace="nowrap"
                      width="100%"
                      textAlign="left"
                      padding="1em"
                    >
                      {resource.name}
                    </Box>
                    {isProjectResource && (
                      <IconButton
                        onClick={() =>
                          onProjectRemoved(
                            parseInt(resource.id.toString().split('-')[1])
                          )
                        }
                        size="small"
                        style={{ height: '2.5rem' }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                );
              case ProjectProcessColumnType.StartDate:
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                  >
                    <ButtonBase
                      onClick={() => {
                        onDateChange(event?.start as Date);
                      }}
                      sx={{
                        height: '100%',
                        width: '100%',
                        fontSize: '0.75rem',
                      }}
                    >
                      {event &&
                        DateTime.fromJSDate(
                          event?.start as Date
                        ).toLocaleString(DateTimeCustom.DATE_SIMPLE_2YEAR)}
                    </ButtonBase>
                  </Box>
                );
              case ProjectProcessColumnType.EndDate:
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    onClick={() => {
                      onDateChange(event.end as Date);
                    }}
                  >
                    <ButtonBase
                      onClick={() => {
                        onDateChange(event?.end as Date);
                      }}
                      sx={{
                        height: '100%',
                        width: '100%',
                        fontSize: '0.75rem',
                      }}
                    >
                      {event &&
                        DateTime.fromJSDate(event?.end as Date).toLocaleString(
                          DateTimeCustom.DATE_SIMPLE_2YEAR
                        )}
                    </ButtonBase>
                  </Box>
                );
              case ProjectProcessColumnType.Duration:
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    fontSize="0.75rem"
                    display="flex"
                    justifyContent="end"
                    padding="0.7rem 0.5rem"
                    height="100%"
                  >
                    {event &&
                      Math.round(
                        DateTime.fromJSDate(event.end as Date).diff(
                          DateTime.fromJSDate(event.start as Date),
                          'day'
                        ).days
                      )}
                  </Box>
                );
              case ProjectProcessColumnType.TargetValue:
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    fontSize="0.75rem"
                    display="flex"
                    justifyContent="end"
                    padding="0.7rem 0.5rem"
                    height="100%"
                  >
                    {process?.planningAmount ? process?.planningAmount : ''}
                  </Box>
                );
              case ProjectProcessColumnType.ActualValue: {
                const amount =
                  (process?.projectProcessAppointmentList?.length &&
                    process?.projectProcessAppointmentList
                      ?.map((x) => x.projectProcessDuration)
                      .reduce((a, b) => a + b)) ||
                  0;
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    fontSize="0.75rem"
                    display="flex"
                    justifyContent="end"
                    padding="0.7rem 0.5rem"
                    height="100%"
                    color={
                      amount >= process?.planningAmount
                        ? 'var(--success)'
                        : undefined
                    }
                  >
                    {amount ? amount : ''}
                  </Box>
                );
              }
              case ProjectProcessColumnType.Difference: {
                const plannedAmount =
                  (process?.projectProcessAppointmentList?.length &&
                    process?.projectProcessAppointmentList
                      ?.map((x) => x.projectProcessDuration)
                      .reduce((a, b) => a + b)) ||
                  0;

                const amount = process?.planningAmount - plannedAmount;

                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    fontSize="0.75rem"
                    display="flex"
                    justifyContent="end"
                    padding="0.7rem 0.5rem"
                    height="100%"
                    color={amount <= 0 ? 'var(--success)' : undefined}
                  >
                    {amount ? amount : ''}
                  </Box>
                );
              }
              case ProjectProcessColumnType.Progress: {
                const completionPercent =
                  process?.completionFeedbacks?.[0]?.completionPercent || 0;
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    fontSize="0.75rem"
                    display="flex"
                    justifyContent="end"
                    padding="0.7rem 0.5rem"
                    height="100%"
                    color={
                      completionPercent === 100 ? 'var(--success)' : undefined
                    }
                  >
                    {completionPercent} %&nbsp;
                  </Box>
                );
              }
              case ProjectProcessColumnType.Resource:
                return resourceIsParentOrProject ? null : (
                  <Box
                    key={col.type}
                    borderLeft={'1px solid var(--ui04)'}
                    width={col.width}
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    fontSize="0.75rem"
                    display="flex"
                    justifyContent="start"
                    padding="0.7rem 0.5rem"
                    height="100%"
                    bgcolor={
                      process?.ressourceClass?.hexColorPair?.split(',')[0] ||
                      undefined
                    }
                  >
                    {process?.ressourceClass?.name || '-'} &nbsp;
                  </Box>
                );
              default:
                return null;
            }
          })}
        </Stack>
      );
    },
    [
      focusedResourceId,
      leftAreaColumns,
      onDateChange,
      onProjectRemoved,
      setFocusedResourceId,
    ]
  );
};
