import { Grid } from '@mui/material';
import React from 'react';
import { Control, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Collapse } from '@work4all/components/lib/components/collapse';

import { useUser } from '@work4all/data';

import { Contract } from '@work4all/models/lib/Classes/Contract.entity';
import { InboundDeliveryNote } from '@work4all/models/lib/Classes/InboundDeliveryNote.entity';
import { Invoice } from '@work4all/models/lib/Classes/Invoice.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';
import { canEditERPStatus } from '@work4all/utils/lib/permissions';

import { ErpStatusPickerField } from '../../../../../../../../../../components/entity-picker/ErpStatusPickerField';
import { InvoiceStatusPickerField } from '../../../../../../../../../../components/entity-picker/InvoiceStatusPickerField';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInput,
} from '../../../../../../../../locked-inputs';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { ErpData } from '../../../../../ErpData';

export const Description: React.FC = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();
  const { register, control } = useFormContextPlus<ErpData>();
  const dateProperty =
    Entities.contract === mask.entity ? 'contractDate' : 'date';
  const numberProperty =
    Entities.contract === mask.entity ? 'contractNumber' : 'number';

  const user = useUser();

  const ERPStatusEditAllowed = canEditERPStatus(user);

  return (
    <Collapse defaultOpen={true} title={t('MASK.DESCRIPTION')}>
      <Grid container spacing="1rem">
        <Grid item xs={12} md={6}>
          <LabeledInput
            label={t('COMMON.NOTE')}
            {...register('note')}
            required={true}
            multiline={true}
            style={{ minHeight: '7rem' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing="1rem">
            <Grid item xs={4}>
              <ControllerPlus
                control={control as Control<PathsOf<Contract>>}
                name={numberProperty}
                render={({ field }) => {
                  const { value, onChange } = field;

                  const handleChange = (
                    e: React.ChangeEvent<HTMLInputElement>
                  ) => {
                    const newValue = e.target.value;

                    const newValue2 = newValue.replace(/[^0-9]/g, '');

                    const valueAsNumber =
                      newValue2 === '' ? null : Number(newValue2);

                    onChange(valueAsNumber);
                  };
                  return (
                    <LabeledInput
                      label={
                        mask.isCreateMode
                          ? t('INPUTS.NUMBER_AUTO')
                          : t('INPUTS.NUMBER')
                      }
                      value={String(value ?? '')}
                      onChange={handleChange}
                      type="text"
                      error={field.error}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <ControllerPlus
                control={control}
                name="frozen"
                render={({ field: frozenField }) => {
                  if (mask.entity === Entities.invoice) {
                    return (
                      <ControllerPlus
                        control={
                          control as Control<PathsOf<Invoice, 2>, object>
                        }
                        name="ra"
                        render={({ field: raField }) => {
                          return (
                            <ControllerPlus
                              control={
                                control as Control<PathsOf<Invoice, 2>, object>
                              }
                              name="paid"
                              render={({ field: paidField }) => {
                                return (
                                  <div>
                                    <InvoiceStatusPickerField
                                      ra={raField?.value}
                                      paid={paidField?.value}
                                      onChange={(ra, paid) => {
                                        raField.onChange(ra);
                                        paidField.onChange(paid);
                                      }}
                                      disabled
                                    />
                                  </div>
                                );
                              }}
                            />
                          );
                        }}
                      />
                    );
                  }
                  return [
                    Entities.offer,
                    Entities.deliveryNote,
                    Entities.contract,
                    Entities.order,
                    Entities.demand,
                    Entities.calculation,
                    Entities.inboundDeliveryNote,
                  ].includes(mask.entity) ? (
                    <ControllerPlus
                      control={control as Control<PathsOf<Contract, 2>, object>}
                      name="isClosed"
                      render={({ field: closedField }) => {
                        return (
                          <ErpStatusPickerField
                            frozen={frozenField?.value}
                            isClosed={closedField?.value}
                            onChange={(frozen, isClosed) => {
                              frozenField.onChange(frozen);
                              closedField.onChange(isClosed);
                            }}
                            disabled={!ERPStatusEditAllowed ? true : undefined}
                          />
                        );
                      }}
                    />
                  ) : null;
                }}
              />
            </Grid>

            {[Entities.invoice].includes(mask.entity) ? (
              <React.Fragment>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.DATE')}
                    withTime={false}
                    {...(register as UseFormRegister<PathsOf<Invoice>>)('date')}
                    clearable={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.BOOKING_DATE')}
                    withTime={false}
                    {...(register as UseFormRegister<PathsOf<Invoice>>)(
                      'bookingDate'
                    )}
                    clearable={false}
                  />
                </Grid>
              </React.Fragment>
            ) : null}
            {[
              Entities.offer,
              Entities.deliveryNote,
              Entities.contract,
              Entities.order,
              Entities.demand,
              Entities.calculation,
            ].includes(mask.entity) ? (
              <React.Fragment>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.DATE')}
                    withTime={false}
                    {...(register as UseFormRegister<PathsOf<Contract>>)(
                      dateProperty
                    )}
                    clearable={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.PLANNED_DATE_OF_DELIVERY')}
                    withTime={false}
                    {...(register as UseFormRegister<PathsOf<Contract>>)(
                      'contractStartDate'
                    )}
                    clearable
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.FACT_DATE_OF_DELIVERY')}
                    withTime={false}
                    {...register(
                      //eslint-disable-next-line
                      //@ts-ignore
                      mask.entity === Entities.order
                        ? 'actualDeliveryDate'
                        : 'outgoingDeliveryDate'
                    )}
                    clearable
                  />
                </Grid>
              </React.Fragment>
            ) : null}

            {[Entities.inboundDeliveryNote].includes(mask.entity) ? (
              <React.Fragment>
                <Grid item xs={4}>
                  <LabeledInput
                    label={t('INPUTS.DELIVERY_RECEIPT_NUMBER')}
                    {...(
                      register as UseFormRegister<PathsOf<InboundDeliveryNote>>
                    )('deliveryNoteNumber')}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.DATE')}
                    withTime={false}
                    {...register('date')}
                    clearable={false}
                  />
                </Grid>
                <Grid item xs={4}>
                  <DateTimeInputPicker
                    required={false}
                    dateLabel={t('INPUTS.DELIVERY_DATE')}
                    withTime={false}
                    {...(
                      register as UseFormRegister<PathsOf<InboundDeliveryNote>>
                    )('deliveryDate')}
                    clearable
                  />
                </Grid>
              </React.Fragment>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Collapse>
  );
};
