import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useTableStateBag } from '@work4all/components';
import { useTablePrefilterContext } from '@work4all/components/lib/components/table/TablePrefilterProvider';

import { canFlagEntityRecordsAsFavorite } from '@work4all/data/lib/utils/can-flag-entity-records-as-favorite';

import { Work4AllEntity } from '@work4all/models/lib/additionalEnums/Work4AllEntity.entity';
import { ICustomCellConfigBase } from '@work4all/models/lib/table-schema/table-schema';

import { useToggleItemFavorability } from '../../../hooks/use-toggle-Item-favorability';
import { isBoardEnabled } from '../table/components/kanban-board/Board';
import { useTableLayoutState } from '../table-layout';
import { IUseDataTableOptions, useDataTable } from '../use-data-table';

export interface IUseEntityDataOptions<
  TEntity,
  TCustomColumns extends Record<string, ICustomCellConfigBase>
> extends Omit<
      IUseDataTableOptions<TCustomColumns>,
      'prefilter' | 'layout' | 'tableStateBag'
    >,
    Partial<
      Pick<
        IUseDataTableOptions<TCustomColumns>,
        'prefilter' | 'layout' | 'tableStateBag'
      >
    > {
  rowModifiers?: (value?: TEntity) => Record<string, boolean>;
}

/**
 * Wrapper for Table entities
 * @param options Look useDataTable
 * @returns useDataTable result
 */
export function useEntityDataTable<
  Entity extends Work4AllEntity,
  CustomColumns extends Record<string, ICustomCellConfigBase>
>({
  rowModifiers,
  schema: schemaProps,
  ...options
}: IUseEntityDataOptions<Entity, CustomColumns>) {
  const { prefilter } = useTablePrefilterContext();
  const tableStateBag = useTableStateBag();
  const layoutState = useTableLayoutState();
  const [layoutSetting, setLayout] = layoutState;
  const canFlagAsFavorite = canFlagEntityRecordsAsFavorite(schemaProps.entity);

  const toggleItemFavorability = useToggleItemFavorability();

  const { t } = useTranslation();

  const schema = useMemo(() => {
    // TODO Fix that
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const columns: any = [...schemaProps.columns];

    if (canFlagAsFavorite) {
      columns.unshift({
        title: t('COMMON.FLAG'),
        accessor: 'favoriteItem.id',
        id: 'favoriteFlag',
        width: 50,
        sortable: false,
        groupable: false,
        quickSearchable: false,
        cell: {
          type: 'Flag',
          params: {
            onClick: (id: string | number, isFavorite: boolean) => {
              toggleItemFavorability({
                id,
                entity: schemaProps.entity,
                isFavorite,
              });
            },
          },
        },
        defaultHidden: false,
        filterable: false,
      });
    }

    return { ...schemaProps, columns };
  }, [schemaProps, canFlagAsFavorite, toggleItemFavorability, t]);

  useEffect(() => {
    if (isBoardEnabled(schema.entity) || layoutSetting !== 'board') return;
    // in case for reset to Table where entity does not suppor board
    setLayout('table');
  }, [schema]);

  const dataTable = useDataTable<Entity, CustomColumns>({
    tableStateBag,
    layout:
      isBoardEnabled(schema.entity) || layoutSetting !== 'board'
        ? layoutSetting
        : 'table',
    prefilter,
    schema,
    ...options,
  });

  const prepareRowDisplayModifiers = useCallback(
    (value: Entity) => {
      const defaultModifiers = dataTable.prepareRowDisplayModifiers(value);
      const extraModifiers = rowModifiers?.(value);

      return {
        ...defaultModifiers,
        ...extraModifiers,
      };
    },
    [dataTable, rowModifiers]
  );

  return { ...dataTable, prepareRowDisplayModifiers, schema };
}
