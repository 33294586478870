import { useDataMutation } from '@work4all/data';

import { CheckList } from '@work4all/models/lib/Classes/CheckList.entity';
import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { InputChecklistePosition } from '@work4all/models/lib/Classes/InputChecklistePosition.entity';
import { InputChecklisteRelation } from '@work4all/models/lib/Classes/InputChecklisteRelation.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { pickUpdateFields } from '../../../utils/pick-update-fields';

import {
  CheckListMaskFormValue,
  UseCheckListSubmitHandler,
  UseCheckListSubmitHandlerSubmitHandlerFunction,
} from './types';

const positionToPositionInput = (
  position: CheckListPosition,
  withId = false
) => {
  const result: InputChecklistePosition = {
    name: position.name,
    positionsNummer: position.positionNumber,
    prioritaet: position.priority,
    checked: position.isChecked,
    benutzerCodeGeplant: position.plannedByUserId,
    personGeplant: position.plannedBy,
    beginntAm: position.date,
    art: position.kind,
    bisWann: position.endDate,
    benutzerCodeErledigt: position.doneByUserId,
    kategorie1: position.category1,
    kategorie2: position.category2,
    lieferantenCode: position.supplierId,
    projektePlanungCode: position.projectProcessId,
    notiz: position.note,
  };

  if (withId) {
    result['code'] = position.id;
  }

  return result;
};

const mapPositionsRelations = (
  oldPositions: CheckListPosition[] = [],
  newPositions: CheckListPosition[]
) => {
  const add = [];
  const modify = [];

  newPositions.forEach((position) => {
    if (position.id < 0) {
      add.push(positionToPositionInput(position));
    }

    if (position.id > 0) {
      modify.push(positionToPositionInput(position, true));
    }
  });

  const modifiedIds = newPositions
    .map((position) => position.id)
    .filter((id) => id > 0);

  const positionsToDelete = oldPositions
    .map((position) => position.id)
    .filter((id) => !modifiedIds.includes(id));

  return {
    add,
    modify,
    delete: positionsToDelete,
  };
};

const mapRelations = (oldData: CheckList, newData: CheckList) => {
  const relations: InputChecklisteRelation = {};

  if (newData.positionList) {
    relations.positions = mapPositionsRelations(
      oldData.positionList,
      newData.positionList
    );
  }

  return relations;
};

export const useCheckListSubmitHandler: UseCheckListSubmitHandler = ({
  isCreateMode,
  dirtyFields,
  onAfterSave,
  defaultValues,
}) => {
  const [mutate] = useDataMutation<
    CheckListMaskFormValue,
    EMode.upsert,
    InputChecklisteRelation
  >({
    entity: Entities.checkList,
    mutationType: EMode.upsert,
    responseData: { id: null },
    onCompleted: onAfterSave,
  });

  const submitHandler: UseCheckListSubmitHandlerSubmitHandlerFunction = async (
    data
  ) => {
    const mutateData = isCreateMode
      ? data
      : pickUpdateFields<CheckListMaskFormValue>(data, dirtyFields);

    const relations = mapRelations(defaultValues, data);

    await mutate(mutateData, { relations });
  };

  return submitHandler;
};
