export enum UserRights {
  NotSet = -1,
  KundenNeuAendern = 0,
  KundenLoeschen = 1,
  KundenAnsprechpartnerNeuAendern = 59,
  KundenAnsprechpartnerLoeschen = 60,
  LieferantenNeuAendern = 2,
  LieferantenLoeschen = 3,
  LieferantenAnsprechpartnerNeuAendern = 61,
  LieferantenAnsprechpartnerLoeschen = 62,
  MitarbeiterNeuAendern = 4,
  MitarbeiterLoeschen = 5,
  AdressverknuepfungVerwalten = 6,
  ExportAssistentNutzen = 8,
  ImportAssistentNutzen = 9,
  GenerelleEinstellungVerwalten = 10,
  KategorieVerwalten = 12,
  TelefonwahlmodulNutzen = 13,
  TextbausteineVerwalten = 14,
  KundenLieferantenZusammenfuehren = 15,
  KorrespondenzAnlegenAendern = 16,
  KorrespondenzLoeschen = 17,
  KorrespondenzLoeschenEigene = 18,
  TelefonateAufgabenNotizenAnlegenAendern = 19,
  TelefonateAufgabenNotizenLoeschen = 20,
  TelefonateAufgabenNotizenAendernLoeschenEigene = 21,
  AngeboteWordAnlegenAendern = 22,
  AngeboteWordLoeschen = 23,
  AngeboteWordAendernLoeschenEigene = 24,
  TermineAnlegenAendern = 25,
  TermineLoeschen = 26,
  TermineAendernLoeschenEigene = 27,
  BesuchsberichteAnlegenAendern = 28,
  BesuchsberichteLoeschen = 29,
  BesuchsberichteAendernLoeschenEigene = 30,
  JournalNutzen = 31,
  ListenreportsNutzen = 32,
  SerienEmailErstellen = 33,
  SerienbriefErstellen = 34,
  Statistik = 35,
  Scannen = 75,
  Insight = 76,
  VerkaufschancenNutzen = 81,
  Neukundenkalendar = 77,
  VorlagenAnlegenLoeschen = 36,
  ZeigenRegisterkarteKunden = 37,
  ZeigenRegisterkarteLieferanten = 38,
  ZeigenRegisterkarteProjekte = 39,
  ZeigenRegisterkarteFirma = 40,
  ZeigenRegisterkarteErp = 41,
  ZeigenInfofensterKundenAngebote = 42,
  ZeigenInfofensterKundenEingangslieferscheine = 182,
  ZeigenInfofensterKundenAufgaben = 43,
  ZeigenInfofensterKundenBesuchsberichte = 44,
  ZeigenInfofensterKundenBriefe = 45,
  ZeigenInfofensterKundenDokumente = 46,
  ZeigenInfofensterKundenEmail = 47,
  ZeigenInfofensterKundenNotizen = 48,
  ZeigenInfofensterKundenTelefonate = 49,
  ZeigenInfofensterKundenTermine = 50,
  ZeigenInfofensterKundenLieferscheine = 130,
  ZeigenInfofensterKundenRechnungen = 131,
  ZeigenInfofensterKundenAuftraege = 132,
  ZeigenInfofensterKundenTickets = 140,
  ZeigenInfofensterLieferantenAufgaben = 139,
  ZeigenInfofensterLieferantenBriefe = 141,
  ZeigenInfofensterLieferantenDokumente = 142,
  ZeigenInfofensterLieferantenEmail = 143,
  ZeigenInfofensterLieferantenNotizen = 144,
  ZeigenInfofensterLieferantenTelefonate = 145,
  ZeigenInfofensterLieferantenTermine = 146,
  ZeigenInfofensterLieferantenEingangsrechnung = 147,
  ZeigenInfofensterLieferantenEingangslieferscheine = 183,
  ZeigenInfofensterLieferantenBestellung = 151,
  ZeigenInfofensterLieferantenBedarfsanforderungen = 152,
  ZeigenInfofensterLieferantenReisekostenabrechnungen = 170,
  ZeigenInfofensterProjekteAngebote = 153,
  ZeigenInfofensterProjekteAufgaben = 154,
  ZeigenInfofensterProjekteBesuchsberichte = 155,
  ZeigenInfofensterProjekteBriefe = 156,
  ZeigenInfofensterProjekteDokumente = 157,
  ZeigenInfofensterProjekteEmail = 158,
  ZeigenInfofensterProjekteNotizen = 159,
  ZeigenInfofensterProjekteTelefonate = 160,
  ZeigenInfofensterProjekteTermine = 161,
  ZeigenInfofensterProjekteEingangsrechnungen = 162,
  ZeigenInfofensterProjekteLieferscheine = 163,
  ZeigenInfofensterProjekteRechnungen = 164,
  ZeigenInfofensterProjekteAuftraege = 165,
  ZeigenInfofensterProjekteBestellungen = 166,
  ZeigenInfofensterProjekteBedarf = 167,
  ZeigenInfofensterProjekteTickets = 133,
  ZeigenKundenMaskeNotizen = 51,
  ZeigenKundenMaskeBild = 52,
  ZeigenKundenMaskeKategorie = 53,
  ZeigenKundenMaskeVerknuepfung = 63,
  ZeigenKundenAnsprechpartnerMaskePrivates = 65,
  ZeigenKundenAnsprechpartnerMaskeNotizen = 66,
  ZeigenKundenAnsprechpartnerMaskeBild = 67,
  ZeigenKundenAnsprechpartnerMaskeKategorie = 68,
  ZeigenLieferantenMaskeNotizen = 55,
  ZeigenLieferantenMaskeBild = 56,
  ZeigenLieferantenMaskeKategorie = 57,
  ZeigenLieferantenMaskeVerknuepfung = 64,
  ZeigenLieferantenAnsprechpartnerMaskePrivates = 70,
  ZeigenLieferantenAnsprechpartnerMaskeNotizen = 71,
  ZeigenLieferantenAnsprechpartnerMaskeBild = 72,
  ZeigenLieferantenAnsprechpartnerMaskeKategorie = 73,
  Umsatzansicht = 82,
  ZeigenExtrasMarketingTools = 83,
  UrlaubskalenderAufrufen = 78,
  UrlaubskalenderEigeneDatenPflegen = 79,
  UrlaubskalenderEigeneFremdeDatenPflegen = 80,
  UrlaubsKalenderHervorgehobeneBereichePflegen = 256,
  ProjektKostenzuordnen = 257,
  ProjekteNeu = 84,
  ProjektAendern = 216,
  ProjekteLoeschen = 85,
  ProjekteAendernLoeschenEigene = 86,
  KalkulationenBearbeiten = 87,
  KostenerfassungNutzen = 88,
  ProjektKostenUebersichtNutzen = 90,
  SollIstVergleichNutzen = 91,
  PlantermineAnlegenAendern = 92,
  ProjektvorgaengeAnlegenAendern = 93,
  ArtikelNeuAendern = 94,
  ArtikelLoeschen = 95,
  ArtikelNummerAendern = 96,
  GruppenVerwalten = 97,
  KundenLieferantenDeaktivieren = 98,
  AuftragsbestaetigungErstellenAendern = 99,
  LieferscheinErstellenAendern = 100,
  RechnungErstellenAendern = 101,
  BestellungErstellenAendern = 102,
  BedarfErstellenAendern = 103,
  RechnungseingangErstellenAendern = 104,
  RechnungenLoeschen = 105,
  LieferscheineLoeschen = 106,
  AngebotErstellenAendern = 107,
  AngebotLoeschen = 108,
  BedarfsmeldungenLoeschen = 109,
  GaebImportDurchfuehren = 110,
  RechnungsausgangsbuchEinsehenBearbeiten = 111,
  AuftraegeLoeschen = 112,
  BestellungenLoeschen = 113,
  Mahnwesen = 114,
  FibuSchnittstelle = 115,
  Inventarverwaltung = 116,
  WareneingaengeBuchen = 117,
  WarenausgaengeBuchen = 118,
  LoeschenUmbuchenUndStornierenVonLagerbewegungen = 119,
  QMDokumenteErstellenAendern = 120,
  QMDokumenteLoeschen = 121,
  PruefauftraegeErstellenAendern = 122,
  PruefauftraegeLoeschen = 123,
  Lieferantenbewertungen = 124,
  DTALastschriftverfahren = 125,
  DTAZahlungsverfahren = 126,
  FreigabeEingangsrechnungen = 127,
  OffenePostenKundenEinsehen = 128,
  QMDokumenteAnsehen = 135,
  EingangsrechnungenLoeschen = 136,
  OffenePostenLieferantenEinsehen = 137,
  GruppeAendernLoeschen = 134,
  EingangslieferscheinAnlegenAendern = 129,
  EingangslieferscheinLoeschen = 148,
  Reisekostenabrechnung = 149,
  WartungsmodulNutzen = 150,
  FremdeKalenderEinsehen = 168,
  ProduktionsauftraegeErstellenAendern = 138,
  ProduktionsauftraegeLoeschen = 169,
  ArtikellisteEinsehen = 171,
  ProjektgruppenAnzeigen = 172,
  LieferantengruppenAnzeigen = 173,
  ProjektbezogeneAnsichtsrechteIgnorieren = 174,
  AngeboteNurAnschauen = 175,
  AuftraegeNurAnschauen = 176,
  LieferscheineNurAnschauen = 177,
  RechnungenNurAnschauen = 178,
  BestellungenNurAnschauen = 179,
  BedarfsmeldungenNurAnschauen = 180,
  EingangslieferscheineNurAnschauen = 181,
  Produktionsartikelbearbeiten = 184,
  ProjektStatusAendern = 185,
  KasseBearbeiten = 186,
  FreigabeAngebotFremde = 187,
  FreigabeAuftragFremde = 188,
  FreigabeRechnungFremde = 189,
  FreigabeBestellungFremde = 190,
  FreigabeLieferscheinFremde = 191,
  FreigabeBedarfFremde = 192,
  InfofensterPositionAendern = 195,
  Unternehmenscontrolling = 196,
  SuperInfoDrucken = 197,
  Unbenutzt_EhemalsPlankalender = 198,
  ZeigenInfofensterLieferantenLieferantenbewertung = 199,
  ZeigenInfofensterProjekteLieferantenbewertung = 200,
  ExcelConnectorNutzen = 201,
  Mitarbeiterliste = 202,
  Geraeteverwaltung = 203,
  ZeiterfassungGesperrteEintraegeAendern = 204,
  FreigabeEingangsrechnungenNurEigene = 205,
  EKPreiseAnzeigen = 206,
  FreigabeEingangsrechnungenNurWeiterleiten = 207,
  PlankalenderPlantafelNutzen = 208,
  BankingNutzen = 209,
  ZeiterfassungFuerAndereBenutzer = 210,
  ExcelExportNutzen = 211,
  BankverbindungAnlegenAendern = 212,
  ForecastErfassen = 213,
  ForecastVerwalten = 214,
  KontrolleLetzterWerktag = 215,
  AngeboteVerdoppeln = 217,
  BedarfsanforderungenVerdoppeln = 218,
  BestellungenVerdoppeln = 219,
  AuftraegeVerdoppeln = 220,
  EingangslieferscheineVerdoppeln = 221,
  LieferscheineVerdoppeln = 222,
  RechnungenVerdoppeln = 223,
  SummenAnzeigeRa = 224,
  EigeneArbeitszeitenAnzeigen = 225,
  FremdeArbeitszeitenAnzeigen = 226,
  ProjektControllingEinsehen = 227,
  IndividuellesControllingEinsehen = 228,
  ProjektverteilerPflegen = 229,
  KundenDebitorennummerAendern = 230,
  LieferantenKreditorennummerAendern = 231,
  ProjektNummerAendern = 232,
  AngebotNummerAendern = 233,
  BedarfNummerAendern = 234,
  BestellungNummerAendern = 235,
  AuftragNummerAendern = 236,
  EingangslieferscheinNummerAendern = 237,
  EingangsrechnungNummerAendern = 238,
  LieferscheinNummerAendern = 239,
  RechnungNummerAendern = 240,
  WartungsvertragsNummerAendern = 241,
  VerkaufschancenNurEigeneAendernLoeschen = 242,
  UeberstundenUebertragAuszahlungPflegen = 243,
  ZahlungenErfassen = 244,
  MonatsLagerabschluss = 245,
  KalenderKrankheitstageAnzeigen = 246,
  FreigabeGutschrift = 247,
  PausenStempeln = 248,
  KommtGehtZeitenAendernEigene = 193,
  KommtGehtZeitenAnsehen = 194,
  KommtGehtZeitenAendernFremde = 249,
  KommtGehtCockpitZeigen = 89,
  KommtGehtZeitenKorrigierenEigene = 250,
  KommtGehtMoantsKorrekturenVerwalten = 255,
  MittlerenEKAendern = 251,
  EMailSignaturenBearbeiten = 252,
  AuftauenVonEingefrorenenVerkaufschancen = 253,
  LieferantendatenVonMitarbeiternEinsehen = 254,
  VKPreiseInZeiterfassungAnzeigen = 258,
  StatistikUmsatzNachVerantwortlichem = 259,
  AppDesktopIch = 500,
  AppDesktopTeam = 501,
  AppDesktopFirma = 502,
  AppDesktopKundenDesktop = 505,
  AppDesktopLieferantenDesktop = 553,
  AppDesktopProjektDesktop = 554,
  AppDesktopDashboard = 555,
  AppIchAufgaben = 506,
  AppIchTelefonate = 561,
  AppIchTermine = 507,
  AppIchTickets = 508,
  AppIchVerkaufschancen = 509,
  AppIchAngebote = 510,
  AppIchAuftraege = 511,
  AppIchLieferscheine = 512,
  AppIchRechnungen = 513,
  AppIchBestellungen = 514,
  AppIchKostenbelege = 515,
  AppIchZeiterfassungProjekt = 516,
  AppIchZeiterfassungKommtGeht = 517,
  AppIchMitarbeiterwechsel = 556,
  AppIchGenehmigungen = 564,
  AppTeamAufgaben = 518,
  AppTeamTermine = 519,
  AppTeamTickets = 520,
  AppTeamVerkaufschancen = 521,
  AppTeamAngebote = 522,
  AppTeamAuftraege = 523,
  AppTeamLieferscheine = 524,
  AppTeamRechnungen = 525,
  AppTeamBestellungen = 526,
  AppTeamKostenbelege = 527,
  AppFirmaAufgaben = 528,
  AppFirmaBesuchsberichte = 529,
  AppFirmaBriefe = 530,
  AppFirmaDokumente = 531,
  AppFirmaEMails = 532,
  AppFirmaTelefonate = 533,
  AppFirmaTermine = 534,
  AppFirmaTickets = 535,
  AppFirmaVerkaufschancen = 536,
  AppFirmaAngebote = 537,
  AppFirmaAuftraege = 538,
  AppFirmaBestellungen = 539,
  AppFirmaLieferscheine = 540,
  AppFirmaKostenbelege = 541,
  AppFirmaRechnungen = 542,
  AppFirmaOffenePosten = 562,
  AppFunktionenAufgaben = 543,
  AppFunktionenDokumente = 544,
  AppFunktionenTelefonate = 545,
  AppFunktionenBesuchsberichte = 546,
  AppFunktionenAngebote = 547,
  AppFunktionenAuftraege = 548,
  AppFunktionenLieferscheine = 549,
  AppFunktionenWandlungErp = 565,
  AppDashboardUmsatz = 550,
  AppDashboardAuftragseingang = 551,
  AppDashboardOffenePosten = 552,
  AppDashboardMitarbeiterkennzahlen = 557,
  AppDashboardVertriebNeukunden = 558,
  AppDashboardVertriebInteressenten = 559,
  AppDashboardFirmaKennzahlen = 560,
  VorgangsnachverfolgungNutzen = 563,
  TicketAnlegenAendern = 566,
  TicketLoeschen = 567,
  PositionenKurztextBearbeitungVerhindern = 568,
  AppWechselDerAbteilung = 569,
  AppFreigabeAngebote = 570,
  AppFreigabeAuftraege = 571,
  AppFreigabeLieferscheine = 572,
  AppFreigabeRechnungen = 573,
  AppFreigabeBestellungen = 574,
  AppGenehmigungEingangsrechnungen = 575,
  AppFreigabeGutschriften = 576,
  AppTicketsErfassenUndAendern = 577,
  AppZeiterfassungOhneZeitaenderung = 578,
  WartungsvertraegeAnlegenundAendern = 579,
  WartungsvertraegeVerdoppeln = 580,
  WartungsvertraegeLoeschen = 581,
  WartungsvertraegenurAnschauen = 582,
  WartungsvertraegeAboLaufAusfuehren = 583,
  EingangsrechnungProjektzuordnungaendern = 584,
  KalkulationenVerdoppeln = 585,
  KalkulationenLoeschen = 586,
  KalkulationenNurAnschauen = 587,
  SummenInERPListenAnzeigen = 588,
  ProjektWiederAktivierenWennAbgeschlossen = 589,
  KrankheitstageInProjektplanung = 590,
  AppFreigabeBedarfsanforderung = 591,
  ArtikelNurAnschauen = 592,
  UrlaubsgenehmigungErteilen = 593,
  LagerMobilEingangslieferscheinErstellen = 594,
  LagerMobilEingangslieferscheinOeffnen = 595,
  LagerMobilEingangslieferscheinPositionHinzufuegenEinbuchen = 596,
  LagerMobilEingangslieferscheinPositionEinbuchen = 597,
  LagerMobilLieferscheinErstellen = 598,
  LagerMobilLieferscheinOeffnen = 599,
  LagerMobilLieferscheinPositionHinzufuegenAusbuchen = 600,
  LagerMobilLieferscheinPositionAusbuchen = 601,
  LagerMobilUmbuchungLieferscheinErstellen = 602,
  LagerMobilUmbuchungLieferscheinOeffnen = 603,
  LagerMobilUmbuchungLieferscheinPositionHinzufuegenBuchen = 604,
  LagerMobilUmbuchungLieferscheinPositionBuchen = 605,
  LagerMobilArtikelInfosAnzeigen = 606,
  LagerMobilArtikelAnlegenAendern = 607,
  LagerMobilInventurDurchfuehren = 608,
  LagerMobilBeleglosesBuchenErlauben = 609,
  KommtGehtZeitkartenAuswertung = 610,
  ArtikelnummerInErpBelegenAendern = 611,
  ERPstatusAendern = 612,
  Leistungsnachweis_AnsichtEigener = 613,
  Leistungsnachweis_AnsichtEigenerUndZugeordneteMitarbeiter = 614,
  Leistungsnachweis_AnsichtAlle = 615,
  Zeitkarte_AnsichtEigene = 616,
  Zeitkarte_AnsichtEigeneUndZugeordneteMitarbeiter = 617,
  Zeitkarte_Alle = 618,
  UrlaubsanspruchAendernEigenen = 619,
  UrlaubsanspruchAendernFremden = 620,
  MobilNurEigeneVorgaenge = 621,
  EingangsrechnungNurAnschauen = 622,
  MobilPreiseAusblenden = 623,
  KundenindividuellePreisePflegen = 624,
  GesperrteChargenBuchen = 625,
  FreigabeAngebotEigene = 626,
  FreigabeAuftragEigene = 627,
  FreigabeLieferscheinEigene = 628,
  FreigabeRechnungEigene = 629,
  FreigabeGutschriftEigene = 630,
  FreigabeBestellungEigene = 631,
  FreigabeBedarfEigene = 632,
  ChargenInQuarantaeneAusbuchen = 633,
  Web_PreiseInERPBelegenAusblenden = 637,
}

export function checkUserRight(index: UserRights, userRightsString: string) {
  let result = false;

  if (!userRightsString || userRightsString === undefined) {
    return false;
  }
  userRightsString = userRightsString.replace('[', '').replace(']', '');
  if (index >= 0 && index < userRightsString.length) {
    if (userRightsString[index] === '0') result = false;
    else result = true;
  }

  return result;
}
