import { gql, useQuery } from '@apollo/client';

const GET_VALIDATE = gql`
  query ValidateDirectoryPath($directoryPath: String!) {
    validateDirectoryPath(directoryPath: $directoryPath) {
      ... on DirectoryProbeErrorResult {
        type
      }
      ... on DirectoryProbeSuccessResult {
        exists
        fileServiceProvider {
          name
          id
          isWorkDirectory
          directoryNameReplacePath
          directoryNameConvertPath
          providerType
        }
      }
    }
  }
`;

interface ValidateDirectoryPathResult {
  validateDirectoryPath: {
    __typename: string;
    exists?: boolean;
  };
}

interface GetUserPresenceVariables {
  directoryPath: string;
}

export const useValidateDirectoryPath = (directoryPath: string) => {
  const query = useQuery<ValidateDirectoryPathResult, GetUserPresenceVariables>(
    GET_VALIDATE,
    {
      skip: !directoryPath,
      variables: { directoryPath },
    }
  );
  return {
    isValid:
      (query.data?.validateDirectoryPath.__typename ===
        'DirectoryProbeSuccessResult' &&
        query.data?.validateDirectoryPath.exists) ||
      query.loading,
    loading: query.loading,
  };
};
