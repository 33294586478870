import { nanoid } from 'nanoid';

export let sessionId = nanoid();
const currentTabSessionId = sessionStorage.getItem('tabId');
const lastClosedSessionId = localStorage.getItem('lastClosedTabId');
if (
  currentTabSessionId &&
  lastClosedSessionId &&
  currentTabSessionId === lastClosedSessionId
) {
  sessionId = currentTabSessionId;
}
localStorage.removeItem('lastClosedTabId');
sessionStorage.setItem('tabId', sessionId);
window.addEventListener('unload', () => {
  localStorage.setItem('lastClosedTabId', sessionId);
});

export const application = `work4all 2.0 ${navigator.userAgent} ${sessionId}`;
