import styles from './WidgetGroupContent.module.scss';

import { Theme, useMediaQuery } from '@mui/material';
import { produce } from 'immer';
import { cloneDeep, partition } from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';

import { GridLayout } from '@work4all/components/lib/dataDisplay/grid-layout/GridLayout';
import {
  LayoutResponsiveBreakpoint,
  LayoutResponsiveDefinition,
} from '@work4all/components/lib/utils/react-grid-layout/react-grid-layout-models';

import { FileViewMode, useWidgetsDataBag } from '@work4all/data';

import {
  ILayoutDefinition,
  LayoutDefinitionTypes,
} from '@work4all/models/lib/Layout';

import { settings, useSetting } from '../../../../../../settings';
import { resolvePreviewType } from '../../../FilePage';
import { FileContext } from '../../FileContext';
import { CardWidgetContainer } from '../CardWidgetContainer';

export type IWidgetGroupContentProps = {
  widgets: ILayoutDefinition[];
  viewMode: FileViewMode;
};

export const LAYOUT_SIZES: LayoutResponsiveBreakpoint[] = [
  'lg',
  'md',
  'sm',
  'xs',
  'xxs',
];
export const WIDGET_COLS = { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 };
export const DEFAULT_WIDGET_WIDTHS = { lg: 3, md: 4, sm: 4, xs: 6, xxs: 12 };
export const BLANK_LAYOUT: LayoutResponsiveDefinition = {
  lg: [],
  md: [],
  sm: [],
  xs: [],
  xxs: [],
};

export function WidgetGroupContent({
  widgets: definitions,
  viewMode,
}: IWidgetGroupContentProps) {
  const isViewportDesktop = useMediaQuery<Theme>(
    (theme) => theme.breakpoints.up('md'),
    { noSsr: true }
  );

  const { t } = useTranslation();

  const {
    widgetsData,
    maxRows: defaultWidgetRows,
    currentBreakpoint,
    setCurrentBreakpoint,
    querySizeByWidgetId,
  } = useWidgetsDataBag();

  const fileContext = useContext(FileContext);

  const collapsedWidgets = useSetting(
    settings.collapsedWidgets({ fileType: fileContext.type })
  );

  const hiddenWidgets = useSetting(
    settings.hiddenWidgets({ fileType: fileContext.type })
  );

  const persistedFileWidgetLayouts = useSetting(settings.fileWidgetLayouts());

  // Group widgets by "collapsed" state on above "md" breakpoint. On smaller
  // screens just return all widgets as one group.

  const widgetGroups = useMemo(() => {
    const widgets = definitions
      .map((definition) => {
        const widgetData = widgetsData[definition.id];
        const data = widgetData
          ? {
              ...widgetData,
              widgetTitle: t(widgetData.widgetTitle),
            }
          : null;

        return {
          definition,
          // Display the widget skeleton even if the request has not finished yet.

          // TODO Add widget skeleton UI.
          data: data ?? {
            data: [],
            totalCount: 0,
            widgetId: definition.id,
            widgetTitle: t(definition.title),
          },
          collapsed:
            isViewportDesktop &&
            (collapsedWidgets.value[definition.id] ?? false),
          hidden:
            isViewportDesktop && hiddenWidgets.value[definition.id] === true,
        };
      })

      .sort((a, b) => {
        return a.data.widgetTitle.localeCompare(b.data.widgetTitle);
      });

    if (!isViewportDesktop) {
      return [widgets];
    }

    const result = partition(
      widgets,
      (widget) => viewMode !== 'individual' && !widget.collapsed
    );

    return result;
  }, [
    definitions,
    isViewportDesktop,
    widgetsData,
    t,
    collapsedWidgets.value,
    hiddenWidgets.value,
    viewMode,
  ]);

  const [widgetGroupLayouts, setWidgetGroupLayouts] = useState<
    Array<LayoutResponsiveDefinition>
  >([]);

  useEffect(() => {
    const resultingLayouts = produce(
      persistedFileWidgetLayouts.value.layouts,
      (savedLayouts) => {
        while (savedLayouts.length < widgetGroups.length) {
          savedLayouts.push(produce(BLANK_LAYOUT, (draft) => draft));
        }

        for (let layoutIdx = 0; layoutIdx < savedLayouts.length; layoutIdx++) {
          const layout = produce(savedLayouts[layoutIdx], (draft) => {
            for (const layoutSize in draft) {
              draft[layoutSize] = draft[layoutSize].filter(
                (x) => !hiddenWidgets.value[x.i]
              );
            }

            return draft;
          });

          const widgetIds =
            widgetGroups[layoutIdx]
              ?.map((w) => w.definition.id)
              .filter((id) => !hiddenWidgets.value[id]) || [];

          for (
            let widgetIdIdx = 0;
            widgetIdIdx < widgetIds.length;
            widgetIdIdx++
          ) {
            const widgetId = widgetIds[widgetIdIdx];
            for (const layoutSize in layout) {
              const widget = layout[layoutSize]?.find((x) => x.i === widgetId);
              if (!widget) {
                layout[layoutSize].push({
                  i: widgetId,
                  h: defaultWidgetRows,
                  w: DEFAULT_WIDGET_WIDTHS[layoutSize],
                  x:
                    (widgetIdIdx * DEFAULT_WIDGET_WIDTHS[layoutSize]) %
                    WIDGET_COLS[layoutSize],
                  y: Infinity,
                  minW: 2,
                });
              }
            }
          }

          savedLayouts[layoutIdx] = layout;
        }
        return savedLayouts;
      }
    );

    setWidgetGroupLayouts(resultingLayouts);
  }, [
    defaultWidgetRows,
    hiddenWidgets.value,
    persistedFileWidgetLayouts.value.layouts,
    viewMode,
    widgetGroups,
  ]);

  const setLayout = useCallback(
    (updatedLayouts: Layout[], groupIndex: number) => {
      const newLayouts = [...widgetGroupLayouts];

      const currentBreakpointLayouts: Layout[] =
        newLayouts[groupIndex][currentBreakpoint];

      const updatedBreakpointLayouts = currentBreakpointLayouts.map(
        (layout) => {
          const updatedLayout = updatedLayouts.find(
            (_layout) => layout.i === _layout.i
          );

          if (updatedLayout) {
            const isCollapsed = collapsedWidgets.value[layout.i] ?? false;
            const h = isCollapsed ? layout.h : updatedLayout.h;

            return { ...updatedLayout, h };
          }

          return layout;
        }
      );

      newLayouts[groupIndex] = {
        ...newLayouts[groupIndex],
        [currentBreakpoint]: updatedBreakpointLayouts,
      };
      persistedFileWidgetLayouts.set({ layouts: newLayouts });
    },
    [
      currentBreakpoint,
      persistedFileWidgetLayouts,
      widgetGroupLayouts,
      collapsedWidgets.value,
    ]
  );

  const previewSize = useSetting(
    settings.filePreviewSize({
      fileType: resolvePreviewType(fileContext.type),
    })
  );

  const isPhone = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));
  const isDesktop = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  const renderWidgets = useCallback(
    (widgets, fixedHeight?: boolean) => {
      return widgets.map(({ definition, data, collapsed, hidden }) => {
        const renderWidget = () => {
          const maxRows =
            (!fixedHeight && querySizeByWidgetId[definition.id]) ||
            (defaultWidgetRows - 1) * 2;

          switch (definition.type) {
            case LayoutDefinitionTypes.EntityList:
              return (
                <div key={definition.id}>
                  <CardWidgetContainer<unknown> //ToDo - this can literally be any work4all entity
                    definition={definition}
                    title={data.widgetTitle}
                    items={data.data}
                    totalCount={data.totalCount}
                    maxRows={maxRows}
                    collapsible={isDesktop}
                    collapsed={collapsed}
                    onCollapsedChange={() => {
                      collapsedWidgets.set({
                        ...collapsedWidgets.value,
                        [definition.id]: !collapsed,
                      });
                    }}
                  />
                </div>
              );

            default:
              return null;
          }
        };

        if (hidden) return null;

        return renderWidget();
      });
    },
    [querySizeByWidgetId, defaultWidgetRows, isDesktop, collapsedWidgets]
  );

  return (
    <>
      {widgetGroups.map((widgets, groupIndex) => {
        let layout: LayoutResponsiveDefinition = cloneDeep(
          widgetGroupLayouts[groupIndex]
        );

        if (layout)
          for (const size of LAYOUT_SIZES) {
            layout = {
              ...layout,
              [size]: layout[size].map((lay) => {
                const widget = widgets.find((x) => x.definition.id === lay.i);
                const resolvedHeight = widget?.collapsed
                  ? 2
                  : !widget?.collapsed && lay.h === 1
                  ? 3
                  : lay.h;
                return {
                  ...lay,
                  y: lay.y,
                  h: resolvedHeight,
                  isResizable: !widget?.collapsed,
                } as Layout;
              }),
            };
          }

        return !isPhone && viewMode === 'individual' ? (
          <GridLayout
            key={groupIndex}
            sizeDeps={[previewSize.value]}
            layouts={layout}
            cols={WIDGET_COLS}
            isDraggable={viewMode === 'individual'}
            isResizable={viewMode === 'individual'}
            onDragStop={(layout) => {
              setLayout(layout, groupIndex);
            }}
            onResizeStop={(layout) => {
              setLayout(layout, groupIndex);
            }}
            onBreakpointChange={setCurrentBreakpoint}
            style={{ marginTop: '-0.5rem' }}
            useCSSTransforms={false}
          >
            {renderWidgets(widgets)}
          </GridLayout>
        ) : (
          <div key={groupIndex} className={styles.widgets}>
            {renderWidgets(widgets, true)}
          </div>
        );
      })}
    </>
  );
}
