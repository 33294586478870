import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LockProvider } from '@work4all/components/lib/hooks';
import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { CommentProvider } from '@work4all/utils';

import { resolveEntityVariant } from '../../file-entities-lists';

import { useAfterSave } from './hooks/use-after-save';
import { useMaskMetaData } from './hooks/use-mask-meta-data';
import { OpenMaskOverride, useOpenMaskOverlay } from './hooks/use-open-mask';
import { NEW_ENTITY_ID } from './mask-metadata';
import { IRouteParams, MaskTemplateEntity } from './types';
import { getMaskControllerComponent } from './utils/get-mask-controller-component';

export const MaskOverlay: React.FC<{ amplitudeEntryPoint: string }> = (
  props
) => {
  const metaData = useMaskMetaData();

  const [searchParams] = useSearchParams();

  const handleAfterSave = useAfterSave(props?.amplitudeEntryPoint);

  const resolved = resolveEntityVariant(metaData.subEntityType);
  const entity = resolved.entityType;
  const entityVariant = resolved.entityVariant;
  const id =
    metaData.subEntityId === NEW_ENTITY_ID ? null : metaData.subEntityId;
  const template =
    tryParseTemplateFromSearchParams(searchParams) ??
    tryParseTemplateFromRouteParams(metaData);
  const params = parseCustomParamsFromSearchParams(searchParams);
  const openTab = searchParams.get('openTab') ?? undefined;

  const MaskController = getMaskControllerComponent(entity);

  useEffect(() => {
    sendAmplitudeData(
      id === null || id === NEW_ENTITY_ID
        ? EventType.AddElement
        : EventType.EditElement,
      {
        name: metaData.subEntityType,
        entryPoint:
          props.amplitudeEntryPoint === 'fileDetailPage'
            ? metaData.entityType
            : props.amplitudeEntryPoint,
      }
    );
  }, [
    id,
    metaData.entityType,
    metaData.subEntityType,
    props.amplitudeEntryPoint,
  ]);

  const onOpenMask = useOpenMaskOverlay();
  return (
    <LockProvider autoLock={!!id} entity={entity} entityIds={id}>
      <OpenMaskOverride value={{ onOpenMask }}>
        <CommentProvider>
          <MaskController
            key={`${entity}_${id}`}
            entity={entity}
            entityVariant={entityVariant}
            id={id}
            template={template}
            openTab={openTab}
            params={params}
            onAfterSave={handleAfterSave}
            amplitudeEntryPoint={props.amplitudeEntryPoint}
            data-test-id="maskForm"
          />
        </CommentProvider>
      </OpenMaskOverride>
    </LockProvider>
  );
};

function tryParseTemplateFromSearchParams(
  searchParams: URLSearchParams
): MaskTemplateEntity | null {
  const raw = searchParams.get('template');

  if (!raw) {
    return null;
  }

  const result = /^(?<entity>.+?):(?<id>.+)$/.exec(raw);

  if (!result) {
    return null;
  }

  const { entity, id } = result.groups;

  return { entity: entity as Entities, id };
}

function tryParseTemplateFromRouteParams(
  params: IRouteParams
): MaskTemplateEntity | null {
  const entity = pathnameToEntity(params.entityType);
  const id = params.entityId;

  if (!entity || !id) {
    return null;
  }

  return { entity, id };
}

function pathnameToEntity(pathname: string): Entities | null {
  switch (pathname) {
    case 'customers':
      return Entities.customer;
    case 'suppliers':
      return Entities.supplier;
    case 'projects':
      return Entities.project;

    default:
      console.warn(`Could not parse entity from pathname: ${pathname}`);
      return null;
  }
}

function parseCustomParamsFromSearchParams(searchParams: URLSearchParams): {
  [key: string]: string;
} {
  const result: { [key: string]: string } = {};

  searchParams.forEach((value, key) => {
    // Template is a special parameter and will be passed separately from other
    // params after processing.
    if (!['template', 'openTab'].includes(key)) {
      result[key] = value;
    }
  });

  return result;
}
