import { useEventCallback } from '@mui/material/utils';
import { useRef } from 'react';
import { Fragment } from 'react/jsx-runtime';

import { Menu } from './Menu';
import { MenuDivider } from './MenuDivider';
import { MenuItem } from './MenuItem';
import { HoveredItem, MenuSectionsProps } from './types';

export const MenuSections = <TItem,>({
  sections,
  renderItem,
  setHoveredItem,
  hoveredItem,
  onItemClick,
}: MenuSectionsProps<TItem>) => {
  const changeHoveredItemTimeoutRef =
    useRef<ReturnType<typeof setTimeout>>(null);
  const changeHoveredItem = useEventCallback(
    (value: HoveredItem, withTimeout = false) => {
      const timeoutDuration = withTimeout ? 100 : 0;

      if (changeHoveredItemTimeoutRef.current) {
        clearTimeout(changeHoveredItemTimeoutRef.current);
        changeHoveredItemTimeoutRef.current = null;
      }

      changeHoveredItemTimeoutRef.current = setTimeout(() => {
        setHoveredItem(value);
      }, timeoutDuration);
    }
  );

  return sections.map((section, index) => {
    return (
      <Fragment key={index}>
        {section.items.map((item, index) => {
          if (item.sections) {
            return (
              <MenuItem
                key={index}
                onMouseEnter={(e) => {
                  changeHoveredItem(
                    { id: item.id, anchorEl: e.currentTarget },
                    true
                  );
                }}
                onClick={(e) => {
                  changeHoveredItem({ id: item.id, anchorEl: e.currentTarget });
                }}
                onMouseLeave={() => {
                  changeHoveredItem(null);
                }}
              >
                {renderItem(item)}

                {hoveredItem?.id === item.id && (
                  <Menu
                    open
                    anchorEl={hoveredItem.anchorEl}
                    sections={item.sections}
                    renderItem={renderItem}
                    onClose={() => {
                      changeHoveredItem(null);
                    }}
                    onItemClick={onItemClick}
                    searchable={false}
                  />
                )}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              key={index}
              onClick={() => {
                onItemClick(item);
              }}
            >
              {renderItem(item)}
            </MenuItem>
          );
        })}

        {index + 1 !== sections.length && <MenuDivider />}
      </Fragment>
    );
  });
};
