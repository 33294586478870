import { Box } from '@mui/material';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from '@work4all/components/lib/dataDisplay/card';
import { TimeInputPicker } from '@work4all/components/lib/input/time-input-picker';

import { ComesGoesRounding } from '@work4all/models/lib/Enums/ComesGoesRounding.enum';
import { TypeOfWorkTimeTracking } from '@work4all/models/lib/Enums/TypeOfWorkTimeTracking.enum';

import { EnumPickerField } from '../../../../../../../components/entity-picker/EnumPickerField';
import { PauseRulePickerField } from '../../../../../../../components/entity-picker/PauseRulePickerField';
import { ControllerPlus } from '../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../form-plus/use-form-context-plus';
import {
  CheckboxRadioItem,
  LabeledCurrencyInput,
  LabeledInput,
} from '../../../../../locked-inputs';
import { Collapse, ControlWrapper, Panel } from '../../../../components';
import { UserFormValue } from '../../types';

import { getTimeValue } from './getTimeValue';

const generateDayLabels = () => {
  return Array.from({ length: 7 }, (_, i) => i + 1)
    .map((x) =>
      DateTime.fromFormat(`2024.01.0${x}`, 'yyyy.MM.dd').toFormat('cccc')
    )
    .map((x) => x.slice(0, 2) + '.');
};

export const WorkTimeTabPanel = () => {
  const { i18n, t } = useTranslation();
  const { register, control } = useFormContextPlus<UserFormValue>();

  const dayLabels = useMemo(() => {
    return generateDayLabels();
  }, [i18n.language]);

  return (
    <Panel style={{ height: '100%' }}>
      <Collapse defaultOpen title={t('USER.CALCULATION_OF_WORK_HOURS')}>
        <Card>
          <ControlWrapper columns={3}>
            <LabeledCurrencyInput
              {...register('timestampTargetMinutesPerDay', {
                valueAsNumber: true,
              })}
              unit="Min."
              type="number"
              label={t('INPUTS.DAILY_TARGET')}
            />
            <ControlWrapper gridColumn="span 2" columns={7}>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                gridColumn="span 5"
              >
                <LabeledInput
                  {...register('dailyTargetWorkHoursMonday', {
                    valueAsNumber: true,
                  })}
                  multientry="left"
                  type="number"
                  label={dayLabels[0]}
                />
                <LabeledInput
                  {...register('dailyTargetWorkHoursTuesday', {
                    valueAsNumber: true,
                  })}
                  multientry="both"
                  type="number"
                  label={dayLabels[1]}
                />
                <LabeledInput
                  {...register('dailyTargetWorkHoursWednesday', {
                    valueAsNumber: true,
                  })}
                  multientry="both"
                  type="number"
                  label={dayLabels[2]}
                />
                <LabeledInput
                  {...register('dailyTargetWorkHoursThursday', {
                    valueAsNumber: true,
                  })}
                  multientry="both"
                  type="number"
                  label={dayLabels[3]}
                />
                <LabeledInput
                  {...register('dailyTargetWorkHoursFriday', {
                    valueAsNumber: true,
                  })}
                  multientry="right"
                  type="number"
                  label={dayLabels[4]}
                />
              </Box>
              <ControlWrapper gap={'0'} gridColumn="span 2" columns={2}>
                <LabeledInput
                  {...register('dailyTargetWorkHoursSaturday', {
                    valueAsNumber: true,
                  })}
                  multientry="left"
                  type="number"
                  label={dayLabels[5]}
                />
                <LabeledInput
                  {...register('dailyTargetWorkHoursSunday', {
                    valueAsNumber: true,
                  })}
                  multientry="right"
                  type="number"
                  label={dayLabels[6]}
                />
              </ControlWrapper>
            </ControlWrapper>
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <LabeledCurrencyInput
              {...register('maxWorkingHours', { valueAsNumber: true })}
              unit="Min."
              type="number"
              label={t('INPUTS.MAX_WORKING_HOURS')}
            />
            <ControlWrapper gridColumn="span 2" columns={7}>
              <Box
                display="grid"
                gridTemplateColumns="1fr 1fr 1fr 1fr 1fr"
                gridColumn="span 5"
              >
                <LabeledInput
                  {...register('maxWorkHoursMonday', { valueAsNumber: true })}
                  multientry="left"
                  type="number"
                  label={dayLabels[0]}
                />
                <LabeledInput
                  {...register('maxWorkHoursTuesday', { valueAsNumber: true })}
                  multientry="both"
                  type="number"
                  label={dayLabels[1]}
                />
                <LabeledInput
                  {...register('maxWorkHoursWednesday', {
                    valueAsNumber: true,
                  })}
                  multientry="both"
                  type="number"
                  label={dayLabels[2]}
                />
                <LabeledInput
                  {...register('maxWorkHoursThursday', { valueAsNumber: true })}
                  multientry="both"
                  type="number"
                  label={dayLabels[3]}
                />
                <LabeledInput
                  {...register('maxWorkHoursFriday', { valueAsNumber: true })}
                  multientry="right"
                  type="number"
                  label={dayLabels[4]}
                />
              </Box>
              <ControlWrapper gap={'0'} gridColumn="span 2" columns={2}>
                <LabeledInput
                  {...register('maxWorkHoursSaturday', { valueAsNumber: true })}
                  multientry="left"
                  type="number"
                  label={dayLabels[5]}
                />
                <LabeledInput
                  {...register('maxWorkHoursSunday', { valueAsNumber: true })}
                  multientry="right"
                  type="number"
                  label={dayLabels[6]}
                />
              </ControlWrapper>
            </ControlWrapper>
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <LabeledCurrencyInput
              {...register('vacationValue', { valueAsNumber: true })}
              unit="h"
              type="number"
              label={t('INPUTS.VARIANT_VACATION_TIME_VALUE')}
            />
            <ControllerPlus
              control={control}
              name="timestampRecordingVariant"
              render={({ field }) => {
                return (
                  <EnumPickerField
                    values={Object.values(TypeOfWorkTimeTracking)}
                    label={t('INPUTS.CAPTURE_DEVICE')}
                    {...field}
                  />
                );
              }}
            />
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <LabeledCurrencyInput
              {...register('timing', { valueAsNumber: true })}
              unit="Min."
              type="number"
              label={t('INPUTS.TIMING')}
            />
            <CheckboxRadioItem
              {...register('markHolidaysAsWorkingHours')}
              label={t('INPUTS.TREAT_PUBLIC_HOLIDAYS_AS_WORKING_HOURS')}
            />
          </ControlWrapper>
          <ControlWrapper columns={3}>
            <ControlWrapper columns={2} gap="0" paddingBottom={false}>
              <ControllerPlus
                control={control}
                name="timestampRoundingMorning"
                render={({ field }) => {
                  return (
                    <EnumPickerField
                      values={Object.values(ComesGoesRounding)}
                      label={t('INPUTS.START_ROUND')}
                      {...field}
                    />
                  );
                }}
              />
              <ControllerPlus
                control={control}
                name="timestampRoundingEvening"
                render={({ field }) => {
                  return (
                    <EnumPickerField
                      values={Object.values(ComesGoesRounding)}
                      label={t('INPUTS.END_ROUND')}
                      {...field}
                    />
                  );
                }}
              />
            </ControlWrapper>

            <ControlWrapper columns={2} gap="0" paddingBottom={false}>
              <ControllerPlus
                control={control}
                name="timestampRoundingFromTime"
                render={({ field }) => {
                  return (
                    <EnumPickerField
                      values={Object.values(ComesGoesRounding)}
                      label={t('INPUTS.ROUNDING')}
                      {...field}
                    />
                  );
                }}
              />
              <ControllerPlus
                name="roundingForHour"
                control={control}
                render={({ field }) => {
                  const value = getTimeValue(field.value);
                  return (
                    <TimeInputPicker
                      label={t('INPUTS.AT')}
                      value={value}
                      onChange={(e) => {
                        const value = e.currentTarget?.value;

                        if (value) {
                          field.onChange(value);
                        }
                      }}
                    />
                  );
                }}
              />
            </ControlWrapper>

            <ControllerPlus
              name="earliestCommingTime"
              control={control}
              render={({ field }) => {
                const value = getTimeValue(field.value);
                return (
                  <TimeInputPicker
                    label={t('INPUTS.EARLIEST_START')}
                    value={value}
                    onChange={(e) => {
                      const value = e.currentTarget?.value;

                      if (value) {
                        field.onChange(value);
                      }
                    }}
                  />
                );
              }}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.CALCULATION_OF_OVERTIME')}>
        <Card>
          <ControlWrapper columns={3}>
            <LabeledCurrencyInput
              {...register('timestampIncludedMinutesPerDay', {
                valueAsNumber: true,
              })}
              unit="Min."
              type="number"
              label={t('INPUTS.INCLUDED_PER_DAY')}
            />
            <LabeledCurrencyInput
              {...register('comesGoesInclWeek', { valueAsNumber: true })}
              unit="Min."
              type="number"
              label={t('INPUTS.INCLUDED_PER_WEEK')}
            />
            <LabeledCurrencyInput
              {...register('timestampIncludedMinutesPerMonth', {
                valueAsNumber: true,
              })}
              unit="Min."
              type="number"
              label={t('INPUTS.INCLUDED_PER_MONTH')}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
      <Collapse defaultOpen title={t('USER.PAUSE')}>
        <Card>
          <ControlWrapper columns={3}>
            <ControllerPlus
              control={control}
              name="workTimeTrackingBreakRule"
              render={({ field }) => {
                return <PauseRulePickerField multiple={false} {...field} />;
              }}
            />

            <LabeledCurrencyInput
              {...register('comesGoesMinimalPause', { valueAsNumber: true })}
              unit="Min."
              type="number"
              label={t('INPUTS.MINIMAL_BREAK')}
            />
          </ControlWrapper>
        </Card>
      </Collapse>
    </Panel>
  );
};
