import { useEventCallback } from '@mui/material/utils';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useDataMutation,
  useDataProvider,
  useHttpClient,
  useUser,
} from '@work4all/data';
import { uploadTempFile } from '@work4all/data/lib/hooks/data-provider/useTempFileManager';
import { EMPTY_UID } from '@work4all/data/lib/utils/empty-uid';

import { DataRequest } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { resizeImage } from '../components/user-menu/views/Profile/components/Avatar/utils/resizeImage';

type ImageType = 'image/jpeg' | 'image/png' | 'image/gif';

export const useAvatar = (inUserId?: number) => {
  const httpClient = useHttpClient();
  const user = useUser();
  const userId = inUserId ?? user.benutzerCode;

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      data: {
        id: null,
        avatarUrl: null,
      },
      filter: [{ id: { $eq: userId } }],
    };
  }, [user]);

  const response = useDataProvider(requestData);

  const avatarUrl = response.data?.[0]?.avatarUrl;

  const [mutate] = useDataMutation({
    entity: Entities.user,
    mutationType: EMode.upsert,
    responseData: { id: null, avatarUrl: null },
  });

  const handleRemoveAvatar = useCallback(() => {
    mutate(
      { id: userId },
      {
        relations: {
          userImageFromTempFile: EMPTY_UID,
        },
      }
    );
  }, [mutate, userId]);

  const handleUploadAvatar = useUploadAvatar(async (userImageFromTempFile) => {
    await mutate(
      { id: userId },
      {
        relations: {
          userImageFromTempFile,
        },
      }
    );
  });

  return {
    uploadAvatar: handleUploadAvatar,
    removeAvatar: handleRemoveAvatar,
    avatarUrl,
  };
};

export const useUploadAvatar = (
  onUpload: (objectKey: string, url?: string) => Promise<void>
) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const httpClient = useHttpClient();
  const user = useUser();
  return useEventCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];

      if (file) {
        const validImageTypes: ImageType[] = [
          'image/jpeg',
          'image/png',
          'image/gif',
        ];

        if (!validImageTypes.includes(file.type as ImageType)) {
          enqueueSnackbar(
            t('ERROR.WRONG_FILE_EXTENSION_BY_TYPE', {
              files: '.jpeg, .png or .gif',
            }),
            {
              variant: 'error',
              autoHideDuration: 3000,
            }
          );

          return;
        }

        const resizedImage = await resizeImage(file);

        const uploadResponse = await uploadTempFile(
          resizedImage,
          httpClient,
          user.baseUrl
        );

        if (uploadResponse && uploadResponse.data) {
          const objectKey = uploadResponse.data.generatedObject;
          const downloadUrlForPreview =
            uploadResponse.data.downloadUrlForPreview;
          await onUpload(objectKey, downloadUrlForPreview);
        }
      }
    }
  );
};
