import { useDataProvider, useUser } from '@work4all/data';

import { MailboxConfiguration } from '@work4all/models/lib/Classes/MailboxConfiguration.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { MailboxFeatures } from '@work4all/models/lib/Enums/MailboxFeatures.enum';

const GET_MAILBOX_CONFIGURATIONS_REQUEST_DATA: MailboxConfiguration = {
  id: null,
  smtpAddress: null,
  features: null,
};

const GET_MAILBOX_CONFIGURATIONS_REQUEST: DataRequest = {
  entity: Entities.mailboxConfiguration,
  data: GET_MAILBOX_CONFIGURATIONS_REQUEST_DATA,
  completeDataResponse: true,
};

export const useCanCreateTeamsMeeting = (): boolean | undefined => {
  const user = useUser();
  const getMailboxConfigurationsQuery = useDataProvider<MailboxConfiguration>(
    GET_MAILBOX_CONFIGURATIONS_REQUEST
  );

  if (getMailboxConfigurationsQuery.loading) {
    return undefined;
  }

  const userEmailConfigurations = user.email
    ? getMailboxConfigurationsQuery.data.find((configurations) => {
        return configurations.smtpAddress === user.email;
      })
    : null;

  if (userEmailConfigurations) {
    return userEmailConfigurations.features.includes(
      MailboxFeatures.ONLINE_MEETING_TEAMS_CREATE
    );
  }

  return getMailboxConfigurationsQuery.data.some((configurations) =>
    configurations.features.includes(
      MailboxFeatures.ONLINE_MEETING_TEAMS_CREATE
    )
  );
};
