import { DateTime } from 'luxon';
import { memo, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { IUser, useDataMutation, useUser } from '@work4all/data';
import { useCustomFieldsConfig } from '@work4all/data/lib/custom-fields';
import { useSearchHistory } from '@work4all/data/lib/hooks/use-search-history';

import { InputSalesOpportunityAttachementRelation } from '@work4all/models/lib/Classes/InputSalesOpportunityAttachementRelation.entity';
import { InputVerkaufschanceRelation } from '@work4all/models/lib/Classes/InputVerkaufschanceRelation.entity';
import { SalesOpportunities } from '@work4all/models/lib/Classes/SalesOpportunities.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { SalesOpportunityStatus } from '@work4all/models/lib/Enums/SalesOpportunityStatus.enum';

import { FieldToErrorMap } from '@work4all/utils';

import useAttachementsRelation from '../../../../../hooks/useAttachementsRelation';
import { MaskTab, MaskTabPanel, MaskTabs } from '../../../mask-tabs';
import { INDIVIDUAL_TAB_ID } from '../../components/custom-fields/contants';
import { IndividualTabPanel } from '../../components/custom-fields/IndividualTabPanel';
import { prepareInputWithCustomFields } from '../../components/custom-fields/prepare-input-with-custom-fields';
import { MaskContent } from '../../components/MaskContent/MaskContent';
import { HistoryTabPanel } from '../../components/tab-panels/history/HistoryTabPanel';
import { useMaskConfig } from '../../hooks/mask-context';
import { OverlayController } from '../../overlay-controller/OverlayController';
import { useMaskOverlay } from '../../overlay-controller/use-mask-overlay';
import { MaskControllerProps } from '../../types';
import { pickUpdateFields } from '../../utils/pick-update-fields';
import {
  AssignableEntityResult,
  useAssignableTemplateEntity,
} from '../../utils/use-assignable-template-entity';
import {
  CurrencyExchangeInfoContext,
  CurrencyExchangeInfoContextValue,
} from '../inbound-invoice/currency-exchange-info-context';
import { CurrencyExchangeInfoContextProvider } from '../inbound-invoice/CurrencyExchangeInfoContextProvider';

import { AttachmentsTabPanel } from './components/tab-panels/attachments/AttachmentsTabPanel';
import { GeneralTabPanel } from './components/tab-panels/general/GeneralTabPanel';
import { useSalesOpportunitiesFormUpdate } from './hooks/use-sales-opportunities-form-update';
import { useSalesOpportunitiesRequestData } from './hooks/use-sales-opportunities-request-data';
import { SalesOpportunitiesMaskFormValue } from './types';

export enum TabKeys {
  general = 'general',
  attachments = 'attachments',
  history = 'history',
  individual = 'individual',
}
export const SalesOpportunitiesOverlayController = (
  props: MaskControllerProps
) => {
  return (
    <CurrencyExchangeInfoContextProvider>
      <SalesOpportunitiesOverlayControllerInternal {...props} />;
    </CurrencyExchangeInfoContextProvider>
  );
};

const SalesOpportunitiesOverlayControllerInternal = (
  props: MaskControllerProps
) => {
  const user = useUser();

  const { t } = useTranslation();

  const exchangeInfo = useContext(CurrencyExchangeInfoContext);

  const mask = useMaskConfig(props);

  const template = useAssignableTemplateEntity(mask);

  const customFields = useCustomFieldsConfig({
    entity: Entities.salesOpportunities,
  });

  const request = useSalesOpportunitiesRequestData(mask.id);

  const newEntityData = useMemo(() => {
    const data = createSalesOppotunityDefaultData({
      template,
      user,
      exchangeInfo,
      isCreateMode: mask.isCreateMode,
    });

    return data;
  }, [template, user, exchangeInfo, mask.isCreateMode]);

  const customRules = useCallback(
    (data: SalesOpportunitiesMaskFormValue) => {
      const errors: FieldToErrorMap = {};

      if (!data?.name)
        errors['name'] = {
          message: t('ERROR.FIELD_REQUIRED'),
          type: 'customValidation',
        };

      if (data?.decisionDate) {
        const date = mask.isEditMode
          ? DateTime.fromISO(data.dateCreated)
          : DateTime.now();
        const currentDate = DateTime.fromISO(data.decisionDate);
        if (date > currentDate)
          errors['decisionDate'] = {
            message: t('ERROR.INVALID_VALUE'),
            type: 'customValidation',
          };
      }
      if (data?.dateNextReview) {
        const date = mask.isEditMode
          ? DateTime.fromISO(data.dateCreated)
          : DateTime.now();
        const currentDate = DateTime.fromISO(data.dateNextReview);
        if (date > currentDate)
          errors['dateNextReview'] = {
            message: t('ERROR.INVALID_VALUE'),
            type: 'customValidation',
          };
      }

      return Object.entries(errors).length ? errors : true;
    },
    [t, mask.isEditMode]
  );

  const getTempFileInitialData = useCallback(
    (data: SalesOpportunitiesMaskFormValue) => {
      return data?.attachementList?.map((attachement) => {
        return {
          ...attachement,
          fileName: attachement.fileInfos?.fileEntityFilename,
          date: attachement.updateTime,
        };
      });
    },
    []
  );

  const overlay = useMaskOverlay<SalesOpportunitiesMaskFormValue>({
    ...props,
    request,
    newEntityData,
    mask,
    customFields,
    customRules,
    getSubTitle: (x) => x.name,
    getTempFileInitialData,
  });

  const attachementsRelation =
    useAttachementsRelation<InputSalesOpportunityAttachementRelation>(
      overlay.tempFileManager,
      Entities.salesOpportunityAttachement,
      'id'
    );

  const { formState, getValues } = overlay.form;

  const { saveSearchItemFromEnityData } = useSearchHistory();

  const { ...mutationData } = request.data as SalesOpportunities;
  const [mutate] = useDataMutation<
    SalesOpportunities,
    EMode.upsert,
    InputVerkaufschanceRelation
  >({
    entity: mask.entity,
    mutationType: EMode.upsert,
    responseData: mutationData,
    onCompleted: (data) => {
      if (mask.isCreateMode) {
        saveSearchItemFromEnityData(data);
      }

      props.onAfterSave?.(data);
    },
  });

  useSalesOpportunitiesFormUpdate(overlay.form);

  const handleSubmit = useCallback(
    async (formValues: SalesOpportunitiesMaskFormValue) => {
      const relations: InputVerkaufschanceRelation<EMode.entity> = {
        attachements: attachementsRelation?.attachements,
      };

      const updateRaw = mask.isCreateMode
        ? formValues
        : pickUpdateFields(formValues, formState.dirtyFields);

      const updateMapped = prepareInputWithCustomFields(updateRaw);

      await mutate(updateMapped, relations ? { relations } : undefined);
    },
    [attachementsRelation, mask.isCreateMode, formState.dirtyFields, mutate]
  );

  const shouldRenderIndividualTab = customFields && customFields.length > 0;

  return (
    <OverlayController<SalesOpportunitiesMaskFormValue>
      {...overlay}
      onSubmit={handleSubmit}
      tabs={
        <Tabs
          isCreateMode={mask.isCreateMode}
          renderIndividualTab={shouldRenderIndividualTab}
        />
      }
    >
      <Content renderIndividualTab={shouldRenderIndividualTab} />
    </OverlayController>
  );
};

const Tabs = memo(function AppointmentTabs({
  isCreateMode,
  renderIndividualTab,
}: {
  isCreateMode: boolean;
  renderIndividualTab: boolean;
}) {
  const { t } = useTranslation();

  return (
    <MaskTabs>
      <MaskTab value={TabKeys.general} label={t('MASK.GENERAL')}></MaskTab>
      <MaskTab
        value={TabKeys.attachments}
        label={t('MASK.ATTACHMENTS')}
      ></MaskTab>
      <MaskTab
        value={TabKeys.history}
        label={t('MASK.HISTORY')}
        disabled={isCreateMode}
      ></MaskTab>
      {renderIndividualTab && (
        <MaskTab value={INDIVIDUAL_TAB_ID} label={t('MASK.INDIVIDUAL')} />
      )}
    </MaskTabs>
  );
});

const Content = memo(function AppointmentTabPanels({
  renderIndividualTab,
}: {
  renderIndividualTab: boolean;
}) {
  return (
    <MaskContent>
      <MaskTabPanel value={TabKeys.general}>
        <GeneralTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value="attachments">
        <AttachmentsTabPanel />
      </MaskTabPanel>

      <MaskTabPanel value={TabKeys.history}>
        <HistoryTabPanel />
      </MaskTabPanel>

      {renderIndividualTab && (
        <MaskTabPanel value={INDIVIDUAL_TAB_ID}>
          <IndividualTabPanel />
        </MaskTabPanel>
      )}
    </MaskContent>
  );
});

type ICreateSalesOppotunityDefaultParams = {
  template: AssignableEntityResult;
  exchangeInfo: CurrencyExchangeInfoContextValue;
  user: Pick<IUser, 'displayName' | 'benutzerCode'>;
  isCreateMode?: boolean;
};

function createSalesOppotunityDefaultData(
  params: ICreateSalesOppotunityDefaultParams
): SalesOpportunities {
  const { template, user, exchangeInfo, isCreateMode } = params;

  const common: SalesOpportunities = {
    status: SalesOpportunityStatus.ACTIVE,
    creatorUserId: user.benutzerCode,
    probabilityPercent: 0,
    dateNextReview: isCreateMode
      ? DateTime.now().plus({ days: 30 }).toISO()
      : null,
    decisionDate: isCreateMode
      ? DateTime.now().plus({ days: 30 }).toISO()
      : null,
    userId: user.benutzerCode,
    user: {
      id: user.benutzerCode,
      displayName: user.displayName,
    },
    user2Id: user.benutzerCode,
    user2: {
      id: user.benutzerCode,
      displayName: user.displayName,
    },
    currency: exchangeInfo?.defaultCurrency,
    currencyId: exchangeInfo?.defaultCurrency?.id,
    value: 0,
    expectedCosts: 0,
    orderValue: 0,
  };

  if (template.enabled) {
    if (!template.data) {
      return null;
    }

    const contact =
      template.data.contact || template.data.businessPartner?.data?.mainContact;
    const data: SalesOpportunities = {
      ...common,
      businessPartner: {
        id: template.data.businessPartner?.data?.id ?? 0,
        data: template.data.businessPartner?.data
          ? { ...template.data.businessPartner?.data, mainContact: null }
          : null,
      },
      businessPartnerId: template.data.businessPartner?.data?.id ?? 0,
      contact: contact,
      contactId: contact?.id ?? 0,
      project: template.data.project,
      projectId: template.data.project?.id ?? 0,
      projectProcess: template.data.projectProcess,
      projectProcessId: template.data.projectProcess?.id ?? 0,
    };

    return data;
  }

  return {
    ...common,
    businessPartner: null,
    businessPartnerId: 0,
    contact: null,
    contactId: 0,
    project: null,
    projectId: 0,
  };
}
