import styles from './PickerTargetButton.module.scss';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import { forwardRef } from 'react';

export type PickerTargetButtonProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    hover?: boolean;
    withoutArrow?: boolean;
  };

export const PickerTargetButton = forwardRef<
  HTMLButtonElement,
  PickerTargetButtonProps
>((props, ref) => {
  return (
    <button
      ref={ref}
      {...props}
      type="button"
      className={clsx(props.className, styles.pickerButton, {
        [styles.hover]: props.hover,
      })}
    >
      <span className={styles.pickerButtonContent}>{props.children}</span>
      {!props.disabled && !props.withoutArrow && (
        <ArrowDropDownIcon className={styles.pickerButtonDropdownIcon} />
      )}
    </button>
  );
});
