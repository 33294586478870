import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { PaymentKindIntern } from '../Enums/PaymentKindIntern.enum';
import { ReceiptKindInternal } from '../Enums/ReceiptKindInternal.enum';
import { ReceiptKindTravelCostsInternal } from '../Enums/ReceiptKindTravelCostsInternal.enum';

import { CostCenter } from './CostCenter.entity';
import { Currency } from './Currency.entity';
import { File } from './File.entity';
import { LedgerAccount } from './LedgerAccount.entity';
import { Project } from './Project.entity';
import { TaxKey } from './TaxKey.entity';
import { TravelCostInvoiceKind } from './TravelCostInvoiceKind.entity';
import { TravelCostInvoiceKindTransportCost } from './TravelCostInvoiceKindTransportCost.entity';
import { TravelExpenseReportPaymentMethod } from './TravelExpenseReportPaymentMethod.entity';
import { TravelExpenses } from './TravelExpenses.entity';

export class TravelReceipts<T extends EMode = EMode.entity> {
  /** Alias: abrechnungBZObjMemberCode */
  billingBZObjMemberId?: number;
  /** Alias: abrechnungKundeErfolgt */
  billingCustomerDone?: number;
  /** Alias: abrechnungKundeGeplant */
  billingCustomerPlanned?: number;
  /** Alias: anzahl */
  amount?: number;
  /** Alias: ausgabe */
  expenditure?: number;
  /** Alias: belegart */
  receiptKind?: TravelCostInvoiceKind<T>;
  /** Alias: belegnummer */
  receiptNumber?: number;
  /** Alias: benutzercode */
  userId?: number;
  /** Alias: betragBrutto1 */
  amountGross1?: number;
  /** Alias: betragBrutto2 */
  amountGross2?: number;
  /** Alias: bezeichnung */
  description?: string;
  /** Alias: code */
  id?: number;
  /** Alias: datum */
  date?: string;
  /** Alias: einnahme */
  intake?: number;
  /** Alias: enumBelegart */
  enumReceiptKind?: ReceiptKindInternal;
  /** Alias: enumBelegartFahrtkosten */
  enumReceiptKindTravelCosts?: ReceiptKindTravelCostsInternal;
  /** Alias: enumZahlungsart */
  enumPaymentKind?: PaymentKindIntern;
  /** Alias: ePreis */
  ePrice?: number;
  /** Alias: kategorie1 */
  category1?: string;
  /** Alias: kategorie2 */
  category2?: string;
  /** Alias: kostenst */
  costCenterId?: number;
  /** Alias: kurs */
  course?: number;
  /** Alias: lohnkonto1 */
  payrollAccount1?: number;
  /** Alias: lohnkonto2 */
  payrollAccount2?: number;
  /** Alias: mwst */
  vat?: number;
  /** Alias: mwst1 */
  vat1?: number;
  /** Alias: mwst2 */
  vat2?: number;
  /** Alias: notiz */
  note?: string;
  /** Alias: projektCode */
  projectId?: number;
  /** Alias: reisekostenabrechnungcode */
  travelExpensesId?: number;
  /** Alias: sachkonto */
  ledgerAccountId?: number;
  /** Alias: sachkonto1 */
  ledgerAccount1Id?: number;
  /** Alias: sachkonto2 */
  ledgerAccount2Id?: number;
  /** Alias: steuerschluessel1 */
  taxKey1Value?: number;
  /** Alias: steuerschluessel2 */
  taxKey2Value?: number;
  /** Alias: waehrungscode */
  currencyId?: number;
  /** Alias: zahlungsart */
  paymentKind?: number;
  /** Alias: waehrung */
  currency?: Currency<T>;
  /** Alias: costCenter */
  costCenter?: CostCenter<T>;
  /** Alias: file */
  file?: File<T>[];
  /** Alias: reisekostenabrechnung */
  travelExpenses?: TravelExpenses<T>;
  /** Alias: ledgerAccount1 */
  ledgerAccount1?: LedgerAccount<T>;
  /** Alias: ledgerAccount2 */
  ledgerAccount2?: LedgerAccount<T>;
  /** Alias: ledgerAccount3 */
  ledgerAccount3?: LedgerAccount<T>;
  /** Alias: project */
  project?: Project<T>;
  /** Alias: betragBrutto3 */
  amountGross3?: number;
  /** Alias: mwst3 */
  vat3?: number;
  /** Alias: belegartCode */
  receiptKindId?: number;
  /** Alias: belegartFahrtkostenCode */
  receiptKindTravelCostId?: number;
  /** Alias: belegartFahrkosten */
  receiptKindTravelCost?: TravelCostInvoiceKindTransportCost<T>;
  /** Alias: paymentMethod */
  paymentMethod?: TravelExpenseReportPaymentMethod<T>;
  /** Alias: sachkonto3 */
  ledgerAccount3Id?: number;
  /** Alias: taxKey1 */
  taxKey1?: TaxKey<T>;
  /** Alias: taxKey2 */
  taxKey2?: TaxKey<T>;
  /** Alias: steuerschluessel3 */
  taxKey3Value?: number;
  /** Alias: taxKey3 */
  taxKey3?: TaxKey<T>;

  __typename?: string;
}

const fields: FieldDefinitions<TravelReceipts> = {
  billingBZObjMemberId: {
    alias: 'abrechnungBZObjMemberCode',
  },
  billingCustomerDone: {
    alias: 'abrechnungKundeErfolgt',
  },
  billingCustomerPlanned: {
    alias: 'abrechnungKundeGeplant',
  },
  amount: {
    alias: 'anzahl',
  },
  expenditure: {
    alias: 'ausgabe',
  },
  receiptKind: {
    alias: 'belegart',
    entity: Entities.travelCostInvoiceKind,
  },
  receiptNumber: {
    alias: 'belegnummer',
  },
  userId: {
    alias: 'benutzercode',
  },
  amountGross1: {
    alias: 'betragBrutto1',
  },
  amountGross2: {
    alias: 'betragBrutto2',
  },
  description: {
    alias: 'bezeichnung',
  },
  id: {
    alias: 'code',
  },
  date: {
    alias: 'datum',
  },
  intake: {
    alias: 'einnahme',
  },
  enumReceiptKind: {
    alias: 'enumBelegart',
  },
  enumReceiptKindTravelCosts: {
    alias: 'enumBelegartFahrtkosten',
  },
  enumPaymentKind: {
    alias: 'enumZahlungsart',
  },
  ePrice: {
    alias: 'ePreis',
  },
  category1: {
    alias: 'kategorie1',
  },
  category2: {
    alias: 'kategorie2',
  },
  costCenterId: {
    alias: 'kostenst',
  },
  course: {
    alias: 'kurs',
  },
  payrollAccount1: {
    alias: 'lohnkonto1',
  },
  payrollAccount2: {
    alias: 'lohnkonto2',
  },
  vat: {
    alias: 'mwst',
  },
  vat1: {
    alias: 'mwst1',
  },
  vat2: {
    alias: 'mwst2',
  },
  note: {
    alias: 'notiz',
  },
  projectId: {
    alias: 'projektCode',
  },
  travelExpensesId: {
    alias: 'reisekostenabrechnungcode',
  },
  ledgerAccountId: {
    alias: 'sachkonto',
  },
  ledgerAccount1Id: {
    alias: 'sachkonto1',
  },
  ledgerAccount2Id: {
    alias: 'sachkonto2',
  },
  taxKey1Value: {
    alias: 'steuerschluessel1',
  },
  taxKey2Value: {
    alias: 'steuerschluessel2',
  },
  currencyId: {
    alias: 'waehrungscode',
  },
  paymentKind: {
    alias: 'zahlungsart',
  },
  currency: {
    alias: 'waehrung',
    entity: Entities.currency,
  },
  costCenter: {
    alias: 'costCenter',
    entity: Entities.costCenter,
  },
  file: {
    alias: 'file',
    entity: Entities.file,
  },
  travelExpenses: {
    alias: 'reisekostenabrechnung',
    entity: Entities.travelExpenses,
  },
  ledgerAccount1: {
    alias: 'ledgerAccount1',
    entity: Entities.ledgerAccount,
  },
  ledgerAccount2: {
    alias: 'ledgerAccount2',
    entity: Entities.ledgerAccount,
  },
  ledgerAccount3: {
    alias: 'ledgerAccount3',
    entity: Entities.ledgerAccount,
  },
  project: {
    alias: 'project',
    entity: Entities.project,
  },
  amountGross3: {
    alias: 'betragBrutto3',
  },
  vat3: {
    alias: 'mwst3',
  },
  receiptKindId: {
    alias: 'belegartCode',
  },
  receiptKindTravelCostId: {
    alias: 'belegartFahrtkostenCode',
  },
  receiptKindTravelCost: {
    alias: 'belegartFahrkosten',
    entity: Entities.travelCostInvoiceKindTransportCost,
  },
  paymentMethod: {
    alias: 'paymentMethod',
    entity: Entities.travelExpenseReportPaymentMethod,
  },
  ledgerAccount3Id: {
    alias: 'sachkonto3',
  },
  taxKey1: {
    alias: 'taxKey1',
    entity: Entities.taxKey,
  },
  taxKey2: {
    alias: 'taxKey2',
    entity: Entities.taxKey,
  },
  taxKey3Value: {
    alias: 'steuerschluessel3',
  },
  taxKey3: {
    alias: 'taxKey3',
    entity: Entities.taxKey,
  },
  __typename: {
    alias: '__typename',
  },
};

export const travelReceiptsEntityDefinition: EntitiyDefinition<TravelReceipts> =
  {
    local: {},
    remote: {
      queryName: 'getTravelReceipts',
      fragmentName: 'ReisekostenabrechnungBeleg',
      withPaginationWrapper: true,
      mutations: {
        upsert: {
          mutationName: 'upsertSimpleReceipt',
          args: [
            { name: 'input', type: 'InputReisekostenabrechnungBeleg!' },
            {
              name: 'relations',
              type: 'InputReisekostenabrechnungBelegRelation',
            },
            { name: 'drySave', type: 'Boolean' },
          ],
        },
      },
      params: [
        { name: 'querySize', type: 'Int' },
        { name: 'queryPage', type: 'Int' },
        { name: 'querySortOrder', type: 'SortOrderStyle' },
        { name: 'querySortBy', type: 'String' },
        { name: 'querySortByEx', type: '[SortByPair]' },
        { name: 'filter', type: 'String' },
      ],
    },
    fieldDefinitions: fields,
  };
