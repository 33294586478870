import { useState } from 'react';

import { useSearchedItemsSection } from '../hooks';

import { MenuActions } from './MenuActions';
import { MenuDivider } from './MenuDivider';
import { MenuPopover } from './MenuPopover';
import { MenuSearch } from './MenuSearch';
import { MenuSections } from './MenuSections';
import { HoveredItem, MenuProps } from './types';

export const Menu = <TItem,>(props: MenuProps<TItem>) => {
  if (!props.open) {
    return null;
  }

  return <MenuContent<TItem> {...props} />;
};

const MenuContent = <TItem,>({
  anchorEl,
  onClose,
  sections: sectionsProps,
  renderItem,
  onItemClick,
  actions,
  searchable = true,
  title,
}: MenuProps<TItem>) => {
  const [hoveredItem, setHoveredItem] = useState<HoveredItem>();
  const { sections, searchedValue, setSearchValue } =
    useSearchedItemsSection<TItem>({
      sections: sectionsProps,
    });

  return (
    <MenuPopover
      open
      anchorEl={anchorEl}
      onClose={onClose}
      onMouseLeave={() => {
        setHoveredItem(null);
      }}
    >
      {searchable && (
        <MenuSearch
          placeholder={title}
          searchedValue={searchedValue}
          setSearchValue={setSearchValue}
        />
      )}

      <MenuSections
        sections={sections}
        renderItem={renderItem}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
        onItemClick={onItemClick}
      />

      {!searchedValue && actions && <MenuDivider />}

      {!searchedValue && <MenuActions actions={actions} />}
    </MenuPopover>
  );
};
