import styles from './styles.module.scss';

import { Link, Stack, Typography } from '@mui/material';
import { FC, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { InlineData } from './InlineData';

type IContactCardProps = {
  email: string;
  phoneNumber: string;
  website?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onPhoneNumberClick: () => void;
  onEmailClick: () => void;
};

export const ContactCard: FC<IContactCardProps> = ({
  email,
  phoneNumber,
  onPhoneNumberClick,
  onEmailClick,
  website,
}) => {
  const { t } = useTranslation();

  const createWebsiteHref = () =>
    website === undefined
      ? undefined
      : website.trim().startsWith('http')
      ? website
      : 'https://' + website;

  return (
    <>
      <InlineData
        label={t('FILE_CONTACTS.PHONE')}
        content={
          <div className={styles['contact-buttons']}>
            {!phoneNumber && <Typography variant="body2">-</Typography>}
            {phoneNumber && (
              <Link
                onClick={onPhoneNumberClick}
                underline="none"
                variant="body2"
                className={styles['link']}
              >
                {phoneNumber}
              </Link>
            )}
          </div>
        }
      />
      <InlineData
        label={t('FILE_CONTACTS.CONTACT_OWN')}
        content={
          <div className={styles['contact-buttons']}>
            {!email && !website ? (
              <Typography variant="body2">-</Typography>
            ) : (
              <Stack flexDirection="row" gap="1rem" rowGap="0" flexWrap="wrap">
                {website && (
                  <Link
                    href={createWebsiteHref()}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                    variant="body2"
                    className={styles['link']}
                  >
                    {website}
                  </Link>
                )}

                <Link
                  onClick={onEmailClick}
                  variant="body2"
                  className={styles['link']}
                >
                  {email}
                </Link>
              </Stack>
            )}
          </div>
        }
      />
    </>
  );
};
