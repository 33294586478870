import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { EMAIL_SIGNATURE_KEYS_ARGUMENTS } from '@work4all/models';
import { EMailSignature } from '@work4all/models/lib/Classes/EMailSignature.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { ListEntityPicker } from './components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from './types';

export type EmailSignaturePickerProps = Omit<
  IPickerProps<EMailSignature, false>,
  'multiple' | 'prefilter'
>;

export function EmailSignaturePicker(props: EmailSignaturePickerProps) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...EMAIL_SIGNATURE_PICKER_DATA, ...data };
  }, [data]);

  const renderItem = (item: EMailSignature) => {
    const label = item.name;

    return (
      <Tooltip title={label}>
        <Typography variant="body2" noWrap>
          {label}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <ListEntityPicker
      multiple={false}
      entity={Entities.eMailSignature}
      data={allFields}
      filterBy={['name']}
      sortBy="name"
      renderItemContent={renderItem}
      keysArguments={EMAIL_SIGNATURE_KEYS_ARGUMENTS}
      {...rest}
    />
  );
}

export const EMAIL_SIGNATURE_PICKER_DATA: EMailSignature = {
  id: null,
  name: null,
  body: null,
  bodyTextmarkenAufgeloestInlineImagesResolved: null,
};
