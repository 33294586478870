import { MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FlagIcon } from '@work4all/components';

import { FavoriteItem } from '@work4all/models/lib/Classes/FavoriteItem.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useToggleItemFavorability } from '../../../../../../hooks/use-toggle-Item-favorability';

type Props = {
  id: string | number;
  entity: Entities;
  isFavorite: boolean;
  onChange?: (favoriteItem: FavoriteItem | null) => void;
};

type Return = JSX.Element;

export const OverlayControllerFavoriteItemAction = ({
  id,
  entity,
  isFavorite,
}: Props): Return => {
  const { t } = useTranslation();
  const toggleItemFavorability = useToggleItemFavorability();

  return (
    <MenuItem
      onClick={() => {
        toggleItemFavorability({
          id,
          entity,
          isFavorite,
        });
      }}
    >
      <FlagIcon isFlagged={isFavorite} />
      <Typography marginLeft="0.5rem">
        {isFavorite ? t('COMMON.REMOVE_FLAG') : t('COMMON.SET_FLAG')}
      </Typography>
    </MenuItem>
  );
};
