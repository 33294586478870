import { DateTime } from 'luxon';

import { TableRow } from '@work4all/components';

import { Vacation } from '@work4all/models/lib/Classes/Vacation.entity';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

type Props = {
  value: string;
  row?: TableRow;
};

export const VacationEndDateCell = ({ row }: Props) => {
  const days = (row?.original as Vacation)?.amount;
  const date = (row?.original as Vacation)?.date;

  if (!days || !date) {
    return '';
  }

  const fullDays = Math.floor(days);
  const hours = (days - fullDays) * 24;
  const beginDate = DateTime.fromISO(date);
  const endDate = beginDate.plus({ days: fullDays, hours });

  return endDate.toLocaleString(DateTimeCustom.DATE_SHORT);
};
