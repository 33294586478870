import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data/lib/hooks/data-provider';

import { AppointmentState } from '@work4all/models/lib/Classes/AppointmentState.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IAppointmentStatePickerProps<TMultiple extends boolean> =
  IPickerProps<AppointmentState, TMultiple>;

export function AppointmentStatePicker<TMultiple extends boolean>(
  props: IAppointmentStatePickerProps<TMultiple>
) {
  const { data, ...rest } = props;

  const allFields = useMemo(() => {
    return { ...APPOINTMENT_STATE_FIELDS, ...data };
  }, [data]);

  const requestData = useMemo<DataRequest>(() => {
    return {
      entity: Entities.appointmentState,
      data: allFields,
      completeDataResponse: true,
    };
  }, [allFields]);

  const response = useDataProvider(requestData);

  const dataset = useMemo(() => {
    return {
      total: response.data.length,
      loading: false,
      fetchMore: () => Promise.resolve(),
      refetch: () => Promise.resolve(null),
      data: response.data,
    };
  }, [response.data]);

  return (
    <ListEntityPicker
      entity={Entities.appointmentState}
      data={allFields}
      fixedDataSet={dataset}
      filterBy="name"
      sortBy="name"
      {...rest}
      renderItemContent={(appointmentState) => (
        <Tooltip title={appointmentState.name}>
          <Typography variant="body2" noWrap>
            {appointmentState.name}
          </Typography>
        </Tooltip>
      )}
    />
  );
}

const APPOINTMENT_STATE_FIELDS: AppointmentState = {
  id: null,
  name: null,
};
