import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MAIN_LANGUAGE_CODE } from '@work4all/components/lib/components/entity-picker/base-data-language-picker/BaseDataLanguagePicker';

import { useDataProvider, useUser } from '@work4all/data';

import { Article } from '@work4all/models/lib/Classes/Article.entity';
import { BaseDataLanguage } from '@work4all/models/lib/Classes/BaseDataLanguage.entity';
import { PriceGroup } from '@work4all/models/lib/Classes/PriceGroup.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { ITableSchema } from '@work4all/models/lib/table-schema/table-schema';

import { canViewERPPrices } from '@work4all/utils/lib/permissions';

const priceGroupRequest = {
  entity: Entities.priceGroup,
  data: {
    id: null,
    name: null,
  } as PriceGroup,
  completeDataResponse: true,
};

const languageRequest = {
  operationName: 'GetBaseDataLanguages',
  entity: Entities.baseDataLanguage,
  data: {
    id: null,
    name: null,
  } as BaseDataLanguage,
  completeDataResponse: true,
};

export const useTransformedArticleTableSchema = (props: {
  schema;
  customerId?: number;
}) => {
  const { schema, customerId } = props;

  const { t } = useTranslation();

  const priceGroups = useDataProvider<PriceGroup>(priceGroupRequest);

  const languagesResponse = useDataProvider<BaseDataLanguage>(languageRequest);
  const languages = useMemo(() => {
    return languagesResponse.data.slice().sort((a, b) => a.id - b.id);
  }, [languagesResponse.data]);

  const user = useUser();
  const showERPPrices = canViewERPPrices(user);

  const transformedSchema = useMemo(() => {
    return {
      ...schema,
      entity: schema.entity as Entities,
      columns: [
        ...schema.columns,
        ...(priceGroups.data.map((pG) => ({
          id: 'priceGroup-' + pG.id,
          title: pG.name,
          accessor: 'id',
          sortable: false,
          groupable: false,
          filterable: false,
          defaultHidden: true,
          quickSearchable: false,
          disableFooterSum: true,
          width: 100,
          cell: {
            type: showERPPrices ? 'ArticleSinglePrice' : 'ForbiddenPrice',
            params: {
              hideUnit: true,
              priceGroupId: pG.id,
              customerId,
            },
          },
          path: ['FILTER.groups.sale'],
        })) || []),
        ...(languages
          ?.filter((lang) => lang.id !== MAIN_LANGUAGE_CODE)
          ?.flatMap((lang) => [
            {
              id: 'languageShortText-' + lang.id,
              title: t('PROJECT.LANGUAGE.SHORT_TEXT', { lang: lang.name }),
              accessor: (row: Article) =>
                row.articleTextList?.find((text) => text.sprachCode === lang.id)
                  ?.kurztext,
              sortable: false,
              groupable: false,
              filterable: false,
              defaultHidden: true,
              quickSearchable: false,
              disableFooterSum: true,
              width: 150,
              path: ['FILTER.groups.language'],
            },
            {
              id: 'languageLongText-' + lang.id,
              title: t('PROJECT.LANGUAGE.LONG_TEXT', { lang: lang.name }),
              accessor: (row: Article) =>
                row.articleTextList?.find((text) => text.sprachCode === lang.id)
                  ?.longtext,
              sortable: false,
              groupable: false,
              filterable: false,
              defaultHidden: true,
              quickSearchable: false,
              disableFooterSum: true,
              width: 200,
              path: ['FILTER.groups.language'],
            },
          ]) || []),
      ],
    };
  }, [schema, priceGroups.data, languages, showERPPrices, customerId, t]);

  return transformedSchema as ITableSchema<never>;
};
