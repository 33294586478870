import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import {
  canAddCalculation,
  canAddContract,
  canAddDeliveryNote,
  canAddDemand,
  canAddInboundDeliveryNote,
  canAddInboundInvoice,
  canAddInvoice,
  canAddOffer,
  canAddOrder,
  canConvertCalculation,
  canConvertContract,
  canConvertDeliveryNote,
  canConvertDemand,
  canConvertInboundDeliveryNote,
  canConvertInvoice,
  canConvertOffer,
  canConvertOrder,
  canDeleteCalculation,
  canDeleteContract,
  canDeleteDeliveryNote,
  canDeleteDemand,
  canDeleteInboundDeliveryNote,
  canDeleteInboundInvoice,
  canDeleteInvoice,
  canDeleteOffer,
  canDeleteOrder,
  canEditCalculation,
  canEditContract,
  canEditDeliveryNote,
  canEditDemand,
  canEditInboundDeliveryNote,
  canEditInboundInvoice,
  canEditInvoice,
  canEditOffer,
  canEditOrder,
} from '@work4all/utils/lib/permissions';

import { ALL_PERMISSIONS_TYPES } from '../../constants';
import { PermissionRules, PermissionsConfigsByEntity } from '../types';

const ERP_DENY_PERMISSION_RULES: PermissionRules = {
  isErpLightUser: {
    applyWhenValue: true,
    applyWithPermissions: ALL_PERMISSIONS_TYPES,
  },
  canAccessErp: {
    applyWhenValue: false,
    applyWithPermissions: ALL_PERMISSIONS_TYPES,
  },
};

export const ERP_PERMISSIONS_CONFIGS: PermissionsConfigsByEntity = {
  [Entities.calculation]: {
    permissionsFunctions: {
      canAdd: canAddCalculation,
      canEdit: canEditCalculation,
      canDelete: canDeleteCalculation,
      canConvert: canConvertCalculation,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.offer]: {
    permissionsFunctions: {
      canAdd: canAddOffer,
      canEdit: canEditOffer,
      canDelete: canDeleteOffer,
      canConvert: canConvertOffer,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.contract]: {
    permissionsFunctions: {
      canAdd: canAddContract,
      canEdit: canEditContract,
      canDelete: canDeleteContract,
      canConvert: canConvertContract,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.invoice]: {
    permissionsFunctions: {
      canAdd: canAddInvoice,
      canEdit: canEditInvoice,
      canDelete: canDeleteInvoice,
      canConvert: canConvertInvoice,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.deliveryNote]: {
    permissionsFunctions: {
      canAdd: canAddDeliveryNote,
      canEdit: canEditDeliveryNote,
      canDelete: canDeleteDeliveryNote,
      canConvert: canConvertDeliveryNote,
    },
    denyPermissionRules: {
      canAccessErp: {
        applyWhenValue: false,
        applyWithPermissions: ALL_PERMISSIONS_TYPES,
      },
      /*
       * Light user in the delivery note can do anything normally except converting
       *
       * Full context: https://work4all.atlassian.net/browse/WW-4148
       */
      isErpLightUser: {
        applyWhenValue: true,
        applyWithPermissions: ['canConvert'],
      },
    },
  },
  [Entities.demand]: {
    permissionsFunctions: {
      canAdd: canAddDemand,
      canEdit: canEditDemand,
      canDelete: canDeleteDemand,
      canConvert: canConvertDemand,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.order]: {
    permissionsFunctions: {
      canAdd: canAddOrder,
      canEdit: canEditOrder,
      canDelete: canDeleteOrder,
      canConvert: canConvertOrder,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.inboundDeliveryNote]: {
    permissionsFunctions: {
      canAdd: canAddInboundDeliveryNote,
      canEdit: canEditInboundDeliveryNote,
      canDelete: canDeleteInboundDeliveryNote,
      canConvert: canConvertInboundDeliveryNote,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.inboundInvoice]: {
    permissionsFunctions: {
      canAdd: canAddInboundInvoice,
      canEdit: canEditInboundInvoice,
      canDelete: canDeleteInboundInvoice,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
  [Entities.reViewModel]: {
    permissionsFunctions: {
      canAdd: canAddInboundInvoice,
      canEdit: canEditInboundInvoice,
      canDelete: canDeleteInboundInvoice,
    },
    denyPermissionRules: ERP_DENY_PERMISSION_RULES,
  },
};
