import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableInstance } from 'react-table';

import { WordDocumentTemplate } from '@work4all/models/lib/Classes/WordDocumentTemplate.entity';
import { SortDirection } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationArea } from '../organisation-area';

import { EntityTable, IEntityTable } from './entity-table/EntityTable';
import { useEntityDataTable } from './entity-table/use-entity-data-table';
import schema from './schemata/wordDocumentTemplate-table-schema.json';

type IDocumentTemplateCustomColumns = Record<string, never>;

const defaultSort = [{ field: 'body', direction: SortDirection.ASCENDING }];

const FORCE_REQUEST_FIELDS: WordDocumentTemplate = {
  groupId: null,
};

export const WordDocumentTemplateTable = React.forwardRef<
  TableInstance,
  IEntityTable
>(function WordDocumentTemplateTable(props, ref) {
  const { forceRequestFields, ...rest } = props;

  const { t } = useTranslation();

  const dataTable = useEntityDataTable<
    WordDocumentTemplate,
    IDocumentTemplateCustomColumns
  >({
    schema: schema as never,
    defaultSort,
    forceRequestFields: useMemo(
      () => ({ ...forceRequestFields, ...FORCE_REQUEST_FIELDS }),
      [forceRequestFields]
    ),
  });

  return (
    <EntityTable
      ref={ref}
      {...dataTable}
      areas={{
        left: {
          title: t('COMMON.ORGANISATION'),
          content: <OrganisationArea entity={Entities.wordDocumentTemplate} />,
          resizable: true,
          collapsible: true,
          custom: true,
        },
      }}
      {...rest}
    />
  );
});
