import styles from './PreviewTitle.module.scss';

import { ChevronRight, Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Fullscreen from '@mui/icons-material/Fullscreen';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ShareIcon from '@mui/icons-material/Share';
import { OutlinedInput, Theme, useMediaQuery } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { ForwardedRef, forwardRef, useImperativeHandle, useState } from 'react';

import { ReactComponent as ConvertIcon } from '@work4all/assets/icons/convert.svg';
import { ReactComponent as SettingsIcon } from '@work4all/assets/icons/settings3.svg';

import { usePopoverState } from '@work4all/data/lib/hooks/usePopoverState';

import {
  ConvertPopover,
  IConvertPopoverProps,
} from '../../../convert-popover/ConvertPopover';

export type PreviewTitle = {
  open: () => void;
  close: () => void;
};

export type IPreviewTitleProps = {
  disabled?: boolean;
  readonly?: boolean;
  showEdit?: boolean;
  showDelete?: boolean;
  label?: string;
  leftSubLabel?: string;
  removeTopPadding?: boolean;
  children: React.ReactNode;
  actions?: React.ReactNode;
  onClick?: () => void;
  onVisibilityToggle?: (visible: boolean) => void;
  onClose?: (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onCloseClick?: React.MouseEventHandler;
  onEditClicked?: React.MouseEventHandler;
  onDeleteClicked?: React.MouseEventHandler;
  onSettingsClicked?: React.MouseEventHandler;
  onOpenFilePageClicked?: () => void;
  onShareClicked?: () => void;
  convertProps?: Pick<IConvertPopoverProps, 'exclude' | 'onClick'>;
  leftActions?: React.ReactNode;
  rightActions?: React.ReactNode;
  onFullscreenClick?: () => void;
};

export const PreviewTitle = forwardRef(function PreviewTitle(
  props: IPreviewTitleProps,
  ref: ForwardedRef<PreviewTitle>
) {
  const {
    disabled = true,
    showEdit = true,
    showDelete = true,
    readonly = false,
    label = '',
    children,
    actions,
    onClick,
    onClose,
    onCloseClick,
    onShareClicked,
    onEditClicked,
    onDeleteClicked,
    onSettingsClicked,
    leftSubLabel,
    removeTopPadding,
    onOpenFilePageClicked,
    convertProps,
    leftActions,
    rightActions,
    onFullscreenClick,
    onVisibilityToggle,
  } = props;

  const [editMode, setEditMode] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setEditMode(true);
      },
      close: () => {
        setEditMode(false);
      },
    }),
    []
  );

  const isUpLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
  const isDownSm = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );
  const convertPopoverState = usePopoverState('bottom');

  const closeButton =
    (isDownSm || !onVisibilityToggle) && onCloseClick ? (
      <IconButton size="small" onClick={onCloseClick}>
        <CloseIcon />
      </IconButton>
    ) : null;

  const visibilityToggle =
    !isDownSm && onVisibilityToggle ? (
      <IconButton size="small" onClick={() => onVisibilityToggle(false)}>
        <ChevronRight />
      </IconButton>
    ) : null;

  const openFilePageButton = onOpenFilePageClicked ? (
    <IconButton size="small" onClick={onOpenFilePageClicked}>
      <OpenInNewIcon />
    </IconButton>
  ) : null;

  const fullscreenButton = onFullscreenClick ? (
    <IconButton
      size="small"
      color="primary"
      onClick={() => onFullscreenClick()}
    >
      <Fullscreen />
    </IconButton>
  ) : null;

  const shareButton = onShareClicked ? (
    <IconButton size="small" color="primary" onClick={onShareClicked}>
      <ShareIcon />
    </IconButton>
  ) : null;

  const editButton =
    onEditClicked && !editMode && showEdit ? (
      <IconButton size="small" color="primary" onClick={onEditClicked}>
        <EditIcon />
      </IconButton>
    ) : null;

  const deleteButton =
    onDeleteClicked && showDelete ? (
      <IconButton size="small" color="primary" onClick={onDeleteClicked}>
        <Delete />
      </IconButton>
    ) : null;

  const settingsButton =
    isUpLg && onSettingsClicked ? (
      <IconButton size="small" color="primary" onClick={onSettingsClicked}>
        <SettingsIcon />
      </IconButton>
    ) : null;

  const convertButton = convertProps ? (
    <IconButton
      size="small"
      color="primary"
      onClick={convertPopoverState.handleClick}
    >
      <ConvertIcon style={{ height: '1.5rem', width: '1.5rem' }} />
    </IconButton>
  ) : null;

  return (
    <Stack
      className={styles.root}
      direction="row"
      alignItems="center"
      sx={(theme) => ({
        borderBottom: `1px solid ${theme.vars.palette.ui4.main}`,
        paddingTop: removeTopPadding ? 0 : 'var(--w4a-safe-area-top, 0px)',
        zIndex: 1,
      })}
    >
      {closeButton}
      {visibilityToggle}

      <div className={styles.titleWrapper}>
        {leftSubLabel ? (
          <>
            <Typography variant="h4" className={styles.subTitle}>
              {leftSubLabel}
            </Typography>
            <Typography
              variant="h4"
              className={clsx(styles.subTitle, styles.divider)}
            >
              {'|'}
            </Typography>
          </>
        ) : null}
        {editMode ? (
          <OutlinedInput
            autoFocus={true}
            className={styles.input}
            defaultValue={label}
            classes={{ notchedOutline: styles.withoutOutline }}
            onBlur={(e) => {
              setEditMode(false);
              onClose && onClose(e);
            }}
          />
        ) : (
          <div
            className={clsx(styles.triggerContainer, {
              [styles.readonly]: readonly,
              [styles.interactive]: true,
            })}
            onClick={() => {
              onClick && onClick();

              if (!disabled) {
                setEditMode(true);
              }
            }}
          >
            <Typography
              justifyContent="center"
              variant="h4"
              color="text.primary"
              className={styles.title}
              noWrap
              sx={(theme) => ({
                flex: 1,
                '&:last-of-type': {
                  marginRight: theme.spacing(2),
                },
              })}
            >
              {children}
            </Typography>
          </div>
        )}
      </div>

      {convertProps && (
        <ConvertPopover {...convertProps} popoverState={convertPopoverState} />
      )}

      {actions ? (
        <Stack direction="row">{actions}</Stack>
      ) : (
        <Stack direction="row" alignItems="center">
          {leftActions}
          {shareButton} {fullscreenButton} {openFilePageButton} {editButton}
          {deleteButton}
          {convertButton}
          {settingsButton} {rightActions}
        </Stack>
      )}
    </Stack>
  );
});
