import { Note } from '@work4all/models/lib/Classes/Note.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '../types';

import { NotePreview } from './NotePreview';

type INotePreviewContainerProps = EntityPreviewContainerProps;

export const notePreviewFields: Note<EMode.query> = {
  id: null,
  title: null,
  date: null,
  user: {
    id: null,
    displayName: null,
  },
  businessPartner: {
    id: null,
    businessPartnerType: null,
    data: {
      customer: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
      supplier: {
        id: null,
        name: null,
        website: null,
        isPrivateCustomer: null,
      },
    },
  },
  contact: {
    id: null,
    displayName: null,
    businessPartnerId: null,
    businessPartnerType: null,
    eMail: null,
  },
  project: {
    id: null,
    name: null,
  },
  projectProcess: {
    id: null,
    process: null,
  },
  topicMarkList: [
    {
      id: null,
      name: null,
    },
  ],
  note: null,
};

export function NotePreviewContainer(props: INotePreviewContainerProps) {
  const {
    ids: noteIds,
    onCloseClick,
    onEditClicked,
    onShareClicked,
    convertProps,
    onVisibilityToggle,
  } = props;

  const notes = useEntityPreviewData<Note>(
    noteIds,
    Entities.note,
    notePreviewFields
  );

  if (!notes) {
    return null;
  }

  return (
    <NotePreview
      isCallMemo={false}
      onCloseClick={onCloseClick}
      onEditClicked={onEditClicked}
      entries={notes}
      onShareClicked={onShareClicked}
      convertProps={convertProps}
      onVisibilityToggle={onVisibilityToggle}
    />
  );
}
