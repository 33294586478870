import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';

import { useDataProvider, useUser } from '@work4all/data';

import {
  checkUserRight,
  UserRights,
} from '@work4all/models/lib/additionalEnums/UserRights';
import { User } from '@work4all/models/lib/Classes/User.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { VacationOverview } from '../../../vacation/VacationOverview';

interface IProps {
  beginDate?: string;
  department?: string;
}

export const FullCalendarView = (props: IProps) => {
  const { beginDate, department } = props;

  const usersRequest = useMemo<DataRequest>(() => {
    return {
      entity: Entities.user,
      data: USER_REQUEST_DATA,
      filter: [
        {
          departmentName: {
            $eq: department,
          },
        },
        {
          hasLeft: {
            $eq: false,
          },
        },
      ],
    };
  }, [department]);

  const userResponse = useDataProvider<User>(usersRequest);

  const currentUser = useUser();
  const canUserDisplayMultipleCalendars = checkUserRight(
    UserRights.UrlaubskalenderAufrufen,
    currentUser.benutzerRechte
  );

  const [date, setDate] = useState(() =>
    DateTime.now().startOf('month').toJSDate()
  );

  useEffect(() => {
    if (beginDate) {
      const activeMonth = DateTime.fromISO(beginDate)
        .startOf('month')
        .toJSDate();
      setDate(activeMonth);
    }
  }, [beginDate]);

  return (
    <VacationOverview
      showSelfOnly={!canUserDisplayMultipleCalendars}
      date={date}
      users={userResponse?.data}
      timeRange="month"
      setDate={setDate}
    />
  );
};

const USER_REQUEST_DATA = {
  id: null,
  displayName: null,
  departmentName: null,
  eMail: null,
  hasLeft: null,
  firstName: null,
  lastName: null,
  loginName: null,
  userKind: null,
};
