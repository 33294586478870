import styles from './EntitySuggestionPickerCell.module.scss';

import { Autocomplete, Input } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntitySuggestions } from '../../../../../../hooks/use-entity-suggestions';

type Props = {
  entity: Entities;
  field: string;
  value: string;
  onChange: (value: string) => void;
  isEditMode: boolean;
};

export function EntitySuggestionPickerCell({
  field,
  entity,
  value,
  onChange,
  isEditMode = false,
}: Props) {
  const [inputValue, setInputValue] = useState(value);
  const suggestions = useEntitySuggestions({
    field,
    entity,
    value: inputValue,
  });

  return (
    <Autocomplete
      freeSolo
      clearIcon={false}
      forcePopupIcon={false}
      options={suggestions}
      renderInput={(params) => {
        const { InputProps, inputProps } = params;

        return (
          <Input
            inputProps={inputProps}
            inputRef={InputProps.ref}
            className={clsx(styles.input, {
              [styles.inputEditMode]: isEditMode,
            })}
            disableUnderline
          />
        );
      }}
      value={value}
      inputValue={inputValue}
      onInputChange={(_, value) => {
        setInputValue(value);
      }}
      onChange={(_, value) => {
        onChange(value);
      }}
      onBlur={() => {
        onChange(inputValue);
      }}
    />
  );
}
