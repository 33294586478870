import styles from './Dashboard.module.scss';

import { ArrowUpward } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { CurrencyCell } from '@work4all/components';
import { Card } from '@work4all/components/lib/dataDisplay/card';
import { Body1 } from '@work4all/components/lib/typography/body1/Body1';
import { Heading4 } from '@work4all/components/lib/typography/heading4/Heading4';

export function DashboardCalcCard(props: {
  label: string;
  checkBoxValue: boolean;
  currentValue: number;
  previousValue: number;
  variation: number;
  isLoading: boolean;
  onChange: (val: boolean) => void;
}) {
  const { t } = useTranslation();

  const trend = props.variation;

  return (
    <Card style={{ height: '100%' }}>
      <Heading4>{props.label}</Heading4>

      {!props.isLoading && (
        <div className={styles.dashboardValues}>
          <div className={styles.value}>
            {props.currentValue !== undefined && (
              <CurrencyCell
                value={props?.currentValue}
                maximumFractionDigits={0}
                minimumFractionDigits={0}
              />
            )}
          </div>
          <div className={styles.dashboardPreviousValueWrap}>
            <Heading4>
              {props.previousValue !== undefined && (
                <CurrencyCell
                  value={props?.previousValue}
                  maximumFractionDigits={0}
                  minimumFractionDigits={0}
                />
              )}
            </Heading4>
            <Body1
              className={clsx(styles.dashboardTrend, {
                [styles.dashboardTrendEven]: trend < 1 && trend > -1,
                [styles.dashboardTrendSlightlyUp]: trend >= 1 && trend < 10,
                [styles.dashboardTrendUp]: trend >= 10,
                [styles.dashboardTrendSlightlyDown]: trend <= -1 && trend > -10,
                [styles.dashboardTrendDown]: trend <= -10,
              })}
            >
              {trend === Infinity
                ? '∞'
                : trend === -Infinity
                ? '-∞'
                : `${
                    trend < 10 && trend > -10
                      ? trend?.toFixed(1)
                      : trend?.toFixed(0)
                  } `}{' '}
              {t('DASHBOARD.PERCENT_TO_PREVIOUS_YEAR')}
            </Body1>
          </div>
          <ArrowUpward
            className={clsx(styles.dashboardTrendIndicator, {
              [styles.dashboardTrendEven]: trend < 1 && trend > -1,
              [styles.dashboardTrendSlightlyUp]: trend >= 1 && trend < 10,
              [styles.dashboardTrendUp]: trend >= 10,
              [styles.dashboardTrendSlightlyDown]: trend <= -1 && trend > -10,
              [styles.dashboardTrendDown]: trend <= -10,
            })}
          />
        </div>
      )}

      {props.isLoading && (
        <div className={styles.dashboardValues} style={{ gap: '1rem' }}>
          <Skeleton height="4rem" width="50%" />
          <div
            className={styles.dashboardPreviousValueWrap}
            style={{ gap: '0', width: '50%' }}
          >
            <Skeleton height="1.5rem" width="100%" />
            <Skeleton height="1.5rem" width="100%" />
          </div>
          <Skeleton height="4rem" width="5rem" style={{ flexBasis: '' }} />
        </div>
      )}
    </Card>
  );
}
