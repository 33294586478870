import { EntitiyDefinition, FieldDefinitions } from '../DataProvider';
import { EMailTemplateKind } from '../Enums/EMailTemplateKind.enum';
import { EMode } from '../Enums/EMode.enum';
import { Entities } from '../Enums/Entities.enum';
import { SignaturMode } from '../Enums/SignaturMode.enum';

import { BaseDataLanguage } from './BaseDataLanguage.entity';
import { EMailSignature } from './EMailSignature.entity';
import { EMailTemplateAttachment } from './EMailTemplateAttachment.entity';
import { EMailTemplateGroup } from './EMailTemplateGroup.entity';

export class EMailTemplate<T extends EMode = EMode.entity> {
  /** Alias: id */
  id?: string;
  /** Alias: name */
  name?: string;
  /** Alias: betreff */
  subject?: string;
  /** Alias: body */
  body?: string;
  /** Alias: signaturId */
  signaturId?: string;
  /** Alias: signaturMode */
  signaturMode?: SignaturMode;
  /** Alias: vorlagenArt */
  eMailTemplateKind?: EMailTemplateKind;
  /** Alias: signatur */
  signature?: EMailSignature<T>;
  /** Alias: legacyTemplateCode */
  legacyTemplateCode?: number;
  /** Alias: updateTime */
  updateTime?: string;
  /** Alias: insertTime */
  insertTime?: string;
  /** Alias: anhaenge */
  attachmentList?: EMailTemplateAttachment<T>[];
  /** Alias: groupId */
  groupId?: string;
  /** Alias: templateGroup */
  templateGroup?: EMailTemplateGroup<T>;
  /** Alias: parentId */
  parentId?: string;
  /** Alias: languageCode */
  languageCode?: number;
  /** Alias: language */
  language?: BaseDataLanguage<T>;
  /** Alias: parent */
  parent?: EMailTemplate<T>;
  /** Alias: childs */
  childs?: EMailTemplate<T>[];

  __typename?: string;
}

const fields: FieldDefinitions<EMailTemplate> = {
  id: {
    alias: 'id',
  },
  name: {
    alias: 'name',
  },
  subject: {
    alias: 'betreff',
  },
  body: {
    alias: 'body',
  },
  signaturId: {
    alias: 'signaturId',
  },
  signaturMode: {
    alias: 'signaturMode',
  },
  eMailTemplateKind: {
    alias: 'vorlagenArt',
  },
  signature: {
    alias: 'signatur',
    entity: Entities.eMailSignature,
  },
  legacyTemplateCode: {
    alias: 'legacyTemplateCode',
  },
  updateTime: {
    alias: 'updateTime',
  },
  insertTime: {
    alias: 'insertTime',
  },
  attachmentList: {
    alias: 'anhaenge',
    entity: Entities.eMailTemplateAttachment,
  },
  groupId: {
    alias: 'groupId',
  },
  templateGroup: {
    alias: 'templateGroup',
    entity: Entities.eMailTemplateGroup,
  },
  parentId: {
    alias: 'parentId',
  },
  languageCode: {
    alias: 'languageCode',
  },
  language: {
    alias: 'language',
    entity: Entities.baseDataLanguage,
  },
  parent: {
    alias: 'parent',
    entity: Entities.eMailTemplate,
  },
  childs: {
    alias: 'childs',
    entity: Entities.eMailTemplate,
  },
  __typename: {
    alias: '__typename',
  },
};

export const eMailTemplateEntityDefinition: EntitiyDefinition<EMailTemplate> = {
  local: {},
  remote: {
    queryName: 'getEMailVorlagen2',
    fragmentName: 'EMailVorlage',
    withPaginationWrapper: true,
    mutations: {
      upsert: {
        mutationName: 'upsertEMailVorlage',
        args: [
          { name: 'input', type: 'InputEMailVorlage!' },
          { name: 'relations', type: 'InputEMailVorlagenRelation' },
          { name: 'drySave', type: 'Boolean' },
        ],
      },
    },
    params: [
      { name: 'querySize', type: 'Int' },
      { name: 'queryPage', type: 'Int' },
      { name: 'querySortOrder', type: 'SortOrderStyle' },
      { name: 'querySortBy', type: 'String' },
      { name: 'querySortByEx', type: '[SortByPair]' },
      { name: 'filter', type: 'String' },
    ],
  },
  fieldDefinitions: fields,
};
