import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { Filters } from 'react-table';

import { ITableStateBag, useTableStateBag } from '@work4all/components';

import {
  ICustomCellConfigBase,
  ITableSchema,
} from '@work4all/models/lib/table-schema/table-schema';

export const useTransformedFilterFields = (
  schema: ITableSchema<Record<string, ICustomCellConfigBase>>
) => {
  const tableStateBag = useTableStateBag();
  return useMemo<Filters<object>>(() => {
    return transformedFilterFields(tableStateBag, schema);
  }, [schema.columns, tableStateBag.tableState?.filters]);
};

export const transformedFilterFields = (
  tableStateBag: ITableStateBag,
  schema: ITableSchema<Record<string, ICustomCellConfigBase>>
) => {
  return (cloneDeep(tableStateBag.tableState?.filters) || []).map((filter) => {
    const colId = filter.id;
    const col = schema.columns.find((x) => x.id === colId);
    if (col?.filterField) {
      filter.id = col?.filterField;
    }
    return filter;
  });
};
