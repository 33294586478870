import { useTranslation } from 'react-i18next';

import { useDialogs } from '@work4all/components';
import { CheckboxRadioItem } from '@work4all/components/lib/input/checkbox-radio-item';

import { useSetting } from '@work4all/data/lib/settings';

import { settings } from '../../../../settings';

export const Force2FACheckbox = () => {
  const { t } = useTranslation();

  const enabled = useSetting(settings.admin2faEnabled());

  const dialogs = useDialogs();

  const handleOnChange = async (e, value) => {
    if (value) {
      const accepted = await dialogs.confirm({
        title: t('MFA.ADMIN.DIALOG_TITLE'),
        description: t('MFA.ADMIN.DIALOG_DESC'),
        confirmLabel: t('MFA.ADMIN.ACTIVATE'),
        cancelLabel: t('MFA.CANCEL'),
      });
      if (accepted) enabled.set(true);
    } else {
      enabled.set(false);
    }
  };

  return (
    <CheckboxRadioItem
      checked={enabled.value}
      onChange={handleOnChange}
      label={t('MFA.ADMIN.MESSAGE')}
    />
  );
};
