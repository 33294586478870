import { Add, Delete, Fullscreen, Print, Repeat } from '@mui/icons-material';
import { Box, IconButton, Stack, ToggleButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TimeFrameSwitch } from '@work4all/components/lib/components/time-frame-switch/TimeFrameSwitch';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';
import { Divider } from '@work4all/components/lib/dataDisplay/divider/Divider';
import { BaseActionButton } from '@work4all/components/lib/input/base-action-button/BaseActionButton';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { LookUp } from '@work4all/models/lib/Classes/LookUp.entity';

import { RessourceClassPickerField } from '../../../../../../../../entity-picker/RessourceClassPickerField';
import { TimeUnit, TimeUnitSwitch } from '../time-unit-switch/TimeUnitSwitch';
import { TimelineZoom, ZoomValue } from '../timeline-zoom/TimelineZoom';

interface IProps {
  showAddAction?: boolean;
  showDeleteAction?: boolean;
  showPrintAction?: boolean;
  showDateSelector?: boolean;
  showZoomControl?: boolean;
  showTimeUnitControl?: boolean;
  showFullscreenAction?: boolean;
  onPrintClick: () => void;
  onAddProcessClick: () => void;
  onDeleteProcessClick: () => void;
  onRessourceClassesChange: (values: LookUp[]) => void;
  resourceClasses: LookUp[];
  onFullscreenBtnClick: () => void;
  onDateChange: (startTime: Date, endTime: Date) => void;
  unit: 'day' | 'week' | 'month' | 'year';
  date: Date;
  zoom: ZoomValue;
  onZoomChange: (zoom: ZoomValue) => void;
  onTimeUnitChange: (unit: TimeUnit) => void;
  showResourceFilter?: boolean;
  showMaxEventStackSelector?: boolean;
  maxEventStack?: number;
  onMaxEventStackChange?: (value: number) => void;
  showConnectionToggle?: boolean;
  showConnections?: boolean;
  onConnectionToggleChange?: (value: boolean) => void;
}

export const ProjectPlanningToolbar = ({
  showAddAction = true,
  showDeleteAction = true,
  showPrintAction = true,
  showDateSelector = true,
  showZoomControl = true,
  showTimeUnitControl = true,
  showFullscreenAction = true,
  showResourceFilter = true,
  showMaxEventStackSelector = true,
  showConnectionToggle = true,
  showConnections = true,
  maxEventStack = 3,
  unit,
  date,
  zoom,
  onPrintClick,
  onAddProcessClick,
  onDateChange,
  onZoomChange,
  resourceClasses,
  onTimeUnitChange,
  onFullscreenBtnClick,
  onRessourceClassesChange,
  onConnectionToggleChange,
  onMaxEventStackChange,
  onDeleteProcessClick,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row">
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        alignContent="center"
        gap="1rem"
      >
        {showAddAction && (
          <BaseActionButton
            color="primary"
            icon={<Add />}
            onClick={onAddProcessClick}
          >
            {t('COMMON.NEW_PROJECT_PROCESS')}
          </BaseActionButton>
        )}
        {showDeleteAction && (
          <>
            <IconButton onClick={onDeleteProcessClick}>
              <Delete color="primary" />
            </IconButton>
            <Divider orientation="vertical" style={{ padding: '1rem 0' }} />
          </>
        )}
        {showPrintAction && (
          <>
            <Tooltip title={t('INPUTS.PRINT')}>
              <IconButton color="primary" onClick={onPrintClick}>
                <Print />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" style={{ padding: '1rem 0' }} />
          </>
        )}

        {showDateSelector && (
          <TimeFrameSwitch
            timeUnit={unit === 'year' ? 'month' : unit}
            initialTime={new Date(date)}
            onChange={onDateChange}
            showTitle={true}
            todayAction={true}
          />
        )}
      </Stack>
      <Stack
        width="100%"
        direction="row"
        gap="1rem"
        padding="0.25rem 1rem"
        justifyContent="end"
        alignContent="center"
        alignItems="center"
      >
        {showResourceFilter && (
          <Box width="10rem">
            <RessourceClassPickerField
              multiple
              onChange={(e) => onRessourceClassesChange(e || [])}
              value={resourceClasses}
            />
          </Box>
        )}
        {unit === 'year' && showZoomControl && (
          <>
            <TimelineZoom zoom={zoom} onZoomChange={onZoomChange} />
            <Divider orientation="vertical" style={{ padding: '0.5rem 0' }} />
          </>
        )}
        {showConnectionToggle && (
          <Tooltip title={t('TIMELINE.SHOW_CONNECTIONS')}>
            <ToggleButton
              selected={showConnections}
              value="check"
              onChange={() => {
                onConnectionToggleChange?.(!showConnections);
              }}
              color="primary"
            >
              <Repeat />
            </ToggleButton>
          </Tooltip>
        )}
        {showMaxEventStackSelector && (
          <LabeledInput
            type="number"
            value={maxEventStack}
            onChange={(e) => {
              const value = parseInt(e.target.value.toString());
              if (value > 0) onMaxEventStackChange?.(value);
            }}
            style={{ width: '4rem' }}
            label={t('TIMELINE.MAX_EVENT_STACK')}
            inputProps={{
              onFocus: (e) => {
                /**
                 * Workaround:
                 * setSelectionRange() is only possible on input type "text"
                 * */
                const target = e.target;
                target.type = 'text';
                const value = target.value;
                target.setSelectionRange(0, value.length);
                target.type = 'number';
              },
            }}
          />
        )}
        {showTimeUnitControl && (
          <>
            <Divider orientation="vertical" style={{ padding: '0.5rem 0' }} />
            <TimeUnitSwitch unit={unit} onChange={onTimeUnitChange} />
          </>
        )}

        {showFullscreenAction && (
          <>
            <Divider orientation="vertical" style={{ padding: '0.5rem 0' }} />
            <Tooltip title={t('COMMON.FULLSCREEN')}>
              <IconButton color="primary" onClick={onFullscreenBtnClick}>
                <Fullscreen />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </Stack>
  );
};
