import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { IUser, useDataProvider } from '@work4all/data';

import { VacationRequestEntity } from '@work4all/models/lib/Classes/VacationRequestEntity.entity';
import { DataRequest } from '@work4all/models/lib/DataProvider';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { VacationRequestStatus } from '@work4all/models/lib/Enums/VacationRequestStatus.enum';

export const useVacationPermits = (user: IUser, startDate: DateTime) => {
  const requestOpenVacations: DataRequest = useMemo(() => {
    return {
      entity: Entities.vacationRequestEntity,
      data: {},
      filter: [
        {
          $and: [
            {
              'releasedByUser.id': {
                $eq: user.benutzerCode,
              },
            },
            {
              status: {
                $in: [VacationRequestStatus.OPEN],
              },
            },
            { dateFrom: { $gte: startDate.toISO() } },
          ],
        },
      ],
    };
  }, [startDate, user.benutzerCode]);

  const resOpenVacations = useDataProvider<VacationRequestEntity>(
    requestOpenVacations,
    false,
    1
  );

  return resOpenVacations;
};
