import styles from './StatisticPage.module.scss';

import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
  EventType,
  sendAmplitudeData,
} from '@work4all/components/lib/utils/amplitude/amplitude';
import { isTimeTrackingUser } from '@work4all/components/lib/utils/isTimeTrackingUser';

import { AppParts, useCanView, useModuleRights } from '@work4all/data';

import { NoAccess } from '../NoAccess';

import { StatisticCustomerSheetContainer } from './components/StatisticCustomerSheetContainer';
import { StatisticDashboardContainer } from './components/StatisticDashboardContainer';
import { StatisticSalesVolumeByCostCenterContainer } from './components/StatisticSalesVolumeByCostCenterContainer';
import { StatisticSalesVolumeByCustomerContainer } from './components/StatisticSalesVolumeByCustomerContainer';
import { StatisticSalesVolumeByItemContainer } from './components/StatisticSalesVolumeByItemContainer';
import { StatisticSalesVolumeBySupplierContainer } from './components/StatisticSalesVolumeBySupplierContainer';
import { StatisticSalesVolumeYearContainer } from './components/StatisticSalesVolumeYearContainer';
import { StatisticSupplierSheetContainer } from './components/StatisticSupplierSheetContainer';
import { StatisticTimeCardContainer } from './components/StatisticTimeCardContainer';
import { TopBar } from './components/top-bar/TopBar';
import { StatisticType } from './data/StatisticDefinitions';

const statisticComponents = {
  [StatisticType.salesvolumeyear]: StatisticSalesVolumeYearContainer,
  [StatisticType.salesvolumebycustomer]:
    StatisticSalesVolumeByCustomerContainer,
  [StatisticType.salesvolumebycostcenter]:
    StatisticSalesVolumeByCostCenterContainer,
  [StatisticType.salesvolumebysupplier]:
    StatisticSalesVolumeBySupplierContainer,
  [StatisticType.dashboard]: StatisticDashboardContainer,
  [StatisticType.customersheet]: StatisticCustomerSheetContainer,
  [StatisticType.suppliersheet]: StatisticSupplierSheetContainer,
  [StatisticType.salesvolumebyitem]: StatisticSalesVolumeByItemContainer,
};

export function StatisticPage() {
  const { statisticType } = useParams<{
    statisticType: StatisticType;
  }>();

  const { rights } = useModuleRights();
  const canViewTimecard = useCanView(AppParts.TIMECARD);
  const canViewTimetracking = useCanView(AppParts.TIME_TRACKING);
  const canViewStatistic =
    useCanView(AppParts.STATISTICS) && !isTimeTrackingUser(rights);

  useEffect(() => {
    sendAmplitudeData(EventType.OpenStatistic, {
      name: statisticType,
    });
  }, [statisticType]);

  const hasAccess = useCallback(
    (type: StatisticType) => {
      if (type === StatisticType.timecard) {
        return canViewTimecard && canViewTimetracking;
      }
      return canViewStatistic;
    },
    [canViewStatistic, canViewTimecard, canViewTimetracking]
  );

  const statisticComp = useMemo(() => {
    if (!hasAccess(statisticType)) {
      return <NoAccess reason="route" />;
    }

    if (statisticType === StatisticType.timecard) {
      return <StatisticTimeCardContainer />;
    }

    const Component = statisticComponents[statisticType];
    return Component ? <Component /> : null;
  }, [hasAccess, statisticType]);

  return (
    <div className={styles.root}>
      <TopBar
        showSettingdeliveryPeriod={
          statisticType === StatisticType.salesvolumeyear ||
          statisticType === StatisticType.salesvolumebycustomer ||
          statisticType === StatisticType.salesvolumebycostcenter ||
          statisticType === StatisticType.dashboard
        }
      />
      <div className={styles.wrap}>
        <div className={styles.tableWrapper}>{statisticComp}</div>
      </div>
    </div>
  );
}
