import { DateTime } from 'luxon';
import { createSearchParams } from 'react-router-dom';

import {
  APPOINTMENT_GROUP_MODES,
  APPOINTMENT_TIME_RANGES,
  DEFAULT_APPOINTMENT_GROUP_MODE,
  DEFAULT_APPOINTMENT_TIME_RANGE,
  DEFAULT_PLANNING_APPOINTMENT_VALUE,
} from '../constants';
import {
  AppointmentCalendarParams,
  AppointmentCalendarState,
  AppointmentsGroupMode,
  AppointmentsTimeRange,
} from '../types';

export const SearchParamNames = {
  UserIds: 'users',
  FocusedUserIds: 'focused-users',
  Date: 'date',
  Range: 'view',
  GroupMode: 'display-mode',
  AppointmentStates: 'categories',
  PlanningAppointments: 'planningAppointments',
};

export function parseCalendarSearchParams(
  searchParams: URLSearchParams
): AppointmentCalendarParams {
  function parseUserIdsParam(): number[] {
    const userIdsParam = searchParams.get(SearchParamNames.UserIds);

    if (userIdsParam !== null) {
      return userIdsParam
        .split(',')
        .filter((id) => id !== '')
        .map((id) => Number(id))
        .filter((id) => !Number.isNaN(id));
    }

    return [];
  }

  function parseFocusedUserIdsParam(): number[] {
    const focusedUserIdsParam = searchParams.get(
      SearchParamNames.FocusedUserIds
    );

    if (focusedUserIdsParam !== null) {
      return focusedUserIdsParam
        .split(',')
        .filter((id) => id !== '')
        .map((id) => Number(id))
        .filter((id) => !Number.isNaN(id));
    }

    return [];
  }

  function parseDateParam(): Date {
    const dateParam = searchParams.get(SearchParamNames.Date);

    if (dateParam !== null) {
      const date = new Date(dateParam);

      if (!Number.isNaN(date.valueOf())) {
        return date;
      }
    }

    return DateTime.now().startOf('day').toJSDate();
  }

  function parsePlanningAppointments(): boolean {
    const param = searchParams.get(SearchParamNames.PlanningAppointments);

    if (param !== null && param !== 'undefined') {
      return JSON.parse(param);
    }

    return DEFAULT_PLANNING_APPOINTMENT_VALUE;
  }

  function parseRange(): AppointmentsTimeRange {
    const rangeParam = searchParams.get(SearchParamNames.Range);

    if (
      rangeParam !== null &&
      APPOINTMENT_TIME_RANGES.includes(rangeParam as AppointmentsTimeRange)
    ) {
      return rangeParam as AppointmentsTimeRange;
    }

    return DEFAULT_APPOINTMENT_TIME_RANGE;
  }

  function parseGroupMode(): AppointmentsGroupMode {
    const groupModeParam = searchParams.get(SearchParamNames.GroupMode);

    if (
      groupModeParam !== null &&
      APPOINTMENT_GROUP_MODES.includes(groupModeParam as AppointmentsGroupMode)
    ) {
      return groupModeParam as AppointmentsGroupMode;
    }

    return DEFAULT_APPOINTMENT_GROUP_MODE;
  }

  function parseAppointmentStates() {
    const appointmentStatesParam = searchParams.get(
      SearchParamNames.AppointmentStates
    );

    if (appointmentStatesParam !== null) {
      return appointmentStatesParam
        .split(',')
        .filter((id) => id !== '')
        .map((id) => Number(id))
        .filter((id) => !Number.isNaN(id));
    }

    return [];
  }

  const userIds = parseUserIdsParam();
  const focusedUserIds = parseFocusedUserIdsParam();
  const date = parseDateParam();
  const range = parseRange();
  const groupMode = parseGroupMode();
  const appointmentStates = parseAppointmentStates();
  const planningAppointments = parsePlanningAppointments();

  return {
    userIds,
    focusedUserIds,
    date,
    range: range === 'day' && groupMode === 'vertical' ? 'week-5' : range,
    groupMode,
    appointmentStates,
    planningAppointments,
  };
}

export type CreateCalendarSearchParamsOptions = Omit<
  AppointmentCalendarState,
  'users' | 'date'
> &
  Partial<Pick<AppointmentCalendarState, 'date'>> &
  (
    | Pick<AppointmentCalendarState, 'users'>
    | Pick<AppointmentCalendarParams, 'userIds'>
  );

export function createCalendarSearchParams(
  options: CreateCalendarSearchParamsOptions
): URLSearchParams {
  const {
    focusedUserIds,
    date,
    range,
    groupMode,
    appointmentStates,
    planningAppointments,
  } = options;

  const userIds =
    'userIds' in options
      ? options.userIds
      : options.users.map((user) => user.id);

  return createSearchParams({
    [SearchParamNames.UserIds]: userIds.slice().sort().join(','),
    [SearchParamNames.FocusedUserIds]: focusedUserIds.slice().sort().join(','),
    ...(date != null && {
      [SearchParamNames.Date]: DateTime.fromJSDate(date).toISODate(),
    }),
    [SearchParamNames.Range]: range,
    [SearchParamNames.GroupMode]: groupMode,
    [SearchParamNames.PlanningAppointments]:
      JSON.stringify(planningAppointments),
    [SearchParamNames.AppointmentStates]: appointmentStates
      .slice()
      .sort()
      .join(','),
  });
}
