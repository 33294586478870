import styles from './../../../../../MaskOverlay.module.scss';

import { Panel } from '../../../../../components';

import { AssignmentContent } from './AssignmentContent';
import { DescriptionContent } from './DescriptionContent';
import { CheckListPositionsTable } from './positions-table';

export const CheckListOverlayControllerGeneralTabContent = () => {
  return (
    <Panel className={styles.fullHeightPanel}>
      <DescriptionContent />
      <AssignmentContent />
      <CheckListPositionsTable />
    </Panel>
  );
};
