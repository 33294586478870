import styles from './TimeTrackerControls.module.scss';

import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { Theme, useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { TimeTracker, TimeTrackerState } from './use-time-tracker';

const MAX_HOURS_PRESENT = 20;

export enum TIMETRACKER_LAYOUTS {
  BUTTON = 'BUTTON',
  RIBBON = 'RIBBON',
  CARD_HEADER = 'CARD_HEADER',
}

export interface TimeTrackerControlsProps {
  /**
   * @default false
   */
  layout?: TIMETRACKER_LAYOUTS;
  disabled?: boolean;
  state: TimeTrackerState;
  presentSince: DateTime;
  pausedSince: DateTime;
  stoppedSince: DateTime;
  onStart: () => void;
  onStop: () => void;
  onPause?: () => void;
  onResume?: () => void;
  onClick?: () => void;
  tracker?: TimeTracker;
  className?: string;
}

export function TimeTrackerControls(props: TimeTrackerControlsProps) {
  const {
    disabled = false,
    state,
    presentSince,
    pausedSince,
    stoppedSince,
    onStart,
    onStop,
    onPause,
    onResume,
    onClick,
    layout = 'ribbon',
    tracker,
    className,
  } = props;

  const { t } = useTranslation();

  const format = (dateTime: DateTime): string => {
    return dateTime.toLocaleString(DateTime.TIME_SIMPLE);
  };

  const cleanStateEvent = (fn: () => void) => {
    return (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      e.preventDefault();
      return fn();
    };
  };

  const isRunningForMoreThanMaxHours =
    tracker?.result?.totalPresent?.as('hours') > MAX_HOURS_PRESENT;

  const getCurrentStateTimestamp = () => {
    switch (state) {
      case 'initial':
        return null;
      case 'running':
        return format(presentSince);
      case 'paused':
        return format(pausedSince);
      case 'stopped':
        return format(stoppedSince);
    }
  };

  const isRibbon = layout === TIMETRACKER_LAYOUTS.RIBBON;
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm')
  );

  const translationSuffix = isMobile ? '_short' : '';

  return (
    <div className={styles.root}>
      <Button
        onClick={onClick}
        className={clsx(
          styles.outerButton,
          styles[state],
          {
            [styles.disabled]: disabled,
            [styles.isButtonMode]: !isRibbon,
            [styles.warning]: isRunningForMoreThanMaxHours,
            [styles.cardHeader]: layout === TIMETRACKER_LAYOUTS.CARD_HEADER,
          },
          className
        )}
      >
        {isRunningForMoreThanMaxHours && !isRibbon ? (
          <div>
            <WarningIcon color="error" />
          </div>
        ) : null}
        <Typography
          className={styles.label}
          variant="h4"
          color={isRunningForMoreThanMaxHours ? 'error' : 'inherit'}
        >
          {isRunningForMoreThanMaxHours
            ? t('TIME_TRACKER.MAX_HOURS_WARNING', {
                hours: MAX_HOURS_PRESENT,
              })
            : t(`TIME_TRACKER.INFO_LABEL.${state}${translationSuffix}`, {
                time: getCurrentStateTimestamp(),
              })}
        </Typography>
      </Button>

      {(state === 'initial' || state === 'stopped') && (
        <Button
          className={clsx(styles.innerButton, {
            [styles.start]: !isRibbon && state === 'initial',
            [styles.isButtonMode]: !isRibbon,
            [styles.disabled]: disabled,
          })}
          size="large"
          color="inherit"
          startIcon={<PlayCircleIcon />}
          onClick={cleanStateEvent(onStart)}
        >
          {isRibbon
            ? t(
                `TIME_TRACKER.BUTTONS.${
                  state === 'initial' ? 'START' : 'CONTINUE'
                }`
              )
            : null}
        </Button>
      )}

      {state === 'running' && (
        <Button
          className={clsx(styles.innerButton, styles.stop, {
            [styles.isButtonMode]: !isRibbon,
            [styles.disabled]: disabled,
          })}
          size="large"
          style={
            isRunningForMoreThanMaxHours
              ? {
                  color: 'var(--error)',
                }
              : undefined
          }
          startIcon={<StopCircleIcon />}
          onClick={cleanStateEvent(onStop)}
        >
          {isRibbon ? t('TIME_TRACKER.BUTTONS.STOP') : null}
        </Button>
      )}

      {state === 'running' && onPause && (
        <Button
          className={clsx(styles.innerButton, styles.pause, {
            [styles.isButtonMode]: !isRibbon,
            [styles.disabled]: disabled,
          })}
          size="large"
          style={
            isRunningForMoreThanMaxHours
              ? {
                  color: 'var(--error)',
                }
              : undefined
          }
          startIcon={<PauseCircleIcon />}
          onClick={cleanStateEvent(onPause)}
        >
          {isRibbon ? t('TIME_TRACKER.BUTTONS.PAUSE') : null}
        </Button>
      )}

      {state === 'paused' && (
        <Button
          className={clsx(styles.innerButton, styles.unpause, {
            [styles.isButtonMode]: !isRibbon,
            [styles.disabled]: disabled,
          })}
          size="large"
          color="inherit"
          style={
            isRunningForMoreThanMaxHours
              ? {
                  color: 'var(--error)',
                }
              : undefined
          }
          startIcon={<PlayCircleIcon />}
          onClick={cleanStateEvent(onResume)}
        >
          {isRibbon ? t('TIME_TRACKER.BUTTONS.UNPAUSE') : null}
        </Button>
      )}
    </div>
  );
}
