import { FullUser } from '@work4all/models/lib/Classes/FullUser.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { useEntityPreviewData } from '../hooks/use-entity-preview-data';
import { EntityPreviewContainerProps } from '../types';

import { UserPreview } from './UserPreview';

type UserPreviewContainerProps = EntityPreviewContainerProps;

export const USER_PREVIEW_FIELDS: FullUser<EMode.query> = {
  id: null,
  name: null,
  displayName: null,
  eMail: null,
  ressourceClass: {
    id: null,
    name: null,
  },
  group: {
    id: null,
    name: null,
  },
  role: null,
  costCenter: {
    id: null,
    name: null,
  },
  supervisor: { id: null, displayName: null },
  vacationApprover: { id: null, displayName: null },
  userKind: null,
};

export function UserPreviewContainer(props: UserPreviewContainerProps) {
  const entries = useEntityPreviewData<FullUser>(
    props.ids,
    Entities.fullUser,
    USER_PREVIEW_FIELDS
  );

  if (!entries) {
    return null;
  }

  return <UserPreview entries={entries} {...props} />;
}
