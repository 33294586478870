import styles from './FilterTextInput.module.scss';

import { IconButton } from '@mui/material';
import clsx from 'clsx';
import React, { useLayoutEffect, useRef } from 'react';

import { ReactComponent as CloseIcon } from '@work4all/assets/icons/close.svg';
import { ReactComponent as SearchIcon } from '@work4all/assets/icons/search.svg';

import { reactRefSetter } from '@work4all/utils';

export type IFilterTextInputProps = {
  placeholder?: string;
  value?: string;
  // ref?: React.MutableRefObject<HTMLInputElement>;
  onChange: (value: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  /** @default true */
  autoFocus?: boolean;
  disabled?: boolean;
  inputType?: React.HTMLInputTypeAttribute;
  className?: string;
};

export const FilterTextInput = React.forwardRef<
  HTMLInputElement,
  IFilterTextInputProps
>(function FilterTextInput(props, ref) {
  const {
    placeholder,
    value,
    onChange,
    onKeyDown,
    autoFocus,
    disabled,
    inputType,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (autoFocus) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    onChange(e.target.value);
  };

  const handleClearClick: React.MouseEventHandler = () => {
    onChange('');
    inputRef.current?.focus();
  };

  return (
    <div className={clsx(styles['root'], props.className)}>
      <input
        ref={reactRefSetter(ref, inputRef)}
        className={clsx(styles['input'], {
          [styles['input-disabled']]: disabled,
        })}
        placeholder={placeholder}
        value={value}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={handleInputChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        type={inputType}
      />

      <IconButton
        className={styles['icon-button']}
        disabled={!value || disabled}
        onClick={handleClearClick}
        size="large"
      >
        {value ? (
          <CloseIcon className={styles['icon-override']} />
        ) : (
          <SearchIcon className={styles['icon-override']} />
        )}
      </IconButton>
    </div>
  );
});
