import Button from '@mui/material/Button/Button';
import { useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';
import type { TreeNode } from '@work4all/components/lib/dataDisplay/tree/types';
import { LabeledInput } from '@work4all/components/lib/input/labeled-input';

import { useFormPlus } from '@work4all/data';

import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export type OrganisationAreaGroupsNewDialogProps = {
  entity: Entities;
  open: boolean;
  group?: TreeNode;
  usedIds: number[];
  endIndex: number;
  onClose: () => void;
  onConfirm: (value: TreeNode) => void;
};

const FORM_ID = 'organisation-area-groups';

const getDefaultValues = (usedIds: number[], endIndex: number): TreeNode => {
  const generateUniqueId = (): number => {
    const id = -Math.floor(Math.random() * 10000 + 1);
    return usedIds.includes(id) ? generateUniqueId() : id;
  };

  const id = generateUniqueId();
  usedIds.push(id);

  return {
    id: id.toString(),
    parentId: 0,
    label: null,
    index: endIndex,
  };
};

export function OrganisationAreaGroupsNewDialog(
  props: OrganisationAreaGroupsNewDialogProps
) {
  const { entity, open, group, usedIds, endIndex, onClose, onConfirm } = props;

  const { t } = useTranslation();

  const defaultValues = useMemo(
    () => getDefaultValues(usedIds, endIndex),
    [usedIds, endIndex]
  );

  const form = useFormPlus<TreeNode>({
    defaultValues: group ?? defaultValues,
  });
  const { handleSubmit, register } = form;
  const handleFormSubmit = handleSubmit(onConfirm);

  const formId = `${FORM_ID}-${group ? 'edit' : 'new'}`;

  return (
    <FormProvider {...form}>
      <Dialog
        open={open}
        title={t(
          `MASK.GROUP_MANAGEMENT.${entity.toUpperCase()}_GROUPS.NEW.HEADER`
        )}
        onClose={onClose}
      >
        <DialogContent>
          <form
            id={formId}
            onSubmit={(e) => {
              e.preventDefault();
              handleFormSubmit();
            }}
          >
            <LabeledInput
              label={t('COMMON.NAME')}
              autoFocus
              // @ts-expect-error lib limitation https://github.com/orgs/react-hook-form/discussions/7764#discussioncomment-2123788
              {...register('label', { required: true })}
            />
          </form>
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={onClose}>
            {t('COMMON.ABORT')}
          </Button>
          <Button color="primary" type="submit" form={formId}>
            {t('COMMON.APPLY')}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
