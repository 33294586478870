import styles from './Right.module.scss';

import { Tooltip } from '@mui/material';
import { useRef } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as InfoIcon } from '@work4all/assets/icons/info.svg';

import { Collapse } from '@work4all/components/lib/components/collapse';
import { EntityPickerPopover } from '@work4all/components/lib/components/entity-picker/components/EntityPickerPopover';
import {
  TaskPrioPicker,
  taskPrioTranslationKeys,
} from '@work4all/components/lib/components/entity-picker/task-prio-picker/TaskPrioPicker';
import {
  MultiStepControls,
  Step,
} from '@work4all/components/lib/input/multi-step-controls';

import { Task } from '@work4all/models/lib/Classes/Task.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';
import { TaskStatus } from '@work4all/models/lib/Enums/TaskStatus.enum';

import { PathsOf } from '@work4all/utils/lib/paths-of/paths-of';
import { uppercaseFirstLetter } from '@work4all/utils/lib/uppercaseFirstLetter';

import { BusinessPartnerPickerField } from '../../../../../../../../../../components/entity-picker/BusinessPartnerPickerField';
import { ContactPickerField } from '../../../../../../../../../../components/entity-picker/ContactPickerField';
import { ProjectPickerField } from '../../../../../../../../../../components/entity-picker/ProjectPickerField';
import { ProjectProcessPickerField } from '../../../../../../../../../../components/entity-picker/ProjectProcessPickerField';
import { TaskStatusPickerField } from '../../../../../../../../../../components/entity-picker/TaskStatusPickerField';
import { TopicPickerField } from '../../../../../../../../../../components/entity-picker/TopicPickerField';
import { UserPickerField } from '../../../../../../../../../../components/entity-picker/UserPickerField';
import { Panel } from '../../../../../../../../../mask-overlays/mask-overlay/components/panel/Panel';
import { ControllerPlus } from '../../../../../../../../form-plus/controller-plus';
import { useFormContextPlus } from '../../../../../../../../form-plus/use-form-context-plus';
import {
  DateTimeInputPicker,
  LabeledInputWithUnit,
  PickerTargetButton,
} from '../../../../../../../../locked-inputs';
import { ControlWrapper } from '../../../../../../../components/control-wrapper';
import { useMaskContext } from '../../../../../../../hooks/mask-context';
import { CrmMaskFormValue } from '../../../../../types';

export const Right = () => {
  const { t } = useTranslation();

  const mask = useMaskContext();

  const { control, register, watch } = useFormContextPlus<CrmMaskFormValue>();

  const anchorCenterElRef = useRef<HTMLDivElement>();

  const employeeWrapperRef = useRef<HTMLDivElement>();
  const businessPartnerWrapperRef = useRef<HTMLDivElement>();
  const projectWrapperRef = useRef<HTMLDivElement>();
  const projectProcessWrapperRef = useRef<HTMLDivElement>();
  const topicWrapperRef = useRef<HTMLDivElement>();
  const taskStatusWrapperRef = useRef<HTMLDivElement>();
  const taskPrioWrapperRef = useRef<HTMLDivElement>();

  const businessPartnerType = watch('businessPartnerType');
  const bp = watch('businessPartner');
  const project = watch('project');
  const projectProcess = watch('projectProcess');
  const topicMarkList = watch('topicMarkList');

  return (
    <Panel className={styles.right}>
      <Collapse title={t('INPUTS.PERIOD')} defaultOpen={true}>
        <ControlWrapper ref={anchorCenterElRef}>
          {mask.entity === Entities.note && (
            <DateTimeInputPicker
              {...register('date')}
              dateLabel={t('INPUTS.DATE')}
              withTime={false}
              clearable={false}
            />
          )}
          {mask.entity === Entities.callMemo && (
            <DateTimeInputPicker {...register('date')} clearable={false} />
          )}
          {mask.entity === Entities.task && (
            <>
              <DateTimeInputPicker
                {...register('date')}
                dateLabel={t('INPUTS.START')}
                withTime={false}
                anchorCenterElRef={anchorCenterElRef}
                clearable={false}
              />
              <DateTimeInputPicker
                {...register('endDate')}
                dateLabel={t('INPUTS.DUE')}
                withTime={false}
                anchorCenterElRef={anchorCenterElRef}
                clearable={false}
              />
            </>
          )}
        </ControlWrapper>
        {mask.entity === Entities.task && (
          <ControlWrapper>
            <DateTimeInputPicker
              {...register('reminderDate')}
              dateLabel={t('INPUTS.REMIND')}
              withTime={true}
              clearable={true}
            />
          </ControlWrapper>
        )}
      </Collapse>

      {[Entities.callMemo, Entities.task, Entities.note].includes(
        mask.entity
      ) && (
        <Collapse title={t('INPUTS.PARTICIPANTS')} defaultOpen={true}>
          <ControlWrapper ref={businessPartnerWrapperRef}>
            <ControllerPlus
              control={control}
              name="businessPartner.data"
              render={({ field: businessPartner }) => {
                return (
                  <ControllerPlus
                    control={control}
                    name="contact"
                    render={({ field: contact }) => {
                      return (
                        <MultiStepControls isColumnDirection={true}>
                          <Step active={true} index={0}>
                            <BusinessPartnerPickerField
                              {...businessPartner}
                              onChange={(e) => {
                                businessPartner.onChange(e?.data || null);
                                contact.onChange(e?.data?.mainContact || null);
                              }}
                            />
                          </Step>
                          <Step
                            active={
                              businessPartner.value != null &&
                              !businessPartner.value.isPrivateCustomer
                            }
                            index={1}
                          >
                            {businessPartner.value && (
                              <ContactPickerField
                                {...contact}
                                businessPartnerId={businessPartner.value.id}
                                businessPartnerType={businessPartnerType}
                                businessPartner={bp}
                                project={project}
                                projectProcess={projectProcess}
                                topicMarkList={topicMarkList?.[0]}
                              />
                            )}
                          </Step>
                        </MultiStepControls>
                      );
                    }}
                  />
                );
              }}
            />
          </ControlWrapper>

          <ControlWrapper ref={employeeWrapperRef}>
            <ControllerPlus
              control={control}
              name="user"
              render={({ field }) => {
                return <UserPickerField {...field} />;
              }}
            />
          </ControlWrapper>
        </Collapse>
      )}
      <Collapse title={t('INPUTS.ASSIGNMENT')} defaultOpen={true}>
        <ControlWrapper ref={projectWrapperRef}>
          <ControllerPlus
            control={control}
            name="project"
            render={({ field: project }) => {
              return (
                <MultiStepControls isColumnDirection={true}>
                  <Step active={true} index={0}>
                    <ProjectPickerField {...project} clearable />
                  </Step>
                  <Step active={project.value != null} index={1}>
                    <ControlWrapper ref={projectProcessWrapperRef}>
                      <ControllerPlus
                        control={control}
                        name="projectProcess"
                        render={({ field: projectProcess }) => {
                          return (
                            <ProjectProcessPickerField
                              projectId={project.value.id}
                              {...projectProcess}
                            />
                          );
                        }}
                      />
                    </ControlWrapper>
                  </Step>
                </MultiStepControls>
              );
            }}
          />
        </ControlWrapper>
        <ControlWrapper ref={topicWrapperRef}>
          <ControllerPlus
            control={control}
            name="topicMarkList"
            render={({ field }) => {
              return (
                <TopicPickerField
                  type={Entities.note}
                  {...field}
                  value={field.value ? field.value[0] : null}
                  onChange={(val) => {
                    field.onChange(val ? [val] : []);
                  }}
                />
              );
            }}
          />
        </ControlWrapper>
      </Collapse>

      {mask.entity === Entities.task && (
        <Collapse title={t('INPUTS.ADDITIONAL_SETTINGS')} defaultOpen={true}>
          <ControlWrapper ref={taskStatusWrapperRef}>
            <ControllerPlus
              control={control as Control<PathsOf<Task>>}
              name="status"
              render={({ field: status }) => {
                return (
                  <TaskStatusPickerField
                    multiple={false}
                    {...status}
                    value={{ id: status.value, name: '' }}
                    onChange={(value) => {
                      status.onChange(value || 0);
                    }}
                    availableStatus={[
                      ...new Set([
                        TaskStatus.OFFEN,
                        TaskStatus.ERLEDIGT,
                        TaskStatus.IN_BEARBEITUNG,
                        status.value as unknown as TaskStatus,
                      ]),
                    ]}
                  />
                );
              }}
            />
          </ControlWrapper>
          <ControlWrapper>
            <div ref={taskPrioWrapperRef}>
              <ControllerPlus
                control={control}
                name="priority"
                render={({ field: priority }) => {
                  return (
                    <MultiStepControls isColumnDirection={true}>
                      <Step active={true} index={0}>
                        <EntityPickerPopover
                          anchorEl={taskPrioWrapperRef}
                          picker={
                            <TaskPrioPicker
                              multiple={false}
                              value={{ id: priority.value, name: '' }}
                              onChange={(value) => {
                                priority.onChange(value.id);
                              }}
                            />
                          }
                        >
                          <PickerTargetButton
                            label={uppercaseFirstLetter(t('INPUTS.PRIORITY'))}
                            error={priority.error}
                            value={
                              priority?.value &&
                              t(taskPrioTranslationKeys[priority?.value])
                            }
                          />
                        </EntityPickerPopover>
                      </Step>
                    </MultiStepControls>
                  );
                }}
              />
            </div>
            <ControlWrapper>
              <Controller
                name="timeRequired"
                control={control}
                render={({ field: duration }) => {
                  return (
                    <LabeledInputWithUnit
                      inputProps={{ step: '0.5', min: '0' }}
                      type="number"
                      placeholder={'0,00'}
                      unit={'h'}
                      label={t('INPUTS.TIME_ESTIMATION')}
                      value={(duration.value ?? 0) * 8}
                      onChange={(event) => {
                        duration.onChange(parseFloat(event.target.value) / 8);
                      }}
                      iconRight={
                        <Tooltip
                          title={t('MASK.USED_FOR_RESSOURCE_PLANNING')}
                          arrow
                        >
                          <InfoIcon className={styles.infoIcon} />
                        </Tooltip>
                      }
                    />
                  );
                }}
              />
            </ControlWrapper>
          </ControlWrapper>
        </Collapse>
      )}
    </Panel>
  );
};
