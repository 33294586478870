import { Group } from '@work4all/models/lib/Classes/Group.entity';

import { GroupsWithoutAGivingParentFunction } from './types';

export const groupsWithoutAGivingParent: GroupsWithoutAGivingParentFunction = ({
  groups,
  maxResult,
}) => {
  const groupsIds = new Set(groups.map((group) => group.id));
  const result: Group[] = [];

  for (const group of groups) {
    if (result.length === maxResult) {
      break;
    }

    if (groupsIds.has(group.parentId)) {
      continue;
    }

    result.push(group);
  }

  return result;
};
