import styles from './PasswordChangeDialog.module.scss';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { Controller, useForm, UseFormSetError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CircleNotch } from '@work4all/assets/icons/circle_notch.svg';

import { Dialog, DialogActions, DialogContent } from '@work4all/components';
import { Tooltip } from '@work4all/components/lib/components/tooltip/Tooltip';

import {
  IconButton,
  LabeledInput,
} from '../../../../containers/mask-overlays/locked-inputs';

export interface PasswordData {
  oldPassword: string;
  newPassword: string;
  repeatedPassword: string;
}
interface PasswordChangeDialogProps {
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: (
    data: PasswordData,
    setError: UseFormSetError<PasswordData>
  ) => void;
}

const DEFAULT_VALUES: PasswordData = {
  oldPassword: '',
  newPassword: '',
  repeatedPassword: '',
};

export const PasswordChangeDialog = (props: PasswordChangeDialogProps) => {
  const { open, loading, onClose, onConfirm } = props;
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: DEFAULT_VALUES,
  });

  const oldPassword = watch('oldPassword');
  const newPassword = watch('newPassword');
  const repeatedPassword = watch('repeatedPassword');

  const passwordCriteria = [
    {
      label: t('USER.PASSWORD.CHECKBOX.MIN_SYMBOLS'),
      met: newPassword.length >= 8,
    },
    {
      label: t('USER.PASSWORD.CHECKBOX.LOWER_CASE_CHARACTER'),
      met: /[a-z]/.test(newPassword),
    },
    {
      label: t('USER.PASSWORD.CHECKBOX.UPPER_CASE_CHARACTER'),
      met: /[A-Z]/.test(newPassword),
    },
    {
      label: t('USER.PASSWORD.CHECKBOX.NUMBER'),
      met: /[0-9]/.test(newPassword),
    },
    {
      label: t('USER.PASSWORD.CHECKBOX.SPECIAL_CHARACTER'),
      met: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    },
  ];
  const allCriteriaMet =
    passwordCriteria.every((criterion) => criterion.met) &&
    oldPassword &&
    repeatedPassword;

  const onSubmit = (data: PasswordData) => {
    const { newPassword, repeatedPassword } = data;
    let hasError = false;

    if (newPassword !== repeatedPassword) {
      setError('repeatedPassword', {
        type: 'manual',
        message: t('ALERTS.PASSWORD_NOT_MATCH'),
      });
      hasError = true;
    }
    if (!hasError) {
      onConfirm(data, setError);
    }
  };

  const additionalInfo = (info: string) => (
    <Typography
      variant="subtitle1"
      sx={(theme) => ({
        color: 'var(--alert)',
        [theme.getColorSchemeSelector('dark')]: {
          color: 'var(--textInverse)',
        },
      })}
    >
      {info}
    </Typography>
  );

  return (
    <Dialog
      classes={{
        dialog: {
          paper: styles.dialogRoot,
        },
      }}
      open={open}
      title={t('USER.MANAGE_PASSWORD')}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Stack gap="1rem" py="1rem">
            <Controller
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  label={t('USER.PASSWORD.OLD_PASSWORD')}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.oldPassword?.message}
                />
              )}
            />

            <Controller
              name="newPassword"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  label={t('USER.PASSWORD.NEW_PASSWORD')}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="repeatedPassword"
              control={control}
              render={({ field }) => (
                <PasswordInput
                  label={t('USER.PASSWORD.CONFIRM_NEW_PASSWORD')}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors.repeatedPassword?.message}
                />
              )}
            />

            <Stack className={styles.criteria}>
              <Typography variant="subtitle1">
                {t('USER.PASSWORD.CHECKBOX.TITLE')}
              </Typography>
              <List disablePadding>
                {passwordCriteria.map((criteria, index) => (
                  <ListItem key={index} disableGutters disablePadding>
                    <ListItemIcon>
                      {criteria.met ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <RadioButtonUncheckedIcon color="disabled" />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={criteria.label}
                      sx={{
                        color: criteria.met
                          ? 'text.secondary'
                          : 'text.disabled',
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Stack>

          <Stack className={styles.infoBox}>
            {additionalInfo(t('USER.PASSWORD.LOGOUT_INFO'))}
            {additionalInfo(t('USER.PASSWORD.DESKTOP_INFO'))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{t('ALERTS.BTN_ABORT')}</Button>
          <Button type="submit" disabled={!allCriteriaMet || loading}>
            {loading ? (
              <CircleNotch className={styles.circle} />
            ) : (
              t('ALERTS.BTN_SAVE')
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

interface PasswordInputProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

export const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  value,
  onChange,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <LabeledInput
        value={value}
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
        label={label}
        endAdornment={
          <Tooltip
            title={showPassword ? 'Hide password' : 'Show password'}
            placement="left"
          >
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </IconButton>
          </Tooltip>
        }
      />
      {error && <Typography color="error">{error}</Typography>}
    </>
  );
};
