import {
  Path,
  PathValue,
  UnpackNestedValue,
  UseFormReturn,
} from 'react-hook-form';

import { useEntityEvents } from '@work4all/data/lib/entity-events/use-entity-events';

import { FavoriteItem } from '@work4all/models/lib/Classes/FavoriteItem.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export const useOverlayControllerFavoriteItemEntityEvents = <
  T extends {
    favoriteItem?: FavoriteItem | null;
  }
>(
  form: UseFormReturn<T>
) => {
  useEntityEvents((event) => {
    if (form.getValues('favoriteItem' as Path<T>) === undefined) {
      return;
    }

    if (event.entity === Entities.favoriteItem && event.type === 'upsert') {
      form.setValue(
        'favoriteItem' as Path<T>,
        event.data as unknown as UnpackNestedValue<PathValue<T, Path<T>>>,
        {
          shouldDirty: false,
        }
      );
    }
  });
};
