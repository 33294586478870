import { useColorScheme } from '@mui/material/styles';
import { useCallback, useMemo } from 'react';

type Mode = 'light' | 'dark' | 'system';
type Theme = 'light' | 'dark' | 'auto';

function modeToTheme(mode: Mode): Theme {
  if (mode === 'system') {
    return 'auto';
  }
  return mode;
}

function themeToMode(theme: Theme): Mode {
  if (theme === 'auto') {
    return 'system';
  }
  return theme;
}

export function usePageTheme() {
  const { mode, setMode } = useColorScheme();

  const set = useCallback(
    (theme: Theme) => {
      setMode(themeToMode(theme));
    },
    [setMode]
  );

  const context = useMemo<{
    value: Theme;
    set: (val: Theme) => void;
  }>(() => {
    return { value: modeToTheme(mode), set };
  }, [mode, set]);

  return context;
}
