import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { useDeleteEntity } from '@work4all/data';

import { Group } from '@work4all/models/lib/Classes/Group.entity';
import { InputGroup } from '@work4all/models/lib/Classes/InputGroup.entity';
import { MutateProjectGroups } from '@work4all/models/lib/Classes/MutateProjectGroups.entity';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { OrganisationAreaEntityGroups } from '../types';

const PROJECT_GROUP_MUTATION = gql`
  mutation MutateProjectGroups($input: MutateProjectGroups) {
    mutateProjectGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const CUSTOMER_GROUP_MUTATION = gql`
  mutation MutateCustomerGroups($input: MutateCustomerGroups) {
    mutateCustomerGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const SUPPLIER_GROUP_MUTATION = gql`
  mutation MutateSupplierGroups($input: MutateSupplierGroups) {
    mutateSupplierGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const ARTICLE_GROUP_MUTATION = gql`
  mutation MutateArticleGroups($input: MutateArticleGroups) {
    mutateArticleGroups(input: $input) {
      id: code
      index
      name
      parentId: parentCode
    }
  }
`;

const LETTER_TEMPLATE_GROUP_MUTATION = gql`
  mutation UpsertLetterTemplateGroup($input: InputLetterTemplateGroup!) {
    upsertLetterTemplateGroup(input: $input) {
      id: code
      index: groupIndex
      name
    }
  }
`;

const DOCUMENT_TEMPLATE_GROUP_MUTATION = gql`
  mutation UpsertDocumentTemplateGroup($input: InputDocumentTemplateGroup!) {
    upsertDocumentTemplateGroup(input: $input) {
      id: code
      index: groupIndex
      name
    }
  }
`;

export type MutateProjectGroupsResponse = {
  mutateProjectGroups: InputGroup[];
};

export type MutateCustomerGroupsResponse = {
  mutateCustomerGroups: InputGroup[];
};

export type MutateSupplierGroupsResponse = {
  mutateSupplierGroups: InputGroup[];
};

export type MutateArticleGroupsResponse = {
  mutateArticleGroups: InputGroup[];
};

export type MutateGroupsVars = {
  input: MutateProjectGroups;
};

export type MutateTempalteGroupsVars = {
  input: {
    code: number;
    name: string;
  };
};

export type MutateDocumentTemplateGroupsResponse = {
  upsertDocumentTemplateGroup: InputGroup[];
};

export type MutateLetterTemplateGroupsResponse = {
  upsertLetterTemplateGroup: InputGroup[];
};

export const useMutateGroups = (
  groupEntity: OrganisationAreaEntityGroups,
  groups: Group[]
) => {
  const [mutateProjectGroups] = useMutation<
    MutateProjectGroupsResponse,
    MutateGroupsVars
  >(PROJECT_GROUP_MUTATION);

  const [mutateCustomerGroups] = useMutation<
    MutateCustomerGroupsResponse,
    MutateGroupsVars
  >(CUSTOMER_GROUP_MUTATION);

  const [mutateSupplierGroups] = useMutation<
    MutateSupplierGroupsResponse,
    MutateGroupsVars
  >(SUPPLIER_GROUP_MUTATION);

  const [mutateArticleGroups] = useMutation<
    MutateArticleGroupsResponse,
    MutateGroupsVars
  >(ARTICLE_GROUP_MUTATION);

  const [mutateLetterTemplateGroup] = useMutation<
    MutateLetterTemplateGroupsResponse,
    MutateTempalteGroupsVars
  >(LETTER_TEMPLATE_GROUP_MUTATION);

  const [mutateDocumentTemplateGroup] = useMutation<
    MutateDocumentTemplateGroupsResponse,
    MutateTempalteGroupsVars
  >(DOCUMENT_TEMPLATE_GROUP_MUTATION);

  const [deleteEntity] = useDeleteEntity(false);

  const mutateTemplateGroups = useCallback(
    async (args, mutate, label) => {
      const input = args.variables.input.structure;
      const toDelete = groups.filter(
        (item) => !input.some((group) => group.code === item.id)
      );
      if (toDelete.length) {
        await deleteEntity({
          type: groupEntity,
          ids: toDelete.map((x) => x.id),
        });
      }

      const addOrUpdate = input.filter((x) => {
        const current = groups.find((y) => y.id === x.code);
        if (!current && x.code < 0) return true;
        return current && current.name !== x.name;
      });

      const allResult = await Promise.all(
        addOrUpdate.map(async (item) => {
          const result = await mutate({
            variables: {
              input: {
                code: item.code > 0 ? item.code : undefined,
                name: item.name,
              },
            },
          });
          return result;
        })
      );
      return {
        data: {
          [`${label}`]: allResult.map((x) => x.data[label]),
        },
      };
    },
    [mutateLetterTemplateGroup, groups, groupEntity]
  );

  const mutateLetterTemplateGroups = useCallback(
    async (args) =>
      mutateTemplateGroups(
        args,
        mutateLetterTemplateGroup,
        'upsertLetterTemplateGroup'
      ),
    [mutateTemplateGroups, mutateLetterTemplateGroup]
  );

  const mutateDocumentTemplateGroups = useCallback(
    async (args) =>
      mutateTemplateGroups(
        args,
        mutateDocumentTemplateGroup,
        'upsertDocumentTemplateGroup'
      ),
    [mutateTemplateGroups, mutateDocumentTemplateGroup]
  );

  switch (groupEntity) {
    case Entities.projectGroup:
      return {
        mutateGroups: mutateProjectGroups,
        type: 'mutateProjectGroups',
        isDraggable: true,
      };
    case Entities.customerGroup:
      return {
        mutateGroups: mutateCustomerGroups,
        type: 'mutateCustomerGroups',
        isDraggable: true,
      };
    case Entities.supplierGroup:
      return {
        mutateGroups: mutateSupplierGroups,
        type: 'mutateSupplierGroups',
        isDraggable: true,
      };
    case Entities.articleGroup:
      return {
        mutateGroups: mutateArticleGroups,
        type: 'mutateArticleGroups',
        isDraggable: true,
      };
    case Entities.letterTemplateGroup:
      return {
        mutateGroups: mutateLetterTemplateGroups,
        type: 'upsertLetterTemplateGroup',
        isDraggable: false,
      };

    case Entities.documentTemplateGroup:
      return {
        mutateGroups: mutateDocumentTemplateGroups,
        type: 'upsertDocumentTemplateGroup',
        isDraggable: false,
      };
    default:
      return;
  }
};
