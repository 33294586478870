import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import { useDataProvider } from '@work4all/data';

import { VatRate } from '@work4all/models/lib/Classes/VatRate.entity';
import { DataRequest, SortDirection } from '@work4all/models/lib/DataProvider';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

import { Tooltip } from '../../tooltip/Tooltip';
import { ListEntityPicker } from '../components/list-entity-picker/ListEntityPicker';
import { IPickerProps } from '../types';

export type IVatPickerProps<TMultiple extends boolean> = IPickerProps<
  VatRate,
  TMultiple
>;

const DEFAULT_SORT_BY = 'sentence';
const DEFAULT_SORT_BY_DIRECTION = SortDirection.ASCENDING;

export function VatPicker<TMultiple extends boolean>(
  props: IVatPickerProps<TMultiple>
) {
  const {
    sortBy = DEFAULT_SORT_BY,
    sortByDirection = DEFAULT_SORT_BY_DIRECTION,
    ...rest
  } = props;

  const request = useMemo<DataRequest>(() => {
    return {
      operationName: 'GetVatRates',
      data: VAT_FIELDS,
      entity: Entities.vatRate,
      completeDataResponse: true,
    };
  }, []);

  const response = useDataProvider<VatRate>(request);

  const patchedResponse = useMemo(() => {
    return {
      ...response,
      total: response.data.length,
      data: response.data.slice().sort((a, b) => a.sentence - b.sentence),
    };
  }, [response]);

  return (
    <ListEntityPicker
      entity={Entities.vatRate}
      data={VAT_FIELDS}
      filterBy={[]}
      sortBy={sortBy}
      sortByDirection={sortByDirection}
      fixedDataSet={patchedResponse}
      layout="simple"
      {...rest}
      renderItemContent={(vat) => {
        return (
          <Tooltip title={vat.sentence}>
            <Typography variant="body2" noWrap>
              {formatVatRate(vat)}
            </Typography>
          </Tooltip>
        );
      }}
    />
  );
}

export function formatVatRate(vat: VatRate): string {
  if (vat == null) {
    return '';
  }

  const valueAsString = vat.sentence.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${valueAsString} %`;
}

const VAT_FIELDS: VatRate<EMode.query> = {
  id: null,
  sentence: null,
  sequence: null,
  disabled: null,
};
