import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { DateTimeCustom } from '@work4all/utils/lib/date-utils/formatDateString';

import { DatePicker } from '../../../../../../input/date-picker';
import { useMobiscrollLanguage } from '../../../../../../utils/use-mobiscroll-language/use-mobiscroll-language';
import { withExtras } from '../../utils';

import { PreviewPickerInput } from './PreviewPickerInput';

export const DatePreviewPickerInput = withExtras<string>(
  ({
    value,
    onChange,
    showTime,
    dateFormat = DateTimeCustom.DATE_SIMPLE_2YEAR,
    ...rest
  }) => {
    const language = useMobiscrollLanguage();
    const { t } = useTranslation();

    const formattedValue = useMemo(() => {
      if (!value) {
        return null;
      }

      const date = DateTime.fromISO(value).toLocaleString(dateFormat);

      if (showTime) {
        return t('COMMON.DATE.TIME.VALUE', {
          date,
          time: DateTime.fromISO(value).toLocaleString(DateTime.TIME_SIMPLE),
        });
      }

      return date;
    }, [showTime, t, value, dateFormat]);

    return (
      <PreviewPickerInput<string>
        {...rest}
        value={value}
        formattedValue={formattedValue}
        picker={
          <DatePicker
            locale={language}
            controls={['calendar']}
            defaultValue={DateTime.fromISO(value).toLocaleString(dateFormat)}
            onChange={(val) => {
              onChange(val?.value?.toString());
            }}
          />
        }
        onChange={onChange}
      />
    );
  }
);
