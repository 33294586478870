import styles from './ChecklistPositionStatusCell.module.scss';

import { CheckCircleOutline, CheckCircleRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import clsx from 'clsx';
import { Row } from 'react-table';

import { useDataMutation } from '@work4all/data';

import { CheckListPosition } from '@work4all/models/lib/Classes/CheckListPosition.entity';
import { EMode } from '@work4all/models/lib/Enums/EMode.enum';
import { Entities } from '@work4all/models/lib/Enums/Entities.enum';

export type IChecklistPositionStatusCellProps = {
  row: Row<CheckListPosition>;
  currentUserId: number;
};

export const ChecklistPositionStatusCell = (
  props: IChecklistPositionStatusCellProps
) => {
  const {
    row: { original: checklistPosition },
    currentUserId,
  } = props;

  const [mutate] = useDataMutation({
    entity: Entities.checkListPosition,
    mutationType: EMode.upsert,
    responseData: {
      id: null,
      doneDate: null,
      doneByUserId: null,
      isChecked: null,
    },
  });

  const toggleStatus = () => {
    mutate({
      id: checklistPosition.id,
      doneDate:
        checklistPosition.doneDate !== null ? null : new Date().toISOString(),
      doneByUserId: currentUserId,
      isChecked: !checklistPosition.isChecked,
    } as CheckListPosition);
  };

  const Icon = checklistPosition.isChecked
    ? CheckCircleRounded
    : CheckCircleOutline;

  return (
    <div className={styles['task-status-cell-root']}>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();

          toggleStatus();
        }}
        sx={{ padding: 0 }}
      >
        <Icon
          className={clsx(styles['check-circle'], {
            [styles['check-circle-success']]: checklistPosition.isChecked,
          })}
        />
      </IconButton>
    </div>
  );
};
