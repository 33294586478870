import { useMemo } from 'react';

import { useTableStateBag } from '@work4all/components';

import { Group } from '@work4all/models/lib/Classes/Group.entity';

import { groupsWithoutAGivingParent } from './groups-without-a-giving-parent-function';
import {
  UseStringifyTableFiltersProps,
  UseStringifyTableFiltersReturn,
} from './types';

export const useStringifyTableGroupFilter = <TEntity>({
  groupKey,
  groupIdKey,
}: UseStringifyTableFiltersProps<TEntity>): UseStringifyTableFiltersReturn => {
  const { tableState } = useTableStateBag();

  const group = useMemo(() => {
    const filter = tableState?.filters.find((f) => f.id === groupIdKey);
    const groups = (filter?.value?.value as Group[]) ?? [];
    const groupsLength = groups.length;

    if (groupsLength === 0) {
      return null;
    }

    /*
     *
     * When the user selects a group without any children, only that group will
     * be selected. This means `groupsLength` is 1.
     *
     * However, if the user selects a group with children, all of its children
     * will be selected as well, making `groupsLength` greater than 1.
     * `groupsLength` can also be greater than 1 if the user selects multiple
     * groups from different parents or more than one parent group.
     *
     * If `groupsLength` is 1, only one group is selected, so we return that group.
     *
     * If `groupsLength` is greater than 1, we need to identify groups that
     * do not have a parent within the selected `groups` array.
     *
     * To achieve this, we use `groupsWithoutAGivingParent`, limiting the
     * `maxResult` to 2 to avoid resource-intensive operations.
     *
     * If `groupsWithoutAGivingParentResult` has a length of 1, the user is
     * selecting a single parent group, so we return that group.
     *
     * If the length is greater than 1, the user is selecting multiple groups
     * from different parents or more than one parent group, making it
     * ambiguous. In this case, we return `null`.
     *
     */

    if (groupsLength === 1) {
      return groups[0];
    }

    const groupsWithoutAGivingParentResult = groupsWithoutAGivingParent({
      groups,
      maxResult: 2,
    });

    if (groupsWithoutAGivingParentResult.length === 1) {
      return groupsWithoutAGivingParentResult[0];
    }

    return null;
  }, [groupIdKey, tableState?.filters]);

  const result = useMemo(() => {
    if (group) {
      const data = {
        [groupIdKey]: group.id,
        [groupKey]: group,
      };

      return JSON.stringify(data);
    }

    return undefined;
  }, [group, groupIdKey, groupKey]);

  return result;
};
